import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import clsx from 'clsx';
import { isEmpty } from 'lodash';
import { Dialog, DialogActions, DialogContent } from '@mui/material';
import { Alert, Box, Button, DateField, Grid, MenuItem, SelectField, TextField } from '@common/components/';
import { ReadTextField } from '@icoach/components/';
import GradientColorHeader from '@icoach/components/dialog/GradientColorHeader';
import useCabinetRentalApi from '@apis/useCabinetRentalApi';
import { refIsRequiredError } from '@util/utils';
import { displayDateText, parseDate } from '@util/moment';
import { cabinetQuitKeyStatusMap } from '@icoach/rentCabinet/staticData';

export const RentCabinetsInfoBox = ({ startDate, memberName, cellPhone, cabinetName, monthlyFee, memo, memberNo }) => {
    return (
        <Box className={'p-4 bg-light'}>
            <Grid spacing={3} container>
                <Grid xs={12} item>
                    <ReadTextField label={'租櫃起始日'} textProps={{ className: 'bg-dark-1' }}>
                        {displayDateText(startDate)}
                    </ReadTextField>
                </Grid>
                <Grid xs={4} item>
                    <ReadTextField label={'會員'} textProps={{ className: 'bg-dark-1' }}>
                        {memberName}
                    </ReadTextField>
                </Grid>
                <Grid xs={4} item>
                    <ReadTextField label={'會員編號'} textProps={{ className: 'bg-dark-1' }}>
                        {memberNo}
                    </ReadTextField>
                </Grid>
                <Grid xs={4} item>
                    <ReadTextField label={'會員電話'} textProps={{ className: 'bg-dark-1' }}>
                        {cellPhone}
                    </ReadTextField>
                </Grid>
                <Grid xs={4} item>
                    <ReadTextField label={'置物櫃名稱'} textProps={{ className: 'bg-dark-1' }}>
                        {cabinetName}
                    </ReadTextField>
                </Grid>
                <Grid xs={4} item>
                    <ReadTextField label={'租金'} textProps={{ className: 'bg-dark-1' }}>
                        {monthlyFee}
                    </ReadTextField>
                </Grid>
                <Grid xs={4} item>
                    <ReadTextField label={'置物櫃備註'} textProps={{ className: 'bg-dark-1' }}>
                        {memo}
                    </ReadTextField>
                </Grid>
            </Grid>
        </Box>
    );
};

const QuitAllRentCabinetDialogContent = React.forwardRef((props, ref) => {
    const { sourceData: list = [], options = {} } = props;
    const { employeeOption, cabinetQuitKeyStatusOption } = options;
    const [isShowAlert, setIsShowAlert] = useState(false);
    const keyRef = useRef(null);
    const dateRef = useRef(null);
    const employeeRef = useRef(null);
    const quitReasonRef = useRef(null);

    const handleChangeKeyStatus = (event, node, value) => {
        setIsShowAlert(parseInt(value) === cabinetQuitKeyStatusMap.lost);
    };

    useImperativeHandle(
        ref,
        () => ({
            getResult: () => {
                let result = {};

                if (dateRef.current && dateRef.current.getResult) {
                    result = Object.assign(result, {
                        endDate: dateRef.current.getResult(),
                    });
                }
                if (employeeRef.current && employeeRef.current.getResult) {
                    result = Object.assign(result, {
                        employeeID: employeeRef.current.getResult(),
                    });
                }
                if (keyRef.current && keyRef.current.getResult) {
                    result = Object.assign(result, {
                        cabinetQuitKeyStatus: keyRef.current.getResult(),
                    });
                }
                if (quitReasonRef.current && quitReasonRef.current.getResult) {
                    result = Object.assign(result, {
                        quitReason: quitReasonRef.current.getResult(),
                    });
                }
                return result;
            },
            isError: () => refIsRequiredError(dateRef, employeeRef, keyRef, quitReasonRef),
        }),
        []
    );

    return (
        <DialogContent className={'p-0'}>
            {!isEmpty(list) &&
                list.map(({ cabinetID, startDate, memberName, cellPhone, cabinetName, monthlyFee, memo, memberNo }) => (
                    <RentCabinetsInfoBox
                        key={cabinetID}
                        startDate={startDate}
                        memberName={memberName}
                        cellPhone={cellPhone}
                        cabinetName={cabinetName}
                        monthlyFee={monthlyFee}
                        memo={memo}
                        memberNo={memberNo}
                    />
                ))}
            <Box className={'p-4'}>
                <Grid spacing={3} container>
                    <Grid xs={6} item>
                        <DateField ref={dateRef} label={'退租日期'} defaultValue={parseDate()} fullWidth required />
                    </Grid>
                    <Grid xs={6} item />
                    <Grid xs={6} item>
                        <SelectField label={'擔當'} ref={employeeRef} fullWidth required>
                            {Array.isArray(employeeOption) &&
                                employeeOption
                                    .filter((item) => item || !item.disabled)
                                    .map((item) => (
                                        <MenuItem key={item.value} value={item.value}>
                                            {item.text}
                                        </MenuItem>
                                    ))}
                        </SelectField>
                    </Grid>
                    <Grid xs={6} item>
                        <SelectField label={'繳回鑰匙'} ref={keyRef} onChange={handleChangeKeyStatus} fullWidth required>
                            {Array.isArray(cabinetQuitKeyStatusOption) &&
                                cabinetQuitKeyStatusOption
                                    .filter((item) => item || !item.disabled)
                                    .map((item) => (
                                        <MenuItem key={item.value} value={item.value}>
                                            {item.text}
                                        </MenuItem>
                                    ))}
                        </SelectField>
                    </Grid>
                    <Grid xs={12} item>
                        <TextField ref={quitReasonRef} label={'退租原因'} inputProps={{ placeholder: '如：正常退租' }} minRows={3} multiline fullWidth />
                    </Grid>
                    {isShowAlert && (
                        <Grid xs={12} item>
                            <Alert variant={'filled'} severity={'info'} color={'info'}>
                                鑰匙遺失如有費用產生請至營收管理增加收入
                            </Alert>
                        </Grid>
                    )}
                </Grid>
            </Box>
        </DialogContent>
    );
});

const QuitAllRentCabinetDialog = (props) => {
    const { className, open, memberID: memberIDProps, onClose, refresh } = props;
    const { getAllRentCabinetDataApi, putAllRentCabinetQuitApi } = useCabinetRentalApi();
    const [sourceData, setSourceData] = useState({});
    const contentRef = useRef();

    const handleSubmit = (memberID) => {
        const isError = contentRef.current && contentRef.current.isError();
        if (!isError) {
            const params = contentRef.current && contentRef.current.getResult();
            doQuitAllRentCabinetApi(memberID, params);
        }
    };

    const doOptionsApi = async (memberID) => {
        let res = await getAllRentCabinetDataApi(memberID);
        if (res) {
            const { rentCabinetsData, options } = res;
            setSourceData({
                options,
                rentCabinetsData,
            });
        }
    };

    const doQuitAllRentCabinetApi = async (memberID, params) => {
        let res = await putAllRentCabinetQuitApi(memberID, params);
        if (res) {
            refresh && refresh();
            onClose();
        }
    };

    useEffect(
        () => {
            if (open) doOptionsApi(memberIDProps);
            else setSourceData({});
        },
        // eslint-disable-next-line
        [open]
    );

    if (!open) return null;

    return (
        <Dialog key={open} className={clsx('simple-content', 'dialog', className)} PaperProps={{ className: 'wx-90rem' }} open={open} fullWidth>
            <GradientColorHeader onClose={onClose}>租櫃退租</GradientColorHeader>
            {!isEmpty(sourceData.rentCabinetsData) ? (
                <QuitAllRentCabinetDialogContent ref={contentRef} sourceData={sourceData.rentCabinetsData || []} options={sourceData.options || []} />
            ) : (
                <Alert variant="filled" severity="info" color="info" className="m-2">
                    {`尚無租櫃紀錄`}
                </Alert>
            )}
            <DialogActions className="px-4 pt-0 pb-2">
                {!isEmpty(sourceData.rentCabinetsData) ? (
                    <Button variant="contained" onClick={() => handleSubmit(memberIDProps)}>
                        確認已繳回
                    </Button>
                ) : (
                    <Button variant="contained" onClick={onClose}>
                        關閉
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    );
};

export default QuitAllRentCabinetDialog;
