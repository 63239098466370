import React, { useState } from 'react';
import { Card, Tab, TabPanel, Tabs, Typography } from '../../../components';
import clsx from 'clsx';

export const TabsLabel = ({ className, label, count = 0 }) => {
    return (
        <Typography className={clsx('tabs-label', className)}>
            {label}
            <span className={'tabs-label-count'}>{count}</span>
        </Typography>
    );
};

export const TabsContentLayout = ({
    tabData,
    children,
    handleChange,
    className,
    headerRightBar,
    state,
}) => {
    const [tabsIndex, changeTabIndex] = useState(0);
    const handleChangeTab = (e, index) => {
        if (index < 0 || index >= tabData.length) return false;
        if (typeof handleChange === 'function') handleChange(tabsIndex);
        changeTabIndex(index);
    };

    return (
        <React.Fragment>
            <Card className={clsx(className, 'tabs-content-layout')}>
                <header className={'page-header'}>
                    <Tabs value={tabsIndex} onChange={handleChangeTab}>
                        {tabData.map(({ id, label, isShowState }, index) => {
                            return (
                                <Tab
                                    key={id}
                                    index={index}
                                    label={label}
                                    className={clsx({
                                        state: isShowState,
                                    })}
                                />
                            );
                        })}
                    </Tabs>
                    {React.isValidElement(headerRightBar) &&
                        React.cloneElement(headerRightBar, {
                            tabsIndex,
                            state,
                        })}
                </header>
                <Card.CardContent className="pt-4 px-4 pb-3">
                    {React.isValidElement(children) &&
                        React.cloneElement(children, {
                            tabsIndex,
                            state,
                        })}
                    {tabData.map(({ id, component }, index) => {
                        return (
                            <TabPanel key={id} value={tabsIndex} index={index}>
                                {React.isValidElement(component) &&
                                    React.cloneElement(component, {
                                        tabsIndex,
                                        state,
                                    })}
                            </TabPanel>
                        );
                    })}
                </Card.CardContent>
            </Card>
        </React.Fragment>
    );
};
export default TabsContentLayout;
