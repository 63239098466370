import React, { useEffect, useState } from 'react';
import { Typography, Chip } from '@common/components/';
import { BorderColor as BorderColorIcon, Trash as TrashIcon } from '@common/SvgIcon/';
import Tablenization from '@icoach/components/Tablenization';
import { MemoControllerBox, MoreLabelBox } from '@icoach/components/';
import { displayDateText, parseDate } from '@util/moment';
import useClubApi from '@apis/useClubApi';
import { ClubSuspensionCreateDialog } from '@icoach/club/dialog/';
import useToggleDialog from '@util/hook/useToggleDialog';
import ClubSuspensionCreateButton from './ClubSuspensionCreateButton';
import { ConfirmDeleteDocDialog } from '@icoach/documents/membership/dialog';
import { DateTimeStandard } from '../../../components/pickers/enums/DateTimeStandard';

const suspendStatusColor = {
    0: 'info',
    1: 'success',
    2: 'warning',
    8: 'gray',
    9: 'error',
};

const MoreLabel = (props) => {
    const { rowData, moreActionArray, onDeleteOnClick } = props;
    const moreLabelMap = {
        edit: {
            text: '編輯',
            icon: <BorderColorIcon />,
            dialogString: 'editClubsSuspension',
            Dialog: ClubSuspensionCreateDialog,
            rowData: rowData,
        },
        delete: {
            text: '取消',
            icon: <TrashIcon />,
            onClickEvent: onDeleteOnClick,
            // dialog: SmsTemplateDeleteDialog,
        },
    };
    return <MoreLabelBox {...props} moreActionArray={moreActionArray} moreLabelMap={moreLabelMap} rowData={rowData} isEdit={true} />;
};

const rowLabel = [
    {
        cellKey: '',
        headerLabel: '項次',
        align: 'center',
        formatCell: (cellValue, rowData, { sequence }) => {
            return sequence;
        },
    },
    {
        cellKey: 'applyDate',
        headerLabel: '申請時間',
        align: 'center',
        formatCell: (cellValue) => {
            return parseDate(cellValue, DateTimeStandard.DisplayDateBySlash);
        },
    },
    {
        cellKey: 'suspendStatusText',
        headerLabel: '狀態',
        align: 'center',
        formatCell: (cellValue, { suspendStatus }) => {
            return <Chip label={cellValue} color={suspendStatusColor[suspendStatus] || 'gray'} />;
        },
    },
    {
        cellKey: 'suspendStartDate',
        headerLabel: '期間',
        align: 'center',
        formatCell: (suspendStartDate, { suspendEndDate }) => {
            return displayDateText(suspendStartDate, suspendEndDate);
        },
    },
    {
        cellKey: 'suspendMonth',
        headerLabel: '月數',
        align: 'center',
    },
    {
        cellKey: 'suspendDay',
        headerLabel: '天數',
        align: 'center',
    },
    {
        cellKey: 'employeeDisplayName',
        headerLabel: '擔當',
        width: 5,
        align: 'center',
    },
    {
        cellKey: 'reasonText',
        headerLabel: '原因',
        formatCell: (cellValue, rowData, { onReasonOnClick }) => {
            return <MemoControllerBox data={cellValue} dialogTitle={'原因'} />;
        },
    },

    {
        cellKey: 'more',
        headerLabel: '',
        align: 'right',
        isIcon: true,
        formatCell: (cellValue, rowData, { onRefrash, onDeleteOnClick }) => {
            if (!rowData.canModify) return null;
            return <MoreLabel rowData={rowData} moreActionArray={['edit', 'delete']} onRefrash={onRefrash} onDeleteOnClick={() => onDeleteOnClick(rowData)} />;
        },
    },
];

const ClubSuspensionPage = React.forwardRef((props, ref) => {
    const { getClubsSuspensionApi, deleteClubsSuspensionApi } = useClubApi();
    const [sourceData, setSourceData] = useState({});
    const [isMounted, setMounted] = useState(false);
    const { list: rowsData = [] } = sourceData;
    const { openTarget, handleOpenDialog, handleCloseDialog, suspendID } = useToggleDialog();

    const doInitOverviewDataApi = async () => {
        const resp = await getClubsSuspensionApi({
            page: 1,
            size: 100,
        });
        if (resp) {
            setSourceData(resp);
        }
        setMounted(true);
    };

    const handleDeleteOnClick = (target) => {
        handleOpenDialog('delete', { suspendID: target.suspendID });
    };

    const doDeleteSuspensionApi = async () => {
        const res = await deleteClubsSuspensionApi(suspendID);
        if (res) {
            doInitOverviewDataApi();
            handleCloseDialog();
        }
    };

    useEffect(
        () => {
            doInitOverviewDataApi();
        },
        // eslint-disable-next-line
        [],
    );
    return (
        <div className="container main-container-spacing">
            <header className={'page-header'}>
                <Typography className={'page-header-title'} variant={'h3'}>
                    店舖店休
                </Typography>
                <div className="btn-group">
                    <ClubSuspensionCreateButton onRefrash={doInitOverviewDataApi} />
                </div>
            </header>
            {isMounted && (
                <Tablenization>
                    <Tablenization.TableRowHeader headerRow={rowLabel} />
                    <Tablenization.TableRowBody
                        headerRow={rowLabel}
                        rowsData={rowsData}
                        onRefrash={doInitOverviewDataApi}
                        onDeleteOnClick={handleDeleteOnClick}
                    />
                </Tablenization>
            )}
            <ConfirmDeleteDocDialog
                title={'確認刪除此店舖店休'}
                message={
                    <Typography>
                        取消後就<strong className={'text-error'}>無法恢復</strong>囉 ，確認要取消嗎？
                    </Typography>
                }
                confirmMessage={'同意取消'}
                open={openTarget === 'delete'}
                onClose={handleCloseDialog}
                data={rowsData}
                deleteDocApi={doDeleteSuspensionApi}
                refresh={doInitOverviewDataApi}
            />
        </div>
    );
});

export default ClubSuspensionPage;
