import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { IconButton, Menu, MenuItem, ListItemText } from '@common/components/';
import { MoreVertical as MoreVerticalIcon } from '@common/SvgIcon/';
import { APPOINTMENT_APPOINTMENTID_INFORMATIO_PATH } from '@icoach/router/routerPath';
import useLanding from '@icoach/landingPage/components/useLanding';

const AppointmentMoreMenuOption = React.forwardRef((props, ref) => {
    const { apoId: appointmentID, peopleType, peopleID, name, nickName, apoDateTime } = props;
    const { dialogRef } = useLanding();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleMoreIconClick = (e) => {
        setAnchorEl(e.currentTarget);
    };

    const handleClose = (e) => {
        setAnchorEl(null);
    };

    const handleMenuAction = (target) => {
        if (target === 'sms') {
            dialogRef.current &&
                dialogRef.current.openSmsDialog &&
                dialogRef.current.openSmsDialog({
                    id: peopleID,
                    type: peopleType,
                    name: nickName || name,
                    appointmentID,
                    apoDateTime,
                });
        } else if (target === 'contact') {
            dialogRef.current &&
                dialogRef.current.openAppointmentContactDialog &&
                dialogRef.current.openAppointmentContactDialog({
                    appointmentID: appointmentID,
                    peopleID,
                    peopleType,
                });
        }
        setAnchorEl(null);
    };

    return (
        <React.Fragment>
            <IconButton onClick={handleMoreIconClick}>
                <MoreVerticalIcon />
            </IconButton>
            <Menu anchorEl={anchorEl} open={open} onClose={(e) => handleClose(null)}>
                <MenuItem onClick={() => handleMenuAction()} to={APPOINTMENT_APPOINTMENTID_INFORMATIO_PATH(appointmentID)} component={NavLink}>
                    <ListItemText>編輯/查看</ListItemText>
                </MenuItem>
                <MenuItem onClick={() => handleMenuAction('contact')}>
                    <ListItemText>新增聯繫</ListItemText>
                </MenuItem>
                <MenuItem onClick={() => handleMenuAction('sms')}>
                    <ListItemText>發送簡訊</ListItemText>
                </MenuItem>
            </Menu>
        </React.Fragment>
    );
});

export default AppointmentMoreMenuOption;
