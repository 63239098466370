import React from 'react';
import clsx from 'clsx';
import { Grid } from '@mui/material';
import { ReadTextField } from '../../components';
import { Card, Typography } from '../../../components/';

const ConfigurationOthersReadContent = React.forwardRef((props, ref) => {
    const { className: classNameProps, sourceData } = props;
    const { shinKongSetting = {}, ecPaySetting = {}, smsSetting = {} } = sourceData;

    return (
        <React.Fragment>
            <Card.CardContent className={clsx(classNameProps)}>
                <Typography className={'font-weight-medium'} variant={'h5'}>
                    批次扣款設定
                </Typography>
                <Grid container spacing={3}>
                    <Grid item xs={4}>
                        <ReadTextField label={'銀行'}>新光銀行</ReadTextField>
                    </Grid>
                    <Grid item xs={4}>
                        <ReadTextField label={'特店代號(15碼)'}>{shinKongSetting.merchantNo}</ReadTextField>
                    </Grid>
                </Grid>
            </Card.CardContent>
            <Card.CardContent className={clsx(classNameProps)}>
                <Typography className={'font-weight-medium'} variant={'h5'}>
                    電子發票設定
                </Typography>
                <Grid container spacing={3}>
                    <Grid item xs={4}>
                        <ReadTextField label={'商店代號'}>{ecPaySetting.merchantID}</ReadTextField>
                    </Grid>
                    <Grid item xs={4}>
                        <ReadTextField label={'帳號'}>{ecPaySetting.account}</ReadTextField>
                    </Grid>
                    <Grid item xs={4}>
                        <ReadTextField label={'密碼'}>{ecPaySetting.password}</ReadTextField>
                    </Grid>
                    <Grid item xs={4}>
                        <ReadTextField label={'HashKey'}>{ecPaySetting.hashKey}</ReadTextField>
                    </Grid>
                    <Grid item xs={4}>
                        <ReadTextField label={'HashIV'}>{ecPaySetting.hashIV}</ReadTextField>
                    </Grid>
                </Grid>
            </Card.CardContent>
            <Card.CardContent className={clsx(classNameProps)}>
                <Typography className={'font-weight-medium'} variant={'h5'}>
                    簡訊發送設定
                </Typography>
                <Grid container spacing={3}>
                    <Grid item xs={4}>
                        <ReadTextField label={'帳號'}>{smsSetting.account}</ReadTextField>
                    </Grid>
                    <Grid item xs={4}>
                        <ReadTextField label={'密碼'}>{smsSetting.password}</ReadTextField>
                    </Grid>
                </Grid>
            </Card.CardContent>
        </React.Fragment>
    );
});

export default ConfigurationOthersReadContent;
