import Mock from 'mockjs';
import { successApi } from './respondData';

export const withMockContractMembershipProvider = () => {
    Mock.mock(/^\/api\/doc\/membership\/contract\/[0-9]+$/, 'get', (option) => {
        let sourceData = { ...successApi };
        sourceData.result = 'ok';
        return sourceData;
    });
    // 新增/更新入會申請書合約
    Mock.mock(/^api\/doc\/membership\/contract$/, 'post', (option) => {
        const params = JSON.parse(option.body) ?? '';
        console.log(params, 'create membership');
        let sourceData = { ...successApi };
        sourceData.result = 'ok';
        return sourceData;
    });
    // 暫存入會申請書合約
    Mock.mock(/^api\/doc\/membership\/contract\/temporary$/, 'post', () => {
        let sourceData = { ...successApi };
        sourceData.result = 'ok';
        return sourceData;
    });
    Mock.setup({ timeout: '200-800' });
};
