import React, { useRef } from 'react';
import { Dialog, DialogActions, DialogContent, Grid } from '@mui/material';
import { Button, TextField, Alert } from '@common/components/';
import GradientColorHeader from '@icoach/components/dialog/GradientColorHeader';
import { refIsRequiredError } from '@util/utils';
import useTemporaryApi from '@apis/useTemporaryApi';
import useNonMember from '@icoach/nonMember/components/useNonMember';

const AddTemporaryDialog = (props) => {
    const { postTemorariesOverviewApi } = useTemporaryApi();
    const { screener } = useNonMember();
    const folderRef = useRef(null);
    const { onClose: propsOnClose, open, sourceData = [], customerType, refresh, refreshParams = [] } = props;

    const len = sourceData.length;
    const handleCloseOnClick = () => {
        propsOnClose(null);
    };

    const handleSendOnClick = (e) => {
        if (!refIsRequiredError(folderRef)) {
            const targetIDs = sourceData.map(({ customerID }) => customerID);
            const params = {
                name: folderRef.current.getResult(),
                customerType: customerType,
                note: screener.current.getResult(),
                targetIDs: [...targetIDs],
            };

            addTemporariesDataApi(params);
        }
    };

    const addTemporariesDataApi = async (params) => {
        const resp = await postTemorariesOverviewApi(params);
        if (resp) {
            propsOnClose(null);
            refresh && refresh.current && refresh.current(...refreshParams);
        } else {
            console.error(resp);
        }
    };

    return (
        <Dialog open={open} fullWidth>
            <GradientColorHeader onClose={handleCloseOnClick}>新增暫存清單</GradientColorHeader>
            <DialogContent>
                <Grid container>
                    <Grid item xs={12}>
                        <TextField ref={folderRef} label="名單標題" direction={'row'} fullWidth required />
                    </Grid>
                    <Grid item xs={12}>
                        {open && (
                            <Alert color="info" severity="info" variant="filled" className="my-3">
                                {`篩選條件: ${screener.current.getResult()}`}
                                <br />
                                選取 {len} 筆資料
                            </Alert>
                        )}
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleSendOnClick} color="secondary" variant="contained">
                    確認
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default AddTemporaryDialog;
