import React, { useState, useRef, useEffect } from 'react';
import clsx from 'clsx';
import moment from 'moment';
import { Box, Calendar, Stack, IconButton } from '@common/components/';
import { NavigateBefore as NavigateBeforeIcon, NavigateNext as NavigateNextIcon } from '@common/SvgIcon/';
import { CheckInDialog } from '@icoach/members/memberDetail/dialog';
import { parseDate } from '@util/moment';
import useToggleDialog from '@util/hook/useToggleDialog';
import { dialogTypeMap } from '@icoach/members/staticData';

const WorkoutCalendarBox = ({
    currentDate,
    sourceData = { workoutData: [], measureRangeDate: [] },
    calendarToShow = 2,
    handleNext,
    handlePrev,
    memberID,
    refresh,
}) => {
    const today = parseDate();
    const yearMonthText = parseDate(currentDate, 'YYYY/MM');
    const [controller, setController] = useState({
        isPrev: true,
        isNext: true,
    });
    const { openTarget, handleOpenDialog: onOpenDialog, handleCloseDialog, data: workOutData } = useToggleDialog();
    let cache = useRef({});

    const onClickNext = () => {
        if (typeof handleNext === 'function') handleNext(calendarToShow);
    };

    const onClickPrev = () => {
        if (typeof handlePrev === 'function') handlePrev(calendarToShow);
    };

    const isWorkoutDay = (mDate) => {
        const date = mDate.date();
        const key = mDate.format('YYYY/MM');
        let target;

        if (!cache.current.hasOwnProperty(key)) return false;
        target = cache.current[key];
        if (!target.hasOwnProperty('dateMap')) return false;
        target = target.dateMap;
        if (!target.hasOwnProperty(date)) return false;

        return true;
    };

    const isMeasureDay = (mDate) => {
        const { measureRangeDate = [] } = sourceData;
        if (measureRangeDate.length === 0) return false;
        const _date = parseDate(mDate, 'YYYY-MM-DD');
        
        return measureRangeDate.includes(_date);
    };

    const handleRoleDayClassName = (mDate) => {
        const format = 'YYYY/MM';
        const key = parseDate(mDate, format);
        if (!cache.current.hasOwnProperty(key)) {
            const { workoutData = [] } = sourceData;
            Array.isArray(workoutData) &&
                workoutData.forEach((item) => {
                    const yyyy = item.year;
                    const mm = String(item.month).padStart(2, '0');
                    const _key = parseDate(moment(`${yyyy}-${mm}`), format);
                    let _target;
                    let daysObj = {};
                    cache.current[_key] = { ...item };
                    _target = cache.current[_key];
                    _target.days.forEach((d, i) => {
                        daysObj[d] = i;
                    });
                    _target.dateMap = daysObj;
                });
        }
        return {
            workout: isWorkoutDay(mDate),
            measure: isMeasureDay(mDate),
        };
    };

    const onDayClick = (mDate) => {
        if (!isWorkoutDay(mDate)) return null;
        handleOpenDialog(mDate);
    };

    const handleOpenDialog = (mDate) => {
        let key = mDate.format('YYYY/MM');
        let target = cache.current[key];
        let date = mDate.date();
        let index = target.dateMap[date];

        onOpenDialog(dialogTypeMap.workOut, {
            data: {
                mDate,
                title: mDate.format('YYYY/MM/DD'),
                checkInRecord: target.items[index],
            },
        });
    };

    useEffect(() => {
        if (!currentDate) return null;
        if (moment(currentDate).isSameOrAfter(today, 'month')) {
            setController((prevState) => ({
                ...prevState,
                isNext: false,
            }));
        } else {
            setController((prevState) => ({
                ...prevState,
                isNext: true,
            }));
        }
        // eslint-disable-next-line
    }, [yearMonthText, currentDate]);

    if (!sourceData) return null;

    return (
        <React.Fragment>
            <Box className={clsx('workout-calendar-box')}>
                <IconButton className="workout-calendar-box-controller prev" onClick={onClickPrev} disabled={!controller.isPrev}>
                    <NavigateBeforeIcon />
                </IconButton>
                <IconButton className="workout-calendar-box-controller next" onClick={onClickNext} disabled={!controller.isNext}>
                    <NavigateNextIcon />
                </IconButton>
                <Stack justifyContent="center" spacing={3}>
                    {Array.from({ length: calendarToShow }).map((_, i) => {
                        let currentMonth = moment(currentDate);
                        currentMonth = currentMonth.subtract(calendarToShow - i - 1, 'month');
                        return (
                            <Calendar
                                key={currentMonth.format('YYYY/MM')}
                                className="workout-calendar-box-calendar prev"
                                year={currentMonth.year()}
                                month={currentMonth.month()}
                                handleRoleDayClassName={handleRoleDayClassName}
                                onDayClick={onDayClick}
                            />
                        );
                    })}
                </Stack>
            </Box>
            <CheckInDialog
                refresh={refresh}
                memberID={memberID}
                open={openTarget === dialogTypeMap.workOut}
                data={workOutData}
                title={`${workOutData?.title} 進場紀錄`}
                onClose={handleCloseDialog}
            />
        </React.Fragment>
    );
};

export default WorkoutCalendarBox;
