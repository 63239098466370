import React, { useRef, useState, useImperativeHandle } from 'react';
import { Dialog, DialogContent, DialogActions, Grid } from '@mui/material';
import { v4 as uuid } from 'uuid';
import _ from 'lodash';
import CheckinMultipleDateTimeBox from '../checkin/CheckinMultipleDateTimeBox';
import GradientColorHeader from '@icoach/components/dialog/GradientColorHeader';
import Tablenization from '@icoach/components/Tablenization';
import { Stack, Button, AutocompleteField } from '@common/components/';
import { refIsRequiredError } from '@util/utils';
import { parseDate, getNowTime } from '@util/moment';
import usePeopleApi from '@apis/usePeopleApi';
import useMemberCheckInApi from '@apis/useMemberCheckInApi';

const CancelAddDialog = React.forwardRef((props, ref) => {
    const { onClear: onClearProps, onClose: onCloseProps } = props;
    const [open, setOpen] = useState(false);

    const handleConfirm = () => {
        onClearProps && onClearProps();
        setOpen(false);
    };

    const handleOnClose = () => {
        onCloseProps && onCloseProps();
        setOpen(false);
    };

    useImperativeHandle(
        ref,
        () => {
            return {
                onClose: () => handleOnClose(),
                onOpen: () => setOpen(true),
            };
        },
        // eslint-disable-next-line
        []
    );

    return (
        <Dialog open={open}>
            <GradientColorHeader onClose={handleOnClose}>訊息</GradientColorHeader>
            <DialogContent className="mt-3">您確定要放棄新增手動簽到並清空？</DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={handleConfirm}>
                    確定
                </Button>
            </DialogActions>
        </Dialog>
    );
});

const rowLabel = [
    {
        cellKey: '',
        headerLabel: ({ count }) => `項次(${count})`,
        align: 'center',
        width: 5,
        formatCell: (_value, _rowData, { sequence }) => sequence,
    },
    {
        cellKey: 'dateTime',
        headerLabel: '簽到時間',
        width: 16,
        align: 'center',
        formatCell: (_value) => parseDate(_value, 'YYYY-MM-DD HH:mm', false),
    },
    {
        cellKey: 'date',
        headerLabel: '',
        align: 'right',
        formatCell: (_value, _rowData, { onDelete }) => {
            return <Button onClick={() => onDelete(_value, 'date')}>刪除</Button>;
        },
    },
];

const CheckinDialog = React.forwardRef((props, ref) => {
    const { onClose: onCloseProps, open } = props;
    const { getPeopleApi } = usePeopleApi();
    const { postMemberCheckinApi } = useMemberCheckInApi();
    const [peopleID, setPeopleID] = useState({
        id: null,
        next: null,
    });
    const [rowsData, setRowsData] = useState([]);
    const [options, setOptions] = useState([]);
    const multipleDateTimeRef = useRef(null);
    const peopleRef = useRef(null);
    const cancelAddDialogRef = useRef(null);

    // 額外訊息視窗 不改變原值
    const handleDialogMessageClose = () => {
        const newObj = { id: peopleID.id, next: null };
        setPeopleID(newObj);
    };

    // 額外訊息視窗 確認關閉要清空
    const handleClearRowData = () => {
        setRowsData([]);
        setPeopleID((pre) => ({ id: pre.next, next: null }));
    };

    // 額外訊息視窗 判斷是否改變input
    const handleMemberChange = (peopleData) => {
        const pID = peopleData?.id ?? null;
        if (!_.isEmpty(rowsData) && peopleID.id && peopleID !== pID) {
            cancelAddDialogRef.current.onOpen();
            setPeopleID((pre) => ({ ...pre, next: pID }));
        } else {
            setPeopleID((pre) => ({ ...pre, id: pID }));
        }
    };

    // 關閉本身視窗 按鈕
    const handleOnClose = (e) => {
        setRowsData([]);
        setPeopleID({
            id: null,
            next: null,
        });
        onCloseProps && onCloseProps(null);
    };

    // 確認送出 按鈕
    const handleConfirmOnClick = (e) => {
        const params = rowsData.map(({ id: memberID, dateTime: checkinDate }) => ({
            memberID,
            checkinDate,
        }));
        if (params.length === 0) {
            refIsRequiredError(peopleRef, multipleDateTimeRef);
            return;
        }
        postCheckinApi(params);
    };

    // one box 回傳陣列 [date...],簽到 按鈕
    const handleSigninClick = (e) => {
        if (!refIsRequiredError(peopleRef, multipleDateTimeRef)) {
            const hms = parseDate(getNowTime(), 'HH:mm:ss');
            const memberInfo = peopleRef.current.getResult();
            const dateArrays = multipleDateTimeRef.current.getResult();
            const target = dateArrays.map((target) => {
                return { dateTime: `${target}T${hms}`, date: target, time: hms, ...memberInfo };
            });
            let newTarget = [...rowsData, ...target];
            newTarget = _.uniqBy(newTarget, (item) => item.date);
            newTarget = _.orderBy(newTarget, (item) => item.date);
            setRowsData(newTarget);
        }
    };

    const handleOnDelete = (_id, mapKey) => {
        setRowsData((pre) => {
            if (!Array.isArray(pre)) return [];
            else
                return pre.reduce((acc, cur) => {
                    let targetAry = [...acc];
                    if (cur[mapKey] !== _id) {
                        targetAry.push(cur);
                    }
                    return targetAry;
                }, []);
        });
    };

    const doMemberListApi = async () => {
        const resp = await getPeopleApi(6);
        if (resp) setOptions(resp);
    };

    const postCheckinApi = async (params) => {
        const resp = await postMemberCheckinApi(params);
        if (resp) {
            handleOnClose();
        }
    };

    React.useLayoutEffect(
        () => {
            doMemberListApi();
        },
        // eslint-disable-next-line
        []
    );

    return (
        <React.Fragment>
            <Dialog open={open} PaperProps={{ sx: { maxWidth: 1020, width: 980 } }} fullWidth>
                <GradientColorHeader onClose={handleOnClose}>手動簽到</GradientColorHeader>
                <DialogContent className="checkin-dialog mt-3">
                    <Grid container spacing={2} columns={10}>
                        <Grid item xs={4}>
                            <Stack direction="column" spacing={2}>
                                <Grid container spacing={2}>
                                    <Grid item xs={9} lg={8}>
                                        <AutocompleteField
                                            key={uuid()}
                                            defaultValue={peopleID?.id ?? null}
                                            onChange={handleMemberChange}
                                            ref={peopleRef}
                                            options={options}
                                            optionKeys={['name', 'mobile', 'no']}
                                            primaryKey={'id'}
                                            inputProps={{
                                                placeholder: `請輪人姓名/手機/會員編碼`,
                                            }}
                                            fullWidth
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={3} lg={4}>
                                        <Button variant="contained" onClick={handleSigninClick}>
                                            簽到
                                        </Button>
                                    </Grid>
                                </Grid>
                                <div>
                                    <Tablenization height={360}>
                                        <Tablenization.TableRowHeader headerRow={rowLabel} className="checkin-table-header" count={rowsData.length} />
                                        <Tablenization.TableRowBody headerRow={rowLabel} rowsData={rowsData} onDelete={handleOnDelete} />
                                    </Tablenization>
                                </div>
                            </Stack>
                        </Grid>
                        <Grid item xs={6}>
                            <CheckinMultipleDateTimeBox ref={multipleDateTimeRef} />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Stack spacing={2}>
                        <Button variant="outlined" onClick={handleOnClose}>
                            取消
                        </Button>
                        <Button variant="contained" onClick={handleConfirmOnClick}>
                            確定
                        </Button>
                    </Stack>
                </DialogActions>
            </Dialog>
            <CancelAddDialog ref={cancelAddDialogRef} onClear={handleClearRowData} onClose={handleDialogMessageClose} />
        </React.Fragment>
    );
});

export default CheckinDialog;
