import React from 'react';
import { Dialog, DialogContent, Grid } from '@mui/material';
import GradientColorHeader from '@icoach/components/dialog/GradientColorHeader';
import { Button, Alert, Stack } from '@common/components/';
import useCustomerApi from '@apis/useCustomerApi';

// 新增成為顧客無效
const AddInvalidDialog = (props) => {
    const {
        onClose: propsOnClose,
        open,
        sourceData = [],
        refresh,
        refreshParams = [],
        dialogViewMap = {},
    } = props;
    const { length: count } = sourceData;
    const { TEXT, INVALIDTEXT } = dialogViewMap;
    const { putCustomersJoinInvalidApi } = useCustomerApi();
    const handleCloseOnClick = () => {
        propsOnClose(null);
    };

    const handleSendOnClick = () => {
        const params = {
            customerIDs: sourceData.map(({ customerID }) => customerID),
        };
        AddInvaludApi(params);
    };

    const AddInvaludApi = async (params) => {
        const resp = await putCustomersJoinInvalidApi(params);
        if (resp) {
            propsOnClose(null);
            refresh && refresh.current && refresh.current(...refreshParams);
        } else {
            console.error(resp);
        }
    };

    return (
        <Dialog open={open} fullWidth>
            <GradientColorHeader onClose={handleCloseOnClick}>加入無效</GradientColorHeader>
            <DialogContent dividers>
                <Grid container>
                    <Grid item xs={12}>
                        <Alert color="warning" severity="invalid" variant="filled" className="my-2">
                            {`將有 ${count} 筆${TEXT}狀態改為${INVALIDTEXT}?`}
                        </Alert>
                    </Grid>
                    <Grid item xs={12}>
                        <Stack justifyContent="center">
                            <Button
                                size="large"
                                variant="contained"
                                color="warning"
                                onClick={handleSendOnClick}
                            >
                                確定
                            </Button>
                        </Stack>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};

export default AddInvalidDialog;
