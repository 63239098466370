import React, { useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { Grid } from '@mui/material';
import IconGroupHeader from '@icoach/nonMember/components/IconGroupHeader';
import { NON_MEMBER_PROSPECT_OVERVIEW_ROUTER } from '@icoach/router/NonMemberRouter';
import { NON_MEMBER_CUSTOMER_ADD_ROUTER } from '@icoach/router/NonMemberRouter';
import { Typography, Button, TextField, SelectField, MenuItem, Stack, Chip } from '@common/components/';
import { ReadTextField } from '@icoach/components/';
import { refIsRequiredError } from '@util/utils';
import { displayDateText } from '@util/moment';
import useCustomerApi from '@apis/useCustomerApi';

const ProspectInformationBox = React.forwardRef((props, ref) => {
    const { sourceData = {} } = props;
    const { cellPhone, customerID, customerName, introducer, notes, requiredType, sourceType, employeeID, resource, isValid, registrationDate } = sourceData;
    const { putCustomersInfoApi } = useCustomerApi();
    const history = useHistory();
    const nameRef = useRef(null);
    const phoneRef = useRef(null);
    const originRef = useRef(null);
    const needsRef = useRef(null);
    const coachRef = useRef(null);
    const introducerRef = useRef(null);
    const noteRef = useRef(null);

    const handleBackToOverviewOnClick = () => redirectTablePage();

    const handleUpdataClick = () => {
        if (!refIsRequiredError(nameRef, phoneRef, originRef, needsRef, introducerRef, noteRef)) {
            const params = {
                name: nameRef.current.getResult(),
                cellPhone: phoneRef.current.getResult(),
                sourceType: originRef.current.getResult(),
                requiredType: needsRef.current.getResult(),
                introducer: introducerRef.current.getResult(),
                notes: noteRef.current.getResult(),
                employeeID: coachRef.current.getResult(),
            };
            putProspectDataApi(params);
        }
    };

    const redirectTablePage = () => history.push(NON_MEMBER_PROSPECT_OVERVIEW_ROUTER);

    const putProspectDataApi = async (params) => {
        const resp = await putCustomersInfoApi(customerID, params);
        if (resp) redirectTablePage();
    };

    const { coachOptions = [], sourceTypes = [], requiredTypes = [] } = resource;

    return (
        <React.Fragment>
            <Stack alignItems="center" justifyContent="space-between">
                <Stack alignItems="center">
                    <Typography variant="h5">名單資料</Typography>
                    <Chip className="ml-3" label={isValid ? '有效' : '無效'} color={isValid ? 'success' : 'warning'} />
                </Stack>
                <div>
                    <IconGroupHeader
                        viewIconArray={[
                            'appointment',
                            'contact',
                            // 'newsletter',
                            isValid ? 'invalid' : '',
                            'remove',
                        ]}
                        rowsData={[sourceData]}
                        peopleID={customerID}
                        urls={{
                            apo: `${NON_MEMBER_CUSTOMER_ADD_ROUTER}${customerID}`,
                        }}
                        dialogProps={{
                            remove: { refreshParams: ['successDelete', redirectTablePage] },
                        }}
                    />
                </div>
            </Stack>
            <Grid container spacing={3}>
                <Grid item xs={6}>
                    <TextField label="姓名" ref={nameRef} defaultValue={customerName} fullWidth required />
                </Grid>
                <Grid item xs={6}>
                    <TextField label="手機" ref={phoneRef} defaultValue={cellPhone} fullWidth required />
                </Grid>
                <Grid item xs={6}>
                    <SelectField ref={originRef} label="來源" defaultValue={sourceType || null} displayEmpty fullWidth required>
                        <MenuItem disabled value="">
                            <em>請選擇</em>
                        </MenuItem>
                        {sourceTypes.map(({ text, value, disabled }) => (
                            <MenuItem key={text} value={value} disabled={disabled}>
                                {text}
                            </MenuItem>
                        ))}
                    </SelectField>
                </Grid>
                <Grid item xs={6}>
                    <SelectField ref={needsRef} label="需求" defaultValue={requiredType || null} displayEmpty fullWidth>
                        <MenuItem disabled value="">
                            <em>請選擇</em>
                        </MenuItem>
                        {requiredTypes.map(({ text, value, disabled }) => (
                            <MenuItem value={value} key={text} disabled={disabled}>
                                {text}
                            </MenuItem>
                        ))}
                    </SelectField>
                </Grid>
                <Grid item xs={6}>
                    <TextField label="介紹人" ref={introducerRef} defaultValue={introducer} fullWidth />
                </Grid>
                <Grid item xs={6}>
                    <SelectField ref={coachRef} name="employeeID" label={'擔當'} defaultValue={employeeID || null} displayEmpty fullWidth required>
                        <MenuItem disabled value="">
                            <em>請選擇</em>
                        </MenuItem>
                        {coachOptions.map(({ text, value, disabled }) => (
                            <MenuItem value={value} key={value} disabled={disabled}>
                                {text}
                            </MenuItem>
                        ))}
                    </SelectField>
                </Grid>
                <Grid item xs={6}>
                    <ReadTextField label={'登錄日期'} textProps={{ className: 'bg-white' }}>
                        {displayDateText(registrationDate)}
                    </ReadTextField>
                </Grid>
                <Grid item xs={12}>
                    <TextField label="備註" ref={noteRef} multiline rows={4} defaultValue={notes} fullWidth />
                </Grid>
            </Grid>
            <Stack justifyContent="end" spacing={2} className="mt-4">
                <Button variant="outlined" color="secondary" width={16} onClick={handleBackToOverviewOnClick}>
                    返回名單清單頁
                </Button>
                <Button variant="contained" color="secondary" width={13} onClick={handleUpdataClick}>
                    更新資料
                </Button>
            </Stack>
        </React.Fragment>
    );
});

export default ProspectInformationBox;
