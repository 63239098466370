import { useCallback } from 'react';
import useAjax from '@apis/useAjax';
import { useSnackbar } from 'notistack';


const GET_EMPLOYEE_SCHEDULE_API = `/api/employee-schedule/`;
export const GET_EMPLOYEE_SCHEDULE_RESOURCE_API = `/api/employee-schedule/resource/`;
const POST_EMPLOYEE_SCHEDULE_COACH_SUPPORT_API = `/api/employee-schedule/coach-support/`;
const POST_EMPLOYEE_SCHEDULE_COACH_OUT_API = `/api/employee-schedule/coach-out/`;
const POST_EMPLOYEE_SCHEDULE_CIRCUIT_API = `/api/employee-schedule/circuit/`;
const POST_EMPLOYEE_SCHEDULE_APO_API = `/api/employee-schedule/apo/`;
const POST_EMPLOYEE_SCHEDULE_OTHERS_API = `/api/employee-schedule/others/`;
const DELETE_EMPLOYEE_SCHEDULE_OTHERS_API = `/api/employee-schedule/others/`;
const GET_EMPLOYEE_SCHEDULE_EMPLOYEE_API = `/api/employee-schedule/employee/`;

const useEmployeeScheduleApi = () => {
    const Ajax = useAjax();
    const { enqueueSnackbar } = useSnackbar();

    // 取得每日班表的上班與休假教練
    const getEmployeeScheduleEmployeeApi = useCallback(
        async (params) => {
            const resp = await Ajax.get(GET_EMPLOYEE_SCHEDULE_EMPLOYEE_API, params);
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    // 取得每日班表
    const getEmployeeScheduleDataApi = useCallback(
        async (params) => {
            const resp = await Ajax.get(GET_EMPLOYEE_SCHEDULE_API, params);
            if (!resp) {
                enqueueSnackbar('讀取每日班表資料失敗,請重新整理', { variant: 'error' });
            }
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    // 取得教練清單(僅取得教練內容)
    const getEmployeeScheduleResourceDataApi = useCallback(
        async (params) => {
            const resp = await Ajax.get(GET_EMPLOYEE_SCHEDULE_RESOURCE_API, params);
            if (!resp) {
                enqueueSnackbar('讀取教練清單資料失敗,請重新整理', { variant: 'error' });
            }
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    // 儲存班表教練(支援人力)
    const postEmployeeScheduleCoachSupportDataApi = useCallback(
        async (params) => {
            const resp = await Ajax.post(POST_EMPLOYEE_SCHEDULE_COACH_SUPPORT_API, params);
            if (!resp) {
                enqueueSnackbar('儲存支援教練失敗,請重新再操作一次', { variant: 'error' });
            }
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    // 儲存班表教練(請假、外部支援)
    const postEmployeeScheduleCoachOutDataApi = useCallback(
        async (params) => {
            const resp = await Ajax.post(POST_EMPLOYEE_SCHEDULE_COACH_OUT_API, params);
            if (!resp) {
                enqueueSnackbar('儲存請假、外部支援教練失敗,請重新再操作一次', {
                    variant: 'error',
                });
            }
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    // 儲存班表教練(環狀運動教練)
    const postEmployeeScheduleCircuitDataApi = useCallback(
        async (params) => {
            const resp = await Ajax.post(POST_EMPLOYEE_SCHEDULE_CIRCUIT_API, params);
            if (!resp) {
                enqueueSnackbar('儲存環狀運動教練失敗,請重新再操作一次', { variant: 'error' });
            }
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    // 儲存班表預約服務教練(銷售、服務)
    const postEmployeeScheduleApoDataApi = useCallback(
        async (params) => {
            const resp = await Ajax.post(POST_EMPLOYEE_SCHEDULE_APO_API, params);
            if (resp) {
                enqueueSnackbar('修改銷售&服務成功', { variant: 'success' });
            } else {
                enqueueSnackbar('修改銷售&服務失敗,請重新再操作一次', { variant: 'error' });
            }
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    // 儲存班表預約服務教練(其他)
    const postEmployeeScheduleOthersDataApi = useCallback(
        async (params) => {
            const resp = await Ajax.post(POST_EMPLOYEE_SCHEDULE_OTHERS_API, params);
            if (resp) {
                enqueueSnackbar('儲存服務教練成功', { variant: 'success' });
            } else {
                enqueueSnackbar('儲存服務教練(其他)失敗,請重新再操作一次', { variant: 'error' });
            }
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    // 刪除服務教練
    const deleteEmployeeScheduleOthersDataApi = useCallback(
        async (params) => {
            const resp = await Ajax.delete(DELETE_EMPLOYEE_SCHEDULE_OTHERS_API, params);
            if (resp) {
                enqueueSnackbar('刪除服務教練成功', { variant: 'success' });
            } else {
                enqueueSnackbar('刪除失敗,請重新再操作一次', { variant: 'error' });
            }
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    return {
        getEmployeeScheduleEmployeeApi,
        getEmployeeScheduleDataApi,
        getEmployeeScheduleResourceDataApi,
        postEmployeeScheduleCoachSupportDataApi,
        postEmployeeScheduleCoachOutDataApi,
        postEmployeeScheduleCircuitDataApi,
        postEmployeeScheduleApoDataApi,
        postEmployeeScheduleOthersDataApi,
        deleteEmployeeScheduleOthersDataApi,
    };
};

export default useEmployeeScheduleApi;
