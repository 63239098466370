import React, { useState, useRef, useImperativeHandle } from 'react';
import { v4 as uuid } from 'uuid';
import _ from 'lodash';
import clsx from 'clsx';
import { Stack, SelectField, MenuItem, TextField, IconButton, Button, Alert, Typography, Form, Tooltip, Box } from '@common/components/';
import { Close as CloseIcon, Minus as MinusIcon } from '@common/SvgIcon/';
import Tablenization from '@icoach/components/Tablenization';
import { matchOptionsText } from '@util/utils';
import useMessageDialog from '@util/hook/useMessageDialog';
import useEmployeeScheduleApi from '@apis/useEmployeeScheduleApi';

const OtherMatterViewItemRowLabel = [
    {
        cellKey: 'date',
        headerLabel: '時間',
        width: 16,
    },
    {
        cellKey: 'employeeName',
        headerLabel: '擔當',
        align: 'center',
        width: 8,
        maxWidth: 10,
    },
    {
        cellKey: 'otherTask',
        headerLabel: '備註',
        align: 'center',
        width: 32,
        maxWidth: 32,
        formatCell: (value) => {
            return (
                <Typography component="div" className="white-space-normal-wrap">
                    {value}
                </Typography>
            );
        },
    },
];

// 顯示
const EmployeeOtherMatterViewItem = (props) => {
    const setMessageDialog = useMessageDialog();
    const { employeeName, otherTask, onDeleteClick: onDeleteClickProps, employeeID, scheduleID, scheduleDate, scheduleTime } = props;

    const handleDeleteClick = () => {
        const params = {
            employeeID,
            scheduleID,
            scheduleDate,
            scheduleTime,
        };
        setMessageDialog({
            open: true,
            title: '刪除其他',
            buttonText: '刪除',
            dialogPaperProps: {
                className: 'wx-65rem',
            },
            MsgComp: () => (
                <Box className="ml-4 mr-4">
                    <Tablenization cardProps={{ className: 'theme-gray' }}>
                        <Tablenization.TableRowHeader headerRow={OtherMatterViewItemRowLabel} />
                        <Tablenization.TableRowBody
                            headerRow={OtherMatterViewItemRowLabel}
                            rowsData={[
                                {
                                    date: `${scheduleDate} ${scheduleTime}`,
                                    employeeName,
                                    otherTask,
                                },
                            ]}
                        />
                    </Tablenization>
                </Box>
            ),
            onAsyncConfirm: (e, onClose) => {
                onDeleteClickProps && onDeleteClickProps(params, scheduleID);
                onClose();
            },
            buttonColor: 'error',
        });
    };

    return (
        <li>
            <Stack alignItems="center" justifyContent="space-evenly">
                <Typography component="div" className="employee-other-matter-text">
                    {employeeName}
                </Typography>
                <Tooltip title={otherTask}>
                    <Typography component="div" className="employee-other-matter-text">
                        {otherTask}
                    </Typography>
                </Tooltip>
                <IconButton>
                    <CloseIcon color="error" onClick={handleDeleteClick} />
                </IconButton>
            </Stack>
        </li>
    );
};

// 輸入框
const EmployeeOtherMatterInputItem = React.forwardRef((props, ref) => {
    const {
        employeeID: employeeIDProps,
        otherTask: otherTaskProps,
        options,
        scheduleDate,
        scheduleTime,
        onRemoveTemporaryItem: onRemoveTemporaryItemProps,
        index: indexProps,
    } = props;
    const employeeIDRef = useRef(null);
    const otherTaskRef = useRef(null);

    const getResult = () => {
        const employeeID = employeeIDRef.current.getResult();
        const otherTask = otherTaskRef.current.getResult();
        if (!employeeID || !otherTask) return null;
        return {
            scheduleID: 0,
            employeeID,
            otherTask,
            scheduleDate,
            scheduleTime,
        };
    };

    const handleRemoveTemporaryItemClick = () => {
        onRemoveTemporaryItemProps && onRemoveTemporaryItemProps(indexProps);
    };

    useImperativeHandle(
        ref,
        () => ({
            getResult,
        }),
        // eslint-disable-next-line
        [],
    );

    return (
        <li>
            <Stack alignItems="center" justifyContent="space-evenly">
                <SelectField ref={employeeIDRef} defaultValue={employeeIDProps} style={{ width: '15rem' }} fullWidth={false} displayEmpty>
                    <MenuItem value="" disabled>
                        <em>請選擇</em>
                    </MenuItem>
                    {options.map(({ text, value, disabled }) => (
                        <MenuItem value={value} disabled={disabled} key={value}>
                            {text}
                        </MenuItem>
                    ))}
                </SelectField>
                <TextField defaultValue={otherTaskProps} ref={otherTaskRef} style={{ width: '15rem' }} />
                <IconButton onClick={handleRemoveTemporaryItemClick}>
                    <MinusIcon />
                </IconButton>
            </Stack>
        </li>
    );
});

// 其他班表
const EmployeeScheduleOtherMatter = React.forwardRef((props, ref) => {
    const { options, sourceList: sourceListProp = [], scheduleDate, scheduleTime } = props;
    const [sourceList, setSourceList] = useState([...sourceListProp]);
    const { postEmployeeScheduleOthersDataApi, deleteEmployeeScheduleOthersDataApi } = useEmployeeScheduleApi();
    const employeeOtherMatterInputItemRef = useRef(null);

    // 畫面新增按鈕  不打api
    const handleTemporaryEmployeeOnClick = () => {
        const len = sourceList.length;
        if (!sourceList[len - 1] || sourceList[len - 1].scheduleID !== 0) {
            setSourceList((pre) => [...pre, { scheduleID: 0, employeeID: 0, otherTask: null }]);
        } else if (employeeOtherMatterInputItemRef.current && typeof employeeOtherMatterInputItemRef.current.getResult === 'function') {
            const params = employeeOtherMatterInputItemRef.current.getResult();
            params && postOthersApi(params);
        }
    };

    // 刪除畫面上的假資料
    const handleRemoveTemporaryClick = (num) => {
        const newList = [...sourceList];
        newList.splice(num, 1);
        setSourceList(newList);
    };

    // 刪除教練
    const handleDeleteEmployeeMatters = (params, sID) => {
        deleteOthersApi(params, sID);
    };

    // 新增教練Api
    const postOthersApi = async (params) => {
        const resp = await postEmployeeScheduleOthersDataApi(params);
        if (resp) {
            setSourceList((prev) => {
                const newList = prev.filter((target) => target.scheduleID);
                return [
                    ...newList,
                    {
                        employeeID: params.employeeID,
                        otherTask: params.otherTask,
                        scheduleID: resp,
                    },
                ];
            });
        }
    };

    // 刪除教練Api
    const deleteOthersApi = async (params, sID) => {
        const resp = await deleteEmployeeScheduleOthersDataApi(params);
        if (resp) {
            setSourceList((prev) => prev.filter((target) => String(target.scheduleID) !== String(sID)));
        }
    };

    const employeesOptions = sourceList.map(({ employeeID }) => ({ value: String(employeeID) })).filter(({ value }) => String(value) !== '0');
    return (
        <Form onSubmit={handleTemporaryEmployeeOnClick}>
            <ul className="employee-other-matter">
                {sourceList.map(({ employeeID = '', scheduleID = 0, otherTask }, index) => {
                    return (
                        <React.Fragment key={scheduleID || uuid()}>
                            {scheduleID ? (
                                <EmployeeOtherMatterViewItem
                                    employeeID={employeeID}
                                    scheduleID={scheduleID}
                                    scheduleDate={scheduleDate}
                                    scheduleTime={scheduleTime}
                                    employeeName={matchOptionsText(options, employeeID, '未設定')}
                                    onDeleteClick={handleDeleteEmployeeMatters}
                                    otherTask={otherTask}
                                />
                            ) : (
                                <EmployeeOtherMatterInputItem
                                    ref={employeeOtherMatterInputItemRef}
                                    options={_.xorBy(options, employeesOptions, 'value')}
                                    scheduleID={scheduleID}
                                    employeeID={employeeID}
                                    otherTask={otherTask}
                                    scheduleDate={scheduleDate}
                                    scheduleTime={scheduleTime}
                                    onRemoveTemporaryItem={handleRemoveTemporaryClick}
                                    index={index}
                                />
                            )}
                        </React.Fragment>
                    );
                })}
                <li>
                    <Stack alignItems="center" className={clsx(!_.isEmpty(sourceList) && 'mt-2')} spacing="1">
                        {_.isEmpty(sourceList) ? (
                            <Alert severity="info" color="gray" className="flex-grow-1">
                                尚無安排
                            </Alert>
                        ) : null}
                        <Button type="submit" variant="text" className={clsx(!_.isEmpty(sourceList) && 'ml-auto')}>
                            新增
                        </Button>
                    </Stack>
                </li>
            </ul>
        </Form>
    );
});

export default EmployeeScheduleOtherMatter;
