import React, { useRef, useState, useEffect } from 'react';
import { Dialog, DialogContent, Grid } from '@mui/material';
import GradientColorHeader from '@icoach/components/dialog/GradientColorHeader';
import { TextField, SelectField, FormLabel, MenuItem, Button, Stack, DateField } from '@common/components/';
import { refIsRequiredError } from '@util/utils';
import { parseDate } from '@util/moment';
import { useCustomerContactRecordApi } from '@apis/useContactRecordApi';

// 新增聯繫
const NoMemberContactDialog = (props) => {
    const { open, onClose: onCloseProps, peopleID: peopleIDProps, apiParams = {}, setContactState } = props;
    const { getCustomerContactRecordOptionsApi, postCustomerContactRecordDataApi } = useCustomerContactRecordApi();
    const [options, setOptions] = useState({});
    const [reasonIsRequired, setReasonRequired] = useState(false);
    const contactDateRef = useRef(null);
    const contactReasonTypeRef = useRef(null);
    const contactResultTypeRef = useRef(null);
    const employeeIDRef = useRef(null);
    const notesRef = useRef(null);

    const handleCloseClick = (e) => onCloseProps && onCloseProps(null);

    const handleAddClick = (e) => {
        if (!refIsRequiredError(contactDateRef, contactReasonTypeRef, contactResultTypeRef, employeeIDRef, notesRef)) {
            const params = {
                employeeID: employeeIDRef.current.getResult(),
                contactDate: parseDate(contactDateRef.current.getResult()),
                contactResultType: contactResultTypeRef.current.getResult(),
                contactReasonType: contactReasonTypeRef.current.getResult(),
                notes: notesRef.current.getResult(),
                ...apiParams,
            };
            postContactRecordApi(params, peopleIDProps);
        }
    };

    const postContactRecordApi = async (params, targetID) => {
        const resp = await postCustomerContactRecordDataApi(params, targetID);
        if (resp) {
            onCloseProps && onCloseProps(null);
            if (typeof setContactState === 'function') setContactState(targetID, resp);
            // refresh && refresh.current && refresh.current(...refreshParams);
        }
    };

    const doInitOptionsApi = async () => {
        const resp = await getCustomerContactRecordOptionsApi();
        resp && setOptions(resp);
    };

    const handleContactResultChange = (e, node) => {
        let isRequired = false;
        if (String(node.props.value) === '1') isRequired = true;
        setReasonRequired(isRequired);
    };

    useEffect(
        () => {
            if (open) doInitOptionsApi();
        },
        // eslint-disable-next-line
        [open]
    );

    const { contactResultTypes: contactResultOption = [], contactReasonTypes: contactReasonOption = [], coaches: employeeOptions = [] } = options;

    return (
        <Dialog open={open} fullWidth>
            <GradientColorHeader onClose={handleCloseClick}>新增聯繫</GradientColorHeader>
            <DialogContent className="mt-3">
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <SelectField label="擔當" ref={employeeIDRef} displayEmpty fullWidth required>
                            <MenuItem disabled value={''}>
                                <em>請選擇</em>
                            </MenuItem>
                            {employeeOptions.map(({ text, value, disabled }) => (
                                <MenuItem value={value} key={value} disabled={disabled}>
                                    {text}
                                </MenuItem>
                            ))}
                        </SelectField>
                    </Grid>
                    <Grid item xs={6}>
                        <DateField ref={contactDateRef} defaultValue={parseDate()} label="聯繫日期" required fullWidth />
                    </Grid>
                    <Grid item xs={6}>
                        <SelectField label="確認電話結果" ref={contactResultTypeRef} onChange={handleContactResultChange} displayEmpty fullWidth required>
                            <MenuItem disabled value={''}>
                                <em>請選擇</em>
                            </MenuItem>
                            {contactResultOption.map(({ text, value, disabled }) => (
                                <MenuItem value={value} key={value} disabled={disabled}>
                                    {text}
                                </MenuItem>
                            ))}
                        </SelectField>
                    </Grid>
                    <Grid item xs={6}>
                        <SelectField label="聯繫內容" ref={contactReasonTypeRef} displayEmpty fullWidth required={reasonIsRequired}>
                            <MenuItem disabled value={''}>
                                <em>請選擇</em>
                            </MenuItem>
                            {contactReasonOption.map(({ text, value, disabled }) => (
                                <MenuItem value={value} key={value} disabled={disabled}>
                                    {text}
                                </MenuItem>
                            ))}
                        </SelectField>
                    </Grid>
                    <Grid item xs={12}>
                        <Stack direction="column">
                            <FormLabel>聯繫備註</FormLabel>
                            <TextField rows={3} multiline ref={notesRef} />
                        </Stack>
                    </Grid>
                    <Grid item xs={12}>
                        <Stack justifyContent="end">
                            <Button onClick={handleAddClick} variant="contained" color="secondary">
                                新增
                            </Button>
                        </Stack>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};

export default NoMemberContactDialog;
