// date range ref 取值
export const getDateRangeResult = (refObj) => {
    const result = {};
    for (let key in refObj) {
        let val = refObj[key].getResult();

        for (let k in val) {
            let _key = '',
                _k = k;
            if (key !== '_') {
                _key = _key + key;
                _k = k.replace(/^[a-z]/, (s) => s.toUpperCase());
            }
            _key = _key + _k;
            result[_key] = val[k];
        }
    }
    return result;
};

// checkbox or radio Field 取值
export const getRadioValue = (val, type = 'value') => {
    return val.find((v) => v.checked)?.[type] ?? '';
};

export const getCheckboxValue = (val, { type = 'value', isMultiple = true }) => {
    if (!isMultiple) {
        return val.find((v) => v.checked)?.[type] ?? false;
    }

    return val.map((item) => item[type]);
};

export const setCheckboxValue = (val) => {
    return Array.isArray(val) ? val : [val];
};

/**
 * 判斷是否為 手動移入
 * @param {Number|String} tpye
 * @param {Boolean?} isManual
 * @returns {Boolean}
 */
export const checkIsManualTransferIn = (tpye, isManual) => String(tpye) === '11' || isManual;
