import React from 'react';

const useGroupCheckbox = (resource) => {
    const selectedAllRef = React.useRef(null);
    const optionRef = React.useRef([]);
    const indeterminateRef = React.useRef(null);

    const checkAllChange = React.useCallback(
        (e, checked) => {
            if (optionRef.current) {
                optionRef.current.forEach((target) => {
                    if (!target.disabled) {
                        target.setCheckedState(checked);
                    }
                });
                indeterminateRef.current.setIndeterminate(false);
            }
        },
        // eslint-disable-next-line
        []
    );

    const checkOptChange = React.useCallback(
        (e) => {
            if (optionRef.current) {
                const noChecked = optionRef.current.every((target) => !target.checked);
                const isIndeterminate = !optionRef.current.every((target) => target.checked);
                if (isIndeterminate && noChecked) {
                    indeterminateRef.current.setIndeterminate(false);
                    selectedAllRef.current.setCheckedState(false);
                } else if (isIndeterminate || noChecked) {
                    indeterminateRef.current.setIndeterminate(true);
                    selectedAllRef.current.setCheckedState(false);
                } else {
                    indeterminateRef.current.setIndeterminate(false);
                    selectedAllRef.current.setCheckedState(true);
                }
            }
        },
        // eslint-disable-next-line
        []
    );

    const changeIndeterminate = React.useCallback(
        () => {
            if (indeterminateRef.current) {
                let checkedOptionsLength = optionRef.current.filter((item) => item.checked).length;
                let showIndeterminate = Boolean(checkedOptionsLength) && checkedOptionsLength < optionRef.current.length;
                indeterminateRef.current.setIndeterminate(showIndeterminate);
            }
        },
        // eslint-disable-next-line
        []
    );

    const clearAllChecked = React.useCallback(
        () => {
            optionRef.current.forEach((target) => {
                if (!target.disabled) {
                    target.setCheckedState(false);
                }
            });
            indeterminateRef.current.setIndeterminate(false);
        },
        // eslint-disable-next-line
        []
    );

    optionRef.current = [];

    React.useEffect(() => {
        if (indeterminateRef.current) indeterminateRef.current.setIndeterminate(false);
        if (selectedAllRef.current) selectedAllRef.current.setCheckedState(false);
    }, [resource]);

    return React.useMemo(
        () => {
            return [checkAllChange, checkOptChange, selectedAllRef, optionRef, indeterminateRef, changeIndeterminate, clearAllChecked];
        },
        // eslint-disable-next-line
        [checkAllChange, checkOptChange, changeIndeterminate]
    );
};

export default useGroupCheckbox;
