import { PointsProductGiftPage, PointsRedeemPage } from '@icoach/points/';
import { POINTS_POINTS_REDEEM_OVERVIEW_ROUTER, POINTS_PRODUCT_GIFT_OVERVIEW_ROUTER } from '@icoach/router/routerPath';

const inbodyRouter = [
    {
        description: '點數管理',
        frontKey: 'C11X01',
    },
    {
        description: '會員點數總覽',
        static: true,
        path: POINTS_POINTS_REDEEM_OVERVIEW_ROUTER,
        href: POINTS_POINTS_REDEEM_OVERVIEW_ROUTER,
        as: PointsRedeemPage,
        frontKey: 'C11X02',
    },
    {
        description: '贈品兌換總覽',
        static: true,
        path: POINTS_PRODUCT_GIFT_OVERVIEW_ROUTER,
        href: POINTS_PRODUCT_GIFT_OVERVIEW_ROUTER,
        as: PointsProductGiftPage,
        frontKey: 'C11X03',
    },
];

export default inbodyRouter;
