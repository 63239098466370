import Mock from 'mockjs';
import { successApi } from './respondData';

export const withMockAppSystemProvider = () => {
    Mock.mock(/^\/api\/system\/build-time$/, 'get', () => {
        let sourceData = { ...successApi };
        sourceData.result = {
            version: '2.0.0.0',
            utc: new Date().toISOString(),
        };
        return sourceData;
    });
    Mock.setup({ timeout: '200-800' });
};
