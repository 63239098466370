import React, { useRef, useState, useImperativeHandle } from 'react';
import { Grid } from '@mui/material';
import { TextField, SelectField, FormLabel, MenuItem, Button, Stack, DateField } from '@common/components/';
import { parseDate } from '@util/moment';
import { refIsRequiredError } from '@util/utils';

const ContactDailogContentForm = React.forwardRef((props, ref) => {
    const { sourceData, options } = props;
    const [reasonIsRequired, setReasonRequired] = useState(false);
    const employeeIDRef = useRef(null);
    const contactDateRef = useRef(null);
    const contactResultTypeRef = useRef(null);
    const contactReasonTypeRef = useRef(null);
    const notesRef = useRef(null);
    const { contactResultTypes: contactResultOption = [], contactReasonTypes: contactReasonOption = [], coaches: employeeOptions = [] } = options || {};
    const { employeeID, contactDate, contactResultType, contactReasonType, notes } = sourceData || {};

    const getParams = () => {
        return {
            employeeID: employeeIDRef.current.getResult(),
            contactDate: parseDate(contactDateRef.current.getResult()),
            contactResultType: contactResultTypeRef.current.getResult(),
            contactReasonType: contactReasonTypeRef.current.getResult(),
            notes: notesRef.current.getResult(),
        };
    };

    const handleContactResultChange = (e, node) => {
        let isRequired = false;
        if (String(node.props.value) === '1') isRequired = true;
        setReasonRequired(isRequired);
    };

    useImperativeHandle(
        ref,
        () => ({
            getResult: () => {
                return getParams();
            },
            isError: () => {
                return refIsRequiredError(employeeIDRef, contactDateRef, contactResultTypeRef, contactReasonTypeRef, notesRef);
            },
        }),
        // eslint-disable-next-line
        []
    );

    return (
        <Grid container spacing={2}>
            <Grid item xs={6}>
                <SelectField label="擔當" defaultValue={employeeID || null} ref={employeeIDRef} displayEmpty fullWidth required>
                    <MenuItem value={''} disabled>
                        <em>請選擇</em>
                    </MenuItem>
                    {employeeOptions.map(({ text, value, disabled }) => (
                        <MenuItem value={value} key={value} disabled={disabled}>
                            {text}
                        </MenuItem>
                    ))}
                </SelectField>
            </Grid>
            <Grid item xs={6}>
                <DateField ref={contactDateRef} defaultValue={contactDate ? parseDate(contactDate) : parseDate()} label="聯繫日期" required fullWidth />
            </Grid>
            <Grid item xs={6}>
                <SelectField
                    label="確認電話結果"
                    defaultValue={contactResultType}
                    ref={contactResultTypeRef}
                    onChange={handleContactResultChange}
                    displayEmpty
                    fullWidth
                    required
                >
                    <MenuItem value={''} disabled>
                        <em>請選擇</em>
                    </MenuItem>
                    {contactResultOption.map(({ text, value, disabled }) => (
                        <MenuItem value={value} key={value} disabled={disabled}>
                            {text}
                        </MenuItem>
                    ))}
                </SelectField>
            </Grid>
            <Grid item xs={6}>
                <SelectField
                    key={reasonIsRequired}
                    label="聯繫內容"
                    defaultValue={contactReasonType}
                    ref={contactReasonTypeRef}
                    displayEmpty
                    fullWidth
                    required={reasonIsRequired}
                >
                    <MenuItem value={''} disabled>
                        <em>請選擇</em>
                    </MenuItem>
                    {contactReasonOption.map(({ text, value, disabled }) => (
                        <MenuItem value={value} key={value} disabled={disabled}>
                            {text}
                        </MenuItem>
                    ))}
                </SelectField>
            </Grid>
            <Grid item xs={12}>
                <Stack direction="column">
                    <FormLabel>聯繫備註</FormLabel>
                    <TextField defaultValue={notes} rows={3} multiline ref={notesRef} />
                </Stack>
            </Grid>
            <Grid item xs={12}>
                <Stack justifyContent="end">
                    <Button type="submit" variant="contained" color="secondary">
                        新增
                    </Button>
                </Stack>
            </Grid>
        </Grid>
    );
});

export default ContactDailogContentForm;
