import React from 'react';
import { Dialog, DialogContent } from '@mui/material';
import GradientColorHeader from '@icoach/components/dialog/GradientColorHeader';
import { Alert, Stack, Button, Typography } from '@common/components/';
import useCustomerManufactureApi from '@apis/useCustomerManufactureApi';
import { parseDate } from '@util/moment';

const GuestProductionDeleteDialog = (props) => {
    const { onClose: onCloseProps, open, refresh, defaultData = [] } = props;
    const { deleteCustomerManufactureApi } = useCustomerManufactureApi();

    const handleCloseClick = (e) => {
        onCloseProps(null);
    };

    const handleSendClick = (e) => {
        const params = {
            customerManufactureIDs: defaultData.map((x) => x.manufactureID),
        };
        doApi(params);
    };

    const doApi = async (params) => {
        const resp = await deleteCustomerManufactureApi(params);
        if (resp) {
            onCloseProps(null);
            refresh && refresh.current && refresh.current();
        }
    };

    const len = defaultData.length;
    return (
        <Dialog open={open} fullWidth>
            <GradientColorHeader onClose={handleCloseClick}>刪除主動顧客製造</GradientColorHeader>
            <DialogContent className="my-3">
                <Stack direction="column" spacing={3}>
                    <Alert color="error" severity="remove" variant="filled">
                        確定要刪除({len})?
                    </Alert>
                    {defaultData.map(({ manufactureDate, manufactureTimeRange, position, coachName }, _index) => {
                        return (
                            <Stack justifyContent="center" average spacing={2} key={`GuestProductionDeleteDialog_${_index}`}>
                                <Typography>{parseDate(manufactureDate, 'YYYY-MM-DD')}</Typography>
                                <Typography>{manufactureTimeRange}</Typography>
                                <Typography style={{ width: 250 }}>{position}</Typography>
                                <Typography>{coachName}</Typography>
                            </Stack>
                        );
                    })}
                    <Stack justifyContent="center">
                        <Button onClick={handleSendClick} variant="contained" width={12}>
                            送出
                        </Button>
                    </Stack>
                </Stack>
            </DialogContent>
        </Dialog>
    );
};

export default GuestProductionDeleteDialog;
