import React, { useState } from 'react';
import { Button } from '@common/components/';
import { ClubSuspensionCreateDialog } from '../dialog';

const ClubSuspensionCreateButton = React.forwardRef((props, ref) => {
    const [open, setOpen] = useState(false);

    return (
        <React.Fragment>
            <Button variant="outlined" onClick={() => setOpen(true)}>
                新增店休時間
            </Button>
            <ClubSuspensionCreateDialog open={open} onClose={() => setOpen(false)} {...props} />
        </React.Fragment>
    );
});

export default ClubSuspensionCreateButton;
