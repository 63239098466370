import React, { useEffect, useState } from 'react';
import SmsTemplateCreateButton from '@icoach/message/smsTemplate/SmsTemplateCreateButton';
import { SmsTemplateEditDialog, SmsTemplateDeleteDialog } from '@icoach/message/dialog/';
import { Typography, Box } from '@common/components/';
import { BorderColor as BorderColorIcon, Trash as TrashIcon } from '@common/SvgIcon/';
import Tablenization from '@icoach/components/Tablenization';
import useSmsTemplateApi from '@apis/useSmsTemplateApi';
import { MoreLabelBox } from '@icoach/components/';

const MoreLabel = (props) => {
    const { rowData, moreActionArray } = props;
    const moreLabelMap = {
        edit: {
            text: '編輯',
            icon: <BorderColorIcon />,
            dialogString: 'editSMSTemplate',
            Dialog: SmsTemplateEditDialog,
        },
        delete: {
            text: '刪除',
            icon: <TrashIcon />,
            dialogString: 'deleteSMSTemplate',
            Dialog: SmsTemplateDeleteDialog,
        },
    };
    return <MoreLabelBox {...props} moreActionArray={moreActionArray} moreLabelMap={moreLabelMap} rowData={rowData} />;
};

const rowLabel = [
    {
        cellKey: 'name',
        headerLabel: '標題',
        maxWidth: 20,
        width: 20,
        align: 'center',
        formatCell: (value) => {
            return <Box className="white-space-normal-wrap">{value}</Box>;
        },
    },
    {
        cellKey: 'num',
        headerLabel: '則數',
        width: 10,
        align: 'center',
    },
    {
        cellKey: 'length',
        headerLabel: '字數',
        width: 10,
        align: 'center',
    },
    {
        cellKey: 'templateText',
        headerLabel: '內容',
        maxWidth: 30,
        align: 'center',
        formatCell: (value) => {
            return (
                <div
                    style={{
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        display: '-webkit-box !important',
                        WebkitLineClamp: 2,
                        WebkitBoxOrient: 'vertical',
                        whiteSpace: 'normal',
                        textAlign: 'left',
                    }}
                >
                    {value}
                </div>
            );
        },
    },
    {
        cellKey: 'more',
        headerLabel: '',
        align: 'right',
        isIcon: true,
        formatCell: (cellValue, rowData, { onRefrash }) => {
            return <MoreLabel rowData={rowData} moreActionArray={['edit', 'delete']} onRefrash={onRefrash} />;
        },
    },
];

const SmsTemplatePage = React.forwardRef((props, ref) => {
    const [isMounted, setMounted] = useState(false);
    const [sourceData, setSourceData] = useState([]);
    const { postSmsTemplateOverviewApi } = useSmsTemplateApi();

    const doInitSmsDataApi = async () => {
        const resp = await postSmsTemplateOverviewApi();
        if (resp) {
            setSourceData(resp);
            setMounted(true);
        }
    };

    useEffect(
        () => {
            doInitSmsDataApi();
            return () => setMounted(false);
        },
        // eslint-disable-next-line
        [],
    );

    return (
        <div className="container main-container-spacing">
            <header className={'page-header'}>
                <Typography className={'page-header-title'} variant={'h3'}>
                    公版簡訊
                </Typography>
                <div className="btn-group">
                    <SmsTemplateCreateButton onRefrash={doInitSmsDataApi} />
                </div>
            </header>
            {isMounted && (
                <Tablenization>
                    <Tablenization.TableRowHeader headerRow={rowLabel} />
                    <Tablenization.TableRowBody headerRow={rowLabel} rowsData={sourceData} bodyRowHeight={100} onRefrash={doInitSmsDataApi} />
                </Tablenization>
            )}
        </div>
    );
});

export default SmsTemplatePage;
