// redux
import { combineReducers } from "redux";
// types
import { SET_LOADING_DATA } from "./types";

const moduleLoading = { show: false, count:0 }

export function LoadingData(state = { Data: {...moduleLoading} }, action) {
    switch (action.type) {
        case SET_LOADING_DATA:
            if (action.Data === null) {
                action.Data = {...moduleLoading};
            }

            return {
                Data: {
                    show: action.Data,
                    count: (action.Data ? state.Data.count + 1 : state.Data.count - 1)
                }
            };

        default:
            return state;
    }
};

const Reducer = combineReducers({
    LoadingData,
});

export default Reducer;