import Mock from 'mockjs';
import { successApi } from './respondData';

// 來店會員
export const withMockMemberProvider = () => {
    Mock.mock(/^\/api\/members\/[1-100]\/tip$/, 'get', () => {
        let sourceData = { ...successApi };
        sourceData.result = MemberIDTipInfo;
        return sourceData;
    });

    Mock.mock(/^\/api\/members\/[\d]+\/profile$/, 'get', () => {
        let sourceData = { ...successApi };
        sourceData.result = membersIDProfile;
        return sourceData;
    });

    Mock.setup({ timeout: '200-800' });
};

const membersIDProfile = {
    memberID: 64,
    clubID: 1,
    memberNo: 'A000000046',
    memberName: '獅子丸',
    nickName: '子丸',
    picturePath: null,
    memberCardNo: '131231',
    reminderConditionType: 768,
    reminderOrderType: 0,
    xFactorNotes: null,
    agreedNotes: null,
    guidanceNotes: null,
    specialCondition: null,
    memo: null,
    membershipStatus: 1,
    createdDate: '2022-08-01T00:00:00',
    weeklyWorkoutQuantity: 0,
    monthlyWorkoutQuantity: 0,
    totalWorkoutQuantity: 0,
    monthlyWorkoutFrequency: '0-0-0-0',
    measureDay: 22,
    lastMeasureDate: null,
    hundredTShirtSize: '',
    membershipText: '一般-信用卡自動扣款-二年約-$1380',
    membershipBar: {
        membershipID: 60,
        startDate: '2022-08-27T00:00:00',
        endDate: '2024-08-31T23:59:59',
        membershipTermText: '二年約',
        ranges: [
            {
                type: 1,
                typeName: '正常',
                startDate: '2022-08-27T00:00:00',
                endDate: '2024-08-31T23:59:59',
                isShowToolTip: false,
            },
        ],
    },
    proteinPush: {
        pushStatus: 4,
        reasonType: 0,
        reasonText: null,
    },
    probioticsPush: {
        pushStatus: 4,
        reasonType: 0,
        reasonText: null,
    },
    todoTasks: [
        {
            taskID: 286,
            taskType: 11,
            reminderConditionType: 0,
            taskTitle: '超危險',
            isEnabled: true,
            isCompleted: false,
            completedDate: null,
        },
        {
            taskID: 335,
            taskType: 11,
            reminderConditionType: 0,
            taskTitle: '危險',
            isEnabled: true,
            isCompleted: false,
            completedDate: null,
        },
    ],
    serviceTasks: [],
    resource: {
        pushStatusOptions: [
            {
                text: '無',
                value: '0',
                disabled: true,
            },
            {
                text: '訂購中',
                value: '1',
                disabled: true,
            },
            {
                text: '暫停',
                value: '2',
                disabled: true,
            },
            {
                text: '退訂',
                value: '3',
                disabled: true,
            },
            {
                text: '未訂購',
                value: '4',
                disabled: false,
            },
            {
                text: '永久不推',
                value: '9',
                disabled: false,
            },
        ],
        pushReasonTypeOptions: [
            {
                text: '無',
                value: '0',
                disabled: false,
            },
            {
                text: '價格較高',
                value: '1',
                disabled: false,
            },
            {
                text: '先以食物攝取優先',
                value: '2',
                disabled: false,
            },
            {
                text: '身體健康問題(如疾病等)',
                value: '3',
                disabled: false,
            },
            {
                text: '不喜歡奶味',
                value: '4',
                disabled: false,
            },
            {
                text: '家人反對飲用',
                value: '5',
                disabled: false,
            },
            {
                text: '不喝冷飲',
                value: '6',
                disabled: false,
            },
            {
                text: '不喝沖泡飲',
                value: '7',
                disabled: false,
            },
            {
                text: '無信用卡',
                value: '8',
                disabled: false,
            },
            {
                text: '其他',
                value: '99',
                disabled: false,
            },
        ],
        hundredTShirtSizeTextOptions: [
            {
                text: '未定',
                value: '未定',
                disabled: false,
            },
            {
                text: 'XXS',
                value: 'XXS',
                disabled: false,
            },
            {
                text: 'XS',
                value: 'XS',
                disabled: false,
            },
            {
                text: 'S',
                value: 'S',
                disabled: false,
            },
            {
                text: 'M',
                value: 'M',
                disabled: false,
            },
            {
                text: 'L',
                value: 'L',
                disabled: false,
            },
            {
                text: 'XL',
                value: 'XL',
                disabled: false,
            },
            {
                text: '2XL',
                value: '2XL',
                disabled: false,
            },
            {
                text: '3XL',
                value: '3XL',
                disabled: false,
            },
            {
                text: '4XL',
                value: '4XL',
                disabled: false,
            },
            {
                text: 'XS/S',
                value: 'XS/S',
                disabled: false,
            },
            {
                text: 'M/L',
                value: 'M/L',
                disabled: false,
            },
        ],
        serviceTodoTextOptions: [
            {
                text: '無',
                value: '無',
                disabled: false,
            },
            {
                text: '發日記',
                value: '發日記',
                disabled: false,
            },
            {
                text: '收日記',
                value: '收日記',
                disabled: false,
            },
            {
                text: '檢視日記',
                value: '檢視日記',
                disabled: false,
            },
            {
                text: '邀約講座',
                value: '邀約講座',
                disabled: false,
            },
            {
                text: '推進服飾',
                value: '推進服飾',
                disabled: false,
            },
            {
                text: '推進手套',
                value: '推進手套',
                disabled: false,
            },
            {
                text: '推進內衣',
                value: '推進內衣',
                disabled: false,
            },
            {
                text: '其他',
                value: '其他',
                disabled: false,
            },
        ],
    },
};

const MemberIDTipInfo = {
    memberID: 13,
    clubID: 1,
    memberNo: 'A000000002',
    memberName: '同嘉欣',
    nickName: '加薪',
    picturePath: null,
    memberCardNo: 'CardNo004',
    xFactorNotes: null,
    agreedNotes: null,
    guidanceNotes: null,
    specialCondition: null,
    memo: null,
    membershipStatus: 1,
    weeklyWorkoutQuantity: 0,
    monthlyWorkoutQuantity: 0,
    totalWorkoutQuantity: 0,
    todoTasks: [],
    serviceTasks: [],
    resource: {
        serviceTodoTextOptions: [
            {
                text: '發日記',
                value: '發日記',
                disabled: false,
            },
            {
                text: '收日記',
                value: '收日記',
                disabled: false,
            },
            {
                text: '檢視日記',
                value: '檢視日記',
                disabled: false,
            },
            {
                text: '邀約講座',
                value: '邀約講座',
                disabled: false,
            },
            {
                text: '推進服飾',
                value: '推進服飾',
                disabled: false,
            },
            {
                text: '推進手套',
                value: '推進手套',
                disabled: false,
            },
            {
                text: '推進內衣',
                value: '推進內衣',
                disabled: false,
            },
            {
                text: '其他',
                value: '其他',
                disabled: false,
            },
        ],
    },
};
