import React, { useRef, useState } from 'react';
import { Grid } from '@mui/material';
import { DateIntervalField, Calendar, Button, Stack, Typography, TextField } from '@common/components/';
import { parseDate, getAddDays } from '@util/moment';

const DateIntervalFieldTest = React.forwardRef((props, ref) => {
    const calRef = useRef(null);
    const dateRangeRef = useRef(null);
    const [muiDate, setMuiDate] = useState('');
    const handleClick = () => {
        setMuiDate(calRef.current.getResult().join(','));
    };

    return (
        <div className="main-container-spacing">
            <Grid container>
                <Grid item xs={6}>
                    <DateIntervalField />
                </Grid>
                <Grid item xs={6}></Grid>
                <Grid item xs={4}>
                    <Stack className="mt-3" direction="column" justifyContent="center" alignItems="center" spacing={2}>
                        <Typography component="p" variant="h5">
                            多選日曆
                        </Typography>
                        <Calendar ref={calRef} hasHover={true} isChecked={true} />
                        <Button onClick={handleClick} variant="contained">
                            點我
                        </Button>
                    </Stack>
                </Grid>
                <Grid item xs={6}>
                    <TextField key={muiDate} className="mt-3" defaultValue={muiDate} rows="16" multiline fullWidth />
                </Grid>
                <Grid item xs={2}></Grid>
                <Grid item xs={6}>
                    <DateIntervalField
                        ref={dateRangeRef}
                        label="時間區間"
                        mask="__/__"
                        views={['month', 'day']}
                        format="MM/DD"
                        openTo="month"
                        startDateProps={{ defaultValue: parseDate(new Date(), 'MM/DD') }}
                        endDateProps={{ defaultValue: parseDate(getAddDays(new Date(), 14), 'MM/DD') }}
                        required
                        fullWidth
                    />
                </Grid>
            </Grid>
        </div>
    );
});

export default DateIntervalFieldTest;
