import React from 'react';
import FormControlContext from '../formControl/FormControlContext';
import Typography from '../typography/Typography';
import PropTypes from 'prop-types';
import clsx from 'clsx';

const staticClass = `input-adornment`;
const positionMap = ['start', 'end'];

const InputAdornment = (props) => {
    const {
        children,
        className,
        disablePointerEvents = false,
        disableTypography = false,
        position = 'start',
    } = props;

    let classNames = staticClass;
    if (positionMap.indexOf(position) !== -1)
        classNames = clsx(classNames, `${staticClass}-position-${position}`);
    if (disablePointerEvents) classNames = clsx(classNames, `${staticClass}-disablePointerEvents`);

    return (
        <FormControlContext.Provider value={null}>
            <div className={clsx(classNames, className)}>
                {typeof children === 'string' && !disableTypography ? (
                    <Typography color="secondary" component="span">
                        {children}
                    </Typography>
                ) : (
                    <React.Fragment>
                        {/* To have the correct vertical alignment baseline */}
                        {position === 'start' ? (
                            /* notranslate needed while Google Translate will not fix zero-width space issue */
                            /* eslint-disable-next-line react/no-danger */
                            <span
                                className="notranslate"
                                dangerouslySetInnerHTML={{ __html: '&#8203;' }}
                            />
                        ) : null}
                        {children}
                    </React.Fragment>
                )}
            </div>
        </FormControlContext.Provider>
    );
};

InputAdornment.propTypes /* remove-proptypes */ = {
    // ----------------------------- Warning --------------------------------
    // | These PropTypes are generated from the TypeScript type definitions |
    // |     To update them edit the d.ts file and run "yarn proptypes"     |
    // ----------------------------------------------------------------------
    /**
     * The content of the component, normally an `IconButton` or string.
     */
    children: PropTypes.node,
    /**
     * @ignore
     */
    className: PropTypes.string,
    /**
     * The component used for the root node.
     * Either a string to use a HTML element or a component.
     */
    component: PropTypes.elementType,
    /**
     * Disable pointer events on the root.
     * This allows for the content of the adornment to focus the `input` on click.
     * @default false
     */
    disablePointerEvents: PropTypes.bool,
    /**
     * If children is a string then disable wrapping in a Typography component.
     * @default false
     */
    disableTypography: PropTypes.bool,
    /**
     * The position this adornment should appear relative to the `Input`.
     */
    position: PropTypes.oneOf(['end', 'start']).isRequired,
    /**
     * The variant to use.
     * Note: If you are using the `TextField` component or the `FormControl` component
     * you do not have to set this manually.
     */
    variant: PropTypes.oneOf(['filled', 'outlined', 'standard']),
};

export default InputAdornment;
