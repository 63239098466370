import React from 'react';
import SvgIcon from '../SvgIcon';
import useForkRef from '@util/hook/useForkRef';

const Phone = React.forwardRef((props, ref) => {
    const { className, ...others } = props;
    const handleRef = React.useRef(null);
    const svgRef = useForkRef(handleRef, ref);
    const defaultProperty = {
        className,
        ref: svgRef,
        ...others,
    };
    return (
        <SvgIcon {...defaultProperty}>
            <path d="M21.43,16.13l-4.38-1.87a.91.91,0,0,0-1.09.27L14,16.89A14.45,14.45,0,0,1,7.1,10L9.47,8a1,1,0,0,0,.27-1.1L7.86,2.57A.94.94,0,0,0,6.79,2L2.73,3A.94.94,0,0,0,2,3.87,18.13,18.13,0,0,0,20.12,22h0a.94.94,0,0,0,.91-.73L22,17.21A1,1,0,0,0,21.43,16.13Z" />
        </SvgIcon>
    );
});

export default Phone;
