import React, { useState } from 'react';
import useFormControl from '../formControl/useFormControl';
import formControlState from '../formControl/formControlState';
import clsx from 'clsx';
import { ClickAwayListener, Tooltip } from '../index';
import { QuestionCircle as QuestionCircleIcon } from '@common/SvgIcon/';
import Box from '../box/Box';

const staticClass = `formlabel`;
// const colorMap = ['error', 'add', 'primary', 'secondary', 'success', 'warning'];

const AsteriskComponent = (props) => {
    return (
        <React.Fragment>
            <Box component={'span'} className="asterisk">
                &thinsp;&thinsp;{'*'}
            </Box>
            <Box component={'span'}>&thinsp;&thinsp;-&thinsp;&thinsp;</Box>
            <Box component={'span'} className="asterisk-text">
                必填
            </Box>
        </React.Fragment>
    );
};

const FormLabel = React.forwardRef((props, ref) => {
    const {
        children,
        component: Component = 'label',
        className,
        // color,
        required,
        focused,
        disabled,
        error,
        filled,
        lightColor = false,
        inline = false,
        FormLabelProps = {},
        ...other
    } = props;
    let classNames = staticClass;
    const [open, setOpen] = useState(false);

    const formControl = useFormControl();
    const fcs = formControlState({
        props,
        formControl,
        states: ['color', 'required', 'focused', 'disabled', 'error', 'filled', 'readOnly'],
    });

    if (fcs.disabled) classNames = clsx(classNames, `${staticClass}-disabled`);
    if (fcs.error) classNames = clsx(classNames, `${staticClass}-error`);
    if (fcs.focused) classNames = clsx(classNames, `${staticClass}-focused`);
    if (lightColor) classNames = clsx(classNames, `${staticClass}-color-light`);
    if (inline) {
        classNames = clsx(classNames, `${staticClass}-inline`);
    } else if (!inline && FormLabelProps.labelTip) {
        classNames = clsx(classNames, 'stack-root stack-y-start stack-x-center stack-flex-wrap-nowrap');
    }
    // if (colorMap.indexOf(fcs.color) !== -1) classNames = clsx(classNames, `${staticClass}-color-${fcs.color}`);

    const defaultProperty = {
        className: clsx(classNames, className),
        ref,
        ...other,
    };

    const handleTooltipClose = () => {
        setOpen(false);
    };

    const handleTooltipToggle = () => {
        setOpen((prev) => !prev);
    };

    return (
        <Component {...defaultProperty}>
            <Box component={'span'}>{children}</Box>
            {fcs.required && !fcs.readOnly && <AsteriskComponent />}
            {FormLabelProps.labelTip && (
                <ClickAwayListener onClickAway={handleTooltipClose}>
                    <Tooltip
                        title={FormLabelProps.labelTip}
                        PopperProps={{
                            disablePortal: true,
                        }}
                        onClose={handleTooltipClose}
                        open={open}
                        disableFocusListener
                        disableHoverListener
                        disableTouchListener
                    >
                        <QuestionCircleIcon className={'ml-1'} fontSize={'small'} onClick={handleTooltipToggle} />
                    </Tooltip>
                </ClickAwayListener>
            )}
        </Component>
    );
});

export default FormLabel;
