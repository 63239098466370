import React, { useRef, useLayoutEffect, useImperativeHandle, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { Grid } from '@mui/material';
import { FieldGroup } from '@icoach/documents/components/Layout';
import { AddressField, BirthDayAgeBox } from '@icoach/documents/components/';
import { SignBox, PrivateInformationBox } from '@icoach/components/';
import { TextField, Typography } from '@common/components/';

const MembershipTransferInMemberProfile = React.forwardRef((props, ref) => {
    const { temporarySourceDataRef, isReadOnly = false } = props;
    const {
        name,
        nickName,
        identifierID,
        birthday,
        guardianSign,
        email,
        cellPhone,
        tel,
        companyTel,
        postCode,
        address,
        contactName,
        contactPhone,
        contactship,
        memberCardNo,
    } = temporarySourceDataRef.current || {};
    const [refreshKey, setRefreshKey] = useState(null);
    const nameRef = useRef(null);
    const nickNameRef = useRef(null);
    const identifierIDRef = useRef(null);
    const birthdayRef = useRef(null);
    const guardianSignRef = useRef(null);
    const emailRef = useRef(null);
    const cellPhoneRef = useRef(null);
    const telRef = useRef(null);
    const companyTelRef = useRef(null);
    const addressRef = useRef(null);
    const contactNameRef = useRef(null);
    const contactPhoneRef = useRef(null);
    const contactshipRef = useRef(null);
    const memberCardNoRef = useRef(null);

    const setParams = () => {
        if (isReadOnly) return false;
        const { postCode: paramsPostCode, address: paramsAddress } = addressRef.current.getResult();
        temporarySourceDataRef.current.name = nameRef.current.getResult();
        temporarySourceDataRef.current.nickName = nickNameRef.current.getResult();
        temporarySourceDataRef.current.identifierID = identifierIDRef.current.getResult();
        temporarySourceDataRef.current.birthday = birthdayRef.current.getResult();
        temporarySourceDataRef.current.guardianSign = guardianSignRef.current.getResult() || '';
        temporarySourceDataRef.current.email = emailRef.current.getResult();
        temporarySourceDataRef.current.cellPhone = cellPhoneRef.current.getResult();
        temporarySourceDataRef.current.tel = telRef.current.getResult();
        temporarySourceDataRef.current.companyTel = companyTelRef.current.getResult();
        temporarySourceDataRef.current.address = paramsAddress;
        temporarySourceDataRef.current.postCode = paramsPostCode;
        temporarySourceDataRef.current.contactName = contactNameRef.current.getResult();
        temporarySourceDataRef.current.contactPhone = contactPhoneRef.current.getResult();
        temporarySourceDataRef.current.contactship = contactshipRef.current.getResult();
        temporarySourceDataRef.current.memberCardNo = memberCardNoRef.current.getResult();
    };

    useImperativeHandle(
        ref,
        () => ({
            getResult: () => setParams(),
        }),
        // eslint-disable-next-line
        [],
    );

    useLayoutEffect(
        () => {
            setRefreshKey(uuid());
            return () => setParams();
        },
        // eslint-disable-next-line
        [],
    );

    return (
        <React.Fragment key={refreshKey}>
            <FieldGroup label="基本資料">
                <Grid item xs={4}>
                    <TextField
                        label="姓名"
                        ref={nameRef}
                        defaultValue={name}
                        // isError={!!nameRef}
                        // helperText={memberNameError}
                        readOnly={isReadOnly}
                        required
                        fullWidth
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        label="暱稱"
                        ref={nickNameRef}
                        defaultValue={nickName}
                        // isError={Boolean(nickNameError)}
                        // helperText={nickNameError}
                        readOnly={isReadOnly}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={4}></Grid>
                <Grid item xs={4}>
                    <PrivateInformationBox
                        as={TextField}
                        label="身分證/護照號碼"
                        ref={identifierIDRef}
                        defaultValue={identifierID}
                        // isError={Boolean(identifierIDError)}
                        // helperText={identifierIDError}
                        fullWidth
                        readOnly={isReadOnly}
                    />
                </Grid>
                <BirthDayAgeBox
                    fieldRef={birthdayRef}
                    defaultValue={birthday}
                    // isError={birthdayError}
                    isRead={isReadOnly}
                />
                {!isReadOnly && (
                    <Grid item xs={8}>
                        <Typography className="text-gray-700">未滿十八歲者請由監護人簽名</Typography>
                        <SignBox
                            title="監護人簽名"
                            defaultValue={guardianSign || null}
                            ref={guardianSignRef}
                            // isError={Boolean(signImgError)}
                            // helperText={signImgError}
                            readOnly={isReadOnly}
                        />
                    </Grid>
                )}
            </FieldGroup>
            <FieldGroup label="聯繫方式">
                <Grid item xs={8}>
                    <TextField
                        label="Email"
                        ref={emailRef}
                        defaultValue={email}
                        type="email"
                        inputProps={{
                            placeholder: '建議填寫，以便收取重要權益異動、發票、優惠等相關訊息',
                        }}
                        readOnly={isReadOnly}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={4}></Grid>
                <Grid item xs={4}>
                    <TextField
                        label="手機"
                        ref={cellPhoneRef}
                        defaultValue={cellPhone}
                        // isError={Boolean(cellPhoneError)}
                        // helperText={cellPhoneError}
                        maskType={'MOB'}
                        readOnly={isReadOnly}
                        required
                        fullWidth
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        label="住家電話"
                        ref={telRef}
                        defaultValue={tel}
                        // isError={Boolean(telError)}
                        // helperText={telError}
                        readOnly={isReadOnly}
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        label="公司電話"
                        ref={companyTelRef}
                        defaultValue={companyTel}
                        // isError={Boolean(companyTelError)}
                        // helperText={companyTelError}
                        readOnly={isReadOnly}
                        fullWidth
                    />
                </Grid>
            </FieldGroup>
            <FieldGroup label="地址">
                <Grid item xs={12}>
                    <AddressField
                        ref={addressRef}
                        address={address}
                        postCode={postCode}
                        // isError={Boolean(addressError) || Boolean(postCodeError)}
                        // helperText={addressError || postCodeError}
                        readOnly={isReadOnly}
                        fullWidth
                    />
                </Grid>
            </FieldGroup>
            <FieldGroup label="緊急聯絡人">
                <Grid item xs={4}>
                    <TextField
                        label="姓名"
                        ref={contactNameRef}
                        defaultValue={contactName}
                        // isError={Boolean(contactNameError)}
                        // helperText={contactNameError}
                        readOnly={isReadOnly}
                        fullWidth
                        required
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        label="電話"
                        ref={contactPhoneRef}
                        defaultValue={contactPhone}
                        // isError={Boolean(contactPhoneError)}
                        // helperText={contactPhoneError}
                        readOnly={isReadOnly}
                        fullWidth
                        required
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        label="關係"
                        ref={contactshipRef}
                        defaultValue={contactship}
                        // isError={Boolean(contactshipError)}
                        // helperText={contactshipError}
                        readOnly={isReadOnly}
                        fullWidth
                        required
                    />
                </Grid>
            </FieldGroup>
            <FieldGroup label="會員資訊" required={!isReadOnly}>
                <Grid item xs={4}>
                    <TextField
                        label="簽到卡號"
                        ref={memberCardNoRef}
                        defaultValue={memberCardNo}
                        // isError={Boolean(memberCardNoError)}
                        // helperText={memberCardNoError}
                        readOnly={isReadOnly}
                        required
                        fullWidth
                    />
                </Grid>
            </FieldGroup>
        </React.Fragment>
    );
});

export default MembershipTransferInMemberProfile;
