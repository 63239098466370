import React from 'react';
import { Box } from '@common/components/';
import useMessageDialog from '@util/hook/useMessageDialog';

/**
 * 一般內容 會顯示彈跳視窗
 */
const NotesLabelBox = (props) => {
    const { title, msg } = props;
    const setMessageDialog = useMessageDialog();
    return (
        <Box
            className={'cursor-pointer'}
            style={{
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: 2,
                textWrap: 'wrap',
            }}
            onClick={() => {
                setMessageDialog({
                    open: true,
                    title: title,
                    msg: msg,
                });
            }}
        >
            {msg}
        </Box>
    );
};

export default NotesLabelBox;
