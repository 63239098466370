import Mock from 'mockjs';
import { successApi } from './respondData';

export const withMockAuthProvider = () => {
    Mock.mock(/^\/api\/login\/$/, 'post', (option) => {
        let sourceData = { ...successApi };
        const params = JSON.parse(option.body) ?? '';
        if (USER_ACCOUNT[params.username]) {
            sourceData.result = USER_ACCOUNT[params.username];
        } else {
            sourceData = {
                success: false,
                statusCode: '0x00',
                message: '帳號或密碼錯誤',
                result: null,
            };
        }
        return sourceData;
    });

    Mock.mock(/^\/api\/logout$/, 'get', () => {
        let sourceData = { ...successApi };
        sourceData.result = null;
        return sourceData;
    });

    Mock.setup({ timeout: '200-800' });
};


const USER_ACCOUNT = {
    allen: {
        userID: 5,
        userName: 'allen',
        displayName: '陳勃宏',
        email: 'allen.chen@curves.com.tw',
        routerType: 1,
    },
    sabrina: {
        userID: 24,
        userName: 'sabrina',
        displayName: '俞硯懷',
        email: 'sabrina@curves.com.tw',
        routerType: 1,
    },
    crab: {
        userID: 3,
        userName: 'crab',
        displayName: '林宗敬',
        email: 'crab_lin@curves.com.tw',
        routerType: 1,
    },
    curves: {
        userID: 16,
        userName: 'curves',
        displayName: '健康快樂店',
        email: '',
        routerType: 2,
    },
    'curves.master': {
        userID: 17,
        userName: 'curves.master',
        displayName: '健康快樂加盟主',
        email: '',
        routerType: 2,
    },
};
