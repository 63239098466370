import Mock from 'mockjs';
import { successApi } from './respondData';
import { getUrlParameter } from '../util/utils';
import { parseDate } from '../util/moment';

export const withMockAppointmentsApiProvider = () => {
    Mock.mock(/^\/api\/appointments\/options$/, 'get', () => {
        let sourceData = { ...successApi };
        sourceData.result = APPOINTMEMT_OPTIONS;
        return sourceData;
    });

    Mock.mock(/^\/api\/appointments\/[0-9]+$/, 'get', () => {
        let sourceData = { ...successApi };
        sourceData.result = APPOINTMENT_INFORMATION;
        return sourceData;
    });

    Mock.mock(/^\/api\/appointments\/[0-9]+$/, 'delete', () => {
        let sourceData = { ...successApi };
        sourceData.result = true;
        return sourceData;
    });

    Mock.mock(/^\/api\/appointments\/daily\?.*$/, 'get', ({ url }) => {
        let sourceData = { ...successApi };
        const time = getUrlParameter('time', url);
        sourceData.result = {
            apoDate: parseDate(time, 'YYYY-MM-DD'),
            apoTimelines: APPOINTMENT_DAILY,
        };
        return sourceData;
    });

    Mock.mock(/^\/api\/appointments\/timepicker\?.*$/, 'get', () => {
        let sourceData = { ...successApi };
        sourceData.result = APPOINTMENT_TIMEPICKER;
        return sourceData;
    });

    Mock.mock(/^\/api\/appointments\/confirm$/, 'put', () => {
        let sourceData = { ...successApi };
        sourceData.result = true;
        return sourceData;
    });

    Mock.mock(/^\/api\/appointments\/attend$/, 'put', () => {
        let sourceData = { ...successApi };
        sourceData.result = true;
        return sourceData;
    });

    Mock.mock(/^\/api\/appointments\/push$/, 'put', () => {
        let sourceData = { ...successApi };
        sourceData.result = true;
        return sourceData;
    });

    Mock.mock(/^\/api\/appointments\/circuit$/, 'put', () => {
        let sourceData = { ...successApi };
        sourceData.result = true;
        return sourceData;
    });

    Mock.mock(/^\/api\/appointments\/serve$/, 'put', () => {
        let sourceData = { ...successApi };
        sourceData.result = true;
        return sourceData;
    });

    Mock.setup({ timeout: '200-800' });
};

const APPOINTMEMT_OPTIONS = {
    peopleTypeOption: [
        {
            text: '顧客',
            value: '1',
            disabled: false,
        },
        {
            text: '會員',
            value: '2',
            disabled: false,
        },
    ],
    confirmTypeOption: [
        {
            text: '已確認',
            value: '1',
            disabled: false,
        },
        {
            text: '已打，未接',
            value: '2',
            disabled: false,
        },
        {
            text: '尚未聯繫',
            value: '3',
            disabled: false,
        },
        {
            text: '電話錯誤',
            value: '4',
            disabled: false,
        },
    ],
    attendTypeOption: [
        {
            text: '已赴約',
            value: '1',
            disabled: false,
        },
        {
            text: '未赴約',
            value: '2',
            disabled: false,
        },
        {
            text: '已取消',
            value: '3',
            disabled: false,
        },
    ],
    figureAnalysisPushTypeOption: [
        {
            text: '入會',
            value: '1',
            disabled: false,
        },
        {
            text: '未入會',
            value: '2',
            disabled: false,
        },
    ],
    figureAnalysisReasonTypeOption: [
        {
            text: '再想想/再看看',
            value: '3',
            disabled: false,
        },
        {
            text: '家人不支持',
            value: '4',
            disabled: false,
        },
        {
            text: '時間',
            value: '5',
            disabled: false,
        },
        {
            text: '費用',
            value: '6',
            disabled: false,
        },
        {
            text: '其他',
            value: '99',
            disabled: false,
        },
    ],
    appointmentCustomerTypeOption: [
        {
            text: 'FWO',
            value: '2',
            disabled: false,
        },
        {
            text: '2WO',
            value: '3',
            disabled: false,
        },
        {
            text: '3WO',
            value: '4',
            disabled: false,
        },
    ],
    appointmentSellTypeOption: [
        {
            text: 'HC',
            value: '301',
            disabled: false,
        },
        {
            text: 'HC-講座',
            value: '302',
            disabled: false,
        },
        {
            text: '店內參觀',
            value: '303',
            disabled: false,
        },
        {
            text: '其他',
            value: '304',
            disabled: false,
        },
    ],
    appointmentInterviewTypeOption: [
        {
            text: '請假',
            value: '201',
            disabled: false,
        },
        {
            text: '退會',
            value: '202',
            disabled: false,
        },
        {
            text: '量身',
            value: '203',
            disabled: false,
        },
    ],
    appointmentWorkoutTypeOption: [
        {
            text: 'FWO',
            value: '101',
            disabled: false,
        },
        {
            text: '2WO',
            value: '102',
            disabled: false,
        },
        {
            text: '3WO',
            value: '103',
            disabled: false,
        },
        {
            text: '4WO',
            value: '104',
            disabled: false,
        },
        {
            text: '5WO',
            value: '105',
            disabled: false,
        },
        {
            text: '進階',
            value: '106',
            disabled: false,
        },
        {
            text: '複習',
            value: '107',
            disabled: false,
        },
        {
            text: '伸展機',
            value: '108',
            disabled: false,
        },
    ],
};

const APPOINTMENT_INFORMATION = {
    appointmentID: 11,
    name: '同嘉欣',
    nickName: '加薪',
    sourceType: 2,
    sourceTypeText: '自店社群',
    appointmentDate: '2022-07-20T09:00:00',
    appointmentType: 203,
    appointmentTypeText: '量身',
    cellPhone: '0944123123',
    memberNo: 'A000000002',
    imageSrc: null,
    employeeID: 13,
    employeeName: 'Kevin(小K)',
    serveEmployeeID: 11,
    serveEmployeeName: '林宗敬(小C)',
    circuitEmployeeID: 12,
    circuitEmployeeName: 'Allen(小A)',
    notes: '',
    confirmType: 0,
    attendType: 0,
    pushType: 0,
    pushReasonText: null,
    createdDate: '2022-07-20T09:48:39.523',
    updatedDate: '2022-07-20T09:48:55.32',
    contacts: [],
};

const APPOINTMENT_DAILY = [
    {
        apoTime: '10:00',
        circuitEmployeeID: 1,
        circuitEmployeeName: 'Curves小精靈',
        list: [
            {
                appointmentID: 4,
                peopleType: 2,
                peopleID: 24,
                name: '陳美雪',
                nickName: 'yuki',
                memberNo: 'M00001',
                imageSrc: 'https://randomuser.me/api/portraits/women/60.jpg',
                cellPhone: '09123123123',
                employeeID: 12,
                employeeName: 'Allen(小A)',
                appointmentType: 107,
                appointmentTypeText: '複習',
                serveEmployeeID: null,
                serveEmployeeName: '',
                serviceItemNames: ['二週面談', '推進手套'],
                attendType: 1,
                confirmType: 1,
                pushType: 0,
            },
            {
                appointmentID: 6,
                peopleType: 1,
                peopleID: 3,
                name: '曾甜珍',
                nickName: '珍珍',
                cellPhone: '09123123123',
                employeeID: 12,
                employeeName: 'Allen(小A)',
                appointmentType: 1,
                appointmentTypeText: 'FA',
                serveEmployeeID: 12,
                serveEmployeeName: 'Allen(小A)',
                notes: '備註文字',
                attendType: 1,
                confirmType: 1,
                pushType: 99,
                pushReasonText: 'Allen測試哦',
            },
            {
                appointmentID: 7,
                peopleType: 1,
                peopleID: 5,
                name: '就愛美',
                nickName: '美美',
                cellPhone: '0933432432',
                employeeID: 12,
                employeeName: 'Allen(小A)',
                appointmentType: 1,
                appointmentTypeText: 'FA',
                serveEmployeeID: 12,
                serveEmployeeName: 'Allen(小A)',
                notes: '備註文字就愛美',
                attendType: 2,
                confirmType: 3,
                pushType: 0,
                pushReasonText: '',
            },
        ],
    },
    {
        apoTime: '10:30',
        circuitEmployeeID: 0,
        circuitEmployeeName: '',
        list: [],
    },
    {
        apoTime: '11:00',
        circuitEmployeeID: 0,
        circuitEmployeeName: '',
        list: [],
    },
    {
        apoTime: '11:30',
        circuitEmployeeID: 0,
        circuitEmployeeName: '',
        list: [],
    },
    {
        apoTime: '12:00',
        circuitEmployeeID: 0,
        circuitEmployeeName: '',
        list: [],
    },
    {
        apoTime: '12:30',
        circuitEmployeeID: 0,
        circuitEmployeeName: '',
        list: [],
    },
    {
        apoTime: '13:00',
        circuitEmployeeID: 0,
        circuitEmployeeName: '',
        list: [],
    },
    {
        apoTime: '13:30',
        circuitEmployeeID: 0,
        circuitEmployeeName: '',
        list: [],
    },
    {
        apoTime: '14:00',
        circuitEmployeeID: 0,
        circuitEmployeeName: '',
        list: [],
    },
    {
        apoTime: '14:30',
        circuitEmployeeID: 0,
        circuitEmployeeName: '',
        list: [],
    },
    {
        apoTime: '15:00',
        circuitEmployeeID: 0,
        circuitEmployeeName: '',
        list: [],
    },
    {
        apoTime: '15:30',
        circuitEmployeeID: 0,
        circuitEmployeeName: '',
        list: [],
    },
    {
        apoTime: '16:00',
        circuitEmployeeID: 0,
        circuitEmployeeName: '',
        list: [],
    },
    {
        apoTime: '16:30',
        circuitEmployeeID: 0,
        circuitEmployeeName: '',
        list: [],
    },
    {
        apoTime: '17:00',
        circuitEmployeeID: 0,
        circuitEmployeeName: '',
        list: [],
    },
    {
        apoTime: '17:30',
        circuitEmployeeID: 0,
        circuitEmployeeName: '',
        list: [],
    },
];

const APPOINTMENT_TIMEPICKER = [
    {
        appointmentTime: '08:00',
        appointmentItems: null,
    },
    {
        appointmentTime: '08:30',
        appointmentItems: null,
    },
    {
        appointmentTime: '09:00',
        appointmentItems: null,
    },
    {
        appointmentTime: '09:30',
        appointmentItems: [
            { appointmentType: 1, apoTypeName: 'FA', names: ['流川楓,櫻木花道'] },
            { appointmentType: 203, apoTypeName: '量身', names: ['墓木晴子'] },
        ],
    },
    {
        appointmentTime: '10:00',
        appointmentItems: null,
    },
    {
        appointmentTime: '10:30',
        appointmentItems: null,
    },
    {
        appointmentTime: '11:00',
        appointmentItems: null,
    },
    {
        appointmentTime: '11:30',
        appointmentItems: null,
    },
    {
        appointmentTime: '12:00',
        appointmentItems: null,
    },
    {
        appointmentTime: '12:30',
        appointmentItems: null,
    },
    {
        appointmentTime: '13:00',
        appointmentItems: null,
    },
    {
        appointmentTime: '13:30',
        appointmentItems: null,
    },
    {
        appointmentTime: '14:00',
        appointmentItems: null,
    },
    {
        appointmentTime: '14:30',
        appointmentItems: null,
    },
    {
        appointmentTime: '15:00',
        appointmentItems: null,
    },
    {
        appointmentTime: '15:30',
        appointmentItems: null,
    },
    {
        appointmentTime: '16:00',
        appointmentItems: null,
    },
    {
        appointmentTime: '16:30',
        appointmentItems: null,
    },
    {
        appointmentTime: '17:00',
        appointmentItems: null,
    },
    {
        appointmentTime: '17:30',
        appointmentItems: null,
    },
    {
        appointmentTime: '18:00',
        appointmentItems: null,
    },
];
