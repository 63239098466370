import React from 'react';
import SvgIcon from '../SvgIcon';
import useForkRef from '@util/hook/useForkRef';

const GiftSolid = React.forwardRef((props, ref) => {
    const { className, ...others } = props;
    const handleRef = React.useRef(null);
    const svgRef = useForkRef(handleRef, ref);
    const defaultProperty = {
        className,
        ref: svgRef,
        ...others,
    };
    return (
        <SvgIcon {...defaultProperty}>
            <path
                d="M1.25,17.5A1.249,1.249,0,0,0,2.5,18.75H8.75V12.5H1.25Zm10,1.25H17.5a1.249,1.249,0,0,0,1.25-1.25v-5h-7.5Zm7.5-12.5H17.105A3.406,3.406,0,0,0,17.5,4.688,3.443,3.443,0,0,0,14.063,1.25c-1.625,0-2.676.832-4.023,2.668C8.691,2.082,7.641,1.25,6.016,1.25A3.443,3.443,0,0,0,2.578,4.688,3.358,3.358,0,0,0,2.973,6.25H1.25A1.249,1.249,0,0,0,0,7.5v3.125a.627.627,0,0,0,.625.625h18.75A.627.627,0,0,0,20,10.625V7.5A1.249,1.249,0,0,0,18.75,6.25Zm-12.738,0a1.563,1.563,0,0,1,0-3.125c.777,0,1.352.129,3.363,3.125Zm8.051,0H10.7c2.008-2.988,2.566-3.125,3.363-3.125a1.563,1.563,0,0,1,0,3.125Z"
                transform="translate(2 1.75)"
            />
        </SvgIcon>
    );
});

export default GiftSolid;
