import React from 'react';
import { Card } from '@common/components/';
import { Grid } from '@mui/material';
import { ReadTextField } from '@icoach/components';
import { displayDateText } from '@util/moment';

const OrderStateSectionByRead = (props) => {
    const { data = {} } = props;
    const { cycleOrderNo, cycleOrderTypeText, cycleOrderDate, shippingDayText, firstShipmentMonth } = data;
    return (
        <Card.CardContent className={'p-4'}>
            <Grid spacing={3} container>
                <Grid xs={4} item>
                    <ReadTextField label={'訂單編號'}>{cycleOrderNo}</ReadTextField>
                </Grid>
                <Grid xs={4} item>
                    <ReadTextField label={'訂單狀態'}>{cycleOrderTypeText}</ReadTextField>
                </Grid>
                <Grid xs={4} item>
                    <ReadTextField label={'建單日期'}>{displayDateText(cycleOrderDate)}</ReadTextField>
                </Grid>
                <Grid xs={4} item>
                    <ReadTextField label={'出貨週期'}>{`${shippingDayText}日`}</ReadTextField>
                </Grid>
                <Grid xs={4} item>
                    <ReadTextField label={'首次出貨月份'}>{displayDateText(firstShipmentMonth, null, { format: 'YYYY/MM' })}</ReadTextField>
                </Grid>
            </Grid>
        </Card.CardContent>
    );
};

export default OrderStateSectionByRead;
