import React, { useCallback, useRef } from 'react';
import { isEmpty } from 'lodash';
import clsx from 'clsx';
import Tablenization from '../../components/Tablenization';
import { DateLabel } from '../components';
import { isValidDate } from '../../../util/moment';
import { getRowsDataTotal, guestProductionTabsItemType } from '../staticData';
import ScrollerArrowButton from '../../inbody/components/ScrollerArrowButton';
import { Box } from '../../../components';

const apoRowsLabel = [
    {
        cellKey: 'date',
        headerLabel: '日期',
        width: 12,
        formatCell: (date) => {
            if (isValidDate(date)) return <DateLabel date={date} />;
            return date;
        },
    },
    {
        cellKey: 'statisticsAmount',
        headerLabel: '統計',
        align: 'center',
        className: 'total-col',
    },
    {
        cellKey: 'apoService',
        headerLabel: '自店介紹',
        align: 'center',
    },
    {
        cellKey: 'apoOtherClub',
        headerLabel: '他店介紹',
        align: 'center',
    },
    {
        cellKey: 'apoMedia',
        headerLabel: '平面媒體',
        align: 'center',
    },
    {
        cellKey: 'apoInternet',
        headerLabel: '網路',
        align: 'center',
    },
    {
        cellKey: 'apoSign',
        headerLabel: '招牌',
        align: 'center',
    },
    {
        cellKey: 'apoPartner',
        headerLabel: '策略夥伴',
        align: 'center',
    },
    {
        cellKey: 'apoOther',
        headerLabel: '其他',
        align: 'center',
    },
    {
        cellKey: 'apoBox',
        headerLabel: '信箱傳單',
        align: 'center',
    },
    {
        cellKey: 'apoFlyer',
        headerLabel: '手發傳單',
        align: 'center',
    },
    {
        cellKey: 'apoCp',
        headerLabel: 'CP',
        align: 'center',
    },
    {
        cellKey: 'apoGpList',
        headerLabel: 'GP名單',
        align: 'center',
    },
    {
        cellKey: 'apoBrList',
        headerLabel: 'BR名單',
        align: 'center',
    },
    {
        cellKey: 'apoSocial',
        headerLabel: '自店社群',
        align: 'center',
    },
    {
        cellKey: 'apoExisting',
        headerLabel: '既有名單',
        align: 'center',
    },
    {
        cellKey: 'apoCpList',
        headerLabel: 'CP名單',
        align: 'center',
    },
];
const listRowsLabel = [
    {
        cellKey: 'date',
        headerLabel: '日期',
        width: 12,
        formatCell: (date) => {
            if (isValidDate(date)) return <DateLabel date={date} />;
            return date;
        },
    },
    {
        cellKey: 'statisticsAmount',
        headerLabel: '統計',
        align: 'center',
        className: 'total-col',
    },
    {
        cellKey: 'gpList',
        headerLabel: 'GP名單',
        align: 'center',
    },
    {
        cellKey: 'brList',
        headerLabel: 'BR名單',
        align: 'center',
    },
    {
        cellKey: 'social',
        headerLabel: '自店社群',
        align: 'center',
    },
    {
        cellKey: 'cpList',
        headerLabel: 'CP名單',
        align: 'center',
    },
    {
        cellKey: 'strategicPartnerList',
        headerLabel: '策略夥伴',
        align: 'center',
    },
    {
        cellKey: 'networkList',
        headerLabel: '網路',
        align: 'center',
    },
    {
        cellKey: 'existingList',
        headerLabel: '既有名單',
        align: 'center',
    },
    {
        cellKey: 'printMediaList',
        headerLabel: '平面媒體',
        align: 'center',
    },
    {
        cellKey: 'letterBoxFlyerList',
        headerLabel: '信箱傳單',
        align: 'center',
    },
    {
        cellKey: 'selfClubBrList',
        headerLabel: '自店BR',
        align: 'center',
    },
    {
        cellKey: 'handOutFlyerList',
        headerLabel: '手發傳單',
        align: 'center',
    },
    {
        cellKey: 'otherClubBrList',
        headerLabel: '他店BR',
        align: 'center',
    },
    {
        cellKey: 'billboardList',
        headerLabel: '招牌',
        align: 'center',
    },
    {
        cellKey: 'cpCallOutList',
        headerLabel: 'CP去電',
        align: 'center',
    },
    {
        cellKey: 'other',
        headerLabel: '其他',
        align: 'center',
    },
];
const askRowsLabel = [
    {
        cellKey: 'date',
        headerLabel: '日期',
        width: 12,
        formatCell: (date) => {
            if (isValidDate(date)) return <DateLabel date={date} />;
            return date;
        },
    },
    {
        cellKey: 'statisticsAmount',
        headerLabel: '統計',
        align: 'center',
        className: 'total-col',
    },
    {
        cellKey: 'leadService',
        headerLabel: '自店介紹',
        align: 'center',
    },
    {
        cellKey: 'leadOtherClub',
        headerLabel: '他店介紹',
        align: 'center',
    },
    {
        cellKey: 'leadMedia',
        headerLabel: '平面媒體',
        align: 'center',
    },
    {
        cellKey: 'leadInternet',
        headerLabel: '網路',
        align: 'center',
    },
    {
        cellKey: 'leadSign',
        headerLabel: '招牌',
        align: 'center',
    },
    {
        cellKey: 'leadPartner',
        headerLabel: '策略夥伴',
        align: 'center',
    },
    {
        cellKey: 'leadOther',
        headerLabel: '其他',
        align: 'center',
    },
    {
        cellKey: 'leadBox',
        headerLabel: '信箱傳單',
        align: 'center',
    },
    {
        cellKey: 'leadFlyer',
        headerLabel: '手發傳單',
        align: 'center',
    },
    {
        cellKey: 'leadCp',
        headerLabel: 'CP',
        align: 'center',
    },
    {
        cellKey: 'leadGpList',
        headerLabel: 'GP名單',
        align: 'center',
    },
    {
        cellKey: 'leadBrList',
        headerLabel: 'BR名單',
        align: 'center',
    },
    {
        cellKey: 'leadSocial',
        headerLabel: '自店社群',
        align: 'center',
    },
    {
        cellKey: 'leadExisting',
        headerLabel: '既有名單',
        align: 'center',
    },
    {
        cellKey: 'leadCpList',
        headerLabel: 'CP名單',
        align: 'center',
    },
];
const faRowsLabel = [
    {
        cellKey: 'date',
        headerLabel: '日期',
        width: 12,
        formatCell: (date) => {
            if (isValidDate(date)) return <DateLabel date={date} />;
            return date;
        },
    },
    {
        cellKey: 'statisticsAmount',
        headerLabel: '統計',
        align: 'center',
        className: 'total-col',
    },
    {
        cellKey: 'togService',
        headerLabel: '自店BR',
        align: 'center',
    },
    {
        cellKey: 'togOtherClub',
        headerLabel: '他店介紹',
        align: 'center',
    },
    {
        cellKey: 'togMedia',
        headerLabel: '平面媒體',
        align: 'center',
    },
    {
        cellKey: 'togInternet',
        headerLabel: '網路',
        align: 'center',
    },
    {
        cellKey: 'togSign',
        headerLabel: '招牌',
        align: 'center',
    },
    {
        cellKey: 'togPartner',
        headerLabel: '策略夥伴',
        align: 'center',
    },
    {
        cellKey: 'togOther',
        headerLabel: '其他',
        align: 'center',
    },
    {
        cellKey: 'togBox',
        headerLabel: '信箱傳單',
        align: 'center',
    },
    {
        cellKey: 'togFlyer',
        headerLabel: '手發傳單',
        align: 'center',
    },
    {
        cellKey: 'togCp',
        headerLabel: 'CP',
        align: 'center',
    },
    {
        cellKey: 'togGpList',
        headerLabel: 'GP名單',
        align: 'center',
    },
    {
        cellKey: 'togBrList',
        headerLabel: 'BR名單',
        align: 'center',
    },
    {
        cellKey: 'togSocial',
        headerLabel: '自店社群',
        align: 'center',
    },
    {
        cellKey: 'togExisting',
        headerLabel: '既有名單',
        align: 'center',
    },
    {
        cellKey: 'togCpList',
        headerLabel: 'CP名單',
        align: 'center',
    },
];
const totalSalesRowsLabel = [
    {
        cellKey: 'date',
        headerLabel: '日期',
        width: 12,
        formatCell: (date) => {
            if (isValidDate(date)) return <DateLabel date={date} />;
            return date;
        },
    },
    {
        cellKey: 'statisticsAmount',
        headerLabel: '統計',
        align: 'center',
        className: 'total-col',
        width: 12,
    },
    {
        cellKey: 'newTwoYear',
        headerLabel: '二年約',
        align: 'center',
        width: 12,
    },
    {
        cellKey: 'newOneYear',
        headerLabel: '一年約',
        align: 'center',
        width: 12,
    },
    {
        cellKey: 'newOther',
        headerLabel: '其他',
        align: 'center',
        width: 12,
    },
];
const rowsLabelMap = new Map([
    [guestProductionTabsItemType.apo, apoRowsLabel],
    [guestProductionTabsItemType.list, listRowsLabel],
    [guestProductionTabsItemType.ask, askRowsLabel],
    [guestProductionTabsItemType.fa, faRowsLabel],
    [guestProductionTabsItemType.totalSales, totalSalesRowsLabel],
]);

const GuestProductionDailyTable = (props) => {
    const { className, tabsIndex, rowsData = [] } = props;
    const labelTitle = rowsLabelMap.get(tabsIndex);
    const scrollerArrowRef = useRef(null);

    const handleRowsData = useCallback(() => {
        let array = [...rowsData];
        let totalRow = [
            {
                ...getRowsDataTotal(rowsData),
                date: '最新數字',
            },
        ];
        if (!isEmpty(totalRow)) array = totalRow.concat(array);
        return array;
    }, [rowsData]);

    return (
        <Box className={'scroll-arrow-container'}>
            <Tablenization
                cardProps={{ className: clsx('daily-table', className) }}
                tableContainerRef={scrollerArrowRef}
            >
                <Tablenization.TableRowHeader headerRow={labelTitle} />
                <Tablenization.TableRowBody
                    headerRow={labelTitle}
                    rowsData={handleRowsData()}
                    bodyRowClass={{ key: 'date', value: '最新數字', className: 'total-row' }}
                />
            </Tablenization>
            <ScrollerArrowButton key={tabsIndex} parentRef={scrollerArrowRef} />
        </Box>
    );
};

export default GuestProductionDailyTable;
