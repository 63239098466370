import { useCallback, useMemo } from 'react';
import useAjax from '@apis/useAjax';
import NON_MEMBER_ROUTERS from '@icoach/router/NonMemberRouter';
import { useSnackbar } from 'notistack';

const GET_CUSTOMERS_OVERVIEW_API = `/api/customers/overview/`;
const GET_GUESTS_OVERVIEW_API = `/api/guests/overview/`;
const GET_TEMPORARIES_OVERVIEW_API = `/api/temporaries/overview/`;
const POST_CUSTOMERS_OVERVIEW_OPTIONS_API = `/api/customers/overview/options/`;
const POST_GUESTS_OVERVIEW_OPTIONS_API = `/api/guests/overview/options/`;
const POST_TEMPORARIES_OVERVIEW_OPTIONS_API = `/api/temporaries/overview/options/`;


const useNonMemberApi = () => {
    const Ajax = useAjax();
    const { enqueueSnackbar } = useSnackbar();

    const overviewsIndex = ['C3X02', 'C3X04', 'C3X06'];
    // 顧客/名單/暫存  總覽Api
    const overviewApis = useMemo(
        () => {
            const ovApis = NON_MEMBER_ROUTERS.reduce((acc, { frontKey, tabsIndex }) => {
                if (overviewsIndex.indexOf(frontKey) === -1) return acc;
                else return Object.assign(acc, { [frontKey]: tabsIndex });
            }, {});
            return {
                [ovApis[overviewsIndex[0]]]: GET_CUSTOMERS_OVERVIEW_API,
                [ovApis[overviewsIndex[1]]]: GET_GUESTS_OVERVIEW_API,
                [ovApis[overviewsIndex[2]]]: GET_TEMPORARIES_OVERVIEW_API,
            };
        },
        // eslint-disable-next-line
        []
    );

    // 顧客/名單/暫存 各自options Api
    const optionsApis = useMemo(
        () => {
            const opApis = NON_MEMBER_ROUTERS.reduce((acc, { frontKey, tabsIndex }) => {
                if (overviewsIndex.indexOf(frontKey) === -1) return acc;
                else return Object.assign(acc, { [frontKey]: tabsIndex });
            }, {});
            return {
                [opApis[overviewsIndex[0]]]: POST_CUSTOMERS_OVERVIEW_OPTIONS_API,
                [opApis[overviewsIndex[1]]]: POST_GUESTS_OVERVIEW_OPTIONS_API,
                [opApis[overviewsIndex[2]]]: POST_TEMPORARIES_OVERVIEW_OPTIONS_API,
            };
        },
        // eslint-disable-next-line
        []
    );

    // 初始頁面Option
    const getInitOptionsApi = useCallback(
        async (type) => {
            const initOptions = await Ajax.get(optionsApis[type]);
            if (initOptions) return initOptions;
            else {
                enqueueSnackbar('資料讀取有誤，請重新整理', { variant: 'error' });
            }
        },
        // eslint-disable-next-line
        []
    );

    // 顧客 / 名單 / 暫存  總覽資料
    const postOverviewApi = useCallback(async (type, params = {}) => {
        const overviewData = await Ajax.post(overviewApis[type], params);
        if (overviewData) return overviewData;
        else {
            enqueueSnackbar('資料讀取有誤，請重新整理', { variant: 'error' });
        }
        // eslint-disable-next-line
    }, []);

    return {
        getInitOptionsApi,
        postOverviewApi,
    };
};

export default useNonMemberApi;
