export const initBookmark = (sortBy) => {
    let bookmark = {
        pageIndex: 1,
        pageSize: 50,
    };
    if (sortBy) {
        bookmark.sortBy = sortBy;
    }
    return bookmark;
};
