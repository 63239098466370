import React, { useRef, useState, useEffect, useMemo } from 'react';
import { useParams, useHistory, Switch } from 'react-router-dom';
import { DOC_MEMBERSHIP_TRANSFER_OUT_LINK } from '@icoach/documents/membership/staticData';
import MembershipTransferOutContent from '@icoach/documents/membership/transferOut/MembershipTransferOutContent';
import MembershipTransferOutHistory from '@icoach/documents/membership/transferOut/MembershipTransferOutHistory';
import MembershipTransferOutSearchBarTabs from '@icoach/documents/membership/transferOut/MembershipTransferOutSearchBarTabs';
import ConfirmTemporaryContractDialog from '@icoach/documents/dialog/ConfirmTemporaryContractDialog';
import { BlankFormBox, SearchBar } from '@icoach/documents/components/';
import {
    DOCUMENTS_MEMBERSHIPTRANSFEROUT_MOVEOUTTYPE_PATH,
    DOCUMENTS_MEMBERSHIPTRANSFEROUT_MOVEOUTTYPE_PEOPLETYPE_PATH,
    DOCUMENTS_MEMBERSHIPTRANSFEROUT_PEOPLETYPE_REFERENCEID_HISTORY_ROUTER,
    DOCUMENTS_MEMBERSHIPTRANSFEROUT_MOVEOUTTYPE_PEOPLETYPE_REFERENCEID_HISTORY_PATH,
    DOCUMENTS_MEMBERSHIPTRANSFEROUT_UNMOVEOUT_PEOPLETYPE_REFERENCEID_CONTRACT_ROUTER,
    DOCUMENTS_MEMBERSHIPTRANSFEROUT_UNMOVEOUT_PEOPLETYPE_REFERENCEID_CONTRACT_PATH,
    DOCUMENTS_MEMBERSHIPTRANSFEROUT_UNMOVEOUT_PEOPLETYPE_REFERENCEID_READONLY_CONTRACT_ROUTER,
    DOCUMENTS_MEMBERSHIPTRANSFEROUT_MOVEOUT_PEOPLETYPE_REFERENCEID_READONLY_CONTRACT_ROUTER,
} from '@icoach/router/routerPath';
import useDocMembershipTransferOutApi from '@apis/useDocMembershipTransferOutApi';
import { peopleType as peopleTypeMap } from '@apis/usePeopleApi';
import { Typography, PrivateRoute, Box } from '@common/components/';

const MembershipTransferOutPage = (props) => {
    const {
        getInitTransferOutUnMoveoutOptionApi,
        getInitTransferOutMoveoutOptionApi,
        getDocTemporaryTransferOutContractApi,
        deleteDocTemporaryTransferOutContractApi,
    } = useDocMembershipTransferOutApi();
    const { peopleType: peopleTypeProps, moveoutType: moveoutTypeProps, referenceID: referenceIDProps } = useParams();
    const history = useHistory();
    const [options, setOptions] = useState({});
    const [openDialog, setOpenDialog] = useState(false); //有暫存資料時的彈出視窗
    const [isMounted, setMounted] = useState(false);
    const searchRef = useRef([]);
    const temporaryIDRef = useRef(0);

    const checkPeopleType = (opts) => {
        const { targetOptions = [] } = opts;
        const isInclude = targetOptions.find(({ value }) => String(peopleTypeProps) === String(value));
        const type = moveoutTypeProps === 'moveout' ? peopleTypeMap.unConfirmTransferOutMember : peopleTypeMap.member;
        if (!peopleTypeProps || !isInclude) {
            history.replace(DOCUMENTS_MEMBERSHIPTRANSFEROUT_MOVEOUTTYPE_PEOPLETYPE_PATH(moveoutTypeProps, type));
        }
    };

    // 重設 按鈕
    const handleResetClick = (type) => {
        const path = DOCUMENTS_MEMBERSHIPTRANSFEROUT_MOVEOUTTYPE_PEOPLETYPE_PATH(moveoutTypeProps, type);
        history.replace(path);
    };

    // 搜尋 按鈕
    const handleSearchHistoryClick = (id) => {
        const { targetID, targetType } = searchRef.current.getResult();
        const path = DOCUMENTS_MEMBERSHIPTRANSFEROUT_MOVEOUTTYPE_PEOPLETYPE_REFERENCEID_HISTORY_PATH(moveoutTypeProps, targetType, targetID);
        history.replace(path);
    };

    // 新增 按鈕
    const handleCreateClick = () => {
        const { targetID: id, targetType: type } = searchRef.current.getResult();
        const params = { id, type };
        doCreateBlankContractApi(params, type, id);
    };

    // 彈出視窗 要刪除暫存資料
    const handleDialogNoClick = () => {
        const params = {
            id: referenceIDProps,
            type: peopleTypeProps,
        };
        doDeleteTemporaryDataApi(params);
    };

    // 彈出視窗 讀取上次暫存資料繼續編輯
    const handleDialogYseClick = () => {
        if (!temporaryIDRef.current) return;
        const contractID = temporaryIDRef.current;
        const path = DOCUMENTS_MEMBERSHIPTRANSFEROUT_UNMOVEOUT_PEOPLETYPE_REFERENCEID_CONTRACT_PATH(peopleTypeProps, referenceIDProps, contractID);
        setOpenDialog(false);
        history.push(path);
    };

    // 刪除暫存合約資料
    const doDeleteTemporaryDataApi = async (parmas) => {
        const resp = await deleteDocTemporaryTransferOutContractApi(parmas);
        if (resp) {
            const path = DOCUMENTS_MEMBERSHIPTRANSFEROUT_UNMOVEOUT_PEOPLETYPE_REFERENCEID_CONTRACT_PATH(peopleTypeProps, referenceIDProps, 0);
            setOpenDialog(false);
            history.push(path);
        }
    };

    // 新增空白頁面
    const doCreateBlankContractApi = async (params, type, id) => {
        let temporaryID;
        temporaryIDRef.current = 0;
        temporaryID = await getDocTemporaryTransferOutContractApi(params);
        if (temporaryID !== 0 && !!temporaryID) {
            temporaryIDRef.current = temporaryID;
            setOpenDialog(true);
        } else {
            const path = DOCUMENTS_MEMBERSHIPTRANSFEROUT_UNMOVEOUT_PEOPLETYPE_REFERENCEID_CONTRACT_PATH(type, id, 0);
            history.push(path);
        }
    };

    // 初始search options
    const doInitOptionsApi = async () => {
        let resp;
        if (moveoutTypeProps === 'unmoveout') {
            resp = await getInitTransferOutUnMoveoutOptionApi();
        } else if (moveoutTypeProps === 'moveout') {
            resp = await getInitTransferOutMoveoutOptionApi();
        } else {
            history.push(DOCUMENTS_MEMBERSHIPTRANSFEROUT_MOVEOUTTYPE_PATH('unmoveout'));
        }
        if (resp) {
            checkPeopleType(resp);
            setOptions(resp);
            setMounted(true);
        }
    };

    const useSearchBarMemo = useMemo(
        () => {
            return (
                <SearchBar
                    ref={searchRef}
                    className="mb-4"
                    TabsComponentProps={<MembershipTransferOutSearchBarTabs />}
                    sourceOption={options.targetOptions}
                    onResetEvent={handleResetClick}
                    onCreateEvent={handleCreateClick}
                    onSearchHistoryEvent={handleSearchHistoryClick}
                    routerPath={DOCUMENTS_MEMBERSHIPTRANSFEROUT_MOVEOUTTYPE_PATH(moveoutTypeProps)}
                />
            );
        },
        // eslint-disable-next-line
        [options, moveoutTypeProps],
    );

    useEffect(
        () => {
            doInitOptionsApi();
            return () => setMounted(false);
        },
        // eslint-disable-next-line
        [moveoutTypeProps],
    );

    return (
        <Box className="container main-container-spacing">
            {isMounted && (
                <React.Fragment>
                    <Box component="header" className={'page-header'}>
                        <Typography className={'page-header-title'} variant={'h3'}>
                            國內移籍申請書 (移出)
                        </Typography>
                        <Box className="btn-group">
                            <BlankFormBox sourceData={DOC_MEMBERSHIP_TRANSFER_OUT_LINK} />
                        </Box>
                    </Box>
                    {useSearchBarMemo}
                    <Switch>
                        <PrivateRoute
                            exact
                            path={DOCUMENTS_MEMBERSHIPTRANSFEROUT_PEOPLETYPE_REFERENCEID_HISTORY_ROUTER}
                            as={MembershipTransferOutHistory}
                            onBlankContractClick={handleCreateClick}
                        />
                        <PrivateRoute
                            exact
                            path={DOCUMENTS_MEMBERSHIPTRANSFEROUT_UNMOVEOUT_PEOPLETYPE_REFERENCEID_CONTRACT_ROUTER}
                            as={MembershipTransferOutContent}
                            searchRef={searchRef}
                            onReturnHistory={handleSearchHistoryClick}
                            isReadOnly={false}
                        />
                        <PrivateRoute
                            exact
                            path={DOCUMENTS_MEMBERSHIPTRANSFEROUT_UNMOVEOUT_PEOPLETYPE_REFERENCEID_READONLY_CONTRACT_ROUTER}
                            as={MembershipTransferOutContent}
                            searchRef={searchRef}
                            onReturnHistory={handleSearchHistoryClick}
                            isReadOnly={true}
                        />
                        <PrivateRoute
                            exact
                            path={DOCUMENTS_MEMBERSHIPTRANSFEROUT_MOVEOUT_PEOPLETYPE_REFERENCEID_READONLY_CONTRACT_ROUTER}
                            as={MembershipTransferOutContent}
                            searchRef={searchRef}
                            onReturnHistory={handleSearchHistoryClick}
                            isReadOnly={true}
                        />
                    </Switch>
                    <ConfirmTemporaryContractDialog
                        open={openDialog}
                        handleClose={() => setOpenDialog(false)}
                        handleClickYes={handleDialogYseClick}
                        handleClickNo={handleDialogNoClick}
                    />
                </React.Fragment>
            )}
        </Box>
    );
};

export default MembershipTransferOutPage;
