import React, { useEffect, useLayoutEffect, useState, useRef } from 'react';
import clsx from 'clsx';
import { v4 as uuid } from 'uuid';
import { Dialog, DialogContent, Grid } from '@mui/material';
import { Button, Collapse, IconButton, List, ListItemButton, ListItemText, TextField, Typography, Alert } from '@common/components/';
import { AngleDown as AngleDownIcon, AngleUp as AngleUpIcon, Close as CloseIcon } from '@common/SvgIcon/';
import { isEmpty } from '@util/utils';
import { ProductBox } from '@icoach/accountBook/components';
import { useCartCardContext } from '@icoach/accountBook/context/CartCardContext';
import useProductApi from '@apis/useProductApi';
import Logo from '@images/nav_logo.svg';

// 選單
const ShoppingMenu = (props) => {
    const { className, isOpen = false, currentMenu, menuData: menuDataProps = [], layer = 0, setCurrentMenu, productTypeText, onClick: onClickProps } = props;
    const menuData = Array.isArray(menuDataProps) && menuDataProps.filter((item) => !item.disabled);
    const menuDataShowState = Array.isArray(menuDataProps) && menuData.map(() => false);
    const [openSubmenu, setOpenSubmenu] = useState(menuDataShowState);

    const handleToggleSubmenu = (index) => {
        setOpenSubmenu((prev) => {
            let _arr = [...prev];
            _arr[index] = !_arr[index];
            return _arr;
        });
    };

    const handleClick = (item) => {
        let _productTypeText = productTypeText || item.text;
        setCurrentMenu(item);
        if (typeof onClickProps === 'function') onClickProps(item, _productTypeText);
    };

    useLayoutEffect(
        () => {
            if (currentMenu) return;
            let item = menuData[0];
            if (item) {
                if (item.children) {
                    handleToggleSubmenu(0);
                } else {
                    handleClick(item);
                }
            }
        },
        // eslint-disable-next-line
        []
    );

    return (
        <Collapse in={isOpen}>
            <List className={className}>
                {Array.isArray(menuData) &&
                    menuData.map((item, i) => {
                        return (
                            <React.Fragment key={`${item.text}`}>
                                <ListItemButton
                                    className={clsx({
                                        'is-current': currentMenu.text === item.text,
                                    })}
                                    onClick={() => {
                                        if (!isEmpty(item.children)) {
                                            handleToggleSubmenu(i);
                                        } else {
                                            handleClick(item);
                                        }
                                    }}
                                >
                                    <ListItemText primary={item.text} />
                                    {!isEmpty(item.children) && (openSubmenu[i] ? <AngleUpIcon /> : <AngleDownIcon />)}
                                </ListItemButton>
                                {item.children && (
                                    <ShoppingMenu
                                        menuData={item.children}
                                        isOpen={openSubmenu[i]}
                                        layer={`${layer + 1}`}
                                        currentMenu={currentMenu}
                                        productTypeText={productTypeText || item.text}
                                        setCurrentMenu={setCurrentMenu}
                                        onClick={onClickProps}
                                    />
                                )}
                            </React.Fragment>
                        );
                    })}
            </List>
        </Collapse>
    );
};
const ShoppingMenuProduct = (props) => {
    const { data = {}, setCartCount } = props;
    const { productID, variantID, pictureSrc, productName, variantName, sku, stock, isLimited, price } = data;
    const { cartDataRef, addCacheCart, deleteCacheCart } = useCartCardContext();
    const initSelectState = (productID, variantID) => {
        return cartDataRef.current.some((item) => {
            if (item.variantID) {
                return item.variantID === variantID && item.productID === productID;
            } else {
                return item.productID === productID;
            }
        });
    };
    const [isSelect, setSelect] = useState(initSelectState(productID, variantID));

    const handleClickItem = () => {
        if (isSelect) {
            deleteCacheCart(data);
            setSelect(false);
        } else if (addCacheCart(data)) {
            setSelect(true);
        }
        setCartCount(cartDataRef.current.length);
    };

    return (
        <ProductBox
            className={clsx({ 'is-select': isSelect })}
            name={productName}
            imgUrl={pictureSrc}
            variantName={variantName}
            sku={sku}
            price={price}
            stock={stock}
            isLimited={isLimited}
            onClick={handleClickItem}
        />
    );
};
const ShoppingMenuFee = (props) => {
    const { data = {}, productTypeText, setCartCount } = props;
    const { text } = data;
    const { cartDataRef, addCacheCart, deleteCacheCart } = useCartCardContext();
    const initSelectState = (text) => {
        return cartDataRef.current.some((item) => {
            return item.name === text;
        });
    };
    const [isSelect, setSelect] = useState(initSelectState(text));

    const handleClickItem = () => {
        if (isSelect) {
            deleteCacheCart(data);
            setSelect(false);
        } else if (
            addCacheCart({
                ...data,
                productTypeText,
            })
        ) {
            setSelect(true);
        }
        setCartCount(cartDataRef.current.length);
    };

    return <ProductBox className={clsx({ 'is-select': isSelect }, 'fee-item')} name={text} onClick={handleClickItem} />;
};
const ShoppingMenuDialogContent = (props) => {
    const { menuData, onClose } = props;
    const { cacheCartToCart } = useCartCardContext();
    const { getProductSaleOptionsApi, getAllMerchandiseDataApi } = useProductApi();
    const [cartCount, setCartCount] = useState(0);
    const [products, setProducts] = useState([]);
    const [filterProducts, setFilterProducts] = useState(products);
    const [currentMenu, setCurrentMenu] = useState('');
    const [searchKey, setSearchKey] = useState(uuid());
    const productTypeTextRef = useRef('');
    const timeoutRef = useRef(null);
    const isOnComposition = useRef(false);

    const doProductSaleOptionsApi = async (saleCateID, setProduct) => {
        let res = await getProductSaleOptionsApi({ saleCateID });
        if (res) setProduct(res);
    };

    const handleProducts = (data) => {
        setProducts(data);
    };

    const handleChange = (e) => {
        clearTimeout(timeoutRef.current);
        if (e.target instanceof HTMLInputElement && !isOnComposition.current) {
            let { value } = e.target;
            if (isEmpty(currentMenu)) return;
            if (currentMenu.value !== 'ALL') handleFilterProduct(value);
            else {
                value = value.trim();
                if (isEmpty(value)) {
                    if (isEmpty(products)) return;
                    setProducts([]);
                } else {
                    const _t = setTimeout(() => {
                        doAllMerchandiseSearchApi({ text: value });
                    }, 800);
                    timeoutRef.current = _t;
                }
            }
        }
    };

    const handleComposition = (e) => {
        if (e.type === 'compositionend') {
            // composition is end
            isOnComposition.current = false;
            handleChange(e);
        } else {
            // in composition
            isOnComposition.current = true;
        }
    };

    const handleFilterProduct = (_keyword) => {
        let regex, keyword;
        let filterProducts = [...products];
        const allowSpecialSymbols = (str = '') => str.replace(/([.?*+^$[\]\\(){}|-])/g, '\\$1');
        keyword = allowSpecialSymbols(_keyword);
        regex = new RegExp(keyword, 'i');

        if (!keyword) setFilterProducts(filterProducts);

        filterProducts = filterProducts.filter((item) => {
            if (item.hasOwnProperty('productID')) {
                return regex.test(item.productName) || regex.test(item.sku);
            } else {
                return regex.test(item.text);
            }
        });

        setFilterProducts(filterProducts);
    };

    const handleClickCheckout = () => {
        cacheCartToCart();
        onClose();
    };

    const refreshSearchFieldKey = () => {
        setSearchKey(uuid());
    };

    const handleClickMenuBtn = (_itemData, productTypeText) => {
        refreshSearchFieldKey();
        productTypeTextRef.current = productTypeText;
    };

    const doAllMerchandiseSearchApi = async (params) => {
        clearTimeout(timeoutRef.current);
        const resp = await getAllMerchandiseDataApi(params);
        if (resp) {
            setProducts(resp);
        } else {
            setProducts([]);
        }
    };

    useEffect(
        () => {
            if (!currentMenu) return;
            if (currentMenu.hasOwnProperty('checkoutItems')) {
                // 費用
                handleProducts(currentMenu.checkoutItems);
            } else {
                doProductSaleOptionsApi(currentMenu.value, handleProducts);
            }
        },
        // eslint-disable-next-line
        [currentMenu]
    );

    useEffect(
        () => {
            handleFilterProduct();
        },
        // eslint-disable-next-line
        [products]
    );

    return (
        <DialogContent className={'shopping-menu-container'}>
            <div className={'shopping-menu-side-bar'}>
                <div className={'logo'}>
                    <img src={Logo} alt={'Curves'} />
                </div>
                <ShoppingMenu
                    currentMenu={currentMenu}
                    className={'menu'}
                    menuData={menuData}
                    setCurrentMenu={setCurrentMenu}
                    onClick={handleClickMenuBtn}
                    isOpen
                />
            </div>
            <div className={'shopping-menu-content'}>
                <div className={'products'}>
                    <Grid className={'mb-4'} spacing={3} container>
                        <Grid xs={6} item>
                            <TextField
                                key={searchKey}
                                InputProps={{ placeholder: '搜尋品項名稱或商品條碼' }}
                                onCompositionStart={handleComposition}
                                onCompositionUpdate={handleComposition}
                                onCompositionEnd={handleComposition}
                                onChange={handleChange}
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                    {currentMenu && currentMenu.value === 'ALL' && filterProducts.length === 0 && (
                        <Grid container spacing={3}>
                            <Grid xs={12} item>
                                <Alert variant="filled" severity="info" color="secondary">
                                    請輸入品項名稱或商品條碼
                                </Alert>
                            </Grid>
                        </Grid>
                    )}
                    <Grid container spacing={3}>
                        {filterProducts.map((item) => {
                            if (item.hasOwnProperty('productID')) {
                                return (
                                    <Grid key={`product-${item.productID}-${item.variantID}`} xs={12} item>
                                        <ShoppingMenuProduct data={item} setCartCount={setCartCount} />
                                    </Grid>
                                );
                            } else {
                                return (
                                    <Grid key={`fee-${item.text}`} xs={12} item>
                                        <ShoppingMenuFee data={item} productTypeText={productTypeTextRef.current} setCartCount={setCartCount} />
                                    </Grid>
                                );
                            }
                        })}
                    </Grid>
                </div>
                <footer className={'shopping-menu-footer'}>
                    <Typography variant={'h6'}>
                        已經選取：
                        <strong className={'text-primary mr-1'}>{cartCount}</strong>
                        個品項
                    </Typography>
                    <div className={'btn-group'}>
                        <Button className={'btn-checkout'} color={'warning'} variant={'contained'} size={'large'} onClick={handleClickCheckout}>
                            進行結帳
                        </Button>
                    </div>
                </footer>
            </div>
        </DialogContent>
    );
};

const ShoppingMenuDialog = (props) => {
    const { className, open, menuData, onClose } = props;
    const { clearCacheCart, updateCacheCart } = useCartCardContext();

    const handleClickClose = () => {
        clearCacheCart();
        onClose();
    };

    useEffect(() => {
        if (open) {
            updateCacheCart();
        }
        // eslint-disable-next-line
    }, [open]);

    if (!open) return null;

    return (
        <Dialog className={clsx(className, 'shopping-menu-dialog', 'simple-content', 'dialog')} PaperProps={{ className: 'wx-90rem' }} open={open} fullWidth>
            {onClose && (
                <div className="dialog-close-btn">
                    <IconButton size="large" onClick={handleClickClose}>
                        <CloseIcon htmlColor="#fff" />
                    </IconButton>
                </div>
            )}
            <ShoppingMenuDialogContent menuData={menuData} onClose={onClose} />
        </Dialog>
    );
};

export default ShoppingMenuDialog;
