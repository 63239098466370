import React, {useEffect, useRef, useState} from 'react';
import {Grid} from '@mui/material';
import ConfigurationBasicDialogBox from '../dialog/ConfigurationBasicDialogBox';
import {ReadTextField} from '../../components';
import {AddressField} from '../../documents/components';
import useClubApi, {GET_CLUBS_STAMP_JPG_API} from '../../../apis/useClubApi';
import useForkRef from '@util/hook/useForkRef';
import {
    Edit as EditIcon,
    Reply as ReplyIcon,
    SuccessOutlined as SuccessOutlinedIcon,
    LockPassword as LockPasswordIcon,
} from '../../../components/icons/SvgIcon/';
import useMessageDialog from '../../../util/hook/useMessageDialog';
import {Box, Button, Card, Stack, TextField, Typography} from '@common/components/';

const ControllerField = React.forwardRef((props, ref) => {
    const {label, value, isEdit = false, ReadComponent = ReadTextField, FieldComponent} = props;

    const handleRef = useRef(null);
    const fieldRef = useForkRef(handleRef, ref);

    if (isEdit && FieldComponent)
        return (
            <React.Fragment>
                {React.isValidElement(FieldComponent) &&
                    React.cloneElement(FieldComponent, {
                        label: label,
                        ref: fieldRef,
                    })}
            </React.Fragment>
        );
    return <ReadComponent label={label}>{value}</ReadComponent>;
});

const ConfigurationBasicPage = React.forwardRef((props, ref) => {
    const {getClubInfomationDataApi, putClubInfomationDataApi} = useClubApi();
    const setMessageDialog = useMessageDialog();
    const [isEdit, setEdit] = useState(false);
    const [data, setData] = useState({});
    const [imgHash, setImgHash] = useState(new Date().getTime());
    const {
        clubID,
        clubCode,
        clubName,
        postCode,
        address,
        clubTel,
        clubFax,
        email,
        financialMail,
        taxID,
        taxTitle,
        companyName,
        ownerName,
        cyclePurchasePostCode,
        cyclePurchaseAddress,
    } = data;
    const fieldRef = useRef({});
    const dialogBoxRef = useRef(null);

    const doGetClubDataApi = async () => {
        const res = await getClubInfomationDataApi();
        if (res) {
            setData(res);
        }
    };

    const doPutClubDataApi = async (params) => {
        const resp = await putClubInfomationDataApi(params, clubID);
        if (resp) {
            setTimeout(doGetClubDataApi, 500);
            setEdit(false);
            setMessageDialog({
                open: true,
                title: '提醒',
                msg: '店章重新產生中，請1分鐘後重整頁面查看店章，謝謝',
            });
        }
    };

    const getParams = () => {
        const fieldObj = fieldRef.current;
        let result = {};

        if (fieldObj) {
            for (let key in fieldObj) {
                const target = fieldObj[key];
                if (target && target.getResult) {
                    let val = target.getResult();
                    if (key === 'address') {
                        result = {...result, ...val};
                    } else if (key === 'cyclePurchaseAddress') {
                        let {postCode, address} = val;
                        result = {...result, ...{cyclePurchasePostCode: postCode, cyclePurchaseAddress: address}};
                    } else {
                        result[key] = val;
                    }
                }
            }
        }
        return result;
    };

    // 修改登入密碼
    const handlePWDEdit = () => {
        dialogBoxRef.current.openModifyPwdDialog();
    };

    const handleEditClick = () => {
        setEdit(true);
    };

    const handleReturnRead = () => {
        setEdit(false);
    };

    const handleSendClick = () => {
        const params = {...data, ...getParams()};
        doPutClubDataApi(params, clubID);
        setTimeout(() => {
            let ticks = new Date().getTime();
            // console.log(ticks);
            setImgHash(ticks);
        }, 1000);
    };

    useEffect(() => {
        doGetClubDataApi();
        // eslint-disable-next-line
    }, []);

    return (
        <React.Fragment>
            <Card>
                <Card.CardContent className={'p-4'}>
                    <Stack className={'mb-2'} justifyContent={'space-between'} alignItems={'center'}>
                        <Typography className={'font-weight-medium'} variant={'h4'}>
                            分店基本資料
                        </Typography>
                        <Stack justifyContent="end" spacing={2}>
                            {!isEdit ? (
                                <React.Fragment>
                                    <Button startIcon={<LockPasswordIcon/>} variant="contained" onClick={handlePWDEdit}>
                                        修改密碼
                                    </Button>
                                    <Button startIcon={<EditIcon/>} variant="outlined" onClick={handleEditClick}>
                                        編輯
                                    </Button>
                                </React.Fragment>
                            ) : (
                                <React.Fragment>
                                    <Button startIcon={<ReplyIcon/>} variant="outlined" onClick={handleReturnRead}>
                                        返回
                                    </Button>
                                    <Button startIcon={<SuccessOutlinedIcon/>} variant="contained" onClick={handleSendClick}>
                                        儲存
                                    </Button>
                                </React.Fragment>
                            )}
                        </Stack>
                    </Stack>
                    <Box className={'mb-5'}>
                        <Grid className={'mb-2'} spacing={3} container>
                            <Grid xs={4} item>
                                <ControllerField
                                    ref={(el) => (fieldRef.current['clubCode'] = el)}
                                    label={'分店代碼'}
                                    value={clubCode}
                                    isEdit={isEdit}
                                    FieldComponent={<TextField key={clubCode} defaultValue={clubCode} disabled fullWidth/>}
                                />
                            </Grid>
                            <Grid xs={4} item>
                                <ControllerField
                                    ref={(el) => (fieldRef.current['clubName'] = el)}
                                    label={'分店名稱'}
                                    value={clubName}
                                    isEdit={isEdit}
                                    FieldComponent={<TextField key={clubName} defaultValue={clubName} disabled fullWidth/>}
                                />
                            </Grid>
                            <Grid xs={4} item/>
                            <Grid xs={4} item>
                                <ControllerField
                                    ref={(el) => (fieldRef.current['clubTel'] = el)}
                                    label={'分店電話'}
                                    value={clubTel}
                                    isEdit={isEdit}
                                    FieldComponent={<TextField type={'tel'} key={clubTel} defaultValue={clubTel} fullWidth/>}
                                />
                            </Grid>
                            <Grid xs={4} item>
                                <ControllerField
                                    ref={(el) => (fieldRef.current['clubFax'] = el)}
                                    label={'分店傳真'}
                                    value={clubFax}
                                    isEdit={isEdit}
                                    FieldComponent={<TextField type={'tel'} key={clubFax} defaultValue={clubFax} fullWidth/>}
                                />
                            </Grid>
                            <Grid xs={4} item>
                                <ControllerField
                                    ref={(el) => (fieldRef.current['email'] = el)}
                                    label={'分店電子郵件'}
                                    value={email}
                                    isEdit={isEdit}
                                    FieldComponent={<TextField type={'email'} key={email} defaultValue={email} fullWidth/>}
                                />
                            </Grid>
                            <Grid xs={12} item>
                                <ControllerField
                                    ref={(el) => (fieldRef.current['address'] = el)}
                                    label={'分店地址'}
                                    value={`${postCode} ${address}`}
                                    isEdit={isEdit}
                                    FieldComponent={<AddressField address={address} postCode={postCode}/>}
                                />
                            </Grid>
                            <Grid xs={4} item>
                                <Typography className={'font-weight-medium'} variant={'h5'}>
                                    店章
                                </Typography>
                                <Box className={'stamp-img bg-light'}>
                                    <Box component={'img'} src={GET_CLUBS_STAMP_JPG_API + '?t=' + imgHash} alt="店章"/>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                    <Typography className={'font-weight-medium'} variant={'h5'}>
                        負責人 & 公司資料
                    </Typography>
                    <Grid className={'mb-5'} spacing={3} container>
                        <Grid xs={4} item>
                            <ControllerField
                                ref={(el) => (fieldRef.current['ownerName'] = el)}
                                label={'加盟主姓名'}
                                value={ownerName}
                                isEdit={isEdit}
                                FieldComponent={<TextField key={ownerName} defaultValue={ownerName} fullWidth/>}
                            />
                        </Grid>
                        <Grid xs={4} item>
                            <ControllerField
                                ref={(el) => (fieldRef.current['companyName'] = el)}
                                label={'分店公司名稱'}
                                value={companyName}
                                isEdit={isEdit}
                                FieldComponent={<TextField key={companyName} defaultValue={companyName} fullWidth/>}
                            />
                        </Grid>
                    </Grid>
                    <Typography className={'font-weight-medium'} variant={'h5'}>
                        發票統編資訊
                    </Typography>
                    <Grid className={'mb-5'} spacing={3} container>
                        <Grid xs={4} item>
                            <ControllerField
                                ref={(el) => (fieldRef.current['taxID'] = el)}
                                label={'統一編號'}
                                value={taxID}
                                isEdit={isEdit}
                                FieldComponent={<TextField key={taxID} defaultValue={taxID} fullWidth/>}
                            />
                        </Grid>
                        <Grid xs={4} item>
                            <ControllerField
                                ref={(el) => (fieldRef.current['taxTitle'] = el)}
                                label={'統編抬頭'}
                                value={taxTitle}
                                isEdit={isEdit}
                                FieldComponent={<TextField key={taxTitle} defaultValue={taxTitle} fullWidth/>}
                            />
                        </Grid>
                    </Grid>
                    <Typography className={'font-weight-medium'} variant={'h5'}>
                        帳務電子郵件(設定後，下載帳務相關文件指定寄發至下述電子信箱)
                    </Typography>
                    <Grid className={'mb-5'} spacing={3} container>
                        <Grid xs={4} item>
                            <ControllerField
                                ref={(el) => (fieldRef.current['financialMail'] = el)}
                                label={'帳務電子郵件'}
                                value={financialMail}
                                isEdit={isEdit}
                                FieldComponent={<TextField type={'email'} key={financialMail} defaultValue={financialMail} fullWidth/>}
                            />
                        </Grid>
                    </Grid>
                    <Typography className={'font-weight-medium'} variant={'h5'}>
                        循環訂單寄送地址
                    </Typography>
                    <Grid className={'mb-5'} spacing={3} container>
                        <Grid xs={12} item>
                            <ControllerField
                                ref={(el) => (fieldRef.current['cyclePurchaseAddress'] = el)}
                                label={'循環訂單寄送地址'}
                                value={cyclePurchasePostCode && cyclePurchaseAddress ? `${cyclePurchasePostCode} ${cyclePurchaseAddress}` : ''}
                                isEdit={isEdit}
                                FieldComponent={<AddressField address={cyclePurchaseAddress} postCode={cyclePurchasePostCode}/>}
                            />
                        </Grid>
                    </Grid>
                </Card.CardContent>
            </Card>
            <ConfigurationBasicDialogBox ref={dialogBoxRef}/>
        </React.Fragment>
    );
});

export default ConfigurationBasicPage;
