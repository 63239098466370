import React, { useImperativeHandle, useState } from 'react';
import ModifyPasswordDialog from './ModifyPasswordDialog';

const ConfigurationBasicDialogBox = React.forwardRef((props, ref) => {
    const [openDialog, setOpenDialog] = useState(null);

    const handleOnClose = () => {
        setOpenDialog(null);
    };

    useImperativeHandle(
        ref,
        () => {
            return {
                openModifyPwdDialog: () => {
                    setOpenDialog('ModifyPwdDialog');
                },
            };
        },
        []
    );

    return (
        <React.Fragment>
            <ModifyPasswordDialog open={openDialog === 'ModifyPwdDialog'} onClose={handleOnClose} />
        </React.Fragment>
    );
});

export default ConfigurationBasicDialogBox;
