import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

const elevationMap = [0, 1, 2, 3, 4, 6, 8, 12, 16, 24];

const Paper = React.forwardRef((props, ref) => {
    const {
        className,
        style,
        elevation = 1,
        square = false,
        variant = 'elevation',
        as,
        ...other
    } = props;
    let classNames = 'paper';

    if (variant === 'outlined') {
        classNames = clsx(classNames, `paper-outlined`);
    } else if (variant === 'elevation' && elevationMap.indexOf(elevation * 1) !== -1) {
        classNames = clsx(classNames, `elevation-${elevation}`);
    }
    if (square) classNames = clsx(classNames, `paper-square`);

    const defaultProperty = {
        className: clsx(classNames, className),
        style,
        ref,
        ...other,
    };

    const Comp = as ? as : 'div';

    return <Comp {...defaultProperty} />;
});

Paper.propTypes /* remove-proptypes */ = {
    // ----------------------------- Warning --------------------------------
    // | These PropTypes are generated from the TypeScript type definitions |
    // |     To update them edit the d.ts file and run "yarn proptypes"     |
    // ----------------------------------------------------------------------
    /**
     * The content of the component.
     */
    children: PropTypes.node,
    /**
     * Override or extend the styles applied to the component.
     */
    classes: PropTypes.object,
    /**
     * @ignore
     */
    className: PropTypes.string,
    /**
     * The component used for the root node.
     * Either a string to use a HTML element or a component.
     */
    component: PropTypes.elementType,
    /**
     * Shadow depth, corresponds to `dp` in the spec.
     * It accepts values between 1 and 5 inclusive.
     * @default 1
     */
    elevation: PropTypes.any,
    /**
     * If `true`, rounded corners are disabled.
     * @default false
     */
    square: PropTypes.bool,
    /**
     * The system prop that allows defining system overrides as well as additional CSS styles.
     */
    sx: PropTypes.object,
    /**
     * The variant to use.
     * @default 'elevation'
     */
    variant: PropTypes.oneOfType([PropTypes.oneOf(['elevation', 'outlined']), PropTypes.string]),
};

export default Paper;
