import React from 'react';
import { NavLink } from 'react-router-dom';
import { Tabs, Tab } from '@common/components/';
import {
    MEMBERS_MEMBER_OVERVIEW_QUICK_URL,
    MEMBERS_MEMBER_OVERVIEW_NORMAL,
} from '@icoach/router/MembersRouter';

const MemberOverviewTabsHeader = (props) => {
    const { value: propsValue, handleTabsValue = () => {} } = props;
    const handleChange = (event, newValue) => {
        handleTabsValue && handleTabsValue(newValue);
    };

    return (
        <React.Fragment>
            <Tabs
                className="pl-2"
                value={propsValue}
                onChange={handleChange}
                variant="scrollable"
                allowScrollButtonsMobile
            >
                <Tab
                    label="條件篩選"
                    value={'normal'}
                    component={NavLink}
                    to={MEMBERS_MEMBER_OVERVIEW_NORMAL}
                />
                <Tab
                    label="快速名單"
                    value={'quick'}
                    component={NavLink}
                    to={`${MEMBERS_MEMBER_OVERVIEW_QUICK_URL(1)}`}
                />
            </Tabs>
        </React.Fragment>
    );
};

export default MemberOverviewTabsHeader;
