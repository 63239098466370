import React, { useMemo } from 'react';
import { Box, Chip, Typography } from '@common/components/';
import { BusinessCardBox } from '@icoach/components/';
import { OrderInvoiceAbandonDialog, OrderAllowanceDialog } from '@icoach/accountBook/dialog';
import { formatCurrencyFn } from '@util/utils';
import useToggleDialog from '@util/hook/useToggleDialog';
import { dialogType, orderStatusColorMap } from '@icoach/accountBook/staticData';
import { BatchPaymentOrdersTable, checkRowsLabel } from '@icoach/accountBook/tables/BatchPaymentOrdersCreditCardTable';

const rowsLabel = [
    {
        cellKey: 'memberSheet',
        headerLabel: '會員',
        align: 'left',
        maxWidth: 20.4,
        formatCell: (cellValue, { memberNo, memberName, memberPicturePath }) => {
            let memberInfo = {
                memberNo,
                memberName,
                memberImg: memberPicturePath,
            };
            return <BusinessCardBox rowData={memberInfo} />;
        },
    },
    {
        cellKey: 'transactionStatusText',
        headerLabel: '交易狀態',
        align: 'center',
        formatCell: (cellValue, { transactionStatus = 0 }) => {
            return <Chip label={cellValue} color={orderStatusColorMap.get(transactionStatus)} />;
        },
    },
    {
        cellKey: 'transactionTypeText',
        headerLabel: '交易類型',
        align: 'center',
    },
    {
        cellKey: 'itemName',
        headerLabel: '品項',
        align: 'center',
    },
    {
        cellKey: 'transactionPrice',
        headerLabel: '交易金額',
        align: 'center',
        formatCell: (cellValue) => {
            return formatCurrencyFn(cellValue);
        },
    },
    {
        cellKey: 'creditCardNo',
        headerLabel: '信用卡',
        maxWidth: 21,
        formatCell: (cellValue, { cardExpiryDate }) => {
            return (
                <Box className="payment-card-content">
                    <Typography className={'title'} variant="body1" component={'h6'}>
                        {cellValue}
                    </Typography>
                    {cardExpiryDate ? (
                        <Typography className={'sub-title font-color-3'} variant="body2">
                            {`有效期限：${cardExpiryDate}`}
                        </Typography>
                    ) : (
                        ''
                    )}
                </Box>
            );
        },
    },
    {
        cellKey: 'note',
        headerLabel: '備註',
        maxWidth: 30,
    },
];

const BillingResultCreditCardTable = React.forwardRef((props, ref) => {
    const { refresh, action } = props;
    const { openTarget, handleOpenDialog, handleCloseDialog, orderID } = useToggleDialog();
    const labelTitle = useMemo(() => {
        let array = [...rowsLabel];
        if (action) array = [checkRowsLabel(action), ...array];
        return array;
    }, [action]);

    return (
        <React.Fragment>
            <BatchPaymentOrdersTable {...props} ref={ref} labelTitle={labelTitle} handleOpenDialog={handleOpenDialog} />
            <OrderAllowanceDialog open={openTarget === dialogType.allowance} orderID={orderID} onClose={handleCloseDialog} refresh={refresh} />
            <OrderInvoiceAbandonDialog open={openTarget === dialogType.invoiceAbandon} orderID={orderID} onClose={handleCloseDialog} refresh={refresh} />
        </React.Fragment>
    );
});

export default BillingResultCreditCardTable;
