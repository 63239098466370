import React from 'react';
import SvgIcon from '../SvgIcon';
import useForkRef from '@util/hook/useForkRef';

const FolderPlus = React.forwardRef((props, ref) => {
    const { className, ...others } = props;
    const handleRef = React.useRef(null);
    const svgRef = useForkRef(handleRef, ref);
    const defaultProperty = {
        className,
        ref: svgRef,
        ...others,
    };
    return (
        <SvgIcon {...defaultProperty}>
            <path
                d="M18.594.125.488,10.57a.938.938,0,0,0,.086,1.688L4.727,14,15.949,4.109a.234.234,0,0,1,.336.324L6.875,15.9v3.145a.937.937,0,0,0,1.66.617l2.48-3.02,4.867,2.039a.94.94,0,0,0,1.289-.711L19.984,1.094A.938.938,0,0,0,18.594.125Z"
                transform="translate(2 2.001)"
            ></path>
        </SvgIcon>
    );
});

export default FolderPlus;
