import React, { useState, useEffect } from 'react';
import CabinetOverviewSearchBar from './CabinetOverviewSearchBar';
import CabinetOverviewTable from './CabinetOverviewTable';
import { RentCabinetProvider } from './components/RentCabinetContext';
import { initBookmark } from './staticData';

const CabinetOverviewPage = (props) => {
    const { setTabs, value: tabKey, dialogRef, refreshRef } = props;
    const [bookmark, setBookmark] = useState(initBookmark);
    const [tableData, setTableData] = useState({});

    const setBookmarkNum = (num) => setBookmark((pre) => ({ ...pre, pageIndex: num }));

    useEffect(() => {
        setTabs(tabKey);
        // eslint-disable-next-line
    }, []);

    const childContext = {
        tableData,
        setTableData,
        bookmark,
        setBookmarkNum,
        refresh: refreshRef,
        setRefresh: (el) => (refreshRef.current = el),
        dialogRef,
    };

    return (
        <RentCabinetProvider value={childContext}>
            <CabinetOverviewSearchBar className={'mb-4'} />
            <CabinetOverviewTable />
        </RentCabinetProvider>
    );
};

export default CabinetOverviewPage;
