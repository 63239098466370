import React, { useRef, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';
import { Grid } from '@mui/material';
import MemberOverviewTabsHeader from '@icoach/members/overview/MemberOverviewTabsHeader';
import { initBookmark } from '@icoach/members/staticData';
import useMembers from '@icoach/members/components/useMembers';
import usePeopleApi from '@apis/usePeopleApi';
import useMemberApi from '@apis/useMemberApi';
import useMemberFilterExportApi from '@apis/useMemberFilterExportApi';
import useMessageDialog from '@util/hook/useMessageDialog';
import { FormControlLabel, Switch, Card, Stack, AutocompleteField, Button, Form, Typography } from '@common/components/';
import { MEMBERS_MEMBER_OVERVIEW_NORMAL } from '@icoach/router/MembersRouter';
import { isEmpty, objectToQueryString, paramsToObject } from '@util/utils';
import useWindowScrollPositions from '@util/hook/useWindowScrollPositions';

const OverviewNormalSearchBar = (props) => {
    const { tabsIndex } = props;
    const history = useHistory();
    const { bookmark, setSourceData, setBookmarkNum, reSetCheckedItems } = useMembers();
    const { postMemebersOverview } = useMemberApi();
    const { getMemberFilterOverviewApi } = useMemberFilterExportApi();
    const setMessageDialog = useMessageDialog();
    const [peopleType, setPeople] = useState(2);
    const { people: peopleOptions = [] } = usePeopleApi(peopleType);
    const [paramsData, setParamsData] = useState({}); // 初始預設搜尋條件使用,其他狀況下無用
    const [options, setOptions] = useState([]);
    const memberIDRef = useRef(null);
    const includeQuitRef = useRef(null);
    const preloadRef = useRef(false);
    useWindowScrollPositions();

    /** 更新網址上的搜尋條件 **/
    const updateUrlWithSearchQuery = (params) => {
        const url = `${MEMBERS_MEMBER_OVERVIEW_NORMAL}${objectToQueryString(params)}`;
        history.replace(url);
    };

    const handleListData = (list = []) => {
        let _list = list;
        if (Array.isArray(list)) {
            _list = list.map(({ ...others }) => ({
                ...others,
                name: others.nickName || others.memberName,
            }));
        }
        return _list;
    };

    const getParams = (targetPage = {}) => {
        let paramsJson = { ...targetPage };

        if (memberIDRef.current && memberIDRef.current.getResult) Object.assign(paramsJson, { memberID: memberIDRef.current.getResult('id') });
        if (includeQuitRef.current && includeQuitRef.current.checked) Object.assign(paramsJson, { isIncludeQuit: includeQuitRef.current.checked });

        // 沒有篩選
        delete paramsJson.sortBy;
        return paramsJson;
    };

    const handleSearchOnClick = (e) => {
        preloadRef.current = false;
        reSetCheckedItems();
        const params = getParams(initBookmark());
        doSearchApi(params, true);
        setBookmarkNum(1);
    };

    const handleExcelOnClick = () => {
        const params = getParams();
        doExcelSendEmailApi(params);
    };

    const handleSwitchChange = (e, isChecked) => {
        if (isChecked) setPeople(6);
        else setPeople(2);
    };

    // 總覽資料
    const doSearchApi = async (params, isRefresh = false) => {
        const resp = await postMemebersOverview(params);
        if (resp) {
            let { list, sortBy, ...pageParams } = resp;
            // 簡訊使用 name
            list = handleListData(list);

            if (isRefresh) {
                setSourceData({ list, ...pageParams });
            } else {
                setSourceData((prev) => {
                    let targetList = list;
                    let prevList = prev.list;
                    if (String(params.pageIndex) !== '1') {
                        if (Array.isArray(prevList)) {
                            targetList = prevList.concat(list);
                        }
                    }
                    return {
                        ...pageParams,
                        list: targetList,
                    };
                });
            }
            updateUrlWithSearchQuery(params);
            preloadRef.current = true;
        }
    };

    const doExcelSendEmailApi = async (params) => {
        const resp = await getMemberFilterOverviewApi(params);
        if (resp) {
            let { message, url } = resp;
            setMessageDialog({
                open: true,
                title: '個資資訊安全原則',
                MsgComp: () => (
                    <div>
                        <Typography className="word-break-break-word">{message}</Typography>
                        <p />
                        <Button to={url} color="error">
                            保管責任書
                        </Button>
                    </div>
                ),
            });
        }
    };

    useEffect(
        () => {
            if (!_.isEmpty(peopleOptions)) {
                reSetCheckedItems();
                const { location } = history;
                let params = paramsToObject(location.search);
                setOptions(peopleOptions);
                if (!isEmpty(params)) {
                    setParamsData(params);
                }
                preloadRef.current = false;
                doSearchApi(getParams({ ...initBookmark(), ...bookmark }));
            }
        },
        // eslint-disable-next-line
        [peopleOptions, peopleType]
    );

    // 換頁
    useEffect(
        () => {
            // 第一頁不在這打API
            if (String(bookmark.pageIndex) === '1') return null;
            if (!preloadRef.current) return null;
            const params = getParams({ ...initBookmark(), ...bookmark });
            preloadRef.current = false;
            doSearchApi(params);
        },
        // eslint-disable-next-line
        [bookmark.pageIndex]
    );

    return (
        <Form onSubmit={handleSearchOnClick}>
            <Card>
                <Card.CardContent className="members-tabs tabs-bottom-line">
                    <MemberOverviewTabsHeader value={tabsIndex} />
                </Card.CardContent>
                <Card.CardContent className="pb-4 px-2">
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <AutocompleteField
                                key={`${peopleType}_${paramsData.memberID}`}
                                inputProps={{ placeholder: '請輸入姓名/手機號碼/會員編號(擇一即可)' }}
                                ref={memberIDRef}
                                defaultValue={paramsData.memberID}
                                options={options}
                                optionKeys={['name', 'mobile', 'no']}
                                primaryKey={'id'}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Stack className="ml-2" spacing="1">
                                <FormControlLabel
                                    key={Boolean(paramsData.isIncludeQuit)}
                                    control={
                                        <Switch defaultChecked={Boolean(paramsData.isIncludeQuit)} inputRef={includeQuitRef} onChange={handleSwitchChange} />
                                    }
                                    prefixLabel="包含無效會員"
                                />
                                <Button variant="contained" type="submit">
                                    搜尋
                                </Button>
                                <Button variant="contained" color="success" onClick={handleExcelOnClick}>
                                    下載Excel
                                </Button>
                            </Stack>
                        </Grid>
                    </Grid>
                </Card.CardContent>
            </Card>
        </Form>
    );
};

export default OverviewNormalSearchBar;
