import React, { useState } from 'react';
import { useSnackbar } from 'notistack';
import { RemoveContractRecordDialog, EditRecordDialog } from '@icoach/nonMember/dialog';
import useNonMember from '@icoach/nonMember/components/useNonMember';
import { Typography, IconButton, Card, Divider, Stack } from '@common/components/';
import { Trash as TrashIcon, Edit as EditIcon } from '@common/SvgIcon/';
import useAjax from '@apis/useAjax';
import { parseDate } from '@util/moment';

const ProspectContactRecordBox = (props) => {
    const { contactDate, contactResultTypeText, coachName, contactID, customerID, title, notes } = props;
    const [isOpen, setOpen] = useState(null);
    const { enqueueSnackbar: _snackbar } = useSnackbar();
    const { refresh } = useNonMember();
    const Ajax = useAjax();

    const removeApi = async (_customerID, _contactID) => {
        const resp = await Ajax.delete(`/api/customers/${_customerID}/contact-records/${_contactID}`);
        if (resp) {
            _snackbar('成功刪除聯繫紀錄', { variant: 'success' });
            refresh && refresh.current && refresh.current();
        } else {
            _snackbar('失敗', { variant: 'error' });
        }
    };

    const editApi = async (_customerID, _contactID, par) => {
        const resp = await Ajax.put(`/api/customers/${_customerID}/contact-records/${_contactID}`, par);
        if (resp) {
            _snackbar('成功刪除聯繫紀錄', { variant: 'success' });
            setOpen(null);
            refresh && refresh.current && refresh.current();
        } else {
            _snackbar('失敗', { variant: 'error' });
        }
    };

    return (
        <React.Fragment>
            <Card isOverflowHidden className="contact-record-card">
                <Card.CardContent>
                    <Stack justifyContent="space-between" className="pt-2 px-2">
                        <span className="nonmember-info-contact-markbook">
                            <Typography variant="h5" className="nonmember-info-contact-date">
                                {parseDate(contactDate)}
                            </Typography>
                        </span>
                        <div>
                            <IconButton onClick={() => setOpen('edit')}>
                                <EditIcon />
                            </IconButton>
                            <IconButton onClick={() => setOpen('remove')}>
                                <TrashIcon />
                            </IconButton>
                        </div>
                    </Stack>
                    <Stack justifyContent="space-between" className="px-3 py-3">
                        <div className="overflow-hidden">
                            <span className="nonmember-info-contact-label">狀態</span>
                            <span className="nonmember-info-contact-label-text">{contactResultTypeText}</span>
                        </div>
                        <div className="overflow-hidden">
                            <span className="nonmember-info-contact-label">擔當</span>
                            <span className="nonmember-info-contact-label-text">{coachName}</span>
                        </div>
                    </Stack>
                </Card.CardContent>
                <Divider />
                <Card.CardContent>
                    <div className="px-3 py-2">
                        <Typography variant="h6" className="title py-2">
                            {title}
                        </Typography>
                        <Typography className="content pb-4">{notes}</Typography>
                    </div>
                </Card.CardContent>
            </Card>
            <RemoveContractRecordDialog open={isOpen === 'remove'} setOpen={() => setOpen(null)} doAction={() => removeApi(customerID, contactID)} />
            <EditRecordDialog
                open={isOpen === 'edit'}
                setOpen={() => setOpen(null)}
                doAction={(_par) => editApi(customerID, contactID, _par)}
                title={title}
                notes={notes}
            />
        </React.Fragment>
    );
};

export default ProspectContactRecordBox;
