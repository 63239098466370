import React from 'react';
import Tablelvl2Context from './Tablelvl2Context';
import clsx from 'clsx';

const staticClass = `table-footer`;

const tablelvl2 = {
    variant: 'footer',
};

const TableFooter = React.forwardRef((props, ref) => {
    const { className, children, ...other } = props;
    let classNames = staticClass;

    const defaultProperty = {
        className: clsx(classNames, className),
        ref,
        ...other,
    };

    return (
        <Tablelvl2Context.Provider value={tablelvl2}>
            <tfoot {...defaultProperty} />
        </Tablelvl2Context.Provider>
    );
});

export default TableFooter;
