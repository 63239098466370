import * as React from 'react';

let globalId = 0;

/**
 * @param {string} idOverride
 * @return {string | undefined}
 */

const useGlobalId = (idOverride) => {
    const [defaultId, setDefaultId] = React.useState(idOverride);
    const id = idOverride || defaultId;
    React.useEffect(() => {
        if (defaultId == null) {
            // Fallback to this default id when possible.
            // Use the incrementing value for client-side rendering only.
            // We can't use it server-side.
            // If you want to use random values please consider the Birthday Problem: https://en.wikipedia.org/wiki/Birthday_problem
            globalId += 1;
            setDefaultId(`globalId-${globalId}`);
        }
    }, [defaultId]);
    return id;
};

/**
 * @return {undefined | (() => string)}
 */
const maybeReactUseId = React['useId'];

/**
 *
 * @example <div id={useId()} />
 * @param {string} idOverride
 * @returns {string | undefined}
 */
const useId = (idOverride) => {
    if (maybeReactUseId !== undefined) {
        const reactId = maybeReactUseId();
        return idOverride ?? reactId;
    }
    // eslint-disable-next-line
    return useGlobalId(idOverride);
};

export default useId;
