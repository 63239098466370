import React, { useImperativeHandle, useRef, useState, useEffect } from 'react';
import { Dialog, DialogActions, DialogContent, Grid } from '@mui/material';
import clsx from 'clsx';
import { Button, Form, MenuItem, SelectField, TextField } from '@common/components/';
import GradientColorHeader from '@icoach/components/dialog/GradientColorHeader';
import { refIsRequiredError } from '@util/utils';
import { useMemberProfileApi } from '@apis/useMemberProfileApi';

export const PushDialogContent = React.forwardRef((props, ref) => {
    const { data = {}, resource = {} } = props;
    const { pushStatus, reasonType, reasonText } = data;
    const { pushReasonTypeOptions, pushStatusOptions } = resource;
    const [isShow, setShow] = useState(Boolean(reasonType) && reasonType.toString() === '99');
    const pushStatusRef = useRef();
    const reasonTypeRef = useRef();
    const reasonTextRef = useRef();

    const handleChangeReasonType = (e) => {
        let val = e.target.value;
        if (val.toString() === '99' && !isShow) {
            setShow(true);
        } else if (isShow) {
            setShow(false);
        }
    };

    useImperativeHandle(
        ref,
        () => ({
            getResult: () => {
                return {
                    pushStatus: (pushStatusRef.current && pushStatusRef.current.getResult()) || 0,
                    reasonType: (reasonTypeRef.current && reasonTypeRef.current.getResult()) || 0,
                    reasonText: (reasonTextRef.current && reasonTextRef.current.getResult()) || '',
                };
            },
            isError: () => refIsRequiredError(pushStatusRef, reasonTypeRef, reasonTextRef),
        }),
        // eslint-disable-next-line
        []
    );

    return (
        <DialogContent>
            <Grid container spacing={3}>
                <Grid item xs={6}>
                    <SelectField label="訂單狀態" ref={pushStatusRef} defaultValue={pushStatus} displayEmpty fullWidth required>
                        <MenuItem disabled value="">
                            <em>請選擇</em>
                        </MenuItem>
                        {Array.isArray(pushStatusOptions) &&
                            pushStatusOptions.map(({ value, text, disabled }) => (
                                <MenuItem key={value} value={value} disabled={disabled}>
                                    {text}
                                </MenuItem>
                            ))}
                    </SelectField>
                </Grid>
                <Grid item xs={6}>
                    <SelectField label="原因" ref={reasonTypeRef} onChange={handleChangeReasonType} defaultValue={reasonType} displayEmpty fullWidth required>
                        <MenuItem disabled value="">
                            <em>請選擇</em>
                        </MenuItem>
                        {Array.isArray(pushReasonTypeOptions) &&
                            pushReasonTypeOptions.map(({ text, value, disabled }) => (
                                <MenuItem key={value} value={value} disabled={disabled}>
                                    {text}
                                </MenuItem>
                            ))}
                    </SelectField>
                </Grid>
                {isShow && (
                    <Grid item xs={12}>
                        <TextField
                            label="其他原因"
                            ref={reasonTextRef}
                            InputProps={{ placeholder: '請輸入' }}
                            defaultValue={reasonText}
                            multiline
                            fullWidth
                            required
                            rows={2}
                        />
                    </Grid>
                )}
            </Grid>
        </DialogContent>
    );
});

const ProbioticsPushDialog = (props) => {
    const { className, open, memberID, data, onClose, refresh } = props;
    const { postMemberProfileProbioticsPushApi, getMembersProbioticsOptionsApi } = useMemberProfileApi();
    const [options, setOptions] = useState([]);
    const contentRef = useRef();

    const handleSubmit = () => {
        const isError = contentRef.current && contentRef.current.isError();
        if (!isError) {
            const params = contentRef.current && contentRef.current.getResult();
            doMemberProfileProbioticsPushApi(memberID, params);
        }
    };

    const doMemberProfileProbioticsPushApi = async (mID, params) => {
        const res = await postMemberProfileProbioticsPushApi(mID, params);
        if (res) {
            refresh && refresh(mID);
            onClose();
        }
    };

    const doOptionsInitApi = async (mID) => {
        const resp = await getMembersProbioticsOptionsApi(mID);
        if (resp) {
            setOptions(resp);
        }
    };

    useEffect(
        () => {
            if (open && memberID) {
                doOptionsInitApi(memberID);
            }
        },
        // eslint-disable-next-line
        [open, memberID]
    );

    if (!open || options.length === 0) return null;

    return (
        <Dialog className={clsx('simple-content', 'dialog', className)} PaperProps={{ className: 'wx-50rem' }} open={open} fullWidth>
            <Form onSubmit={handleSubmit}>
                <GradientColorHeader onClose={onClose}>編輯益菌訂單</GradientColorHeader>
                <PushDialogContent ref={contentRef} data={data} resource={options} />
                <DialogActions>
                    <Button type={'submit'} variant="contained">
                        確認送出
                    </Button>
                </DialogActions>
            </Form>
        </Dialog>
    );
};

export default ProbioticsPushDialog;
