import React, { useRef, useEffect, useState, useImperativeHandle } from 'react';
import moment from 'moment';
import _ from 'lodash';
import clsx from 'clsx';

const DayItem = React.forwardRef((props, ref) => {
    const {
        className: classNameProps,
        onDayClick: onDayClickProps,
        onDayMouseEnter: onDayMouseEnterProps,
        onDayMouseLeave: onDayMouseLeaveProps,
        day: dayProps,
        dateText,
        isChecked,
    } = props;
    const [isSelect, setSelect] = useState(false);
    const dayRef = useRef(null);
    const isOtherMonth = classNameProps.indexOf('calendar-others') !== -1;

    const handleOnClick = (e) => {
        if (!isChecked) return;
        if (e.target.classList.contains('calendar-disabled')) return;
        const selected = dayRef.current.classList.toggle('calendar-selected');
        setSelect(selected);
        onDayClickProps && onDayClickProps(dayProps);
    };

    useImperativeHandle(
        ref,
        () => ({
            getResult: () => {
                const viewDate = isOtherMonth ? null : dayProps.format('YYYY-MM-DD');
                return isSelect ? viewDate : null;
            },
        }),
        // eslint-disable-next-line
        [isSelect]
    );

    useEffect(
        () => {
            setSelect(dayRef.current.classList.contains('calendar-selected'));
        },
        // eslint-disable-next-line
        []
    );

    return (
        <div
            ref={dayRef}
            className={clsx(classNameProps)}
            onClick={handleOnClick}
            onMouseEnter={() => onDayMouseEnterProps && onDayMouseEnterProps(dayProps)}
            onMouseLeave={() => onDayMouseLeaveProps && onDayMouseLeaveProps(dayProps)}
        >
            {!isOtherMonth && dateText}
        </div>
    );
});

export const CalendarDays = React.forwardRef(
    (
        {
            year,
            month,
            onRoleDayClassNames,
            onDayClick,
            onDayMouseEnter,
            onDayMouseLeave,
            isChecked,
            multiChoices,
        },
        ref
    ) => {
        const dayItemRef = useRef({});
        const getMonthWeekDays = () => {
            let weeks = [];
            let days = [];
            const currentDay = moment([year, month]);
            const nextMonth = moment([year, month]).add(1, 'month').subtract(1, 'day');
            currentDay.weekday(0);
            nextMonth.weekday(6);

            do {
                if (currentDay.weekday() === 0) days = [];
                days.push(moment(currentDay));
                if (currentDay.weekday() === 6) weeks.push(_.clone(days));
                currentDay.add(1, 'days');
            } while (!currentDay.isAfter(nextMonth, 'day'));

            return weeks;
        };

        const weeks = getMonthWeekDays();

        useImperativeHandle(
            ref,
            () => ({
                getResult: () => {
                    let dateValues = Object.values(dayItemRef.current);
                    return dateValues.reduce((acc, cur) => {
                        let newAcc = [...acc];
                        if (cur && typeof cur.getResult === 'function') {
                            const targetValue = cur.getResult();
                            targetValue && newAcc.push(targetValue);
                        }
                        return newAcc;
                    }, []);
                },
            }),
            // eslint-disable-next-line
            []
        );
        dayItemRef.current = {};
        return (
            <div className="calendar-container">
                {weeks.map((_days, i) => (
                    <div key={'week' + i} className="calendar-week">
                        {_days.map((_day) => {
                            const keyName = _day.format('[day]_YYYYMMDD');
                            return multiChoices ? (
                                <DayItem
                                    ref={(el) => (dayItemRef.current[keyName] = el)}
                                    key={keyName}
                                    className={clsx(
                                        'calendar-day',
                                        onRoleDayClassNames && onRoleDayClassNames(_day)
                                    )}
                                    onClick={() => onDayClick && onDayClick(_day)}
                                    onMouseEnter={() => onDayMouseEnter && onDayMouseEnter(_day)}
                                    onMouseLeave={() => onDayMouseLeave && onDayMouseLeave(_day)}
                                    dateText={_day.date()}
                                    day={_day}
                                    isChecked={isChecked}
                                    multiChoices={multiChoices}
                                />
                            ) : (
                                <div
                                    key={keyName}
                                    className={clsx(
                                        'calendar-day',
                                        onRoleDayClassNames && onRoleDayClassNames(_day)
                                    )}
                                    onClick={() => onDayClick && onDayClick(_day)}
                                    onMouseEnter={() => onDayMouseEnter && onDayMouseEnter(_day)}
                                    onMouseLeave={() => onDayMouseLeave && onDayMouseLeave(_day)}
                                >
                                    {_day.date()}
                                </div>
                            );
                        })}
                    </div>
                ))}
            </div>
        );
    }
);

export default CalendarDays;
