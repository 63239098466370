import React, { useMemo, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { isEmpty } from 'lodash';
import Tablenization from '@icoach/components/Tablenization';
import { MorePageBox, LabelHeaderCheckBox, useLabelCheckBox } from '@icoach/components/';
import { Stack, IconButton, Tooltip, Box } from '@common/components/';
import { GiftSolid as GiftSolidIcon, PaperPlane as PaperPlaneIcon, UserMinus as UserMinusIcon } from '@common/SvgIcon/';
import { MEMBERS_MEMBER_DETAIL_URL } from '@icoach/router/MembersRouter';
import { ListInfo } from '@icoach/accountBook/components';
import ScrollerArrowButton from '@icoach/inbody/components/ScrollerArrowButton';
import useMembers from '@icoach/members/components/useMembers';
import MemberDialogBox from '@icoach/members/dialog/MemberDialogBox';
import { giftDispatchByMemberList, membershipQuitByMemberList } from '@icoach/members/staticData';

// 條件篩選 or 快速名單
const OverviewTable = (props) => {
    const { targetType } = props;
    const { refresh, sourceData, rowsLabel, bookmark, sortOption, setBookmarkNum, setBookmarkSortBy, setContactState, reSetCheckedItemsRef } = useMembers();
    const { list: rowsData = [], pageIndex: page = 1, totalPage: count, pageSize, totalCount } = sourceData;
    const { subTabs } = useParams();
    const memberDialogBoxRef = useRef(null);
    const scrollerArrowRef = useRef(null);
    const headerRow = useMemo(() => [...rowsLabel], [rowsLabel]);
    const { sortBy } = bookmark;
    const { checkBoxsRef, headerCheckBoxRef, onHeaderChange, onCellChange, getCheckedItems, getCheckedSourceData, doSaveCheckedItem, doClearCheckedItems } =
        useLabelCheckBox();

    reSetCheckedItemsRef(doClearCheckedItems);

    const handlePageChange = (e, num) => {
        doSaveCheckedItem();
        setBookmarkNum(num);
    };

    const handleSendSMSClick = () => {
        let params = getCheckedSourceData();
        if (params.length === 0) return;
        params = params.map((target) => ({ id: target.memberID * 1, type: 2, name: target.nickName || target.name }));
        memberDialogBoxRef.current.openSmsDialog(params);
    };

    const handleGiftDispatchClick = () => {
        let params = getCheckedSourceData();
        params = params.reduce((acc, cur) => {
            return [...acc, cur.memberID];
        }, []);
        memberDialogBoxRef.current && memberDialogBoxRef.current.openGiftDispatchDialog({ memberIDs: params });
    };

    const handleBatchMembershipQuitClick = () => {
        let params = getCheckedSourceData();
        params = params.reduce((acc, cur) => {
            return [...acc, cur.memberID];
        }, []);
        memberDialogBoxRef.current.openBatchMembershipQuitDialog({ memberIDs: params }, refresh.current);
    };

    const pageNumberProps = useMemo(
        () => {
            let target = {};
            target.isPagination = true;
            target.page = page;
            target.count = count;
            target.onChange = handlePageChange;
            target.Pagination = MorePageBox;
            return target;
        },
        // eslint-disable-next-line
        [subTabs, page, count],
    );

    return (
        <Box className="customer-table">
            <Stack className={'mb-1'} alignItems={'center'} justifyContent={'space-between'}>
                {!isEmpty(rowsData) && (
                    <Stack className="pl-4 pb-1">
                        <LabelHeaderCheckBox key={subTabs} ref={headerCheckBoxRef} onChange={onHeaderChange} />
                        <Stack>
                            <Tooltip title="群發送簡訊">
                                <IconButton onClick={handleSendSMSClick}>
                                    <PaperPlaneIcon />
                                </IconButton>
                            </Tooltip>
                            {giftDispatchByMemberList.includes(String(targetType)) && (
                                <Tooltip title="贈品發送">
                                    <IconButton onClick={handleGiftDispatchClick}>
                                        <GiftSolidIcon />
                                    </IconButton>
                                </Tooltip>
                            )}
                            {membershipQuitByMemberList.includes(String(targetType)) && (
                                <Tooltip title="批次退會">
                                    <IconButton onClick={handleBatchMembershipQuitClick}>
                                        <UserMinusIcon />
                                    </IconButton>
                                </Tooltip>
                            )}
                        </Stack>
                    </Stack>
                )}
                <ListInfo
                    className={'ml-auto'}
                    pageSize={pageSize}
                    pageIndex={page}
                    totalCount={totalCount}
                    sortDefaultValue={sortBy}
                    sortOptions={sortOption}
                    sortOnChange={(val) => {
                        doClearCheckedItems();
                        setBookmarkSortBy(val);
                    }}
                    isSort={targetType}
                    isCurrentPageLastIndex
                />
            </Stack>
            <Box className={'scroll-arrow-container'}>
                <Tablenization {...pageNumberProps} tableContainerRef={scrollerArrowRef} cellWidth={12} isFixed>
                    <Tablenization.TableRowHeader headerRow={headerRow} />
                    <Tablenization.TableRowBody
                        dialogRef={memberDialogBoxRef}
                        headerRow={headerRow}
                        rowsData={rowsData}
                        routerPath={MEMBERS_MEMBER_DETAIL_URL}
                        targetType={targetType}
                        checkBoxsRef={checkBoxsRef}
                        onCheckBoxChange={onCellChange}
                        getCheckedItems={getCheckedItems}
                        setContactState={setContactState}
                    />
                </Tablenization>
                <ScrollerArrowButton parentRef={scrollerArrowRef} />
            </Box>
            <MemberDialogBox ref={memberDialogBoxRef} refresh={refresh} setContactState={setContactState} />
        </Box>
    );
};

export default OverviewTable;
