import React, { useRef } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import Tablenization from '@icoach/components/Tablenization';
import {
    DOCUMENTS_MEMBERSHIPTRANSFEROVER_PEOPLETYPE_REFERENCEID_CONTRACT_ROUTER,
    DOCUMENTS_MEMBERSHIPTRANSFEROVER_PEOPLETYPE_REFERENCEID_READONLY_CONTRACT_ROUTER,
} from '@icoach/router/routerPath';
import { parseDate } from '@util/moment';
import { pathRouterShit } from '@util/utils';
import useDocMembershipTransferOverApi from '@apis/useDocMembershipTransferOverApi';
import { moreColData } from '@icoach/documents/components/moreColData';
import useToggleDialog from '@util/hook/useToggleDialog';
import { dialogTypeMap } from '@icoach/documents/membership/staticData';
import { ConfirmDeleteDocDialog } from '@icoach/documents/membership/dialog';
import { DateTimeStandard } from '../../../components/pickers/enums/DateTimeStandard';

const rowsLabel = [
    {
        cellKey: 'applyDate',
        headerLabel: '申請時間',
        width: 20,
        formatCell: (cellValue) => parseDate(cellValue, DateTimeStandard.DisplayDate, false),
    },
    {
        cellKey: 'changeDate',
        headerLabel: '最後更新時間',
        width: 20,
        formatCell: (cellValue) => parseDate(cellValue, DateTimeStandard.DisplayFullBySlash, false),
    },
    {
        cellKey: 'isTemporary',
        headerLabel: '狀態',
        align: 'center',
        formatCell: (isTemporary, { isDeleted }) => {
            return isTemporary ? '暫存' : isDeleted ? '已刪除' : '已受理';
        },
    },
    moreColData,
];

const MembershipTransferOverHistoryTable = (props) => {
    const { className, sourceData: rowsData = [], onReload } = props;
    const { downloadTransferOverPDFApi, deleteTransferOverContractApi, deleteDocTemporaryTransferOverContractApi } = useDocMembershipTransferOverApi();
    const { openTarget, handleOpenDialog, handleCloseDialog, rowData } = useToggleDialog();
    const { peopleType: peopleTypeProps, referenceID: referenceIDProps } = useParams();
    const history = useHistory();
    const labelTitle = useRef(rowsLabel);

    // 查看
    const handleReviewOnClick = ({ contractID }) => {
        const path = pathRouterShit(DOCUMENTS_MEMBERSHIPTRANSFEROVER_PEOPLETYPE_REFERENCEID_READONLY_CONTRACT_ROUTER, {
            peopleType: peopleTypeProps,
            referenceID: referenceIDProps,
            contractID: contractID,
        });
        history.push(path);
    };

    // 編輯
    const handleEditOnClick = ({ contractID }) => {
        const path = pathRouterShit(DOCUMENTS_MEMBERSHIPTRANSFEROVER_PEOPLETYPE_REFERENCEID_CONTRACT_ROUTER, {
            peopleType: peopleTypeProps,
            referenceID: referenceIDProps,
            contractID: contractID,
        });
        history.push(path);
    };

    // 下載
    const handleDownloadPDFOnClick = (rowData) => {
        doTransferOverPDFApi({ contractUrl: rowData.contractUrl });
    };

    // 下載PDF API
    const doTransferOverPDFApi = async (params) => {
        await downloadTransferOverPDFApi(params);
    };

    // 刪除暫停合約資料
    const doDeleteApi = async (rowData) => {
        const { isTemporary, contractID } = rowData;
        let res;
        if (isTemporary) {
            res = await deleteDocTemporaryTransferOverContractApi({
                type: peopleTypeProps,
                id: referenceIDProps,
            });
        } else {
            res = await deleteTransferOverContractApi(contractID);
        }
        return res;
    };

    return (
        <React.Fragment>
            <Tablenization cardProps={{ className: className }}>
                <Tablenization.TableRowHeader headerRow={labelTitle.current} />
                <Tablenization.TableRowBody
                    headerRow={labelTitle.current}
                    rowsData={rowsData}
                    onReviewEvent={handleReviewOnClick}
                    onEditEvent={handleEditOnClick}
                    onDownloadPDFEvent={handleDownloadPDFOnClick}
                    onDeleteOnEvent={(rowData) => {
                        handleOpenDialog(dialogTypeMap.deleteDoc, { rowData });
                    }}
                />
            </Tablenization>
            <ConfirmDeleteDocDialog
                title={rowData?.isTemporary ? '確認刪除暫存轉讓申請書' : '確認刪除轉讓申請書'}
                open={openTarget === dialogTypeMap.deleteDoc}
                onClose={handleCloseDialog}
                data={rowData}
                deleteDocApi={doDeleteApi}
                refresh={onReload}
            />
        </React.Fragment>
    );
};

export default MembershipTransferOverHistoryTable;
