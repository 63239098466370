import React, { useRef } from 'react';
import clsx from 'clsx';
import { Card, Box, Stack, Typography } from '@common/components/';
import { ListInfo } from '@icoach/accountBook/components';
import ScrollerArrowButton from '@icoach/inbody/components/ScrollerArrowButton';
import { QuestionCircle as QuestionCircleIcon } from '@common/SvgIcon/';
import Tablenization from '@icoach/components/Tablenization';
import { usePointsProductGiftContext } from '@icoach/points/components/PointsProductGiftContext';

const headerRow = [
    {
        cellKey: 'imageSrc',
        headerLabel: '贈品',
        align: 'left',
        isFixed: true,
        isFixedLeft: true,
        formatCell: (value, rowData, { onClick }) => {
            return (
                <Stack alignItems="center" spacing={1}>
                    <Box className="product-gift-img-label">
                        <img src={value} alt={rowData.itemName} />
                    </Box>
                    <Typography variant="h6" className="font-weight-bold">
                        {rowData.itemName}
                    </Typography>
                    <QuestionCircleIcon
                        className="cursor-pointer"
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            onClick(rowData);
                        }}
                    />
                </Stack>
            );
        },
    },
    {
        cellKey: 'points',
        headerLabel: '兌換點數',
        align: 'center',
        width: 8,
        formatCell: (value) => {
            return <Typography variant="h5" component="span">{`${value}點`}</Typography>;
        },
    },
    {
        cellKey: 'activityRedeemQty',
        headerLabel: '兌換數量',
        align: 'center',
        width: 8,
        formatCell: (value) => {
            return (
                <Typography variant="h5" component="span">
                    {value}
                </Typography>
            );
        },
    },
];

const PointsProductGiftTable = React.forwardRef((props, ref) => {
    const { dialogRef, sourceData } = usePointsProductGiftContext();
    const scrollerArrowRef = useRef(null);

    const handleProdcutsInfoOnClick = (rowData) => {
        dialogRef.current.openPointsProductGuideDialog(rowData);
    };

    const handleRowOnClick = (rowData) => {
        dialogRef.current.openProductGiftRecordDialog(rowData);
    };

    return (
        <Card className="mt-4">
            <Card.CardContent className="p-3">
                <Stack direction="column">
                    <ListInfo className={clsx('ml-auto', 'mb-2')} pageSize={sourceData.length} pageIndex={1} totalCount={sourceData.length} />
                    <Box className={'scroll-arrow-container'}>
                        <Tablenization cardProps={{ className: 'theme-gray' }} tableContainerRef={scrollerArrowRef} cellWidth={12} isPagination isFixed>
                            <Tablenization.TableRowHeader headerRow={headerRow} />
                            <Tablenization.TableRowBody
                                headerRow={headerRow}
                                rowsData={sourceData}
                                onClick={handleProdcutsInfoOnClick}
                                rowOnClick={handleRowOnClick}
                            />
                        </Tablenization>
                        <ScrollerArrowButton parentRef={scrollerArrowRef} />
                    </Box>
                </Stack>
            </Card.CardContent>
        </Card>
    );
});

export default PointsProductGiftTable;
