import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import clsx from 'clsx';
import { Table, TableHead, TableBody, TableRow, TableCell, TextField, DateField } from '@common/components/';
import { ProductBox } from '@icoach/accountBook/components/';
import { refIsRequiredError } from '@util/utils';
import { CartTableItemProvider } from '@icoach/accountBook/context/CartTableItemContext';
import { useCartCardContext } from '@icoach/accountBook/context/CartCardContext';
import { parseDate } from '@util/moment';
import { NoDataTableRow, TableItemActions } from '@icoach/accountBook/tables/OrderCartTable';

const CartTableItemRow = React.forwardRef((props, ref) => {
    const { sourceData = {}, cartRef, handleDelete, handleUpdateCart } = props;
    const { name, month, assignPrice = 0, notes = '' } = sourceData;
    const [memo, setMemo] = useState(notes);
    const monthRef = useRef(null);
    const amountRef = useRef(null);

    const updateMoneyCart = () => {
        handleUpdateCart(cartRef);
    };

    const upDateMonthCart = () => {
        handleUpdateCart(cartRef);
    };

    useEffect(
        () => {
            handleUpdateCart(cartRef);
        },
        // eslint-disable-next-line
        [memo]
    );

    useImperativeHandle(
        ref,
        () => ({
            getResult: () => {
                return {
                    bonusText: sourceData.name,
                    month: monthRef.current.getResult(),
                    assignPrice: amountRef.current.getResult(),
                    notes: memo,
                };
            },
            isError: () => refIsRequiredError(monthRef, amountRef),
        }),
        // eslint-disable-next-line
        [memo]
    );

    return (
        <CartTableItemProvider
            value={{
                data: sourceData,
                cartRef,
                memo,
                setMemo,
                handleDelete,
            }}
        >
            <TableRow className={'cart-table-item fee-item'}>
                <TableCell>
                    <ProductBox className={'fee-item'} name={name} desc={memo && `備註：${memo}`} />
                </TableCell>
                <TableCell align={'center'}>
                    <DateField
                        ref={monthRef}
                        onChange={upDateMonthCart}
                        defaultValue={month}
                        direction={'row'}
                        views={['year', 'month']}
                        openTo="month"
                        format="YYYY/MM"
                        mask="____/__"
                        maxDate={parseDate()}
                        fullWidth
                        required
                    />
                </TableCell>
                <TableCell align={'center'}>
                    <TextField maskType={'MONEY'} ref={amountRef} defaultValue={assignPrice} onChangeNative={updateMoneyCart} required />
                </TableCell>
                <TableCell align={'right'}>
                    <TableItemActions
                        memoButtonProps={{ title: '發票備註' }}
                        memoDialogProps={{
                            title: '發票備註',
                            notes: '發票備註已有預設值，如非記載交易事項說明請勿填寫。',
                            maxLength: 40,
                            placeholder: '因綠界發票字數限字為40個字(內)，請勿超過',
                        }}
                    />
                </TableCell>
            </TableRow>
        </CartTableItemProvider>
    );
});

const CartTableHead = () => {
    return (
        <TableHead>
            <TableRow>
                <TableCell>品項</TableCell>
                <TableCell align={'center'}>紅利月份</TableCell>
                <TableCell align={'center'}>金額</TableCell>
                <TableCell align={'right'}></TableCell>
            </TableRow>
        </TableHead>
    );
};

// 匯款-紅利類
const RemitBonusCartTable = React.forwardRef((props, ref) => {
    const { className } = props;
    const { cartData, deleteCart, updateCart } = useCartCardContext();
    const cartRef = useRef([]);

    return (
        <Table className={clsx('cart-table', 'bonus-cart-table', className)}>
            <CartTableHead />
            <TableBody>
                {cartData.length > 0 ? (
                    cartData.map((item, i) => {
                        return (
                            <CartTableItemRow
                                key={`${item.name}_${i}`}
                                ref={(el) => (cartRef.current[i] = el)}
                                sourceData={item}
                                cartRef={cartRef}
                                handleDelete={deleteCart}
                                handleUpdateCart={updateCart}
                            />
                        );
                    })
                ) : (
                    <NoDataTableRow />
                )}
            </TableBody>
        </Table>
    );
});

export default RemitBonusCartTable;
