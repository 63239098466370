import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import clsx from 'clsx';
import { isEmpty } from 'lodash';
import { Dialog, DialogActions, DialogContent, Grid } from '@mui/material';
import { Button, Form, Box, Card, Typography, Stack, IconButton, Collapse, Alert } from '@common/components/';
import { AngleDown as AngleDownIcon, AngleUp as AngleUpIcon } from '@common/SvgIcon/';
import GradientColorHeader from '@icoach/components/dialog/GradientColorHeader';
import { SimpleContentItem } from '@icoach/members/components';
import { SalesAbandonItemTable } from '@icoach/accountBook/tables';
import { CheckoutPaidListBox, InvoiceInfoBox, OtherFieldBox } from '@icoach/accountBook/dialog/OrderAllowanceDialog';
import useOrderReturnApi from '@apis/useOrderReturnApi';
import { BATCH_ORDER } from '@icoach/accountBook/staticData';
import { refIsRequiredError } from '@util/utils';
import { displayDateText } from '@util/moment';

// 當前訂單
const OrderCard = React.forwardRef((props, ref) => {
    const { className, data = {} } = props;
    const { orderNo, orderDate, items } = data;
    return (
        <Card className={clsx('cart', className)}>
            <Card.CardContent>
                <Box className={'px-4 py-2'}>
                    <Grid spacing={2} container>
                        <Grid xs={4} item>
                            <SimpleContentItem label={'訂單編號'}>{orderNo}</SimpleContentItem>
                        </Grid>
                        <Grid xs={4} item>
                            <SimpleContentItem label={'訂單日期'}>{displayDateText(orderDate)}</SimpleContentItem>
                        </Grid>
                    </Grid>
                </Box>
                <SalesAbandonItemTable className={'theme-gray'} rowsData={items} />
            </Card.CardContent>
        </Card>
    );
});

// 其他訂單
const OtherOrderCard = React.forwardRef((props, ref) => {
    const { className, data } = props;
    const [open, setOpen] = useState(true);

    return (
        <Card className={clsx('cart', className)}>
            <Card.CardContent>
                <Stack className={'px-4 py-2'} justifyContent={'space-between'} alignItems={'center'}>
                    <Typography className={'p-0 m-0 font-weight-bold'} variant={'body1'} component={'h4'}>
                        其他訂單銷售品項
                    </Typography>
                    <IconButton onClick={() => setOpen((prev) => !prev)}>{open ? <AngleUpIcon /> : <AngleDownIcon />}</IconButton>
                </Stack>
                <Collapse in={open}>
                    <SalesAbandonItemTable className={'theme-gray'} rowsData={data} />
                </Collapse>
            </Card.CardContent>
        </Card>
    );
});

const OrderInvoiceAbandonDialogContent = React.forwardRef((props, ref) => {
    const { data } = props;
    const { isBatchOrder, otherItems, invoiceNo, invoiceTotalPrice, invoiceReturnPrice, isManual, paymentType } = data || {};
    const checkoutRef = useRef();
    const otherRef = useRef();
    const isCreditCardPayment = String(paymentType) === '1';
    const isACHPayment = String(paymentType) === '2';
    const isCashPayment = String(paymentType) === '3';
    const creditCardRefundHint = '此訂單完成折讓或作廢時將合併於批次扣款刷退中一併退還予會員';
    const achRefundHint = '此訂單完成折讓或作廢，請退還現金給會員';

    const getResult = () => {
        let result = {};
        const total = invoiceTotalPrice - invoiceReturnPrice;

        if (isBatchOrder && !isCashPayment) {
            let cashRefund = 0;
            let cardRefund = 0;
            if (isCreditCardPayment) {
                // 批次信用卡付款,批次退刷
                cardRefund = total;
            } else {
                // 批次ACH付款，退還現金
                cashRefund = total;
            }
            result = Object.assign(result, { cashRefund, cardRefund });
        } else {
            if (checkoutRef.current && checkoutRef.current.getResult) {
                result = Object.assign(result, checkoutRef.current.getResult());
            }
        }

        if (otherRef.current && otherRef.current.getResult) {
            result = Object.assign(result, otherRef.current.getResult());
        }
        return result;
    };

    useImperativeHandle(
        ref,
        () => ({
            getResult,
            isError: () => refIsRequiredError(checkoutRef, otherRef),
        }),
        // eslint-disable-next-line
        [invoiceTotalPrice, invoiceReturnPrice],
    );

    return (
        <DialogContent className={'bg-blue-gray-50 container main-container-spacing'}>
            <InvoiceInfoBox className={'mb-4'} data={data} />
            <OrderCard className={'mb-4'} data={data} />
            {!isEmpty(otherItems) && <OtherOrderCard className={'mb-4'} data={otherItems} />}
            <Grid className={'mb-4'} spacing={3} container>
                <Grid xs={6} item>
                    <OtherFieldBox ref={otherRef} data={data} />
                </Grid>
                <Grid xs={6} item>
                    <CheckoutPaidListBox
                        className={'mb-4'}
                        ref={checkoutRef}
                        total={invoiceTotalPrice - invoiceReturnPrice}
                        isShowCash={!isBatchOrder || isCashPayment} // 現場訂單或現金訂單才顯示
                        isShowCredit={!isBatchOrder || isCashPayment} // 現場訂單或現金訂單才顯示
                        belowText={isBatchOrder && isCreditCardPayment ? creditCardRefundHint : isACHPayment ? achRefundHint : ''}
                    />
                </Grid>
            </Grid>
            {!isManual && (
                <Alert variant="filled" severity="info" color="info">
                    {`發票 ${invoiceNo} 作廢後，所有訂單項目也會一同作廢且無法恢復。`}
                </Alert>
            )}
        </DialogContent>
    );
});

const OrderInvoiceAbandonDialog = (props) => {
    const { className, open, sourceData, isShowCash, isShowCredit, onClose, refresh } = props;
    const orderID = sourceData?.orderID;
    const { getOrderInvalidAbandonApi, postOrderInvalidAbandonConfirmApi } = useOrderReturnApi();
    const [data, setData] = useState({});
    const { items, otherItems, orderSourceType } = data;
    const isBatchOrder = String(orderSourceType) === BATCH_ORDER;
    const contentRef = useRef();

    const doOrderInvalidAbandonApi = async (orderID) => {
        let res = await getOrderInvalidAbandonApi(orderID);
        if (res) {
            setData(res);
        } else {
            onClose();
        }
    };

    const doOrderInvalidAbandonConfirmApi = async (params) => {
        let res = await postOrderInvalidAbandonConfirmApi(params);
        if (res) {
            if (typeof refresh === 'function') refresh();
            if (typeof onClose === 'function') onClose();
        }
    };

    const getParams = () => {
        let result = {
            isRefund: !isBatchOrder,
            items,
            otherItems,
        };
        if (contentRef.current && contentRef.current.getResult) {
            result = Object.assign(result, contentRef.current.getResult());
        }

        return result;
    };

    const handleSubmit = () => {
        let isError = contentRef.current && contentRef.current.isError();
        if (!isError) {
            let params = getParams();
            doOrderInvalidAbandonConfirmApi(params);
        }
    };

    useEffect(() => {
        if (open) doOrderInvalidAbandonApi(orderID);
        // eslint-disable-next-line
    }, [open]);

    if (!open || isEmpty(data)) return null;

    return (
        <Dialog
            className={clsx('simple-content', 'dialog', className)}
            PaperProps={{ className: 'wx-90rem', component: Form }}
            onSubmit={handleSubmit}
            open={open}
            fullWidth
        >
            <GradientColorHeader onClose={onClose}>作廢發票</GradientColorHeader>
            <OrderInvoiceAbandonDialogContent ref={contentRef} data={{ ...data, isBatchOrder }} isShowCash={isShowCash} isShowCredit={isShowCredit} />
            <DialogActions className={'bg-blue-gray-50'}>
                <Button type={'submit'} variant="contained">
                    確認作廢發票
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default OrderInvoiceAbandonDialog;
