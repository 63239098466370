import React, { useState, useImperativeHandle, useEffect } from 'react';
import { isEmpty } from 'lodash';
import { Grid } from '@mui/material/';
import { v4 as uuid } from 'uuid';
import { ArrowDropDown as ArrowDropDownIcon } from '@common/SvgIcon/';
import { TextField, InputAdornment, Popover, FormControlLabel, Checkbox } from '@common/components/';

// 變形下拉式選單(內容有checkbox)
const MultipleSelectCheckedBox = React.forwardRef((props, ref) => {
    const { options = [], label = '', showSelectAll = false, saleCategoryValue = {}, fullWidth = false } = props;
    const [anchorEl, setAnchorEl] = useState(null);
    const [checkedState, setChecked] = useState({});

    const handleAnchorElClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleAnchorElClose = () => {
        setAnchorEl(null);
    };

    const onCheckedChange = (e, isChecked) => {
        setChecked((pre) => ({
            ...pre,
            [e.target.value]: isChecked,
        }));
    };

    const onSelectAllOptionsChange = (e, isChecked) => {
        if (isChecked) {
            setChecked(() =>
                options.reduce((acc, { value: _v }) => {
                    let newAcc = Object.assign({}, acc);
                    Object.assign(newAcc, { [_v]: true });
                    return newAcc;
                }, {})
            );
        } else {
            setChecked({});
        }
    };

    useImperativeHandle(
        ref,
        () => {
            return {
                getResult: () => {
                    return Object.entries(checkedState)
                        .filter(([key, value]) => value)
                        .map(([num]) => num);
                },
            };
        },
        // eslint-disable-next-line
        [checkedState]
    );

    // 初始化預設選全部
    useEffect(
        () => {
            if (options.length !== 0 && showSelectAll) {
                onSelectAllOptionsChange(null, true);
            }
        },
        // eslint-disable-next-line
        [options]
    );

    // 初始化預設選全部
    useEffect(
        () => {
            if (!isEmpty(saleCategoryValue)) {
                setChecked(saleCategoryValue);
            }
        },
        // eslint-disable-next-line
        [saleCategoryValue]
    );

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    const counts = Object.values(checkedState).filter((isTrue) => isTrue).length;

    return (
        <React.Fragment>
            <TextField
                label={label || ''}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end" className={'cursor-pointer'}>
                            <ArrowDropDownIcon />
                        </InputAdornment>
                    ),
                }}
                value={label ? `${label}(${counts})` : ''}
                inputStyle={{ cursor: 'pointer' }}
                inputRootStyle={{ cursor: 'pointer' }}
                onClick={handleAnchorElClick}
                fullWidth={fullWidth}
                readOnly
            />
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleAnchorElClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <div className="py-2 px-4" style={{ width: 520 }} spacing={2}>
                    <Grid container>
                        {showSelectAll && (
                            <Grid item xs={12} key={'all'}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            value={'all'}
                                            onChange={onSelectAllOptionsChange}
                                            checked={counts === options.length}
                                            indeterminate={counts !== options.length && counts !== 0}
                                        />
                                    }
                                    label={'全選'}
                                />
                            </Grid>
                        )}
                        {options.map(({ text, value, disabled }) => {
                            return (
                                <Grid item xs={4} key={uuid()}>
                                    <FormControlLabel
                                        control={<Checkbox value={value} onChange={onCheckedChange} checked={!!checkedState[value]} />}
                                        label={text}
                                        disabled={disabled}
                                    />
                                </Grid>
                            );
                        })}
                    </Grid>
                </div>
            </Popover>
        </React.Fragment>
    );
});

export default MultipleSelectCheckedBox;
