import React, { useState } from 'react';
import GiftDispatchRecordSearchBar from '@icoach/gift/GiftDispatchRecordSearchBar';
import GiftDispatchRecordTable from '@icoach/gift/GiftDispatchRecordTable';
import { GiftProvider, useGiftContext } from '@icoach/gift/components/GiftContext';
import { initBookmark } from '@icoach/gift/staticData';

const GiftDispatchRecordPage = (props) => {
    const giftContext = useGiftContext();
    const [sourceData, setSourceData] = useState({});
    const [bookmark, setBookmark] = useState(initBookmark(1));
    const [sortOption, setSortOption] = useState([]);

    const updateGiftItemData = (giftData) => {
        const _list = sourceData.list;
        const targetID = giftData?.giftID ?? 0;
        const list = _list.map((item) => {
            if (item.giftID === targetID) {
                item = {
                    ...item,
                    ...giftData,
                };
            }
            return item;
        });

        setSourceData((prev) => {
            return {
                ...prev,
                list,
            };
        });
    };

    const childContext = {
        ...giftContext,
        sourceData,
        setSourceData,
        bookmark,
        sortOption,
        setSortOption,
        setBookmarkNum: (num) => setBookmark((prev) => ({ ...prev, pageIndex: num })),
        setSortBy: (value) => setBookmark((prev) => ({ ...prev, sortBy: value })),
        updateGiftItemData,
    };

    return (
        <GiftProvider value={childContext}>
            <GiftDispatchRecordSearchBar className={'mb-2'} />
            <GiftDispatchRecordTable />
        </GiftProvider>
    );
};

export default GiftDispatchRecordPage;
