import React, { useEffect, useRef, useState } from 'react';
import { Button } from '@common/components/';
import GradientColorHeader from '@icoach/components/dialog/GradientColorHeader';
import { Dialog, DialogContent, Grid } from '@mui/material';
import SignatureCanvas from 'react-signature-canvas';

// 筆觸設定
const signConfig = {
    minWidth: 1.8,
    maxWidth: 2.8,
};

const SignatureDialog = (props) => {
    const { onClose: propsOnClose, onCapture: propsOnCapture, signImg: signImgProps, open, titleText } = props;
    const [dataURL, setDataURL] = useState('');
    const signPadRef = useRef(null);

    const handleCloseOnClick = (e) => {
        propsOnClose && propsOnClose(e);
    };

    const handleCleanPannelClick = (e) => {
        if (signPadRef.current) signPadRef.current.clear();
    };

    const handleSignDataURLClick = (e) => {
        if (signPadRef.current) {
            let dataURL = '';
            if (!signPadRef.current.isEmpty()) {
                dataURL = signPadRef.current.toDataURL();
            }

            propsOnCapture && propsOnCapture(e, dataURL);
            propsOnClose && propsOnClose(e);
        }
    };

    useEffect(() => {
        if (signPadRef.current) {
            const canvasWidth = signPadRef.current.getCanvas().width;
            const canvasHeight = signPadRef.current.getCanvas().height;
            signPadRef.current.fromDataURL(dataURL, { width: canvasWidth, height: canvasHeight });
        }
    }, [dataURL]);

    useEffect(() => {
        if (open && signImgProps) {
            setDataURL(signImgProps);
        }
        return () => {
            setDataURL('');
        };
    }, [open, signImgProps]);

    return (
        <Dialog open={open} fullWidth maxWidth="md">
            <GradientColorHeader onClose={handleCloseOnClick}>{titleText}</GradientColorHeader>
            <DialogContent style={{ backgroundColor: '#bdbdbd', padding: '16px 12px' }}>
                <SignatureCanvas
                    key={signImgProps}
                    ref={signPadRef}
                    canvasProps={{
                        className: 'signature-dialog-canvas-container',
                    }}
                    {...signConfig}
                />
            </DialogContent>
            <DialogContent style={{ padding: 0 }}>
                <Grid container>
                    <Grid item xs={6}>
                        <Button color="secondary" className="signature-dialog-action-button" size="large" onClick={handleCleanPannelClick}>
                            清除
                        </Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Button variant="contained" color="secondary" className="signature-dialog-action-button" size="large" onClick={handleSignDataURLClick}>
                            確定
                        </Button>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};

export default SignatureDialog;
