import React, { useImperativeHandle, useRef } from 'react';
import clsx from 'clsx';
import { Dialog, DialogActions, DialogContent, Grid } from '@mui/material';
import { Button, Form, TextField } from '@common/components/';
import GradientColorHeader from '@icoach/components/dialog/GradientColorHeader';
import { refIsRequiredError } from '@util/utils';
import { useMemberProfileApi } from '@apis/useMemberProfileApi';

export const NotesDialogContent = React.forwardRef((props, ref) => {
    const { title, defaultValue } = props;
    const fieldRef = useRef(null);

    useImperativeHandle(
        ref,
        () => ({
            getResult: () => {
                return fieldRef.current.getResult();
            },
            isError: () => refIsRequiredError(fieldRef),
        }),
        // eslint-disable-next-line
        []
    );

    return (
        <DialogContent>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <TextField label={title} ref={fieldRef} InputProps={{ placeholder: '請輸入' }} defaultValue={defaultValue} multiline fullWidth rows={4} />
                </Grid>
            </Grid>
        </DialogContent>
    );
});

const GuidanceNotesDialog = (props) => {
    const { className, open, memberID, data, onClose, refresh } = props;
    const { postMemberProfileNotesApi } = useMemberProfileApi();
    const contentRef = useRef();

    const doMemberProfileNotesApi = async (memberID, params) => {
        let res = await postMemberProfileNotesApi(memberID, params);
        if (res) {
            refresh && refresh(memberID);
            onClose();
        }
    };

    const getParams = () => {
        return {
            noteType: 3,
            notes: contentRef.current && contentRef.current.getResult(),
        };
    };
    const handleSubmit = () => {
        let isError = contentRef.current && contentRef.current.isError();
        if (!isError) {
            let params = getParams();
            doMemberProfileNotesApi(memberID, params);
        }
    };
    return (
        <Dialog
            className={clsx('simple-content', 'dialog', className)}
            PaperProps={{ className: 'wx-40rem', component: Form }}
            onSubmit={handleSubmit}
            open={open}
            fullWidth
        >
            <GradientColorHeader onClose={onClose}>編輯指導注意</GradientColorHeader>
            <NotesDialogContent ref={contentRef} defaultValue={data} title={'指導注意'} />
            <DialogActions>
                <Button type={'submit'} variant="contained">
                    確認送出
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default GuidanceNotesDialog;
