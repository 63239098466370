import { useCallback } from 'react';
import { useSnackbar } from 'notistack';
import useAjax from '@apis/useAjax';
import { serialize } from '@util/utils';

const GET_EMPLOYEES_CHECKIN_EMPLOYEEID_API = (employeeID) => `/api/employees/checkin/${employeeID}/`;
const GET_EMPLOYEES_CHECKIN_EMPLOYEEID_EXPORT_API = (employeeID) => `/api/employees/checkin/${employeeID}/export/`;

const useEmployeeCheckInApi = () => {
    const Ajax = useAjax();
    const { enqueueSnackbar } = useSnackbar();

    // 依員工代碼取得員工報到紀錄
    const getEmployeesClockInRecordApi = useCallback(
        async (empID, params) => {
            const resp = await Ajax.get(GET_EMPLOYEES_CHECKIN_EMPLOYEEID_API(empID), params);
            if (!resp) enqueueSnackbar('讀取員工出勤紀錄失敗，請再操作一次', { variant: 'error' });
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    // 匯出員工出勤 EXCEL
    const getEmployeesClockInExcelApi = useCallback(
        async (empID, params) => {
            const resp = await window.open(`${GET_EMPLOYEES_CHECKIN_EMPLOYEEID_EXPORT_API(empID)}?${serialize(params)}`);
            if (!resp) enqueueSnackbar('匯出員工出勤失敗，請再操作一次', { variant: 'error' });
        },
        // eslint-disable-next-line
        []
    );

    return { getEmployeesClockInRecordApi, getEmployeesClockInExcelApi };
};

export default useEmployeeCheckInApi;
