import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { Prompt, useHistory } from 'react-router-dom';
import useMessageDialog from '@util/hook/useMessageDialog';
import { setHistoryLockData as setHistoryLockDataAction } from '@redux/historyLock/action';
import { getHistoryLockData } from '@redux/historyLock/selector';

const PromptDialog = React.memo((props) => {
    const { when: whenProps = true, message: messageProps = '' } = props;
    const dispatch = useDispatch();
    const [isShowPrompt, setIsShowPrompt] = useState(whenProps);
    const isHistoryLock = useSelector(getHistoryLockData);
    const setMessageDialog = useMessageDialog();
    const history = useHistory();
    const [messageState] = useState(messageProps ? messageProps : '此動作將會遺失資料');

    const setLcok = useCallback(
        (params) => {
            dispatch(setHistoryLockDataAction(params));
        },
        // eslint-disable-next-line
        []
    );

    useEffect(
        () => {
            if (isHistoryLock) {
            }
        },
        // eslint-disable-next-line
        [isHistoryLock]
    );

    const handleBeforeUnloadCallback = useCallback(
        (event) => {
            event.preventDefault();
            event.returnValue = '';
            return '此動作將會遺失資料';
        },
        // eslint-disable-next-line
        []
    );

    useEffect(
        () => {
            window.addEventListener('beforeunload', handleBeforeUnloadCallback);
            return () => {
                window.removeEventListener('beforeunload', handleBeforeUnloadCallback);
            };
        },
        // eslint-disable-next-line
        []
    );

    useEffect(
        () => {
            setIsShowPrompt(whenProps);
            setLcok({
                isLock: true,
                fn: handleBeforeUnloadCallback,
            });
            return () => {
                setLcok({
                    isLock: false,
                    fn: null,
                });
                setIsShowPrompt(false);
            };
        },
        // eslint-disable-next-line
        [whenProps, handleBeforeUnloadCallback]
    );

    return (
        <Prompt
            when={isShowPrompt}
            message={({ pathname }, type) => {
                setIsShowPrompt(false);
                setMessageDialog({
                    open: true,
                    title: '確定要離開',
                    msg: `${messageState}，確定要離開?`,
                    onAsyncConfirm: (e, onClose) => {
                        const action = type === `POP` ? 'push' : type.toLocaleLowerCase();
                        history[action] && history[action](pathname);
                        onClose && onClose();
                    },
                    onClose: () => {
                        setIsShowPrompt(true);
                    },
                });
                return false;
            }}
        />
    );
});

export default PromptDialog;
