import React from 'react';
import SvgIcon from '../SvgIcon';
import useForkRef from '@util/hook/useForkRef';

const PlusCircle = React.forwardRef((props, ref) => {
    const { className, ...others } = props;
    const handleRef = React.useRef(null);
    const svgRef = useForkRef(handleRef, ref);
    const defaultProperty = {
        className,
        ref: svgRef,
        ...others,
    };
    return (
        <SvgIcon {...defaultProperty}>
            <path
                d="M0,12C0,5.4,5.4,0,12,0s12,5.4,12,12s-5.4,12-12,12S0,18.6,0,12z M12,17.2c0.6,0,1.1-0.5,1.1-1.1v-3h3
	c0.6,0,1.1-0.5,1.1-1.1c0-0.6-0.5-1.1-1.1-1.1h-3v-3c0-0.6-0.5-1.1-1.1-1.1c-0.6,0-1.1,0.5-1.1,1.1v3h-3c-0.6,0-1.1,0.5-1.1,1.1
	c0,0.6,0.5,1.1,1.1,1.1h3v3C10.9,16.7,11.4,17.2,12,17.2z"
            />
        </SvgIcon>
    );
});

export default PlusCircle;
