import React, { useRef } from 'react';
import { formatCurrencyFn } from '../../../../util/utils';
import Tablenization from '../../../components/Tablenization';
import { displayDateText } from '../../../../util/moment';
import { Chip } from '../../../../components';
import { COLOR_MATCH } from '../../../accountBook/staticData';

const rowsLabel = [
    {
        cellKey: 'orderDate',
        headerLabel: '日期',
        formatCell: (cellValue) => displayDateText(cellValue),
    },
    {
        cellKey: 'productName',
        headerLabel: '品項',
    },
    {
        cellKey: 'variantName',
        headerLabel: '尺寸',
        align: 'center',
    },
    {
        cellKey: 'originalPrice',
        headerLabel: '單價',
        align: 'center',
        formatCell: (cellValue) => formatCurrencyFn(cellValue),
    },
    {
        cellKey: 'quantity',
        headerLabel: '數量',
        align: 'center',
    },
    {
        cellKey: 'salePrice',
        headerLabel: '金額',
        align: 'right',
        formatCell: (cellValue) => formatCurrencyFn(cellValue),
    },
    {
        cellKey: 'purchaseTimeTypeText',
        headerLabel: '時間點',
        align: 'center',
    },
    {
        cellKey: 'purchaseToolTypeText',
        headerLabel: '使用工具',
        align: 'center',
    },
    {
        cellKey: 'purchaseNotes',
        headerLabel: '活動名稱',
        align: 'center',
    },
    {
        cellKey: 'productTypeText',
        headerLabel: '方式',
        align: 'center',
    },
    {
        cellKey: 'invoiceStatusText',
        headerLabel: '發票狀態',
        align: 'center',
        formatCell: (cellValue, rowData) => {
            const { invoiceStatus } = rowData;
            return <Chip label={cellValue} color={COLOR_MATCH[invoiceStatus]} />;
        },
    },
    {
        cellKey: 'invoiceNo',
        headerLabel: '發票號碼',
        align: 'center',
    },
    {
        cellKey: 'employeeName',
        headerLabel: '擔當',
        align: 'center',
    },
    {
        cellKey: 'notes',
        headerLabel: '備註',
    },
];

const PhysicalOrderTable = (props) => {
    const { className, rowsData = [] } = props;
    const labelTitle = useRef(rowsLabel);

    return (
        <React.Fragment>
            <Tablenization cardProps={{ className: className }}>
                <Tablenization.TableRowHeader headerRow={labelTitle.current} />
                <Tablenization.TableRowBody headerRow={labelTitle.current} rowsData={rowsData} />
            </Tablenization>
        </React.Fragment>
    );
};

export default PhysicalOrderTable;
