import React, { useImperativeHandle, useRef } from 'react';
import clsx from 'clsx';
import { Dialog, DialogActions, DialogContent } from '@mui/material';
import { Button, Form } from '@common/components/';
import { ACHCard } from '@icoach/documents/components';
import GradientColorHeader from '@icoach/components/dialog/GradientColorHeader';
import { useMemberProfileApi } from '@apis/useMemberProfileApi';
import { useMemberDetailContext } from '@icoach/members/memberDetail/MemberDetailContext';

export const ACHBankDialogContent = React.forwardRef((props, ref) => {
    const { sourceData } = props;
    const achRef = useRef(null);

    let handleDefaultValue = (defaultValue) => {
        if (!defaultValue) return undefined;
        let {
            memberPaymentID,
            title,
            bankCode,
            bankName,
            bankAccount,
            bankAccountName,
            bankAccountID,
            paymentType,
        } = defaultValue;
        return {
            memberPaymentID,
            title,
            bankCode,
            bankName,
            bankAccount,
            bankAccountName,
            bankAccountID,
            paymentType,
        };
    };

    useImperativeHandle(
        ref,
        () => ({
            getResult: () => {
                let { memberPaymentID, title, paymentType } = sourceData;
                let result = {
                    memberPaymentID,
                    paymentType,
                    title,
                    ...achRef.current.getResult(),
                };

                return result;
            },
            isError: () => {
                return achRef.current.isError();
            },
        }),
        // eslint-disable-next-line
        []
    );
    return (
        <DialogContent>
            <ACHCard
                ref={achRef}
                isSign={false}
                defaultValue={handleDefaultValue(sourceData)}
                gridItemsSize={{
                    1: { xs: 6 },
                    2: { xs: 6 },
                    3: { xs: 6 },
                    4: { xs: 6 },
                    5: { xs: 6 },
                    6: { xs: 6 },
                }}
            />
        </DialogContent>
    );
});

const AchBankDialog = (props) => {
    const { className, open, memberID, sourceData, onClose } = props;
    const { refresh } = useMemberDetailContext();
    const { postMemberPaymentBankApi } = useMemberProfileApi();
    const contentRef = useRef(null);

    const doMemberPaymentBankApi = async (memberID, params) => {
        let res = await postMemberPaymentBankApi(memberID, params);
        if (res) {
            refresh.current && refresh.current(memberID);
            onClose();
        }
    };

    const handleSubmit = () => {
        let isError = contentRef.current && contentRef.current.isError();
        if (!isError) {
            let params = contentRef.current && contentRef.current.getResult();
            doMemberPaymentBankApi(memberID, params);
        }
    };

    if (!open) return null;

    return (
        <Dialog
            className={clsx('simple-content', 'dialog', className)}
            PaperProps={{ className: 'wx-65rem', component: Form }}
            onSubmit={handleSubmit}
            open={open}
            fullWidth
        >
            <GradientColorHeader onClose={onClose}>修改ACH銀行付款資訊</GradientColorHeader>
            <ACHBankDialogContent ref={contentRef} sourceData={sourceData} />
            <DialogActions>
                <Button type={'submit'} variant="contained">
                    確認送出
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default AchBankDialog;
