import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { Button, Card, Tab, Tabs } from '../../../components';
import MemberDetailRentCabinetContentBillingRecord from './MemberDetailRentCabinetContentBillingRecord';
import MemberDetailRentCabinetContentDocument from './MemberDetailRentCabinetContentDocument';
import { RENT_CABINET_DOCUMENT_CREATE_URL } from '../../router/MembersRouter';
import { serialize } from '../../../util/utils';

const MemberDetailRentCabinetContent = (props) => {
    const { className, memberID, setTab } = props;
    const tabs = props.match.params.tabs;
    const [tabsIndex, setTabsIndex] = useState('document');
    const handleTabChange = (e, value) => {
        setTabsIndex(value);
    };

    useEffect(() => {
        if (tabs) {
            setTab(tabs);
        }
        // eslint-disable-next-line
    }, [tabs]);

    return (
        <Card className={clsx(className, 'tabs-content-layout')}>
            <header className={'page-header'}>
                <Tabs value={tabsIndex} onChange={handleTabChange}>
                    <Tab
                        className={clsx({
                            state: tabsIndex === 'uncheckout',
                        })}
                        label={'租櫃資訊'}
                        value={'document'}
                    />
                    <Tab
                        className={clsx({
                            state: tabsIndex === 'basic',
                        })}
                        label={'收費明細'}
                        value={'billing'}
                    />
                </Tabs>
                <div className="btn-group no-gutter">
                    <Button
                        variant={'outlined'}
                        color="secondary"
                        href={`${RENT_CABINET_DOCUMENT_CREATE_URL}?${serialize({ memberID })}`}
                    >
                        新增租約
                    </Button>
                </div>
            </header>
            <Card.CardContent className="pt-4 px-4 pb-3">
                {tabsIndex === 'document' && (
                    <MemberDetailRentCabinetContentDocument memberID={memberID} />
                )}
                {tabsIndex === 'billing' && (
                    <MemberDetailRentCabinetContentBillingRecord memberID={memberID} />
                )}
            </Card.CardContent>
        </Card>
    );
};

export default MemberDetailRentCabinetContent;
