import React, { useState, useLayoutEffect } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import 'dayjs/locale/zh-tw';
import { PrivateRoute, IconButton } from './scripts/components';
import { Close as CloseIcon } from './scripts/components/icons/SvgIcon/';
import ShowLoading from './scripts/components/loading/ShowLoading';
import LoginPage from './scripts/login';
import Home from './scripts/home';

import { SnackbarProvider } from 'notistack';
import './scss/site.scss';
import './scss/icoach/index.scss';
import AppVersion from './AppVersion';

const App = () => {
    const [isRoute, setRoute] = useState(false);
    const { pathname: rootPath = '' } = useLocation();
    const notistackRef = React.createRef(null);
    const onClickDismiss = (key) => () => {
        notistackRef.current.closeSnackbar(key);
    };

    useLayoutEffect(
        () => {
            const splitPathname = rootPath.split('/');
            if (splitPathname.length > 1 && splitPathname[1] === 'login') setRoute(true);
        },
        // eslint-disable-next-line
        [rootPath],
    );

    return (
        <React.Fragment>
            <SnackbarProvider
                className="snacker-massage"
                ref={notistackRef}
                maxSnack={3}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                action={(key) => (
                    <IconButton onClick={onClickDismiss(key)}>
                        <CloseIcon htmlColor="#fff" />
                    </IconButton>
                )}
            >
                <AppVersion />
                <Switch>
                    {isRoute && <Route exact path="/login/" component={LoginPage} />}
                    <PrivateRoute as={Home} />
                </Switch>
            </SnackbarProvider>
            <ShowLoading />
        </React.Fragment>
    );
};

export default App;
