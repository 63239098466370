import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { Button, Card, DateIntervalField, Form, Stack } from '@common/components/';
import { initBookmark } from './staticData';
import { ACCOUNT_BOOK_REMIT_ORDERS_OVERVIEW_URL } from '@icoach/router/AccountBookRouter';
import { isEmpty, objectToQueryString, paramsToObject } from '@util/utils';
import { useHistory } from 'react-router-dom';
import { parseDate } from '@util/moment';
import useClubRemittanceApi from '@apis/useClubRemittanceApi';
import { useAccountBookContext } from '@icoach/accountBook/components/AccountBookContext';

const AccountBookRemitOverviewSearchBar = (props) => {
    const { className } = props;
    const history = useHistory();
    const { getRemittanceListApi } = useClubRemittanceApi();
    const { setTableData, bookmark, setBookmarkNum, setRefresh } = useAccountBookContext();
    const [paramData, setParamData] = useState({});
    const { start = '', end = '' } = paramData || {};
    const dateRangeRef = useRef();
    const preloadRef = useRef(false);

    const updateUrlWithSearchQuery = (params) => {
        let url = ACCOUNT_BOOK_REMIT_ORDERS_OVERVIEW_URL;
        if (!isEmpty(params)) url += objectToQueryString(params);
        history.replace(url);
    };

    const getParams = (pageInfo = {}) => {
        const result = {};

        if (pageInfo) {
            Object.assign(result, pageInfo);
        }

        if (dateRangeRef && dateRangeRef.current && dateRangeRef.current.getResult) {
            const { startDate: start, endDate: end } = dateRangeRef.current.getResult();
            Object.assign(result, { start, end });
        }

        return result;
    };

    const getOverviewApi = async (params) => {
        preloadRef.current = false;
        let resp = await getRemittanceListApi(params);
        if (resp) {
            setTableData(resp);
        }
        updateUrlWithSearchQuery(params);
        preloadRef.current = true;
    };

    const handleSearchOnClick = async () => {
        const isError = dateRangeRef.current.isError();
        if (!isError) {
            const params = getParams(initBookmark());
            getOverviewApi(params);
            setBookmarkNum(1);
        }
    };

    useEffect(() => {
        if (!preloadRef.current) return;
        const params = getParams(bookmark);
        getOverviewApi(params);
        // eslint-disable-next-line
    }, [bookmark.pageIndex]);

    useEffect(() => {
        setRefresh(handleSearchOnClick);
        const { location } = history;
        const search = location.search;
        const params = search ? paramsToObject(search) : getParams(bookmark);
        if (!isEmpty(params)) {
            setParamData(params);
        }
        getOverviewApi(params);
        // eslint-disable-next-line
    }, []);

    return (
        <Card className={clsx('search-bar px-4 py-3', className)}>
            <Card.CardContent>
                <Form onSubmit={handleSearchOnClick}>
                    <Stack justifyContent={'start'} alignItems={'start'} spacing={2}>
                        <DateIntervalField
                            key={`${start}_${end}`}
                            ref={dateRangeRef}
                            label={'匯款日期'}
                            className={'flex-auto'}
                            direction={'row'}
                            startDateProps={{
                                defaultValue: parseDate(start),
                            }}
                            endDateProps={{
                                defaultValue: parseDate(end),
                            }}
                            fullWidth
                        />
                        <Button type={'submit'} className={'white-space-nowrap'} variant={'contained'}>
                            搜尋
                        </Button>
                    </Stack>
                </Form>
            </Card.CardContent>
        </Card>
    );
};

export default AccountBookRemitOverviewSearchBar;
