import React from 'react';
import { Card, Typography } from '../../../components';

const TermContent = (props) => {
    const { className, data, title, subheader, describe = '', children = null } = props;

    return (
        <Card className={className}>
            <Card.CardHeader
                title={title}
                subheader={subheader}
                className="bg-light-active text-center"
            />
            <Card.CardContent className="p-4">
                {describe && (
                    <Typography variant="h5" component="div" className="pl-2 mb-2">
                        {describe}
                    </Typography>
                )}
                {Array.isArray(data) && (
                    <ol className="lists ol mb-3">
                        {data.map((text, i) => (
                            <li key={i} className="lists-item">
                                {text}
                            </li>
                        ))}
                    </ol>
                )}
                {children}
            </Card.CardContent>
        </Card>
    );
};

export default TermContent;
