import React, { useEffect, useState } from 'react';
import { Typography } from '../../components';
import { OrderAllowanceRecordTable } from './tables';
import useOrderReturnApi from '../../apis/useOrderReturnApi';

const AccountBookOrderAllowanceRecordPage = (props) => {
    const orderID = props.match.params.orderID;
    const { getOrderAbandonAllowanceApi } = useOrderReturnApi();
    const [data, setData] = useState([]);

    const doOrderAbandonAllowanceApi = async (orderID) => {
        let res = await getOrderAbandonAllowanceApi(orderID);
        if (res) {
            setData(res);
        }
    };

    useEffect(() => {
        if (orderID) {
            doOrderAbandonAllowanceApi(orderID);
        }
        // eslint-disable-next-line
    }, [orderID]);

    return (
        <div className="container main-container-spacing">
            <header className={'page-header'}>
                <Typography className={'page-header-title'} variant={'h3'}>
                    訂單折讓歷史
                </Typography>
            </header>
            <OrderAllowanceRecordTable
                rowsData={data}
                refresh={() => doOrderAbandonAllowanceApi(orderID)}
            />
        </div>
    );
};

export default AccountBookOrderAllowanceRecordPage;
