import { useCallback } from 'react';
import useAjax from '@apis/useAjax';
import useShowPDF from '@util/hook/useShowPDF';

const GET_MEASURE_LEADERBOARD_OPTION_API = `/api/measure/leaderboard/option/`;
const GET_MEASURE_LEADERBOARD_OVERVIEW_API = `/api/measure/leaderboard/overview/`;
const POST_MEASURE_LEADERBOARD_PDF_API = (leaderboardType) => `/api/measure/leaderboard/leaderboardtpye/${leaderboardType}/`;
const GET_MEASURE_LEADERBOARD_ZIP_CLUBCODE_TARGETDATE_API = (clubCode, targetDate) => `/api/measure/leaderboard/${clubCode}/${targetDate}/savezip.zip`;
const GET_MEASURE_LEADERBOARD_MEMBERID_API = (memberID) => `/api/measure/leaderboard/member/${memberID}`;

const useMeasureLeaderboard = () => {
    const Ajax = useAjax();
    const showPDF = useShowPDF();

    const getMeasureLeaderboardOptionApi = useCallback(
        async () => {
            const resp = await Ajax.get(GET_MEASURE_LEADERBOARD_OPTION_API);
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    const getmMeasureLeaderboardOverviewApi = useCallback(
        async (params) => {
            const resp = await Ajax.get(GET_MEASURE_LEADERBOARD_OVERVIEW_API, params);
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    const postMeasureLeaderboardPDFApi = useCallback(
        async (params, leaderboardType) => {
            const resp = await Ajax.post(POST_MEASURE_LEADERBOARD_PDF_API(leaderboardType), params);
            if (resp) {
                showPDF({
                    open: true,
                    // htmlUrl: resp.htmlPath,
                    pdfUrl: resp.completedPath,
                });
            }
        },
        // eslint-disable-next-line
        []
    );

    const postMeasureLeaderboardZipApi = useCallback(
        (clubID, targetDate) => {
            window.open(GET_MEASURE_LEADERBOARD_ZIP_CLUBCODE_TARGETDATE_API(clubID, targetDate), '_blank');
        },
        // eslint-disable-next-line
        []
    );

    const getCheckHasTranscriptApi = useCallback(
        async (memberID, params) => {
            const resp = await Ajax.get(GET_MEASURE_LEADERBOARD_MEMBERID_API(memberID), params);
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    return {
        getMeasureLeaderboardOptionApi,
        getmMeasureLeaderboardOverviewApi,
        postMeasureLeaderboardPDFApi,
        postMeasureLeaderboardZipApi,
        getCheckHasTranscriptApi,
    };
};

export default useMeasureLeaderboard;
