import React, { useRef } from 'react';
import { Dialog, DialogContent, Grid } from '@mui/material';
import GradientColorHeader from '../../components/dialog/GradientColorHeader';
import { Checkbox, FormControlLabel, Button, Stack } from '../../../components';
import useEmployeeScheduleApi from '../../../apis/useEmployeeScheduleApi';

const AddEmployeeMatterDialog = React.forwardRef((props, ref) => {
    const {
        title: titleProps,
        onClose: onCloseProps,
        open: openProps,
        options: optionsProps,
        sourceData = [],
        apiParams = {},
        refresh,
    } = props;
    const { postEmployeeScheduleCoachOutDataApi } = useEmployeeScheduleApi();
    const checkGroupRef = useRef([]);
    const checkedEmployeeID = sourceData.map(({ employeeID }) => employeeID);

    const getParams = () => {
        const list = checkGroupRef.current;
        return list.reduce((acc, { checked, value }) => {
            let newArray = [...acc];
            if (checked) newArray.push(value);
            return newArray;
        }, []);
    };

    const handleAddEmployMatterOnClick = () => {
        let params = {
            employeeIDs: getParams(),
        };
        postCoachOutApi(params);
    };

    const postCoachOutApi = async (params) => {
        const resp = await postEmployeeScheduleCoachOutDataApi(
            Object.assign({}, params, apiParams)
        );
        if (resp) {
            onCloseProps && onCloseProps();
            refresh && refresh.current && refresh.current();
        }
    };

    return (
        <Dialog open={openProps} PaperProps={{ className: 'wx-65rem' }} fullWidth>
            <GradientColorHeader onClose={onCloseProps}>{titleProps}</GradientColorHeader>
            <DialogContent className="pt-4">
                <Stack spacing={2} direction="column" alignItems="end">
                    <Grid container spacing={2}>
                        {optionsProps.map(({ text, value, disabled }, index) => {
                            const isChecked =
                                checkedEmployeeID.findIndex(
                                    (id) => String(id) === String(value)
                                ) !== -1;

                            return (
                                <Grid item xs={6} key={value}>
                                    <FormControlLabel
                                        value={value}
                                        label={text}
                                        disabled={disabled}
                                        control={
                                            <Checkbox
                                                defaultChecked={isChecked}
                                                inputRef={(el) =>
                                                    (checkGroupRef.current[index] = el)
                                                }
                                            />
                                        }
                                    />
                                </Grid>
                            );
                        })}
                    </Grid>
                    <Button onClick={handleAddEmployMatterOnClick} variant="contained">
                        儲存名單
                    </Button>
                </Stack>
            </DialogContent>
        </Dialog>
    );
});

export default AddEmployeeMatterDialog;
