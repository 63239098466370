import React, { useImperativeHandle, useRef, useState } from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import OutlinedInput from '../outlinedInput/OutlinedInput';
import FormLabel from '../formLabel/FormLabel';
import FormControl from '../formControl/FormControl';
import FormHelperText from '../formHelperText/FormHelperText';
import Typography from '../typography/Typography';
import Stack from '../stack/Stack';
import useForkRef from '@util/hook/useForkRef';
import useControlled from '@util/hook/useControlled';
import { parseDate, isValidDate, isMonthDaysAfterError, isAfter } from '@util/moment';
import { NavigateNext as NavigateNextIcon, NavigateBefore as NavigateBeforeIcon, AngleDown as AngleDownIcon } from '@common/SvgIcon/';
import { isEmpty } from 'lodash';
import { validateMaxDate, validateMinDate } from '../dateField/DateField';

const EMPTY_DATE = '請輸入正確的起/迄日期';
const START_MORE_END_DATE = '起始日期不得大於迄日日期';
// const START_EQUAL_END_DATE = '起始日期不得與迄日日期相同';

const DateIntervalField = React.forwardRef((props, ref) => {
    const {
        className,
        disabled = false,
        required = false,
        isError: isErrorProps = false,
        helperText: helperTextProps = EMPTY_DATE,
        lightColor = false,
        label,
        symbol: symbolProps = '～',
        startDateProps = {},
        endDateProps = {},
        mask: maskProps = '____/__/__',
        views: viewProps = ['year', 'month', 'day'],
        format: inputFormatProps = 'YYYY/MM/DD',
        openTo: openToProps = 'day', // 'year' | 'month' | 'day'
        direction: directionProps = 'column', // column | row
        readOnly: readOnlyProps = false,
        fullWidth = false,
        FormLabelTip = '',
        ...other
    } = props;
    const startDataRef = useRef(null);
    const EndDataRef = useRef(null);
    let dateFormatsHeaderLabel = 'YYYY年 MMMM';

    if (openToProps === 'year') {
        dateFormatsHeaderLabel = 'YYYY年';
    } else if (openToProps === 'month') {
        dateFormatsHeaderLabel = 'MMMM';
    }

    const [startValue, setStartValue] = useControlled({
        controlled: startDateProps.value,
        default: startDateProps.defaultValue || null,
    });
    const [endValue, setEndValue] = useControlled({
        controlled: endDateProps.value,
        default: endDateProps.defaultValue || null,
    });

    const [helperText, setHelperText] = useState(helperTextProps);
    const [isError, setError] = useControlled({
        controlled: undefined,
        default: isErrorProps,
    });

    useImperativeHandle(
        ref,
        () => {
            return {
                isError: () => {
                    let source = false;
                    let errorText;
                    const isValidStartDate = isValidDate(startValue);
                    const isValidEndDate = isValidDate(endValue);
                    // 確認最大/最小日期限制提示
                    const checkMinAndMaxDate = (DateObj, dateValue, inputFormat, who) => {
                        let isError = true,
                            errorText;
                        const { minDate, maxDate } = DateObj;
                        const invalidateMinDate = minDate && isValidDate(minDate) && !validateMinDate(dateValue, minDate, inputFormat),
                            invalidateMaxDate = maxDate && isValidDate(maxDate) && !validateMaxDate(dateValue, maxDate, inputFormat);
                        const _minDate = parseDate(minDate, inputFormat),
                            _maxDate = parseDate(maxDate, inputFormat);
                        if (invalidateMinDate && invalidateMaxDate) {
                            errorText = `限制於${_minDate} - ${_maxDate}　`;
                        } else if (invalidateMinDate && !invalidateMaxDate) {
                            errorText = `不得小於${_minDate}`;
                        } else if (invalidateMaxDate && !invalidateMinDate) {
                            errorText = `不得大於${_maxDate}`;
                        } else {
                            isError = false;
                        }

                        return {
                            isError,
                            errorText,
                        };
                    };
                    // 處理顯示起訖日的日期限制文字
                    const handleMinAndMaxDate = (startDateProps, endDateProps, startValue, endValue, inputFormat) => {
                        const { isError: isErrorByStartDate, errorText: _errorTextByStartDate } = !isEmpty(startDateProps)
                            ? checkMinAndMaxDate(startDateProps, startValue, inputFormat, '起始日')
                            : {};
                        const { isError: isErrorByEndDate, errorText: _errorTextByEndDate } = !isEmpty(endDateProps)
                            ? checkMinAndMaxDate(endDateProps, endValue, inputFormat, '結束日')
                            : {};
                        let errorText,
                            isError = isErrorByStartDate || isErrorByEndDate;

                        if (isErrorByStartDate && isErrorByEndDate) {
                            errorText = `起始日${_errorTextByStartDate}，結束日${_errorTextByEndDate}`;
                        } else if (isErrorByStartDate && !isErrorByEndDate) {
                            errorText = `起始日${_errorTextByStartDate}`;
                        } else if (!isErrorByStartDate && isErrorByEndDate) {
                            errorText = `結束日${_errorTextByEndDate}`;
                        }

                        return { source: isError, errorText };
                    };

                    if (isValidStartDate && isValidEndDate) {
                        const { source: _source, errorText: _errorText } = handleMinAndMaxDate(
                            startDateProps,
                            endDateProps,
                            startValue,
                            endValue,
                            inputFormatProps,
                        );
                        source = _source || source;
                        errorText = _errorText || errorText;

                        if (viewProps.includes('year')) {
                            if (isAfter(startValue, endValue, inputFormatProps)) {
                                source = true;
                                errorText = START_MORE_END_DATE;
                            }
                        } else if (isMonthDaysAfterError(startValue, endValue)) {
                            // 處理 月日的比較 不看年
                            source = true;
                            errorText = START_MORE_END_DATE;
                        }
                    } else if (required && (!isValidStartDate || !isValidEndDate)) {
                        source = true;
                        errorText = EMPTY_DATE;
                    }

                    errorText && setHelperText(errorText);
                    setError(source);
                    return source;
                },
                getResult: () => {
                    return {
                        startDate: parseDate(startValue),
                        endDate: parseDate(endValue),
                    };
                },
                setError: (isError, helperText) => {
                    setError(isError);
                    if (helperText) setHelperText(helperText);
                },
            };
        },
        // eslint-disable-next-line
        [startValue, endValue],
    );

    const handleStartDateOnChange = (_value) => {
        const val = parseDate(_value);
        const endDate = parseDate(endValue);
        startDateProps.onChange && startDateProps.onChange(val, endDate);
    };

    const handleEndDateOnChange = (_value) => {
        const val = parseDate(_value);
        const startDate = parseDate(startValue);
        endDateProps.onChange && endDateProps.onChange(val, startDate);
    };

    const formlabelProperties = {
        lightColor: lightColor,
        inline: directionProps === 'row',
        FormLabelProps: {
            labelTip: FormLabelTip,
        },
    };

    return (
        <FormControl
            disabled={disabled}
            required={required}
            className={className}
            error={isError}
            fullWidth={fullWidth}
            direction={directionProps}
            readOnly={readOnlyProps}
            {...other}
        >
            {label && <FormLabel {...formlabelProperties}>{label}</FormLabel>}
            <Stack direction="column" style={{ flexGrow: 1 }}>
                <Stack>
                    <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                        dateFormats={{ monthAndYear: dateFormatsHeaderLabel }}
                        adapterLocale={'zh-tw'}
                        localeText={{
                            cancelButtonLabel: '取消',
                            clearButtonLabel: '清空',
                            okButtonLabel: '確定',
                            todayButtonLabel: '今日',
                        }}
                    >
                        <DatePicker
                            componentsProps={{ actionBar: { className: 'mobile-date-picker-action' } }}
                            components={{
                                SwitchViewIcon: AngleDownIcon,
                                LeftArrowIcon: NavigateBeforeIcon,
                                RightArrowIcon: NavigateNextIcon,
                            }}
                            showToolbar={false}
                            disabled={disabled}
                            readOnly={readOnlyProps}
                            mask={maskProps}
                            inputFormat={inputFormatProps}
                            views={viewProps}
                            openTo={openToProps}
                            value={startValue}
                            onChange={(newValue) => {
                                const formatDate = newValue ? newValue['$d'] : new Date('');
                                handleStartDateOnChange(formatDate);
                                setStartValue(formatDate);
                            }}
                            minDate={startDateProps.minDate || null}
                            maxDate={startDateProps.maxDate || null}
                            renderInput={({ inputProps, inputRef, error, ...renderInputOther }) => {
                                const newInputProps = {
                                    error: error || isError,
                                    inputProps: {
                                        ...inputProps,
                                        placeholder: startDateProps.placeholder || '請選擇日期',
                                    },
                                };

                                return (
                                    <OutlinedInput
                                        inputRootStyle={{ flexGrow: 1 }}
                                        inputRef={useForkRef(inputRef, startDataRef)}
                                        {...newInputProps}
                                        {...renderInputOther}
                                        name={startDateProps.name}
                                    />
                                );
                            }}
                        />
                        <Typography component="div" className="px-1" style={{ margin: 'auto' }}>
                            {symbolProps}
                        </Typography>
                        <DatePicker
                            componentsProps={{ actionBar: { className: 'mobile-date-picker-action' } }}
                            components={{
                                SwitchViewIcon: AngleDownIcon,
                                LeftArrowIcon: NavigateBeforeIcon,
                                RightArrowIcon: NavigateNextIcon,
                            }}
                            showToolbar={false}
                            disabled={disabled}
                            readOnly={readOnlyProps}
                            mask={maskProps}
                            inputFormat={inputFormatProps}
                            views={viewProps}
                            openTo={openToProps}
                            value={endValue}
                            onChange={(newValue) => {
                                const formatDate = newValue ? newValue['$d'] : new Date('');
                                handleEndDateOnChange(formatDate);
                                setEndValue(formatDate);
                            }}
                            minDate={endDateProps.minDate || null}
                            maxDate={endDateProps.maxDate || null}
                            renderInput={({ inputProps, inputRef, error, ...renderInputOther }) => {
                                const newInputProps = {
                                    error: error || isError,
                                    inputProps: {
                                        ...inputProps,
                                        placeholder: endDateProps.placeholder || '請選擇日期',
                                    },
                                };
                                return (
                                    <OutlinedInput
                                        inputRootStyle={{ flexGrow: 1 }}
                                        inputRef={useForkRef(inputRef, EndDataRef)}
                                        {...newInputProps}
                                        {...renderInputOther}
                                        name={endDateProps.name}
                                    />
                                );
                            }}
                        />
                    </LocalizationProvider>
                </Stack>
                {isError && <FormHelperText>{helperText}</FormHelperText>}
            </Stack>
        </FormControl>
    );
});

export default DateIntervalField;
