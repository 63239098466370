import Mock from 'mockjs';
import { successApi } from './respondData';
import { CONTACTRECORDSOPTIONS } from './_customerContactRecord.mock';

export const withMockMembersContactRecordApiProvider = () => {
    
    Mock.mock(/^\/api\/members\/contact-records\/options$/, 'get', () => {
        let sourceData = { ...successApi };
        sourceData.result = CONTACTRECORDSOPTIONS;
        return sourceData;
    });

    Mock.mock(/^\/api\/members\/[0-9]+\/contact-records$/, 'post', () => {
        let sourceData = { ...successApi };
        sourceData.result = true;
        return sourceData;
    });

    Mock.setup({ timeout: '200-800' });
};
