import React from 'react';
import useAppointment from '@icoach/appointment/components/useAppointment';
import { ReadTextField } from '@icoach/components';
import AppointmentConfirmMenuOption from '@icoach/landingPage/appointment/AppointmentConfirmMenuOption';
import AppointmentAttendMenuOption from '@icoach/landingPage/appointment/AppointmentAttendMenuOption';
import SaleFigureAnalysisDialog from '@icoach/landingPage/dialog/SaleFigureAnalysisDialog';
import AppointmentCoachDialog from '@icoach/landingPage/dialog/AppointmentCoachDialog';
import useAppointmentApi from '@apis/useAppointmentApi';
import { Card, Typography, Divider, Stack, IconButton } from '@common/components/';
import { Edit as EditIcon, MoreVertical as MoreVerticalIcon } from '@common/SvgIcon/';
import { FaEnum } from '@icoach/landingPage/staticData';

const viewPushTypeTextFn = (options, key) => {
    key = key > 1 ? 2 : key;
    const target = options.find(({ value }) => String(value) === String(key));
    return target ? target.text : '';
};

const AppointmentAttendInformationItem = React.forwardRef((props, ref) => {
    const [open, setOpen] = React.useState(null);
    const { refresh } = useAppointment();
    const { putAppointmentServeApi } = useAppointmentApi();
    const { sourceData, options } = props;
    const {
        confirmTypeOption = [],
        attendTypeOption = [],
        usableEmployeeOption = [],
        figureAnalysisPushTypeOption = [],
        figureAnalysisReasonTypeOption = [],
    } = options;
    const { appointmentID, confirmType, attendType, appointmentType, serveEmployeeName, serveEmployeeID, pushType, pushReasonText } = sourceData;

    const pushTypeText = viewPushTypeTextFn(figureAnalysisPushTypeOption, parseInt(pushType, 10));
    let reasonText = parseInt(pushType, 10) > 1 ? figureAnalysisReasonTypeOption.find(({ value }) => String(value) === String(pushType))?.text ?? '' : '';
    if (pushReasonText) reasonText = pushReasonText;

    return (
        <Card className="p-3" square>
            <Stack direction="column" spacing={2}>
                <Typography variant={'h5'}>動作&確認</Typography>
                <Stack spacing={2}>
                    <AppointmentConfirmMenuOption key={confirmType} apoId={appointmentID} option={confirmTypeOption} value={confirmType} refresh={refresh} />
                    <AppointmentAttendMenuOption apoId={appointmentID} option={attendTypeOption} value={attendType} refresh={refresh} />
                </Stack>
                {FaEnum.includes(String(appointmentType)) && (
                    <React.Fragment>
                        <Divider />
                        <Stack justifyContent="space-between">
                            <Typography variant="h5">推進資訊</Typography>
                            <IconButton onClick={() => setOpen('saleFigureAnalysisDialog')}>
                                <EditIcon />
                            </IconButton>
                        </Stack>
                        <ReadTextField label="推進結果">{pushTypeText}</ReadTextField>
                        <ReadTextField label="原因">{reasonText}</ReadTextField>
                        <SaleFigureAnalysisDialog
                            open={open === 'saleFigureAnalysisDialog'}
                            onClose={() => setOpen(null)}
                            id={appointmentID}
                            options={{
                                figureAnalysisPushTypeOption,
                                figureAnalysisReasonTypeOption,
                            }}
                            sourceData={{ pushType, pushReasonText }}
                            refresh={refresh}
                        />
                    </React.Fragment>
                )}
                <Divider />
                <Stack alignItems="end">
                    <ReadTextField label="服務擔當" style={{ flexGrow: 1 }}>
                        {serveEmployeeName}
                    </ReadTextField>
                    <IconButton onClick={() => setOpen('coachDialog')}>
                        <MoreVerticalIcon />
                    </IconButton>
                    <AppointmentCoachDialog
                        open={open === 'coachDialog'}
                        onClose={() => setOpen(null)}
                        options={usableEmployeeOption}
                        sourceData={{
                            title: '請設定擔當',
                            apiParams: { appointmentID },
                            employeeID: serveEmployeeID,
                            refresh: refresh,
                            apiFn: putAppointmentServeApi,
                        }}
                    />
                </Stack>
            </Stack>
        </Card>
    );
});

export default AppointmentAttendInformationItem;
