import React from 'react';
import SvgIcon from '../SvgIcon';
import useForkRef from '@util/hook/useForkRef';

const Star = React.forwardRef((props, ref) => {
    const { className, ...others } = props;
    const handleRef = React.useRef(null);
    const svgRef = useForkRef(handleRef, ref);
    const defaultProperty = {
        className,
        ref: svgRef,
        ...others,
    };
    return (
        <SvgIcon {...defaultProperty}>
            <path
                d="M10.129.7,7.578,5.867,1.871,6.7A1.251,1.251,0,0,0,1.18,8.832l4.129,4.023-.977,5.684a1.249,1.249,0,0,0,1.813,1.316l5.105-2.684,5.105,2.684a1.25,1.25,0,0,0,1.813-1.316l-.977-5.684L21.32,8.832A1.251,1.251,0,0,0,20.629,6.7l-5.707-.832L12.371.7A1.251,1.251,0,0,0,10.129.7Z"
                transform="translate(0.75 1.999)"
            ></path>
        </SvgIcon>
    );
});

export default Star;
