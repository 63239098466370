import React from 'react';
import { Card } from '@common/components/';
import clsx from 'clsx';
import { CartTable } from '@icoach/documents/table';

const ProductSectionByRead = (props) => {
    const { titleClassName = 'bg-light-active', data = {} } = props;
    const { items } = data;

    return (
        <React.Fragment>
            <Card.CardHeader title={'訂購商品'} className={clsx('px-4', titleClassName)} />
            <Card.CardContent className={'px-4 pt-2 pb-4'}>
                <CartTable className={'mb-4'} data={items} readOnly />
            </Card.CardContent>
        </React.Fragment>
    );
};

export default ProductSectionByRead;
