import React, { useState } from 'react';
import _ from 'lodash';
import {
    Stack,
    IconButton,
    Menu,
    MenuItem,
    ListItemText,
    ListItemIcon,
    Tooltip,
} from '@common/components/';
import {
    CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon,
    Appointed as AppointedIcon,
    NoAppointment as NoAppointmentIcon,
    CancelAppointment as CancelAppointmentIcon,
} from '@common/SvgIcon/';
import useAppointmentApi from '@apis/useAppointmentApi';
import { mergeArrayByProperty } from '@util/utils';

const attendTypeOption = [
    { text: '已赴約', value: '1', icon: <AppointedIcon /> },
    { text: '未赴約', value: '2', icon: <NoAppointmentIcon /> },
    { text: '取消', value: '3', icon: <CancelAppointmentIcon /> },
];

const AppointmentAttendMenuOption = React.forwardRef((props, ref) => {
    const {
        className: classNameProps,
        option: optionProp,
        value: valueProp,
        apoId: apoIdProp,
    } = props;
    const [option] = useState(mergeArrayByProperty(optionProp, attendTypeOption, 'value', 'icon'));
    const [value, setValue] = useState(valueProp || null);
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const { putAppointmentAttendApi } = useAppointmentApi();

    const handleMoreIconClick = (e) => {
        setAnchorEl(e.currentTarget);
    };

    const handleClose = (e) => {
        setAnchorEl(null);
    };

    const handleOnClickMenuItem = (_value) => {
        const currentValue = String(_value) !== String(value) ? _value : null;
        const params = { appointmentID: apoIdProp, attendType: currentValue || 0 };
        setAnchorEl(null);
        putAppointmentAction(params, currentValue);
    };

    const putAppointmentAction = async (params, currentValue) => {
        const resp = await putAppointmentAttendApi(params);
        resp && setValue(currentValue);
    };

    const item = option.find(({ value: _v }) => String(value) === String(_v)) || {};
    const viewEl = _.isEmpty(item) ? { icon: <CheckBoxOutlineBlankIcon />, text: '' } : item;

    return (
        <Stack direction="column" alignItems="center" spacing={1}>
            <span className={classNameProps}>出席</span>
            <Tooltip disableFocusListener title={viewEl.text}>
                <IconButton onClick={handleMoreIconClick}>{viewEl.icon}</IconButton>
            </Tooltip>
            <Menu anchorEl={anchorEl} open={open} onClose={(e) => handleClose(null)}>
                {option.map(({ text, value: _value, disabled, icon }) => {
                    const selected = String(_value) === String(value);
                    return (
                        <MenuItem
                            key={text}
                            onClick={() => handleOnClickMenuItem(_value)}
                            selected={selected}
                            disabled={disabled}
                        >
                            {icon && <ListItemIcon>{icon}</ListItemIcon>}
                            <ListItemText>{text}</ListItemText>
                        </MenuItem>
                    );
                })}
            </Menu>
        </Stack>
    );
});

export default AppointmentAttendMenuOption;
