import React, { useState, useEffect, useRef, useMemo } from 'react';
import { TabsHeader, TabContext } from '@icoach/nonMember/components/TabContainer';
import NonMemberSearchBar from '@icoach/nonMember/components/NonMemberSearchBar';
import NonMemberContext from '@icoach/nonMember/components/NonMemberContext';
import { initBookmark } from '@icoach/nonMember/staticData';
import NON_MEMBER_ROUTERS from '@icoach/router/NonMemberRouter';
import { Box } from '@common/components/';

// refresh 畫面任意action觸發時 刷新畫面使用
// screener 顧客/名單 進階清單已選項目參考值
// bookmark 畫面 table 分頁
// searchParams 畫面的搜尋參數

const NonMemberPage = (props) => {
    const { tabsIndex: propsTabsIndex = 'customerOverview' } = props;
    // 標題欄分頁
    const [tabsIndex, setTabsIndex] = useState(propsTabsIndex);
    // 列表資料
    const [tableData, setTableData] = useState({});
    // 頁碼
    const [bookmark, setBookmark] = useState({
        ...initBookmark,
    });
    // 刷新
    const refreshRef = useRef(() => {});
    // 輸入表單參數
    const searchParamsRef = useRef(() => {});
    // 收集進階收尋(特殊狀況)
    const screenerRef = useRef(() => {});

    const setContactState = (targetID, params) => {
        setTableData((prevTableData) => {
            let { list = [], ...others } = prevTableData;
            list = list.map((target) => {
                let contact = target;
                if (String(target.customerID) === String(targetID)) {
                    contact = {
                        ...target,
                        contact: {
                            ...target.contact,
                            ...params,
                        },
                    };
                }
                return contact;
            });
            return {
                list,
                ...others,
            };
        });
    };

    const childContext = {
        tableData: tableData,
        setTableData: setTableData,
        bookmark: bookmark,
        setBookmarkNum: (num) => setBookmark((pre) => ({ ...pre, pageIndex: num })),
        refresh: refreshRef,
        setRefresh: (el) => (refreshRef.current = el),
        searchParams: searchParamsRef,
        setSearchParams: (el) => (searchParamsRef.current = el),
        screener: screenerRef.current,
        setScreener: (el) => (screenerRef.current = el),
        setContactState,
    };

    const handleTabsValue = (newValue) => setTabsIndex(newValue);

    const SearchBarMemo = useMemo(() => <NonMemberSearchBar value={tabsIndex} />, [tabsIndex]);

    const indexValues = useMemo(() => {
        const targetMap = NON_MEMBER_ROUTERS.map(({ tabsIndex, href, frontKey }) => ({
            tabsIndex,
            href,
            frontKey,
        }));
        return targetMap.reduce((acc, { frontKey, ...others }) => {
            let tmpAcc = { ...acc };
            if (frontKey === 'C3X02') tmpAcc['CUSTOMER_VALUE'] = { ...others };
            else if (frontKey === 'C3X04') tmpAcc['PROSPECT_VALUE'] = { ...others };
            else if (frontKey === 'C3X06') tmpAcc['TEMPORARIES_VALUE'] = { ...others };
            else if (frontKey === 'C3X03') tmpAcc['ADD_CUSTOMER'] = { ...others };
            else if (frontKey === 'C3X05') tmpAcc['ADD_PROSPECT'] = { ...others };
            return tmpAcc;
        }, {});
    }, []);

    // 更新列表中單一則聯繫紀錄
    useEffect(() => {
        if (propsTabsIndex !== tabsIndex) setTabsIndex(propsTabsIndex);
        // return () => console.log('NonMemberPage Destroy');
        // eslint-disable-next-line
    }, [propsTabsIndex]);

    return (
        <NonMemberContext.Provider value={childContext}>
            <Box className="container main-container-spacing non-member-container">
                <Box className="non-member-header page-tabs tabs-bottom-line">
                    <TabsHeader value={tabsIndex} indexValues={indexValues} onChange={handleTabsValue} />
                </Box>
                <Box className="non-member-search-panel">{SearchBarMemo}</Box>
                <Box className="non-member-content">
                    <TabContext value={tabsIndex} onChange={handleTabsValue} indexValues={indexValues} />
                </Box>
            </Box>
        </NonMemberContext.Provider>
    );
};

export default NonMemberPage;
