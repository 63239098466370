import { useCallback } from 'react';
import { useSnackbar } from 'notistack';
import { serialize } from '@util/utils';

const GET_REPORT_PJ_API = `/api/reports-export/pj-output.xlsx`;
const GET_REPORT_CA_API = `/api/reports-export/ca-output.xlsx`;

const useReportExportApi = () => {
    const { enqueueSnackbar } = useSnackbar();

    // 下載 PJ 報表
    const getReportPJExcelApi = useCallback(
        async (params) => {
            const qeury = serialize(params);
            const resp = window.open(`${GET_REPORT_PJ_API}/?${qeury}`, '_black');
            if (resp) {
                enqueueSnackbar('已成功開啟並下載PJ報表', { variant: 'success' });
                return true;
            }
        },
        // eslint-disable-next-line
        []
    );

    // 下載 CA 報表
    const getReportCAExcelApi = useCallback(
        async (params) => {
            const qeury = serialize(params);
            const resp = window.open(`${GET_REPORT_CA_API}/?${qeury}`, '_black');
            if (resp) {
                enqueueSnackbar('已成功開啟並下載CA報表', { variant: 'success' });
                return true;
            }
        },
        // eslint-disable-next-line
        []
    );

    return {
        getReportPJExcelApi,
        getReportCAExcelApi,
    };
};

export default useReportExportApi;
