import React from 'react';
import SvgIcon from '../SvgIcon';
import useForkRef from '@util/hook/useForkRef';

const Check = React.forwardRef((props, ref) => {
    const { className, ...others } = props;
    const handleRef = React.useRef(null);
    const svgRef = useForkRef(handleRef, ref);
    const defaultProperty = {
        className,
        ref: svgRef,
        ...others,
    };

    return (
        <SvgIcon {...defaultProperty}>
            <path
                d="M6.793,17.164l-6.5-6.5a1,1,0,0,1,0-1.414L1.707,7.836a1,1,0,0,1,1.414,0L7.5,12.214l9.379-9.379a1,1,0,0,1,1.414,0L19.707,4.25a1,1,0,0,1,0,1.414l-11.5,11.5A1,1,0,0,1,6.793,17.164Z"
                transform="translate(2 2.457)"
            ></path>
        </SvgIcon>
    );
});

export default Check;
