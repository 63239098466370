import Mock from 'mockjs';
import { successApi } from './respondData';

export const withMockEmployeesProvider = () => {

    Mock.mock(/^\/api\/employees\/options$/, 'get', (option) => {
        let sourceData = { ...successApi };
        sourceData.result = EMPLOYEES_DATA;
        return sourceData;
    });

    Mock.mock(/^\/api\/employees$/, 'post', (option) => {
        let sourceData = { ...successApi };
        sourceData.result = 'ok';
        return sourceData;
    });

    Mock.mock(/^\/api\/employees$/, 'put', (option) => {
        let sourceData = { ...successApi };
        sourceData.result = 'ok';
        return sourceData;
    });
    Mock.setup({ timeout: '200-800' });
};

const EMPLOYEES_DATA = [
    {
        text: '林宗敬(小C)',
        value: '11',
        disabled: false,
    },
    {
        text: 'Allen(小A)',
        value: '12',
        disabled: false,
    },
    {
        text: 'Kevin(小K)',
        value: '13',
        disabled: false,
    },
    {
        text: 'Sabrina(小K)',
        value: '14',
        disabled: false,
    },
];
