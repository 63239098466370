import React, { useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import BatchPaymentManagementSearchBar from '@icoach/accountBook/BatchPaymentManagementSearchBar';
import { AccountBookProvider } from '@icoach/accountBook/components/AccountBookContext';
import { bulkDebitBillingType as bulkDebitBillingTypeMap, orderTypeMap } from '@icoach/accountBook/staticData';
import { parseDate } from '@util/moment';
import { paymentTypeMap } from '@icoach/rentCabinet/staticData';
import { paramsToObject } from '@util/utils';
import BatchPaymentOrderMembershipPage from '@icoach/accountBook/BatchPaymentOrderMembershipPage';
import BatchPaymentOrderRentCabinetPage from '@icoach/accountBook/BatchPaymentOrderRentCabinetPage';
import BatchPaymentOrderHCPage from '@icoach/accountBook/BatchPaymentOrderHCPage';

const initialParamData = (data) => {
    const { type, date, bulkDebitBillingType, paymentType } = data || {};
    let paramData = {
        type: type || orderTypeMap.membership,
        date: date || parseDate(new Date(), 'YYYY-MM'),
        bulkDebitBillingType: bulkDebitBillingType || bulkDebitBillingTypeMap.billing,
    };
    if (paymentType) {
        paramData = {
            ...paramData,
            paymentType: paymentType || paymentTypeMap.credit,
        };
    }
    return paramData;
};

const BatchPaymentManagementPage = (props) => {
    const history = useHistory();
    const search = history.location.search;
    const [tableData, setTableData] = useState({});
    const [resource, setResource] = useState({});
    const [paramData, setParamData] = useState(initialParamData(paramsToObject(search)));
    const { type } = paramData;
    const refreshRef = useRef(() => {});
    const setSearchBarData = useRef(null);

    const setSearchConditions = (data) => {
        setParamData({
            ...initialParamData(),
            ...data,
        });
    };

    const setBulkDebitBillingType = (bulkDebitBillingType) => {
        const hasPaymentType = (type) => {
            const hasPaymentTypeData = [bulkDebitBillingTypeMap.currentOrders, bulkDebitBillingTypeMap.overdueOrders];
            return hasPaymentTypeData.includes(String(type));
        };

        setParamData((prev) => {
            const { type } = prev;
            let result = { ...prev };

            if (String(type) === orderTypeMap.membership && hasPaymentType(bulkDebitBillingType) && !result.hasOwnProperty('paymentType')) {
                result.paymentType = paymentTypeMap.credit;
            } else if (!hasPaymentType(bulkDebitBillingType) && result.hasOwnProperty('paymentType')) {
                delete result.paymentType;
            }

            result = {
                ...result,
                bulkDebitBillingType,
            };
            return result;
        });
    };

    const setPaymentType = (paymentType) => {
        setParamData((prev) => {
            return {
                ...prev,
                paymentType,
            };
        });
    };

    const childContext = {
        tableData,
        setTableData,
        resource,
        setResource,
        paramData,
        setParamData,
        setSearchConditions,
        setBulkDebitBillingType,
        setPaymentType,
        setSearchBarData,
        refresh: refreshRef.current,
        setRefresh: (el) => (refreshRef.current = el),
        dialogRef: props.dialogRef,
    };

    return (
        <AccountBookProvider value={childContext}>
            <BatchPaymentManagementSearchBar className={'mb-4'} />
            {type === orderTypeMap.membership && <BatchPaymentOrderMembershipPage />}
            {type === orderTypeMap.rentCabinet && <BatchPaymentOrderRentCabinetPage />}
            {type === orderTypeMap.cycleOrder && <BatchPaymentOrderHCPage />}
        </AccountBookProvider>
    );
};

export default BatchPaymentManagementPage;
