import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import { Dialog, DialogActions, DialogContent } from '@mui/material';
import clsx from 'clsx';
import { Alert, Button, CheckboxField, DateField, Form, Grid, MenuItem, SelectField } from '@common/components/';
import GradientColorHeader from '@icoach/components/dialog/GradientColorHeader';
import MemberSelectionPanel from '@icoach/members/components/MemberSelectionPanel';
import { isEmpty, refIsRequiredError } from '@util/utils';
import useDocMembershipBatchQuitApi from '@apis/useDocMembershipBatchQuitApi';
import { parseDate } from '@util/moment';

const BatchMembershipQuitDialogContent = React.forwardRef((props, ref) => {
    const { sourceData } = props;
    const { errorCount = 0, members, coachOptions = [], quitReasonTypeOptions = [] } = sourceData || {};
    const memberIDRef = useRef(null);
    const quitReasonTypeRef = useRef(null);
    const applyDateRef = useRef(null);
    const employeeRef = useRef(null);
    const isConfirmRef = useRef(null);

    useImperativeHandle(ref, () => ({
        getResult: () => {
            let result = {};

            if (memberIDRef.current && memberIDRef.current.getResult) {
                Object.assign(result, { memberIDs: memberIDRef.current.getResult() });
            }
            if (quitReasonTypeRef.current && quitReasonTypeRef.current.getResult) {
                Object.assign(result, { quitReasonType: quitReasonTypeRef.current.getResult() });
            }
            if (applyDateRef.current && applyDateRef.current.getResult) {
                Object.assign(result, { applyDate: applyDateRef.current.getResult() });
            }
            if (employeeRef.current && employeeRef.current.getResult) {
                Object.assign(result, { employeeID: employeeRef.current.getResult() });
            }

            return result;
        },
        isError: () => refIsRequiredError(memberIDRef, quitReasonTypeRef, applyDateRef, employeeRef, isConfirmRef),
    }));

    return (
        <DialogContent>
            <Grid spacing={2} container>
                {errorCount > 0 && (
                    <Grid xs={12} item>
                        <Alert className={'alert-justify-content-start'} severity={'info'} variant={'filled'} color={'info'}>
                            {`選取的清單含有無法退會的會員，系統共過濾${errorCount}筆。請確認以下會員清單內容，謝謝。`}
                        </Alert>
                    </Grid>
                )}
                <Grid xs={12} item>
                    <MemberSelectionPanel ref={memberIDRef} members={members} />
                </Grid>
                <Grid xs={12} item>
                    <SelectField key={'11'} ref={quitReasonTypeRef} label={'終止原因'} defaultValue={'11'} fullWidth required>
                        {quitReasonTypeOptions.map(({ value, text }) => (
                            <MenuItem key={value} value={value}>
                                {text}
                            </MenuItem>
                        ))}
                    </SelectField>
                </Grid>
                <Grid xs={6} item>
                    <DateField key={''} ref={applyDateRef} defaultValue={parseDate()} label={'申請日期'} fullWidth />
                </Grid>
                <Grid xs={6} item>
                    <SelectField key={''} ref={employeeRef} label={'擔當'} defaultValue={''} fullWidth required>
                        {coachOptions.map(({ value, text }) => (
                            <MenuItem key={value} value={value}>
                                {text}
                            </MenuItem>
                        ))}
                    </SelectField>
                </Grid>
                <Grid item xs={12}>
                    <Alert severity="info" variant="filled" color="info" className="alert-justify-content-start">
                        提醒：批次退會將不會收取任何退會費用，請謹慎使用
                    </Alert>
                    <CheckboxField ref={isConfirmRef} required>
                        <CheckboxField.Checkbox label={'我已確認會員清單，現將進行批次退會。'} value={true} />
                    </CheckboxField>
                </Grid>
            </Grid>
        </DialogContent>
    );
});

const BatchMembershipQuitDialog = (props) => {
    const { className, open, sourceData, refresh, onClose } = props;
    const { memberIDs } = sourceData || {};
    const { postDocMembershipBatchQuitApi, postDocMembershipBatchQuitValidateApi } = useDocMembershipBatchQuitApi();
    const [data, setData] = useState({});
    const contentRef = useRef(null);

    const validateMembershipApi = async (params) => {
        let resp = await postDocMembershipBatchQuitValidateApi(params);
        if (resp) {
            setData(resp);
        }
    };

    const doDocMembershipBatchQuitApi = async (params) => {
        let resp = await postDocMembershipBatchQuitApi(params);
        if (resp) {
            if (typeof refresh === 'function') refresh();
            onClose();
        }
    };

    const handleSubmit = () => {
        let isError = contentRef.current && contentRef.current.isError();
        if (!isError) {
            let params = contentRef.current && contentRef.current.getResult();
            doDocMembershipBatchQuitApi(params);
        }
    };

    useEffect(
        () => {
            if (open && !isEmpty(memberIDs)) {
                validateMembershipApi({ memberIDs });
            }
        },
        // eslint-disable-next-line
        [open, memberIDs],
    );

    if (!open) return null;

    return (
        <Dialog
            className={clsx('simple-content', 'dialog', className)}
            PaperProps={{ className: 'wx-70rem', component: Form }}
            onSubmit={handleSubmit}
            open={open}
            fullWidth
        >
            <GradientColorHeader onClose={onClose}>批次操作退會</GradientColorHeader>
            <BatchMembershipQuitDialogContent ref={contentRef} sourceData={data} />
            <DialogActions>
                <Button type={'submit'} variant="contained">
                    確認送出
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default BatchMembershipQuitDialog;
