import { MembersPage, MemberDetailPage } from '@icoach/members/';
import MemberDetailWorkoutContent from '@icoach/members/memberDetail/MemberDetailWorkoutContent';
import MemberDetailBasicInfoContent from '@icoach/members/memberDetail/MemberDetailBasicInfoContent';
import MemberDetailMembershipContent from '@icoach/members/memberDetail/MemberDetailMembershipContent';
import MemberDetailInbodyContent from '@icoach/members/memberDetail/MemberDetailInbodyContent';
import MemberDetailContactRecordContent from '@icoach/members/memberDetail/MemberDetailContactRecordContent';
import MemberDetailCycleOrderContent from '@icoach/members/memberDetail/MemberDetailCycleOrderContent';
import MemberDetailOtherContent from '@icoach/members/memberDetail/MemberDetailOtherContent';
import { RentCabinetOverviewPage, CabinetOverviewPage, RentCabinetDocumentPage, RentCabinetDocumentPageByRead } from '@icoach/rentCabinet';
import MemberDetailRentCabinetContent from '@icoach/members/memberDetail/MemberDetailRentCabinetContent';

// 會員管理 -> 會員總覽 -> 快速名單 與 一般名單
const MEMBERS_TABS = '/club/members/:tabs(member-overview)';
export const MEMBERS_MEMBER_OVERVIEW = `/club/members/member-overview/`;
export const MEMBERS_MEMBER_OVERVIEW_NORMAL = `/club/members/member-overview/normal/`;
export const MEMBERS_MEMBER_OVERVIEW_QUICK = `/club/members/member-overview/quick/`;
export const MEMBERS_MEMBER_OVERVIEW_NORMAL_SUBTABS = `/club/members/member-overview/:subTabs(normal)/`;
export const MEMBERS_MEMBER_OVERVIEW_QUICK_SUBTABS = `/club/members/member-overview/:subTabs(quick)/:quickType([\\d]+)?/`;
export const MEMBERS_MEMBER_OVERVIEW_QUICK_URL = (num) => `/club/members/member-overview/quick/${num}/`;
export const MEMBERS_MEMBER_OVERVIEW_URL = (path) => `/club/members/member-overview/${path}/`;

// 會員明細
const MEMBERS_MEMBER_DETAIL = `/club/members/:memberID/detail/`;
export const MEMBERS_MEMBER_DETAIL_URL = (memberID) => `/club/members/${memberID}/detail/`;
const MEMBER_DETAIL_WORKOUT = `${MEMBERS_MEMBER_DETAIL}:tabs(workout)/`;
const MEMBER_DETAIL_WORKOUT_URL = (memberID) => `${MEMBERS_MEMBER_DETAIL_URL(memberID)}workout/`;
const MEMBER_DETAIL_BASIC = `${MEMBERS_MEMBER_DETAIL}:tabs(basic)/`;
const MEMBER_DETAIL_BASIC_URL = (memberID) => `${MEMBERS_MEMBER_DETAIL_URL(memberID)}basic/`;
const MEMBER_DETAIL_MEMBERSHIP = `${MEMBERS_MEMBER_DETAIL}:tabs(membership)/`;
const MEMBER_DETAIL_MEMBERSHIP_URL = (memberID) => `${MEMBERS_MEMBER_DETAIL_URL(memberID)}membership/`;
const MEMBER_DETAIL_INBODY = `${MEMBERS_MEMBER_DETAIL}:tabs(inbody)/`;
const MEMBER_DETAIL_INBODY_URL = (memberID) => `${MEMBERS_MEMBER_DETAIL_URL(memberID)}inbody/`;
const MEMBER_DETAIL_CONTACT_RECORD = `${MEMBERS_MEMBER_DETAIL}:tabs(contact-record)/`;
const MEMBER_DETAIL_CONTACT_RECORD_URL = (memberID) => `${MEMBERS_MEMBER_DETAIL_URL(memberID)}contact-record/`;
const MEMBER_DETAIL_CYCLE_PURCHASE = `${MEMBERS_MEMBER_DETAIL}:tabs(cycle-purchase)/`;
export const MEMBER_DETAIL_CYCLE_PURCHASE_URL = (memberID) => `${MEMBERS_MEMBER_DETAIL_URL(memberID)}cycle-purchase/`;
const MEMBER_DETAIL_RENT_CABINET = `${MEMBERS_MEMBER_DETAIL}:tabs(rent-cabinet)/`;
export const MEMBER_DETAIL_RENT_CABINET_URL = (memberID) => `${MEMBERS_MEMBER_DETAIL_URL(memberID)}rent-cabinet/`;
const MEMBER_DETAIL_OTHER = `${MEMBERS_MEMBER_DETAIL}:tabs(other)/`;
const MEMBER_DETAIL_OTHER_URL = (memberID) => `${MEMBERS_MEMBER_DETAIL_URL(memberID)}other/`;

// 租櫃管理
export const MEMBERS_TABS_RENT_CABINET = '/club/members/:tabs(rent-cabinet)/';
export const MEMBERS_TABS_RENT_CABINET_URL = '/club/members/rent-cabinet/';
const RENT_CABINET_DOCUMENT = `${MEMBERS_TABS_RENT_CABINET}document/`;
export const RENT_CABINET_DOCUMENT_URL = `${MEMBERS_TABS_RENT_CABINET_URL}document/`;
const RENT_CABINET_DOCUMENT_CREATE = `${RENT_CABINET_DOCUMENT}create/`;
export const RENT_CABINET_DOCUMENT_CREATE_URL = `${RENT_CABINET_DOCUMENT_URL}create/`;
const RENT_CABINET_DOCUMENT_ID = `${RENT_CABINET_DOCUMENT}:rentCabinetID/`;
export const RENT_CABINET_DOCUMENT_ID_URL = (rentCabinetID) => `${RENT_CABINET_DOCUMENT_URL}${rentCabinetID}/`;
const RENT_CABINET_DOCUMENT_PREVIEW = `${RENT_CABINET_DOCUMENT_ID}preview/`;
export const RENT_CABINET_DOCUMENT_PREVIEW_URL = (rentCabinetID) => `${RENT_CABINET_DOCUMENT_ID_URL(rentCabinetID)}preview/`;
export const RENT_CABINET_CABINETS = `${MEMBERS_TABS_RENT_CABINET}cabinets/`;
export const RENT_CABINET_CABINETS_URL = `${MEMBERS_TABS_RENT_CABINET_URL}cabinets/`;

// 贈品管理
export const MEMBERS_TABS_GIFT = '/club/members/:tabs(gift)/:subTabs(overview|dispatch-record|dispatch-metrics)?';
export const MEMBERS_TABS_GIFT_OVERVIEW_URL = '/club/members/gift/overview';
export const MEMBERS_TABS_GIFT_DISPATCH_RECORD_URL = '/club/members/gift/dispatch-record';
export const MEMBERS_TABS_GIFT_DISPATCH_METRICS_URL = '/club/members/gift/dispatch-metrics';

// 會員管理
const memberRouter = [
    {
        description: '會員管理',
        frontKey: 'C2X01',
    },
    {
        description: '會員總覽',
        strict: true,
        path: MEMBERS_TABS,
        href: MEMBERS_MEMBER_OVERVIEW,
        as: MembersPage,
        frontKey: 'C2X02',
    },
    {
        description: '會員詳細頁',
        strict: true,
        path: MEMBERS_MEMBER_DETAIL,
        as: MemberDetailPage,
        frontKey: 'C2X03',
    },
    {
        description: '置物櫃承租申請建立',
        exact: true,
        path: RENT_CABINET_DOCUMENT_CREATE,
        as: RentCabinetDocumentPage,
        frontKey: 'C2X07',
    },
    {
        description: '查看置物櫃承租申請',
        exact: true,
        path: RENT_CABINET_DOCUMENT_PREVIEW,
        as: RentCabinetDocumentPageByRead,
        frontKey: 'C2X08',
    },
    {
        description: '置物櫃承租申請編輯',
        exact: true,
        path: RENT_CABINET_DOCUMENT_ID,
        as: RentCabinetDocumentPage,
        frontKey: 'C2X09',
    },
    {
        description: '租櫃管理',
        strict: true,
        path: MEMBERS_TABS_RENT_CABINET,
        href: MEMBERS_TABS_RENT_CABINET_URL,
        as: MembersPage,
        frontKey: 'C2X06',
    },
    {
        description: '贈品管理',
        exact: true,
        path: MEMBERS_TABS_GIFT,
        href: MEMBERS_TABS_GIFT_OVERVIEW_URL,
        as: MembersPage,
        frontKey: 'C2X05',
    },
];

// 會員詳細頁 todo
export const memberDetailRouter = [
    {
        description: '運動紀錄',
        exact: true,
        path: MEMBER_DETAIL_WORKOUT,
        href: MEMBER_DETAIL_WORKOUT_URL,
        tabsIndex: 'workout',
        as: MemberDetailWorkoutContent,
    },
    {
        description: '會員管理',
        exact: true,
        path: MEMBER_DETAIL_BASIC,
        href: MEMBER_DETAIL_BASIC_URL,
        tabsIndex: 'basic',
        as: MemberDetailBasicInfoContent,
    },
    {
        description: '會籍管理',
        exact: true,
        path: MEMBER_DETAIL_MEMBERSHIP,
        href: MEMBER_DETAIL_MEMBERSHIP_URL,
        tabsIndex: 'membership',
        as: MemberDetailMembershipContent,
    },
    {
        description: '量身紀錄',
        exact: true,
        path: MEMBER_DETAIL_INBODY,
        href: MEMBER_DETAIL_INBODY_URL,
        tabsIndex: 'inbody',
        as: MemberDetailInbodyContent,
    },
    {
        description: '聯繫紀錄',
        exact: true,
        path: MEMBER_DETAIL_CONTACT_RECORD,
        href: MEMBER_DETAIL_CONTACT_RECORD_URL,
        tabsIndex: 'contact-record',
        as: MemberDetailContactRecordContent,
    },
    {
        description: '健康商品',
        exact: true,
        path: MEMBER_DETAIL_CYCLE_PURCHASE,
        href: MEMBER_DETAIL_CYCLE_PURCHASE_URL,
        tabsIndex: 'cycle-purchase',
        as: MemberDetailCycleOrderContent,
    },
    {
        description: '租櫃管理',
        exact: true,
        path: MEMBER_DETAIL_RENT_CABINET,
        href: MEMBER_DETAIL_RENT_CABINET_URL,
        tabsIndex: 'rent-cabinet',
        as: MemberDetailRentCabinetContent,
    },
    {
        description: '其他管理',
        exact: true,
        path: MEMBER_DETAIL_OTHER,
        href: MEMBER_DETAIL_OTHER_URL,
        tabsIndex: 'other',
        as: MemberDetailOtherContent,
    },
];

// 租櫃管理 todo
export const rentCabinetRouter = [
    {
        description: '置物櫃管理',
        exact: true,
        path: RENT_CABINET_CABINETS,
        value: 'cabinets',
        as: CabinetOverviewPage,
    },
    {
        description: '租約管理',
        exact: true,
        path: RENT_CABINET_DOCUMENT,
        value: 'document',
        as: RentCabinetOverviewPage,
    },
];

export default memberRouter;
