import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

const staticClass = 'stack';

const directionArray = ['column-reverse', 'column', 'row-reverse', 'row'];
const spacingArray = [1, 2, 3, 4, 5];
const alignItemsArray = ['stretch', 'center', 'start', 'end'];
const justifyContentArray = ['start', 'end', 'center', 'space-between', 'space-around', 'space-evenly'];
const flexWrapArray = ['revert', 'nowrap', 'wrap', 'wrap-reverse'];

const joinChildren = (children, separator) => {
    const childrenArray = React.Children.toArray(children).filter(Boolean);

    return childrenArray.reduce((output, child, index) => {
        output.push(child);
        if (index < childrenArray.length - 1) {
            output.push(React.cloneElement(separator, { key: `separator-${index}` }));
        }
        return output;
    }, []);
};

const Stack = React.forwardRef((props, ref) => {
    const {
        component: Component = 'div',
        direction = 'row',
        alignItems,
        justifyContent,
        spacing = 0,
        divider,
        children,
        className,
        style,
        flexWrap,
        average = false,
        onClick: onClickProp = () => {},
    } = props;

    let classNames = `${staticClass}-root`;
    if (directionArray.includes(direction)) classNames = clsx(classNames, `${staticClass}-direction-${direction}`);
    if (spacingArray.includes(spacing * 1)) classNames = clsx(classNames, `${staticClass}-spacing-${spacing}`);
    if (alignItemsArray.includes(alignItems)) classNames = clsx(classNames, `${staticClass}-x-${alignItems}`);
    if (justifyContentArray.includes(justifyContent)) classNames = clsx(classNames, `${staticClass}-y-${justifyContent}`);
    if (average) classNames = clsx(classNames, `${staticClass}-x-average`);
    if (flexWrapArray.includes(flexWrap)) classNames = clsx(classNames, `${staticClass}-flex-wrap-${flexWrap}`);

    const defaultProperty = {
        className: clsx(classNames, className),
        onClick: onClickProp,
        style,
        ref,
    };

    return <Component {...defaultProperty}>{divider ? joinChildren(children, divider) : children}</Component>;
});

Stack.propTypes = {
    // ----------------------------- Warning --------------------------------
    // | These PropTypes are generated from the TypeScript type definitions |
    // |     To update them edit the d.ts file and run "yarn proptypes"     |
    // ----------------------------------------------------------------------
    /**
     * The content of the component.
     */
    children: PropTypes.node,
    /**
     * The component used for the root node.
     * Either a string to use a HTML element or a component.
     */
    component: PropTypes.elementType,
    /**
     * Defines the `flex-direction` style property.
     * It is applied for all screen sizes.
     * @default 'column'
     */
    direction: PropTypes.oneOfType([
        PropTypes.oneOf(['column-reverse', 'column', 'row-reverse', 'row']),
        PropTypes.arrayOf(PropTypes.oneOf(['column-reverse', 'column', 'row-reverse', 'row'])),
        PropTypes.object,
    ]),
    /**
     * Defines the space between immediate children.
     * @default 0
     */
    spacing: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
        PropTypes.number,
        PropTypes.object,
        PropTypes.string,
    ]),
    /**
     * Add an element between each child.
     */
    divider: PropTypes.node,
    /**
     * Defines the `align-items` style property.
     * * @default 'normal'
     */
    alignItems: PropTypes.oneOfType([PropTypes.oneOf(['normal', 'stretch', 'center', 'start', 'end']), PropTypes.string]),
    /**
     * Defines the `justify-content` style property.
     * * @default 'normal'
     */
    justifyContent: PropTypes.oneOfType([PropTypes.oneOf(['start', 'end', 'space-between', 'space-around', 'space-evenly']), PropTypes.string]),
};

export default Stack;
