import React, { useRef } from 'react';
import { useParams } from 'react-router-dom';
import { Grid } from '@mui/material';
import useEmployees from '../compoments/useEmployees';
import { Form, DateField, Button } from '../../../components';
import useEmployeeCheckInApi from '../../../apis/useEmployeeCheckInApi';
import { refIsRequiredError } from '../../../util/utils';
import { parseDate } from '../../../util/moment';

const EmployeesInformationsClockInSearchBar = (props) => {
    const { employeeID } = useParams();
    const { setRefresh, setSourceData, setSearchParamsRef } = useEmployees();
    const { getEmployeesClockInRecordApi } = useEmployeeCheckInApi();
    const searchDateRef = useRef(null);

    const handleSearchClick = () => {
        if (!refIsRequiredError(searchDateRef)) {
            const date = parseDate(searchDateRef.current.getResult(), 'YYYY-MM');
            setSearchParamsRef({date});
            getSearchClockInRecordDataApi(employeeID, {
                date,
            });
        }
    };

    const getSearchClockInRecordDataApi = async (empID, params) => {
        const resp = await getEmployeesClockInRecordApi(empID, params);
        if (resp) {
            setSourceData(resp);
        }
    };

    setRefresh(handleSearchClick);

    return (
        <Form onSubmit={handleSearchClick}>
            <Grid spacing={3} container>
                <Grid xs={6} item>
                    <DateField
                        ref={searchDateRef}
                        label={'查詢月份'}
                        placeholder={'請選擇月份'}
                        views={['year', 'month']}
                        openTo={'month'}
                        format={'YYYY/MM'}
                        mask={'____/__'}
                        defaultValue={parseDate(new Date())}
                        direction={'row'}
                        required
                        fullWidth
                    />
                </Grid>
                <Grid xs={6} item>
                    <Button
                        className={'mb-1 white-space-nowrap'}
                        variant={'contained'}
                        type="submit"
                    >
                        搜尋
                    </Button>
                </Grid>
            </Grid>
        </Form>
    );
};

export default EmployeesInformationsClockInSearchBar;
