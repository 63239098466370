import React, { useRef } from 'react';
import clsx from 'clsx';
import { Dialog, DialogActions } from '@mui/material';
import { Button, Form } from '@common/components/';
import GradientColorHeader from '@icoach/components/dialog/GradientColorHeader';
import { NotesDialogContent } from '@icoach/members/memberDetail/dialog/GuidanceNotesDialog';
import { useMemberProfileApi } from '@apis/useMemberProfileApi';

const BatchPaymentMemo = (props) => {
    const { open, className, memberID, data, onClose, refresh } = props;
    const { postMemberProfileNotesApi } = useMemberProfileApi();
    const contentRef = useRef();

    const doMemberProfileNotesApi = async (memberID, params) => {
        let res = await postMemberProfileNotesApi(memberID, params);
        if (res) {
            refresh && refresh(memberID);
            onClose();
        }
    };

    const getParams = () => {
        return {
            noteType: 6,
            notes: contentRef.current && contentRef.current.getResult(),
        };
    };
    const handleSubmit = () => {
        let isError = contentRef.current && contentRef.current.isError();
        if (!isError) {
            let params = getParams();
            doMemberProfileNotesApi(memberID, params);
        }
    };
    return (
        <Dialog open={open} PaperProps={{ className: clsx('wx-40rem', className), component: Form }} onSubmit={handleSubmit} fullWidth>
            <GradientColorHeader onClose={onClose}>編輯批次扣款備註</GradientColorHeader>
            <NotesDialogContent ref={contentRef} defaultValue={data} title={'備註'} />
            <DialogActions>
                <Button type={'submit'} variant="contained">
                    送出
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default BatchPaymentMemo;
