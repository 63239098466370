import clsx from 'clsx';
import { IconButton, Typography, Tooltip } from '../../../components';
import { Edit as EditIcon } from '../../../components/icons/SvgIcon/';
import React from 'react';

const SimpleContentItem = ({
    isEdit,
    className,
    handleClickEdit,
    label,
    children,
    isMultiline,
}) => {
    const onEditEvent = () => {
        if (typeof handleClickEdit === 'function') handleClickEdit();
    };

    const Content =
        typeof children === 'string' ? (
            <Tooltip title={children}>
                <Typography paragraph className="simple-content-item-content">
                    {children}
                </Typography>
            </Tooltip>
        ) : (
            <Typography paragraph className="simple-content-item-content" component="div">
                {children}
            </Typography>
        );

    return (
        <div
            className={clsx('simple-content-item', className, {
                multiline: isMultiline,
            })}
        >
            <Typography paragraph className="simple-content-item-label" component="div">
                {label}
            </Typography>
            {Content}
            {isEdit && (
                <IconButton size="small" className="btn-edit" onClick={onEditEvent}>
                    <EditIcon />
                </IconButton>
            )}
        </div>
    );
};

export default SimpleContentItem;
