import React, { useState, useRef } from 'react';
import SaleFigureAnalysisDialog from '../dialog/SaleFigureAnalysisDialog';
import { Stack, IconButton } from '@common/components/';
import { CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon, Check as CheckIcon, Close as CloseIcon } from '@common/SvgIcon/';
import { FaEnum } from '@icoach/landingPage/staticData';

const FA_ICON = {
    0: <CheckBoxOutlineBlankIcon />,
    1: <CheckIcon color="success" />,
};
const NO_PUSH_ICON = <CloseIcon color="error" />;

const AppointmentSaleMenuOption = React.forwardRef((props, ref) => {
    const {
        className: classNameProps,
        apoId,
        apoType,
        options: optionsProp,
        sourceData: sourceDataProp = {},
        // refresh,
    } = props;
    const [open, setOpen] = useState(false);
    const [pushSourceData, setPushSourceData] = useState(sourceDataProp || {});
    const refresh = useRef(null);

    const Dialog = React.useMemo(
        () => {
            if (FaEnum.includes(String(apoType))) return SaleFigureAnalysisDialog;
            return null;
        },
        // eslint-disable-next-line
        [apoType]
    );

    refresh.current = setPushSourceData;

    return (
        <React.Fragment>
            {Dialog ? (
                <Stack direction="column" alignItems="center" spacing={1}>
                    <span className={classNameProps}>入會</span>
                    <IconButton onClick={() => setOpen(true)}>{FA_ICON[pushSourceData.pushType] || NO_PUSH_ICON}</IconButton>
                    <Dialog
                        key={pushSourceData}
                        open={open}
                        onClose={() => setOpen(false)}
                        id={apoId}
                        options={optionsProp}
                        sourceData={pushSourceData}
                        refresh={refresh}
                    />
                </Stack>
            ) : null}
        </React.Fragment>
    );
});

export default AppointmentSaleMenuOption;
