import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import EmployeesInformationsBasic from './EmployeesInformationsBasic';
import EmployeesInformationsClockIn from './EmployeesInformationsClockIn';
import { Typography, Card, Tabs, Tab, Button } from '../../../components/';
import { HUMAN_RESOURCES_EMPLOYEES_OVERVIEW } from '../../router/EmployeesRouter';

const EmployeesInformationsPage = React.forwardRef((props, ref) => {
    const { employeeID } = useParams();
    const history = useHistory();
    const [tabsIndex, setTabsIndex] = useState('information');

    const handleTabChange = (e, value) => {
        setTabsIndex(value);
    };

    const handleRedirectClick = () => {
        history.push(HUMAN_RESOURCES_EMPLOYEES_OVERVIEW);
    };

    useEffect(
        () => {
            if (!/^[\d]+$/.test(employeeID)) {
                return null;
            }
        },
        // eslint-disable-next-line
        []
    );

    return (
        <div className={'container main-container-spacing'}>
            <header className={'page-header'}>
                <Typography className={'page-header-title'} variant={'h3'}>
                    員工資訊
                </Typography>
                <Button variant="contained" onClick={handleRedirectClick}>
                    返回員工總覽
                </Button>
            </header>
            <Card className={'tabs-content-layout'}>
                <header className={'page-header'}>
                    <Tabs value={tabsIndex} onChange={handleTabChange}>
                        <Tab label={'基本資料'} value={'information'} />
                        <Tab label={'出勤紀錄'} value={'clockIn'} />
                    </Tabs>
                </header>
                {tabsIndex === 'information' ? (
                    <EmployeesInformationsBasic employeeID={employeeID} />
                ) : null}
                {tabsIndex === 'clockIn' ? (
                    <EmployeesInformationsClockIn employeeID={employeeID} />
                ) : null}
            </Card>
        </div>
    );
});

export default EmployeesInformationsPage;
