import { useCallback } from 'react';
import { useSnackbar } from 'notistack';
import useAjax from '@apis/useAjax';
import { GET_EMPLOYEE_SCHEDULE_RESOURCE_API } from '@apis/useEmployeeScheduleApi';
import usePeopleApi from '@apis/usePeopleApi';
import useShowLoading from '@util/hook/useShowLoading';
import { parseDate, isValidDate } from '@util/moment';

const GET_APPOINTMENTS_OPTIONS_API = `/api/appointments/options/`;
const GET_APPOINTMENTS_FORM_OPTIONS_API = `/api/appointments/form/options/`; // FA入會約運動,會員預約
const GET_APPOINTMENTS_MEMBER_OPTIONS_API = `/api/appointments/member/options/`;
const GET_APPOINTMENTS_NO_SALES_OPTIONS_API = `/api/appointments/no-sales/options/`;
const GET_APPOINTMENTS_APPOINTMENTID_API = (appointmentID) => `/api/appointments/${appointmentID}/`;
const PUT_APPOINTMENTS_APPOINTMENTID_API = GET_APPOINTMENTS_APPOINTMENTID_API;
const DELETE_APPOINTMENTS_APPOINTMENTID_API = GET_APPOINTMENTS_APPOINTMENTID_API;
const GET_APPOINTMENTS_TIMEPICKER_API = `/api/appointments/timepicker/`;
const GET_APPOINTMENTS_DAILY_API = `/api/appointments/daily/`;
const POST_APPOINTMENTS_CHECK_API = `/api/appointments/check/`;
const POST_APPOINTMENTS_API = `/api/appointments/`;
const PUT_APPOINTMENTS_NOTES_API = `/api/appointments/notes/`;
const PUT_APPOINTMENTS_CONFIRM_API = `/api/appointments/confirm/`;
const PUT_APPOINTMENTS_ATTEND_API = `/api/appointments/attend/`;
const PUT_APPOINTMENTS_PUSH_API = `/api/appointments/push/`;
const PUT_APPOINTMENTS_CIRCUIT_API = `/api/appointments/circuit/`;
const PUT_APPOINTMENTS_TELEMARKETING_API = `/api/appointments/telemarketing/`;
const PUT_APPOINTMENTS_SERVE_API = `/api/appointments/serve/`;
const GET_APPOINTMENTS_ADVANCED_OPTIONS_API = `/api/appointments/advanced-options/`;
const GET_APPOINTMENTS_OVERVIEW_API = `/api/appointments/overview/`;
const GET_APPOINTMENTS_MEMO_API = `/api/appointments/memo`;
const POST_APPOINTMENTS_MEMO_API = GET_APPOINTMENTS_MEMO_API;

const useAppointmentApi = () => {
    const Ajax = useAjax();
    const showLoading = useShowLoading();
    const { getPeopleApi } = usePeopleApi();
    const { enqueueSnackbar } = useSnackbar();

    // 預約表清單使用 所有option
    const getAppointmentOptionsDataApi = useCallback(
        async () => {
            const resp = await Ajax.get(GET_APPOINTMENTS_OPTIONS_API);
            if (!resp) {
                enqueueSnackbar('資料讀取有誤,請重新整理', { variant: 'error' });
            }
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    // 預約單使用 所有option
    const getAppointmentFormOptionsDataApi = useCallback(
        async () => {
            const resp = await Ajax.get(GET_APPOINTMENTS_FORM_OPTIONS_API);
            if (!resp) {
                enqueueSnackbar('資料讀取有誤,請重新整理', { variant: 'error' });
            }
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    const getAppointmentMemberFormOptionsApi = useCallback(
        async () => {
            showLoading(true);
            let allOptions = {};
            const sourceTypeOption = await Ajax.get(GET_APPOINTMENTS_MEMBER_OPTIONS_API);
            const validMemberOption = await getPeopleApi(2);
            const invalidMemberOption = await getPeopleApi(3);
            if (sourceTypeOption) {
                Object.assign(allOptions, { sourceTypeOption });
            }
            if (validMemberOption) {
                Object.assign(allOptions, { validMemberOption: validMemberOption.map((o) => ({ ...o, isValid: true })) });
            }
            if (invalidMemberOption) {
                Object.assign(allOptions, { invalidMemberOption: invalidMemberOption.map((o) => ({ ...o, isValid: false })) });
            }
            showLoading(false);
            return allOptions;
        },
        // eslint-disable-next-line
        []
    );

    // 取得未入會optipns
    const getAppointmentNoSalesOptionsApi = useCallback(
        async () => {
            const resp = await Ajax.get(GET_APPOINTMENTS_NO_SALES_OPTIONS_API);
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    const getAppointmentInitOverviewOptionsApi = useCallback(
        async () => {
            showLoading(true);
            let optionsResp = {};
            const advancedOptions = await Ajax.get(GET_APPOINTMENTS_ADVANCED_OPTIONS_API);
            const peopleOptions = await getPeopleApi(15);
            if (advancedOptions) {
                Object.assign(optionsResp, advancedOptions);
            }
            if (peopleOptions) {
                Object.assign(optionsResp, { peopleOptions });
            }
            if (!advancedOptions || !peopleOptions) {
                enqueueSnackbar('資料讀取有誤,請重新整理', { variant: 'error' });
            }
            showLoading(false);
            return optionsResp;
        },
        // eslint-disable-next-line
        []
    );

    const postAppointmentOverviewDataApi = useCallback(
        async (params) => {
            const resp = await Ajax.post(GET_APPOINTMENTS_OVERVIEW_API, params);
            if (!resp) {
                enqueueSnackbar('資料讀取有誤,請重新整理', { variant: 'error' });
            }
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    // 顧客 新增詢問的下拉式選單 and 取得單一預約單 ,預約明細使用
    const getAppointmentInitDataApi = useCallback(
        async (appointmentID) => {
            let respData = {};
            let options = {};
            showLoading(true);
            const apoOptions = await Ajax.get(GET_APPOINTMENTS_OPTIONS_API);
            const apoResp = await Ajax.get(GET_APPOINTMENTS_APPOINTMENTID_API(appointmentID));
            if (apoOptions) Object.assign(options, apoOptions);
            if (apoResp) Object.assign(respData, { result: apoResp });
            if (!apoOptions || !apoResp) {
                enqueueSnackbar('資料讀取有誤,請重新整理', { variant: 'error' });
            }
            showLoading(false);
            return { ...respData, options };
        },
        // eslint-disable-next-line
        []
    );

    // 預約表清單使用 初始
    const getAppointmentScheduleRecordsInitApi = useCallback(
        async (params) => {
            let respData = {};
            let options = {};
            showLoading(true);
            const apoOptions = await Ajax.get(GET_APPOINTMENTS_OPTIONS_API);
            const scheduleData = await Ajax.get(GET_APPOINTMENTS_DAILY_API, params);
            if (apoOptions) Object.assign(options, apoOptions);
            if (scheduleData) Object.assign(respData, { result: scheduleData });
            if (!apoOptions || !scheduleData) {
                enqueueSnackbar('資料讀取有誤,請重新整理', { variant: 'error' });
            }
            showLoading(false);
            return { ...respData, options };
        },
        // eslint-disable-next-line
        []
    );

    // 刪除預約單(假刪除)
    const deleteAppointmentIDApi = useCallback(
        async (appointmentID) => {
            const resp = await Ajax.delete(DELETE_APPOINTMENTS_APPOINTMENTID_API(appointmentID));
            if (resp) {
                enqueueSnackbar('刪除成功', { variant: 'success' });
            } else {
                enqueueSnackbar('刪除失敗', { variant: 'error' });
            }
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    // 取得當日預約清單 填寫表單初始用
    const getAppointmentTimepickerRecordsApi = useCallback(
        async (params) => {
            const resp = await Ajax.get(GET_APPOINTMENTS_TIMEPICKER_API, params);
            if (!resp) {
                enqueueSnackbar('取得當日預約清單失敗,請重新整理', { variant: 'error' });
            }
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    // 取得預約表 當日預約清單列表(刷新用整筆資料用)
    const getAppointmentDailyRecordsApi = useCallback(
        async (params) => {
            showLoading(true);
            const empOptions = await Ajax.get(GET_EMPLOYEE_SCHEDULE_RESOURCE_API, params);
            let resp = await Ajax.get(GET_APPOINTMENTS_DAILY_API, params);
            if (!empOptions || !resp) enqueueSnackbar('取得當日預約清單失敗,請重新整理', { variant: 'error' });
            if (empOptions) {
                resp.options = {
                    employeeOption: empOptions.clubEmployees,
                    usableEmployeeOption: empOptions.usableEmployees,
                };
            }
            showLoading(false);
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    // 確認該日期及未來是否有重複的預約
    const postAppointmentCheckApi = useCallback(
        async (params) => {
            const resp = await Ajax.post(POST_APPOINTMENTS_CHECK_API, params);
            if (Array.isArray(resp)) {
                if (resp.length === 0) return 'noAppointmentYet';
                else {
                    return resp.map((item) => parseDate(item, 'YYYY-MM-DD HH:mm'));
                }
            } else {
                enqueueSnackbar('查詢預約單有誤，請重新整理', { variant: 'error' });
            }
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    // 建立 顧客/會員 預約資料
    const postAppointmentAddApi = useCallback(
        async (params) => {
            const resp = await Ajax.post(POST_APPOINTMENTS_API, params);
            if (resp) {
                enqueueSnackbar('預約資料成功', { variant: 'success' });
            }
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    // 編輯 顧客/會員 預約資料
    const putAppointmentEditApi = useCallback(
        async (params, apoID) => {
            const resp = await Ajax.put(PUT_APPOINTMENTS_APPOINTMENTID_API(apoID), params);
            if (resp) {
                enqueueSnackbar('編輯預約資料成功', { variant: 'success' });
            }
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    // 修改預約單備注
    const putAppointmentNotesApi = useCallback(
        async (params) => {
            const resp = await Ajax.put(PUT_APPOINTMENTS_NOTES_API, params);
            if (resp) {
                enqueueSnackbar('成功更新預約備註', { variant: 'success' });
            }
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    // 修改確認狀態
    const putAppointmentConfirmApi = useCallback(
        async (params) => {
            const resp = await Ajax.put(PUT_APPOINTMENTS_CONFIRM_API, params);
            if (resp) {
                enqueueSnackbar('修改確認成功', { variant: 'success' });
            }
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    // 修改出席狀態
    const putAppointmentAttendApi = useCallback(
        async (params) => {
            const resp = await Ajax.put(PUT_APPOINTMENTS_ATTEND_API, params);
            if (resp) {
                enqueueSnackbar('修改出席成功', { variant: 'success' });
            }
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    // 修改推進資訊
    const putAppointmentPushApi = useCallback(
        async (params) => {
            const resp = await Ajax.put(PUT_APPOINTMENTS_PUSH_API, params);
            if (resp) {
                enqueueSnackbar('修改推進成功', { variant: 'success' });
            }
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    //修改推進狀態
    const putAppointmentTelemarketingApi = useCallback(
        async (params) => {
            const resp = await Ajax.put(PUT_APPOINTMENTS_TELEMARKETING_API, params);
            if (resp) {
                enqueueSnackbar('修改推進成功', { variant: 'success' });
            } else {
                enqueueSnackbar('修改推進失敗', { variant: 'error' });
            }
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    // 修改CC教練
    const putAppointmentCircuitApi = useCallback(
        async (params) => {
            const resp = await Ajax.put(PUT_APPOINTMENTS_CIRCUIT_API, params);
            if (resp) {
                enqueueSnackbar('修改CC教練成功', { variant: 'success' });
            } else {
                enqueueSnackbar('修改CC教練失敗', { variant: 'error' });
            }
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    // 修改服務教練
    const putAppointmentServeApi = useCallback(
        async (params) => {
            const resp = await Ajax.put(PUT_APPOINTMENTS_SERVE_API, params);
            if (resp) {
                enqueueSnackbar('修改服務教練成功', { variant: 'success' });
            } else {
                enqueueSnackbar('修改服務教練失敗', { variant: 'error' });
            }
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    const getDailyMemoApi = useCallback(
        async (params) => {
            if (!isValidDate(params)) params = parseDate();
            const resp = Ajax.get(GET_APPOINTMENTS_MEMO_API, { date: params });
            if (!resp) {
                enqueueSnackbar('讀取每日備註失敗，請再操作一次', { variant: 'error' });
            }
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    const postDailyMemoApi = useCallback(
        async (params) => {
            const resp = Ajax.post(POST_APPOINTMENTS_MEMO_API, params);
            if (!resp) {
                enqueueSnackbar('讀取每日備註失敗，請再操作一次', { variant: 'error' });
            }
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    return {
        getAppointmentOptionsDataApi,
        getAppointmentFormOptionsDataApi,
        getAppointmentMemberFormOptionsApi,
        getAppointmentInitOverviewOptionsApi,
        getAppointmentNoSalesOptionsApi,
        postAppointmentOverviewDataApi,
        getAppointmentInitDataApi,
        getAppointmentScheduleRecordsInitApi,
        deleteAppointmentIDApi,
        getAppointmentTimepickerRecordsApi,
        getAppointmentDailyRecordsApi,
        postAppointmentCheckApi,
        postAppointmentAddApi,
        putAppointmentNotesApi,
        putAppointmentEditApi,
        putAppointmentConfirmApi,
        putAppointmentAttendApi,
        putAppointmentPushApi,
        putAppointmentCircuitApi,
        putAppointmentTelemarketingApi,
        putAppointmentServeApi,
        getDailyMemoApi,
        postDailyMemoApi,
    };
};

export default useAppointmentApi;
