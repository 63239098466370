import React, { useLayoutEffect } from 'react';
import clsx from 'clsx';
import useForkRef from '../../util/hook/useForkRef';
import ListContext from './ListContext';

const staticClass = 'list-item';

const ListItem = React.forwardRef((props, ref) => {
    const {
        className,
        children: childrenProp,
        autoFocus = false,
        alignItems = 'center',
        disablePadding = false,
        disableGutters = false,
        disabled = false,
        dense = false,
        divider = false,
        ...other
    } = props;
    let classNames = staticClass;

    const context = React.useContext(ListContext);
    const childContext = {
        dense: dense || context.dense || false,
        alignItems,
        disableGutters,
        disabled,
    };

    const listItemRef = React.useRef(null);
    useLayoutEffect(() => {
        if (autoFocus) {
            if (listItemRef.current) {
                listItemRef.current.focus();
            } else if (process.env.NODE_ENV !== 'production') {
                console.error('MUI: Unable to set focus to a ListItem whose component has not been rendered.');
            }
        }
    }, [autoFocus]);

    const children = React.Children.toArray(childrenProp);

    const handleRef = useForkRef(listItemRef, ref);

    if (alignItems === 'flex-start') classNames = clsx(classNames, `${staticClass}-${alignItems}`);
    if (disablePadding) classNames = clsx(classNames, `${staticClass}-disable-padding`);
    if (!disableGutters) classNames = clsx(classNames, `${staticClass}-gutters`);
    if (dense) classNames = clsx(classNames, `${staticClass}-dense`);
    if (disabled) classNames = clsx(classNames, `${staticClass}-disabled`);
    if (divider) classNames = clsx(classNames, `${staticClass}-divider`);

    const defaultProperty = {
        className: clsx(classNames, className),
        ref: handleRef,
        ...other,
    };

    return (
        <ListContext.Provider value={childContext}>
            <li {...defaultProperty}>{children}</li>
        </ListContext.Provider>
    );
});

export default ListItem;
