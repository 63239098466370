import React, { useEffect, useState, useRef } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import _ from 'lodash';
import NonMemberContext from '@icoach/nonMember/components/NonMemberContext';
import ProspectInformationBox from '@icoach/nonMember/prospect/ProspectInformationBox';
import ProspectContactRecordBox from '@icoach/nonMember/prospect/ProspectContactRecordBox';
import { NON_MEMBER_PROSPECT_OVERVIEW_ROUTER } from '@icoach/router/NonMemberRouter';
import { Typography, Stack } from '@common/components/';
import useCustomerApi from '@apis/useCustomerApi';

const ProspectInformationPage = (props) => {
    const { getCustomerInfoApi } = useCustomerApi();
    const history = useHistory();
    const { id: customerId } = useParams();
    const [isMounted, setMounted] = useState(false);
    const [sourceData, setSourceData] = useState({});
    const { contacts = [] } = sourceData;
    const refreshRef = useRef(null);

    const getProspectInitDataApi = async (stateType, doRefresh) => {
        if (stateType === 'successDelete') doRefresh();
        else {
            const resp = await getCustomerInfoApi(customerId);
            if (resp) {
                setSourceData({ ...resp });
                setMounted(true);
            }
        }
    };

    const childContext = {
        refresh: refreshRef,
    };

    useEffect(() => {
        if (!_.isInteger(customerId * 1)) history.push(NON_MEMBER_PROSPECT_OVERVIEW_ROUTER);
        else {
            refreshRef.current = getProspectInitDataApi;
            getProspectInitDataApi();
        }
        return () => setMounted(false);
        // eslint-disable-next-line
    }, []);

    return (
        <NonMemberContext.Provider value={childContext}>
            <Stack>
                <div className="nonmember-info-width-50 px-5 py-4">{isMounted && <ProspectInformationBox sourceData={sourceData} />}</div>
                <div className="nonmember-info-width-50 px-5 py-4 nonmember-info-contact-cotainer">
                    {isMounted && (
                        <React.Fragment>
                            <Typography>聯繫紀錄({contacts.length})</Typography>
                            {_.isArray(contacts) &&
                                contacts.map((target) => (
                                    <ProspectContactRecordBox
                                        key={`namelist_contact_record_${target.contactID}`}
                                        {...target}
                                        customerID={sourceData.customerID}
                                    />
                                ))}
                        </React.Fragment>
                    )}
                </div>
            </Stack>
        </NonMemberContext.Provider>
    );
};

export default ProspectInformationPage;
