import * as clubsRouter from './';

const clubRouter = Object.keys(clubsRouter).reduce((acc, cur) => {
    return [...acc, ...clubsRouter[cur]];
}, []);

const routerMap = {
    admin: [],
    club: [...clubRouter],
};

export default routerMap;
