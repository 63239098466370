import React, { useEffect, useImperativeHandle, useState } from 'react';
import clsx from 'clsx';
import { isEmpty } from 'lodash';
import img_pdf from '../../assets/img/pdf.png';
import useMemberCyclePurchaseApi from '../../../apis/useMemberCyclePurchaseApi';
import { Alert, Box, Typography } from '../../../components';
import useShowPDF from '@util/hook/useShowPDF';
import { displayDateText } from '../../../util/moment';

export const CycleOrderRecordCard = (props) => {
    const { className, data = {} } = props;
    const showPDF = useShowPDF();
    const {
        title,
        isEffection,
        effectTypeText,
        createDate,
        effectDate,
        employeeName,
        url: link,
        suspendStartDate,
        suspendEndDate,
        suspendReasonText,
        suspendNote,
        unsubscribeReasonText,
        unsubscribeNote,
    } = data;

    const getTitle = (title, isEffection, effectTypeText) => {
        let _title = title;
        if (isEffection) {
            _title = '變更';
            if (Array.isArray(effectTypeText)) _title += effectTypeText.join('、');
        } else {
            _title += '成立';
        }
        return _title;
    };

    const handleShowPDFClick = (url) => {
        if (url) {
            showPDF({
                open: true,
                pdfUrl: url,
            });
        }
    };

    return (
        <Box className={clsx('cycle-order-history-box', className)}>
            <div className="cycle-order-history-info">
                <Typography>
                    <span className="label">申請日期</span>
                    <span className="text">{displayDateText(createDate)}</span>
                </Typography>
                {effectDate && (
                    <Typography>
                        <span className="label">生效日期</span>
                        <span className="text">{displayDateText(effectDate)}</span>
                    </Typography>
                )}
                <Typography className="coach">
                    <span className="label">擔當</span>
                    <span className="text">{employeeName}</span>
                </Typography>
            </div>
            <div className="cycle-order-history-card">
                {link && (
                    <div className="cycle-order-history-card-img" onClick={() => handleShowPDFClick(link)}>
                        <img src={img_pdf} alt="申請書" />
                    </div>
                )}
                <div className="cycle-order-history-card-content">
                    <Typography className="font-weight-bold title">{getTitle(title, isEffection, effectTypeText)}</Typography>
                    {!(!suspendStartDate && !suspendEndDate) && (
                        <Typography>
                            <span className="date-range">
                                {displayDateText(suspendStartDate, suspendEndDate, {
                                    format: 'YYYY/MM',
                                })}
                            </span>
                        </Typography>
                    )}
                    {suspendReasonText && <Typography>{`${suspendReasonText}${suspendNote && `；${suspendNote}`}`}</Typography>}
                    {unsubscribeReasonText && <Typography>{`${unsubscribeReasonText}${unsubscribeNote && `；${unsubscribeNote}`}`}</Typography>}
                </div>
            </div>
        </Box>
    );
};

const CycleOrderRecordList = React.forwardRef((props, ref) => {
    const { memberID, cycleOrderID } = props;
    const { getMemberCycleOrderHistoryApi } = useMemberCyclePurchaseApi();
    const [data, setData] = useState([]);

    const doMemberCycleOrderHistoryApi = async (memberID, cycleOrderID) => {
        let res = await getMemberCycleOrderHistoryApi(memberID, cycleOrderID);
        if (res) {
            setData(res);
            return res;
        }
    };

    useImperativeHandle(
        ref,
        () => ({
            doMemberCycleOrderHistoryApi,
        }),
        // eslint-disable-next-line
        [],
    );

    useEffect(() => {
        if (memberID && cycleOrderID) {
            doMemberCycleOrderHistoryApi(memberID, cycleOrderID);
        }
        // eslint-disable-next-line
    }, [memberID, cycleOrderID]);
    return (
        <React.Fragment>
            {Array.isArray(data) && !isEmpty(data) ? (
                <Box component={'ul'} className="time-line membership-record mb-4">
                    {data.map((item, i) => (
                        <Box component={'li'} key={i} className="time-line-item">
                            <CycleOrderRecordCard data={item} />
                        </Box>
                    ))}
                </Box>
            ) : (
                <Alert className={'mb-4'} color="info" severity="info" variant="filled">
                    尚無資料
                </Alert>
            )}
        </React.Fragment>
    );
});

export default CycleOrderRecordList;
