import React from 'react';
import clsx from 'clsx';
import {v4 as uuid} from 'uuid';
import {Grid} from '@mui/material';
import {Box, Chip, IconButton, Typography} from '@common/components/';
import {Close as CloseIcon} from '@common/SvgIcon/';
import {isEmpty, displayDateLength} from '@util/utils';
import useShowPDF from '@util/hook/useShowPDF';
import {displayDateText} from '@util/moment';
import {BadgeBox} from '@icoach/components';
import img_pdf from '@icoach/assets/img/pdf.png';

const MembershipRecordBox = (props) => {
    const {sourceData = {}, onDelete} = props;
    const showPDF = useShowPDF();
    const {
        className,
        itemTypeText,
        rangeTypeText,
        applyDate,
        employeeName,
        url: link,
        suspensionDate,
        reasonText,
        recoveryDate,
        startDate,
        endDate,
        rangeMonth,
        rangeDay,
        canDelete = false,
        isSuspendUsed,
        itemProcessMessage,
    } = sourceData;

    const handleShowPDFClick = (urlString) => {
        showPDF({
            open: true,
            pdfUrl: urlString,
        });
    };

    return (
        <Box className={clsx('membership-record-box', className, isSuspendUsed && 'membership-suspend-bg-gray')}>
            <div className="membership-record-info">
                <p>
                    <span className="label">期間</span>
                    <span className="date-range">{displayDateText(startDate, endDate, '')}</span>
                </p>
                {!(!rangeMonth && !rangeDay) && (
                    <p>
                        <span className="label">天數</span>
                        <span className="text">{displayDateLength(rangeMonth, rangeDay)}</span>
                    </p>
                )}
                <p className="coach">
                    <span className="label">擔當</span>
                    <span className="text">{employeeName}</span>
                </p>
            </div>
            <div className="membership-record-card">
                <div className={clsx('membership-record-box-close', {hide: !canDelete})}>
                    <IconButton onClick={onDelete}>
                        <CloseIcon/>
                    </IconButton>
                </div>
                {link && (
                    <div className="membership-record-card-img" onClick={() => handleShowPDFClick(link)}>
                        <img src={img_pdf} alt="申請書"/>
                    </div>
                )}

                <div className="membership-record-card-content">
                    <Typography variant="h6" className="title">
                        {rangeTypeText !== '' ? rangeTypeText : itemTypeText}
                    </Typography>
                    <p>
                        <span className="text">{'申請時間 ' + displayDateText(applyDate)}</span>
                    </p>
                    {!!(rangeMonth || rangeDay) && reasonText && (
                        <React.Fragment>
                            {reasonText.split(/\s/g).map((target) => {
                                const _t = target.trim();
                                let comp = null;
                                if (_t) {
                                    comp = <p key={uuid()}>{_t}</p>;
                                }
                                return comp;
                            })}
                        </React.Fragment>
                    )}
                    {!isEmpty(suspensionDate) && !isEmpty(recoveryDate) && (
                        <div className="mt-4">
                            <Grid container spacing={3}>
                                <Grid item xs={6}>
                                    <BadgeBox title="暫停扣款日" content={displayDateText(suspensionDate)} color="error"/>
                                </Grid>
                                <Grid item xs={6}>
                                    <BadgeBox title="恢復扣款日" content={displayDateText(recoveryDate)} color="error"/>
                                </Grid>
                            </Grid>
                        </div>
                    )}
                    {isEmpty(suspensionDate) && !isEmpty(itemProcessMessage) && (
                        <div className="mt-4">
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <Chip label={itemProcessMessage} color={'secondary'}></Chip>

                                </Grid>
                            </Grid>
                        </div>
                    )}
                </div>
            </div>
        </Box>
    );
};

export default MembershipRecordBox;
