import React from 'react';
import SvgIcon from '../SvgIcon';
import useForkRef from '@util/hook/useForkRef';

// 特殊icon 共用性極差 不是用複合路徑製作
const DialogOutlined = React.forwardRef((props, ref) => {
    const { className, ...others } = props;
    const handleRef = React.useRef(null);
    const svgRef = useForkRef(handleRef, ref);
    const defaultProperty = {
        className,
        ref: svgRef,
        ...others,
    };
    return (
        <SvgIcon {...defaultProperty}>
            <path d="M12,0C5.35,0,0,4.38,0,9.78,0,12.02,.94,14.08,2.49,15.72c-.7,1.86-2.16,3.43-2.18,3.45-.31,.33-.39,.81-.22,1.22,.14,.45,.54,.72,1,.72,2.89,0,5.13-1.21,6.54-2.18,1.36,.42,2.83,.67,4.37,.67,6.65,0,12-4.38,12-9.74S18.65,0,12,0Z" />
            <path
                style={{ fill: '#fff' }}
                d="M12,17.35c-1.26,0-2.5-.19-3.69-.57l-1.07-.34-.92,.65c-.67,.48-1.59,1.01-2.7,1.36,.35-.57,.68-1.21,.93-1.89l.5-1.32-.97-1.03c-.85-.91-1.87-2.41-1.87-4.39C2.22,5.68,6.61,2.3,11.96,2.3s9.74,3.37,9.74,7.53-4.3,7.53-9.7,7.53Z"
            />
        </SvgIcon>
    );
});

export default DialogOutlined;
