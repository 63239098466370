import React, { useRef } from 'react';
import clsx from 'clsx';
import { Button, Form } from '../../../../components';
import GradientColorHeader from '../../../components/dialog/GradientColorHeader';
import { Dialog, DialogActions } from '@mui/material';
import { NotesItemDialogContent } from './AgreedNotesDialog';
import { useMemberProfileApi } from '../../../../apis/useMemberProfileApi';

const XFactorDialog = (props) => {
    const { className, open, memberID, data, onClose, refresh } = props;
    const { postMemberProfileNotesApi } = useMemberProfileApi();
    const contentRef = useRef();

    const doMemberProfileNotesApi = async (memberID, params) => {
        let res = await postMemberProfileNotesApi(memberID, params);
        if (res) {
            refresh && refresh(memberID);
            onClose();
        }
    };

    const getParams = () => {
        return {
            noteType: 1,
            noteItems: contentRef.current && contentRef.current.getResult(),
        };
    };
    const handleSubmit = () => {
        let isError = contentRef.current && contentRef.current.isError();
        if (!isError) {
            let params = getParams();
            doMemberProfileNotesApi(memberID, params);
        }
    };
    return (
        <Dialog className={clsx('simple-content', 'dialog', className)} open={open}>
            <Form onSubmit={handleSubmit}>
                <GradientColorHeader onClose={onClose}>設定 X-Factor</GradientColorHeader>
                <NotesItemDialogContent ref={contentRef} defaultValue={data} title={'X-Factor'} />
                <DialogActions>
                    <Button type={'submit'} variant="contained">
                        確認送出
                    </Button>
                </DialogActions>
            </Form>
        </Dialog>
    );
};

export default XFactorDialog;
