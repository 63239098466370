import React, { useEffect, useState } from 'react';
import { Typography } from '../../components';
import useClubRemittanceReturnApi from '../../apis/useClubRemittanceReturnApi';
import RemitAllowanceRecordTable from './tables/RemitAllowanceRecordTable';

const AccountBookRemitAllowanceRecordPage = (props) => {
    const remittanceID = props.match.params.remittanceID;
    const { getRemittanceAbandonAllowanceApi } = useClubRemittanceReturnApi();
    const [data, setData] = useState([]);

    const doRemittanceAbandonAllowanceApi = async (remittanceID) => {
        let res = await getRemittanceAbandonAllowanceApi(remittanceID);
        if (res) {
            setData(res);
        }
    };

    useEffect(() => {
        if (remittanceID) {
            doRemittanceAbandonAllowanceApi(remittanceID);
        }
        // eslint-disable-next-line
    }, [remittanceID]);

    return (
        <div className="container main-container-spacing">
            <header className={'page-header'}>
                <Typography className={'page-header-title'} variant={'h3'}>
                    匯款折讓歷史
                </Typography>
            </header>
            <RemitAllowanceRecordTable
                rowsData={data}
                refresh={() => doRemittanceAbandonAllowanceApi(remittanceID)}
            />
        </div>
    );
};

export default AccountBookRemitAllowanceRecordPage;
