// pageIndex: 第幾頁
// pageSize:  一頁幾筆
import {
    NOTIFICATIONS_ENTIRE_ROUTER,
    NOTIFICATIONS_DOC_TRANSFERIN_ROUTER,
    NOTIFICATIONS_DOC_TRANSFEROUT_ROUTER,
    NOTIFICATIONS_DOC_MEMBERSHIP_EXPIRE_ROUTER,
    NOTIFICATIONS_INVOICE_ROUTER,
    NOTIFICATIONS_UPDATE_VERSION_ROUTER,
    NOTIFICATIONS_DOC_TEMPORARINESS_ROUTER,
    NOTIFICATIONS_CYCLE_PURCHASE_ROUTER,
} from '@icoach/router/routerPath';

export const initBookmark = () => ({
    pageIndex: 1,
    pageSize: 50,
});

export const notificationMenus = [
    { menuText: '全部', sourceType: 99, router: NOTIFICATIONS_ENTIRE_ROUTER },
    { menuText: '會籍移入', sourceType: 1, router: NOTIFICATIONS_DOC_TRANSFERIN_ROUTER },
    { menuText: '會籍移出', sourceType: 2, router: NOTIFICATIONS_DOC_TRANSFEROUT_ROUTER },
    { menuText: '會籍到期', sourceType: 3, router: NOTIFICATIONS_DOC_MEMBERSHIP_EXPIRE_ROUTER },
    { menuText: '發票異常', sourceType: 4, router: NOTIFICATIONS_INVOICE_ROUTER },
    { menuText: '暫存文書', sourceType: 5, router: NOTIFICATIONS_DOC_TEMPORARINESS_ROUTER },
    { menuText: '新版本', sourceType: 6, router: NOTIFICATIONS_UPDATE_VERSION_ROUTER },
    { menuText: '循環訂單', sourceType: 7, router: NOTIFICATIONS_CYCLE_PURCHASE_ROUTER },
];
