import React from 'react';
import PropTypes from 'prop-types';
import { createPopper } from '@popperjs/core';
import Portal from '../portal/Portal';
import useForkRef from '../../util/hook/useForkRef';
import useEnhancedEffect from '../../util/hook/useEnhancedEffect';
import { ownerDocument } from '../../util/utils';

const refType = PropTypes.oneOfType([PropTypes.func, PropTypes.object]);

const chainPropTypes = (propType1, propType2) => {
    // if (process.env.NODE_ENV === 'production') {
    //     return () => null;
    // }
    return function validate(...args) {
        return propType1(...args) || propType2(...args);
    };
};

const HTMLElementType = (props, propName, componentName, location, propFullName) => {
    // if (process.env.NODE_ENV === 'production') {
    //   return null;
    // }
    const propValue = props[propName];
    const safePropName = propFullName || propName;
    if (propValue == null) return null;
    if (propValue && propValue.nodeType !== 1) {
        return new Error(
            `Invalid ${location} \`${safePropName}\` supplied to \`${componentName}\`. Expected an HTMLElement.`
        );
    }
    return null;
};

const flipPlacement = (placement, direction) => {
    if (direction === 'ltr') return placement;
    switch (placement) {
        case 'bottom-end':
            return 'bottom-start';
        case 'bottom-start':
            return 'bottom-end';
        case 'top-end':
            return 'top-start';
        case 'top-start':
            return 'top-end';
        default:
            return placement;
    }
};

const resolveAnchorEl = (anchorEl) => {
    return typeof anchorEl === 'function' ? anchorEl() : anchorEl;
};

/* eslint-disable react/prop-types */
const PopperTooltip = React.forwardRef(function PopperTooltip(props, ref) {
    const {
        anchorEl,
        children,
        direction,
        disablePortal,
        modifiers,
        open,
        ownerState,
        placement: initialPlacement,
        popperOptions,
        popperRef: popperRefProp,
        TransitionProps,
        as,
        ...other
    } = props;

    const tooltipRef = React.useRef(null);
    const ownRef = useForkRef(tooltipRef, ref);

    const popperRef = React.useRef(null);
    const handlePopperRef = useForkRef(popperRef, popperRefProp);
    const handlePopperRefRef = React.useRef(handlePopperRef);

    useEnhancedEffect(() => {
        handlePopperRefRef.current = handlePopperRef;
    }, [handlePopperRef]);

    React.useImperativeHandle(
        popperRefProp,
        () => popperRef.current,
        // eslint-disable-next-line
        []
    );

    const rtlPlacement = flipPlacement(initialPlacement, direction);
    /**
     * placement initialized from prop but can change during lifetime if modifiers.flip.
     * modifiers.flip is essentially a flip for controlled/uncontrolled behavior
     */
    const [placement, setPlacement] = React.useState(rtlPlacement);

    React.useEffect(() => {
        popperRef.current && popperRef.current.forceUpdate();
    });

    useEnhancedEffect(() => {
        if (!anchorEl || !open) {
            return undefined;
        }

        const handlePopperUpdate = (data) => {
            setPlacement(data.placement);
        };

        const resolvedAnchorEl = resolveAnchorEl(anchorEl);

        if (process.env.NODE_ENV !== 'production') {
            if (resolvedAnchorEl && resolvedAnchorEl.nodeType === 1) {
                const box = resolvedAnchorEl.getBoundingClientRect();
                if (
                    process.env.NODE_ENV !== 'test' &&
                    box.top === 0 &&
                    box.left === 0 &&
                    box.right === 0 &&
                    box.bottom === 0
                ) {
                    console.warn(
                        [
                            'Allen: The `anchorEl` prop provided to the component is invalid.',
                            'The anchor element should be part of the document layout.',
                            "Make sure the element is present in the document or that it's not display none.",
                        ].join('\n')
                    );
                }
            }
        }

        let popperModifiers = [
            {
                name: 'preventOverflow',
                options: {
                    altBoundary: disablePortal,
                },
            },
            {
                name: 'flip',
                options: {
                    altBoundary: disablePortal,
                },
            },
            {
                name: 'onUpdate',
                enabled: true,
                phase: 'afterWrite',
                fn: ({ state }) => {
                    handlePopperUpdate(state);
                },
            },
        ];

        if (modifiers != null) {
            popperModifiers = popperModifiers.concat(modifiers);
        }
        if (popperOptions && popperOptions.modifiers != null) {
            popperModifiers = popperModifiers.concat(popperOptions.modifiers);
        }

        const popper = createPopper(resolveAnchorEl(anchorEl), tooltipRef.current, {
            placement: rtlPlacement,
            ...popperOptions,
            modifiers: popperModifiers,
        });

        handlePopperRefRef.current(popper);

        return () => {
            popper.destroy();
            handlePopperRefRef.current(null);
        };
    }, [anchorEl, disablePortal, modifiers, open, popperOptions, rtlPlacement]);

    const childProps = { placement };

    if (TransitionProps !== null) {
        childProps.TransitionProps = TransitionProps;
    }

    return (
        <div ref={ownRef} role="tooltip" {...other}>
            {typeof children === 'function' ? children(childProps) : children}
        </div>
    );
});

const defaultPopperOptions = {};

/**
 * Poppers rely on the 3rd party library [Popper.js](https://popper.js.org/docs/v2/) for positioning.
 */
const PopperUnstyled = React.forwardRef((props, ref) => {
    const {
        anchorEl,
        children,
        container: containerProp,
        direction = 'ltr',
        disablePortal = false,
        keepMounted = false,
        modifiers,
        open,
        placement = 'bottom',
        popperOptions = defaultPopperOptions,
        popperRef,
        style,
        transition = false,
        ...other
    } = props;
    const [exited, setExited] = React.useState(true);

    const handleEnter = () => {
        setExited(false);
    };

    const handleExited = () => {
        setExited(true);
    };

    if (!keepMounted && !open && (!transition || exited)) {
        return null;
    }

    // If the container prop is provided, use that
    // If the anchorEl prop is provided, use its parent body element as the container
    // If neither are provided let the Modal take care of choosing the container
    const container =
        containerProp || (anchorEl ? ownerDocument(resolveAnchorEl(anchorEl)).body : undefined);

    return (
        <Portal disablePortal={disablePortal} container={container}>
            <PopperTooltip
                anchorEl={anchorEl}
                direction={direction}
                disablePortal={disablePortal}
                modifiers={modifiers}
                ref={ref}
                open={transition ? !exited : open}
                placement={placement}
                popperOptions={popperOptions}
                popperRef={popperRef}
                {...other}
                style={{
                    // Prevents scroll issue, waiting for Popper.js to add this style once initiated.
                    position: 'fixed',
                    // Fix Popper.js display issue
                    top: 0,
                    left: 0,
                    display: !open && keepMounted && (!transition || exited) ? 'none' : null,
                    ...style,
                }}
                TransitionProps={
                    transition
                        ? {
                              in: open,
                              onEnter: handleEnter,
                              onExited: handleExited,
                          }
                        : null
                }
            >
                {children}
            </PopperTooltip>
        </Portal>
    );
});

PopperUnstyled.propTypes /* remove-proptypes */ = {
    // ----------------------------- Warning --------------------------------
    // | These PropTypes are generated from the TypeScript type definitions |
    // |     To update them edit the d.ts file and run "yarn proptypes"     |
    // ----------------------------------------------------------------------
    /**
     * An HTML element, [virtualElement](https://popper.js.org/docs/v2/virtual-elements/),
     * or a function that returns either.
     * It's used to set the position of the popper.
     * The return value will passed as the reference object of the Popper instance.
     */
    anchorEl: chainPropTypes(
        PropTypes.oneOfType([HTMLElementType, PropTypes.object, PropTypes.func]),
        (props) => {
            if (props.open) {
                const resolvedAnchorEl = resolveAnchorEl(props.anchorEl);

                if (resolvedAnchorEl && resolvedAnchorEl.nodeType === 1) {
                    const box = resolvedAnchorEl.getBoundingClientRect();

                    if (
                        process.env.NODE_ENV !== 'test' &&
                        box.top === 0 &&
                        box.left === 0 &&
                        box.right === 0 &&
                        box.bottom === 0
                    ) {
                        return new Error(
                            [
                                'Allen: The `anchorEl` prop provided to the component is invalid.',
                                'The anchor element should be part of the document layout.',
                                "Make sure the element is present in the document or that it's not display none.",
                            ].join('\n')
                        );
                    }
                } else if (
                    !resolvedAnchorEl ||
                    typeof resolvedAnchorEl.getBoundingClientRect !== 'function' ||
                    (resolvedAnchorEl.contextElement != null &&
                        resolvedAnchorEl.contextElement.nodeType !== 1)
                ) {
                    return new Error(
                        [
                            'Allen: The `anchorEl` prop provided to the component is invalid.',
                            'It should be an HTML element instance or a virtualElement ',
                            '(https://popper.js.org/docs/v2/virtual-elements/).',
                        ].join('\n')
                    );
                }
            }

            return null;
        }
    ),
    /**
     * Popper render function or node.
     */
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
    /**
     * An HTML element or function that returns one.
     * The `container` will have the portal children appended to it.
     *
     * By default, it uses the body of the top-level document object,
     * so it's simply `document.body` most of the time.
     */
    container: PropTypes.oneOfType([HTMLElementType, PropTypes.func]),
    /**
     * Direction of the text.
     * @default 'ltr'
     */
    direction: PropTypes.oneOf(['ltr', 'rtl']),
    /**
     * The `children` will be under the DOM hierarchy of the parent component.
     * @default false
     */
    disablePortal: PropTypes.bool,
    /**
     * Always keep the children in the DOM.
     * This prop can be useful in SEO situation or
     * when you want to maximize the responsiveness of the Popper.
     * @default false
     */
    keepMounted: PropTypes.bool,
    /**
     * Popper.js is based on a "plugin-like" architecture,
     * most of its features are fully encapsulated "modifiers".
     *
     * A modifier is a function that is called each time Popper.js needs to
     * compute the position of the popper.
     * For this reason, modifiers should be very performant to avoid bottlenecks.
     * To learn how to create a modifier, [read the modifiers documentation](https://popper.js.org/docs/v2/modifiers/).
     */
    modifiers: PropTypes.arrayOf(
        PropTypes.shape({
            data: PropTypes.object,
            effect: PropTypes.func,
            enabled: PropTypes.bool,
            fn: PropTypes.func,
            name: PropTypes.any,
            options: PropTypes.object,
            phase: PropTypes.oneOf([
                'afterMain',
                'afterRead',
                'afterWrite',
                'beforeMain',
                'beforeRead',
                'beforeWrite',
                'main',
                'read',
                'write',
            ]),
            requires: PropTypes.arrayOf(PropTypes.string),
            requiresIfExists: PropTypes.arrayOf(PropTypes.string),
        })
    ),
    /**
     * If `true`, the component is shown.
     */
    open: PropTypes.bool.isRequired,
    /**
     * Popper placement.
     * @default 'bottom'
     */
    placement: PropTypes.oneOf([
        'auto-end',
        'auto-start',
        'auto',
        'bottom-end',
        'bottom-start',
        'bottom',
        'left-end',
        'left-start',
        'left',
        'right-end',
        'right-start',
        'right',
        'top-end',
        'top-start',
        'top',
    ]),
    /**
     * Options provided to the [`Popper.js`](https://popper.js.org/docs/v2/constructors/#options) instance.
     * @default {}
     */
    popperOptions: PropTypes.shape({
        modifiers: PropTypes.array,
        onFirstUpdate: PropTypes.func,
        placement: PropTypes.oneOf([
            'auto-end',
            'auto-start',
            'auto',
            'bottom-end',
            'bottom-start',
            'bottom',
            'left-end',
            'left-start',
            'left',
            'right-end',
            'right-start',
            'right',
            'top-end',
            'top-start',
            'top',
        ]),
        strategy: PropTypes.oneOf(['absolute', 'fixed']),
    }),
    /**
     * A ref that points to the used popper instance.
     */
    popperRef: refType,
    /**
     * @ignore
     */
    style: PropTypes.object,
    /**
     * Help supporting a react-transition-group/Transition component.
     * @default false
     */
    transition: PropTypes.bool,
};

export default PopperUnstyled;
