import { useCallback } from 'react';
import useAjax from '@apis/useAjax';
import { useSnackbar } from 'notistack';

const POST_SMS_TEMPLATE_OVERVIEW_API = `/api/sms-template/overview/`;
const GET_SMS_TEMPLATE_SMSTEMPLATEID_API = (smsTemplateID) => `/api/sms-template/${smsTemplateID}/`;
const POST_SMS_TEMPLATE_SMSTEMPLATEID_API = GET_SMS_TEMPLATE_SMSTEMPLATEID_API;
const DELETE_SMS_TEMPLATE_SMSTEMPLATEID_API = GET_SMS_TEMPLATE_SMSTEMPLATEID_API;
const POST_SMS_TEMPLATE_API = `/api/sms-template/`;

const useSmsTemplate = () => {
    const Ajax = useAjax();
    const { enqueueSnackbar } = useSnackbar();

    // 簡訊公版資料總覽
    const postSmsTemplateOptionApi = useCallback(
        async () => {
            let resp = await Ajax.post(POST_SMS_TEMPLATE_OVERVIEW_API);
            if (!resp) {
                enqueueSnackbar('讀取公版簡訊失敗,請重新整理', { variant: 'error' });
            } else {
                resp = resp.map(({ smsTemplateID, templateText, name }) => ({
                    value: smsTemplateID,
                    text: templateText,
                    title: name,
                }));
            }
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    // 簡訊公版資料總覽
    const postSmsTemplateOverviewApi = useCallback(
        async () => {
            const resp = await Ajax.post(POST_SMS_TEMPLATE_OVERVIEW_API);
            if (!resp) {
                enqueueSnackbar('讀取資料失敗,請重新整理', { variant: 'error' });
            }
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    // 取得 SMS 公版單一資料
    const getSmsTemplatePublicDataApi = useCallback(
        async (smsTemplateID) => {
            const resp = await Ajax.get(GET_SMS_TEMPLATE_SMSTEMPLATEID_API(smsTemplateID));
            if (!resp) {
                enqueueSnackbar('讀取資料失敗,請重新整理', { variant: 'error' });
            }
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    // 編輯 SMS 公版資料
    const putSmsTemplatePublicDataApi = useCallback(
        async (smsTemplateID, params) => {
            const resp = await Ajax.put(POST_SMS_TEMPLATE_SMSTEMPLATEID_API(smsTemplateID), params);
            if (resp) {
                enqueueSnackbar('修改成功', { variant: 'success' });
            } else {
                enqueueSnackbar('修改資料失敗,請重新再操作一次', { variant: 'error' });
            }
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    // 刪除 SMS 公版資料
    const deleteSmsTemplatePublicDataApi = useCallback(
        async (smsTemplateID) => {
            const resp = await Ajax.delete(DELETE_SMS_TEMPLATE_SMSTEMPLATEID_API(smsTemplateID));
            if (resp) {
                enqueueSnackbar('刪除成功', { variant: 'success' });
            } else {
                enqueueSnackbar('刪除資料失敗,請重新再操作一次', { variant: 'error' });
            }
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    // 新增 SMS 公版資料
    const postAddSmsTemplatePublicDataApi = useCallback(
        async (params) => {
            const resp = await Ajax.post(POST_SMS_TEMPLATE_API, params);
            if (resp) {
                enqueueSnackbar('新增成功', { variant: 'success' });
            } else {
                enqueueSnackbar('新增失敗,請重新再操作一次', { variant: 'error' });
            }
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    return {
        postSmsTemplateOptionApi,
        postSmsTemplateOverviewApi,
        getSmsTemplatePublicDataApi,
        putSmsTemplatePublicDataApi,
        deleteSmsTemplatePublicDataApi,
        postAddSmsTemplatePublicDataApi,
    };
};

export default useSmsTemplate;
