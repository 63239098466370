import { useEffect, useCallback, useState } from 'react';
import { useSnackbar } from 'notistack';
import useAjax from '@apis/useAjax';

const GET_EMPLOYEES_OPTIONS_API = `/api/employees/options/`;
const GET_EMPLOYEES_OPTIONS_CLUBID_API = (clubID) => `/api/employees/options/${clubID}/`;
const GET_EMPLOYEES_API = `/api/employees/`;
const POST_EMPLOYEES_API = GET_EMPLOYEES_API;
const PUT_EMPLOYEES_API = GET_EMPLOYEES_API;
const GET_EMPLOYEES_STATUS_OPTIONS_API = `/api/employees/status/options/`;
const GET_EMPLOYEES_RANK_TYPE_OPTIONS_API = `/api/employees/rank-type/options/`;
const GET_EMPLOYEES_OVERVIEW_ADVANCE_API = `/api/employees/overview/advance/`;
const GET_EMPLOYEES_EMPLOYEEID_API = (employeeID) => `/api/employees/${employeeID}/`;

const useEmployeeApi = (targetClubID) => {
    const Ajax = useAjax();
    const [employees, setEmployees] = useState({});
    const { enqueueSnackbar } = useSnackbar();

    const clearEmployeesData = (who) => {
        if (!who) setEmployees({});
        setEmployees((old) => ({
            ...old,
            [who]: null,
        }));
    };

    // 取得該使用者店舖內全部可用的員工(教練)
    const getEmployeesOptionsApi = useCallback(
        async () => {
            const resp = await Ajax.get(GET_EMPLOYEES_OPTIONS_API);
            if (!resp) enqueueSnackbar('讀取教練失敗，請重新操作', { variant: 'error' });
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    // 取得使用者店舖內的員工清單(加盟主)
    const getEmployeesApi = useCallback(
        async () => {
            const resp = await Ajax.get(GET_EMPLOYEES_API);
            if (!resp) enqueueSnackbar('讀取店舖內的員工清單，請重新操作', { variant: 'error' });
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    // 新增員工
    const postEmployeesApi = useCallback(
        async (params) => {
            const resp = await Ajax.post(POST_EMPLOYEES_API, params);
            if (!resp) enqueueSnackbar('新增失敗，請再操作一次', { variant: 'error' });
            else enqueueSnackbar('儲存成功', { variant: 'success' });
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    // 更新員工
    const putEmployeesApi = useCallback(
        async (params) => {
            const resp = await Ajax.put(PUT_EMPLOYEES_API, params);
            if (!resp) enqueueSnackbar('修改失敗，請再操作一次', { variant: 'error' });
            else enqueueSnackbar('修改成功', { variant: 'success' });
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    const getEmployeesClubIdApi = useCallback(
        async (clubID) => {
            const resp = await Ajax.get(GET_EMPLOYEES_OPTIONS_CLUBID_API(clubID));
            if (!resp) enqueueSnackbar('讀取教練失敗', { variant: 'error' });
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    // 取得員工狀態下拉選項
    const getEmployeesStatusOptionsApi = useCallback(
        async () => {
            const resp = await Ajax.get(GET_EMPLOYEES_STATUS_OPTIONS_API);
            if (!resp) enqueueSnackbar('讀取員工狀態選項失敗,請重新整理畫面', { variant: 'error' });
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    // 取得員工職稱下拉選項
    const getEmployeesRankTypeOptionsApi = useCallback(
        async () => {
            const resp = await Ajax.get(GET_EMPLOYEES_RANK_TYPE_OPTIONS_API);
            if (!resp) enqueueSnackbar('讀取員工職稱選項失敗,請重新整理畫面', { variant: 'error' });
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    // 取得員工總覽(進階)
    const getEmployeesOverviewApi = useCallback(
        async (params) => {
            const resp = await Ajax.get(GET_EMPLOYEES_OVERVIEW_ADVANCE_API, params);
            if (!resp) enqueueSnackbar('讀取總覽失敗,請重新整理畫面', { variant: 'error' });
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    // 取得單一員工資訊內容及聯絡資訊
    const getEmployeesEmployeeIDApi = useCallback(
        async (id) => {
            const resp = await Ajax.get(GET_EMPLOYEES_EMPLOYEEID_API(id));
            if (!resp) enqueueSnackbar('取得單一員工資訊失敗,請重新整理畫面', { variant: 'error' });
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    useEffect(
        () => {
            async function fetchData() {
                if (!targetClubID) return false;
                if (employees.hasOwnProperty(targetClubID)) return false;
                const resp = await getEmployeesClubIdApi(targetClubID);
                if (resp) {
                    setEmployees((old) => ({
                        ...old,
                        [targetClubID]: resp,
                    }));
                }
            }
            fetchData();
        },
        // eslint-disable-next-line
        [targetClubID]
    );

    return {
        employees,
        postEmployeesApi,
        putEmployeesApi,
        getEmployeesOptionsApi,
        getEmployeesClubIdApi,
        getEmployeesApi,
        clearEmployeesData,
        getEmployeesStatusOptionsApi,
        getEmployeesRankTypeOptionsApi,
        getEmployeesOverviewApi,
        getEmployeesEmployeeIDApi,
    };
};

export default useEmployeeApi;
