import React, { useRef } from 'react';
import { Box, Button, Card, PrivateRoute, Tab, Tabs } from '@common/components/';
import { NavLink, Switch, useParams } from 'react-router-dom';
import { MEMBERS_TABS_GIFT_DISPATCH_METRICS_URL, MEMBERS_TABS_GIFT_DISPATCH_RECORD_URL, MEMBERS_TABS_GIFT_OVERVIEW_URL } from '@icoach/router/MembersRouter';
import { GiftProvider, useGiftContext } from '@icoach/gift/components/GiftContext';
import GiftDialogBox from '@icoach/gift/dialog/GiftDialogBox';
import GiftPage from '@icoach/gift/GiftPage';
import GiftDispatchRecordPage from '@icoach/gift/GiftDispatchRecordPage';
import GiftDispatchMetricsPage from '@icoach/gift/GiftDispatchMetricsPage';

const GiftTabs = () => {
    const { subTabs } = useParams();
    const { dialogRef, refresh } = useGiftContext();

    const openGiftDialog = () => {
        dialogRef.current && dialogRef.current.openGiftDialog(null, refresh);
    };

    const openGiftDispatchDialog = () => {
        dialogRef.current && dialogRef.current.openGiftDispatchDialog(null, refresh);
    };
    return (
        <Box component={'header'} className={'page-header'}>
            <Tabs value={subTabs}>
                <Tab label={'贈品總覽'} className={'py-3'} value={'overview'} component={NavLink} to={MEMBERS_TABS_GIFT_OVERVIEW_URL} />
                <Tab label={'發送紀錄'} className={'py-3'} value={'dispatch-record'} component={NavLink} to={MEMBERS_TABS_GIFT_DISPATCH_RECORD_URL} />
                <Tab label={'贈送統計'} className={'py-3'} value={'dispatch-metrics'} component={NavLink} to={MEMBERS_TABS_GIFT_DISPATCH_METRICS_URL} />
            </Tabs>
            <Box className="btn-group">
                <Button variant={'outlined'} onClick={openGiftDialog}>
                    新增贈品
                </Button>
                <Button variant={'outlined'} onClick={openGiftDispatchDialog}>
                    贈品發送
                </Button>
            </Box>
        </Box>
    );
};

const GiftManagementPage = (props) => {
    const dialogRef = useRef(null);
    const refreshRef = useRef(null);

    const childContext = {
        refresh: () => typeof refreshRef.current === 'function' && refreshRef.current(),
        setRefresh: (fn) => (refreshRef.current = fn),
        dialogRef: dialogRef,
    };

    return (
        <GiftProvider value={childContext}>
            <Card className={'tabs-content-layout'}>
                <GiftTabs />
                <Card.CardContent className={'py-3 px-4'}>
                    <Switch>
                        <PrivateRoute path={MEMBERS_TABS_GIFT_OVERVIEW_URL} as={GiftPage} exact />
                        <PrivateRoute path={MEMBERS_TABS_GIFT_DISPATCH_METRICS_URL} as={GiftDispatchMetricsPage} exact />
                        <PrivateRoute path={MEMBERS_TABS_GIFT_DISPATCH_RECORD_URL} as={GiftDispatchRecordPage} exact />
                    </Switch>
                </Card.CardContent>
            </Card>
            <GiftDialogBox ref={dialogRef} />
        </GiftProvider>
    );
};

export default GiftManagementPage;
