import React, { useState } from 'react';
import _ from 'lodash';
import { ReadTextField } from '@icoach/components/';
import { Stack, SelectField, MenuItem, Typography, Alert } from '@common/components/';
import useEmployeeScheduleApi from '@apis/useEmployeeScheduleApi';
import { useEffect } from 'react';

const ServiceCoachBox = (props) => {
    const { postEmployeeScheduleApoDataApi } = useEmployeeScheduleApi();
    const { employeeID: employeeIDProps, options, appointmentID, appointmentType, scheduleID: scheduleIDProps, scheduleDate, scheduleTime } = props;
    const [employeeID, setEmployeeID] = useState(employeeIDProps);
    const [scheduleID, setScheduleID] = useState(scheduleIDProps);

    const handleOnDutyOnChange = (e, node, selectedID) => {
        const params = {
            scheduleDate: scheduleDate,
            scheduleTime: scheduleTime,
            scheduleID: scheduleID,
            appointmentID: appointmentID,
            appointmentType: appointmentType,
            employeeID: selectedID,
        };
        doPostServiceSellApoApi(params, selectedID);
    };

    const doPostServiceSellApoApi = async (params, selectedID) => {
        const resp = await postEmployeeScheduleApoDataApi(params);
        if (resp) {
            setEmployeeID(selectedID);
            setScheduleID(resp);
        }
    };

    useEffect(
        () => {
            setScheduleID(scheduleIDProps);
        },
        // eslint-disable-next-line
        [scheduleIDProps]
    );

    return (
        <SelectField value={employeeID || 0} style={{ width: 150 }} fullWidth={false} onChange={handleOnDutyOnChange}>
            <MenuItem value="0"><em>未安排</em></MenuItem>
            {options.map(({ text, value, disabled }) => (
                <MenuItem value={value} disabled={disabled} key={`${value}-${text}`}>
                    {text}
                </MenuItem>
            ))}
        </SelectField>
    );
};

const EmployeeScheduleServiceItem = React.forwardRef((props, ref) => {
    const { sourceData: sourceDataProps = [], refresh, options, scheduleDate, scheduleTime } = props;

    return (
        <Stack spacing={2} direction="column">
            {!_.isEmpty(sourceDataProps) ? (
                sourceDataProps.map(({ employeeID, appointmentTypeText, appointmentType, name, nickName, appointmentID, scheduleID }) => {
                    return (
                        <Stack spacing={2} alignItems="center" justifyContent="start" key={appointmentID}>
                            <ServiceCoachBox
                                employeeID={employeeID}
                                options={options}
                                scheduleDate={scheduleDate}
                                scheduleTime={scheduleTime}
                                appointmentID={appointmentID}
                                appointmentType={appointmentType}
                                scheduleID={scheduleID}
                                refresh={refresh}
                            />
                            <ReadTextField>{appointmentTypeText}</ReadTextField>
                            <Stack direction="column" alignItems="start">
                                <Typography component="span" style={{ fontWeight: 700 }}>
                                    {name}
                                </Typography>
                                <Typography component="span" variant={'body2'} color="secondary">
                                    {nickName}
                                </Typography>
                            </Stack>
                        </Stack>
                    );
                })
            ) : (
                <Alert severity="info" color="gray">
                    尚無預約單
                </Alert>
            )}
        </Stack>
    );
});

export default EmployeeScheduleServiceItem;
