import React, { useMemo, useRef } from 'react';
import Tablenization from '@icoach/components/Tablenization';
import { Box } from '@common/components/';
import { MeasureLeaderboardDialogBox } from '@icoach/measureLeaderboard/';
import { useLeaderborad } from '@icoach/measureLeaderboard/useLeaderboradContext';
import clsx from 'clsx';
import { BusinessCardBox } from '@icoach/components';

const MeasureLeaderboardTable = React.memo((props = {}) => {
    const { sourceData, getMonths } = useLeaderborad();
    const MeasureLeaderboardDialogBoxRef = useRef(null);

    const rowsLabel = useMemo(
        () => {
            const { firstDate, secondDate } = getMonths();
            return [
                {
                    cellKey: 'clubRanking',
                    headerLabel: '排名',
                    align: 'center',
                    width: 9,
                    maxWidth: 9,
                    formatCell: (cellValue) => {
                        const ranking = parseInt(cellValue);
                        let className = 'text-gray-400';

                        if (ranking <= 3) {
                            className = 'text-warning';
                        }

                        return <Box className={clsx('font-weight-bold h4', className)}>{cellValue}</Box>;
                    },
                },
                {
                    cellKey: 'members',
                    headerLabel: '會員',
                    width: 15,
                    maxWidth: 15,
                    formatCell: (cellValue, rowsData) => {
                        const { memberName, memberNo, picturePath } = rowsData;
                        return <BusinessCardBox rowData={{ memberName, memberNo, memberImg: picturePath }} />;
                    },
                },
                {
                    cellKey: 'diffValue',
                    headerLabel: '成果',
                    width: 9,
                    maxWidth: 9,
                    align: 'center',
                    formatCell: (cellValue, { clubRanking }) => {
                        const diffValue = parseFloat(cellValue);
                        const ranking = parseInt(clubRanking);
                        let className = '';

                        if (ranking <= 3) {
                            className = 'text-warning';
                        }

                        return <Box className={clsx('font-weight-bold', className)}>{`${diffValue}`}</Box>;
                    },
                },
                {
                    cellKey: 'ratherSecond',
                    headerLabel: `${secondDate}月`,
                    width: 9,
                    maxWidth: 9,
                    align: 'center',
                    formatCell: (cellValue) => {
                        return cellValue;
                    },
                },
                {
                    cellKey: 'ratherFirst',
                    headerLabel: `${firstDate}月`,
                    width: 9,
                    maxWidth: 9,
                    align: 'center',
                    formatCell: (cellValue) => {
                        return cellValue;
                    },
                },
                {
                    cellKey: 'rankingNum',
                    headerLabel: '同名次人數',
                    width: 14,
                    maxWidth: 14,
                    align: 'center',
                },
                {
                    cellKey: 'more',
                    headerLabel: '',
                    width: 12,
                    formatCell: () => {
                        return '';
                    },
                },
            ];
        },
        // eslint-disable-next-line
        [sourceData]
    );

    return (
        <React.Fragment>
            {rowsLabel.length !== 0 && (
                <React.Fragment>
                    <Box className={'scroll-arrow-container'}>
                        <Tablenization
                            cardProps={{
                                className: 'leader-board-table',
                            }}
                            isPagination
                            isFixed
                        >
                            <Tablenization.TableRowHeader headerRow={rowsLabel} />
                            <Tablenization.TableRowBody headerRow={rowsLabel} rowsData={sourceData} rowHiddenHover={true} />
                        </Tablenization>
                    </Box>
                    <MeasureLeaderboardDialogBox ref={MeasureLeaderboardDialogBoxRef} />
                </React.Fragment>
            )}
        </React.Fragment>
    );
});

export default MeasureLeaderboardTable;
