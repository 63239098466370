import React from 'react';
import { Dialog, DialogContent, Grid } from '@mui/material';
import { Button, Typography, Alert } from '@common/components/';
import GradientColorHeader from '@icoach/components/dialog/GradientColorHeader';
import useCustomerApi from '@apis/useCustomerApi';

// 刪除對象(顧客/名單)
const RemoveCutomersDialog = (props) => {
    const { onClose: propsOnClose, open, sourceData = [], refresh, refreshParams = [], dialogViewMap = {} } = props;
    const { TEXT } = dialogViewMap;
    const { deleteCustomersRemove } = useCustomerApi();

    const handleCloseOnClick = () => {
        propsOnClose(null);
    };

    const handleSendOnClick = (e) => {
        const params = {
            customerIDs: sourceData.map(({ customerID }) => customerID),
        };
        addRemoveApi(params);
    };

    const addRemoveApi = async (params) => {
        const resp = await deleteCustomersRemove(params);
        if (resp) {
            propsOnClose(null);
            refresh && refresh.current && refresh.current(...refreshParams);
        } else {
            console.error(resp);
        }
    };

    return (
        <Dialog open={open}>
            <GradientColorHeader onClose={handleCloseOnClick}>訊息</GradientColorHeader>
            <DialogContent>
                <Grid container>
                    <Grid item xs={12}>
                        <Alert severity="remove" variant="filled" color="error">
                            確定要刪除({sourceData.length})?
                            <br />
                            刪除會影響名單、詢問、APO與FA、確定要刪除?
                        </Alert>
                    </Grid>
                    <Grid item xs={12} className="my-2">
                        <Grid container>
                            <Grid item xs={6}>
                                <Typography variant="span" className="text-gray-500">
                                    {TEXT}
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant="span" className="text-gray-500">
                                    {'電話'}
                                </Typography>
                            </Grid>
                        </Grid>
                        {sourceData.map((target, _index) => {
                            const { name, cellPhone } = target;
                            if (!name) return null;
                            return (
                                <Grid container spacing={1} key={`error_content_${_index}`}>
                                    <Grid item xs={1}></Grid>
                                    <Grid item xs={5}>
                                        <Typography className="ml-5" variant="h5" compoment="span">
                                            {name}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={1}></Grid>
                                    <Grid item xs={5}>
                                        {cellPhone && (
                                            <Typography className="ml-3" variant="h5" compoment="span">
                                                {cellPhone}
                                            </Typography>
                                        )}
                                    </Grid>
                                </Grid>
                            );
                        })}
                    </Grid>
                    <Grid item xs={12}>
                        <Button size="large" variant="contained" color="error" onClick={handleSendOnClick}>
                            確定
                        </Button>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};

export default RemoveCutomersDialog;
