import React, { useState, useRef } from 'react';
import { AccountBookProvider, useAccountBookContext } from '@icoach/accountBook/components/AccountBookContext';
import { Box, Button, Card, Tab, Tabs } from '@common/components/';
import { statisticalType } from '@icoach/accountBook/staticData';
import AccountBookOrderStatOnSiteSearchBar from '@icoach/accountBook/AccountBookOrderStatOnSiteSearchBar';
import AccountBookOrderStatOnSiteTable from '@icoach/accountBook/AccountBookOrderStatOnSiteTable';
import { useHistory } from 'react-router-dom';
import { paramsToObject } from '@util/utils';
import { parseDate } from '@util/moment';
import { ACCOUNT_BOOK_TABS_SETTLE_CASH_PRICE_URL } from '@icoach/router/AccountBookRouter';

const AccountBookOrderStatOnSiteTabs = () => {
    const history = useHistory();
    const { tabIndex, setTabIndex } = useAccountBookContext();

    const handleTabChange = (e, value) => setTabIndex(value);

    const handleSettButtonClick = () => history.push(ACCOUNT_BOOK_TABS_SETTLE_CASH_PRICE_URL(parseDate()));

    return (
        <Box component={'header'} className={'page-header'}>
            <Tabs value={tabIndex} onChange={handleTabChange}>
                <Tab className={'py-3'} label={'收入來源'} value={statisticalType.orderType} />
                <Tab className={'py-3'} label={'收款方式'} value={statisticalType.paymentType} />
            </Tabs>
            <Box className="btn-group">
                <Button variant={'outlined'} onClick={handleSettButtonClick}>
                    每日結算
                </Button>
            </Box>
        </Box>
    );
};

const AccountBookOrderStatOnSitePage = () => {
    const history = useHistory();
    const search = history.location.search;
    const { tabIndex: tabIndexByUrl } = paramsToObject(search);
    const [tabIndex, setTabIndex] = useState(tabIndexByUrl || statisticalType.orderType);
    const [tableData, setTableData] = useState([]);
    const refreshRef = useRef(() => {});

    const childContext = {
        tableData,
        setTableData,
        tabIndex,
        setTabIndex,
        refresh: refreshRef,
        setRefresh: (el) => (refreshRef.current = el),
    };

    return (
        <AccountBookProvider value={childContext}>
            <Card className={'tabs-content-layout'}>
                <AccountBookOrderStatOnSiteTabs />
                <Card.CardContent className={'py-3 px-4'}>
                    <AccountBookOrderStatOnSiteSearchBar className={'mb-4'} />
                    <AccountBookOrderStatOnSiteTable />
                </Card.CardContent>
            </Card>
        </AccountBookProvider>
    );
};

export default AccountBookOrderStatOnSitePage;
