export default {
    101: {
        data: [
            '須學員本人與承接人簽署轉讓申請書後，經店舖確認即完成學員籍轉讓手續。',
            '轉讓的學員籍以「15日」作為轉讓單位，學員籍轉讓生效前，原學員仍享有學員籍。',
            '此流程酌收手續費新臺幣三百元整。',
        ],
    },
    102: {
        data: [
            '須會員本人與承接人簽署轉讓申請書後，經店舖確認即完成會員籍轉讓手續。',
            '轉讓的會員籍以「15日」作為轉讓單位，會員籍轉讓生效前，原會員仍享有會員籍。',
            '本公司向原會員酌收費用 300 元整。',
        ],
    },
    103: {
        data: [
            '須會員本人與承接人簽署轉讓申請書後，經店舖確認即完成會員籍轉讓手續。',
            '轉讓的會員籍以「15日」作為轉讓單位，會員籍轉讓生效前，原會員仍享有會員籍。',
            '本公司向原會員酌收費用 300 元整。',
        ],
    },
};
