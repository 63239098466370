import React from 'react';
import SvgIcon from '../SvgIcon';
import useForkRef from '@util/hook/useForkRef';

const NavigateNext = React.forwardRef((props, ref) => {
    const { className, ...others } = props;
    const handleRef = React.useRef(null);
    const svgRef = useForkRef(handleRef, ref);
    const defaultProperty = {
        className,
        ref: svgRef,
        ...others,
    };
    return (
        <SvgIcon {...defaultProperty}>
            <path
                d="M6.914,6.238l5.313,5.313a.934.934,0,0,1,0,1.324l-.883.883a.934.934,0,0,1-1.324,0L6.25,10,2.484,13.762a.934.934,0,0,1-1.324,0l-.887-.883a.934.934,0,0,1,0-1.324L5.586,6.242A.935.935,0,0,1,6.914,6.238Z"
                transform="translate(6.002 1.036)"
            />
        </SvgIcon>
    );
});

export default NavigateNext;
