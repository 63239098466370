import React, { useRef } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Box, Chip, Typography } from '@common/components/';
import Tablenization from '@icoach/components/Tablenization';
import { useAccountBookContext } from '@icoach/accountBook/components/AccountBookContext';
import ScrollerArrowButton from '@icoach/inbody/components/ScrollerArrowButton';
import useMessageDialog from '@util/hook/useMessageDialog';
import useOrderBatchPaymentApi from '@apis/useOrderBatchPaymentApi';
import { ACCOUNT_BOOK_BULK_DEBIT_BILLING_DETAIL_URL } from '@icoach/router/AccountBookRouter';
import { parseDate } from '@util/moment';
import { billingStatusColorMap } from '@icoach/accountBook/staticData';
import { formatCurrencyFn } from '@util/utils';
import { ActionMenuButton } from '@icoach/accountBook/tables/BatchPaymentOrdersCreditCardTable';

const rowsLabel = [
    {
        cellKey: 'sendDate',
        headerLabel: '送件日期',
        formatCell: (cellValue) => {
            const _parseDate = (date, format) => {
                return parseDate(date, format, false);
            };
            return (
                <React.Fragment>
                    <Typography className="mb-0">{_parseDate(cellValue, 'YYYY/MM/DD')}</Typography>
                    <Typography className="mb-0 font-color-3">{_parseDate(cellValue, 'HH:mm')}</Typography>
                </React.Fragment>
            );
        },
    },
    {
        cellKey: 'billingItem',
        headerLabel: '扣款方式',
        align: 'center',
        formatCell: (cellValue, { billingID }) => {
            return (
                <Link className={'text-decoration-underline'} to={ACCOUNT_BOOK_BULK_DEBIT_BILLING_DETAIL_URL(billingID)}>
                    {cellValue}
                </Link>
            );
        },
    },
    {
        cellKey: 'billingStatusText',
        headerLabel: '狀態',
        align: 'center',
        formatCell: (cellValue, { billingStatusColor = 0 }) => {
            return <Chip label={cellValue} color={billingStatusColorMap.get(billingStatusColor)} />;
        },
    },
    {
        cellKey: 'totalQuantity',
        headerLabel: '總筆數',
        align: 'center',
    },
    {
        cellKey: 'successQuantity',
        headerLabel: '成功筆數',
        align: 'center',
        formatCell: (cellValue) => {
            return <Chip label={cellValue} color="success" />;
        },
    },
    {
        cellKey: 'failureQuantity',
        headerLabel: '失敗筆數',
        align: 'center',
        formatCell: (cellValue) => {
            return <Chip label={cellValue} color="error" />;
        },
    },
    {
        cellKey: 'employeeDisplayName',
        headerLabel: '擔當',
        align: 'center',
    },
    {
        cellKey: 'paymentPrice',
        headerLabel: '扣款金額',
        align: 'right',
        formatCell: (cellValue) => {
            return formatCurrencyFn(cellValue);
        },
    },
    {
        cellKey: 'returnPrice',
        headerLabel: '退款金額',
        align: 'right',
        formatCell: (cellValue) => {
            return formatCurrencyFn(cellValue);
        },
    },
    {
        cellKey: 'more',
        headerLabel: '',
        width: 6.8,
        formatCell: (
            cellValue,
            { billingID, canCancel, canAchAction },
            { goToBatchPaymentOrderBillingDetail, onClickDownload, onClickDelete, dialogRef, refresh },
        ) => {
            const { openImportAchDialog } = dialogRef.current || {};
            const viewActionData = {
                text: '查看',
                onClick: () => {
                    if (typeof goToBatchPaymentOrderBillingDetail === 'function') goToBatchPaymentOrderBillingDetail(billingID);
                },
            };

            const exportActionData = {
                text: '下載扣款名單',
                onClick: () => {
                    if (typeof onClickDownload === 'function') onClickDownload(billingID);
                },
            };

            const importActionData = {
                text: '回填扣款紀錄',
                onClick: () => {
                    if (typeof openImportAchDialog === 'function') openImportAchDialog({ billingID }, refresh);
                },
            };

            const cancelActionData = {
                text: '取消批次扣款',
                onClick: () => {
                    if (typeof onClickDelete === 'function') onClickDelete(billingID);
                },
            };

            const menuData = [viewActionData];

            if (canAchAction) {
                menuData.push(exportActionData);
                menuData.push(importActionData);
            }

            if (canCancel) {
                menuData.push(cancelActionData);
            }

            return <ActionMenuButton menuData={menuData} textAlign={'center'} />;
        },
    },
];

const BatchPaymentOrderBillingPage = (props) => {
    const history = useHistory();
    const { tableData, refresh, dialogRef } = useAccountBookContext();
    const { billings = [] } = tableData || {};
    const { deleteOrderBatchPaymentBillingApi } = useOrderBatchPaymentApi();
    const setMessageDialog = useMessageDialog();
    const labelTitle = useRef(rowsLabel);
    const scrollerArrowRef = useRef(null);

    const doDeleteOrderBatchPaymentBillingApi = async (billingID, onClose) => {
        let res = await deleteOrderBatchPaymentBillingApi(billingID);
        if (res) {
            if (typeof refresh === 'function') refresh();
            onClose();
        }
    };

    // 下載扣款名單
    const handleClickDownload = async (billingID) => {
        window.open(`/api/orders/batch-payments/${billingID}/export-ach/`, '_blank');
    };

    // 刪除扣款群組
    const handleDelete = (billingID) => {
        setMessageDialog({
            open: true,
            title: '確認取消批次扣款',
            msg: '取消後將停止交易作業且無法恢復，請問確定要取消嗎?',
            onAsyncConfirm: (_, onClose) => doDeleteOrderBatchPaymentBillingApi(billingID, onClose),
        });
    };

    const goToBatchPaymentOrderBillingDetail = (billingID) => {
        history.push(ACCOUNT_BOOK_BULK_DEBIT_BILLING_DETAIL_URL(billingID));
    };

    return (
        <React.Fragment>
            <Box className={'scroll-arrow-container'}>
                <Tablenization tableContainerRef={scrollerArrowRef} cardProps={{ className: 'theme-gray' }}>
                    <Tablenization.TableRowHeader headerRow={labelTitle.current} />
                    <Tablenization.TableRowBody
                        headerRow={labelTitle.current}
                        rowsData={billings}
                        onClickDownload={handleClickDownload}
                        onClickDelete={handleDelete}
                        dialogRef={dialogRef}
                        refresh={refresh}
                        goToBatchPaymentOrderBillingDetail={goToBatchPaymentOrderBillingDetail}
                    />
                </Tablenization>
                <ScrollerArrowButton parentRef={scrollerArrowRef} />
            </Box>
        </React.Fragment>
    );
};

export default BatchPaymentOrderBillingPage;
