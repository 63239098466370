import React, { useEffect, useState } from 'react';
import { Alert, Box } from '@common/components/';
import { isEmpty } from 'lodash';
import useMemberCyclePurchaseApi from '@apis/useMemberCyclePurchaseApi';
import { CycleEffectOrderCard } from '@icoach/members/components/PendingCycleEffectOrderList';

const CyclePurchaseDetailOrderFuture = (props) => {
    const { memberID, cycleOrderID } = props.sourceData || {};
    const { getMemberCycleOrderFutureApi } = useMemberCyclePurchaseApi();
    const [data, setData] = useState([]);

    const doMemberCycleOrderFutureApi = async (memberID, cycleOrderID) => {
        let res = await getMemberCycleOrderFutureApi(memberID, cycleOrderID);
        if (res) {
            setData(res);
            return res;
        }
    };

    const refresh = () => {
        if (typeof props.refresh === 'function') {
            props.refresh(memberID, cycleOrderID);
        }
        doMemberCycleOrderFutureApi(memberID, cycleOrderID);
    };

    useEffect(() => {
        if (memberID && cycleOrderID) {
            doMemberCycleOrderFutureApi(memberID, cycleOrderID);
        }
        // eslint-disable-next-line
    }, [memberID, cycleOrderID]);

    return (
        <React.Fragment>
            {Array.isArray(data) && !isEmpty(data) ? (
                <Box component={'ul'} className="minor-orders">
                    {data.map((item, i) => (
                        <Box component={'li'} key={i} className="minor-order-item">
                            <CycleEffectOrderCard data={item} cycleOrderID={cycleOrderID} refresh={refresh} />
                        </Box>
                    ))}
                </Box>
            ) : (
                <Alert className={'mb-4'} color="info" severity="info" variant="filled">
                    尚無資料
                </Alert>
            )}
        </React.Fragment>
    );
};

export default CyclePurchaseDetailOrderFuture;
