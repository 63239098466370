import { useCallback } from 'react';
import useAjax from '@apis/useAjax';
import { useSnackbar } from 'notistack';

const GET_APPOINTMENTS_APPOINTMENTID_CONTACT_RECORDS_API = (appointmentID) => `/api/appointments/${appointmentID}/contact-records/`;
const POST_APPOINTMENTS_APPOINTMENTID_CONTACT_RECORDS_API = (appointmentID) => `/api/appointments/${appointmentID}/contact-records/`;
const PUT_APPOINTMENTS_APPOINTMENTID_CONTACT_RECORDS_RECORDID_API = (appointmentID, contactID) =>
    `/api/appointments/${appointmentID}/contact-records/${contactID}/`;
const DELETE_APPOINTMENTS_APPOINTMENTID_CONTACT_RECORDS_TYPE_CUSTOMERID_RECORDID_API = (appointmentID, type, customerID, recordID) =>
    `/api/appointments/${appointmentID}/contact-records/${type}/${customerID}/${recordID}/`;

const useAppointmentContactRecordApi = () => {
    const Ajax = useAjax();
    const { enqueueSnackbar } = useSnackbar();

    // 取得預約單聯繫資料
    const getAppointmentContactRecordsApi = useCallback(
        async (appointmentID) => {
            const resp = await Ajax.get(GET_APPOINTMENTS_APPOINTMENTID_CONTACT_RECORDS_API(appointmentID));
            if (!resp) {
                enqueueSnackbar('讀取預約單聯繫資料錯誤,請重新整理', { variant: 'error' });
            }
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    // 建立預約單聯繫資料
    const postAppointmentContactRecordsApi = useCallback(
        async (appointmentID, params) => {
            const resp = await Ajax.post(POST_APPOINTMENTS_APPOINTMENTID_CONTACT_RECORDS_API(appointmentID), params);
            if (!resp) {
                enqueueSnackbar('讀取預約單聯繫資料錯誤,請重新整理', { variant: 'error' });
            } else {
                enqueueSnackbar('新增預約單聯繫資料成功', { variant: 'success' });
            }
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    // 編輯預約單聯繫資料
    const putAppointmentContactRecordsApi = useCallback(
        async (appointmentID, contactID, params) => {
            const resp = await Ajax.put(PUT_APPOINTMENTS_APPOINTMENTID_CONTACT_RECORDS_RECORDID_API(appointmentID, contactID), params);
            if (resp) {
                enqueueSnackbar('編輯預約單聯繫資料成功', { variant: 'success' });
            }
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    // 刪除預約單聯繫資料
    const deleteAppointmentContactRecordsApi = useCallback(
        async (appointmentID, type, customerID, recordID) => {
            const resp = await Ajax.delete(
                DELETE_APPOINTMENTS_APPOINTMENTID_CONTACT_RECORDS_TYPE_CUSTOMERID_RECORDID_API(appointmentID, type, customerID, recordID)
            );
            if (resp) {
                enqueueSnackbar('刪除預約單聯繫資料成功', { variant: 'success' });
            }
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    return {
        getAppointmentContactRecordsApi,
        postAppointmentContactRecordsApi,
        putAppointmentContactRecordsApi,
        deleteAppointmentContactRecordsApi,
    };
};

export default useAppointmentContactRecordApi;
