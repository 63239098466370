import { useCallback } from 'react';
import useAjax from '@apis/useAjax';
import { useSnackbar } from 'notistack';
import useShowPDF from '@util/hook/useShowPDF';

const GET_DOC_MEMBERSHIP_SUSPEND_OPTIONS_API = `/api/doc/membership/suspensions/options/`;
const GET_DOC_MEMBERSHIP_SUSPEND_HISTORY_API = `/api/doc/membership/suspensions/history/`;
const GET_DOC_MEMBERSHIP_SUSPEND_CONTRACTID_API = (contractID) => `/api/doc/membership/suspensions/${contractID}/`;
const POST_DOC_MEMBERSHIP_SUSPEND_CONTRACTID_API = GET_DOC_MEMBERSHIP_SUSPEND_CONTRACTID_API;
const GET_DOC_MEMBERSHIP_SUSPEND_TEMPORARY_API = `/api/doc/membership/suspensions/temporary/`;
const POST_DOC_MEMBERSHIP_SUSPEND_TEMPORARY_API = GET_DOC_MEMBERSHIP_SUSPEND_TEMPORARY_API;
const DELETE_DOC_MEMBERSHIP_SUSPEND_TEMPORARY_API = GET_DOC_MEMBERSHIP_SUSPEND_TEMPORARY_API;
const POST_DOC_MEMBERSHIP_SUSPEND_RANGE_API = `/api/doc/membership/suspensions/range/`;
const POST_DOC_MEMBERSHIP_SUSPEND_CONTRACTID_PLUGIN_API = (contractID) => `/api/doc/membership/suspensions/${contractID}/plugin/`;
const DELETE_DOC_MEMBERSHIP_SUSPENSIONS_API = `/api/doc/membership/suspensions/`;

const useDocMembershipSuspendApi = () => {
    const Ajax = useAjax();
    const showPDF = useShowPDF();
    const { enqueueSnackbar } = useSnackbar();

    // search 預設option
    const getInitSuspendOptionApi = useCallback(
        async () => {
            const respOptions = await Ajax.get(GET_DOC_MEMBERSHIP_SUSPEND_OPTIONS_API);
            if (!respOptions) {
                enqueueSnackbar('預載資料有誤，請重新整理', { variant: 'error' });
            }
            return respOptions;
        },
        // eslint-disable-next-line
        [],
    );

    // 暫停申請書歷史紀錄
    const postDocSuspendContractHistoryApi = useCallback(
        async (params) => {
            const resp = await Ajax.get(GET_DOC_MEMBERSHIP_SUSPEND_HISTORY_API, params);
            if (!resp) {
                enqueueSnackbar('讀取資料失敗,請重新整理', { variant: 'error' });
            }
            return resp;
        },
        // eslint-disable-next-line
        [],
    );

    // 讀取暫停申請書
    const getDocSuspendContractApi = useCallback(
        async (contractID, params) => {
            const resp = await Ajax.get(GET_DOC_MEMBERSHIP_SUSPEND_CONTRACTID_API(contractID), params);
            if (!resp) {
                enqueueSnackbar('讀取資料失敗,請重新整理', { variant: 'error' });
            }
            return resp;
        },
        // eslint-disable-next-line
        [],
    );

    // 新增暫停申請書
    const postDocSuspendContractApi = useCallback(
        async (contractID, params) => {
            let resp = await Ajax.post(POST_DOC_MEMBERSHIP_SUSPEND_CONTRACTID_API(contractID), params);
            if (resp) {
                if (!resp.isError) enqueueSnackbar('新增成功', { variant: 'success' });
            } else {
                enqueueSnackbar('新增失敗,請再操作一次', { variant: 'error' });
            }
            return resp;
        },
        // eslint-disable-next-line
        [],
    );

    // 確認是否有暫停申請書暫存資料
    const getDocTemporarySuspendContractApi = useCallback(
        async (params) => {
            let resp = await Ajax.get(GET_DOC_MEMBERSHIP_SUSPEND_TEMPORARY_API, params);
            if (resp !== 0 && !resp) {
                enqueueSnackbar('讀取資料失敗,請重新整理', { variant: 'error' });
            }
            return resp;
        },
        // eslint-disable-next-line
        [],
    );

    // 暫存暫停申請書
    const postDocTemporarySuspendContractApi = useCallback(
        async (params) => {
            let resp = await Ajax.post(POST_DOC_MEMBERSHIP_SUSPEND_TEMPORARY_API, params);
            if (resp) {
                enqueueSnackbar('暫存暫停申請書成功', { variant: 'success' });
                return resp;
            } else {
                enqueueSnackbar('新增暫存失敗，請重新操作', { variant: 'error' });
            }
            return resp;
        },
        // eslint-disable-next-line
        [],
    );

    // 刪除暫存暫停申請書
    const deleteDocTemporarySuspendContractApi = useCallback(
        async (params) => {
            let resp = await Ajax.delete(DELETE_DOC_MEMBERSHIP_SUSPEND_TEMPORARY_API, params);
            if (resp) {
                enqueueSnackbar('刪除暫存暫停申請書成功', { variant: 'success' });
            } else {
                enqueueSnackbar('刪除失敗，請重新操作', { variant: 'error' });
            }
            return resp;
        },
        // eslint-disable-next-line
        [],
    );

    // 依入會方案取得合約日期、會籍日期、初次費用
    const postDocMembershipSuspendRange = useCallback(
        async (params) => {
            const resp = await Ajax.post(POST_DOC_MEMBERSHIP_SUSPEND_RANGE_API, params);
            if (!resp) {
                enqueueSnackbar('讀取資料失敗,請重新整理', { variant: 'error' });
            }
            return resp;
        },
        // eslint-disable-next-line
        [],
    );

    // 上傳檔案附件 圖檔會進行縮圖，最大長寬為 1200，照比例縮放 一般檔案直接上傳
    const postUploadDocMembershipSuspendPlugin = useCallback(
        async (contractID, formData) => {
            const headerOption = {
                headerOption: {
                    contentType: false,
                    processData: false,
                },
            };
            let resp = await Ajax.post(POST_DOC_MEMBERSHIP_SUSPEND_CONTRACTID_PLUGIN_API(contractID), formData, headerOption);
            if (!resp) {
                enqueueSnackbar('上傳檔案失敗', { variant: 'error' });
            }
            return resp;
        },
        // eslint-disable-next-line
        [],
    );

    // 註銷請假
    const deleteDocumentSuspensionsApi = useCallback(
        async (params) => {
            let resp = await Ajax.delete(DELETE_DOC_MEMBERSHIP_SUSPENSIONS_API, params);
            if (resp) {
                enqueueSnackbar('銷假成功', { variant: 'success' });
            } else {
                enqueueSnackbar('銷假失敗,請再操作一次', { variant: 'error' });
            }
            return resp;
        },
        // eslint-disable-next-line
        [],
    );

    // 暫停申請書下載PDF
    const downloadDocMembershipSuspendPDFApi = useCallback(
        async (params) => {
            const { contractUrl } = params;
            showPDF({
                open: true,
                pdfUrl: contractUrl,
            });
        },
        // eslint-disable-next-line
        [],
    );

    return {
        getInitSuspendOptionApi,
        postDocSuspendContractHistoryApi,
        getDocSuspendContractApi,
        postDocSuspendContractApi,
        getDocTemporarySuspendContractApi,
        postDocTemporarySuspendContractApi,
        deleteDocTemporarySuspendContractApi,
        postDocMembershipSuspendRange,
        postUploadDocMembershipSuspendPlugin,
        deleteDocumentSuspensionsApi,
        downloadDocMembershipSuspendPDFApi,
    };
};

export default useDocMembershipSuspendApi;
