import React, { useState } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import { Tab, Tabs } from '@common/components/';
import { HumanResource as HumanResourceIcon } from '@common/SvgIcon/';
import { HUMAN_RESOURCES_EMPLOYEES_OVERVIEW } from '@icoach/router/EmployeesRouter';
import EmployeesOverviewContent from '@icoach/employees/overview/EmployeesOverviewContent';

const EmployeesPage = React.forwardRef((props, ref) => {
    const { tabs } = useParams();
    const [tabsIndex, setTabsIndex] = useState(tabs);

    const handleTabChange = (value) => {
        setTabsIndex(value);
    };

    return (
        <div className="container main-container-spacing">
            <div className="page-tabs tabs-bottom-line mb-4">
                <Tabs value={tabsIndex} handleChange={handleTabChange}>
                    <Tab
                        className={'py-3'}
                        label={'教練總覽'}
                        value={'employees-overview'}
                        icon={<HumanResourceIcon />}
                        component={NavLink}
                        to={HUMAN_RESOURCES_EMPLOYEES_OVERVIEW}
                    />
                </Tabs>
            </div>
            {tabsIndex === 'employees-overview' && <EmployeesOverviewContent />}
        </div>
    );
});

export default EmployeesPage;
