import React, { useEffect, useRef, useState } from 'react';
import { BillingRecordTable } from './table';
import { initBookmark } from '../staticData';
import useMembershipApi from '@apis/useMembershipApi';
import { ListInfo } from '@icoach/accountBook/components/';
import { Stack } from '@common/components/';

// todo: 等api
const MemberDetailMembershipContentBillingRecord = (props) => {
    const { memberID } = props;
    const { postMemberMembershipOrdersApi } = useMembershipApi();
    const [bookmark, setBookmark] = useState(initBookmark());
    const [data, setData] = useState([]);
    const { list = [], totalCount, totalPage, pageIndex = bookmark.pageIndex, pageSize = bookmark.pageSize } = data;
    const preload = useRef(true);

    const doMemberMembershipOrders = async (memberID, params) => {
        let res;
        preload.current = false;
        res = await postMemberMembershipOrdersApi(memberID, params);
        if (res) {
            setData(res);
        }
        preload.current = true;
    };

    const handlePageChange = (e, num) => {
        setBookmark((prev) => ({
            ...prev,
            pageIndex: num,
        }));
    };

    useEffect(() => {
        if (preload.current) {
            doMemberMembershipOrders(memberID, bookmark);
        }
        // eslint-disable-next-line
    }, [bookmark]);

    useEffect(() => {
        if (memberID) {
            if (preload.current) doMemberMembershipOrders(memberID, bookmark);
        }
        // eslint-disable-next-line
    }, [memberID]);

    return (
        <React.Fragment>
            <Stack className={'mb-2'} alignItems={'center'} justifyContent={'end'}>
                <ListInfo
                    className={'ml-auto'}
                    pageIndex={pageIndex}
                    padeSize={pageSize}
                    totalCount={totalCount}
                    isCurrentPageFirstIndex
                    isCurrentPageLastIndex
                />
            </Stack>
            <BillingRecordTable className={'theme-gray'} rowsData={list} page={pageIndex} count={totalPage} handlePageChange={handlePageChange} />
        </React.Fragment>
    );
};

export default MemberDetailMembershipContentBillingRecord;
