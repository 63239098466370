import React, { useState, useRef, useEffect } from 'react';
import { AccountBookProvider } from './components/AccountBookContext';
import { Card } from '../../components';
import { initBookmark } from './staticData';
import AccountBookRemitOverviewTable from '@icoach/accountBook/AccountBookRemitOverviewTable';
import AccountBookRemitOverviewSearchBar from '@icoach/accountBook/AccountBookRemitOverviewSearchBar';
import { paramsToObject } from '@util/utils';
import { useHistory } from 'react-router-dom';

const AccountBookRemitOverviewPage = () => {
    const history = useHistory();
    const search = history.location.search;
    const { pageIndex } = paramsToObject(search);
    const [tableData, setTableData] = useState({});
    const [bookmark, setBookmark] = useState(initBookmark());
    const refreshRef = useRef(() => {});
    const preloadRef = useRef(true);

    const setBookmarkNum = (num) => setBookmark((pre) => ({ ...pre, pageIndex: num }));

    const childContext = {
        tableData,
        setTableData,
        bookmark,
        setBookmarkNum,
        refresh: refreshRef,
        setRefresh: (el) => (refreshRef.current = el),
    };

    useEffect(() => {
        if (!pageIndex) return null;
        if (preloadRef.current) {
            setBookmarkNum(pageIndex);
            preloadRef.current = false;
        }
    }, [pageIndex]);

    return (
        <AccountBookProvider value={childContext}>
            <AccountBookRemitOverviewSearchBar className={'mb-4'} />
            <Card>
                <Card.CardContent className={'px-4 pb-4 pt-1'}>
                    <AccountBookRemitOverviewTable />
                </Card.CardContent>
            </Card>
        </AccountBookProvider>
    );
};

export default AccountBookRemitOverviewPage;
