import React, { useEffect, useState } from 'react';
import { Dialog, DialogContent, Grid, Slide } from '@mui/material';
import GradientColorHeader from '@icoach/components/dialog/GradientColorHeader';
import { Stack, Box, Typography } from '@common/components/';
import { formatCurrencyFn, isEmpty } from '@util/utils';
import usePointsApi from '@apis/usePointsApi';

const Transition = React.forwardRef((props, ref) => {
    return <Slide direction="up" ref={ref} {...props} />;
});

const PointsProductGuideDialog = React.forwardRef((props, ref) => {
    const { open: openProps = false, onClose: onCloseProps, sourceData: productItemsData = {} } = props;
    if (isEmpty(productItemsData) || !openProps) return null;
    const { getPointsRedeemItemsApi } = usePointsApi();
    const [sourceData, setSourceData] = useState({});

    const initGetProductItemsDataApi = async (itemID) => {
        const resp = await getPointsRedeemItemsApi(itemID);
        if (resp) {
            setSourceData(resp);
        }
    };

    useEffect(
        () => {
            if (openProps) {
                const { itemID } = productItemsData;
                initGetProductItemsDataApi(itemID);
            }
        },
        // eslint-disable-next-line
        [productItemsData]
    );

    const { imageSrc = '', imageDesc = [], itemName = '', points = 0, costPrice = 0, sku = '' } = sourceData;
    return (
        <Dialog open={openProps} TransitionComponent={Transition} fullScreen>
            <GradientColorHeader onClose={onCloseProps}>點數贈品說明</GradientColorHeader>
            <DialogContent className="bg-blue-gray-50">
                <Grid container spacing={2} className="flex-y-flex-center">
                    <Grid xs={8} item>
                        <Stack>
                            <Box className="product-guide-header-box">
                                <img src={imageSrc} alt={itemName} />
                            </Box>
                            <Box className="pl-3">
                                <Stack className="h-100" direction="column" justifyContent="space-between">
                                    <Box>
                                        <Typography variant="h2">{itemName}</Typography>
                                        <Typography className={'sku'}>SKU：{sku}</Typography>
                                    </Box>
                                    <Box>
                                        <Typography variant="h3" component="span">
                                            兌換點數：
                                        </Typography>
                                        <Typography variant="h1" component="span" color="main" className="font-weight-bold px-1">
                                            {points}
                                        </Typography>
                                        <Typography variant="h3" component="span">
                                            &nbsp;點
                                        </Typography>
                                        <Typography>市價 {formatCurrencyFn(costPrice)}</Typography>
                                    </Box>
                                </Stack>
                            </Box>
                        </Stack>
                    </Grid>
                    {imageDesc.length !== 0 &&
                        imageDesc.map((item, idx) => (
                            <Grid xs={8} key={`desc_${idx}`} item>
                                <Box className="product-guide-content-box">
                                    <img src={item} alt={`desc_${idx}`} />
                                </Box>
                            </Grid>
                        ))}
                </Grid>
            </DialogContent>
        </Dialog>
    );
});

export default PointsProductGuideDialog;
