import React from 'react';
import clsx from 'clsx';
import Tablenization from '../../components/Tablenization';
import { TableLabel } from '../components';
import { transposedWeeklyData } from '../staticData';
import { isEmpty } from 'lodash';
import {floatToPercentage, toFixed} from '../../../util/utils';

const getRowsData = (data, cumulative, thData) => {
    let rowsData = [];
    let rowsLabel = [
        {
            cellKey: 'title',
            headerLabel: '項目',
            width: 26,
            formatCell: (title, { tip, isStar }) => {
                return <TableLabel title={title} tip={tip} isStar={isStar} />;
            },
        },
        {
            cellKey: 'goal',
            headerLabel: '目標',
            align: 'center',
            width: 11,
        },
        {
            cellKey: 'cumulative',
            headerLabel: '最新數字',
            align: 'center',
            className: 'total-col',
            width: 9,
        },
    ];

    data = transposedWeeklyData(data);

    if (!isEmpty(data)) {
        rowsData = thData.map(({ key, formatCell, ...other }) => {
            let weeklyData = data[key];
            let rowData = {
                cellKey: '',
                cumulative: cumulative[key],
                ...other,
                ...weeklyData,
            };

            if (formatCell) {
                rowData.cellKey = 'cumulative';
                rowData.cumulative = formatCell(cumulative[key], rowData);
                for (let week in weeklyData) {
                    rowData.cellKey = week;
                    rowData[week] = formatCell(weeklyData[week], rowData);
                }
            }

            return rowData;
        });

        rowsLabel = [...rowsLabel, ...data.week];
    }

    return { rowsLabel, rowsData };
};

// 表格標題與目標資訊
// title: 標題 string
// tip: 說明 string
// isStar: 閃亮之星 boolean 選填
// goal: 目標 string
const thData = [
    {
        key: 'goodsFA',
        title: 'FA即購買運動商品之會員數',
        tip: '每日銷售與收入總表中FA即購買運動商品之會員數',
        goal: '',
        formatCell: (val) => {
            return toFixed(val);
        },
    },
    {
        key: 'goodsFARate',
        title: 'FA即購買運動商品之成交率',
        tip: 'FA即購買運動商品之會員數/入會數',
        goal: '',
        formatCell: (val) => {
            return `${floatToPercentage(val)}%`;
        },
    },
    {
        key: 'goodsFWO',
        title: '商品銷售在FWO',
        tip: '每日銷售與收入總表中商品銷售在FWO時的數量',
        goal: '',
        formatCell: (val) => {
            return toFixed(val);
        },
    },
    {
        key: 'goodsTwoWeeks',
        title: '商品銷售在2W',
        tip: '每日銷售與收入總表中商品銷售在2W時的數量',
        goal: '',
        formatCell: (val) => {
            return toFixed(val);
        },
    },
    {
        key: 'goodsOneMonth',
        title: '商品銷售在1WM',
        tip: '每日銷售與收入總表中商品銷售在1WM時的數量',
        goal: '',
        formatCell: (val) => {
            return toFixed(val);
        },
    },
    {
        key: 'goodsTwoMonths',
        title: '商品銷售在2-3WM',
        tip: '每日銷售與收入總表中商品銷售在2-3WM時的數量',
        goal: '',
        formatCell: (val) => {
            return toFixed(val);
        },
    },
    {
        key: 'goodsThreeMonths',
        title: '商品銷售在>3WM',
        tip: '每日銷售與收入總表中商品銷售在>3WM時的數量',
        goal: '',
        formatCell: (val) => {
            return toFixed(val);
        },
    },
    {
        key: 'goodsAtSale',
        title: '商品銷售在特賣',
        tip: '每日銷售與收入總表中商品銷售在特賣會時的數量',
        goal: '',
        formatCell: (val) => {
            return toFixed(val);
        },
    },
];

const GoodsMonthlyTable = (props) => {
    const { className, rowsData: data = [], cumulative } = props;
    const { rowsLabel, rowsData } = getRowsData(data, cumulative, thData);

    return (
        <Tablenization cardProps={{ className: clsx('monthly-table', className) }}>
            <Tablenization.TableRowHeader headerRow={rowsLabel} />
            <Tablenization.TableRowBody headerRow={rowsLabel} rowsData={rowsData} />
        </Tablenization>
    );
};

export default GoodsMonthlyTable;
