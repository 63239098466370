import React from 'react';
import SvgIcon from '../SvgIcon';
import useForkRef from '@util/hook/useForkRef';

const Leave = React.forwardRef((props, ref) => {
    const { className, htmlColor = '#D02952', ...others } = props;
    const handleRef = React.useRef(null);
    const svgRef = useForkRef(handleRef, ref);
    const defaultProperty = {
        className,
        ref: svgRef,
        htmlColor,
        ...others,
    };
    return (
        <SvgIcon {...defaultProperty}>
            <path
                d="M20,6.5c-1.3-2-3.3-3.4-5.6-3.9c-3.6-1-7.4,0.2-9.8,3.1c-1.2,1.4-2,3.1-2.3,5c0,0.2-0.1,0.5-0.1,0.7v1.3
		c0.1,0.3,0.1,0.7,0.2,1c0.3,1.8,1.1,3.5,2.4,4.9c1.4,1.5,3.2,2.6,5.2,3c0.4,0.1,0.8,0.1,1.2,0.2c0.1,0,0.1,0,0.2,0h1.3
		c0.4,0,0.7-0.1,1-0.2c1.8-0.3,3.6-1.2,4.9-2.5c1.5-1.4,2.5-3.1,2.9-5.1C22.1,11.4,21.6,8.7,20,6.5z M18,13.1c0,0.4-0.2,0.6-0.6,0.6
		H8.2c-0.6,0-1.1,0-1.7,0c0,0-0.1,0-0.1,0c-0.3,0-0.5-0.3-0.4-0.6c0-0.7,0-1.4,0-2.1c0,0,0-0.1,0-0.1c0-0.3,0.3-0.5,0.6-0.4H12
		c1.8,0,3.6,0,5.3,0c0.1,0,0.1,0,0.2,0c0.3,0,0.5,0.3,0.5,0.6C18,11.7,18,12.4,18,13.1z"
            />
            <path
                d="M12,0C5.4,0,0,5.4,0,12s5.4,12,12,12s12-5.4,12-12S18.6,0,12,0z M12,22.9C6,22.9,1.1,18,1.1,12
		C1.1,6,6,1.1,12,1.1C18,1.1,22.9,6,22.9,12S18,22.9,12,22.9z"
            />
        </SvgIcon>
    );
});

export default Leave;
