import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import routers from '@icoach/router/routers';
import { getMenusData } from '@redux/menus/selector';
import { List, ListItemButton, ListItemText, Collapse, Skeleton, Stack, ListItemIcon } from '@common/components/';
import {
    UserHome as UserHomeIcon,
    UserAlt as UserAltIcon,
    UserPlus as UserPlusIcon,
    DocumentFile as DocumentFileIcon,
    PaperPlane as PaperPlaneIcon,
    DollarSign as DollarSignIcon,
    Developer as DeveloperIcon,
    ChartColumnSolid as ChartColumnSolidIcon,
    Ruler as RulerIcon,
    HumanResource as HumanResourceIcon,
    Points as PointsIcon,
} from '@common/SvgIcon/';
import { isEmpty } from '@util/utils';

/**
 * 對應左側Menu Icon
 */
const menuIconMap = {
    home: <UserHomeIcon />,
    member: <UserAltIcon />,
    analysis: <ChartColumnSolidIcon />,
    customer: <UserPlusIcon />,
    document: <DocumentFileIcon />,
    message: <PaperPlaneIcon />,
    accountbook: <DollarSignIcon />,
    measure: <RulerIcon />,
    developer: <DeveloperIcon />,
    hr: <HumanResourceIcon />,
    points: <PointsIcon />,
};

const checkRouter = (source, target, key) => {
    return source[target].find(({ frontKey }) => frontKey === key);
};
/**
 * 子層選單
 */
const SubNavComponent = (props) => {
    const { onCloseMenu } = props;
    const { sourceData = [], isOpen = false } = props;

    return (
        <React.Fragment>
            {sourceData.length !== 0 && (
                <Collapse in={isOpen}>
                    <List component="div" className="list-sub" disablePadding>
                        {sourceData.map(({ frontKey, menuName, isVisible }) => {
                            let isComponent = null;
                            const isExist = routers['club'].find(({ frontKey: _key }) => frontKey === _key);
                            if (isVisible && isExist) {
                                const item = checkRouter(routers, 'club', frontKey);
                                let newProperty = {};
                                // 給href就會顯示出來
                                if (item && item.href) {
                                    newProperty = {
                                        component: NavLink,
                                        to: item.href,
                                        activeClassName: 'list-item-button-selected',
                                        onClick: onCloseMenu,
                                    };
                                } else {
                                    return null;
                                }
                                const defaultProperty = {
                                    key: frontKey,
                                    ...newProperty,
                                };
                                isComponent = (
                                    <ListItemButton {...defaultProperty}>
                                        <ListItemText primary={menuName} />
                                    </ListItemButton>
                                );
                            }
                            return isComponent;
                        })}
                    </List>
                </Collapse>
            )}
        </React.Fragment>
    );
};

/**
 * 主選單
 */
const NavContent = (props) => {
    const { onCloseMenu } = props;
    const menuData = useSelector(getMenusData);
    const [menuState, setMenuState] = useState([]);
    const [openAry, setOpenAry] = useState({});

    const handleItemCollapseClick = (key) => (e) => {
        setOpenAry((prev) => {
            let openMenuKay = {};
            if (openAry[key] === undefined) openMenuKay[key] = true;
            else openMenuKay[key] = !openAry[key];
            return {
                ...openMenuKay,
            };
        });
    };

    useEffect(
        () => {
            if (!isEmpty(menuData) && menuData.oriMenuData.length > 0) {
                setMenuState(menuData.oriMenuData);
            }
        },
        // eslint-disable-next-line
        [menuData],
    );

    // 開發專用區
    const devMenu = React.useMemo(
        () => {
            const { hostname } = window.location;
            if (hostname === 'localhost' || /192.168.0/.test(hostname)) {
                const { developerRouter } = require('@icoach/router/DevelopmentRouter');
                const { menuName, children, frontKey } = developerRouter || {};
                const nestKey = frontKey;

                return (
                    <React.Fragment>
                        <ListItemButton onClick={handleItemCollapseClick(nestKey)}>
                            <ListItemIcon>
                                <DeveloperIcon />
                            </ListItemIcon>
                            <ListItemText primary={menuName} />
                        </ListItemButton>
                        <Collapse in={openAry[nestKey]}>
                            <List component="div" className="list-sub" disablePadding>
                                {children.map((target) => {
                                    const key = `menu-list-sub-${target.frontKey}`;
                                    return (
                                        <ListItemButton key={key} component={NavLink} to={target.href} activeClassName={`list-item-button-selected`}>
                                            <ListItemText primary={target.menuName} />
                                        </ListItemButton>
                                    );
                                })}
                            </List>
                        </Collapse>
                    </React.Fragment>
                );
            } else {
                return null;
            }
        },
        // eslint-disable-next-line
        [openAry],
    );

    return (
        <div className="nav-contnet">
            {menuState.length !== 0 ? (
                <List component="nav">
                    {menuState.map(({ menuName, frontKey, children, iconClass, isVisible }) => {
                        const nestKey = frontKey;
                        const item = checkRouter(routers, 'club', frontKey);
                        let newProperty = {};
                        if (item) {
                            if (item.href) {
                                newProperty = Object.assign(newProperty, {
                                    component: NavLink,
                                    to: item.href,
                                    activeClassName: 'list-item-button-selected',
                                    onClick: onCloseMenu,
                                });
                            } else {
                                newProperty = {
                                    onClick: handleItemCollapseClick(nestKey),
                                };
                            }
                        } else {
                            return null;
                        }
                        return (
                            <React.Fragment key={nestKey}>
                                {isVisible ? (
                                    <React.Fragment>
                                        <ListItemButton {...newProperty}>
                                            <ListItemIcon>{menuIconMap[iconClass]}</ListItemIcon>
                                            <ListItemText primary={menuName} />
                                        </ListItemButton>
                                        {Array.isArray(children) && (
                                            <SubNavComponent sourceData={children} isOpen={openAry[nestKey]} onCloseMenu={onCloseMenu} />
                                        )}
                                    </React.Fragment>
                                ) : null}
                            </React.Fragment>
                        );
                    })}
                    {devMenu}
                </List>
            ) : (
                <Stack className="m-2" direction="column">
                    <Skeleton height={60} />
                    <Skeleton height={60} />
                    <Skeleton height={60} />
                    <Skeleton height={60} />
                    <Skeleton height={60} />
                    <Skeleton height={60} />
                    <Skeleton height={60} />
                    <Skeleton height={60} />
                    <Skeleton height={60} />
                </Stack>
            )}
        </div>
    );
};

export default NavContent;
