import React, { useEffect, useState } from 'react';
import { Box, Button, Card, FormControlLabel, Tabs, Tab, Switch } from '@common/components/';
import { MemberInfoBox } from '@icoach/components/BusinessCardBox';
import { MembershipProgressBar, SimpleContentItem } from '@icoach/members/components';
import { Grid } from '@mui/material';
import useInBodyApi from '@apis/useInBodyApi';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import {
    INBODY_INTERVIEW_COMPARE_PATH,
    INBODY_INTERVIEW_MEASURE_PATH,
    INBODY_INTERVIEW_NOTES_PATH,
    INBODY_INTERVIEW_SUMMARY_PATH,
    INBODY_RECORD_ROUTER,
} from '@icoach/router/routerPath';
import clsx from 'clsx';
import InBodyInterviewNotMeasureDialog from '@icoach/inbody/interview/InBodyInterviewNotMeasureDialog';
import InBodyInterviewAddMeasureDialog from '@icoach/inbody/interview/InBodyInterviewAddMeasureDialog';
import ProfileImageUploader, { ProfileImageDisplay } from '@icoach/components/ProfileImageUploader';
import useMemberApi from '@apis/useMemberApi';

const InbodyInterviewMemberProfileImageDisplay = (props) => {
    const { imageUrl: imageUrlProps, memberID: memberIDProps } = props;
    const { postMemberTranscriptAvatarApi } = useMemberApi();
    const [imageUrl, setImageUrl] = useState(null);

    useEffect(
        () => {
            if (imageUrlProps) {
                setImageUrl(imageUrlProps);
            }
        },
        // eslint-disable-next-line
        [imageUrlProps]
    );

    const handleImageUpload = ({ blob, previewImage }) => {
        const formData = new FormData();
        formData.append('file', blob);
        postTranscriptAvatar(previewImage, memberIDProps, formData);
    };

    const postTranscriptAvatar = async (previewImage, memeberID, params) => {
        const resp = await postMemberTranscriptAvatarApi(memeberID, params);
        if (resp) {
            setImageUrl(previewImage);
        }
    };

    return (
        <Box className={'inbody-interview-member-profile-image'}>
            <Box className={'inbody-interview-member-profile-image-wrap'}>
                <ProfileImageDisplay imageUrl={imageUrl} />
                <ProfileImageUploader buttonText={'設定形象照'} onImageUpload={handleImageUpload} />
            </Box>
        </Box>
    );
};

// 切換是否要量身
const SwitchIsMeasureBox = (props) => {
    const { status = false, peopleID, setSourceData } = props;
    const { putInterviewNotMeasureStatusApi } = useInBodyApi();
    const handleIsMeasureONChange = (e, checked) => {
        doIsMeasureApi({ memberID: peopleID, isInterviewNotMeasure: checked });
    };

    const doIsMeasureApi = async (params) => {
        const resp = await putInterviewNotMeasureStatusApi(params);
        if (resp) {
            const { isInterviewNotMeasure } = params;
            setSourceData((pre) => ({
                ...pre,
                isInterviewNotMeasure,
            }));
        }
    };

    return (
        <Box className={'is-measure'}>
            <FormControlLabel
                onChange={handleIsMeasureONChange}
                className="mr-3 mb-1"
                labelPlacement="start"
                control={<Switch defaultChecked={status} />}
                label="只面談不量身"
            />
        </Box>
    );
};

// tabs 元件
const ContentTabs = (props) => {
    const { peopleID } = props;
    const location = useLocation();
    const [index, setIndex] = React.useState(null);

    const handleChangeTabEvent = (e, value) => {
        setIndex(value);
    };

    useEffect(() => {
        if (!index) {
            const strSplit = location.pathname.split('/');
            const pathIndex = strSplit[strSplit.length - 2];
            !/^\d+$/g.test(pathIndex) && setIndex(pathIndex);
        }
        // eslint-disable-next-line
    }, [location]);

    return (
        <React.Fragment>
            <Tabs value={index} onChange={handleChangeTabEvent}>
                <Tab value={'summary'} label={'會員摘要'} component={NavLink} to={INBODY_INTERVIEW_SUMMARY_PATH(peopleID)} />
                <Tab value={'compare'} label={'比較表'} component={NavLink} to={INBODY_INTERVIEW_COMPARE_PATH(peopleID)} />
                <Tab value={'measure'} label={'量身紀錄'} component={NavLink} to={INBODY_INTERVIEW_MEASURE_PATH(peopleID)} />
                <Tab value={'notes'} label={'面談內容'} component={NavLink} to={INBODY_INTERVIEW_NOTES_PATH(peopleID)} />
            </Tabs>
        </React.Fragment>
    );
};

const InBodyInterviewMemberInfoCard = (props) => {
    const history = useHistory();
    const { sourceData, peopleID, setSourceData, className } = props;
    const { profileData, isInterviewNotMeasure } = sourceData || {};
    const { memberName, nickName, memberNo, membershipStatus, proPicturePath, picturePath, membershipText, membershipBar, memberID } = profileData || {};

    const handleReturnHistoryOnClick = () => {
        history.push(INBODY_RECORD_ROUTER);
    };

    return (
        <Card className={clsx('inbody-interview-member-info-card', className)}>
            <Card.CardContent className={'px-3 pt-3'}>
                <InbodyInterviewMemberProfileImageDisplay imageUrl={proPicturePath ?? picturePath} memberID={memberID} />
                <Box className={'inbody-interview-member-content'}>
                    <Grid spacing={3} container>
                        <Grid item xs={8}>
                            <MemberInfoBox
                                className={'mb-2'}
                                resource={{
                                    memberName,
                                    nickName,
                                    memberNo,
                                    membershipStatus,
                                }}
                            />
                            <MembershipProgressBar className={'mb-4'} progressBarData={membershipBar} />
                            <SimpleContentItem label="會籍類別">{membershipText}</SimpleContentItem>
                        </Grid>
                        <Grid item xs={4}>
                            <Box className={'tool-btn-group text-right'}>
                                <Button variant={'contained'} onClick={handleReturnHistoryOnClick}>
                                    返回列表
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                    <Box className={'controller-box'}>
                        <SwitchIsMeasureBox setSourceData={setSourceData} status={isInterviewNotMeasure} peopleID={peopleID} />
                        {isInterviewNotMeasure ? <InBodyInterviewNotMeasureDialog /> : <InBodyInterviewAddMeasureDialog />}
                    </Box>
                </Box>
            </Card.CardContent>
            <ContentTabs peopleID={peopleID} />
        </Card>
    );
};

export default InBodyInterviewMemberInfoCard;
