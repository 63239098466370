import React, { useRef } from 'react';
import clsx from 'clsx';
import { Box, Stack, Typography } from '@common/components/';
import { ListInfo } from '@icoach/accountBook/components';
import ScrollerArrowButton from '@icoach/inbody/components/ScrollerArrowButton';
import Tablenization from '@icoach/components/Tablenization';
import { parseDate } from '@util/moment';
import { Check as CheckIcon } from '@common/SvgIcon/';

const headerRow = [
    {
        cellKey: 'orderDate',
        headerLabel: '建立日期',
        align: 'left',
        width: 18,
        maxWidth: 18,
        formatCell: (value, { orderNo }) => {
            return (
                <Stack direction="column">
                    <Typography component="span">{parseDate(value, 'YYYY/MM/DD HH:MM')}</Typography>
                    <Typography component="span">{orderNo}</Typography>
                </Stack>
            );
        },
    },
    {
        cellKey: 'peopleInfo',
        headerLabel: '兌換人',
        align: 'center',
        width: 18,
        maxWidth: 18,
        formatCell: (value, { displayName, memberNo }) => {
            return (
                <Stack direction="column">
                    <Typography component="span">{displayName}</Typography>
                    <Typography component="span">{memberNo}</Typography>
                </Stack>
            );
        },
    },
    {
        cellKey: 'redeemItemName',
        headerLabel: '贈品名',
        align: 'center',
        formatCell: (value) => {
            return <Typography component="span">{value}</Typography>;
        },
    },
    {
        cellKey: 'quantity',
        headerLabel: '兌換數量',
        width: 10,
        maxWidth: 10,
        align: 'center',
        formatCell: (value) => {
            return <Typography component="span">{value}</Typography>;
        },
    },
    {
        cellKey: 'redeemStatus',
        headerLabel: '已簽收',
        width: 10,
        maxWidth: 10,
        align: 'center',
        formatCell: (value) => {
            return String(value) === '1' ? <CheckIcon color="success" /> : null;
        },
    },
];

const PointsProductGiftRecordTable = React.forwardRef((props, ref) => {
    const { pageSize = 10, pageIndex = 1, totalCount = 0, totalPage = 0, rowsData = [], onPageChange: onPageChangeProps } = props;
    const scrollerArrowRef = useRef(null);

    const handlePageChange = (e, num) => {
        onPageChangeProps(num);
    };

    return (
        <Box className="mt-4">
            <Stack direction="column">
                <ListInfo className={clsx('ml-auto', 'mb-2')} pageSize={pageSize} pageIndex={pageIndex} totalCount={totalCount} isCurrentPageLastIndex />
                <Box className={'scroll-arrow-container'}>
                    <Tablenization
                        cardProps={{ className: 'theme-gray' }}
                        page={pageIndex}
                        count={totalPage}
                        onChange={handlePageChange}
                        tableContainerRef={scrollerArrowRef}
                        isPagination
                    >
                        <Tablenization.TableRowHeader headerRow={headerRow} />
                        <Tablenization.TableRowBody headerRow={headerRow} rowsData={rowsData} />
                    </Tablenization>
                    <ScrollerArrowButton parentRef={scrollerArrowRef} />
                </Box>
            </Stack>
        </Box>
    );
});

export default PointsProductGiftRecordTable;
