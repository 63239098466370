import React from 'react';
import SvgIcon from '../SvgIcon';
import useForkRef from '@util/hook/useForkRef';

const HumanResource = React.forwardRef((props, ref) => {
    const { className, ...others } = props;
    const handleRef = React.useRef(null);
    const svgRef = useForkRef(handleRef, ref);
    const defaultProperty = {
        className,
        ref: svgRef,
        ...others,
    };
    return (
        <SvgIcon {...defaultProperty}>
            <path d="M10.98,19.34c-3.34,0-6.67,0-10.01,0-.79,0-.97-.18-.97-.95C0,15.86,0,13.32,0,10.79c0-.7,.2-.9,.88-.9,6.74,0,13.49,0,20.23,0,.68,0,.88,.2,.88,.9,0,2.55,0,5.1,0,7.65,0,.71-.2,.9-.93,.9-3.37,0-6.73,0-10.1,0ZM3.99,4.84c-1.27,.65-2.08,2.38-1.74,3.74H11.17c.26-1.49-.46-3.03-1.73-3.74-1.77,1.71-4.03,1.44-5.44,0Zm9.17,3.7c.06,.03,.08,.05,.11,.05,1.92,0,3.84,0,5.76,0,.12,0,.29-.16,.34-.29,.41-.98,.8-1.96,1.18-2.94,.26-.67,0-1.06-.72-1.06-1.25,0-2.49,0-3.74,0-1.28,0-1.2-.11-1.69,1.15-.41,1.03-.82,2.06-1.24,3.1Zm-6.48-3.82c1.31,.01,2.38-1.05,2.38-2.36C9.06,1.07,8.02,.01,6.73,0c-1.31-.01-2.38,1.05-2.38,2.36,0,1.3,1.04,2.35,2.34,2.37ZM1.98,21.37c.03,.38,.25,.61,.63,.63,.44,.02,.88,.02,1.33,0,.34-.02,.56-.22,.61-.56,.03-.26,0-.53,0-.8H1.98c0,.27-.01,.5,0,.73Zm15.47-.01c.02,.39,.26,.63,.66,.64,.41,.01,.83,.01,1.24,0,.42-.01,.65-.25,.67-.67,.01-.22,0-.45,0-.68h-2.58c0,.26-.01,.49,0,.71Zm-8.42-4.72c0,.46,.26,.75,.64,.75,.39,0,.64-.28,.64-.75,0-1.35,0-2.69,0-4.04,0-.5-.25-.79-.65-.78-.38,0-.62,.29-.63,.76,0,.42,0,.85,0,1.28h-1.69c0-.48,.01-.93,0-1.39-.02-.42-.27-.66-.64-.66-.37,0-.63,.25-.63,.67-.01,1.4,0,2.81,0,4.21,0,.33,.15,.58,.49,.66,.43,.1,.76-.18,.78-.67,.02-.49,0-.99,0-1.49h1.69c0,.49,0,.96,0,1.43Zm4.92-1.41c.11-.07,.17-.1,.23-.14,.72-.46,1.03-1.18,.84-1.95-.18-.71-.83-1.25-1.63-1.31-.41-.03-.83-.03-1.24-.01-.44,.02-.67,.24-.68,.67-.01,1.4-.01,2.81,0,4.21,0,.44,.28,.7,.66,.69,.36-.01,.6-.26,.62-.68,.01-.25,0-.5,0-.88,.44,.5,.78,.89,1.14,1.28,.31,.33,.7,.38,.98,.12,.27-.25,.27-.63-.01-.96-.29-.34-.58-.67-.9-1.04Zm-.25-1.43c-.22,.33-.56,.26-.92,.21v-.88c.34-.04,.68-.12,.9,.2,.08,.11,.09,.36,.01,.47Z" />
        </SvgIcon>
    );
});

export default HumanResource;
