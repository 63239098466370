import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { AutocompleteField, Box, Button, FormControlLabel, Switch, Typography, Stack, IconButton, Tooltip } from '@common/components/';
import { Close as CloseIcon } from '@common/SvgIcon/';
import Tablenization from '@icoach/components/Tablenization';
import usePeopleApi, { peopleType } from '@apis/usePeopleApi';
import useMessageDialog from '@util/hook/useMessageDialog';

/**
 * 根據會員ID陣列和會員資料陣列生成行數據
 * @param {Array} memberIDs - 包含會員ID的陣列
 * @param {Array} memberData - 包含會員資料的陣列
 * @returns {Array} - 行數據陣列
 */
const handleRowsData = (memberIDs, memberData) => {
    if (Array.isArray(memberIDs) && Array.isArray(memberData)) {
        return memberIDs.reduce((accumulator, currentID) => {
            const matchingMember = memberData.find((member) => String(member.id) === String(currentID));

            if (matchingMember) {
                return [...accumulator, matchingMember];
            }

            return accumulator;
        }, []);
    }

    return [];
};

const headerRow = [
    {
        cellKey: 'name',
        headerLabel: '會員姓名',
    },
    {
        cellKey: 'no',
        headerLabel: '編號',
    },
    {
        cellKey: 'id',
        align: 'right',
        headerLabel: '',
        formatCell: (id, rowData, { onDeleteItem }) => {
            return (
                <Tooltip title="刪除">
                    <IconButton onClick={() => onDeleteItem(id)}>
                        <CloseIcon />
                    </IconButton>
                </Tooltip>
            );
        },
    },
];

const MemberList = (props) => {
    const { rowsData, handleDeleteItem } = props;
    const memberDataRef = useRef(null);

    return (
        <Box className={'bg-light p-2'}>
            <Typography className={'font-weight-bold mb-1'} variant={'h6'}>
                {`贈送人清單(${rowsData.length})`}
            </Typography>
            <Tablenization>
                <Tablenization.TableRowHeader headerRow={headerRow} />
                <Tablenization.TableRowBody
                    headerRow={headerRow}
                    rowsData={rowsData}
                    memberData={memberDataRef.current || {}}
                    onDeleteItem={handleDeleteItem}
                />
            </Tablenization>
        </Box>
    );
};

const MemberSelectionPanel = React.forwardRef((props, ref) => {
    const { memberIDs = [], isIncludeInvalid = false } = props;
    const [memberData, setMemberData] = useState({
        memberIDs: [],
        memberRowsData: [],
    });
    const { memberRowsData } = memberData || {};
    const [uuidInput, setUuidInput] = useState(uuid());
    const [filterType, setFilterType] = useState(isIncludeInvalid ? peopleType.all : peopleType.member);
    const { people: peopleOption = [] } = usePeopleApi(filterType);
    const setMessageDialog = useMessageDialog();
    const memberRef = useRef(null);
    const preloadRef = useRef(false);

    const handleSwitchChange = (e, isChecked) => {
        if (isChecked) setFilterType(peopleType.all);
        else setFilterType(peopleType.member);
    };

    const handleAddItem = () => {
        const { memberIDs } = memberData;
        const currentData = memberRef.current && memberRef.current.getResult();
        const { id } = currentData;

        if (!id) return false;

        if (!memberIDs.includes(id)) {
            setMemberData((prev) => {
                return {
                    memberIDs: [...prev.memberIDs, id],
                    memberRowsData: [...prev.memberRowsData, currentData],
                };
            });
        } else {
            setMessageDialog({
                open: true,
                title: '重複加入',
                msg: '贈送人清單已有重複的會員',
            });
        }

        setUuidInput(uuid());
    };

    const handleDeleteItem = (memberID) => {
        const { memberIDs: prevMemberIDs } = memberData;
        const memberIDs = prevMemberIDs.filter((id) => String(id) !== String(memberID));
        const memberRowsData = handleRowsData(memberIDs, peopleOption);
        setMemberData({
            memberIDs,
            memberRowsData,
        });
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleAddItem();
        }
    };

    useEffect(
        () => {
            if (preloadRef.current) return;
            if (!Array.isArray(peopleOption) && !Array.isArray(memberIDs)) return;
            if (peopleOption.length < 1) return;

            let result = { memberIDs };

            if (memberIDs.length > 0) {
                result.memberRowsData = handleRowsData(memberIDs, peopleOption);
            }
            setMemberData((prev) => ({
                ...prev,
                ...result,
            }));

            preloadRef.current = true;
        },
        // eslint-disable-next-line
        [memberIDs, peopleOption],
    );

    useImperativeHandle(
        ref,
        () => ({
            getResult: () => memberData.memberIDs,
            isError: () => {
                let isError = false;
                const { memberIDs } = memberData;

                if (memberIDs.length < 1) {
                    setMessageDialog({
                        open: true,
                        title: '提醒',
                        msg: '至少要設定一筆贈送對象',
                    });
                    isError = true;
                }

                return isError;
            },
        }),
        // eslint-disable-next-line
        [memberData.memberIDs],
    );

    return (
        <React.Fragment>
            <Box>
                <Stack justifyContent={'end'} spacing={2}>
                    <AutocompleteField
                        key={uuidInput}
                        className={'flex-auto'}
                        label={'選擇會員'}
                        ref={memberRef}
                        options={peopleOption}
                        optionKeys={['name', 'mobile', 'no']}
                        primaryKey={'id'}
                        inputProps={{
                            placeholder: '請輸入姓名或會編或電話',
                        }}
                        InputProps={{
                            onKeyDown: handleKeyPress,
                        }}
                        fullWidth
                    />
                    <Button className={'mt-4 white-space-nowrap'} variant={'outlined'} onClick={handleAddItem}>
                        加入清單
                    </Button>
                </Stack>
                <FormControlLabel
                    key={filterType}
                    className={'ml-0'}
                    control={<Switch defaultChecked={String(filterType) === peopleType.all} onChange={handleSwitchChange} />}
                    prefixLabel={'包含無效會員'}
                />
            </Box>
            <MemberList rowsData={memberRowsData} handleDeleteItem={handleDeleteItem} />
        </React.Fragment>
    );
});

export default MemberSelectionPanel;
