import React, { useImperativeHandle, useLayoutEffect } from 'react';
import clsx from 'clsx';
import { Autocomplete, Dialog, DialogContent } from '@mui/material';
import GradientColorHeader from '@icoach/components/dialog/GradientColorHeader';
import { OutlinedInput, Box, Typography, TextField, Stack, Button, FormControl, FormLabel, FormHelperText } from '@common/components/';
import { createFilterOptions, isEmpty, findIndexFn } from '@util/utils';

const filter = createFilterOptions();

/**
 * return String
 */
const mutipleView = (_keys, _option, sign) =>
    _keys
        .reduce((acc, cur) => {
            let newAry = [...acc];
            if (_option[cur]) newAry.push(_option[cur]);
            return newAry;
        }, [])
        .join(sign);

const getAllState = (_keys) => {
    return _keys.reduce((acc, cur) => ({ ...acc, [cur]: '' }), {});
};

const getNameAndMobile = (target, sign) => {
    let source;
    if (typeof target === 'string') source = target.split(sign);
    else source = target.inputValue.split(sign);
    return source;
};

const initValue = (props) => {
    const { value: _value, key, options } = props;
    let _index = -1;
    if (isEmpty(_value)) return {};
    _index = findIndexFn(options, key, _value);
    if (_index === -1) return {};
    return options[_index];
};

const FreeSoloCreateOptionDialog = React.forwardRef((props, ref) => {
    const {
        onChange: onChangeProps,
        options: optionsProps,
        defaultValue: defaultValueProps = undefined,
        optionKeys = ['name', 'mobile'],
        primaryKey = 'id',
        name: nameProps,
        isError: propsError = false,
        disabled = false,
        fullWidth = false,
        required = false,
        isLightColor = false,
        className,
        label,
        direction: directionProps = 'column',
        helperText = '請填寫必填欄位',
        InputProps = {},
        sign: signProps = ',',
    } = props;

    const [isError, setError] = React.useState(propsError);
    const [value, setValue] = React.useState(initValue({ value: defaultValueProps, options: optionsProps, key: primaryKey }));
    const [open, toggleOpen] = React.useState(false);
    const [dialogValue, setDialogValue] = React.useState(getAllState(optionKeys));
    const inputRef = React.useRef(null);

    const handleSubmit = () => {
        setValue(dialogValue);
        toggleOpen(false);
    };

    const handleClose = () => {
        setDialogValue(optionKeys.reduce((acc, cur) => ({ ...acc, [cur]: '' }), {}));
        toggleOpen(false);
    };

    const onChange = (event, newValue = '', eventName, targetData) => {
        if (typeof newValue === 'string' || (newValue && newValue.inputValue)) {
            const [name = '', mobile = ''] = getNameAndMobile(newValue, signProps);
            setTimeout(() => {
                toggleOpen(true);
            });
            setDialogValue(Object.assign({}, getAllState(optionKeys), { name: name || '' }, { mobile: mobile || '' }));
        } else {
            setValue(newValue);
        }
        onChangeProps && onChangeProps(eventName, targetData);
    };

    const getOptionLabel = (_option) => {
        if (typeof _option === 'string') return _option;
        if (_option.inputValue) return _option.inputValue;
        return mutipleView(optionKeys, _option, signProps);
    };

    const renderOption = (props, _option) => {
        const { key: label } = props;
        const viewText = mutipleView(optionKeys, _option, signProps);

        return (
            <Box {...props} key={`${_option[primaryKey]}_${label}`}>
                <Typography component="span">{viewText}</Typography>
            </Box>
        );
    };

    const filterOptions = (options, params) => {
        const filtered = filter(options, params);
        if (params.inputValue !== '') {
            filtered.push({
                inputValue: params.inputValue,
                [optionKeys[0]]: `新增 "${params.inputValue}"`,
            });
        }
        return filtered;
    };

    const renderInput = (params) => {
        const defaultProperty = {
            disabled: params.disabled,
            fullWidth: params.fullWidth,
            InputProps: { ...params.InputProps, ...InputProps },
            ...params,
            name: nameProps,
        };
        return <OutlinedInput {...defaultProperty} inputRef={inputRef} />;
    };

    useImperativeHandle(
        ref,
        () => {
            return {
                getResult: () => {
                    let source = {};
                    let targetValue = value || {};
                    if (targetValue.id) Object.assign(source, { customerID: targetValue.id });
                    else Object.assign(source, { name: targetValue.name || '', cellphone: targetValue.mobile || '' });
                    return source;
                },
                isError: () => {
                    let error = true;
                    if (value && value.name && value.mobile) error = false;
                    setError(error);
                    return error;
                },
                resetValue: () => {
                    setValue('');
                },
            };
        },
        // eslint-disable-next-line
        [value]
    );

    useLayoutEffect(
        () => {
            if (isEmpty(primaryKey)) console.error('Please give primaryKey');
        },
        // eslint-disable-next-line
        []
    );

    const autoCompleteProperty = {
        value,
        onChange,
        filterOptions,
        options: optionsProps,
        getOptionLabel,
        renderInput,
        renderOption,
        selectOnFocus: true,
        clearOnBlur: true,
        freeSolo: true,
        disabled: disabled,
        ...InputProps,
    };

    return (
        <React.Fragment>
            <FormControl
                disabled={disabled}
                fullWidth={fullWidth}
                required={required}
                className={clsx('autocomplete-field', className)}
                direction={directionProps}
                error={isError}
            >
                {label && <FormLabel lightColor={isLightColor}>{label}</FormLabel>}
                <Stack direction="column" className="field-input-root">
                    <Autocomplete {...autoCompleteProperty} />
                    {isError && <FormHelperText>{helperText}</FormHelperText>}
                </Stack>
            </FormControl>
            <Dialog open={open}>
                <GradientColorHeader onClose={handleClose}>{'新增'}</GradientColorHeader>
                <DialogContent className="pt-2">
                    <Stack spacing={2}>
                        <TextField
                            label="姓名"
                            value={dialogValue.name}
                            onChange={(event) =>
                                setDialogValue({
                                    ...dialogValue,
                                    name: event.target.value,
                                })
                            }
                            fullWidth
                            required
                        />
                        <TextField
                            label="手機"
                            value={dialogValue.mobile}
                            maskType={'MOB'}
                            onChange={(event) =>
                                setDialogValue({
                                    ...dialogValue,
                                    mobile: event.target.value,
                                })
                            }
                            fullWidth
                            required
                        />
                    </Stack>
                    <Stack justifyContent="end" className="pt-2">
                        <Button variant="contained" onClick={handleSubmit}>
                            確定
                        </Button>
                    </Stack>
                </DialogContent>
            </Dialog>
        </React.Fragment>
    );
});

export default FreeSoloCreateOptionDialog;
