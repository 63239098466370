import React, { useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
    AddTemporaryDialog,
    RemoveCutomersDialog,
    RemoveTemparyCustomersDialog,
    AddInvalidDialog,
    NoMemberContactDialog,
    SendSmsDialog,
} from '@icoach/nonMember/dialog/';
import {
    FolderPlus as FolderPlusIcon,
    PaperPlane as PaperPlaneIcon,
    UserSlash as UserSlashIcon,
    Trash as TrashIcon,
    Phone as PhoneIcon,
    Assignment as AssignmentIcon,
    RemoveRedEye as RemoveRedEyeIcon,
} from '@common/SvgIcon/';
import { IconButton } from '@common/components/';
import { findIndexFn } from '@util/utils';

export const DefaultIconMap = {
    invalid: {
        description: '將顧客或名單人員設定為無效',
        Icon: <UserSlashIcon />,
        Dialog: AddInvalidDialog,
    },
    remove: {
        description: '刪除顧客或名單人員',
        Icon: <TrashIcon />,
        Dialog: RemoveCutomersDialog,
    },
    removetemp: {
        description: '刪除暫存清單內的顧客或名單人員',
        Icon: <TrashIcon />,
        Dialog: RemoveTemparyCustomersDialog,
    },
    sms: { Icon: <PaperPlaneIcon />, Dialog: SendSmsDialog },
    folder: { Icon: <FolderPlusIcon />, Dialog: AddTemporaryDialog },
    contact: { Icon: <PhoneIcon />, Dialog: NoMemberContactDialog },
    appointment: { Icon: <AssignmentIcon />, Dialog: null, to: ({ urls }) => `${urls['apo']}` },
    review: { Icon: <RemoveRedEyeIcon />, Dialog: null },
};

/**
 * filterKey 顧客/名單  -> customerID, 暫存名單 -> temporaryID
 */

const IconGroupHeader = (props) => {
    const {
        selectedRef: domRef,
        rowsData = [],
        viewIconArray = [],
        dialogProps = {},
        IconMap = DefaultIconMap,
        filterKey = 'customerID',
        urls,
        refresh: refreshProp,
        clearAllChecked,
        ...others
    } = props;
    const history = useHistory();
    const [dialogOpen, setDialogOpen] = useState(null);
    const [sourceData, setSourceData] = useState([]);
    const refreshRef = useRef();

    const checkedAction = (targetArray, targetData) =>
        targetArray.reduce((acc, cur) => {
            const _index = findIndexFn(targetData, filterKey, cur.value); // todo
            let newAcc = [...acc];
            if (cur.checked && _index !== -1) newAcc.push(targetData[_index]);
            return newAcc;
        }, []);

    const handleClose = () => {
        setSourceData([]);
        setDialogOpen(null);
    };

    const handleRefresh = (target) => {
        switch (target) {
            case 'sms':
            case 'folder':
                refreshRef.current = clearAllChecked;
                break;
            default:
                refreshRef.current = refreshProp;
                break;
        }
    };

    const handleDialogMessage = (target, hasDialog) => {
        handleRefresh(target);
        if (hasDialog) {
            const selectedItem = domRef && domRef.current ? checkedAction(domRef.current, rowsData) : rowsData;
            if (selectedItem.length === 0) return;
            setSourceData([...selectedItem]);
            setDialogOpen(target);
        } else if (target === 'appointment' && urls['apo']) {
            history.push(urls['apo']);
        }
    };

    return (
        <React.Fragment>
            {viewIconArray.map((target) => {
                const { Icon, Dialog } = IconMap[target] || {};
                return IconMap[target] ? (
                    <React.Fragment key={target}>
                        <IconButton onClick={() => handleDialogMessage(target, !!Dialog)}>{Icon}</IconButton>
                        {Dialog && (
                            <Dialog
                                {...others}
                                open={dialogOpen === target}
                                onClose={handleClose}
                                sourceData={dialogOpen === target ? sourceData : []}
                                refresh={refreshRef}
                                {...(dialogProps[target] || {})}
                            />
                        )}
                    </React.Fragment>
                ) : null;
            })}
        </React.Fragment>
    );
};

export default IconGroupHeader;
