import React, { useState, useCallback } from 'react';
import _ from 'lodash';
import clsx from 'clsx';
import { Grid } from '@mui/material';
import { GuidanceNotesTitle, SimpleContentItem, TaskTodo } from '@icoach/members/components';
import {makeBasicData, handleListValue, viewOrderText} from '@icoach/members/memberDetail/MemberDetailSideBar';
import { Card } from '@common/components/';
import {
    AgreedNotesDialog, CalciumPushDialog,
    GuidanceNotesDialog,
    HundredTShirtSizeDialog,
    MeasureDayDialog,
    MemoDialog,
    ProbioticsPushDialog,
    ProteinPushDialog,
    SpecialConditionDialog,
    XFactorDialog,
} from '@icoach/members/memberDetail/dialog';

const InBodyInterviewSummary = React.forwardRef((props, ref) => {
    const { sourceData = {}, options = {}, initAllDateApi, refreshProfileApi } = props;
    const {
        memberID,
        measureDay,
        isInterviewNotMeasure,
        proteinPush,
        probioticsPush,
        calciumPush,
        hundredTShirtSize,
        xFactorNotes,
        agreedNotes,
        guidanceNotes,
        specialCondition,
        memo,
        todoTasks = [],
        serviceTasks = [],
    } = sourceData;

    // 會員狀況 items
    const getMemberSituationItems = useCallback(
        () => {
            const { measureDay, proteinPush, probioticsPush, hundredTShirtSize } = sourceData;

            return [
                makeBasicData('measureDay', 'WM日', measureDay || '未設定', false, true),
                makeBasicData('proteinPush', '蛋白訂單', viewOrderText(proteinPush.pushStatusText, proteinPush.reasonTypeText), false, proteinPush.isEditable),
                makeBasicData('probioticsPush', '益菌訂單', viewOrderText(probioticsPush.pushStatusText, probioticsPush.reasonTypeText), false, probioticsPush.isEditable),
                makeBasicData('calciumPush', '鈣訂單', viewOrderText(calciumPush.pushStatusText, calciumPush.reasonTypeText), false, calciumPush.isEditable),
                makeBasicData('hundredTShirtSize', '百T尺寸', hundredTShirtSize || '未設定', false, true),
            ];
        },
        // eslint-disable-next-line
        [sourceData]
    );

    const memberSituationItems = getMemberSituationItems();
    // 注意事項 items
    const getNotifyItems = useCallback(
        () => {
            const { xFactorNotes, agreedNotes, guidanceNotes, specialCondition, memo } = sourceData;
            return [
                makeBasicData('xFactorNotes', 'X-Factor', handleListValue(xFactorNotes), true, true, {
                    className: 'mb-2',
                }),
                makeBasicData('agreedNotes', '約定事項', handleListValue(agreedNotes), true, true, {
                    className: 'mb-2',
                }),
                makeBasicData('guidanceNotes', <GuidanceNotesTitle />, guidanceNotes, true, true, {
                    className: 'mb-2',
                }),
                makeBasicData('specialCondition', '特殊狀況', specialCondition, true, true, {
                    className: 'mb-2',
                }),
                makeBasicData('memo', '備註', memo, true, true, { className: 'mb-2' }),
            ];
        },
        // eslint-disable-next-line
        [sourceData]
    );

    const notifyItems = getNotifyItems();
    const [openDialog, setOpenDialog] = useState('');
    const handleOpenDialog = (target) => setOpenDialog(target);
    const handleCloseDialog = () => setOpenDialog('');

    return (
        <React.Fragment>
            {!_.isEmpty(sourceData) && (
                <Grid container spacing={2}>
                    <Grid item xs={3}>
                        <Card className="mb-4 ">
                            <Card.CardHeader title="會員狀況" className="pt-4 pb-0" />
                            <Card.CardContent className="px-4 pb-2 member-state">
                                <ul className="list">
                                    {memberSituationItems.map((item) => {
                                        return (
                                            <li className={clsx('lists-item')} key={item.id}>
                                                <SimpleContentItem
                                                    label={item.title}
                                                    isEdit={item.isEdit}
                                                    isMultiline={item.isMultiline}
                                                    handleClickEdit={() => handleOpenDialog(item.id)}
                                                >
                                                    {item.value}
                                                </SimpleContentItem>
                                            </li>
                                        );
                                    })}
                                </ul>
                            </Card.CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={3}>
                        <Card className="mb-4">
                            <Card.CardHeader title="注意事項" className="pt-4 pb-0" />
                            <Card.CardContent className="px-4 member-state">
                                <ul className="list">
                                    {notifyItems.map((item) => {
                                        return (
                                            <li className={clsx('lists-item', item.className)} key={item.id}>
                                                <SimpleContentItem
                                                    label={item.title}
                                                    isEdit={item.isEdit}
                                                    isMultiline={item.isMultiline}
                                                    handleClickEdit={() => handleOpenDialog(item.id)}
                                                >
                                                    {item.value}
                                                </SimpleContentItem>
                                            </li>
                                        );
                                    })}
                                </ul>
                            </Card.CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={3}>
                        <TaskTodo memberID={memberID} title="待辦事項" taskType={11} data={todoTasks} refresh={refreshProfileApi} hasIcon />
                    </Grid>
                    <Grid item xs={3}>
                        <TaskTodo memberID={memberID} title="服銷代辦事項" taskType={12} data={serviceTasks} refresh={refreshProfileApi} hasIcon />
                    </Grid>
                </Grid>
            )}
            <MeasureDayDialog
                open={openDialog === 'measureDay'}
                memberID={memberID}
                data={{ measureDay, isInterviewNotMeasure }}
                onClose={handleCloseDialog}
                refresh={initAllDateApi}
                showInterviewNotMeasureIcon={false}
            />
            <HundredTShirtSizeDialog
                open={openDialog === 'hundredTShirtSize'}
                memberID={memberID}
                data={hundredTShirtSize}
                resource={options}
                onClose={handleCloseDialog}
                refresh={initAllDateApi}
            />
            <AgreedNotesDialog
                open={openDialog === 'agreedNotes'}
                memberID={memberID}
                data={agreedNotes}
                onClose={handleCloseDialog}
                refresh={initAllDateApi}
            />
            <GuidanceNotesDialog
                open={openDialog === 'guidanceNotes'}
                memberID={memberID}
                data={guidanceNotes}
                onClose={handleCloseDialog}
                refresh={initAllDateApi}
            />
            <SpecialConditionDialog
                open={openDialog === 'specialCondition'}
                memberID={memberID}
                data={specialCondition}
                onClose={handleCloseDialog}
                refresh={initAllDateApi}
            />
            <MemoDialog open={openDialog === 'memo'} memberID={memberID} data={memo} onClose={handleCloseDialog} refresh={initAllDateApi} />
            <ProbioticsPushDialog
                open={openDialog === 'probioticsPush'}
                memberID={memberID}
                data={probioticsPush}
                onClose={handleCloseDialog}
                refresh={initAllDateApi}
            />
            <ProteinPushDialog
                open={openDialog === 'proteinPush'}
                memberID={memberID}
                data={proteinPush}
                onClose={handleCloseDialog}
                refresh={initAllDateApi}
            />
            <CalciumPushDialog
                open={openDialog === 'calciumPush'}
                memberID={memberID}
                data={calciumPush}
                onClose={handleCloseDialog}
                refresh={initAllDateApi}
            />
            <XFactorDialog open={openDialog === 'xFactorNotes'} memberID={memberID} data={xFactorNotes} onClose={handleCloseDialog} refresh={initAllDateApi} />
        </React.Fragment>
    );
});

export default InBodyInterviewSummary;
