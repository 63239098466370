import React, { createContext, useContext } from 'react';

const GiftContext = createContext(undefined);

export const GiftProvider = ({ children, value }) => {
    return <GiftContext.Provider value={value}>{children}</GiftContext.Provider>;
};

export const useGiftContext = () => {
    const context = useContext(GiftContext);
    if (context === undefined) {
        throw new Error('useGiftContext 必須在 GiftProvider 中使用');
    }
    return context;
};
