import React, { useEffect, useRef, useState } from 'react';
import { isEmpty } from 'lodash';
import { Alert, Card, Tab, Tabs, Typography } from '@common/components/';
import { SearchBar } from '@icoach/dataAnalysis/components/';
import useReportApi from '@apis/useReportApi';
import { EmployeesDifferenceCumulativeTable, EmployeesMonthlyTable } from '@icoach/dataAnalysis/tables/';
import { DataAnalysisProvider } from '@icoach/dataAnalysis/components/DataAnalysisContext';

const tableType = {
    differenceCumulative: 'differenceCumulative',
};

const EmployeesReportTableContent = (props) => {
    const { data = {} } = props;
    const { employeeDetail, differenceCumulative } = data;
    const findEmployeeData = (employeeID) => {
        if (!isEmpty(employeeDetail)) {
            return employeeDetail.find((item) => item.employeeID === employeeID) || {};
        }
        return {};
    };
    const [tabsIndex, setTabsIndex] = useState(tableType.differenceCumulative);
    const currentEmployeeData = findEmployeeData(tabsIndex);

    const handleTabChange = (_, value) => {
        setTabsIndex(value);
    };

    return (
        <Card className={'mb-4'}>
            <Card.CardContent className={'px-4 pt-3 pb-4'}>
                <Typography className={'font-weight-bold'} variant={'h5'}>
                    月統計
                </Typography>
                {!isEmpty(employeeDetail) ? (
                    <React.Fragment>
                        <div className="page-tabs tabs-bottom-line mb-4">
                            <Tabs value={tabsIndex} variant="scrollable" scrollButtons="auto" onChange={handleTabChange}>
                                <Tab className={'py-3'} label={'比較'} value={tableType.differenceCumulative} />
                                {!isEmpty(employeeDetail) &&
                                    employeeDetail.map(({ employeeID, employeeName }) => {
                                        return <Tab key={employeeID} className={'py-3'} label={employeeName} value={employeeID} />;
                                    })}
                            </Tabs>
                        </div>
                        {tabsIndex === tableType.differenceCumulative ? (
                            <EmployeesDifferenceCumulativeTable rowsData={employeeDetail} cumulative={differenceCumulative} />
                        ) : (
                            <EmployeesMonthlyTable rowsData={currentEmployeeData?.weekly} cumulative={currentEmployeeData?.cumulative} />
                        )}
                    </React.Fragment>
                ) : (
                    <Alert variant={'standard'} severity={'info'} color={'info'}>
                        尚無資料
                    </Alert>
                )}
            </Card.CardContent>
        </Card>
    );
};

const DataAnalysisContentEmployees = (props) => {
    const { defaultDate, dialogRef } = props;
    const { getReportEmployeeApi } = useReportApi();
    const [data, setData] = useState({});
    const searchRef = useRef();

    const doReportEmployeeApi = async (params) => {
        let res = await getReportEmployeeApi(params);
        if (res) {
            setData(res);
        }
    };

    const getParams = () => {
        let result = {};
        if (searchRef.current && searchRef.current.getResult) {
            result = Object.assign(result, searchRef.current.getResult());
        }

        return result;
    };

    const handleSubmit = () => {
        let isError = searchRef.current && searchRef.current.isError();
        if (!isError) {
            let params = getParams();
            doReportEmployeeApi(params);
        }
    };

    const childContext = {
        data,
        defaultDate,
        dialogRef,
    };

    useEffect(() => {
        handleSubmit();
        // eslint-disable-next-line
    }, []);

    return (
        <DataAnalysisProvider value={childContext}>
            <SearchBar ref={searchRef} className={'mb-4'} onSubmit={handleSubmit} defaultDate={props.defaultDate} />
            <EmployeesReportTableContent data={data} />
        </DataAnalysisProvider>
    );
};

export default DataAnalysisContentEmployees;
