import React from 'react';
import { TableRow, TableBody, TableCell, Table } from '../../../../components';
import { formatCurrencyFn } from '../../../../util/utils';

const CycleOrderProductItem = (props) => {
    const { data } = props;
    const { productName, quantity, salePrice } = data;
    return (
        <TableRow>
            <TableCell>{productName}</TableCell>
            <TableCell>{`x ${quantity}`}</TableCell>
            <TableCell className={'text-right'}>{formatCurrencyFn(salePrice)}</TableCell>
            <TableCell className={'text-right'}>{formatCurrencyFn(salePrice * quantity)}</TableCell>
        </TableRow>
    );
};

const CycleOrderProductsTable = (props) => {
    const { className, data } = props;

    return (
        <React.Fragment>
            <Table className={className}>
                <TableBody>
                    {Array.isArray(data) &&
                        data.map((item) => {
                            const { productID, variantID } = item;
                            return (
                                <CycleOrderProductItem
                                    key={`${productID}-${variantID}`}
                                    data={item}
                                />
                            );
                        })}
                </TableBody>
            </Table>
        </React.Fragment>
    );
};

export default CycleOrderProductsTable;
