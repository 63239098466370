import React, { useRef, useEffect } from 'react';
import { Grid } from '@mui/material';
import clsx from 'clsx';
import useMessage from '../components/useMessage';
import useSmsApi from '../../../apis/useSmsApi';
import { Button, Card, Form, DateField, Stack } from '../../../components';
import { initialDate, parseDate, isValidDate, getAddMonths } from '../../../util/moment';

const defaultPageModel = { pageIndex: 1, pageSize: 10 };

const SmsOverviewSearchBar = React.forwardRef((props, ref) => {
    const { className } = props;
    const { postSmsOverviewApi } = useSmsApi();
    const { bookmark, setSourceData, setRefresh, sortParams } = useMessage();

    const searchMonthRef = useRef(null);
    const isPreLoadRef = useRef(true); // 等待渲染完畢

    const getAllParams = (pageModel = {}) => {
        const searchMonth = searchMonthRef.current.getResult();
        const startMonth = parseDate(searchMonth, 'YYYY-MM');
        const endMonth = parseDate(getAddMonths(searchMonth, 1), 'YYYY-MM');
        const params = {
            ...defaultPageModel,
            startDate: startMonth,
            endDate: endMonth,
            sortBy: sortParams,
            ...pageModel,
        };
        return params;
    };

    const handleHistorySearch = () => {
        const searchMonth = searchMonthRef.current.getResult();
        if (isValidDate(searchMonth)) {
            doHistorySearchApi(getAllParams());
        }
    };

    const doHistorySearchApi = async (params) => {
        const resp = await postSmsOverviewApi(params);
        if (resp) {
            setSourceData(resp);
        }
    };

    useEffect(
        () => {
            if (!isPreLoadRef.current) {
                doHistorySearchApi(getAllParams(bookmark));
            }
            isPreLoadRef.current = false;
        },
        // eslint-disable-next-line
        [bookmark]
    );

    useEffect(
        () => {
            if (!isPreLoadRef.current) {
                doHistorySearchApi(getAllParams(bookmark));
            }
            isPreLoadRef.current = false;
        },
        // eslint-disable-next-line
        [sortParams]
    );

    setRefresh(() => doHistorySearchApi(getAllParams()));

    return (
        <Card className={clsx('search-bar', className)}>
            <Card.CardContent>
                <Form name="create" onSubmit={handleHistorySearch}>
                    <Stack className="px-4 py-3" spacing={3} alignItems={'end'}>
                        <Grid container spacing={3}>
                            <Grid item xs={6}>
                                <DateField
                                    label={'月份'}
                                    ref={searchMonthRef}
                                    defaultValue={initialDate(new Date())}
                                    format="YYYY/MM"
                                    mask="____/__"
                                    openTo="month"
                                    views={['year', 'month']}
                                    direction="row"
                                    placeholder="請選擇月份"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Button type="submit" color="secondary" variant="contained">
                                    搜尋
                                </Button>
                            </Grid>
                        </Grid>
                    </Stack>
                </Form>
            </Card.CardContent>
        </Card>
    );
});

export default SmsOverviewSearchBar;
