import React, { useImperativeHandle, useState, useRef } from 'react';
import { Grid } from '@mui/material';
import { v4 as uuid } from 'uuid';
import { Minus as MinusIcon } from '../../../components/icons/SvgIcon/';
import { TextField, Button, IconButton } from '../../../components';
import { refIsRequiredError } from '../../../util/utils';

const formatContactPropsData = (targetData) => {
    let resourceAry = [];
    if (Array.isArray(targetData)) {
        if (targetData.length === 0) {
            resourceAry = [{ uid: uuid() }];
        } else {
            resourceAry = targetData.map((target) => ({ uid: uuid(), ...target }));
        }
    }
    return resourceAry;
};

const ContactPeopleTemplate = React.forwardRef((props, ref) => {
    const { sourceData: sourceDataProps = {}, isFirst, onRemove: onRemoveProps } = props;
    const nameRef = useRef(null);
    const mobileRef = useRef(null);
    const relationshipRef = useRef(null);
    const { name = '', mobile = '', relationship = '', employeeContactID: empID = 0 } =
        sourceDataProps || {};

    const handleRemoveClick = () => {
        onRemoveProps && onRemoveProps();
    };

    useImperativeHandle(
        ref,
        () => ({
            isError: () => refIsRequiredError(nameRef, mobileRef, relationshipRef),
            getResult: () => {
                return {
                    employeeContactID: empID,
                    name: nameRef.current.getResult(),
                    mobile: mobileRef.current.getResult(),
                    relationship: relationshipRef.current.getResult(),
                };
            },
        }),
        // eslint-disable-next-line
        []
    );

    return (
        <React.Fragment>
            <Grid xs={4} item>
                <TextField
                    ref={nameRef}
                    defaultValue={name}
                    label={'姓名'}
                    fullWidth
                    required={isFirst}
                />
            </Grid>
            <Grid xs={4} item>
                <TextField
                    ref={mobileRef}
                    defaultValue={mobile}
                    label={'電話'}
                    fullWidth
                    required={isFirst}
                    type="phone"
                />
            </Grid>
            <Grid xs={3} item>
                <TextField
                    ref={relationshipRef}
                    defaultValue={relationship}
                    label={'關係'}
                    fullWidth
                    required={isFirst}
                />
            </Grid>
            <Grid xs={1} item>
                {!isFirst ? (
                    <IconButton className="mt-3" color="error" onClick={handleRemoveClick}>
                        <MinusIcon />
                    </IconButton>
                ) : null}
            </Grid>
        </React.Fragment>
    );
});

const EmployeesInformationsAddContactsItem = React.forwardRef((props, ref) => {
    const { sourceData: sourceDataProps = [] } = props;
    const [sourceData, setSourceData] = useState(formatContactPropsData(sourceDataProps));
    const contactPeopleTemplateRef = useRef([]);

    const handleAddContactPeolpe = () => {
        setSourceData((prev) => {
            return [...prev, { uid: uuid() }];
        });
    };

    const handleRemoveContactPeople = (uid) => {
        setSourceData((prev) => prev.filter((target) => target.uid !== uid));
    };

    useImperativeHandle(
        ref,
        () => ({
            isError: () => refIsRequiredError(...contactPeopleTemplateRef.current),
            getResult: () => {
                return contactPeopleTemplateRef.current.reduce((acc, cur) => {
                    let newAry = [...acc];
                    if (cur) newAry.push(cur.getResult());
                    return newAry;
                }, []);
            },
        }),
        // eslint-disable-next-line
        []
    );

    return (
        <React.Fragment>
            {sourceData.map((target, index) => {
                const uid = target.uid || uuid();
                return (
                    <ContactPeopleTemplate
                        key={uid}
                        isFirst={index === 0}
                        sourceData={target}
                        onRemove={() => handleRemoveContactPeople(uid)}
                        ref={(el) => (contactPeopleTemplateRef.current[index] = el)}
                    />
                );
            })}
            <Grid xs={3} item>
                <Button variant="outlined" onClick={handleAddContactPeolpe}>
                    新增聯絡人
                </Button>
            </Grid>
        </React.Fragment>
    );
});

export default EmployeesInformationsAddContactsItem;
