import React from 'react';
import clsx from 'clsx';
import { Dialog, DialogContent, Grid } from '@mui/material';
import GradientColorHeader from '../../components/dialog/GradientColorHeader';
import { ReadTextField } from '../../components';

const CycleOrderPaymentDialog = (props) => {
    const { className, open, data = {}, onClose } = props;
    const { creditCardNo, cardExpiryDate, cardName, cardRelation, creditCardTypeText } = data;

    return (
        <Dialog className={clsx('simple-content', 'dialog', className)} PaperProps={{ className: 'wx-40rem' }} open={open} fullWidth>
            <GradientColorHeader onClose={onClose}>付款資訊</GradientColorHeader>
            <DialogContent>
                <Grid spacing={3} container>
                    <Grid xs={12} item>
                        <ReadTextField label={'信用卡號'}>{creditCardNo}</ReadTextField>
                    </Grid>
                    <Grid xs={12} item>
                        <ReadTextField label={'有效期限'}>{cardExpiryDate}</ReadTextField>
                    </Grid>
                    <Grid xs={12} item>
                        <ReadTextField label={'持卡人姓名'}>{cardName}</ReadTextField>
                    </Grid>
                    <Grid xs={12} item>
                        <ReadTextField label={'持卡人關係'}>{cardRelation}</ReadTextField>
                    </Grid>
                    <Grid xs={12} item>
                        <ReadTextField label={'卡別'}>{creditCardTypeText}</ReadTextField>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};

export default CycleOrderPaymentDialog;
