import React from 'react';
import { Dialog, DialogActions, DialogContent, Grid } from '@mui/material';
import { Button, Alert } from '@common/components/';
import GradientColorHeader from '@icoach/components/dialog/GradientColorHeader';

// 刪除 顧客/名單 明細的聯繫資訊
const RemoveContractRecordDialog = (props) => {
    const { setOpen: propsSetOpen, open: propsOpen, doAction } = props;

    const handleCloseOnClick = () => {
        propsSetOpen && propsSetOpen();
    };

    const handleSendOnClick = (e) => {
        doAction && doAction();
        propsSetOpen && propsSetOpen();
    };

    return (
        <Dialog open={propsOpen} fullWidth>
            <GradientColorHeader onClose={handleCloseOnClick}>刪除聯繫資訊</GradientColorHeader>
            <DialogContent>
                <Grid container>
                    <Grid item xs={12}>
                        <Alert severity="remove" variant="filled" color="error">
                            確定要刪除?
                        </Alert>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button size="large" variant="contained" color="error" onClick={handleSendOnClick}>
                    確定
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default RemoveContractRecordDialog;
