import React, { useRef, useImperativeHandle, useState, useEffect } from 'react';
import clsx from 'clsx';
import { Grid } from '@mui/material';
import { refIsRequiredError } from '@util/utils';
import {
    InvoiceByCarrierNo,
    InvoiceByCarrierType,
    InvoiceByDonate,
    InvoiceByIssuingType,
    InvoiceByNotes,
    InvoiceByTriplicateUniform,
    invoiceCarrierTypeMap,
    issuingTypeMap,
} from '@icoach/components/Invoice';

export const INVOICE_CARRIER_TYPE_OPTIONS = [
    {
        text: '會員載具',
        value: 1,
    },
    {
        text: '手機條碼',
        value: 2,
    },
    {
        text: '自然人憑證載具',
        value: 3,
    },
];

export const INVOICE_ISSUING_TYPE_OPTIONS = [
    {
        text: '捐贈發票',
        value: 1,
    },
    {
        text: '二聯式發票',
        value: 2,
    },
    {
        text: '三聯式發票',
        value: 3,
    },
];

const InvoiceCard = React.forwardRef((props, ref) => {
    const { className, sourceData, resource = {}, errorModule = {}, readOnly, showInvoiceNotes = false } = props;
    const initInvoice = {
        invoiceIssuingType: 0,
        invoiceCarrierType: 0,
        invoiceCarrierNo: '',
        invoiceTitle: '',
        invoiceTaxID: '',
        invoiceLoveCode: '',
    };
    const { invoiceCarrierTypeOptions = [], invoiceIssuingTypeOptions = [] } = resource;
    const {
        invoiceIssuingType: invoiceIssuingTypeProps = 1,
        invoiceCarrierType: invoiceCarrierTypeProps,
        invoiceCarrierNo,
        invoiceTitle,
        invoiceTaxID,
        invoiceLoveCode,
        invoiceNotes,
    } = sourceData || {};

    const [issuingType, setIssuingType] = useState(invoiceIssuingTypeProps || 2);
    const [invoiceCarrierType, setInvoiceCarrierType] = useState(invoiceCarrierTypeProps || 1);
    const issuingTypeRef = useRef(null);
    const donateRef = useRef(null);
    const invoiceCarrierTypeRef = useRef(null);
    const invoiceCarrierNoRef = useRef(null);
    const triplicateUniformRef = useRef(null);
    const invoiceNotesRef = useRef(null);

    const handleChangeIssuingType = (value) => {
        setIssuingType(value);
    };

    const handleChangeInvoiceCarrierType = (value) => {
        setInvoiceCarrierType(value);
    };

    useImperativeHandle(
        ref,
        () => ({
            getResult: () => {
                let result = { ...initInvoice };

                if (issuingTypeRef.current && issuingTypeRef.current.getResult) {
                    const { issuingType } = issuingTypeRef.current.getResult();
                    Object.assign(result, { invoiceIssuingType: issuingType });
                }

                if (donateRef.current && donateRef.current.getResult) {
                    const { donationCode } = donateRef.current.getResult();
                    Object.assign(result, {
                        invoiceLoveCode: donationCode,
                    });
                }

                if (invoiceCarrierTypeRef.current && invoiceCarrierTypeRef.current.getResult) {
                    const { carrierType } = invoiceCarrierTypeRef.current.getResult();
                    Object.assign(result, { invoiceCarrierType: carrierType });
                }

                if (invoiceCarrierNoRef.current && invoiceCarrierNoRef.current.getResult) {
                    const { carrierNo } = invoiceCarrierNoRef.current.getResult();
                    Object.assign(result, { invoiceCarrierNo: carrierNo });
                }

                if (triplicateUniformRef.current && triplicateUniformRef.current.getResult) {
                    const { taxID, taxTitle } = triplicateUniformRef.current.getResult();
                    Object.assign(result, {
                        invoiceTaxID: taxID,
                        invoiceTitle: taxTitle,
                    });
                }

                if (invoiceNotesRef.current && invoiceNotesRef.current.getResult) {
                    Object.assign(result, invoiceNotesRef.current.getResult());
                }

                return result;
            },
            isError: () => refIsRequiredError(issuingTypeRef, donateRef, invoiceCarrierTypeRef, invoiceCarrierNoRef, triplicateUniformRef, invoiceNotesRef),
        }),
        // eslint-disable-next-line
        [],
    );

    useEffect(
        () => {
            if (invoiceIssuingTypeProps) setIssuingType(invoiceIssuingTypeProps);
        },
        // eslint-disable-next-line
        [invoiceIssuingTypeProps],
    );

    useEffect(
        () => {
            if (invoiceCarrierTypeProps) setInvoiceCarrierType(invoiceCarrierTypeProps);
        },
        // eslint-disable-next-line
        [invoiceCarrierTypeProps],
    );

    return (
        <Grid container spacing={3} className={clsx('invoice-card', className)}>
            <Grid xs={12} item>
                <InvoiceByIssuingType
                    ref={issuingTypeRef}
                    defaultValue={issuingType}
                    options={invoiceIssuingTypeOptions}
                    errorData={errorModule}
                    onChange={handleChangeIssuingType}
                    readOnly={readOnly}
                />
            </Grid>
            {String(issuingType) === issuingTypeMap.donate && (
                <Grid xs={12} item>
                    <InvoiceByDonate ref={donateRef} defaultValue={invoiceLoveCode} errorData={errorModule} readOnly={readOnly} />
                </Grid>
            )}
            {String(issuingType) === issuingTypeMap.duplicateUniform && (
                <React.Fragment>
                    <Grid xs={12} item>
                        <InvoiceByCarrierType
                            ref={invoiceCarrierTypeRef}
                            defaultValue={invoiceCarrierType}
                            options={invoiceCarrierTypeOptions}
                            errorData={errorModule}
                            onChange={handleChangeInvoiceCarrierType}
                            readOnly={readOnly}
                        />
                    </Grid>
                    {(String(invoiceCarrierType) === invoiceCarrierTypeMap.citizenDigitalCertificate1 ||
                        String(invoiceCarrierType) === invoiceCarrierTypeMap.mobile) && (
                        <Grid xs={12} item>
                            <InvoiceByCarrierNo
                                ref={invoiceCarrierNoRef}
                                defaultValue={invoiceCarrierNo}
                                invoiceCarrierType={invoiceCarrierType}
                                errorData={errorModule}
                                readOnly={readOnly}
                            />
                        </Grid>
                    )}
                </React.Fragment>
            )}
            {String(issuingType) === issuingTypeMap.triplicateUniform && (
                <InvoiceByTriplicateUniform
                    ref={triplicateUniformRef}
                    sourceData={{ taxTitle: invoiceTitle, taxID: invoiceTaxID }}
                    errorData={{
                        taxTitle: errorModule?.invoiceTitle,
                        taxID: errorModule?.invoiceTaxID,
                    }}
                    readOnly={readOnly}
                />
            )}
            {showInvoiceNotes && (
                <Grid xs={12} item>
                    <InvoiceByNotes ref={invoiceNotesRef} defaultValue={invoiceNotes} errorData={errorModule} readOnly={readOnly} />
                </Grid>
            )}
        </Grid>
    );
});

export default InvoiceCard;
