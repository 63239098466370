import React from 'react';
import SvgIcon from '../SvgIcon';
import useForkRef from '@util/hook/useForkRef';

const Bookmark = React.forwardRef((props, ref) => {
    const { className, ...others } = props;
    const handleRef = React.useRef(null);
    const svgRef = useForkRef(handleRef, ref);
    const defaultProperty = {
        className,
        ref: svgRef,
        ...others,
    };
    return (
        <SvgIcon {...defaultProperty}>
            <path
                d="M7,5.1V20c0,0.1,0.1,0.2,0.2,0.2c0,0,0.1,0,0.1,0l4.6-3.6
	c0.1-0.1,0.3-0.1,0.4,0l4.6,3.6c0.1,0.1,0.2,0,0.2,0c0,0,0-0.1,0-0.1V5.1c0-0.7-0.6-1.2-1.2-1.2c0,0,0,0,0,0H8.2
	C7.6,3.9,7,4.4,7,5.1C7,5.1,7,5.1,7,5.1z"
            />
        </SvgIcon>
    );
});

export default Bookmark;
