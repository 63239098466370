import React, { useEffect } from 'react';
import { NavLink, useParams, useHistory, Switch } from 'react-router-dom';
import OverviewContainer from '@icoach/members/overview/OverviewContainer';
import { Tabs, Tab, PrivateRoute, Box } from '@common/components/';
import { UserCircle as UserCircleIcon, Inbox as InboxIcon, GiftSolid as GiftSolidIcon } from '@common/SvgIcon/';
import {
    MEMBERS_MEMBER_OVERVIEW_NORMAL_SUBTABS,
    MEMBERS_MEMBER_OVERVIEW_QUICK_SUBTABS,
    MEMBERS_MEMBER_OVERVIEW_NORMAL,
    MEMBERS_MEMBER_OVERVIEW,
    MEMBERS_TABS_GIFT,
    MEMBERS_TABS_RENT_CABINET_URL,
    MEMBERS_TABS_GIFT_OVERVIEW_URL,
} from '@icoach/router/MembersRouter';
import { RentCabinetPage } from '@icoach/rentCabinet/';
import { GiftManagementPage } from '@icoach/gift/';

const TAB_MEMBER_OVERVIEW = 'member-overview';
const TAB_RENT_CABINET = 'rent-cabinet';
const TAB_GIFT = 'gift';

const MembersTabsHeader = (props) => {
    const { value: propsValue, handleTabsValue = () => {} } = props;

    const handleChange = (event, newValue) => {
        handleTabsValue && handleTabsValue(newValue);
    };

    return (
        <Box className="members-tabs page-tabs tabs-bottom-line">
            <Tabs value={propsValue} onChange={handleChange} variant="scrollable" allowScrollButtonsMobile>
                <Tab
                    className={'py-3'}
                    label={'會員總覽'}
                    icon={<UserCircleIcon />}
                    value={TAB_MEMBER_OVERVIEW}
                    component={NavLink}
                    to={MEMBERS_MEMBER_OVERVIEW}
                />
                <Tab className={'py-3'} label={'贈品管理'} icon={<GiftSolidIcon />} value={TAB_GIFT} component={NavLink} to={MEMBERS_TABS_GIFT_OVERVIEW_URL} />
                <Tab
                    className={'py-3'}
                    label={'租櫃管理'}
                    icon={<InboxIcon />}
                    value={TAB_RENT_CABINET}
                    component={NavLink}
                    to={MEMBERS_TABS_RENT_CABINET_URL}
                />
            </Tabs>
        </Box>
    );
};

const MembersPage = () => {
    const { tabs } = useParams();
    const history = useHistory();

    useEffect(
        () => {
            if (tabs === 'member-overview') {
                const splitPaths = history.location.pathname.split('/').filter((el) => el);
                const lastPath = splitPaths[splitPaths.length - 1];
                if (lastPath === tabs) history.push(MEMBERS_MEMBER_OVERVIEW_NORMAL);
            }
        },
        // eslint-disable-next-line
        [history.location],
    );

    return (
        <React.Fragment>
            {tabs && (
                <Box className="container main-container-spacing members-container">
                    <MembersTabsHeader value={tabs} />
                    <Switch>
                        <PrivateRoute path={MEMBERS_MEMBER_OVERVIEW_NORMAL_SUBTABS} as={OverviewContainer} exact />
                        <PrivateRoute path={MEMBERS_MEMBER_OVERVIEW_QUICK_SUBTABS} as={OverviewContainer} exact />
                        <PrivateRoute path={MEMBERS_TABS_GIFT} as={GiftManagementPage} exact />
                    </Switch>
                    {tabs === TAB_RENT_CABINET && <RentCabinetPage />}
                </Box>
            )}
        </React.Fragment>
    );
};

export default MembersPage;
