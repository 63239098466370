import React, { useLayoutEffect, useState, useRef } from 'react';
import {
    Close as CloseIcon,
    Print as PrintIcon,
    Download as DownloadIcon,
    MagnifyingGlassMinus as MagnifyingGlassMinusIcon,
    MagnifyingGlassPlus as MagnifyingGlassPlusIcon,
} from '@common/SvgIcon/';
import { IconButton, Stack, Box } from '@common/components/';
import usePrintApi from '@apis/usePrintApi';
import useAjax from '@apis/useAjax';
import useMessageDialog from '@util/hook/useMessageDialog';
import MeasureInformationBox from '@icoach/inbody/components/MeasureInformationBox';

const HeaderBar = (props) => {
    const {
        pdfUrl,
        onClose: onCloseProps = () => {},
        fileName,
        canDownload: canDownloadProps = true,
        canPrint: canPrintProps = true,
        canQrCode: canQrCodeProps = false,
        iframeHTMLRef: iframeHTMLRefProps,
        sourceData: sourceDataProps,
    } = props;
    const Ajax = useAjax();
    const { postPrintApi } = usePrintApi();
    const setMessageDialog = useMessageDialog();

    const handlePrinter = () => {
        setMessageDialog({
            open: true,
            title: '列印PDF',
            msg: '是否要列印？',
            onConfirm: doPrintApi,
        });
    };

    const handleOndownLoad = () => {
        if (!pdfUrl) {
            alert('尚無pdf路徑可供下載');
            return null;
        }
        downloadExcel();
    };

    const handleAdjustFontSize = (target) => {
        const { contentWindow } = iframeHTMLRefProps.current;
        const { document } = contentWindow;
        const transcriptPageDoms = document.querySelectorAll("[class*='-page']");
        const step = 0.1;
        const computedStyle = window.getComputedStyle(transcriptPageDoms[0]);
        // 获取 transform 属性
        const transformValue = computedStyle.getPropertyValue('transform');

        // 解析 transform 属性中的 scale 值
        let scaleValue = 1; // 默认值为 1
        if (transformValue !== 'none') {
            const match = transformValue.match(/matrix\(([^,]+),[^,]+,[^,]+,[^,]+,[^,]+,[^,]+\)/);
            if (match) {
                scaleValue = parseFloat(match[1]);
            }
        }
        let updatedScale = 1;
        if (target === 'zoomin') {
            updatedScale = scaleValue + step;
            if (updatedScale > 1) updatedScale = 1;
        } else if (target === 'zoomout') {
            updatedScale = scaleValue - step;
            if (updatedScale < 0.5) updatedScale = 0.5;
        }
        transcriptPageDoms.forEach((element) => {
            element.style.transform = `scale(${updatedScale})`;
        });
    };

    const downloadExcel = async () => {
        await Ajax.getPDF(pdfUrl, '', { fileName: fileName });
    };

    const doPrintApi = async () => {
        if (!pdfUrl) {
            alert('尚無pdf路徑可供下載');
            return null;
        }
        await postPrintApi(pdfUrl);
    };
    
    return (
        <Box
            style={{
                alignItems: 'center',
                backgroundColor: '#eeeeee',
                borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
                display: 'flex',
                padding: '4px',
            }}
        >
            <Stack>
                <IconButton onClick={() => handleAdjustFontSize('zoomout')}>
                    <MagnifyingGlassMinusIcon />
                </IconButton>
                <IconButton onClick={() => handleAdjustFontSize('zoomin')}>
                    <MagnifyingGlassPlusIcon />
                </IconButton>
            </Stack>
            <Stack className="ml-auto" spacing={2}>
                {canDownloadProps && (
                    <IconButton onClick={handleOndownLoad}>
                        <DownloadIcon />
                    </IconButton>
                )}
                {canPrintProps && (
                    <IconButton onClick={handlePrinter}>
                        <PrintIcon />
                    </IconButton>
                )}
                {canQrCodeProps && <MeasureInformationBox sourceData={sourceDataProps} />}
                <IconButton onClick={onCloseProps}>
                    <CloseIcon />
                </IconButton>
            </Stack>
        </Box>
    );
};

const HTMLViewPdfComponent = React.forwardRef((props, ref) => {
    const {
        htmlUrl = '',
        pdfUrl = '',
        onClose: onCloseProps,
        fileName,
        canDownload: canDownloadProps = true,
        canPrint: canPrintProps = true,
        canQrCode: canQrCodeProps = false,
        sourceData: sourceDataProps,
    } = props;
    const divFrameRef = useRef(null);
    const iframeHTMLRef = useRef(null);
    const [width, setWidth] = useState('100%');

    const obseverWidth = (entries) => {
        const { contentRect } = entries[0];
        setWidth(contentRect.height);
    };

    useLayoutEffect(
        () => {
            const detectionDiv = new ResizeObserver(obseverWidth);
            detectionDiv.observe(divFrameRef.current);
        },
        // eslint-disable-next-line
        []
    );

    return (
        <Box
            className="rpv-core-viewer"
            style={{
                border: '1px solid rgba(0, 0, 0, 0.3)',
                height: '100%',
            }}
        >
            <Box
                style={{
                    backgroundColor: '#eeeeee',
                    borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
                    padding: '4px',
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                }}
            >
                <HeaderBar
                    pdfUrl={pdfUrl}
                    onClose={onCloseProps}
                    fileName={fileName}
                    canDownload={canDownloadProps}
                    canPrint={canPrintProps}
                    canQrCode={canQrCodeProps}
                    iframeHTMLRef={iframeHTMLRef}
                    sourceData={sourceDataProps}
                />
                <Box
                    style={{
                        flex: 1,
                        overflow: 'hidden',
                        background: '#fff',
                    }}
                    ref={divFrameRef}
                >
                    <iframe title={fileName || 'undefined'} ref={iframeHTMLRef} id="htmlView" width={'100%'} height={width} src={htmlUrl}></iframe>
                </Box>
            </Box>
        </Box>
    );
});

export default HTMLViewPdfComponent;
