import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import clsx from 'clsx';
import { IconButton, ListItemIcon, ListItemText, Menu, MenuItem } from '@common/components/';
import { MoreVertical as MoreVerticalIcon } from '@common/SvgIcon/';

const MoreLabelBox = (props) => {
    const {
        refresh,
        moreLabelMap,
        moreActionArray = [],
        className,
        peopleID = '',
        type: propsType,
        urls: propsUrl,
        textAlign,
        buttonProps,
        sourceData: sourceDataProps,
        ...others
    } = props;

    const [anchorEl, setAnchorEl] = useState(null);
    const [dialogOpen, setDialogOpen] = useState(null);
    const open = Boolean(anchorEl);

    const handleMoreIconClick = (e) => {
        setAnchorEl(e.currentTarget);
    };

    const handleClose = (target) => {
        if (target) setDialogOpen(target);
        setAnchorEl(null);
    };

    const handleDialogClose = (e) => {
        setDialogOpen(null);
        setAnchorEl(null);
    };

    return (
        <React.Fragment>
            <IconButton {...buttonProps} onClick={handleMoreIconClick}>
                <MoreVerticalIcon />
            </IconButton>
            {moreActionArray.length > 0 && (
                <React.Fragment>
                    <Menu
                        className={clsx('more-label-box-menu', Boolean(textAlign) && `align-${textAlign}`)}
                        anchorEl={anchorEl}
                        open={open}
                        onClose={(e) => handleClose(null)}
                    >
                        {moreActionArray.map((target) => {
                            if (!moreLabelMap[target]) return null;
                            const { text, icon, to, dialogString, onClickEvent, Dialog } = moreLabelMap[target];
                            let menuProperty = {};
                            if (to) {
                                menuProperty.to = to({ urls: propsUrl });
                                menuProperty.component = NavLink;
                            } else if (onClickEvent) {
                                menuProperty.onClick = (e) => {
                                    handleClose(null);
                                    onClickEvent && onClickEvent(e);
                                };
                            } else if (Dialog) {
                                menuProperty.onClick = (e) => handleClose(dialogString);
                            }
                            return (
                                <MenuItem {...menuProperty} key={`more_label_${text}`}>
                                    {icon && <ListItemIcon>{icon}</ListItemIcon>}
                                    <ListItemText>{text}</ListItemText>
                                </MenuItem>
                            );
                        })}
                    </Menu>
                    {moreActionArray.map((target, _index) => {
                        if (!moreLabelMap[target]) return null;
                        const { dialogString, Dialog } = moreLabelMap[target];
                        const sourceData = dialogOpen === dialogString ? [{ ...sourceDataProps }] : [];
                        return (
                            <React.Fragment key={`more_dailog_${_index}`}>
                                {!!Dialog && (
                                    <Dialog
                                        refresh={refresh}
                                        open={dialogOpen === dialogString}
                                        onClose={handleDialogClose}
                                        sourceData={sourceData}
                                        type={propsType}
                                        peopleID={peopleID}
                                        {...others}
                                    />
                                )}
                            </React.Fragment>
                        );
                    })}
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

export default MoreLabelBox;
