import React from 'react';
import SvgIcon from '../SvgIcon';
import useForkRef from '@util/hook/useForkRef';

const Renew = React.forwardRef((props, ref) => {
    const { className, htmlColor = '#6E488B', ...others } = props;
    const handleRef = React.useRef(null);
    const svgRef = useForkRef(handleRef, ref);
    const defaultProperty = {
        className,
        ref: svgRef,
        htmlColor,
        ...others,
    };
    return (
        <SvgIcon {...defaultProperty}>
            <g>
                <rect x="7.6" y="4.7" width="1.2" height="1.1" />
                <rect x="5.9" y="4.7" width="1.2" height="1.1" />
                <rect x="11" y="4.6" width="6.9" height="1.3" />
                <path
                    d="M12,0.1c-6.6,0-12,5.4-12,12s5.4,12,12,12s12-5.4,12-12S18.6,0.1,12,0.1z M16.6,21.3c-1,0-1.9-0.4-2.5-1.1H5V4
		h13.7H19v11.3c0.7,0.6,1.1,1.5,1.1,2.5C20.1,19.8,18.5,21.3,16.6,21.3z"
                />
                <path
                    d="M6,19.3h7.4c-0.2-0.4-0.3-0.9-0.3-1.4c-1.8,0-4.6,0-4.7,0c-0.1,0-0.2-0.1-0.2-0.3c0.1-0.7,0.5-2.9,1.7-3.6
		c-0.8-0.5-1.3-1.5-1.3-2.5c0-1.7,1.3-3,3-3c0.6,0,1.2,0.2,1.6,0.5c0.1-0.3,0.5-0.6,0.9-0.6c0.4,0,0.7,0.2,0.9,0.5l0,0c0,0,0,0,0,0
		c0,0,0,0,0,0c0.1,0.2,0.4,0.9,0.3,2c0,0-0.1,0.5,0.5,0.6c0,0-0.6,0.5-1.2,0h0c0,1-0.6,1.9-1.4,2.4c0.4,0.3,0.7,0.8,1,1.3
		c0.6-0.6,1.4-0.9,2.4-0.9c0.5,0,1,0.1,1.4,0.3V6.6H6V19.3z"
                />
                <path
                    d="M13.8,10.3h-1.7l-0.5-0.9l-0.5,0.9H9.4c-0.2,0.4-0.3,0.8-0.3,1.2c0,1.4,1.1,2.5,2.5,2.5c1.4,0,2.5-1.1,2.5-2.5
		C14.2,11.1,14,10.7,13.8,10.3z"
                />
            </g>
            <path
                d="M15.1,18c-0.1,0-0.3,0-0.4,0.1c-0.1-0.6,0.1-1.2,0.5-1.6c0.6-0.6,1.5-0.7,2.2-0.3c0.1,0.1,0.3,0,0.4-0.1
	c0,0,0,0,0.1-0.1c0.1-0.2,0-0.4-0.1-0.5c-0.5-0.3-1-0.4-1.6-0.3c-0.6,0.1-1.1,0.3-1.5,0.7c-0.4,0.4-0.7,0.9-0.7,1.5
	c0,0.3,0,0.6,0,0.9c-0.1,0-0.3,0-0.4,0.2c-0.1,0.2,0,0.4,0.1,0.5l0.6,0.4c0.1,0.1,0.2,0.1,0.4,0v0c0.1,0,0.1-0.1,0.1-0.1
	c0,0,0.1-0.1,0.1-0.2l0.3-0.7C15.4,18.3,15.3,18.1,15.1,18z"
            />
            <path
                d="M19.4,16.7L19.4,16.7l-0.6-0.4c-0.1-0.1-0.2-0.1-0.4,0c-0.1,0-0.2,0.1-0.3,0.3l-0.2,0.8c-0.1,0.2,0,0.4,0.2,0.4
	c0.1,0,0.3,0,0.4-0.1c0.1,0.6-0.1,1.2-0.5,1.6c-0.6,0.6-1.5,0.7-2.2,0.3c-0.2-0.1-0.4,0-0.5,0.1c-0.1,0.2,0,0.4,0.1,0.5
	c0.5,0.3,1,0.4,1.6,0.3c0.6-0.1,1.1-0.3,1.5-0.7c0.4-0.4,0.7-0.9,0.7-1.5c0-0.3,0-0.6,0-0.9c0.1,0,0.2,0,0.3-0.1c0,0,0,0,0.1-0.1
	C19.6,17.1,19.6,16.8,19.4,16.7z"
            />
        </SvgIcon>
    );
});

export default Renew;
