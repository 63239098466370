import React, { useState, useRef } from 'react';
import { Grid } from '@mui/material';
import { InvoiceCard } from '@icoach/accountBook/AccountBookCreateOrderPage';
import { Box, Button, Typography } from '@common/components/';
import { refIsRequiredError } from '@util/utils';

const InvoiceTestPage = React.forwardRef((props, ref) => {
    const [showParams, setShowParams] = useState('');
    const invoiceInfoRef = useRef(null);

    const handleGetParams = () => {
        if (!refIsRequiredError(invoiceInfoRef)) {
            const params = invoiceInfoRef.current.getResult();
            setShowParams(JSON.stringify(params));
        } else {
            setShowParams('');
        }
    };

    return (
        <Box className="my-3 mx-2">
            <Grid container>
                <Grid item xs={6}>
                    <InvoiceCard ref={invoiceInfoRef} showEmail isSyncEmailCarrier />
                </Grid>
                <Grid item xs={12}>
                    <Box className="my-2">
                        <Button variant={'contained'} onClick={handleGetParams}>
                            讀取參數
                        </Button>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Typography className="word-break-break-word">{showParams}</Typography>
                </Grid>
            </Grid>
        </Box>
    );
});

export default InvoiceTestPage;
