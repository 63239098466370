import React, { useImperativeHandle, useState } from 'react';
import useShowPDF from '@util/hook/useShowPDF';
import useInBody from '@icoach/inbody/components/useInBody';
import InbodyXFactorDialog from '@icoach/inbody/components/InBodyXFactorDialog';
import InbodyAgreedNotesDialog from '@icoach/inbody/components/InBodyAgreedNotesDialog';
import InbodyDeleteMeasureDialog from '@icoach/inbody/components/InBodyDeleteMeasureDialog';
import InbodyInterviewNoteDialog from '@icoach/inbody/components/InBodyInterviewNoteDialog';
import useInBodyApi from '@apis/useInBodyApi';
import MemberSiteQrCodeDialog from '@icoach/inbody/interview/MemberSiteQrCodeDialog';

// table 重複元件使用(單一來源彈跳視窗)，如果只有一個元件在使用就不用寫在這
const InBodyInterviewDialogBox = React.forwardRef((props, ref) => {
    const showPDF = useShowPDF();
    const [openDialog, setOpenDialog] = useState(null);
    const [sourceData, setSourceData] = useState([]);
    const [measureID, setMeasureID] = useState(null);
    const { getInterviewMemberMeasureApi } = useInBodyApi();
    const { setDialogRef, peopleID, setSourceData: setMeasureData, options } = useInBody();

    useImperativeHandle(
        setDialogRef,
        () => {
            return {
                openXFactorDialog: (targetData, targetID) => {
                    setSourceData(targetData);
                    setMeasureID(targetID);
                    setOpenDialog('xFactor');
                },
                openAgreedNotesDialog: (targetData, targetID) => {
                    setSourceData(targetData);
                    setMeasureID(targetID);
                    setOpenDialog('agreedNotes');
                },
                openDeleteMeasureDialog: (targetData, targetID) => {
                    setSourceData(targetData);
                    setMeasureID(targetID);
                    setOpenDialog('deleteMeasure');
                },
                openInterviewNotesDialog: (targetData, targetID) => {
                    setSourceData(targetData);
                    setMeasureID(targetID);
                    setOpenDialog('interviewNotes');
                },
                openViewPdfDialog: (pdfUrl) => {
                    showPDF({
                        open: true,
                        pdfUrl: pdfUrl,
                    });
                },
                openMemberSiteQrCodeDialog: (targetData) => {
                    setSourceData(targetData);
                    setOpenDialog('memberSiteQrCode');
                },
                closeDialog: () => handleCloseDialog(),
            };
        },
        // eslint-disable-next-line
        [],
    );

    const handleCloseDialog = () => {
        setOpenDialog(false);
        setMeasureID(null);
        setSourceData([]);
    };

    const doRefreshApi = async () => {
        const resp = await getInterviewMemberMeasureApi(peopleID);
        if (resp) setMeasureData((preData) => ({ ...preData, ...resp }));
    };
    // 以下給的property都是可選(除了open)，*****不是一定要這樣給*****，依照自己的需求給
    return (
        <React.Fragment>
            <InbodyXFactorDialog
                open={openDialog === 'xFactor'}
                onClose={handleCloseDialog}
                sourceData={sourceData}
                memberID={peopleID}
                measureID={measureID}
                refresh={doRefreshApi}
            />
            <InbodyAgreedNotesDialog
                open={openDialog === 'agreedNotes'}
                onClose={handleCloseDialog}
                sourceData={sourceData}
                memberID={peopleID}
                measureID={measureID}
                refresh={doRefreshApi}
            />
            <InbodyDeleteMeasureDialog
                open={openDialog === 'deleteMeasure'}
                onClose={handleCloseDialog}
                sourceData={sourceData}
                measureID={measureID}
                refresh={doRefreshApi}
            />
            <InbodyInterviewNoteDialog
                open={openDialog === 'interviewNotes'}
                onClose={handleCloseDialog}
                sourceData={sourceData}
                measureID={measureID}
                refresh={doRefreshApi}
                option={options.employeesOptions || []}
            />
            <MemberSiteQrCodeDialog open={openDialog === 'memberSiteQrCode'} onClose={handleCloseDialog} sourceData={sourceData} refresh={doRefreshApi} />
        </React.Fragment>
    );
});

export default InBodyInterviewDialogBox;
