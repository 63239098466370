import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import { Grid } from '@mui/material';
import useInBody from '@icoach/inbody/components/useInBody';
import { INBODY_RECORD_ROUTER, INBODY_INTERVIEW_ROUTER } from '@icoach/router/routerPath';
import { Card, AutocompleteField, Form, Button, Stack } from '@common/components/';
import useInBodyApi from '@apis/useInBodyApi';
import { refIsRequiredError } from '@util/utils';

const InBodyHistorySearchBar = React.forwardRef((props, ref) => {
    const { className, referenceID } = props;
    const { setSourceData } = useInBody();
    const { getHistoryOptionsApi, getSearchHistoryApi } = useInBodyApi();
    const history = useHistory();
    const [isMounted, setMounred] = useState(false);
    const [isLock, setLock] = useState(false);
    const [options, setOptions] = useState([]);
    const searchInputRef = useRef(null);

    const getAllParams = () => {
        return {
            referenceID: searchInputRef.current.getResult('id'),
            type: 2,
        };
    };

    const handleSearchOnClick = () => {
        if (!refIsRequiredError(searchInputRef)) {
            const params = getAllParams();
            doSearchApi(params, params.referenceID);
        }
    };

    const handleInterviewOnClick = () => {
        if (!refIsRequiredError(searchInputRef)) {
            const referenceID = searchInputRef.current.getResult('id');
            history.push(`${INBODY_INTERVIEW_ROUTER}${referenceID}`);
        }
    };

    const handleResetSearch = () => {
        setLock(false);
        history.replace(`${INBODY_RECORD_ROUTER}`);
        setSourceData(null);
    };

    const doSearchApi = async (params, id) => {
        const resp = await getSearchHistoryApi(params);
        setSourceData(resp);
        if (id) history.replace(`${INBODY_RECORD_ROUTER}${id}`);
        setLock(true);
    };

    const initOptionsApi = async () => {
        const resp = await getHistoryOptionsApi();
        if (resp) setOptions(resp);
        if (referenceID)
            await doSearchApi({
                referenceID: referenceID,
                type: 2,
            });
        setMounred(true);
    };

    useEffect(
        () => {
            if (options.length === 0) initOptionsApi();
        },
        // eslint-disable-next-line
        []
    );

    return (
        <Card className={clsx('search-bar', className)}>
            {isMounted && (
                <Card.CardContent>
                    <Form onSubmit={handleSearchOnClick}>
                        <div className="px-4 py-3">
                            <Grid container spacing={3}>
                                <Grid item xs={8}>
                                    <AutocompleteField
                                        key={isLock}
                                        ref={searchInputRef}
                                        direction={'row'}
                                        defaultValue={referenceID}
                                        options={options}
                                        optionKeys={['name', 'mobile', 'no']}
                                        primaryKey={'id'}
                                        label={'姓名/手機/編號'}
                                        InputProps={{
                                            placeholder: '請輸入姓名、手機、編號 進行搜索',
                                            disabled: isLock,
                                        }}
                                        fullWidth
                                        required
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <div>
                                        {isLock ? (
                                            <Button name="reset" color="secondary" variant="contained" onClick={handleResetSearch}>
                                                重設
                                            </Button>
                                        ) : (
                                            <Stack spacing={2}>
                                                <Button type="submit" color="secondary" variant="outlined">
                                                    查詢歷史
                                                </Button>
                                                <Button onClick={handleInterviewOnClick} color="secondary" variant="contained">
                                                    開始面談
                                                </Button>
                                            </Stack>
                                        )}
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                    </Form>
                </Card.CardContent>
            )}
        </Card>
    );
});

export default InBodyHistorySearchBar;
