import React, { useRef, useState, useEffect, useLayoutEffect } from 'react';
import { v4 as uuid } from 'uuid';
import clsx from 'clsx';
import { Grid } from '@mui/material';
import { Box, Stack, Typography, TextField, IconButton } from '@common/components/';
import { AngleDown as AngleDownIcon, AngleUp as AngleUpIcon } from '@common/SvgIcon/';
import useMessageDialog from '@util/hook/useMessageDialog';
import useAppointmentApi from '@apis/useAppointmentApi';
import useShowLoading from '@util/hook/useShowLoading';

const AppointmentDailyMemo = (props) => {
    const { date } = props;
    const [sourceData, setSourceData] = useState({ memo: '', otherTexts: [] });
    const [isMounted, setMounted] = useState(false);
    const [showArrow, setShowArrow] = useState(false);
    const [memoToggle, setMomoToggle] = useState(false);
    const memoRef = useRef(null);
    const memoBoxRef = useRef(null);
    const { getDailyMemoApi, postDailyMemoApi } = useAppointmentApi();
    const setMessageDialog = useMessageDialog();
    const showLoading = useShowLoading();

    const getAllParams = () => {
        return {
            scheduleDate: date,
            memo: memoRef.current.getResult(),
        };
    };

    const handleMemoOnClick = () => {
        setMessageDialog({
            open: true,
            title: `設定 ${date} 備註`,
            buttonText: `送出`,
            MsgComp: () => <TextField ref={memoRef} label="備註" defaultValue={sourceData.memo} rows={6} multiline fullWidth />,
            onAsyncConfirm: (_, onClose) => setMemoApi(getAllParams(), onClose),
        });
    };

    const setMemoApi = async (params, onClose) => {
        showLoading(true);
        const resp = await postDailyMemoApi(params);
        if (resp) {
            await getInitData(date);
            onClose();
        }
        showLoading(false);
    };

    const getInitData = async (params) => {
        const resp = await getDailyMemoApi(params);
        if (resp) {
            setSourceData(resp);
        }
        setMounted(true);
    };

    const handleToggleClick = () => {
        setMomoToggle((prev) => !prev);
    };

    useEffect(
        () => {
            getInitData(date);
        },
        // eslint-disable-next-line
        [date]
    );

    useEffect(
        () => {
            if (showArrow) {
                setMomoToggle(false);
            }
        },
        // eslint-disable-next-line
        [showArrow]
    );

    useLayoutEffect(
        () => {
            if (isMounted) {
                const listenWidth = new ResizeObserver((entries) => {
                    const { height } = entries[0]['contentRect'];
                    if (height && height > 110) {
                        setShowArrow(true);
                    } else {
                        setShowArrow(false);
                    }
                });
                listenWidth.observe(memoBoxRef.current);
            }
        },
        // eslint-disable-next-line
        [isMounted]
    );

    if (!isMounted) return null;
    const { memo = '', otherTexts = [] } = sourceData || {};

    return (
        <Box className="daily-memo py-1 px-2" ref={memoBoxRef}>
            <Grid container>
                <Grid item xs={6} className="cursor-pointer" onClick={handleMemoOnClick}>
                    <Stack>
                        <Typography variant="h6" className="flex-shrink-0">
                            備註：
                        </Typography>
                        <Typography component="p" className={clsx('word-break-break-word', 'daily-memo-text', !memoToggle && 'dialy-memo-text-toggle')}>
                            {memo}
                        </Typography>
                    </Stack>
                </Grid>
                <Grid item xs={6}>
                    <Stack>
                        <Typography variant="h6" className="flex-shrink-0">
                            其他：
                        </Typography>
                        <Box className={clsx('daily-memo-text', !memoToggle && 'dialy-memo-text-toggle')} component="ul">
                            {otherTexts.map((_text, _index) => (
                                <Box key={`${_index}_${_text}` || uuid()} component="li">
                                    {_text}
                                </Box>
                            ))}
                        </Box>
                    </Stack>
                </Grid>
            </Grid>
            {showArrow && (
                <Box className="daily-memo-toggle">
                    <IconButton onClick={handleToggleClick} size="small">
                        {memoToggle ? <AngleUpIcon /> : <AngleDownIcon />}
                    </IconButton>
                </Box>
            )}
        </Box>
    );
};

export default AppointmentDailyMemo;
