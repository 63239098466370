import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { Dialog, DialogActions, DialogContent } from '@mui/material';
import { Button, MenuItem, SelectField } from '../../../components';
import { UploadBox, UploadFeedbackBox } from '../../components';
import GradientColorHeader from '../../components/dialog/GradientColorHeader';
// import useCabinetApi from '../../../apis/useCabinetApi';
import { refIsRequiredError } from '../../../util/utils';
import useEmployeeApi from '../../../apis/useEmployeeApi';

const getInitFeedbackData = () => {
    return {
        isShow: false,
        isError: false,
        data: {},
    };
};

const UploadFileDialog = (props) => {
    const {
        className,
        title = '批次新增',
        open,
        refresh,
        postImportApi,
        fileExtension: fileExtensionProps = 'xlsx',
        isTemplate = false,
        getFileTemplate,
        onClose,
    } = props;
    const { getEmployeesOptionsApi } = useEmployeeApi();
    const [feedbackData, setFeedBackData] = useState(getInitFeedbackData());
    const [options, setOptions] = useState({});
    const { employeeOption } = options;
    const uploadFileRef = useRef();
    const employeeRef = useRef();

    const doEmployeesOptionsApi = async () => {
        let res = await getEmployeesOptionsApi();
        if (res) {
            setOptions(res);
        }
    };

    const doImportApi = async (formData, params) => {
        let res = await postImportApi(formData, params);
        if (res) {
            if (res.isError) {
                const { isError, result } = res;
                setFeedBackData({
                    isShow: true,
                    isError,
                    data: result,
                });
            } else {
                refresh && refresh();
                onClose();
            }
        }
    };

    const getParams = () => {
        let result = {};
        if (employeeRef.current && employeeRef.current.getResult) {
            result = Object.assign(result, { employeeID: employeeRef.current.getResult() });
        }
        return result;
    };

    const checkError = () => refIsRequiredError(employeeRef);

    const handleFileChange = (file) => {
        if (file) uploadFileRef.current = file;
    };

    const handleSubmit = () => {
        const isError = checkError();
        if (!isError) {
            const formData = new FormData();
            const { employeeID } = getParams();
            if (uploadFileRef.current) {
                let file = uploadFileRef.current;
                formData.append('file', file, file.name);
                doImportApi(employeeID, formData);
            }
        }
    };

    const getFileExtension = (fileExtension) => {
        if (typeof fileExtension === 'string') {
            fileExtension.replace(' ', '');
            fileExtension = fileExtension.split(',');
        } else if (!Array.isArray(fileExtension)) {
            fileExtension = '';
        }
        return fileExtension;
    };

    useEffect(() => {
        if (open) {
            setFeedBackData(getInitFeedbackData());
            doEmployeesOptionsApi();
        }
        // eslint-disable-next-line
    }, [open]);

    if (!open) return null;

    return (
        <Dialog
            className={clsx('simple-content', 'dialog', className)}
            PaperProps={{ className: 'wx-65rem' }}
            open={open}
            fullWidth
        >
            <GradientColorHeader onClose={onClose}>{title}</GradientColorHeader>
            <DialogContent>
                {!feedbackData.isError ? (
                    <UploadBox
                        className={'import-ach-box'}
                        onFileChange={handleFileChange}
                        fileExtension={getFileExtension(fileExtensionProps)}
                    />
                ) : (
                    <UploadFeedbackBox
                        isShow={feedbackData.isShow}
                        className={'import-ach-box'}
                        isError={feedbackData.isError}
                        {...feedbackData.data}
                        onCononConfirmfirm={onClose}
                        onDownload={onClose}
                    />
                )}
                <SelectField label={'擔當'} ref={employeeRef} fullWidth required>
                    {Array.isArray(employeeOption) &&
                        employeeOption
                            .filter((item) => item || !item.disabled)
                            .map((item) => (
                                <MenuItem key={item.value} value={item.value}>
                                    {item.text}
                                </MenuItem>
                            ))}
                </SelectField>
            </DialogContent>
            {!feedbackData.isShow && (
                <DialogActions>
                    {isTemplate && (
                        <Button className={'mr-auto'} variant="outlined" onClick={getFileTemplate}>
                            下載excel範本
                        </Button>
                    )}
                    <Button variant="contained" onClick={handleSubmit}>
                        確認匯入
                    </Button>
                </DialogActions>
            )}
        </Dialog>
    );
};

export default UploadFileDialog;
