import React from 'react';
import Backdrop from '../backdrop/Backdrop';
import ModalUnstyled from './ModalUnstyled';
import clsx from 'clsx';

const staticClass = `modal`;

const ModalRoot = React.forwardRef((props, ref) => {
    const { ...other } = props;
    return <div ref={ref} {...other} />;
});

const Modal = React.forwardRef((props, ref) => {
    const {
        BackdropComponent = Backdrop,
        closeAfterTransition = false,
        children,
        className,
        disableAutoFocus = false,
        disableEnforceFocus = false,
        disableEscapeKeyDown = false,
        disablePortal = false,
        disableRestoreFocus = false,
        disableScrollLock = false,
        hideBackdrop = false,
        keepMounted = false,
        ...other
    } = props;
    let classNames = staticClass;
    const [exited, setExited] = React.useState(true);
    if (exited) classNames = clsx(classNames, `${staticClass}-exited`);

    const defaultProperty = {
        className: clsx(classNames, className),
        closeAfterTransition,
        disableAutoFocus,
        disableEnforceFocus,
        disableEscapeKeyDown,
        disablePortal,
        disableRestoreFocus,
        disableScrollLock,
        hideBackdrop,
        keepMounted,
        BackdropComponent,
        ref,
    };

    return (
        <ModalUnstyled
            components={{
                Root: ModalRoot,
            }}
            onTransitionEnter={() => setExited(false)}
            onTransitionExited={() => setExited(true)}
            {...other}
            {...defaultProperty}
        >
            {children}
        </ModalUnstyled>
    );
});

export default Modal;
