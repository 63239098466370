import useAjax from '@apis/useAjax';
import useShowPDF from '@util/hook/useShowPDF';
import { checkResponse } from '@apis/utils';

const GET_DOC_MEMBERSHIP_OPTIONS_API = `/api/doc/membership/contracts/options/`;
const GET_DOC_MEMBERSHIP_HISTORY_API = `/api/doc/membership/contracts/history/`;
const GET_DOC_MEMBERSHIP_ID_API = (contractID) => `/api/doc/membership/contracts/${contractID}/`;
const POST_DOC_MEMBERSHIP_ID_API = GET_DOC_MEMBERSHIP_ID_API;
const DELETE_DOC_MEMBERSHIP_ID_API = GET_DOC_MEMBERSHIP_ID_API;
const POST_DOC_MEMBERSHIP_RANGE_FEE_API = `/api/doc/membership/contracts/range-fee/`;
const GET_DOC_MEMBERSHIP_TEMPORARY_API = `/api/doc/membership/contracts/temporary/`;
const POST_DOC_MEMBERSHIP_TEMPORARY_API = GET_DOC_MEMBERSHIP_TEMPORARY_API;
const DELETE_DOC_MEMBERSHIP_TEMPORARY_API = GET_DOC_MEMBERSHIP_TEMPORARY_API;
const GET_DOC_MEMBERSHIP_TEMPLATE_PDF_API = `/api/doc/membership/contracts/template.pdf`;
const GET_DOC_MEMBERSHIP_CONTRACTS_CONTRACTIDID_CAN_DELETE_API = (contractID) => `/api/doc/membership/contracts/${contractID}/can-delete`;

const useDocMembershipApi = () => {
    const Ajax = useAjax();
    const showPDF = useShowPDF();

    // 取得入會申請書的下拉式選單
    const getDocMembershipOptionApi = async () => {
        const resp = await Ajax.get(GET_DOC_MEMBERSHIP_OPTIONS_API);
        return resp;
    };

    // 取得會員歷史紀錄(包含暫存)
    const getDocMembershipHistoryApi = async (params) => {
        const resp = await Ajax.get(GET_DOC_MEMBERSHIP_HISTORY_API, params);
        return resp;
    };

    // 取得入會會籍申請書內容
    const getDocMembershipApi = async (contractID, params) => {
        const resp = await Ajax.get(GET_DOC_MEMBERSHIP_ID_API(contractID), params);
        return resp;
    };

    // 新增/重填入會合約
    const postDocMembershipApi = async (contractID = 0, parameters) => {
        const resp = await Ajax.post(POST_DOC_MEMBERSHIP_ID_API(contractID), parameters);
        return resp;
    };

    // 刪除入會申請書
    const deleteDocMembershipApi = async (params, contractID) => {
        const resp = await Ajax.delete(DELETE_DOC_MEMBERSHIP_ID_API(contractID), params);
        return resp;
    };

    // 依輸入的內容重新計算日期及金額結果
    const postDocMembershipRangeFeeApi = async (parameters) => {
        const resp = await Ajax.post(POST_DOC_MEMBERSHIP_RANGE_FEE_API, parameters);
        return resp;
    };

    // 確認來源對象是否有暫存合約
    const getDocMembershipTemporaryApi = async (params) => {
        const resp = await Ajax.get(GET_DOC_MEMBERSHIP_TEMPORARY_API, params);
        return resp;
    };

    // 暫存入會申請書合約
    const postDocMembershipTemporaryApi = async (parameters) => {
        const resp = await Ajax.post(POST_DOC_MEMBERSHIP_TEMPORARY_API, parameters);
        return resp;
    };

    // 刪除來源對象的暫存合約
    const deleteDocMembershipTemporaryApi = async (parameters) => {
        const resp = await Ajax.delete(DELETE_DOC_MEMBERSHIP_TEMPORARY_API, parameters);
        return resp;
    };

    // 檢查是否可以刪除 正式文書
    const getCheckDocMembershipCanDeleteApi = async (contractID) => {
        const resp = await Ajax.get(GET_DOC_MEMBERSHIP_CONTRACTS_CONTRACTIDID_CAN_DELETE_API(contractID));
        return checkResponse(resp);
    };

    // 下載空白PDF文件
    const getDocMembershipTemplatePDFApi = async () => {
        showPDF({
            open: true,
            pdfUrl: GET_DOC_MEMBERSHIP_TEMPLATE_PDF_API,
        });
    };

    // 下載合約申請書(非空白)
    const getDocMembershipPDFApi = async (contractUrl) => {
        showPDF({
            open: true,
            pdfUrl: contractUrl,
        });
    };

    return {
        getDocMembershipOptionApi,
        getDocMembershipHistoryApi,
        getDocMembershipApi,
        postDocMembershipApi,
        deleteDocMembershipApi,
        postDocMembershipRangeFeeApi,
        getDocMembershipTemporaryApi,
        postDocMembershipTemporaryApi,
        deleteDocMembershipTemporaryApi,
        getDocMembershipTemplatePDFApi,
        getDocMembershipPDFApi,
        getCheckDocMembershipCanDeleteApi,
    };
};

export default useDocMembershipApi;
