import React, { useState } from 'react';
import { useParams } from 'react-router';
import Tablenization from '@icoach/components/Tablenization';
import { Card, Box, Button, Typography } from '@common/components/';
import { ConfirmDeleteDocDialog } from '@icoach/documents/membership/dialog';
import useDocHealthDeclarationApi from '@apis/useDocHealthDeclarationApi';
import { dialogTypeMap } from '@icoach/documents/membership/staticData';
import moreColData from '@icoach/documents/components/moreColData';
import useToggleDialog from '@util/hook/useToggleDialog';

const HealthDeclarationHistoryTable = ({
    thSource,
    source = [],
    onCreateEvent,
    onEditEvent,
    onDeleteEvent,
    onReadEvent,
    onDownloadEvent,
    getInitPeopleRecordOverview,
}) => {
    const { peopleType: peopleTypeProps, referenceID: referenceIDProps } = useParams();
    const { deleteDocHealthDeclarationContractApi, deleteDocHealthDeclarationTemporaryApi } = useDocHealthDeclarationApi();
    const [thData] = useState(() => {
        if (!Array.isArray(thSource) || thSource.length < 1) return [];
        return [...thSource, moreColData];
    });
    const { openTarget, handleOpenDialog, handleCloseDialog, rowData } = useToggleDialog();

    const handleOnCreate = () => {
        onCreateEvent && onCreateEvent();
    };

    const handleOnEdit = (rowData) => {
        onEditEvent && onEditEvent(rowData.contractID);
    };

    const handleOnRead = (rowData) => {
        onDeleteEvent && onReadEvent(rowData);
    };

    // 刪除暫停合約資料
    const doDeleteApi = async (rowData) => {
        const { isTemporary, contractID } = rowData;
        let res;
        if (isTemporary) {
            res = await deleteDocHealthDeclarationTemporaryApi({
                type: peopleTypeProps,
                id: referenceIDProps,
            });
        } else {
            res = await deleteDocHealthDeclarationContractApi(contractID);
        }
        return res;
    };

    const handleOnDownload = (rowData) => {
        onDownloadEvent && onDownloadEvent(rowData);
    };

    return (
        <Card>
            <Card.CardContent className="document-history-list">
                <Box component={'header'} className={'page-header'}>
                    <Typography className={'page-header-title'} variant={'h3'}>
                        歷史紀錄
                    </Typography>
                    <Button name="create" variant="outlined" color="secondary" onClick={handleOnCreate}>
                        建立新表單
                    </Button>
                </Box>
                <Tablenization cardProps={{ className: 'theme-gray' }}>
                    <Tablenization.TableRowHeader headerRow={thData} />
                    <Tablenization.TableRowBody
                        headerRow={thData}
                        rowsData={[...source]}
                        onEditEvent={handleOnEdit}
                        onReviewEvent={handleOnRead}
                        onDownloadPDFEvent={handleOnDownload}
                        onDeleteOnEvent={(rowData) => {
                            handleOpenDialog(dialogTypeMap.deleteDoc, { rowData });
                        }}
                    />
                </Tablenization>
                <ConfirmDeleteDocDialog
                    title={rowData?.isTemporary ? '確認刪除暫存健康狀態申告書' : '確認刪除健康狀態申告書'}
                    open={openTarget === dialogTypeMap.deleteDoc}
                    onClose={handleCloseDialog}
                    data={rowData}
                    deleteDocApi={doDeleteApi}
                    refresh={getInitPeopleRecordOverview}
                />
            </Card.CardContent>
        </Card>
    );
};

export default HealthDeclarationHistoryTable;
