import useAjax from '@apis/useAjax';

const GET_MEMBER_CYCLE_PURCHASE_API = (memberID) => `/api/members/${memberID}/cycle-purchase/`;
const GET_CHECK_VALID_MEMBER_CYCLE_ORDER_API = (memberID) => `/api/members/${memberID}/cycle-purchase/checkvalid/`;
const GET_MEMBER_CYCLE_PURCHASE_HISTORY_API = (memberID, cycleOrderID) => `/api/members/${memberID}/cycle-purchase/history/${cycleOrderID}/`;
const GET_MEMBER_CYCLE_PURCHASE_FUTURE_API = (memberID, cycleOrderID) => `/api/members/${memberID}/cycle-purchase/future/${cycleOrderID}/`;
const GET_MEMBER_CYCLE_PURCHASE_TIMELINE_API = (memberID) => `/api/members/${memberID}/cycle-purchase/timeline/`;

const useMemberCyclePurchaseApi = () => {
    const Ajax = useAjax();

    // 取得循環訂單列表 包含刪除跟退訂
    const getMemberCycleOrderApi = async (memberID) => {
        const resp = await Ajax.get(GET_MEMBER_CYCLE_PURCHASE_API(memberID));
        return resp;
    };

    const getMemberCycleOrderCheckValidApi = async (memberID) => {
        const resp = await Ajax.get(GET_CHECK_VALID_MEMBER_CYCLE_ORDER_API(memberID));
        return resp;
    };

    const getMemberCycleOrderHistoryApi = async (memberID, cycleOrderID) => {
        const resp = await Ajax.get(GET_MEMBER_CYCLE_PURCHASE_HISTORY_API(memberID, cycleOrderID));
        return resp;
    };

    const getMemberCycleOrderFutureApi = async (memberID, cycleOrderID) => {
        const resp = await Ajax.get(GET_MEMBER_CYCLE_PURCHASE_FUTURE_API(memberID, cycleOrderID));
        return resp;
    };

    const getMemberCycleOrderTimelineApi = async (memberID, params) => {
        const resp = await Ajax.get(GET_MEMBER_CYCLE_PURCHASE_TIMELINE_API(memberID), params);
        return resp;
    };

    return {
        getMemberCycleOrderApi,
        getMemberCycleOrderCheckValidApi,
        getMemberCycleOrderHistoryApi,
        getMemberCycleOrderFutureApi,
        getMemberCycleOrderTimelineApi,
    };
};

export default useMemberCyclePurchaseApi;
