import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import clsx from 'clsx';
import { Dialog, DialogActions, DialogContent, Grid } from '@mui/material';
import { Button, MenuItem, SelectField } from '@common/components/';
import GradientColorHeader from '@icoach/components/dialog/GradientColorHeader';
import useCyclePurchaseApi from '@apis/useCyclePurchaseApi';
import { refIsRequiredError } from '@util/utils';
import useCyclePurchaseEffectApi from '@apis/useCyclePurchaseEffectApi';

const CycleOrderQuitDialogContent = React.forwardRef((props, ref) => {
    const { resource } = props;
    const { coachOptions: employeeOptions = [] } = resource;
    const employeeRef = useRef();

    useImperativeHandle(
        ref,
        () => ({
            getResult: () => {
                return { employeeID: employeeRef.current && employeeRef.current.getResult() };
            },
            isError: () => refIsRequiredError(employeeRef),
        }),
        // eslint-disable-next-line
        []
    );

    return (
        <DialogContent>
            <Grid spacing={3} container>
                <Grid xs={12} item>
                    <SelectField label={'擔當'} ref={employeeRef} fullWidth required>
                        {Array.isArray(employeeOptions) &&
                            employeeOptions
                                .filter((item) => item || !item.disabled)
                                .map((item) => (
                                    <MenuItem key={item.value} value={item.value}>
                                        {item.text}
                                    </MenuItem>
                                ))}
                    </SelectField>
                </Grid>
            </Grid>
        </DialogContent>
    );
});

const CycleEffectOrderQuitDialog = (props) => {
    const { className, open, memberID, cycleOrderID, effectID, onClose, refresh } = props;
    const { getCyclePurchaseOptionsApi } = useCyclePurchaseApi();
    const { deleteCyclePurchaseEffectOrderApi } = useCyclePurchaseEffectApi();
    const [resource, setResource] = useState({});
    const contentRef = useRef();

    const doCyclePurchaseOptionsApi = async () => {
        let res = await getCyclePurchaseOptionsApi();
        if (res) {
            setResource(res);
        }
    };
    let doCyclePurchaseOrderApi = async (effectID, employeeID) => {
        let res = await deleteCyclePurchaseEffectOrderApi(effectID, employeeID);
        if (res) {
            refresh && refresh(memberID, cycleOrderID);
            onClose();
        }
    };

    const getParams = () => {
        return contentRef.current && contentRef.current.getResult();
    };

    const checkError = () => {
        return contentRef.current && contentRef.current.isError();
    };

    const handleSubmit = () => {
        let isError = checkError();
        if (!isError) {
            const { employeeID } = getParams();
            doCyclePurchaseOrderApi(effectID, employeeID);
        }
    };

    useEffect(() => {
        if (open) {
            doCyclePurchaseOptionsApi();
        }
        // eslint-disable-next-line
    }, [open]);

    return (
        <Dialog
            className={clsx('simple-content', 'dialog', className)}
            PaperProps={{ className: 'wx-40rem' }}
            open={open}
            fullWidth
        >
            <GradientColorHeader onClose={onClose}>刪除健康產品變更單</GradientColorHeader>
            <CycleOrderQuitDialogContent ref={contentRef} resource={resource} />
            <DialogActions>
                <Button onClick={handleSubmit} variant="contained">
                    確認送出
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default CycleEffectOrderQuitDialog;
