import React, { useImperativeHandle, useState, useRef } from 'react';
import SendSmsDialog from '@icoach/components/dialog/SendSmsDialog';
import GiftDialog from '@icoach/gift/dialog/GiftDialog';
import GiftDispatchDialog from '@icoach/gift/dialog/GiftDispatchDialog';
import BatchDeleteGiftRecordsDialog from '@icoach/gift/dialog/BatchDeleteGiftRecordsDialog';

const GIFT_DIALOG = 'gift';
const SMS_DIALOG = 'sendSMS';
const GIFT_DISPATCH_DIALOG = 'gift-dispatch';
const BATCH_DELETE_GIFT_RECORDS_DIALOG = 'batch-delete-gift-records';

const GiftDialogBox = React.forwardRef((props, ref) => {
    const [openDialog, setOpenDialog] = useState('');
    const [sourceData, setSourceData] = useState({});
    const refreshRef = useRef(null);

    const doRefresh = (...arg) => {
        if (refreshRef.current && typeof refreshRef.current === 'function') refreshRef.current(...arg);
    };

    useImperativeHandle(
        ref,
        () => {
            return {
                openGiftDialog: (targetData, _refreshFn = () => {}) => {
                    setSourceData(targetData);
                    setOpenDialog(GIFT_DIALOG);
                    refreshRef.current = _refreshFn;
                },
                openGiftDispatchDialog: (targetData, _refreshFn = () => {}) => {
                    setSourceData(targetData);
                    setOpenDialog(GIFT_DISPATCH_DIALOG);
                    refreshRef.current = _refreshFn;
                },
                openSMSDialog: (targetData, _refreshFn = () => {}) => {
                    setSourceData(targetData);
                    setOpenDialog(SMS_DIALOG);
                    refreshRef.current = _refreshFn;
                },
                openBatchDeleteGiftRecordsDialog: (targetData, _refreshFn = () => {}) => {
                    setSourceData(targetData);
                    setOpenDialog(BATCH_DELETE_GIFT_RECORDS_DIALOG);
                    refreshRef.current = _refreshFn;
                },
                closeDialog: () => handleDialogClose(),
            };
        },
        // eslint-disable-next-line
        [],
    );

    const handleDialogClose = () => {
        setOpenDialog(null);
        setSourceData(null);
        refreshRef.current = null;
    };

    if (!openDialog) return null;

    return (
        <React.Fragment>
            <GiftDialog open={openDialog === GIFT_DIALOG} onClose={handleDialogClose} sourceData={sourceData} refresh={doRefresh} />
            <GiftDispatchDialog open={openDialog === GIFT_DISPATCH_DIALOG} onClose={handleDialogClose} sourceData={sourceData} refresh={doRefresh} />
            <SendSmsDialog open={openDialog === SMS_DIALOG} onClose={handleDialogClose} sourceData={sourceData} refresh={doRefresh} />
            <BatchDeleteGiftRecordsDialog
                open={openDialog === BATCH_DELETE_GIFT_RECORDS_DIALOG}
                onClose={handleDialogClose}
                sourceData={sourceData}
                refresh={doRefresh}
            />
        </React.Fragment>
    );
});

export default GiftDialogBox;
