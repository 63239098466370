import React, { useRef } from 'react';
import { Dialog, DialogActions, DialogContent, Grid } from '@mui/material';
import { Button, TextField } from '@common/components/';
import GradientColorHeader from '@icoach/components/dialog/GradientColorHeader';
import useSmsTemplateApi from '@apis/useSmsTemplateApi';

const SmsTemplateCreateDialog = React.forwardRef((props, ref) => {
    const { open, onClose: propsOnClose, onRefrash } = props;
    const { postAddSmsTemplatePublicDataApi } = useSmsTemplateApi();
    const titleRef = useRef(null);
    const templateTextRef = useRef(null);

    // 送出 按鈕
    const handleEditClick = () => {
        const params = {
            name: titleRef.current.getResult(),
            templateText: templateTextRef.current.getResult(),
        };
        doCreateSMSTemplate(params);
    };

    // 新增 SMS公版 API
    const doCreateSMSTemplate = async (params) => {
        const resp = await postAddSmsTemplatePublicDataApi(params);
        if (resp) {
            propsOnClose && propsOnClose();
            onRefrash && onRefrash();
        }
    };

    return (
        <Dialog open={open} PaperProps={{ className: 'wx-65rem' }} fullWidth>
            <GradientColorHeader onClose={propsOnClose}>SMS編輯</GradientColorHeader>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField ref={titleRef} label="簡訊標題" fullWidth required />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            ref={templateTextRef}
                            label="簡訊內容"
                            rows={6}
                            multiline
                            fullWidth
                            required
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={handleEditClick}>
                    送出
                </Button>
            </DialogActions>
        </Dialog>
    );
});

export default SmsTemplateCreateDialog;
