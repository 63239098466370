import React from 'react';
import { Card, CheckboxField } from '@common/components/';
import clsx from 'clsx';
import { Grid } from '@mui/material';
import { setCheckboxValue } from '@icoach/documents/tools';
import { ReadTextField } from '@icoach/components';

const PaymentSectionByRead = (props) => {
    const { titleClassName = 'bg-light-active', data = {}, isCreate } = props;
    const { payment = {}, noDeductNow } = data;
    const { creditCardNo, cardExpiryDate, cardName, cardRelation, creditCardTypeText } = payment;

    return (
        <React.Fragment>
            <Card.CardHeader title="付款資訊" className={clsx('px-4', titleClassName)} />
            <Card.CardContent className={'p-4'}>
                <Grid spacing={3} container>
                    {!isCreate && (
                        <Grid xs={12} item>
                            <CheckboxField defaultChecked={setCheckboxValue(noDeductNow)} readOnly>
                                <CheckboxField.Checkbox label={<span className={'font-weight-bold'}>當月不補扣</span>} />
                            </CheckboxField>
                        </Grid>
                    )}
                    <Grid xs={4} item>
                        <ReadTextField label={'信用卡號'}>{creditCardNo}</ReadTextField>
                    </Grid>
                    <Grid xs={4} item>
                        <ReadTextField label={'有效期限'}>{cardExpiryDate}</ReadTextField>
                    </Grid>
                    <Grid xs={4} item>
                        <ReadTextField label={'持卡人姓名'}>{cardName}</ReadTextField>
                    </Grid>
                    <Grid xs={4} item>
                        <ReadTextField label={'卡片關係人'}>{cardRelation}</ReadTextField>
                    </Grid>
                    <Grid xs={4} item>
                        <ReadTextField label={'卡別'}>{creditCardTypeText}</ReadTextField>
                    </Grid>
                </Grid>
            </Card.CardContent>
        </React.Fragment>
    );
};

export default PaymentSectionByRead;
