import React, { useImperativeHandle, useRef } from 'react';
import { Box, MenuItem, SelectField } from '../../../components';
import clsx from 'clsx';
import { Grid } from '@mui/material';
import { Magnifier as MagnifierIcon } from '../../../components/icons/SvgIcon/';
import { refIsRequiredError } from '../../../util/utils';

const QuestionnaireBox = React.forwardRef((props, ref) => {
    const { className, resource = {} } = props;
    const { purchaseTimeOptions = [], purchaseToolOptions = [] } = resource;
    const purchaseTimeRef = useRef();
    const purchaseToolRef = useRef();

    useImperativeHandle(
        ref,
        () => ({
            getResult: () => {
                return {
                    purchaseTimeType: purchaseTimeRef.current.getResult(),
                    purchaseToolType: purchaseToolRef.current.getResult(),
                };
            },
            isError: () => refIsRequiredError(purchaseTimeRef, purchaseToolRef),
        }),
        []
    );
    return (
        <Box className={clsx('questionnaire-box', className)}>
            <h3 className={'questionnaire-title'}>
                <MagnifierIcon />
                商品調查
            </h3>
            <Grid spacing={2} container>
                <Grid xs={6} item>
                    <SelectField
                        label={'時間點'}
                        ref={purchaseTimeRef}
                        displayEmpty
                        fullWidth
                        required
                    >
                        <MenuItem disabled value="">
                            <em>請選擇</em>
                        </MenuItem>
                        {Array.isArray(purchaseTimeOptions) &&
                            purchaseTimeOptions
                                .filter((item) => item || !item.disabled)
                                .map((item) => (
                                    <MenuItem key={item.value} value={item.value}>
                                        {item.text}
                                    </MenuItem>
                                ))}
                    </SelectField>
                </Grid>
                <Grid xs={6} item>
                    <SelectField
                        label={'使用工具'}
                        ref={purchaseToolRef}
                        displayEmpty
                        fullWidth
                        required
                    >
                        <MenuItem disabled value="">
                            <em>請選擇</em>
                        </MenuItem>
                        {Array.isArray(purchaseToolOptions) &&
                            purchaseToolOptions
                                .filter((item) => item || !item.disabled)
                                .map((item) => (
                                    <MenuItem key={item.value} value={item.value}>
                                        {item.text}
                                    </MenuItem>
                                ))}
                    </SelectField>
                </Grid>
            </Grid>
        </Box>
    );
});

export default QuestionnaireBox;
