import React, { useRef, useState } from 'react';
import { Box, Chip, ClickAwayListener, Stack, Tooltip, Typography } from '@common/components/';
import { isEmpty } from 'lodash';
import Tablenization from '../components/Tablenization';
import { BusinessCardBox, MemoControllerBox, MoreLabelBox, MorePageBox } from '../components';
import { formatCurrencyFn, serialize } from '@util/utils';
import { displayDateText, parseDate } from '@util/moment';
import { QuestionCircle as QuestionCircleIcon } from '@common/SvgIcon/';
import { cabinetStatusMap, getCabinetMoreActionArray, CABINET_STATUS_COLOR_MATCH } from './staticData';
import { MEMBER_DETAIL_RENT_CABINET_URL, RENT_CABINET_DOCUMENT_CREATE_URL } from '../router/MembersRouter';
import { Link } from 'react-router-dom';
import { DateTimeStandard } from '../../components/pickers/enums/DateTimeStandard';
import { useRentCabinetContext } from '@icoach/rentCabinet/components/RentCabinetContext';
import { ListInfo } from '@icoach/accountBook/components';
import ScrollerArrowButton from '@icoach/inbody/components/ScrollerArrowButton';

export const StatusChipTooltip = (props) => {
    const { icon: Icon = <QuestionCircleIcon fontSize={'small'} htmlColor={'#ef5350'} />, statusText, statusMemo, color, isShowTip = false } = props;
    const [open, setOpen] = useState(false);

    const handleTooltipClose = () => {
        setOpen(false);
    };

    const handleTooltipToggle = () => {
        setOpen((prev) => !prev);
    };

    if (isShowTip) {
        return (
            <ClickAwayListener onClickAway={handleTooltipClose}>
                <Tooltip
                    title={statusMemo}
                    PopperProps={{
                        disablePortal: true,
                    }}
                    onClose={handleTooltipClose}
                    open={open}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                >
                    <Chip label={statusText} icon={Icon} color={color} onClick={handleTooltipToggle} />
                </Tooltip>
            </ClickAwayListener>
        );
    }
    return <Chip label={statusText} color={color} />;
};

const MoreLabel = ({ rowsData, refresh, dialogRef, ...props }) => {
    const { cabinetID } = rowsData;
    const moreLabelMap = {
        edit: {
            text: '編輯置物櫃',
            onClickEvent: () => dialogRef.current && dialogRef.current.openEditCabinetDialog({ cabinetID }, refresh),
        },
        addRent: {
            text: '新增租約',
            to: () => `${RENT_CABINET_DOCUMENT_CREATE_URL}?${serialize({ cabinetID })}`,
        },
        delete: {
            text: '刪除置物櫃',
            onClickEvent: () => dialogRef.current && dialogRef.current.openDeleteCabinetDialog({ cabinetID }, refresh),
        },
    };

    return <MoreLabelBox {...props} moreLabelMap={moreLabelMap} textAlign={'center'} />;
};

const rowsLabel = [
    {
        cellKey: 'cabinetName',
        headerLabel: '置物櫃名稱',
        align: 'center',
    },
    {
        cellKey: 'cabinetFee',
        headerLabel: '月租定價',
        align: 'right',
        formatCell: (cellValue) => {
            return formatCurrencyFn(cellValue, { symbol: '$' });
        },
    },
    {
        cellKey: 'cabinetStatusText',
        headerLabel: '狀態',
        align: 'center',
        formatCell: (cellValue, { cabinetStatus, statusMemo }) => {
            return (
                <StatusChipTooltip
                    statusText={cellValue}
                    statusMemo={statusMemo}
                    color={CABINET_STATUS_COLOR_MATCH.get(cabinetStatus)}
                    isShowTip={cabinetStatus === cabinetStatusMap.pause}
                />
            );
        },
    },
    {
        cellKey: 'memberID',
        headerLabel: '承租會員',
        maxWidth: 20.4,
        formatCell: (memberID, rowData) => {
            if (!memberID) return '';
            return (
                <Link className={'unstyled'} to={MEMBER_DETAIL_RENT_CABINET_URL(memberID)}>
                    <BusinessCardBox rowData={rowData} />
                </Link>
            );
        },
    },
    {
        cellKey: 'startDate',
        headerLabel: '租借期間',
        formatCell: (startDate, { endDate }) => {
            if (!startDate && !endDate) return '';
            return displayDateText(startDate, endDate);
        },
    },
    {
        cellKey: 'memo',
        headerLabel: '備註',
        formatCell: (cellValue) => {
            return <MemoControllerBox data={cellValue} />;
        },
    },
    {
        cellKey: 'employeeName',
        headerLabel: '異動擔當',
        align: 'center',
    },
    {
        cellKey: 'updatedDate',
        headerLabel: '更新日期',
        formatCell: (cellValue) => {
            return parseDate(cellValue, DateTimeStandard.DisplayDateBySlash);
        },
    },

    {
        cellKey: 'more',
        headerLabel: '',
        align: 'right',
        isIcon: true,
        formatCell: (cellValue, rowsData, other) => {
            let moreActionArray = getCabinetMoreActionArray(rowsData);
            if (isEmpty(moreActionArray)) return null;
            return <MoreLabel moreActionArray={moreActionArray} rowsData={rowsData} {...other} />;
        },
    },
];

const CabinetOverviewTable = () => {
    const { tableData, setBookmarkNum, refresh, dialogRef } = useRentCabinetContext();
    const { list = [], totalCount, totalPage, pageIndex, pageSize } = tableData;
    const labelTitle = useRef(rowsLabel);
    const scrollerArrowRef = useRef(null);

    const refreshFn = () => {
        if (typeof refresh.current === 'function') refresh.current();
    };

    const handlePageChange = (e, page) => {
        setBookmarkNum(page);
    };

    return (
        <React.Fragment>
            <Stack className={'mb-1'} alignItems={'center'} justifyContent={'space-between'}>
                <Typography variant="h5" className={'font-weight-bold'}>
                    置物櫃總覽
                </Typography>
                <ListInfo
                    className={'ml-auto'}
                    pageIndex={pageIndex}
                    padeSize={pageSize}
                    totalCount={totalCount}
                    isCurrentPageFirstIndex
                    isCurrentPageLastIndex
                />
            </Stack>
            <Box className={'scroll-arrow-container'}>
                <Tablenization
                    cardProps={{ className: 'theme-gray overview' }}
                    count={totalPage}
                    page={pageIndex}
                    onChange={handlePageChange}
                    tableContainerRef={scrollerArrowRef}
                    Pagination={MorePageBox}
                    isPagination
                >
                    <Tablenization.TableRowHeader headerRow={labelTitle.current} />
                    <Tablenization.TableRowBody headerRow={labelTitle.current} rowsData={list} dialogRef={dialogRef} refresh={refreshFn} />
                </Tablenization>
                <ScrollerArrowButton parentRef={scrollerArrowRef} />
            </Box>
        </React.Fragment>
    );
};

export default CabinetOverviewTable;
