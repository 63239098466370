import { LandingPage } from '../landingPage';

export const HOME_TABS_ARRIVE = '/club/home/:tabs(arrive)/';
export const HOME_TABS_ARRIVE_URL = '/club/home/arrive/';
export const APPOINTMENT_SCHEDULING = '/club/appointment/:tabs(time-scheduling)/';
export const APPOINTMENT_SCHEDULING_URL = '/club/appointment/time-scheduling/';
export const EMPLOYEE_SHIFT_SCHEDULING = '/club/employee/:tabs(shift-scheduling)/';
export const EMPLOYEE_SHIFT_SCHEDULING_URL = '/club/employee/shift-scheduling/';
export const APPOINTMENT_OVERVIEW = '/club/appointment/:tabs(ovewview)/';
export const APPOINTMENT_OVERVIEW_URL = '/club/appointment/ovewview/';

// 首頁
const landingPageRouter = [
    {
        description: '首頁',
        frontKey: 'C1X01',
    },
    {
        description: '來店會員',
        exact: true,
        path: HOME_TABS_ARRIVE,
        href: HOME_TABS_ARRIVE_URL,
        as: LandingPage,
        frontKey: 'C1X02',
    },
    {
        description: '預約表',
        exact: true,
        path: APPOINTMENT_SCHEDULING,
        href: APPOINTMENT_SCHEDULING_URL,
        as: LandingPage,
        frontKey: 'C1X03',
    },
    {
        description: '每日班表',
        exact: true,
        path: EMPLOYEE_SHIFT_SCHEDULING,
        href: EMPLOYEE_SHIFT_SCHEDULING_URL,
        as: LandingPage,
        frontKey: 'C1X04',
    },
    {
        description: '預約管理',
        exact: true,
        path: APPOINTMENT_OVERVIEW,
        href: APPOINTMENT_OVERVIEW_URL,
        as: LandingPage,
        frontKey: 'C1X05',
    },
];

export default landingPageRouter;
