export default {
    101: [
        '須本人親自簽署相關文件始可生效，辦理暫停會籍應以「月」為單位。',
        '因出國逾十天者，持相關證明可辦理暫停會員權，暫停期間以每年2個月為限。',
        '經醫師指示暫時不宜運動且開立診斷書證明者，持相關證明辦理。',
        'Curves關心您身體健康及營養均衡的重要性，您可用循環優惠價持續訂購Curves健康產品。 為了保障您的權益，如需調整訂單內容，請洽詢該店舖教練。',
    ],
    102: [
        '須本人親自簽署相關文件始可生效，辦理暫停會籍應以「月」為單位。',
        '因出國逾一個月者，持相關證明可辦理暫停會員權。',
        '經醫師指示暫時不宜運動且開立診斷書證明者，持相關證明辦理。',
        'Curves 關心您身體健康及營養均衡的重要性，您可用循環優惠價持續訂購 Curves 健康產品。為了保障您的權益，如需調整訂單內容，請洽詢該店舖教練。',
    ],
    103: [
        '須本人親自簽署相關文件始可生效，辦理暫停會籍應以「月」為單位。',
        '因出國逾一個月者，持相關證明可辦理暫停會員權。',
        '經醫師指示暫時不宜運動且開立診斷書證明者，持相關證明辦理。',
        'Curves 關心您身體健康及營養均衡的重要性，您可用循環優惠價持續訂購 Curves 健康產品。為了保障您的權益，如需調整訂單內容，請洽詢該店舖教練。',
    ],
};
