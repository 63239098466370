import React, {useEffect, useImperativeHandle, useRef, useState} from 'react';
import clsx from 'clsx';
import _ from 'lodash';
import {Dialog, DialogContent, Grid, Stack} from '@mui/material';
import {Box, IconButton, Typography, Button} from '@common/components/';
import {Close as CloseIcon, Plus as PlusIcon, Minus as MinusIcon} from '@common/SvgIcon/';
import {isEmpty} from '@util/utils';
import usePointsApi from '@apis/usePointsApi';
import Logo from '@images/nav_logo.svg';

const RemainingPointsBox = React.forwardRef(({totalPoints, selectedItemsRef, originData}, ref) => {
    const [point, setPoint] = useState(0);
    // Allen To do 待優化
    const reFreshPoint = () => {
        const _p = totalPoints;
        const oriPoints = originData.reduce((acc, {itemPoints, quantity}) => {
            let __p = acc;
            return quantity * itemPoints + __p;
        }, 0);
        const selectedItems = Object.values(selectedItemsRef.current);
        const selectedPoints =
            selectedItems.reduce((acc, {itemPoints, quantity}) => {
                let __p = acc;
                return quantity * itemPoints + __p;
            }, 0) * -1;
        setPoint(_p + oriPoints + selectedPoints);
    };

    useImperativeHandle(
        ref,
        () => {
            return {reFreshPoint};
        },
        // eslint-disable-next-line
        [point]
    );

    useEffect(
        () => {
            setPoint(totalPoints);
        },
        // eslint-disable-next-line
        [totalPoints]
    );

    return (
        <Typography variant={'h6'}>
            目前剩餘：
            <strong className={'text-primary mr-1'}>{point}</strong>點
        </Typography>
    );
});

const ProductItemBox = React.forwardRef((props, ref) => {
    const {item, selectedItem, onChangeItems} = props;
    const [quantity, setQuantity] = useState(0);
    const changeTypeRef = useRef(null);

    const handleAddQuantity = () => {
        changeTypeRef.current = 'add';
        setQuantity((prev) => prev + 1);
    };

    const handleMinusQuantity = () => {
        if (quantity <= 0) {
            return;
        } else {
            quantity === 1 ? (changeTypeRef.current = 'remove') : (changeTypeRef.current = 'minus');
            setQuantity((prev) => prev - 1);
        }
    };

    useImperativeHandle(
        ref,
        () => ({
            getResult: () => {
                if (quantity === 0) {
                    return null;
                } else {
                    return {itemID: item.itemID, quantity: quantity, itemPoints: item.points};
                }
            },
        }),
        // eslint-disable-next-line
        [quantity]
    );

    useEffect(
        () => {
            if (!isEmpty(changeTypeRef.current)) {
                onChangeItems(changeTypeRef.current, item.itemID);
            }
        },
        // eslint-disable-next-line
        [quantity]
    );

    useEffect(
        () => {
            if (!isEmpty(selectedItem)) setQuantity(selectedItem?.quantity ?? 0);
        },
        // eslint-disable-next-line
        [selectedItem?.quantity ?? 0]
    );

    return (
        <Grid item xs={12}>
            <Box className={clsx('product-box', {'is-select': quantity !== 0})}>
                <img className={'product-box-img'} src={item.imageSrc} alt={item.itemName}/>
                <Box className="product-box-desc">
                    <Typography className={'product-box-title'} variant={'h3'}>
                        {`${item.itemName} - ${item.points}點`}
                    </Typography>
                    {item.sku && <Typography className={'sku'}>SKU：{item.sku}</Typography>}
                </Box>
                {(item.isEnabled || quantity > 0) ? (
                    <Box className="product-box-quantity">
                        <Stack direction="row">
                            <IconButton onClick={handleAddQuantity}>
                                <PlusIcon/>
                            </IconButton>
                            <Typography className={clsx('mx-1', 'my-auto', 'box-quantity')} variant="h5" component="div">
                                {quantity}
                            </Typography>
                            <IconButton onClick={handleMinusQuantity}>
                                <MinusIcon/>
                            </IconButton>
                        </Stack>
                    </Box>
                ) : (<Box className="product-box-warn">無庫存量</Box>)}
            </Box>
        </Grid>
    );
});
/**
 * resultData api回應的勾選
 * sourceDataProps 商品來源
 * selectedItemsRef 已勾選資料來源
 */
const PointsProductExchangeContent = (props) => {
    const {sourceData: sourceDataProps, onClose: onCloseProps, resultData, totalPoints: totalPointsProps, activityId, rewardID, canRedeem} = props;
    const {postPointsRewardsActivityRedeemCartApi, postPointsRewardsActivityUnredeemedApi} = usePointsApi();
    const [filterProducts, setFilterProducts] = useState([]);
    const [totalPoints, setTotalPoints] = useState(0);
    // const isOnComposition = useRef(false);
    // const searchRef = useRef(null);
    const itemsComponentRef = useRef({});
    const selectedItemsRef = useRef({});
    const remainingPointsBoxRef = useRef(null);

    const setSelectedParams = (params) => {
        const cloneData = JSON.parse(JSON.stringify(selectedItemsRef.current));
        selectedItemsRef.current = {
            ...cloneData,
            ..._.keyBy(params, 'itemID'),
        };
    };

    // 數量轉換時必會去getResult
    const getAllRefParams = () => {
        const _key = Object.keys(itemsComponentRef.current);
        const params = _key.map((target) => {
            return itemsComponentRef.current[target].getResult();
        });
        setSelectedParams(params.filter((_n) => _n));
    };

    const setItemComponentRef = (targetID, targetRef) => {
        if (!targetRef) {
            delete itemsComponentRef.current[targetID];
        } else {
            itemsComponentRef.current[targetID] = targetRef;
        }
    };

    const handleItemsOnChange = (changeType, _key) => {
        if (changeType === 'add' || changeType === 'minus') {
            getAllRefParams();
        } else if (changeType === 'remove') {
            delete selectedItemsRef.current[_key];
        }
        remainingPointsBoxRef.current.reFreshPoint();
    };

    const handleOnClickCheckout = () => {
        const params = Object.values(selectedItemsRef.current).map(({itemID, quantity}) => ({itemID, quantity}));
        doExchangePointsApi({redeemResults: params}, rewardID, activityId);
    };
    
    const handleOnClickUnredeemed = () => {
        doUnredeemedPointsApi(rewardID, activityId);
    };

    const doExchangePointsApi = async (params, rID, aID) => {
        const resp = await postPointsRewardsActivityRedeemCartApi(params, rID, aID);
        if (resp) {
            onCloseProps();
        }
    };
    
    const doUnredeemedPointsApi = async (rID, aID) => {
        const resp = await postPointsRewardsActivityUnredeemedApi(rID, aID);
        if (resp) {
            onCloseProps();
        }
    };

    useEffect(
        () => {
            if (!isEmpty(resultData)) {
                const cloneData = _.keyBy(JSON.parse(JSON.stringify(resultData)), 'itemID');
                selectedItemsRef.current = cloneData;
            }
        },
        // eslint-disable-next-line
        [resultData]
    );

    useEffect(
        () => {
            setFilterProducts(sourceDataProps);
        },
        // eslint-disable-next-line
        [sourceDataProps]
    );

    useEffect(
        () => {
            setTotalPoints(totalPointsProps);
        },
        // eslint-disable-next-line
        [totalPointsProps]
    );
    const _selectedItems = Object.values(selectedItemsRef.current);
    return (
        <DialogContent className={'shopping-menu-container'}>
            <Box className={'shopping-menu-side-bar'}>
                <Box className={'logo'}>
                    <img src={Logo} alt={'Curves'}/>
                </Box>
            </Box>
            <Box className={'shopping-menu-content'}>
                <Box className={'products'}>
                    <Grid container className={'mt-4'} spacing={3}>
                        {filterProducts.length !== 0 &&
                            filterProducts.map((item) => {
                                const selectedItem = _selectedItems.find((target) => String(target.itemID) === String(item.itemID));
                                const key = `${item.sku}_${item.itemID}`;
                                return (
                                    <ProductItemBox
                                        key={key}
                                        ref={(el) => setItemComponentRef(key, el)}
                                        selectedItem={selectedItem}
                                        item={item}
                                        onChangeItems={handleItemsOnChange}
                                    />
                                );
                            })}
                    </Grid>
                </Box>
                <footer className={'shopping-menu-footer'}>
                    <RemainingPointsBox
                        key={resultData}
                        ref={remainingPointsBoxRef}
                        totalPoints={totalPoints}
                        selectedItemsRef={selectedItemsRef}
                        originData={resultData}
                    />
                    <div className={'btn-group'}>
                        {canRedeem &&
                            <Button color={'warning'} variant={'outlined'} size={'large'} onClick={handleOnClickUnredeemed} disabled={!canRedeem}>
                                本次不兌換
                            </Button>}
                        <Button color={'warning'} variant={'contained'} size={'large'} onClick={handleOnClickCheckout} disabled={!canRedeem}>
                            {canRedeem ? '確認兌換' : '尚未開始或停止兌換'}
                        </Button>
                    </div>
                </footer>
            </Box>
        </DialogContent>
    );
};

const PointsProductExchangeDialog = React.forwardRef((props, ref) => {
    const {open: openProps, className: classNameProps, onClose: onCloseProps, sourceData: memberDataProps, refresh} = props;
    if (!openProps) return null;
    const {getPointsRewardsActivityRedeemCartApi} = usePointsApi();
    const [sourceData, setSourceData] = useState({});
    const {activityId, rewardID} = memberDataProps;

    const initProductDataApi = async (rID, aID) => {
        const resp = await getPointsRewardsActivityRedeemCartApi(rID || 0, aID || 0);
        if (resp) {
            setSourceData(resp);

        }
    };

    useEffect(
        () => {
            if (openProps && isEmpty(sourceData)) {
                initProductDataApi(rewardID, activityId);
            }
        },
        // eslint-disable-next-line
        [openProps]
    );

    const {redeemCartItems = [], redeemResults = [], canRedeem} = sourceData || {};

    return (
        <Dialog
            className={clsx(classNameProps, 'shopping-menu-dialog', 'simple-content', 'dialog')}
            PaperProps={{className: 'wx-90rem'}}
            open={openProps}
            fullWidth
        >
            <div className="dialog-close-btn">
                <IconButton size="large" onClick={onCloseProps}>
                    <CloseIcon htmlColor="#fff"/>
                </IconButton>
            </div>
            <PointsProductExchangeContent
                sourceData={redeemCartItems}
                resultData={redeemResults}
                onClose={() => {
                    refresh();
                    onCloseProps();
                }}
                totalPoints={memberDataProps.currentPoints || 0}
                activityId={activityId}
                rewardID={rewardID}
                canRedeem={canRedeem}
            />
        </Dialog>
    );
});

export default PointsProductExchangeDialog;
