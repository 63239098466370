import useAjax from '@apis/useAjax';

const POST_DOC_MEMBERSHIP_BATCH_QUIT_VALIDATE_API = `/api/doc/membership/batch-quit/validate/`;
const POST_DOC_MEMBERSHIP_BATCH_QUIT_API = `/api/doc/membership/batch-quit/`;

const useDocMembershipBatchQuitApi = () => {
    const Ajax = useAjax();

    const postDocMembershipBatchQuitValidateApi = async (params) => {
        return await Ajax.post(POST_DOC_MEMBERSHIP_BATCH_QUIT_VALIDATE_API, params);
    };

    const postDocMembershipBatchQuitApi = async (params) => {
        return await Ajax.post(POST_DOC_MEMBERSHIP_BATCH_QUIT_API, params);
    };

    return {
        postDocMembershipBatchQuitValidateApi,
        postDocMembershipBatchQuitApi,
    };
};

export default useDocMembershipBatchQuitApi;
