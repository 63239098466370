import React, { createContext, useContext } from 'react';

const AccountBookContext = createContext(undefined);

export const AccountBookProvider = ({ children, value }) => {
    return <AccountBookContext.Provider value={value}>{children}</AccountBookContext.Provider>;
};

export const useAccountBookContext = () => {
    const context = useContext(AccountBookContext);
    if (context === undefined) {
        throw new Error('useAccountBookContext 必須在 AccountBookProvider 中使用');
    }
    return context;
};
