import React, { useState, useRef } from 'react';
import { AccountBookProvider, useAccountBookContext } from '@icoach/accountBook/components/AccountBookContext';
import { Box, Card, Tab, Tabs } from '@common/components/';
import { statisticalType } from '@icoach/accountBook/staticData';
import AccountBookOrderStatOnAllSearchBar from '@icoach/accountBook/AccountBookOrderStatOnAllSearchBar';
import AccountBookOrderStatOnAllTable from '@icoach/accountBook/AccountBookOrderStatOnAllTable';
import { useHistory } from 'react-router-dom';
import { paramsToObject } from '@util/utils';

const AccountBookOrderStatOnAllTabs = () => {
    const { tabIndex, setTabIndex } = useAccountBookContext();

    const handleTabChange = (e, value) => setTabIndex(value);

    return (
        <Box component={'header'} className={'page-header'}>
            <Tabs value={tabIndex} onChange={handleTabChange}>
                <Tab className={'py-3'} label={'收入來源'} value={statisticalType.orderType} />
                <Tab className={'py-3'} label={'收款方式'} value={statisticalType.paymentType} />
            </Tabs>
        </Box>
    );
};

const AccountBookOrderStatOnAllPage = () => {
    const history = useHistory();
    const search = history.location.search;
    const { tabIndex: tabIndexByUrl } = paramsToObject(search);
    const [tabIndex, setTabIndex] = useState(tabIndexByUrl || statisticalType.orderType);
    const [tableData, setTableData] = useState([]);
    const refreshRef = useRef(() => {});

    const childContext = {
        tableData,
        setTableData,
        tabIndex,
        setTabIndex,
        refresh: refreshRef,
        setRefresh: (el) => (refreshRef.current = el),
    };

    return (
        <AccountBookProvider value={childContext}>
            <Card className={'tabs-content-layout'}>
                <AccountBookOrderStatOnAllTabs />
                <Card.CardContent className={'py-3 px-4'}>
                    <AccountBookOrderStatOnAllSearchBar className={'mb-4'} />
                    <AccountBookOrderStatOnAllTable />
                </Card.CardContent>
            </Card>
        </AccountBookProvider>
    );
};

export default AccountBookOrderStatOnAllPage;
