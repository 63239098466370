import React from 'react';
import clsx from 'clsx';
import img_pdf from '../../assets/img/pdf.png';
import { Box, Typography } from '../../../components';
import { displayDateText } from '../../../util/moment';

const CycleOrderHistoryCard = (props) => {
    const { className, data = {} } = props;
    const {
        title,
        applyDate,
        postedDate,
        employeeName,
        link = '#',
        startDate,
        endDate,
        reasonTypeText,
        reasonText,
    } = data;

    return (
        <Box className={clsx('cycle-order-history-box', className)}>
            <div className="cycle-order-history-info">
                <Typography>
                    <span className="label">申請日期</span>
                    <span className="text">{displayDateText(applyDate)}</span>
                </Typography>
                <Typography>
                    <span className="label">生效日期</span>
                    <span className="text">{displayDateText(postedDate)}</span>
                </Typography>
                <Typography className="coach">
                    <span className="label">擔當</span>
                    <span className="text">{employeeName}</span>
                </Typography>
            </div>
            <div className="cycle-order-history-card">
                {link && (
                    <a
                        className="cycle-order-history-card-img"
                        href={link}
                        target={'_blank'}
                        rel="noopener noreferrer"
                    >
                        <img src={img_pdf} alt="申請書" />
                    </a>
                )}
                <div className="cycle-order-history-card-content">
                    <Typography className="title">
                        <strong>{title}</strong>
                    </Typography>
                    {!(!startDate && !endDate) && (
                        <Typography>
                            <span className="date-range">
                                {displayDateText(startDate, endDate, '')}
                            </span>
                        </Typography>
                    )}
                    <Typography>{`${reasonTypeText}${reasonText && `；${reasonText}`}`}</Typography>
                </div>
            </div>
        </Box>
    );
};

export default CycleOrderHistoryCard;
