import React, { useRef } from 'react';
import Tablenization from '../../../components/Tablenization';
import { Link } from 'react-router-dom';
import { ACCOUNT_BOOK_ORDERS_ORDER_URL } from '../../../router/AccountBookRouter';
import { parseDate } from '../../../../util/moment';
import { formatCurrencyFn } from '../../../../util/utils';

const rowsLabel = [
    {
        cellKey: 'orderNo',
        headerLabel: '訂單編號',
        width: '13',
        formatCell: (cellValue, { orderID }) => {
            return <Link to={ACCOUNT_BOOK_ORDERS_ORDER_URL(orderID)}>{cellValue}</Link>;
        },
    },

    {
        cellKey: 'orderDate',
        headerLabel: '訂單日期',
        align: 'center',
        width: '12',
        formatCell: (cellValue) => {
            const _parseDate = (date, format) => {
                return parseDate(date, format, false);
            };
            return (
                <React.Fragment>
                    <p className="mb-0">{_parseDate(cellValue, 'YYYY/MM/DD')}</p>
                    <p className="mb-0 font-color-3">{_parseDate(cellValue, 'HH:mm')}</p>
                </React.Fragment>
            );
        },
    },
    {
        cellKey: 'orderTypeText',
        headerLabel: '訂單類型',
        align: 'center',
    },
    {
        cellKey: 'payablePrice',
        headerLabel: '應付金額',
        align: 'right',
        formatCell: (cellValue) => {
            return formatCurrencyFn(cellValue);
        },
    },
    {
        cellKey: 'paidPrice',
        headerLabel: '已付金額',
        align: 'right',
        formatCell: (cellValue) => {
            return formatCurrencyFn(cellValue);
        },
    },
];

const OrderUncheckoutTable = (props) => {
    const { className, rowsData = [] } = props;
    const labelTitle = useRef(rowsLabel);

    return (
        <Tablenization cardProps={{ className: className }}>
            <Tablenization.TableRowHeader headerRow={labelTitle.current} />
            <Tablenization.TableRowBody headerRow={labelTitle.current} rowsData={rowsData} />
        </Tablenization>
    );
};

export default OrderUncheckoutTable;
