import React, { useImperativeHandle, useState, useRef, useEffect } from 'react';
import MemberContactDialog from '@icoach/members/dialog/MemberContactDialog';
import SendSmsDialog from '@icoach/components/dialog/SendSmsDialog';
import { CheckoutCreditOrderDialog } from '@icoach/members/memberDetail/dialog/';
import GiftDispatchDialog from '@icoach/gift/dialog/GiftDispatchDialog';
import { useHistory } from 'react-router-dom';
import { MEMBERS_TABS_GIFT_DISPATCH_RECORD_URL } from '@icoach/router/MembersRouter';
import BatchMemberShipQuitDialog from '@icoach/members/dialog/BatchMembershipQuitDialog';

const BATCH_MEMBERSHIP_QUIT = 'batch-membership-quit';

// table 重複元件使用(單一來源彈跳視窗)，如果只有一個元件在使用就不用寫在這
const MemberDialogBox = React.forwardRef((props, ref) => {
    const { setContactState, refresh: refreshProps } = props;
    const history = useHistory();
    const [openDialog, setOpenDialog] = useState(null);
    const [sourceData, setSourceData] = useState(null);
    const refreshRef = useRef(null);

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const handleGiftDispatchRefresh = () => {
        history.push(MEMBERS_TABS_GIFT_DISPATCH_RECORD_URL);
    };

    const doRefresh = (...arg) => {
        refreshRef.current && typeof refreshRef.current === 'function' && refreshRef.current(arg);
    };

    useImperativeHandle(
        ref,
        () => {
            return {
                openSmsDialog: (targetData) => {
                    setSourceData(targetData);
                    setOpenDialog('sendSMS');
                },
                openContactDialog: (targetData) => {
                    setSourceData(targetData);
                    setOpenDialog('memberContact');
                },
                openOrderDialog: (target, _refreshFn = () => {}) => {
                    setSourceData(target);
                    setOpenDialog('creditOrder');
                    refreshRef.current = _refreshFn;
                },
                openGiftDispatchDialog: (targetData) => {
                    setSourceData(targetData);
                    setOpenDialog('giftDispatch');
                },
                openBatchMembershipQuitDialog: (targetData, _refreshFn = () => {}) => {
                    console.log(_refreshFn, '_refreshFn');
                    setSourceData(targetData);
                    setOpenDialog(BATCH_MEMBERSHIP_QUIT);
                    refreshRef.current = _refreshFn;
                },
                closeDialog: () => handleCloseDialog(),
            };
        },
        [],
    );

    useEffect(
        () => {
            if (!openDialog) {
                refreshRef.current = null;
                setSourceData(null);
            }
        },
        // eslint-disable-next-line
        [openDialog],
    );

    if (!openDialog) return null;

    // 以下給的property都是可選(除了open)，*****不是一定要這樣給*****，依照自己的需求給
    return (
        <React.Fragment>
            <MemberContactDialog
                open={openDialog === 'memberContact'}
                onClose={handleCloseDialog}
                sourceData={sourceData}
                setContactState={setContactState}
                refresh={refreshProps}
            />
            <SendSmsDialog open={openDialog === 'sendSMS'} onClose={handleCloseDialog} sourceData={sourceData} refresh={refreshProps} />
            <CheckoutCreditOrderDialog
                open={openDialog === 'creditOrder'}
                onClose={handleCloseDialog}
                memberID={sourceData?.memberID ?? null}
                refresh={doRefresh}
            />
            <GiftDispatchDialog open={openDialog === 'giftDispatch'} onClose={handleCloseDialog} sourceData={sourceData} refresh={handleGiftDispatchRefresh} />
            <BatchMemberShipQuitDialog
                open={openDialog === BATCH_MEMBERSHIP_QUIT}
                onClose={handleCloseDialog}
                sourceData={sourceData}
                setContactState={setContactState}
                refresh={refreshProps}
            />
        </React.Fragment>
    );
});

export default MemberDialogBox;
