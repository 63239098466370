import React, { useRef, useState, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import _ from 'lodash';
import OverviewNormalSearchBar from '@icoach/members/overview/OverviewNormalSearchBar';
import OverviewFilterSearchBar from '@icoach/members/overview/OverviewFilterSearchBar';
import OverviewTable from '@icoach/members/overview/OverviewTable';
import { normalLabel } from '@icoach/members/overview/searchIndex';
import MembersContext from '@icoach/members/components/MembersContext';
import { initBookmark } from '@icoach/members/staticData';

const searchBarPanels = {
    normal: OverviewNormalSearchBar,
    quick: OverviewFilterSearchBar,
};
/**
 * refresh      重新刷新頁面
 * sourceData   api回存存放資料
 * bookmark     頁數
 */

const OverviewContainer = (props) => {
    const [rowsLabel, setRowsLabel] = useState([]);
    const [sortOption, setSortOption] = useState([]);
    const [sourceData, setSourceData] = useState({});
    const [resource, setResource] = useState({});
    const [bookmark, setBookmark] = useState(initBookmark());
    const refreshRef = useRef(null);
    const reSetCheckedItemsRef = useRef(null);
    const { subTabs, quickType } = useParams();

    // 快速名單使用 更多判斷與刷新單筆資料
    const setContactState = (memberID, params) => {
        setSourceData(({ list, ...others }) => {
            let newData = _.cloneDeep(list);
            newData = newData.map((target) => {
                if (String(target.memberID) === String(memberID)) {
                    return {
                        ...target,
                        contact: {
                            ...target.contact,
                            ...params,
                        },
                    };
                }
                return target;
            });
            return {
                list: newData,
                ...others,
            };
        });
    };

    const refreshFn = () => {
        typeof refreshRef.current === 'function' && refreshRef.current();
    };

    const childValue = {
        sourceData,
        setSourceData,
        bookmark,
        rowsLabel,
        setRowsLabel,
        sortOption,
        setSortOption,
        setContactState,
        resource,
        setResource,
        refresh: refreshFn,
        setRefresh: (el) => (refreshRef.current = el),
        reSetCheckedItemsRef: (fn) => (reSetCheckedItemsRef.current = fn),
        reSetCheckedItems: () => typeof reSetCheckedItemsRef.current === 'function' && reSetCheckedItemsRef.current(),
        setBookmarkNum: (num) => setBookmark((prev) => ({ ...prev, pageIndex: num })),
        setBookmarkSortBy: (sortBy) => setBookmark(() => initBookmark(sortBy)),
    };

    const SearchBar = useMemo(
        () => {
            return subTabs ? searchBarPanels[subTabs] : () => null;
        },
        // eslint-disable-next-line
        [subTabs, quickType],
    );

    const MutipleTable = useMemo(
        () => OverviewTable,
        // eslint-disable-next-line
        [],
    );

    useEffect(
        () => {
            if (subTabs === 'normal') setRowsLabel(normalLabel);
            return () => {
                setBookmark(initBookmark());
                setSourceData({});
                setRowsLabel([]);
            };
        },
        // eslint-disable-next-line
        [subTabs],
    );

    return (
        <MembersContext.Provider value={childValue}>
            <div className="members-search-panel">
                <SearchBar tabsIndex={subTabs} targetType={quickType} />
            </div>
            <div className="members-content">
                <MutipleTable targetType={quickType} />
            </div>
        </MembersContext.Provider>
    );
};

export default OverviewContainer;
