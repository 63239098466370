import React from 'react';
import { Box } from '../../components';
import clsx from 'clsx';

const BadgeBox = ({ title, content, className, color }) => {
    return (
        <Box
            className={clsx(
                'badge-box',
                { [`badge-box-${color}`]: color },
                className
            )}
        >
            <p className="badge-box-title">{title}</p>
            <p className="badge-box-content">{content}</p>
        </Box>
    );
};

export default BadgeBox;
