import {
    ComponentTestPage,
    DateIntervalFieldTest,
    IconsPage,
    DownloadExcel,
    TakePhotoPage,
    MaskTextInputTestPage,
    InvoiceTestPage,
    PDFTest,
    BulkMambershipDataPage
} from '@icoach/development/';

export const developerRouter = {
    description: '開發用',
    menuName: '開發專用區',
    frontKey: 'D999X01',
    children: [
        {
            description: '所有SVG圖檔',
            menuName: '所有Icon',
            strict: true,
            path: '/club/icons/',
            href: '/club/icons/',
            as: IconsPage,
            frontKey: 'D999X02',
        },
        {
            description: 'menu參考',
            menuName: '元件測試',
            strict: true,
            path: '/club/test/components/',
            href: '/club/test/components/',
            as: ComponentTestPage,
            frontKey: 'D999X03',
        },
        {
            description: 'excel',
            menuName: '下載Excel測試',
            strict: true,
            path: '/club/test/excel/',
            href: '/club/test/excel/',
            as: DownloadExcel,
            frontKey: 'D999X04',
        },
        {
            description: '日期區間元件',
            menuName: '日期區間元件',
            strict: true,
            path: '/club/test/dateIntervalField',
            href: '/club/test/dateIntervalField',
            as: DateIntervalFieldTest,
            frontKey: 'D999X05',
        },
        {
            description: '拍照且上傳',
            menuName: '拍照',
            strict: true,
            path: '/club/camera',
            href: '/club/camera',
            as: TakePhotoPage,
            frontKey: 'D999X06',
        },
        {
            description: 'PDF下載測試',
            menuName: 'PDF下載測試',
            strict: true,
            path: '/club/pdf-download',
            href: '/club/pdf-download',
            as: PDFTest,
            frontKey: 'D999X07',
        },
        {
            description: '特殊輸入框',
            menuName: '特殊輸入框',
            strict: true,
            path: '/club/input-text',
            href: '/club/input-text',
            as: MaskTextInputTestPage,
            frontKey: 'D999X08',
        },
        {
            description: '發票元件',
            menuName: '發票元件',
            strict: true,
            path: '/club/invoice',
            href: '/club/invoice',
            as: InvoiceTestPage,
            frontKey: 'D999X09',
        },
        {
            description: '批次新增會籍',
            menuName: '批次新增會籍',
            strict: true,
            path: '/club/bulk-membershipt/test-data/',
            href: '/club/bulk-membershipt/test-data/',
            as: BulkMambershipDataPage,
            frontKey: 'D999X10',
        },
    ],
};
