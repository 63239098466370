import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Grid } from '@mui/material';
import clsx from 'clsx';
import { useLeaderborad } from '@icoach/measureLeaderboard/useLeaderboradContext';
import { Card, Button, SelectField, MenuItem, Stack, DateField } from '@common/components/';
import { getFirstOfMonth, getSubMonths, getToday, isValidDate, parseDate } from '@util/moment';
import useWindowScrollPositions from '@util/hook/useWindowScrollPositions';
import useMeasureLeaderboard from '@apis/useMeasureLeaderboard';
import useShowLoading from '@util/hook/useShowLoading';
import { refIsRequiredError, localUser } from '@util/utils';

const buttonTextMap = {
    1: {
        text: '體重下載',
    },
    2: {
        text: '體脂肪下載',
    },
    3: {
        text: '體脂率下載',
    },
    4: {
        text: '骨骼肌下載',
    },
    5: {
        text: '全體圍下載',
    },
};

// 全店排行榜 searchbar
const MeasureLeaderboardSearchBar = (props) => {
    const { setSourceData, sourceData, setMonths } = useLeaderborad();
    const { className: classNameProps } = props;
    const showLoading = useShowLoading();
    const [leaderboardType, setLeaderboardType] = useState(1);
    const [presentDate, setPresentDate] = useState(getSubMonths(getFirstOfMonth(getToday()), 1));
    const [option, setOption] = useState([]);
    const {
        getMeasureLeaderboardOptionApi,
        getmMeasureLeaderboardOverviewApi,
        postMeasureLeaderboardPDFApi,
        postMeasureLeaderboardZipApi,
    } = useMeasureLeaderboard();
    const presentDateRef = useRef(null);
    const leaderboardTypeRef = useRef(null);
    const isPreloaderRef = useRef(false);
    useWindowScrollPositions();

    const getParams = () => {
        let params = {
            presentDate: presentDateRef.current.getResult(),
            type: leaderboardTypeRef.current.getResult(),
        };
        return params;
    };

    const handleTypeChange = (_dom, { props }) => {
        const { value } = props;
        setLeaderboardType(value);
    };

    const handleDateChange = useCallback(
        (_n, value) => {
            if (presentDate !== value && isValidDate(value)) {
                setPresentDate(value);
            }
        },
        // eslint-disable-next-line
        [presentDate]
    );

    const getSourceDataParams = () => {
        const othersParmas = getParams() || {};
        return {
            leaderboardIDs: sourceData.map(({ leaderboardID }) => leaderboardID),
            ...othersParmas,
        };
    };

    const handleExportLeaderboardIDs = () => {
        const params = getSourceDataParams();
        if (params.leaderboardIDs.length === 0) return;
        if (!refIsRequiredError(presentDateRef)) {
            const { type, ..._params } = params;
            doExportApi(_params, type);
        }
    };

    const handleClubRankingZip = () => {
        if (!refIsRequiredError(presentDateRef)) {
            const { value } = localUser.get('user-club');
            if (!value) return;
            doZipApi(value, presentDateRef.current.getResult());
        }
    };

    const doZipApi = async (clubID, targetDate) => {
        await postMeasureLeaderboardZipApi(clubID, targetDate);
    };

    // 產生顯示PDF
    const doExportApi = async (params, type) => {
        await postMeasureLeaderboardPDFApi(params, type);
    };

    const getOverviewApi = async (params) => {
        const resp = await getmMeasureLeaderboardOverviewApi(params);
        const friDate = parseDate(params.presentDate, 'M');
        const secDate = parseDate(getSubMonths(getFirstOfMonth(params.presentDate), 1), 'M');
        setMonths(friDate, secDate);
        if (resp) {
            setSourceData(resp);
        }
    };

    const initApi = async () => {
        showLoading(true);
        let respOpt = await getMeasureLeaderboardOptionApi();
        if (respOpt) {
            setLeaderboardType(respOpt[0].value);
            setOption(respOpt);
            isPreloaderRef.current = true;
            getOverviewApi(getParams());
        }
        showLoading(false);
    };

    useEffect(
        () => {
            if (isPreloaderRef.current) {
                getOverviewApi(getParams());
            }
        },
        // eslint-disable-next-line
        [leaderboardType, presentDate]
    );

    useEffect(
        () => {
            initApi();
        },
        // eslint-disable-next-line
        []
    );

    return (
        <Card className={clsx(classNameProps)}>
            <Card.CardContent className="px-4 py-3">
                <Grid container spacing={2}>
                    <Grid item xs={6} lg={3}>
                        <DateField
                            ref={presentDateRef}
                            defaultValue={presentDate}
                            maxDate={getSubMonths(getFirstOfMonth(getToday()), 1)}
                            onChange={handleDateChange}
                            openTo="month"
                            format="YYYY/MM"
                            views={['year', 'month']}
                            mask={'____/__'}
                            label="選擇月份"
                            fullWidth
                            required
                        />
                    </Grid>
                    <Grid item xs={6} lg={3}>
                        {option.length > 0 && (
                            <SelectField ref={leaderboardTypeRef} label="排行榜類型" value={leaderboardType} onChange={handleTypeChange} fullWidth>
                                {option.map(({ text, value }) => {
                                    return (
                                        <MenuItem value={value} key={`${text}_${value}`}>
                                            {text}
                                        </MenuItem>
                                    );
                                })}
                            </SelectField>
                        )}
                    </Grid>
                    <Grid xs={6} lg={3} item>
                        <Stack className={'h-100'} direction={'row'} spacing={2} alignItems={'end'}>
                            <Button className={'mb-1'} color={'success'} variant={'contained'} onClick={handleExportLeaderboardIDs} fullWidth>
                                {buttonTextMap[leaderboardType].text}
                            </Button>
                        </Stack>
                    </Grid>
                    <Grid xs={6} lg={3} item>
                        <Stack className={'h-100'} direction={'row'} spacing={2} alignItems={'end'}>
                            <Button className={'mb-1'} color={'warning'} variant={'contained'} onClick={handleClubRankingZip} fullWidth>
                                {parseDate(presentDate, 'M')}月份全排行榜下載
                            </Button>
                        </Stack>
                    </Grid>
                </Grid>
            </Card.CardContent>
        </Card>
    );
};
export default MeasureLeaderboardSearchBar;
