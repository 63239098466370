import React from 'react';
import Tablenization from '../../components/Tablenization';
import { Chip } from '../../../components/';

const setSendType = (sendType, sendText) => {
    let targetMap = {
        text: '失敗',
        color: 'error',
    };
    if (sendType < 0) {
        targetMap = {
            text: '預約',
            color: 'info',
        };
    } else if ([17, 18].includes(sendType)) {
        targetMap = {
            text: '成功',
            color: 'success',
        };
    } else if ([16].includes(sendType)) {
        targetMap = {
            text: sendText,
            color: 'info',
        };
    }
    return targetMap;
};

const rowsLabel = [
    {
        cellKey: 'name',
        headerLabel: '姓名',
        width: 8,
    },
    {
        cellKey: 'phone',
        headerLabel: '電話',
        width: 8,
    },
    {
        cellKey: 'referenceTypeText',
        headerLabel: '對象',
        width: 8,
    },
    {
        cellKey: 'sendStatus',
        headerLabel: '狀態',
        align: 'center',
        width: 8,
        formatCell: (cellValue, { sendStatusText }) => {
            const typeMap = setSendType(cellValue * 1, sendStatusText);
            return <Chip color={typeMap.color} label={typeMap.text} />;
        },
    },
    {
        cellKey: 'sendStatusText',
        headerLabel: '備註',
        width: 14,
    },
];

const SmsIdOverviewTable = React.forwardRef((props, ref) => {
    const { sourceData } = props;

    return (
        <React.Fragment>
            <Tablenization cardProps={{ className: 'my-3' }}>
                <Tablenization.TableRowHeader headerRow={rowsLabel} />
                <Tablenization.TableRowBody headerRow={rowsLabel} rowsData={sourceData} />
            </Tablenization>
        </React.Fragment>
    );
});

export default SmsIdOverviewTable;
