import React from 'react';
import { formatCurrencyFn } from '../../../util/utils';

// 特價介面
const SpecialOffer = ({ price, specialOffer }) => {
    if (specialOffer)
        return (
            <React.Fragment>
                <del className={'font-color-4 mr-1'}>
                    <small>{formatCurrencyFn(price)}</small>
                </del>
                <span>{formatCurrencyFn(specialOffer)}</span>
            </React.Fragment>
        );
    return formatCurrencyFn(price);
};

export default SpecialOffer;
