import { useEffect, useCallback, useState } from 'react';
import useAjax from '@apis/useAjax';
import { useSnackbar } from 'notistack';

const GET_PEOPLE_API = `/api/people/`;

export const peopleType = {
    customer: '1',
    member: '2',
    invalidMember: '3',
    transferMember: '4',
    all: '6',
    onlyJoin: '7',
    transferInMember: '10',
    transferManually: '11',
    transferTemporary: '13',
    transferIn: '14',
    unConfirmTransferOutMember: '16',
    alreadyTransferOutMember: '17',
};

const noApiOptions = [];

const usePeopleApi = (targetType) => {
    const Ajax = useAjax();
    const [people, setPeople] = useState([]);
    const { enqueueSnackbar } = useSnackbar();

    const getPeopleApi = useCallback(
        async (type) => {
            const resp = await Ajax.get(GET_PEOPLE_API, { type });
            if (!resp) enqueueSnackbar('失敗', { variant: 'error' });
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    useEffect(
        () => {
            const fetchData = async () => {
                if (!targetType) return false;
                if (noApiOptions.indexOf(String(targetType)) !== -1) return false; // 新入會不會有清單
                let resp = await getPeopleApi(targetType);
                if (resp) {
                    setPeople(resp);
                }
            };
            fetchData();
        },
        // eslint-disable-next-line
        [targetType]
    );

    return { people, getPeopleApi };
};

export default usePeopleApi;
