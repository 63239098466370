import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { PrivateRoute } from '@common/components/';
import { Switch } from 'react-router-dom';
import { NotificationEntireBox } from '@icoach/notifications/components';
import {
    NOTIFICATIONS_ENTIRE_ROUTER,
    NOTIFICATIONS_DOC_TRANSFERIN_ROUTER,
    NOTIFICATIONS_DOC_TRANSFEROUT_ROUTER,
    NOTIFICATIONS_DOC_MEMBERSHIP_EXPIRE_ROUTER,
    NOTIFICATIONS_INVOICE_ROUTER,
    NOTIFICATIONS_DOC_TEMPORARINESS_ROUTER,
    NOTIFICATIONS_UPDATE_VERSION_ROUTER,
    NOTIFICATIONS_CYCLE_PURCHASE_ROUTER,
} from '@icoach/router/routerPath';
import { NotFoundPage } from '@error/';

const NotificationsPage = (props) => {
    return (
        <Switch>
            <PrivateRoute title="全部通知" path={NOTIFICATIONS_ENTIRE_ROUTER} as={NotificationEntireBox} />
            <PrivateRoute title="會籍到期" path={NOTIFICATIONS_DOC_MEMBERSHIP_EXPIRE_ROUTER} as={NotificationEntireBox} />
            <PrivateRoute title="會籍移入" path={NOTIFICATIONS_DOC_TRANSFERIN_ROUTER} as={NotificationEntireBox} />
            <PrivateRoute title="會籍移出" path={NOTIFICATIONS_DOC_TRANSFEROUT_ROUTER} as={NotificationEntireBox} />
            <PrivateRoute title="循環訂單" path={NOTIFICATIONS_CYCLE_PURCHASE_ROUTER} as={NotificationEntireBox} />
            <PrivateRoute title="發票異常" path={NOTIFICATIONS_INVOICE_ROUTER} as={NotificationEntireBox} />
            <PrivateRoute title="暫存文書" path={NOTIFICATIONS_DOC_TEMPORARINESS_ROUTER} as={NotificationEntireBox} />
            <PrivateRoute title="新版本" path={NOTIFICATIONS_UPDATE_VERSION_ROUTER} as={NotificationEntireBox} />
            <Route path="/">
                <Redirect to={NOTIFICATIONS_ENTIRE_ROUTER} />
            </Route>
            <PrivateRoute path="*" as={NotFoundPage} />
        </Switch>
    );
};

export default NotificationsPage;
