import React, { useImperativeHandle, useRef } from 'react';
import { MenuItem, Select, Box, Typography } from '@common/components/';
import { refIsRequiredError } from '@util/utils';
import clsx from 'clsx';

const fakeSortOptions = [
    {
        text: '建立日期新→舊',
        value: 1,
    },
    {
        text: '建立日期舊→新',
        value: 2,
    },
    {
        text: '金額高→低',
        value: 3,
    },
    {
        text: '金額低→高',
        value: 4,
    },
];

const ListInfo = React.forwardRef((props, ref) => {
    const {
        className,
        pageSize = 10,
        pageIndex = 1,
        totalCount = 0,
        sortDefaultValue,
        sortOptions = fakeSortOptions,
        sortOnChange,
        isSort = false,
        isCurrentPageFirstIndex = false,
        isCurrentPageLastIndex = false,
    } = props;

    const sortRef = useRef();

    // 取得頁資訊
    const getPageInfoText = () => {
        const prevPageLastIndex = pageSize * (pageIndex - 1);
        const currentPageFirstIndex = prevPageLastIndex + 1;
        const currentPageLastIndex = Math.min(pageSize * pageIndex, totalCount);

        if (!isCurrentPageFirstIndex && !isCurrentPageLastIndex) {
            return '';
        }

        let text = isCurrentPageFirstIndex ? `${currentPageFirstIndex}` : '';
        text += isCurrentPageFirstIndex && isCurrentPageLastIndex ? ' - ' : '';
        text += isCurrentPageLastIndex ? `${currentPageLastIndex}` : '';
        text += '筆';

        return text;
    };

    const handleSortChange = (e, _, value) => {
        if (typeof sortOnChange === 'function') sortOnChange(value);
    };

    useImperativeHandle(ref, () => ({
        getResult: () => {
            if (sortRef && sortRef.current && sortRef.current.getResult) return sortRef.current.getResult();
        },
        isError: () => refIsRequiredError(sortRef),
    }));

    return (
        <Box className={className}>
            {totalCount > 0 && (
                <Typography component={'span'} className={'mr-1'}>
                    {getPageInfoText()}
                </Typography>
            )}
            <Typography component={'span'} className={clsx({ 'mr-2': isSort })}>{`(共${totalCount}筆)`}</Typography>
            {isSort && (
                <Select key={sortDefaultValue} ref={sortRef} defaultValue={sortDefaultValue} onChange={handleSortChange}>
                    {Array.isArray(sortOptions) &&
                        sortOptions.map(({ text, value }) => (
                            <MenuItem key={value} value={value}>
                                {text}
                            </MenuItem>
                        ))}
                </Select>
            )}
        </Box>
    );
});

export default ListInfo;
