import { useCallback } from 'react';
import { useSnackbar } from 'notistack';
import useAjax from '@apis/useAjax';

const POST_MEMBERS_CHECKOUT_API = `/api/members/checkout/`;
const GET_MEMBERS_CHECKOUT_PEOPLE_API = `/api/members/checkout/people/`;

// 置物櫃管理 api
const useMemberCheckOutApi = () => {
    const Ajax = useAjax();
    const { enqueueSnackbar } = useSnackbar();

    // 手動刷卡簽退
    const postMembersCheckoutApi = useCallback(
        async (params, memberName) => {
            const resp = await Ajax.post(POST_MEMBERS_CHECKOUT_API, params);
            if (resp) {
                enqueueSnackbar(`${memberName} 已簽退`, { variant: 'success' });
            } else {
                enqueueSnackbar(`${memberName} 簽退失敗`, { variant: 'error' });
            }
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    // 取得簽退人員清單列表
    const getMembersCheckoutPeopleOptionsApi = useCallback(
        async () => {
            const resp = await Ajax.get(GET_MEMBERS_CHECKOUT_PEOPLE_API);
            if (!resp) {
                enqueueSnackbar('讀取資料失敗,請再操作一次', { variant: 'error' });
            }
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    return { postMembersCheckoutApi, getMembersCheckoutPeopleOptionsApi };
};

export default useMemberCheckOutApi;
