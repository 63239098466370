import React, { useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { Grid } from '@mui/material';
import { Box, Card, IconButton } from '../../../components';
import { makeField } from './tools';
import { ReadTextField } from '../../components';
import { Edit as EditIcon } from '../../../components/icons/SvgIcon/';
import { InvoiceDialog } from './dialog';
import { useMemberProfileApi } from '../../../apis/useMemberProfileApi';
import { useMemberDetailContext } from './MemberDetailContext';
import clsx from 'clsx';
import useMessageDialog from '../../../util/hook/useMessageDialog';

export const getInvoiceData = (data) => {
    let { issuingType, carrierType, issuingTypeText, carrierTypeText, donationCode, carrierNo, taxID, taxTitle, invoiceNotes } = data;

    if (String(carrierType) === '4') {
        return [
            makeField('issuingType', '發票開立方式', issuingTypeText),
            makeField('carrierType', '發票載具', carrierTypeText),
            makeField('donationCode', '備註', invoiceNotes),
        ];
    } else {
        switch (String(issuingType)) {
            case '1':
                return [
                    makeField('issuingType', '發票開立方式', issuingTypeText),
                    makeField('donationCode', '愛心碼', donationCode),
                    makeField('donationCode', '備註', invoiceNotes),
                ];
            case '2':
                return [
                    makeField('issuingType', '發票開立方式', issuingTypeText),
                    makeField('carrierType', '發票載具', carrierTypeText),
                    makeField('carrierNo', '載具號碼', carrierNo || '無'),
                    makeField('donationCode', '備註', invoiceNotes),
                ];
            default:
                return [
                    makeField('issuingType', '發票開立方式', issuingTypeText),
                    makeField('taxID', '統一編號', taxID),
                    makeField('taxTitle', '發票抬頭', taxTitle),
                    makeField('donationCode', '備註', invoiceNotes),
                ];
        }
    }
};

const ProfileInvoiceCard = (props) => {
    const { className, fields, title, handleOnEdit } = props;

    return (
        <Card className={clsx('profile-invoice-card', className)}>
            {handleOnEdit && (
                <IconButton className="profile-invoice-card-btn" onClick={handleOnEdit}>
                    <EditIcon />
                </IconButton>
            )}
            <Card.CardHeader title={title} />
            <Card.CardContent className="px-4 pb-4">
                <Grid container spacing={3}>
                    {Array.isArray(fields) &&
                        fields.map((field) => (
                            <Grid key={uuid()} item xs={6}>
                                <ReadTextField label={field.label}>{field.value}</ReadTextField>
                            </Grid>
                        ))}
                </Grid>
            </Card.CardContent>
        </Card>
    );
};

const MemberDetailBasicInfoContentInvoice = (props) => {
    const { memberID } = props;
    const setMessageDialog = useMessageDialog();
    const { setRefresh } = useMemberDetailContext();
    const { getMemberProfileInvoicesApi } = useMemberProfileApi();
    const [data, setData] = useState([]);
    const [dialogOpen, setDialogOpen] = useState({
        isOpen: false,
        current: null,
    });

    const doMemberProfileInvoicesApi = async (memberID) => {
        let res = await getMemberProfileInvoicesApi(memberID);
        if (res) {
            setData(res);
        }
    };

    const handleOpenDialog = (index) => {
        setDialogOpen({
            isOpen: true,
            current: index,
        });
    };

    const handleCloseDialog = () => {
        setDialogOpen({
            isOpen: false,
            current: null,
        });
    };

    const handleEditInvoice = (index, data) => {
        const { sourceType } = data;
        if (String(sourceType) === '4') {
            setMessageDialog({
                open: true,
                title: '提醒',
                msg: '尚未開放，如需變更請洽總部人員',
            });
        } else {
            handleOpenDialog(index);
        }
    };

    useEffect(() => {
        if (memberID) {
            setRefresh(doMemberProfileInvoicesApi);
            doMemberProfileInvoicesApi(memberID);
        }
        // eslint-disable-next-line
    }, [memberID]);
    return (
        <Box className="mb-4">
            {Array.isArray(data) &&
                data.map((item, i) => {
                    let ownerProperty = {
                        className: `mb-4`,
                        key: item.memberInvoiceID,
                        fields: getInvoiceData(item),
                        title: item.title,
                    };

                    // SourceType: 4 循環訂單
                    if (String(item.sourceType) !== '4') {
                        ownerProperty.handleOnEdit = () => handleEditInvoice(i, item);
                    }
                    return <ProfileInvoiceCard {...ownerProperty} />;
                })}
            <InvoiceDialog open={dialogOpen.isOpen} memberID={memberID} data={data[dialogOpen.current]} onClose={handleCloseDialog} />
        </Box>
    );
};

export default MemberDetailBasicInfoContentInvoice;
