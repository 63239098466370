import React from 'react';
import SvgIcon from '../SvgIcon';
import useForkRef from '@util/hook/useForkRef';

const Close = React.forwardRef((props, ref) => {
    const { className, htmlColor, ...others } = props;
    const handleRef = React.useRef(null);
    const svgRef = useForkRef(handleRef, ref);
    const defaultProperty = {
        className,
        ref: svgRef,
        htmlColor,
        ...others,
    };
    return (
        <SvgIcon {...defaultProperty}>
            <path
                d="M19,16.9c-1.6-1.6-3.2-3.2-4.9-4.9c1.6-1.6,3.2-3.2,4.9-4.9L16.9,5c-1.6,1.6-3.3,3.3-4.9,4.9
	C10.4,8.3,8.8,6.6,7.1,5L5,7.1c1.6,1.6,3.3,3.3,4.9,4.9c-1.6,1.6-3.3,3.3-4.9,4.9c0.7,0.7,1.4,1.4,2.1,2.1c1.6-1.6,3.3-3.3,4.9-4.9
	c1.6,1.6,3.2,3.2,4.9,4.9C17.6,18.3,18.3,17.6,19,16.9z"
            />
        </SvgIcon>
    );
});

export default Close;
