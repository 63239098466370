import React from 'react';
import { Grid } from '@mui/material';
import * as Icons from '../../components/icons/SvgIcon/';
import { Tooltip, IconButton } from '../../components';
import { textCopypaste } from '../../util/utils';

const IconsPage = (props) => {
    const IconIterator = Object.keys(Icons);
    return (
        <Grid container className="main-container-spacing" columns={25}>
            {IconIterator.map((target, _index) => {
                const Ele = Icons[target];
                return (
                    <Grid item key={target}>
                        <Tooltip disableFocusListener title={target}>
                            <IconButton onClick={() => textCopypaste(target)}>
                                <Ele />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                );
            })}
        </Grid>
    );
};

export default IconsPage;
