import React, { useRef, useState, useImperativeHandle, useCallback, useEffect } from 'react';
import { TransitionGroup } from 'react-transition-group';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import _ from 'lodash';
import { Grid } from '@mui/material';
import ProspectFormBox from '@icoach/nonMember/prospect/ProspectFormBox';
import { NON_MEMBER_PROSPECT_OVERVIEW_ROUTER } from '@icoach/router/NonMemberRouter';
import useAjax from '@apis/useAjax';
import { refIsRequiredError } from '@util/utils';
import useShowLoading from '@util/hook/useShowLoading';
import { parseDate } from '@util/moment';
import { Plus as PlusIcon, Cancel as CancelIcon } from '@common/SvgIcon/';
import { Card, Typography, Button, Alert, SelectField, MenuItem, Collapse, DateField, IconButton, Box } from '@common/components/';

// 新增一筆名單
const OtherProspectForm = React.forwardRef((props, ref) => {
    const { introducerInfoRef, originOptin, demandOption } = props;
    const [isOther, setOther] = React.useState(0);
    const [deleteIdx, setDeleteIdx] = React.useState([]);
    const [introducerInfoState, setIntroducerInfo] = React.useState({});
    const otherRef = useRef([]);

    useImperativeHandle(
        ref,
        () => ({
            isNull: () => otherRef.current.every((item) => item === null),
            isError: () => {
                let err = false;
                otherRef.current.forEach((item) => {
                    if (item) {
                        const target = item.isError();
                        if (target) err = target;
                    }
                });
                return err;
            },
            getResult: () => {
                return otherRef.current.reduce((acc, cur) => {
                    let newAry = [...acc];
                    if (cur) newAry.push(cur.getResult());
                    return newAry;
                }, []);
            },
        }),
        // eslint-disable-next-line
        [],
    );

    const handleRemoveFriendsClick = (id) => (e) => {
        let newState = [...deleteIdx, id];
        if (isOther !== 0 && isOther === deleteIdx.length + 1) newState = [];
        if (newState.length === 0) {
            setOther(0);
            otherRef.current = [];
        }
        setDeleteIdx([...newState]);
    };

    const handleAddOtherClick = (e) => {
        const introducerInfo = introducerInfoRef.current.getMulti();
        // if (introducerInfo) {
        //
        // }
        setIntroducerInfo(introducerInfo);
        setOther((pre) => pre + 1);
    };
    const {
        // name = '',
        sourceType = null,
    } = introducerInfoState;
    return (
        <React.Fragment>
            <TransitionGroup>
                {Array(isOther)
                    .fill()
                    .map((x, idx) => {
                        if (deleteIdx.indexOf(idx) !== -1) return null;
                        return (
                            <Collapse key={idx} className="pt-3">
                                <Box className="flex flex-direction-row-reverse">
                                    <IconButton onClick={handleRemoveFriendsClick(idx)} style={{ marginBottom: '-1rem' }}>
                                        <CancelIcon />
                                    </IconButton>
                                </Box>
                                <ProspectFormBox
                                    // introducer={name}
                                    originValue={sourceType}
                                    originOptin={originOptin}
                                    demandOption={demandOption}
                                    ref={(el) => (otherRef.current[idx] = el)}
                                />
                            </Collapse>
                        );
                    })}
            </TransitionGroup>
            <Button className="mt-5" onClick={handleAddOtherClick} variant="outlined" startIcon={<PlusIcon />}>
                新增一筆名單
            </Button>
        </React.Fragment>
    );
});

const ProspectAddPage = () => {
    const { enqueueSnackbar: _snackbar } = useSnackbar();
    const isLoading = useShowLoading();
    const Ajax = useCallback(useAjax(), []);
    const [isMounted, setMounted] = useState(false);
    const [optionEnums, setOptionEnums] = useState({});
    const history = useHistory();
    const checkInDateRef = useRef(null);
    const coachRef = useRef(null);
    const prospectFormRef = useRef(null);
    const fieldListGroupRef = useRef(null);

    const handleBackToOverviewOnClick = (e) => {
        history.push(NON_MEMBER_PROSPECT_OVERVIEW_ROUTER);
    };

    const handleAddOnClick = (e) => {
        let params = [];
        if (!refIsRequiredError(checkInDateRef, prospectFormRef, coachRef, fieldListGroupRef)) {
            const employeeID = coachRef.current.getResult();
            const checkInDate = checkInDateRef.current.getResult();
            params = [{ ...prospectFormRef.current.getResult() }, ...(fieldListGroupRef.current?.getResult() ?? [])];
            params = params.map((x) => ({ ...x, employeeID, registrationDate: checkInDate }));
            doAddApi(params);
        }
    };

    const initOptionApi = async () => {
        let options = {};
        isLoading(true);
        let resp = await Ajax.get('/api/guests/overview/options');
        Object.assign(options, {
            originOptin: [...resp.customerSourceTypes], // 名單來源
            demandOption: [...resp.requiredTypes], // 需求
        });
        resp = await Ajax.get('/api/employees/options');
        Object.assign(options, { coachOption: resp }); //  教練

        resp = await Ajax.get('/api/people', { type: 5 });
        Object.assign(options, { peopleOption: resp }); // 名單
        isLoading(false);

        if (!_.isEmpty(options)) {
            setOptionEnums(options);
            setMounted(true);
        } else {
            _snackbar('資料有誤,請重新整理', { variant: 'error' });
        }
    };

    const doAddApi = async (par) => {
        const resp = await Ajax.post('/api/guests', par);
        if (resp) {
            _snackbar('新增成功', { variant: 'success' });
            history.push(NON_MEMBER_PROSPECT_OVERVIEW_ROUTER);
        } else {
            _snackbar('新增失敗', { variant: 'error' });
        }
    };

    useEffect(() => {
        initOptionApi();
        return () => setMounted(false);
        // eslint-disable-next-line
    }, []);
    const { originOptin = [], demandOption = [], coachOption = [] } = optionEnums;

    return (
        <React.Fragment>
            {isMounted && (
                <Box className="container main-container-spacing">
                    <Box component={'header'} className={'page-header'}>
                        <Typography className={'page-header-title'} variant={'h3'}>
                            新增名單
                        </Typography>
                    </Box>
                    <Card>
                        <Card.CardContent className="customer-add-apo-header">
                            <Grid container>
                                <Grid item xs={4}>
                                    <DateField ref={checkInDateRef} label="登錄日期" defaultValue={parseDate()} required lightColor fullWidth />
                                </Grid>
                                <Grid item xs={4} />
                                <Grid item xs={4}>
                                    {coachOption.length > 0 && (
                                        <SelectField ref={coachRef} name="employeeID" label={'擔當'} lightColor fullWidth displayEmpty required>
                                            <MenuItem disabled value="">
                                                請選擇
                                            </MenuItem>
                                            {coachOption.map(({ text, value, disabled }) => (
                                                <MenuItem key={value} value={value} disabled={disabled}>
                                                    {text}
                                                </MenuItem>
                                            ))}
                                        </SelectField>
                                    )}
                                </Grid>
                            </Grid>
                        </Card.CardContent>
                        <Card.CardContent className="px-3 py-4">
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <Alert color="info" severity="info" variant="filled">
                                        僅有GP名單、BR名單、自店社群、CP去店這四個來源計算名單數在報表中!
                                    </Alert>
                                </Grid>
                                <Grid item xs={12}>
                                    <ProspectFormBox ref={prospectFormRef} originOptin={originOptin} demandOption={demandOption} />
                                    <OtherProspectForm
                                        ref={fieldListGroupRef}
                                        originOptin={originOptin}
                                        demandOption={demandOption}
                                        introducerInfoRef={prospectFormRef}
                                    />
                                </Grid>
                            </Grid>
                        </Card.CardContent>
                        <Card.CardContent className="flex flex-y-flex-end flex-gap-3 pr-4 pb-3">
                            <Button variant="outlined" color="secondary" onClick={handleBackToOverviewOnClick}>
                                返回
                            </Button>
                            <Button variant="contained" color="secondary" onClick={handleAddOnClick}>
                                新增
                            </Button>
                        </Card.CardContent>
                    </Card>
                </Box>
            )}
        </React.Fragment>
    );
};

export default ProspectAddPage;
