import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { isEmpty } from 'lodash';
import useMessageDialog from '@util/hook/useMessageDialog';
import { effectTypeMap } from '@icoach/documents/cyclePurchase/staticData';
import { getCheckboxValue } from '@icoach/documents/tools';
import { refIsRequiredError } from '@util/utils';
import { Card, CheckboxField, Stack } from '@common/components/';
import { CartTable } from '@icoach/documents/table';
import { SectionHeader, useSectionEffect } from '@icoach/documents/cyclePurchase/components/CyclePurchaseUtility';

const ProductSection = forwardRef((props, ref) => {
    const { title = '訂購商品', sourceData = {}, options = {}, isCreate = false, setShowTip, onChangeEffectType: onChangeEffectTypeProps } = props;
    const { items, hasSelectedNewCalcium = false } = sourceData;
    const { products, isVisibleNewCalciumCheckbox } = options;
    const setMessageDialog = useMessageDialog();
    const [isShow, setShow] = useState(Boolean(isCreate));
    const [showCalciumCheckbox, setShowCalciumCheckbox] = useState(false);
    const pickItemRef = useRef(null);
    const itemsRef = useRef(null);
    const confirmRef = useRef(null);
    const calciumCheckboxRef = useRef(null);
    const effectTypeID = effectTypeMap.product;

    const handleShowContent = (checked) => {
        setShow(checked);
    };

    const handleProductChange = (updatedItems) => {
        if (!!products && !!updatedItems) {
            const calciumSKU = '232211CME020';
            const calciumProduct = products.find((item) => item.sku === calciumSKU);
            const specificProduct = !!calciumProduct && updatedItems.find((item) => item.productID === calciumProduct.productID);
            setShowCalciumCheckbox(!!specificProduct);
        }
    };

    const handleChangeCalciumCheckbox = () => {
        const selected = getCheckboxValue(calciumCheckboxRef.current.getResult(), {
            isMultiple: false,
        });

        if (selected) {
            setMessageDialog({
                open: true,
                title: '提醒',
                msg: '我確定不參加鈣力嗨周年慶的"持續訂購永續獎"抽獎',
            });
        }
    };

    const getResult = () => {
        let result = {};
        if (itemsRef.current && itemsRef.current.getResult) {
            result.items = itemsRef.current.getResult();
        }
        if (calciumCheckboxRef.current && calciumCheckboxRef.current.getResult) {
            result.hasSelectedNewCalcium = getCheckboxValue(calciumCheckboxRef.current.getResult(), {
                isMultiple: false,
            });
        }
        return result;
    };

    useImperativeHandle(
        ref,
        () => ({
            getResult,
            isError: () => {
                let isError = refIsRequiredError(itemsRef, confirmRef);
                let items = itemsRef.current && itemsRef.current.getResult();
                if (Array.isArray(items) && items < 1) {
                    if (!isError) isError = true;
                    setMessageDialog({
                        open: true,
                        title: '提醒',
                        msg: '至少要購買一項商品才可以送單喔，謝謝。',
                    });
                }
                return isError;
            },
            getEffectTypeID: () => isShow && effectTypeID,
            setHelperText: (isError, text) => {
                return pickItemRef.current && pickItemRef.current.setHelperText(isError, text);
            },
        }),
        // eslint-disable-next-line
        [isShow],
    );

    useSectionEffect(setShowTip, onChangeEffectTypeProps, isShow, effectTypeID);

    useEffect(
        () => {
            if (!isEmpty(sourceData.effectType)) {
                setShow(sourceData.effectType.includes(effectTypeID));
            }
            if (!isEmpty(items) && !isEmpty(products)) {
                const calciumSKU = '232211CME020';
                const calciumProduct = products.find((item) => item.sku === calciumSKU);
                const specificProduct = !!calciumProduct && items.find((item) => item.productID === calciumProduct.productID);
                setShowCalciumCheckbox(!!specificProduct && isVisibleNewCalciumCheckbox);
            }
        },
        // eslint-disable-next-line
        [sourceData],
    );

    return (
        <React.Fragment>
            <SectionHeader title={title} isShowCheckBox={!isCreate} refItem={pickItemRef} handleChange={handleShowContent} defaultChecked={isShow} />
            {isShow && (
                <Card.CardContent className={'px-4 pt-2 pb-4'}>
                    <CartTable key={items} className={'mb-4'} ref={itemsRef} data={items} resource={products} onUpdateProduct={handleProductChange} />
                    <Stack direction={'column'} className="bg-info-light px-4 py-2 mb-4">
                        <CheckboxField ref={confirmRef} required>
                            <CheckboxField.Checkbox value={true} label={'我同意於提出變更口味申請後，刷卡金額會依品項單價而不同。'} required />
                        </CheckboxField>
                        {showCalciumCheckbox && isVisibleNewCalciumCheckbox && (
                            <CheckboxField ref={calciumCheckboxRef} defaultValue={[hasSelectedNewCalcium]}>
                                <CheckboxField.Checkbox value={true} label={'選擇”新口感”鈣力嗨'} onChange={handleChangeCalciumCheckbox} />
                            </CheckboxField>
                        )}
                    </Stack>
                </Card.CardContent>
            )}
        </React.Fragment>
    );
});

export default ProductSection;
