import { DataAnalysisPage } from '@icoach/dataAnalysis';
import {
    DATA_ANALYSIS_ROUTER,
    DATA_ANALYSIS_TABS_ROUTER,
    DATA_ANALYSIS_GOAL_ROUTER,
    DATA_ANALYSIS_MEMBERSHIP_URL_ROUTER,
    DATA_ANALYSIS_SERVICE_URL_ROUTER,
    DATA_ANALYSIS_GUEST_PRODUCTION_URL_ROUTER,
    DATA_ANALYSIS_SALES_URL_ROUTER,
    DATA_ANALYSIS_EMPLOYEES_URL_ROUTER,
    DATA_ANALYSIS_TASK_MATTER_URL_ROUTER,
} from '@icoach/router/routerPath';

const dataAnalysisRouter = [
    {
        description: '營運報表',
        exact: true,
        path: DATA_ANALYSIS_TABS_ROUTER,
        href: DATA_ANALYSIS_ROUTER,
        as: DataAnalysisPage,
        frontKey: 'C5X01',
    },
    {
        description: '目標進度',
        exact: true,
        path: DATA_ANALYSIS_GOAL_ROUTER,
        as: DataAnalysisPage,
        frontKey: 'C5X02',
    },
    {
        description: '會籍',
        exact: true,
        path: DATA_ANALYSIS_MEMBERSHIP_URL_ROUTER,
        as: DataAnalysisPage,
        frontKey: 'C5X03',
    },
    {
        description: '服務',
        exact: true,
        path: DATA_ANALYSIS_SERVICE_URL_ROUTER,
        as: DataAnalysisPage,
        frontKey: 'C5X04',
    },
    {
        description: '顧客製造',
        exact: true,
        path: DATA_ANALYSIS_GUEST_PRODUCTION_URL_ROUTER,
        as: DataAnalysisPage,
        frontKey: 'C5X05',
    },
    {
        description: '非會籍',
        exact: true,
        path: DATA_ANALYSIS_SALES_URL_ROUTER,
        as: DataAnalysisPage,
        frontKey: 'C5X06',
    },
    {
        description: '教練',
        exact: true,
        path: DATA_ANALYSIS_EMPLOYEES_URL_ROUTER,
        as: DataAnalysisPage,
        frontKey: 'C5X07',
    },
    {
        description: '管理',
        exact: true,
        path: DATA_ANALYSIS_TASK_MATTER_URL_ROUTER,
        as: DataAnalysisPage,
        frontKey: 'C5X08',
    },
];

export default dataAnalysisRouter;
