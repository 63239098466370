import React, { useState } from 'react';
import useAppointment from '@icoach/appointment/components/useAppointment';
import AppointmentContactDialog from '@icoach/appointment/dialog/AppointmentContactDialog';
import SendSmsDialog from '@icoach/components/dialog/SendSmsDialog';
import { ContactRecordCard, ContactSMSRecordCard } from '@icoach/components/';
import { Card, Typography, Stack, Button, Alert } from '@common/components/';
import useAppointmentContactRecordApi from '@apis/useAppointmentContactRecordApi';
import { parseDate } from '@util/moment';
import { mergeContactAndSMSData, matchOptionsText } from '@util/utils';

const AppointmentMessageRecordItem = React.forwardRef((props, ref) => {
    const { sourceData: sourceDataProps, options } = props;
    const { refresh } = useAppointment();
    const { putAppointmentContactRecordsApi, deleteAppointmentContactRecordsApi } = useAppointmentContactRecordApi();
    const { contacts = [], sms = [], peopleID, peopleType, appointmentID, appointmentDate, name, nickName } = sourceDataProps;

    const [editContactDate, setEditContactDate] = useState(null);
    const [isOpen, setOpen] = useState(null);

    const handleAddApoContactClick = (e) => setOpen('contactDialog');

    const handleAddApoSmsClick = (e) => setOpen('sendSMS');

    const handleEditContact = (_, contactID, params) => {
        params.peopleType = peopleType;
        params.peopleID = peopleID;
        return doPutAppointmentContactApi(appointmentID, contactID, params);
    };

    const handleDeleteContact = (_, contactID) => {
        doDeleteAppointmentContactApi(appointmentID, peopleType, peopleID, contactID);
    };

    const doPutAppointmentContactApi = async (apoID, contactID, params) => {
        const resp = await putAppointmentContactRecordsApi(apoID, contactID, params);
        if (resp) {
            refresh && refresh.current && refresh.current();
        }
        return !!resp;
    };

    const doDeleteAppointmentContactApi = async (apoID, type, peoID, contactID) => {
        const resp = await deleteAppointmentContactRecordsApi(apoID, type, peoID, contactID);
        if (resp) {
            refresh && refresh.current && refresh.current();
        }
    };

    const list = mergeContactAndSMSData(contacts, sms);

    return (
        <Card className="p-3" square>
            <Stack className="pb-2" justifyContent="space-between">
                <Typography variant={'h5'}>聯繫紀錄</Typography>
                <Stack spacing={2}>
                    <Button onClick={handleAddApoSmsClick} variant="contained">
                        新增訊息
                    </Button>
                    <Button onClick={handleAddApoContactClick} variant="outlined">
                        新增聯繫
                    </Button>
                </Stack>
            </Stack>
            <Card.CardContent className="px-4 pb-3">
                {Array.isArray(list) && list.length > 0 ? (
                    <ul className="time-line contact-record">
                        {list.map((item) => {
                            return (
                                <li key={item.key} className="time-line-item">
                                    {item.isSMS ? (
                                        <ContactSMSRecordCard {...item} employeeName={matchOptionsText(options, item.employeeID, '')} />
                                    ) : (
                                        <ContactRecordCard
                                            {...item}
                                            coachName={item.employeeName}
                                            handleDeleteContact={handleDeleteContact}
                                            handleEditContact={handleEditContact}
                                        />
                                    )}
                                </li>
                            );
                        })}
                    </ul>
                ) : (
                    <Alert color="info" severity="info">
                        尚無任何聯繫資料
                    </Alert>
                )}
            </Card.CardContent>
            <AppointmentContactDialog
                refresh={refresh}
                open={isOpen === 'contactDialog'}
                onClose={() => {
                    setOpen(null);
                    setEditContactDate(null);
                }}
                sourceData={{ ...(editContactDate || {}), appointmentID, peopleID, peopleType }}
            />
            <SendSmsDialog
                refresh={refresh}
                open={isOpen === 'sendSMS'}
                onClose={() => setOpen(null)}
                sourceData={{ id: peopleID, type: peopleType, name: nickName || name, appointmentID: appointmentID }}
                affixParams={{ appointmentID: appointmentID }}
                appointmentDate={parseDate(appointmentDate, 'MM/DD(E)HH:mm')}
                isPeopleAppointment
            />
        </Card>
    );
});

export default AppointmentMessageRecordItem;
