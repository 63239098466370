import React from 'react';
import clsx from 'clsx';
import InputBase, {
    InputBaseRoot,
    InputBaseComponent as InputBaseInput,
} from '../inputBase/InputBase';
import NotchedOutline from './NotchedOutline';

const OutlinedInputRoot = React.forwardRef((props, ref) => {
    const { ownerState, children, style = {} } = props;
    const staticClass = `outlinedinput-root`;
    let classNames = staticClass;

    if (ownerState.focused) classNames = clsx(classNames, `${staticClass}-focused`);
    if (ownerState.error) classNames = clsx(classNames, `${staticClass}-error`);
    if (ownerState.disabled) classNames = clsx(classNames, `${staticClass}-disabled`);
    if (ownerState.startAdornment) classNames = clsx(classNames, `${staticClass}-startAdornment`);
    if (ownerState.endAdornment) classNames = clsx(classNames, `${staticClass}-endAdornment`);
    if (ownerState.multiline) classNames = clsx(classNames, `${staticClass}-multiline`);
    if (ownerState.multiline && ownerState.size === 'large')
        classNames = clsx(classNames, `${staticClass}-size-large`);
    return (
        <InputBaseRoot ownerState={ownerState} ref={ref} className={classNames} style={style}>
            {children}
        </InputBaseRoot>
    );
});

const OutlinedInputInput = React.forwardRef((props, ref) => {
    const { className, ownerState, ...other } = props;
    const staticClass = `outlinedinput-input`;
    let classNames = staticClass;

    if (ownerState.size === 'large') classNames = clsx(classNames, `${staticClass}-size-large`);
    if (ownerState.multiline) classNames = clsx(classNames, `${staticClass}-multiline`);
    if (ownerState.startAdornment) classNames = clsx(classNames, `${staticClass}-startAdornment`);
    if (ownerState.endAdornment) classNames = clsx(classNames, `${staticClass}-endAdornment`);
    return (
        <InputBaseInput
            className={clsx(classNames, className)}
            ownerState={ownerState}
            ref={ref}
            {...other}
        />
    );
});

const NotchedOutlineRoot = (props) => {
    const { label, notched } = props;
    return <NotchedOutline label={label} notched={notched} />;
};

const OutlinedInput = React.forwardRef((props, ref) => {
    const {
        components = {},
        fullWidth = false,
        InputProps = {},
        // inputComponent = 'input',
        label,
        multiline = false,
        notched,
        type = 'text',
        inputRootStyle = {},
        inputStyle = {},
        ...other
    } = props;

    return (
        <InputBase
            components={{
                Root: OutlinedInputRoot,
                Input: OutlinedInputInput,
                rootStyle: inputRootStyle,
                inputStyle: inputStyle,
                ...components,
            }}
            renderSuffix={(state) => (
                <NotchedOutlineRoot
                    label={label}
                    notched={
                        typeof notched !== 'undefined'
                            ? notched
                            : Boolean(state.startAdornment || state.filled || state.focused)
                    }
                />
            )}
            multiline={multiline}
            fullWidth={fullWidth}
            ref={ref}
            type={type}
            {...InputProps}
            {...other}
        />
    );
});

OutlinedInput.curName = 'Input';

export default OutlinedInput;
