import React, { useEffect } from 'react';
import { Worker, Viewer, ProgressBar } from '@react-pdf-viewer/core';
import { toolbarPlugin } from '@react-pdf-viewer/toolbar';
import { zoomPlugin } from '@react-pdf-viewer/zoom';
import useMessageDialog from '@util/hook/useMessageDialog';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import {
    Close as CloseIcon,
    Print as PrintIcon,
    Download as DownloadIcon,
    OpenInFull as OpenInFullIcon,
    MagnifyingGlassMinus as MagnifyingGlassMinusIcon,
    MagnifyingGlassPlus as MagnifyingGlassPlusIcon,
} from '@common/SvgIcon/';
import { IconButton, Button, Stack, Box } from '@common/components/';
import usePrintApi from '@apis/usePrintApi';

const PrevNextButtons = (props) => {
    const { isShowPrev = false, isShowNext = false, handlePrev, handleNext } = props;
    return (
        <React.Fragment>
            <Button variant="outlined" onClick={handlePrev} disabled={!isShowPrev}>
                上一份
            </Button>
            <Button variant="outlined" onClick={handleNext} disabled={!isShowNext}>
                下一份
            </Button>
        </React.Fragment>
    );
};

const PdfComponent = React.forwardRef((props, ref) => {
    const { pdfUrl = '', onClose: onCloseProps = () => {} } = props;
    const setMessageDialog = useMessageDialog();
    const { postPrintApi } = usePrintApi();
    const zoomPluginInstance = zoomPlugin();
    const { ZoomIn, ZoomOut, Zoom } = zoomPluginInstance;
    const toolbarPluginInstance = toolbarPlugin();
    const { Toolbar } = toolbarPluginInstance;
    const [pdfUrls, setPdfUrls] = React.useState([]);
    const [currentIndex, setCurrentIndex] = React.useState(0);
    const currentPdfUrl = pdfUrls[currentIndex] || '';

    const handlePrinter = () => {
        setMessageDialog({
            open: true,
            title: '列印PDF',
            msg: '是否要列印？',
            onConfirm: doPrintApi,
        });
    };

    const doPrintApi = async () => {
        await postPrintApi(currentPdfUrl);
    };

    useEffect(
        () => {
            if (Array.isArray(pdfUrl)) {
                setPdfUrls(pdfUrl);
            } else {
                setPdfUrls([pdfUrl]);
            }
        },
        // eslint-disable-next-line
        [pdfUrl]
    );

    return (
        // @ts-ignore
        <Worker workerUrl={'https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js'}>
            <Box
                className="rpv-core-viewer"
                style={{
                    border: '1px solid rgba(0, 0, 0, 0.3)',
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                }}
            >
                <Box
                    style={{
                        alignItems: 'center',
                        backgroundColor: '#eeeeee',
                        borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
                        display: 'flex',
                        padding: '4px',
                    }}
                >
                    <Toolbar>
                        {(props) => {
                            const { Download, EnterFullScreen } = props;
                            return (
                                <React.Fragment>
                                    <Box style={{ padding: '0px 2px' }}>
                                        <Zoom>
                                            {({ onZoom }) => {
                                                return (
                                                    <Stack spacing={1}>
                                                        {pdfUrls.length > 1 && (
                                                            <PrevNextButtons
                                                                isShowPrev={currentIndex > 0}
                                                                isShowNext={currentIndex < pdfUrls.length - 1}
                                                                handlePrev={() => setCurrentIndex(currentIndex - 1)}
                                                                handleNext={() => setCurrentIndex(currentIndex + 1)}
                                                            />
                                                        )}
                                                        <Button
                                                            variant="outlined"
                                                            onClick={() => {
                                                                onZoom('ActualSize');
                                                            }}
                                                        >
                                                            實際大小
                                                        </Button>
                                                        <Button
                                                            variant="outlined"
                                                            onClick={() => {
                                                                onZoom('PageFit');
                                                            }}
                                                        >
                                                            適當大小
                                                        </Button>
                                                        <Button
                                                            variant="outlined"
                                                            onClick={() => {
                                                                onZoom('PageWidth');
                                                            }}
                                                        >
                                                            頁面寬
                                                        </Button>
                                                    </Stack>
                                                );
                                            }}
                                        </Zoom>
                                    </Box>
                                    <Box style={{ padding: '0px 2px' }}>
                                        <ZoomOut>
                                            {({ onClick: onClickProps }) => {
                                                return (
                                                    <IconButton onClick={onClickProps}>
                                                        <MagnifyingGlassMinusIcon />
                                                    </IconButton>
                                                );
                                            }}
                                        </ZoomOut>
                                    </Box>
                                    <Box style={{ padding: '0px 2px' }}>
                                        <ZoomIn>
                                            {({ onClick: onClickProps }) => {
                                                return (
                                                    <IconButton onClick={onClickProps}>
                                                        <MagnifyingGlassPlusIcon />
                                                    </IconButton>
                                                );
                                            }}
                                        </ZoomIn>
                                    </Box>
                                    <Box style={{ padding: '0px 2px', marginLeft: 'auto' }}>
                                        <EnterFullScreen>
                                            {({ onClick: onClickProps }) => {
                                                return (
                                                    <IconButton onClick={onClickProps}>
                                                        <OpenInFullIcon />
                                                    </IconButton>
                                                );
                                            }}
                                        </EnterFullScreen>
                                    </Box>
                                    <Box style={{ padding: '0px 2px' }}>
                                        <Download>
                                            {({ onClick: onClickProps }) => {
                                                return (
                                                    <IconButton onClick={onClickProps}>
                                                        <DownloadIcon />
                                                    </IconButton>
                                                );
                                            }}
                                        </Download>
                                    </Box>
                                    <Box style={{ padding: '0px 2px' }}>
                                        <IconButton onClick={handlePrinter}>
                                            <PrintIcon />
                                        </IconButton>
                                    </Box>
                                    <Box style={{ padding: '0px 2px' }}>
                                        <IconButton onClick={onCloseProps}>
                                            <CloseIcon />
                                        </IconButton>
                                    </Box>
                                </React.Fragment>
                            );
                        }}
                    </Toolbar>
                </Box>

                <Box
                    style={{
                        flex: 1,
                        overflow: 'hidden',
                    }}
                >
                    {/* @ts-ignore */}
                    <Viewer
                        renderLoader={(percentages) => (
                            <Box style={{ width: '240px' }}>
                                <ProgressBar progress={Math.round(percentages)} />
                            </Box>
                        )}
                        fileUrl={currentPdfUrl}
                        plugins={[toolbarPluginInstance, zoomPluginInstance]}
                    />
                </Box>
            </Box>
        </Worker>
    );
});

export default PdfComponent;
