import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Button, Card, Typography } from '@common/components/';
import { MembershipQuitHistoryTable } from '@icoach/documents/table/';
import useDocMembershipQuitApi from '@apis/useDocMembershipQuitApi';

const MembershipQuitHistory = (props) => {
    const { onBlankContractClick } = props;
    const history = useHistory();
    const { referenceID: referenceIDProps } = useParams();
    const { getDocMembershipQuitHistoryApi } = useDocMembershipQuitApi();
    const [sourceData, setSourceData] = useState([]);

    // 讀取該對象的歷史紀錄
    const doDocMembershipQuitHistoryApi = async (id) => {
        let resp = await getDocMembershipQuitHistoryApi({ id });
        if (resp) {
            setSourceData(resp);
        }
    };

    // 按鈕 建立新表單
    const handleCreateButtonClick = () => {
        onBlankContractClick && onBlankContractClick();
    };

    useEffect(
        () => {
            doDocMembershipQuitHistoryApi(referenceIDProps);
        },
        // eslint-disable-next-line
        []
    );

    useEffect(
        () => {
            // 重刷頁面才會用 REPLACE
            if (history.action === 'REPLACE') {
                doDocMembershipQuitHistoryApi(referenceIDProps);
            }
        },
        // eslint-disable-next-line
        [history.location]
    );

    return (
        <Card>
            <Card.CardContent className="document-history-list">
                <header className={'page-header'}>
                    <Typography className={'page-header-title'} variant={'h3'}>
                        歷史紀錄
                    </Typography>
                    <Button
                        name="create"
                        variant="outlined"
                        color="secondary"
                        onClick={handleCreateButtonClick}
                    >
                        建立新表單
                    </Button>
                </header>
                <MembershipQuitHistoryTable className={'theme-gray'} rowsData={sourceData} />
            </Card.CardContent>
        </Card>
    );
};

export default MembershipQuitHistory;
