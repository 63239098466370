import React, { useRef } from 'react';
import clsx from 'clsx';
import { Typography } from '../../../components';
import Tablenization from '../../components/Tablenization';
import { ProductBox } from '../components';
import { ReturnTitleComponent } from './ReadOnlyProductCartTable';
import { formatCurrencyFn, isEmpty } from '../../../util/utils';
import { MemoControllerBox } from '@icoach/components';

const rowsLabel = [
    {
        cellKey: 'productID',
        headerLabel: '品項',
        className: 'vertical-align-middle',
        formatCell: (cellValue, { pictureSrc, productName }) => {
            return <ProductBox className={'fee-item'} imgUrl={pictureSrc} name={productName} />;
        },
    },
    {
        cellKey: 'productTypeText',
        headerLabel: '類型',
        align: 'center',
        className: 'vertical-align-middle',
        formatCell: (cellValue, { returnItems }) => {
            return (
                <React.Fragment>
                    <Typography className={'mb-0'}>{cellValue}</Typography>
                    {!isEmpty(returnItems) && returnItems.map((item) => <ReturnTitleComponent key={item.orderReturnID} date={item.orderReturnDate} />)}
                </React.Fragment>
            );
        },
    },
    {
        cellKey: 'unitPrice',
        headerLabel: '價格',
        align: 'right',
        minWidth: 100,
        className: 'vertical-align-middle',
        formatCell: (cellValue, { returnItems }) => {
            return (
                <React.Fragment>
                    <Typography className={'mb-0'}>{formatCurrencyFn(cellValue)}</Typography>
                    {!isEmpty(returnItems) &&
                        returnItems.map((item) => (
                            <Typography key={item.orderReturnID} className={'text-error'}>
                                {formatCurrencyFn(item.returnUnitPrice)}
                            </Typography>
                        ))}
                </React.Fragment>
            );
        },
    },
    {
        cellKey: 'notes',
        headerLabel: '備註',
        className: 'vertical-align-middle',
        formatCell: (cellValue) => {
            return <MemoControllerBox data={cellValue} dialogTitle={'備註'} />;
        },
    },
];

const ReadOnlyProductCartTable = (props) => {
    const { className, rowsData = [] } = props;
    const labelTitle = useRef(rowsLabel);
    return (
        <React.Fragment>
            <Tablenization cardProps={{ className: clsx('read-only-cart-table', className) }}>
                <Tablenization.TableRowHeader headerRow={labelTitle.current} />
                <Tablenization.TableRowBody headerRow={labelTitle.current} rowsData={rowsData} />
            </Tablenization>
        </React.Fragment>
    );
};

export default ReadOnlyProductCartTable;
