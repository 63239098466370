import React from 'react';
import SvgIcon from '../SvgIcon';
import useForkRef from '@util/hook/useForkRef';

const Gift = React.forwardRef((props, ref) => {
    const { className, htmlColor = '#6E488B', ...others } = props;
    const handleRef = React.useRef(null);
    const svgRef = useForkRef(handleRef, ref);
    const defaultProperty = {
        className,
        ref: svgRef,
        htmlColor,
        ...others,
    };
    return (
        <SvgIcon {...defaultProperty}>
            <path
                d="M12,0C5.4,0,0,5.4,0,12s5.4,12,12,12s12-5.4,12-12S18.6,0,12,0z M12.5,6.3L12.8,5l0.3-1.2
	c0-0.1,0.1-0.2,0.2-0.3c0.2-0.1,0.4-0.1,0.5,0.1l0.9,0.9l0.9,0.9c0.1,0.1,0.1,0.1,0.1,0.2c0.1,0.2-0.1,0.5-0.3,0.5l-1.2,0.3L13,6.8
	c-0.1,0-0.1,0-0.2,0C12.6,6.7,12.4,6.5,12.5,6.3z M8.3,5.5l0.9-0.9l0.9-0.9c0.1-0.1,0.1-0.1,0.2-0.1c0.2-0.1,0.5,0.1,0.5,0.3L11.2,5
	l0.3,1.3c0,0.1,0,0.1,0,0.2c-0.1,0.2-0.3,0.4-0.5,0.3L9.7,6.5L8.5,6.2c-0.1,0-0.1-0.1-0.2-0.1C8.1,5.9,8.1,5.6,8.3,5.5z M11.5,19
	H7.7c-0.7,0-1.2-0.5-1.2-1.2v-5.6h4.9V19z M17.4,18c0,0.6-0.5,1.1-1.1,1.1h-3.9v-6.8h4.9V18z M18.6,10.5c0,0.4-0.3,0.8-0.8,0.8H6.2
	c-0.4,0-0.8-0.3-0.8-0.8v-2c0-0.4,0.3-0.8,0.8-0.8h11.6c0.4,0,0.8,0.3,0.8,0.8V10.5z"
            />
        </SvgIcon>
    );
});

export default Gift;
