import React from 'react';
import clsx from 'clsx';
import TableContext from './TableContext';

const staticClass = `table`;

const Table = React.forwardRef((props, ref) => {
    const {
        className,
        padding = 'normal',
        size = 'medium',
        stickyHeader = false,
        height,
        ...other
    } = props;
    let classNames = staticClass;

    if (stickyHeader) classNames = clsx(classNames, `${staticClass}-sticky-header`);

    const table = React.useMemo(() => ({ padding, size, stickyHeader }), [
        padding,
        size,
        stickyHeader,
    ]);

    const defaultProperty = {
        className: clsx(classNames, className),
        ref,
        ...other,
    };

    if (height) defaultProperty.style = { height: height };

    return (
        <TableContext.Provider value={table}>
            <table {...defaultProperty} />
        </TableContext.Provider>
    );
});

export default Table;
