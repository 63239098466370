import React, { useRef, useState, useImperativeHandle, useEffect } from 'react';
import { createPortal } from 'react-dom';
import { v4 as uuid } from 'uuid';

const TakePhotoBox = React.memo(
    React.forwardRef((props, ref) => {
        const { onFileChange: onFileChangeProps } = props;
        const [fileState, setFileState] = useState(null);
        const inputFileRef = useRef(null);

        const handleClearFile = () => {
            setFileState(null);
            onFileChangeProps(null, null);
        };

        const handleFileOnChange = (e) => {
            const files = e.target.files[0];
            if (!files) {
                console.error('No File upload');
                return;
            }
            const [fileExtension, fileName] = files.name.split('.').reverse();
            const resource = {
                extension: fileExtension,
                name: fileName,
                file: files,
                blobSrc: URL.createObjectURL(files),
            };
            setFileState(resource);
            onFileChangeProps && onFileChangeProps(resource);
        };

        useImperativeHandle(
            ref,
            () => {
                return {
                    open: () => inputFileRef.current.click(),
                    getResult: () => fileState,
                    clearFile: () => handleClearFile(),
                };
            },
            // eslint-disable-next-line
            [fileState]
        );

        useEffect(
            () => {
                const USERAGENT = navigator.userAgent.toLowerCase();
                if (inputFileRef.current && USERAGENT.indexOf('android') !== -1) {
                    inputFileRef.current.setAttribute('capture', 'camera');
                }
            },
            // eslint-disable-next-line
            []
        );

        return (
            <React.Fragment>
                {createPortal(
                    <input
                        key={uuid()}
                        ref={inputFileRef}
                        type="file"
                        accept="image/*"
                        onChange={handleFileOnChange}
                        style={{
                            display: 'none',
                            opacity: 0,
                            position: 'absolute',
                            top: 0,
                            left: 0,
                        }}
                    />,
                    document.body
                )}
            </React.Fragment>
        );
    })
);

export default TakePhotoBox;
