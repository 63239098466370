import React from 'react';
import SvgIcon from '../SvgIcon';
import useForkRef from '../../../util/hook/useForkRef';

const CaOrdered = React.forwardRef((props, ref) => {
    const { className, htmlColor = '#4F9E97', ...others } = props;
    const handleRef = React.useRef(null);
    const svgRef = useForkRef(handleRef, ref);
    const defaultProperty = {
        className,
        ref: svgRef,
        htmlColor,
        ...others,
    };
    return (
        <SvgIcon {...defaultProperty}>
            <path
                d="M12,0C5.4,0,0,5.4,0,12s5.4,12,12,12s12-5.4,12-12S18.6,0,12,0z M5.7,10.6c-0.5-0.2-1-1.9-0.7-2.2
	C5.4,8.2,7.2,8,7.3,7.6C7.4,7.2,6.6,7,7.2,6.4s1-0.5,1.1-0.1c0.1,0.4,0.2,0.7,0.5,0.5C9,6.8,8.7,6.3,9.2,6.3
	c0.4-0.1,1.7-0.5,2.7-1.1c1-0.5,1.9-1,2.1-0.4c0.3,0.7,0.4,1.2,0.8,1.8c0.4,0.6,0.4,1.9-0.2,2.2c-0.6,0.4-3.6,1.4-4.3,1.3
	C9.7,10,9.4,8.8,9,8.8S8.6,8.9,8.4,9.6C8.1,10.3,6.2,10.8,5.7,10.6z M7.4,15.1c-0.6-0.4-0.8-1.6-0.3-1.9c0.5-0.4,1.1-0.6,1.1-0.8
	c0-0.3-0.3-0.7,0.1-1c0.4-0.3,1-0.6,1.1-0.1c0.2,0.6,0.3,1,0.8,0.9c0.5-0.1,0.2-1,0.5-1.2l0,0c0.3-0.2,3.5-1.3,4.1-1.4
	c0.6-0.2,1.1-0.2,1.1,0.5s0.2,1.1,0.8,1.7c0.5,0.6,0.4,1.4-0.1,1.5c-0.5,0.1-1.6,0-2.5,0.3s-1.5,0.6-1.9,0.2c-0.4-0.4-0.8-1.2-1.1-1
	c-0.4,0.2-0.2,0.7-0.7,1c-0.5,0.3-1.1,0.3-1.6,0.6C8.6,14.7,8,15.5,7.4,15.1z M17.1,16.6c0.2,0.7,0.8,2.2-0.1,2.8S15.4,19,14.7,19
	s-1.4,0.2-1.7-0.4c-0.3-0.6,0-0.9-0.8-0.6c-0.7,0.3-1.4,0.5-1.9,1c-0.4,0.5-1.3,0.8-1.4-0.5c-0.1-1.3-0.2-1.6,0.5-1.8
	c0.7-0.1,1.4,0.1,1.6-0.2c0.2-0.3-1-0.8-0.7-1.4c0.3-0.6,1.3-0.8,1.5-0.2c0.2,0.6-0.2,1.7,0.7,1.4h0c0.4-0.1,0.2-1,0.5-1.5
	s4.1-1.2,4.4-0.6C17.8,14.9,17,15.9,17.1,16.6z"
            />
        </SvgIcon>
    );
});

export default CaOrdered;
