import React, { useRef } from 'react';
import Tablenization from '@icoach/components/Tablenization';
import { parseDate } from '@util/moment';
import { pathRouterShit } from '@util/utils';
import { moreColData } from '@icoach/documents/components/moreColData';
import { useHistory, useParams } from 'react-router-dom';
import {
    DOCUMENTS_MEMBERSHIP_PEOPLETYPE_REFERENCEID_HISTORY_ROUTER,
    DOCUMENTS_MEMBERSHIP_PEOPLETYPE_REFERENCEID_CONTRACT_ROUTER,
    DOCUMENTS_MEMBERSHIP_PEOPLETYPE_REFERENCEID_READONLY_CONTRACT_ROUTER,
} from '@icoach/router/routerPath';
import useDocMembershipApi from '@apis/useDocMembershipApi';
import useToggleDialog from '@util/hook/useToggleDialog';
import { ConfirmDeleteDocDialog } from '@icoach/documents/membership/dialog';
import { dialogTypeMap } from '@icoach/documents/membership/staticData';
import { Typography, Alert, Box } from '@common/components/';
import useMessageDialog from '@util/hook/useMessageDialog';
import { DateTimeStandard } from '../../../components/pickers/enums/DateTimeStandard';
import { isBoolean } from 'lodash';

const rowsLabel = [
    {
        cellKey: 'applyDate',
        headerLabel: '申請時間',
        formatCell: (cellValue) => parseDate(cellValue, DateTimeStandard.DisplayDateBySlash),
    },
    {
        cellKey: 'changeDate',
        headerLabel: '最後更新時間',
        formatCell: (cellValue) => parseDate(cellValue, DateTimeStandard.DisplayFullBySlash),
    },
    {
        cellKey: 'isTemporary',
        headerLabel: '狀態',
        align: 'center',
        formatCell: (isTemporary, { isDeleted }) => {
            return isTemporary ? '暫存' : isDeleted ? '已刪除' : '已受理';
        },
    },
    {
        cellKey: 'membershipRange',
        headerLabel: '會籍時間',
        align: 'center',
    },
    {
        cellKey: 'note',
        headerLabel: '備註',
        formatCell: (cellValue, rowData) => {
            const { note } = rowData;
            return <Box className="note">{note}</Box>;
        },
    },
    moreColData,
];

const MembershipCreateHistoryTable = (props) => {
    const { className, rowsData = [] } = props;
    const history = useHistory();
    const setMessageDialog = useMessageDialog();
    const { peopleType: peopleTypeProps, referenceID: referenceIDProps } = useParams();
    const { getDocMembershipPDFApi, deleteDocMembershipTemporaryApi, deleteDocMembershipApi, getCheckDocMembershipCanDeleteApi } = useDocMembershipApi();
    const { openTarget, handleOpenDialog, handleCloseDialog, rowData } = useToggleDialog();
    const labelTitle = useRef(rowsLabel);

    // 查看
    const handleReviewOnClick = (rowData) => {
        const { contractID } = rowData;
        const path = pathRouterShit(DOCUMENTS_MEMBERSHIP_PEOPLETYPE_REFERENCEID_READONLY_CONTRACT_ROUTER, {
            peopleType: peopleTypeProps,
            referenceID: referenceIDProps,
            contractID: contractID,
        });
        history.push(path);
    };

    // 編輯
    const handleEditOnClick = (rowData) => {
        const { contractID } = rowData;
        const path = pathRouterShit(DOCUMENTS_MEMBERSHIP_PEOPLETYPE_REFERENCEID_CONTRACT_ROUTER, {
            peopleType: peopleTypeProps,
            referenceID: referenceIDProps,
            contractID: contractID,
        });
        history.push(path);
    };

    // 下載
    const handleDownloadPDFOnClick = (rowData) => {
        const { contractUrl } = rowData;
        getDocMembershipPDFApi(contractUrl);
    };

    const handleDeleteDocumentOnClick = (targetData) => {
        const { isTemporary } = targetData;
        if (isTemporary) {
            deleteTemporaryDocumentMembershipsApi();
        } else {
            deleteDocumentMembershipsApi(targetData);
        }
    };

    // 刪除暫存文書
    const deleteTemporaryDocumentMembershipsApi = async () => {
        await deleteDocMembershipTemporaryApi({
            type: peopleTypeProps,
            id: referenceIDProps,
        });
    };

    // 判斷刪除正式文書資料
    const deleteDocumentMembershipsApi = async (rowData) => {
        const { contractID } = rowData;
        const resp = await getCheckDocMembershipCanDeleteApi(contractID);
        console.log(resp);
        if (isBoolean(resp)) {
            if (resp) {
                handleOpenDialog(dialogTypeMap.deleteDoc, { rowData });
            } else {
                handleCloseDialog();
                setMessageDialog({
                    open: true,
                    title: '刪除入會申請書',
                    msg: '會員已超過審閱期或者鑑賞期，無法刪除，請執行一般退會流程',
                    buttonText: '了解',
                    DialogActionsProps: { className: 'flex-y-flex-center' },
                });
            }
        }
    };

    // 刪除入會申請書
    const doDeleteApi = async (rowData) => {
        const { contractID, pushType, pushReasonText } = rowData;
        const resp = await deleteDocMembershipApi({ pushType, pushReasonText }, contractID);
        return resp;
    };

    return (
        <React.Fragment>
            <Tablenization cardProps={{ className: className }}>
                <Tablenization.TableRowHeader headerRow={labelTitle.current} />
                <Tablenization.TableRowBody
                    headerRow={labelTitle.current}
                    rowsData={rowsData}
                    onReviewEvent={handleReviewOnClick}
                    onEditEvent={handleEditOnClick}
                    onDownloadPDFEvent={handleDownloadPDFOnClick}
                    onDeleteOnEvent={(rowData) => {
                        handleDeleteDocumentOnClick(rowData);
                    }}
                />
            </Tablenization>
            <ConfirmDeleteDocDialog
                title={rowData?.isTemporary ? '確認刪除暫存入會申請書' : '確認刪除入會申請書'}
                message={
                    <Typography>
                        刪除後資料就<strong className={'text-error'}>無法恢復</strong>囉！
                        {rowData?.isTemporary ? '' : '將計數一筆NoSale在FA服務擔當上'}
                    </Typography>
                }
                confirmMessage={rowData?.isTemporary ? '會員已同意刪除此文書' : '已了解刪除文書時，該會員將無法享有會員權益。'}
                alertMessage={
                    !rowData?.isTemporary && (
                        <Alert severity="info" variant="filled" color="info" className="mb-3">
                            提醒您！要記得去作廢或折讓此筆訂單喔！
                        </Alert>
                    )
                }
                open={openTarget === dialogTypeMap.deleteDoc}
                onClose={handleCloseDialog}
                data={rowData}
                deleteDocApi={doDeleteApi}
                refresh={() => {
                    const path = pathRouterShit(DOCUMENTS_MEMBERSHIP_PEOPLETYPE_REFERENCEID_HISTORY_ROUTER, {
                        peopleType: peopleTypeProps,
                        referenceID: referenceIDProps,
                    });
                    history.replace(path);
                }}
            />
        </React.Fragment>
    );
};

export default MembershipCreateHistoryTable;
