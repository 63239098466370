import { useCallback } from 'react';
import useAjax from '@apis/useAjax';
import { checkResponse } from '@apis/utils';

const GET_GIFT_ITEM_RECORD_OPTIONS_API = `/api/gift-item/record/options/`;
const GET_GIFT_ITEM_RECORD_STATISTICS_API = `/api/gift-item/record/statistics/`;
const GET_GIFT_ITEM_RECORD_OVERVIEW_API = `/api/gift-item/record/overview/`;
const POST_GIFT_ITEM_RECORD_API = `/api/gift-item/record/`;
const DELETE_GIFT_ITEM_RECORD_API = `/api/gift-item/record/`;
const PUT_GIFT_ITEM_RECORD_CLAIMED_API = (recordID) => `/api/gift-item/record/${recordID}/claimed/`;

const useGiftItemRecordApi = () => {
    const Ajax = useAjax();

    const getGiftItemRecordOptionsApi = useCallback(
        async (params) => {
            const resp = await Ajax.get(GET_GIFT_ITEM_RECORD_OPTIONS_API, params);
            if (checkResponse(resp)) return resp;
            return false;
        },
        // eslint-disable-next-line
        []
    );

    const getGiftItemRecordStatisticsApi = useCallback(
        async (params) => {
            const resp = await Ajax.get(GET_GIFT_ITEM_RECORD_STATISTICS_API, params);
            if (checkResponse(resp)) return resp;
            return false;
        },
        // eslint-disable-next-line
        []
    );

    const getGiftItemRecordOverviewApi = useCallback(
        async (params) => {
            const resp = await Ajax.get(GET_GIFT_ITEM_RECORD_OVERVIEW_API, params);
            if (checkResponse(resp)) return resp;
            return false;
        },
        // eslint-disable-next-line
        []
    );

    const postGiftItemRecordApi = useCallback(
        async (params) => {
            const resp = await Ajax.post(POST_GIFT_ITEM_RECORD_API, params);
            if (checkResponse(resp)) return resp;
            return false;
        },
        // eslint-disable-next-line
        []
    );

    const deleteGiftItemRecordApi = useCallback(
        async (params) => {
            const resp = await Ajax.delete(DELETE_GIFT_ITEM_RECORD_API, params);
            if (checkResponse(resp)) return resp;
            return false;
        },
        // eslint-disable-next-line
        []
    );

    const putGiftItemClaimedApi = useCallback(
        async (recordID, params) => {
            const resp = await Ajax.put(PUT_GIFT_ITEM_RECORD_CLAIMED_API(recordID), params);
            if (checkResponse(resp)) return resp;
            return false;
        },
        // eslint-disable-next-line
        []
    );

    return {
        getGiftItemRecordOptionsApi,
        getGiftItemRecordStatisticsApi,
        getGiftItemRecordOverviewApi,
        postGiftItemRecordApi,
        deleteGiftItemRecordApi,
        putGiftItemClaimedApi,
    };
};

export default useGiftItemRecordApi;
