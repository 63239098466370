import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { getFirstOfMonth, parseDate } from '@util/moment';
import { effectTypeMap } from '@icoach/documents/cyclePurchase/staticData';
import { isEmpty } from 'lodash';
import { refIsRequiredError } from '@util/utils';
import moment from 'moment';
import { Card, DateField } from '@common/components/';
import { Grid } from '@mui/material';
import { getEffectiveDate, SectionHeader, SelectWithMenuItems, useSectionEffect } from '@icoach/documents/cyclePurchase/components/CyclePurchaseUtility';

const ShippingSection = forwardRef((props, ref) => {
    const {
        title = '出貨資訊',
        sourceData = {},
        effectDate,
        options = {},
        isCreate = false,
        setShowTip,
        onChangeEffectType: onChangeEffectTypeProps,
        onChangeShipping,
    } = props;
    const effectTypeID = effectTypeMap.shippingDay;
    const { shippingDay } = sourceData;
    const { shippingDayOptions } = options;
    const [isShow, setShow] = useState(Boolean(isCreate));
    const [minDate, setMinDate] = useState(parseDate());
    const [firstShippingDate, setFirstShippingDate] = useState('');
    const pickItemRef = useRef(null);
    const shippingDayRef = useRef(null);
    const firstShippingMonthRef = useRef(null);

    const handleShowContent = (checked) => {
        setShow(checked);
    };

    const getResult = () => {
        const result = {};
        if (firstShippingMonthRef.current && firstShippingMonthRef.current.getResult) {
            result.firstShipmentMonth = getFirstOfMonth(firstShippingMonthRef.current.getResult());
        }
        if (shippingDayRef.current && shippingDayRef.current.getResult) {
            result.shippingDay = shippingDayRef.current.getResult() || 0;
        }

        return result;
    };

    const handleMinDate = (effectDate, shippingDay) => {
        setMinDate(getEffectiveDate(effectDate, shippingDay));
    };

    const getShippingDayText = (ShippingDayType) => {
        return shippingDayOptions.find((item) => item.value === ShippingDayType);
    };

    const handleChangeShippingDay = (e, n, v) => {
        let currentData = getShippingDayText(v);
        if (!isEmpty(currentData)) {
            handleMinDate(effectDate, currentData.text);
        }
        if (typeof onChangeShipping === 'function') {
            onChangeShipping(e, n, v, currentData);
        }

        setFirstShippingDate(getEffectiveDate(effectDate, currentData.text));
    };

    useImperativeHandle(
        ref,
        () => ({
            getResult,
            isError: () => refIsRequiredError(firstShippingMonthRef, shippingDayRef),
            getShippingDay: () => {
                let result;
                let shippingDay = shippingDayRef.current && shippingDayRef.current.getResult();
                if (shippingDay) {
                    result = shippingDayOptions.find((item) => item.value === shippingDay);
                }
                return result;
            },
            getEffectTypeID: () => isShow && effectTypeID,
            setHelperText: (isError, text) => {
                return pickItemRef.current && pickItemRef.current.setHelperText(isError, text);
            },
        }),
        // eslint-disable-next-line
        [shippingDayOptions, isShow],
    );

    useSectionEffect(setShowTip, onChangeEffectTypeProps, isShow, effectTypeID);

    useEffect(() => {
        if (!isEmpty(sourceData.effectType)) {
            setShow(sourceData.effectType.includes(effectTypeID));
        }

        if (sourceData.firstShipmentMonth && moment(sourceData.firstShipmentMonth) > moment()) {
            setFirstShippingDate(sourceData.firstShipmentMonth);
        }
        // eslint-disable-next-line
    }, [sourceData]);

    useEffect(() => {
        if (effectDate) {
            let shippingDay;
            if (shippingDayRef.current && shippingDayRef.current.getResult) {
                shippingDay = shippingDayRef.current.getResult();
            }
            if (shippingDay) {
                handleMinDate(effectDate, shippingDay);
            }
        }
    }, [effectDate]);

    return (
        <React.Fragment>
            <SectionHeader title={title} isShowCheckBox={!isCreate} refItem={pickItemRef} handleChange={handleShowContent} defaultChecked={isShow} />
            {isShow && (
                <Card.CardContent className={'p-4'}>
                    <Grid spacing={3} container>
                        <Grid xs={4} item>
                            <SelectWithMenuItems
                                refItem={shippingDayRef}
                                label="出貨週期"
                                defaultValue={shippingDay || null}
                                options={shippingDayOptions}
                                onChange={handleChangeShippingDay}
                                fullWidth
                                required
                            />
                        </Grid>
                        {isCreate && (
                            <Grid xs={4} item>
                                <DateField
                                    key={firstShippingDate}
                                    ref={firstShippingMonthRef}
                                    label={'首次出貨月份'}
                                    defaultValue={firstShippingDate}
                                    views={['year', 'month']}
                                    openTo={'month'}
                                    format={'YYYY/MM'}
                                    mask={'____/__'}
                                    minDate={minDate}
                                    placeholder={'請選擇月份'}
                                    fullWidth
                                    required
                                />
                            </Grid>
                        )}
                    </Grid>
                </Card.CardContent>
            )}
        </React.Fragment>
    );
});

export default ShippingSection;
