import React, { createContext, useContext } from 'react';

const DataAnalysisContext = createContext(undefined);

export const DataAnalysisProvider = ({ children, value }) => {
    return <DataAnalysisContext.Provider value={value}>{children}</DataAnalysisContext.Provider>;
};

export const useDataAnalysisContext = () => {
    const context = useContext(DataAnalysisContext);
    if (context === undefined) {
        throw new Error('useDataAnalysisContext 必須在 DataAnalysisProvider 中使用');
    }
    return context;
};
