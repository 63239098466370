import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import { isEmpty, isBoolean } from 'lodash';
import { Grid } from '@mui/material';
import { Alert, Box, Typography, Chip } from '@common/components/';
import { DocumentFile as DocumentFileIcon } from '@common/SvgIcon/';
import { MoreLabelBox } from '@icoach/components/';
import { CycleEffectOrderDeleteDialog } from '@icoach/documents/dialog';
import { CycleOrderEffectDialog } from '@icoach/members/memberDetail/dialog';
import { useMemberDetailContext } from '@icoach/members/memberDetail/MemberDetailContext';
import { SimpleContentItem } from '@icoach/members/components/';
import useMemberCyclePurchaseApi from '@apis/useMemberCyclePurchaseApi';
import { displayDateText } from '@util/moment';
import useShowPDF from '@util/hook/useShowPDF';
import { DOCUMENT_CYCLE_PURCHASE_EFFECT_PATH } from '@icoach/router/routerPath';

export const getMoreActionArray = (data = {}, view = true) => {
    const { canEffection, canDeleted, url } = data;
    const moreAction = {
        view: view,
        effect: canEffection,
        delete: canDeleted,
        download: Boolean(url),
    };
    let array = [];
    for (let key in moreAction) {
        let bool;
        if (moreAction.hasOwnProperty(key)) {
            bool = moreAction[key];
        }
        if (bool) {
            array.push(key);
        }
    }
    return array;
};

const CycleOrderEffectLabel = (props) => {
    const { memberID, cycleOrderID, effectID, url, refresh } = props;
    // const { refresh } = useMemberDetailContext();
    const showPDF = useShowPDF();

    const moreLabelMap = useRef({
        view: {
            text: '查看',
            dialogString: 'view',
            Dialog: CycleOrderEffectDialog,
        },
        effect: {
            text: '編輯',
            to: () => DOCUMENT_CYCLE_PURCHASE_EFFECT_PATH(memberID, cycleOrderID, effectID),
        },
        delete: {
            text: '刪除',
            dialogString: 'delete',
            Dialog: CycleEffectOrderDeleteDialog,
        },
        download: {
            text: '查看PDF',
            onClickEvent: () =>
                showPDF({
                    open: true,
                    pdfUrl: url,
                }),
        },
    });

    return <MoreLabelBox {...props} moreLabelMap={moreLabelMap?.current || {}} textAlign={'center'} refresh={refresh?.current || refresh} />;
};

// 變更單
export const CycleEffectOrderCard = (props) => {
    const { title = '預計變更單', data = {}, cycleOrderID, refresh } = props;
    const { effectDate, effectStatusText, effectCreateDate, employeeName, effectTypeText = [] } = data;

    const moreAction = getMoreActionArray(data);
    const { canDeleted = null } = data || {};

    return (
        <React.Fragment>
            <Box className={'minor-title'}>
                <DocumentFileIcon />
                <Typography>{title}</Typography>
            </Box>
            <Box className={'cycle-order-card minor'}>
                <Box className={'cycle-order-card-header'}>
                    <Grid spacing={3} container>
                        <Grid xs={4} item>
                            <SimpleContentItem label={'生效日期'}>{displayDateText(effectDate)}</SimpleContentItem>
                        </Grid>
                        <Grid xs={4} item>
                            <SimpleContentItem label={'建單日期'}>{displayDateText(effectCreateDate)}</SimpleContentItem>
                        </Grid>
                        <Grid xs={4} item>
                            <SimpleContentItem label={'建單擔當'}>{employeeName}</SimpleContentItem>
                        </Grid>
                        <Grid xs={4} item>
                            <SimpleContentItem label={'狀態'}>{effectStatusText}</SimpleContentItem>
                        </Grid>
                        <Grid xs={8} item>
                            <SimpleContentItem label={'變更項目'}>
                                <span className={'font-weight-bold'}>{Array.isArray(effectTypeText) && effectTypeText.join('、')}</span>
                            </SimpleContentItem>
                        </Grid>
                    </Grid>
                    {!isEmpty(moreAction) && (
                        <CycleOrderEffectLabel
                            {...data}
                            cycleOrderID={cycleOrderID}
                            buttonProps={{ className: 'cycle-order-card-header-actions' }}
                            moreActionArray={moreAction}
                            refresh={refresh}
                        />
                    )}
                    {isBoolean(canDeleted) && !canDeleted && <Chip className={'decorate-delete-chip'} label={'請由變更單恢復暫停'} color={'error'} />}
                </Box>
            </Box>
        </React.Fragment>
    );
};

const PendingCycleEffectOrderList = React.forwardRef((props, ref) => {
    const { memberID, cycleOrderID } = props;
    const { setRefresh, refresh } = useMemberDetailContext();
    const { getMemberCycleOrderFutureApi } = useMemberCyclePurchaseApi();
    const [data, setData] = useState([]);

    const doMemberCycleOrderFutureApi = async (memberID, cycleOrderID) => {
        let res = await getMemberCycleOrderFutureApi(memberID, cycleOrderID);
        if (res) {
            setData(res);
            return res;
        }
    };

    useImperativeHandle(
        ref,
        () => ({
            doMemberCycleOrderFutureApi,
        }),
        // eslint-disable-next-line
        [],
    );

    useEffect(() => {
        if (memberID && cycleOrderID) {
            setRefresh(doMemberCycleOrderFutureApi);
            doMemberCycleOrderFutureApi(memberID, cycleOrderID);
        }
        // eslint-disable-next-line
    }, [memberID, cycleOrderID]);
    return (
        <React.Fragment>
            {Array.isArray(data) && !isEmpty(data) ? (
                <Box component={'ul'} className="minor-orders mb-4">
                    {data.map((item, i) => (
                        <Box component={'li'} key={i} className="minor-order-item">
                            <CycleEffectOrderCard data={item} cycleOrderID={cycleOrderID} refresh={refresh} />
                        </Box>
                    ))}
                </Box>
            ) : (
                <Alert className={'mb-4'} color="info" severity="info" variant="filled">
                    尚無資料
                </Alert>
            )}
        </React.Fragment>
    );
});

export default PendingCycleEffectOrderList;
