import React, { useCallback, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { isEmpty } from 'lodash';
import { useHistory } from 'react-router';
import { Grid } from '@mui/material';
import { Alert, Box, Button, Card, Chip, List, ListItemText, Stack, Table, TableBody, TableCell, TableRow, Typography } from '@common/components/';
import useOrderApi from '@apis/useOrderApi';
import { ReadOnlyProductCartTable, ReadOnlyFeeCartTable } from './tables';
import { parseDate } from '@util/moment';
import { formatCurrencyFn } from '@util/utils';
import AccountBookDialogBox from '@icoach/accountBook/dialog/AccountBookDialogBox';
import { COLOR_MATCH, orderItemTypeMap } from '@icoach/accountBook/staticData';
import { ACCOUNT_BOOK_ORDERS_ALLOWANCE_URL } from '@icoach/router/AccountBookRouter';
import { issuingTypeMap } from '@icoach/components';

const InvoiceInfoByDonate = (props) => {
    const { data } = props;
    return (
        <TableRow>
            <TableCell>愛心碼</TableCell>
            <TableCell>{data}</TableCell>
        </TableRow>
    );
};
const InvoiceInfoByDuplicateUniform = (props) => {
    const { data } = props;
    return (
        <TableRow>
            <TableCell>載具號碼</TableCell>
            <TableCell>{data}</TableCell>
        </TableRow>
    );
};
const InvoiceInfoByTriplicateUniform = (props) => {
    const { data = {} } = props;
    const { taxID, taxTitle } = data;
    return (
        <TableRow>
            <TableCell>發票資訊</TableCell>
            <TableCell>{`${taxID}, ${taxTitle}`}</TableCell>
        </TableRow>
    );
};
// 發票資訊
const InvoiceInfoContent = (props) => {
    const { data } = props;
    const { invoiceExceptionMessage } = data || {};
    const {
        invoiceIntegrateStatusText,
        invoiceIntegrateStatusColor,
        invoiceCarrierTypeText,
        issuingTypeText,
        invoiceCarrierType,
        invoiceCarrierNo,
        invoiceNo,
        issuingType,
        taxID,
        taxTitle,
        donationCode,
    } = data?.payment ?? {};

    const handleIssuingInfo = (issuingType, invoiceCarrierTypeText, issuingTypeText) => {
        let text = '無相關資料';
        if (String(issuingType) === issuingTypeMap.duplicateUniform) {
            // by 二聯式
            text = invoiceCarrierTypeText;
        } else {
            text = issuingTypeText;
        }
        return text;
    };

    return (
        <React.Fragment>
            <TableRow>
                <TableCell className={'vertical-align-top'}>發票狀態</TableCell>
                <TableCell>
                    <Chip label={invoiceIntegrateStatusText} color={COLOR_MATCH[invoiceIntegrateStatusColor]} />
                    {invoiceExceptionMessage && (
                        <Alert className={'alert-justify-content-start my-2'} variant="filled" severity="error" color="error">
                            {invoiceExceptionMessage}
                        </Alert>
                    )}
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell>開立發票方式</TableCell>
                <TableCell>
                    <Stack alignItems={'center'}>
                        {handleIssuingInfo(issuingType, invoiceCarrierTypeText, issuingTypeText)}
                        {String(issuingType) === issuingTypeMap.manual && (
                            <Alert className={'alert-justify-content-start'} color={'info'} severity={'info'}>
                                選擇手開發票，發票異動需由店舖自行處理。
                            </Alert>
                        )}
                    </Stack>
                </TableCell>
            </TableRow>
            {String(issuingType) === issuingTypeMap.donate && <InvoiceInfoByDonate data={donationCode} />}
            {(String(invoiceCarrierType) === issuingTypeMap.duplicateUniform || invoiceCarrierType === issuingTypeMap.triplicate) && (
                <InvoiceInfoByDuplicateUniform data={invoiceCarrierNo} />
            )}
            {String(issuingType) === issuingTypeMap.triplicateUniform && <InvoiceInfoByTriplicateUniform data={{ taxID, taxTitle }} />}
            <TableRow>
                <TableCell>發票號碼</TableCell>
                <TableCell>{invoiceNo ? <strong>{invoiceNo}</strong> : '無相關資料'}</TableCell>
            </TableRow>
        </React.Fragment>
    );
};

// 付款資訊(含發票資訊)
const PaymentInfoCard = (props) => {
    const { className, data, dialogRef, refresh } = props;
    const { paymentStatusText, paymentStatusColor, payablePrice, cashPayment, cardPayment, returnPrice, paymentDate } = data?.payment ?? {};

    return (
        <Card className={className}>
            <Card.CardContent>
                <Typography className={'text-primary px-4 pt-4 font-weight-bold'} variant="h6" component={'h4'}>
                    付款資訊
                </Typography>
                <Box className={'px-4 pb-3'}>
                    <Table className={'order-info-table'}>
                        <TableBody>
                            <TableRow>
                                <TableCell>付款狀態</TableCell>
                                <TableCell>
                                    <Chip label={paymentStatusText} color={COLOR_MATCH[paymentStatusColor]} />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>訂單金額</TableCell>
                                <TableCell>
                                    <strong>{formatCurrencyFn(payablePrice)}</strong>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>現金收款金額</TableCell>
                                <TableCell>
                                    <strong>{formatCurrencyFn(cashPayment)}</strong>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>信用卡收款金額</TableCell>
                                <TableCell>
                                    <strong>{formatCurrencyFn(cardPayment)}</strong>
                                </TableCell>
                            </TableRow>
                            {!!returnPrice && (
                                <TableRow>
                                    <TableCell className={'text-error'}>退費金額</TableCell>
                                    <TableCell className={'text-error'}>
                                        <strong>{formatCurrencyFn(returnPrice)}</strong>
                                    </TableCell>
                                </TableRow>
                            )}
                            <InvoiceInfoContent data={data} dialogRef={dialogRef} refresh={refresh} />
                            <TableRow>
                                <TableCell>收款日期</TableCell>
                                <TableCell>{parseDate(paymentDate, 'YYYY/MM/DD HH:mm:ss')}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </Box>
            </Card.CardContent>
        </Card>
    );
};

// 訂單資訊
const OrderInfoCard = (props) => {
    const { className, data } = props;
    const info = { ...data, ...data.info };
    const { orderNo, statusColor, statusText, receiverTypeText, receiverName, receiverMobile, orderDate, orderSourceTypeText, employeeDisplayName } = info;

    return (
        <Card className={className}>
            <Card.CardContent>
                <Typography className={'text-primary px-4 pt-4 font-weight-bold'} variant="h6" component={'h4'}>
                    訂單資訊
                </Typography>
                <Box className={'px-4 pb-3'}>
                    <Table className={'order-info-table'}>
                        <TableBody>
                            <TableRow>
                                <TableCell>訂單編號</TableCell>
                                <TableCell>
                                    <strong>{orderNo}</strong>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>訂單狀態</TableCell>
                                <TableCell>
                                    <Chip label={statusText} color={COLOR_MATCH[statusColor]} />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>訂單來源</TableCell>
                                <TableCell>{orderSourceTypeText}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>購買人</TableCell>
                                <TableCell>{receiverName}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>購買人身分</TableCell>
                                <TableCell>{receiverTypeText}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>聯絡電話</TableCell>
                                <TableCell>{receiverMobile}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>建單擔當</TableCell>
                                <TableCell>{employeeDisplayName}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>建立日期</TableCell>
                                <TableCell>{parseDate(orderDate, 'YYYY/MM/DD HH:mm:ss')}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </Box>
            </Card.CardContent>
        </Card>
    );
};

// 購買清單
const CartCard = (props) => {
    const { className, data } = props;
    const { payment, orderType, items = [] } = data || {};
    const cartTotal = payment?.payablePrice ?? 0;

    // 商品還是費用
    const getTableKey = useCallback(() => orderItemTypeMap.get(orderType), [orderType]);

    return (
        <Card className={className}>
            <Card.CardContent>
                <Typography className={'text-primary px-4 pt-4 font-weight-bold'} variant={'h6'} component={'h4'}>
                    購買清單
                </Typography>
                <Box className={clsx('cart-content', className)}>
                    {getTableKey() === 'product' ? (
                        <ReadOnlyProductCartTable className={'theme-gray'} rowsData={items} />
                    ) : (
                        <ReadOnlyFeeCartTable className={'theme-gray'} rowsData={items} />
                    )}
                    <Grid spacing={3} container>
                        <Grid className={'ml-auto'} xs={4} item>
                            <List>
                                <ListItemText className={'cart-info total'} secondary={'總計'} primary={formatCurrencyFn(cartTotal)} />
                            </List>
                        </Grid>
                    </Grid>
                </Box>
            </Card.CardContent>
        </Card>
    );
};

const OrderHistoryCardItem = (props) => {
    const { title, returnNotes, recordDate } = props.data || {};
    return (
        <Box component={'li'} className={'order-history-item'}>
            <Box className={'order-history-item-inner'}>
                <Typography className={'order-history-item-title'}>{title}</Typography>
                {returnNotes && <Typography className={'mb-1 font-color-3'}>{returnNotes}</Typography>}
                <Typography className={'order-history-item-subtitle'}>{parseDate(recordDate, 'YYYY/MM/DD HH:mm:ss')}</Typography>
            </Box>
        </Box>
    );
};

// 訂單歷史紀錄
const OrderHistoryCard = (props) => {
    const { className, data } = props;
    const records = data.records || [];
    return (
        <Card className={className}>
            <Card.CardContent>
                <Typography className={'text-primary px-4 pt-4 font-weight-bold'} variant="h6" component={'h4'}>
                    訂單紀錄
                </Typography>
                <Box component={'ul'} className={'order-history px-4'}>
                    {Array.isArray(records) && records.map((item, i) => <OrderHistoryCardItem key={i} data={item} />)}
                </Box>
            </Card.CardContent>
        </Card>
    );
};

const AccountBookOrderButtons = (props) => {
    const { data, dialogRef, refresh } = props;
    const orderID = data.info.orderID;
    const canAllowance = data.canAllowance;
    const canInvalid = data.canInvalid;
    const canDelete = data.canDelete;
    const canReissuing = data.canReissuing;
    const canReinit = !data.isManual;
    const showAllowanceRecord = String(data.orderType) !== '51';

    return (
        <Box className={'btn-group'}>
            {canAllowance && (
                <Button variant={'outlined'} onClick={() => dialogRef.current && dialogRef.current.openOrderAllowanceDialog({ orderID }, refresh)}>
                    訂單折讓
                </Button>
            )}
            {canInvalid && (
                <Button variant={'outlined'} onClick={() => dialogRef.current && dialogRef.current.openOrderInvoiceAbandonDialog({ orderID }, refresh)}>
                    作廢發票
                </Button>
            )}
            {showAllowanceRecord && (
                <Button variant={'outlined'} to={ACCOUNT_BOOK_ORDERS_ALLOWANCE_URL(orderID)}>
                    折讓歷史
                </Button>
            )}
            {canDelete && (
                <Button variant={'outlined'} onClick={() => dialogRef.current && dialogRef.current.openDeleteOrderDialog({ orderID }, refresh)}>
                    刪除訂單
                </Button>
            )}
            {canReinit && (
                <Button variant={'outlined'} onClick={() => dialogRef.current && dialogRef.current.openReinitOrderDialog({ orderID }, refresh)}>
                    恢復訂單
                </Button>
            )}
            {canReissuing && (
                <Button variant={'outlined'} onClick={() => dialogRef.current && dialogRef.current.openResetOrderInvoiceDialog({ orderID }, refresh)}>
                    重開發票
                </Button>
            )}
        </Box>
    );
};

const AccountBookOrderDetailPage = (props) => {
    const history = useHistory();
    const orderID = props.match.params.orderID;
    const { getOrderApi } = useOrderApi();
    const [data, setData] = useState({});
    const dialogRef = useRef(null);

    const doOrderApi = async (orderID) => {
        let resp = await getOrderApi(orderID);
        if (resp) setData(resp);
        else history.push('/club/404');
    };

    const refresh = () => doOrderApi(orderID);

    useEffect(() => {
        if (parseInt(orderID, 10) > 0) {
            doOrderApi(orderID);
        }
        // eslint-disable-next-line
    }, [orderID]);

    if (isEmpty(data)) return null;

    return (
        <Box className={'container main-container-spacing order-detail-page'}>
            <Box component={'header'} className={'page-header'}>
                <Typography className={'page-header-title'} variant={'h3'}>
                    訂單明細
                </Typography>
                <AccountBookOrderButtons data={data} dialogRef={dialogRef} refresh={refresh} />
            </Box>
            <Grid spacing={3} container>
                <Grid xs={12} md={8} item>
                    <OrderInfoCard className={'mb-4'} data={data} />
                    <PaymentInfoCard className={'mb-4'} data={data} dialogRef={dialogRef} refresh={refresh} />
                    <CartCard className={'mb-4'} data={data} />
                </Grid>
                <Grid xs={12} md={4} item>
                    <OrderHistoryCard data={data} />
                </Grid>
            </Grid>
            <AccountBookDialogBox ref={dialogRef} />
        </Box>
    );
};

export default AccountBookOrderDetailPage;
