import React, { useRef } from 'react';
import Tablenization from '../../components/Tablenization';
import { RemitAllowanceAbandonDialog } from '../dialog';
import { rowsLabel } from './OrderAllowanceRecordTable';
import useToggleDialog from '../../../util/hook/useToggleDialog';
import { dialogType } from '../staticData';

const RemitAllowanceRecordTable = (props) => {
    const { className, rowsData = [], refresh } = props;
    const {
        openTarget,
        handleOpenDialog,
        handleCloseDialog,
        allowanceID,
        items,
    } = useToggleDialog();
    const labelTitle = useRef(rowsLabel);
    return (
        <React.Fragment>
            <Tablenization cardProps={{ className: className }}>
                <Tablenization.TableRowHeader headerRow={labelTitle.current} />
                <Tablenization.TableRowBody
                    headerRow={labelTitle.current}
                    rowsData={rowsData}
                    handleOpenDialog={handleOpenDialog}
                />
            </Tablenization>
            <RemitAllowanceAbandonDialog
                open={openTarget === dialogType.allowanceAbandon}
                allowanceID={allowanceID}
                items={items}
                onClose={handleCloseDialog}
                refresh={refresh}
            />
        </React.Fragment>
    );
};

export default RemitAllowanceRecordTable;
