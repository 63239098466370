import React, { useState, useImperativeHandle, useEffect, useRef } from 'react';
import { Grid, Dialog, DialogContent, Slide } from '@mui/material';
import { useSnackbar } from 'notistack';
import { find, flatten, map, concat, isBoolean } from 'lodash';
import clsx from 'clsx';
import GradientColorHeader from '@icoach/components/dialog/GradientColorHeader';
import { Typography, Stack, Divider, TextField, MenuItem, SelectField, Button, Card, AutocompleteField, CheckboxField, Alert, Box } from '@common/components/';
import { ReadTextField } from '@icoach/components/';
import MobileDatePickerBox from '@icoach/components/MobileDatePickerBox';
import { FaEnum } from '@icoach/landingPage/staticData';
import { AppointmentGreetingBox } from '@icoach/landingPage/appointment/';
import { isValidDate, parseDate, hasTimeCheck } from '@util/moment';
import useAppointmentApi from '@apis/useAppointmentApi';
import { refIsRequiredError, isEmpty } from '@util/utils';
import useEmployeeScheduleApi from '@apis/useEmployeeScheduleApi';
import useMessageDialog from '@util/hook/useMessageDialog';

export const Transition = React.forwardRef((props, ref) => {
    return <Slide direction="up" ref={ref} {...props} />;
});

// 會員預約
// 預約事項
export const AppointmentItemBox = React.forwardRef((props, ref) => {
    const { options: optionsProps, sourceData = {}, isEdit, apoInfoRef } = props;
    const { enqueueSnackbar } = useSnackbar();
    const [options, setOptions] = useState([]);
    const [categoryIndex, setCategoryIndex] = useState(null); //預約事項 分類value
    const [itemIndex, setItemIndex] = useState(null); //預約事項 有效值
    const [showSaleFa, setShowSaleFa] = useState(false);
    const [showAlertMesg, setShowAlertMesg] = useState(false);

    // 切換分類初始值
    const handleApoCatalogClick = (_value) => {
        setCategoryIndex(_value);
        setItemIndex(() => {
            let apoType = 0;
            const targetCategoryOption = options.find(({ value: _v }) => String(_v) === String(_value));
            apoType = targetCategoryOption.option[0].value;
            if (!showSaleFa && FaEnum.includes(String(apoType))) {
                apoType = targetCategoryOption.option[1].value;
            }
            return apoType;
        }); // 初始預約事項索引位置
    };

    const handleApoItemClick = (_value) => {
        setItemIndex(_value);
    };

    useImperativeHandle(
        ref,
        () => {
            return {
                getResult: () => ({
                    appointmentType: itemIndex,
                }),
                isError: () => {
                    let error = true;
                    if (itemIndex) error = false;
                    else enqueueSnackbar(`請輸入預約項目`, { variant: 'error' });
                    return error;
                },
                setOpenSaleFa: (isShowSaleFa, isShowAlertMessage) => {
                    setShowSaleFa(isShowSaleFa);
                    setShowAlertMesg(isShowAlertMessage);
                },
            };
        },
        // eslint-disable-next-line
        [itemIndex, categoryIndex]
    );

    // 初始化
    useEffect(
        () => {
            if (options.length === 0) {
                setOptions([...optionsProps]);
            }
        },
        // eslint-disable-next-line
        [optionsProps]
    );

    useEffect(
        () => {
            if (options.length !== 0) {
                let cat = '';
                let apoItemType = '';
                if (sourceData.item) {
                    const targetOpt = options.find((target) => {
                        return target.option.find((subTarget) => {
                            const isTarget = String(subTarget.value) === String(sourceData.item);
                            if (isTarget) {
                                apoItemType = subTarget.value;
                            }
                            return isTarget;
                        });
                    });
                    if (targetOpt) {
                        cat = targetOpt.value;
                    }
                } else {
                    cat = options[0].value;
                    apoItemType = options[0].option.filter((x) => {
                        if (showSaleFa) {
                            // 000 不存在此數值
                            return String(x.value) !== '000';
                        }
                        return !FaEnum.includes(String(x.value));
                    })[0].value;
                }
                setCategoryIndex(cat);
                setItemIndex(apoItemType);
            }
        },
        // eslint-disable-next-line
        [showSaleFa, options]
    );

    useEffect(
        () => {
            if (!isEdit && apoInfoRef && apoInfoRef.current) {
                apoInfoRef.current.setShowSourceType(FaEnum.includes(String(itemIndex)));
            }
        },
        // eslint-disable-next-line
        [itemIndex]
    );

    const optionSub = categoryIndex && options.find(({ value: _v }) => String(_v) === String(categoryIndex));
    const readOnly = isEdit && FaEnum.includes(String(sourceData.item));

    return (
        <div>
            <Typography variant="h4" component="h4">
                預約事項
            </Typography>
            {readOnly ? (
                <React.Fragment>
                    <div className="member-apo-catalog-items">
                        <Grid container columns={6}>
                            <Grid item xs={1}>
                                <Typography component="div" className={clsx('item-text', 'clicked')}>
                                    {options.find((target) => target.value === categoryIndex)?.text ?? ''}
                                </Typography>
                            </Grid>
                        </Grid>
                    </div>
                    <div className="member-apo-type-items">
                        <Grid container columns={6}>
                            <Grid item xs={1}>
                                <Typography component="div" className={clsx('item-text', 'clicked')}>
                                    {find(flatten(map(options, 'option')), { value: itemIndex })?.text ?? ''}
                                </Typography>
                            </Grid>
                        </Grid>
                    </div>
                </React.Fragment>
            ) : (
                <React.Fragment>
                    <div className="member-apo-catalog-items">
                        <Grid container columns={6}>
                            {options.map(({ value, text }) => (
                                <Grid item xs={1} key={value}>
                                    <Typography
                                        component="div"
                                        className={clsx('item-text', { clicked: String(value) === categoryIndex })}
                                        onClick={() => handleApoCatalogClick(value)}
                                    >
                                        {text}
                                    </Typography>
                                </Grid>
                            ))}
                        </Grid>
                    </div>
                    <div className="member-apo-type-items">
                        <Grid container columns={6}>
                            {optionSub &&
                                optionSub.option.map(({ text, value }) => {
                                    if (!showSaleFa && FaEnum.includes(String(value))) {
                                        return null;
                                    }
                                    return (
                                        <Grid item xs={1} key={value}>
                                            <Typography
                                                onClick={() => handleApoItemClick(value)}
                                                component="div"
                                                className={clsx('item-text', {
                                                    clicked: String(value) === String(itemIndex),
                                                })}
                                            >
                                                {text}
                                            </Typography>
                                        </Grid>
                                    );
                                })}
                        </Grid>
                    </div>
                    {showAlertMesg && (
                        <Box className="py-2">
                            <Alert color="warning" severity="warning" variant="filled">
                                目前正在執行有效會員補做無效會員FA，將會計數詢問、APO
                            </Alert>
                        </Box>
                    )}
                </React.Fragment>
            )}
        </div>
    );
});

// 預約者表單
const AppointmentPeopleInformationItem = React.forwardRef((props, ref) => {
    const {
        options: { employeeOption },
        sourceData,
        appointmentItemBoxRef,
        isEdit,
    } = props;
    const { getAppointmentMemberFormOptionsApi } = useAppointmentApi();
    const { peopleID: peopleIDProps, employeeID = null, notes = null } = sourceData;
    const [options, setOptions] = useState({});
    const [isValidMember, setIsValidMember] = useState(true);
    const [showInvalid, setShowInvalid] = useState(false);
    const [showSourceType, setShowSourceType] = useState(false);
    const [isSupplyRecord, setSupplyRecord] = useState(false);
    const peopleRef = useRef(null);
    const coachRef = useRef(null);
    const noteRef = useRef(null);
    const sourceTypeRef = useRef(null);
    const appointmentGreetingRef = useRef(null);
    const preloadRef = useRef(false);
    const { sourceTypeOption = [], validMemberOption = [], invalidMemberOption = [] } = options;

    const handleMemberOptionsOnChange = (peopleModel) => {
        if (peopleModel && isBoolean(peopleModel.isValid)) {
            setIsValidMember(peopleModel.isValid);
        } else {
            // 清空重置
            setIsValidMember(true);
            setShowSourceType(false);
            setSupplyRecord(false);
        }
    };

    const handleMemberTypeOnChange = (_e, checked) => {
        setShowInvalid(checked);
    };

    const getAllMembersApi = async () => {
        const resp = await getAppointmentMemberFormOptionsApi();
        if (resp) {
            preloadRef.current = true;
            setOptions(resp);
        }
    };

    useImperativeHandle(
        ref,
        () => {
            return {
                getResult: () => {
                    return {
                        peopleType: 2,
                        employeeID: coachRef.current.getResult(),
                        notes: noteRef.current.getResult(),
                        peopleID: sourceData.peopleID || (peopleRef.current?.getResult('id') ?? 0),
                        sourceType: sourceData.sourceType || (sourceTypeRef.current?.getResult('id') ?? 0),
                        ...(appointmentGreetingRef.current?.getResult() ?? {}),
                    };
                },
                isError: () => {
                    return refIsRequiredError(peopleRef, coachRef, noteRef, sourceTypeRef, appointmentGreetingRef);
                },
                setShowSourceType: (isShow) => setShowSourceType(isShow),
                isValidMember: isValidMember,
            };
        },
        // eslint-disable-next-line
        [isValidMember]
    );

    useEffect(
        () => {
            if (preloadRef.current && peopleIDProps) {
                const el = find(options.invalidMemberOption, ({ id }) => String(id) === String(peopleIDProps));
                setIsValidMember(isEmpty(el));
                setShowInvalid(!isEmpty(el));
            }
        },
        // eslint-disable-next-line
        [options]
    );

    useEffect(
        () => {
            getAllMembersApi();
        },
        // eslint-disable-next-line
        []
    );

    useEffect(
        () => {
            if (preloadRef.current) {
                if (showSourceType && isSupplyRecord) {
                    appointmentItemBoxRef.current.setOpenSaleFa(showSourceType, true);
                } else {
                    const pID = peopleRef.current?.getResult('id') ?? null;
                    if (pID) {
                        const showMesage = isValidMember ? isSupplyRecord : false;
                        const showFa = isSupplyRecord ? true : !isValidMember;
                        appointmentItemBoxRef.current.setOpenSaleFa(showFa, showMesage);
                    } else {
                        appointmentItemBoxRef.current.setOpenSaleFa(false, false);
                    }
                }
            }
        },
        // eslint-disable-next-line
        [showSourceType, isValidMember, isSupplyRecord]
    );

    return (
        <React.Fragment>
            <Box className="pt-4">
                <Typography variant="h4" component="h4">
                    預約者資料
                </Typography>
            </Box>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    {isEdit ? (
                        <ReadTextField label={'會員'}>{`${sourceData.name},${sourceData.cellPhone},${sourceData.memberNo}`}</ReadTextField>
                    ) : (
                        <Stack spacing={2} direction="row" justifyContent="end">
                            <AutocompleteField
                                key={showInvalid}
                                label="會員"
                                defaultValue={peopleIDProps}
                                ref={peopleRef}
                                options={showInvalid ? concat([], validMemberOption, invalidMemberOption) : validMemberOption}
                                optionKeys={['name', 'mobile', 'no']}
                                primaryKey={'id'}
                                onChange={handleMemberOptionsOnChange}
                                fullWidth
                                required
                                InputProps={{ disabled: !!peopleIDProps }}
                            />
                            <CheckboxField className="flex-shrink-0 mt-3" onChange={handleMemberTypeOnChange}>
                                <CheckboxField.Checkbox label={'包含無效會員'} checked={showInvalid} />
                            </CheckboxField>
                        </Stack>
                    )}
                </Grid>
                <Grid item xs={12}>
                    {isEdit ? (
                        FaEnum.includes(String(sourceData.appointmentType)) && <ReadTextField label="來源">{sourceData.sourceTypeText}</ReadTextField>
                    ) : (
                        <React.Fragment>
                            {showSourceType && (
                                <SelectField label="來源" ref={sourceTypeRef} displayEmpty required>
                                    <MenuItem disabled value="">
                                        <em>請選擇</em>
                                    </MenuItem>
                                    {sourceTypeOption.map(({ text, value, disabled }) => (
                                        <MenuItem key={value} value={value} disabled={disabled}>
                                            {text}
                                        </MenuItem>
                                    ))}
                                </SelectField>
                            )}
                        </React.Fragment>
                    )}
                </Grid>
                <Grid item xs={6}>
                    <SelectField label="擔當" defaultValue={employeeID} ref={coachRef} displayEmpty fullWidth required>
                        <MenuItem disabled value="">
                            <em>請選擇</em>
                        </MenuItem>
                        {employeeOption.map(({ text, value, disabled }) => (
                            <MenuItem key={value} value={value} disabled={disabled}>
                                {text}
                            </MenuItem>
                        ))}
                    </SelectField>
                </Grid>
                <Grid item xs={6}>
                    {!isEdit && <AppointmentGreetingBox ref={appointmentGreetingRef} />}
                </Grid>
                <Grid item xs={12}>
                    <TextField label="備註" ref={noteRef} defaultValue={notes} multiline fullWidth rows={4} />
                    {!isEdit && isValidMember && (
                        <CheckboxField row>
                            <CheckboxField.Checkbox
                                onChange={(_e, checked) => {
                                    setSupplyRecord(checked);
                                }}
                                checked={isSupplyRecord}
                                label={'有效會員補做無效會員預約單'}
                            />
                        </CheckboxField>
                    )}
                </Grid>
            </Grid>
        </React.Fragment>
    );
});

// 選擇預約時間
export const AppointmentTimelineBox = React.forwardRef((props, ref) => {
    const { apoDate: apoDateProps } = props;
    const { getAppointmentTimepickerRecordsApi } = useAppointmentApi();
    const { getEmployeeScheduleEmployeeApi } = useEmployeeScheduleApi();
    const [apoDate, setApoDate] = useState(parseDate(apoDateProps || new Date()));
    const [apoTime, setApoTime] = useState('');
    const [helperError, setHelperError] = useState(false);
    const [apoTimeLineList, setApoTimeLineList] = useState([]);
    const [employeeData, setEmployeeData] = useState({});

    const handleDateChange = (dateString) => {
        setApoDate(parseDate(dateString));
        setApoTime('');
    };

    const handleTimeChange = (dateString) => {
        setApoTime(dateString);
    };

    const getTimePickerApi = async () => {
        const resp = await getAppointmentTimepickerRecordsApi({ time: apoDate });
        if (resp) {
            setApoTimeLineList(resp);
            hasTimeCheck(apoDateProps) && setApoTime(parseDate(apoDateProps, 'HH:mm'));
        }
    };

    const doEmployeeScheduleEmployeeApi = async (params) => {
        const resp = await getEmployeeScheduleEmployeeApi(params);
        if (resp) {
            setEmployeeData(resp);
        }
    };

    useImperativeHandle(
        ref,
        () => {
            return {
                getResult: () => {
                    return {
                        appointmentDate: parseDate(apoDate),
                        appointmentTime: apoTime,
                    };
                },
                isError: () => {
                    let chk = true;
                    if (apoTime) chk = false;
                    setHelperError(chk);
                    return chk;
                },
            };
        },
        // eslint-disable-next-line
        [apoTime]
    );

    useEffect(
        () => {
            if (isValidDate(apoDate)) {
                getTimePickerApi();
                doEmployeeScheduleEmployeeApi({ time: apoDate });
            }
        },
        // eslint-disable-next-line
        [apoDate]
    );

    return (
        <React.Fragment>
            <Box className={'mt-0'}>
                <Grid container spacing={3}>
                    <Grid item xs={7}>
                        <MobileDatePickerBox
                            className={'pt-2 flex-y-flex-start'}
                            value={apoDate}
                            onChange={handleDateChange}
                            dateFontSize={'h5'}
                            showCalendarIcon={false}
                        />
                    </Grid>
                    <Grid item xs={5}>
                        <TextField
                            label="預約時間"
                            value={apoTime}
                            isError={helperError}
                            helperText="請點選預約的時段"
                            InputProps={{ placeholder: '請點選預約的時段' }}
                            fullWidth
                            readOnly
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography className={'mb-0'}>
                            排休：{!isEmpty(employeeData.leaveEmployees) ? employeeData.leaveEmployees.join(', ') : '無'} / 外出支援：
                            {!isEmpty(employeeData.goingOutSupportEmployees) ? employeeData.goingOutSupportEmployees.join(', ') : '無'} / 店內支援：
                            {!isEmpty(employeeData.externalSupportCoaches) ? employeeData.externalSupportCoaches.join(', ') : '無'}
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
            <Box>
                <Card className="apo-time-form-list">
                    <Card.CardContent className="label-header">
                        <Stack>
                            <Typography component="div" variant="body2" className="label-time-first-column label-text text-center">
                                時間
                            </Typography>
                            <Typography component="div" variant="body2" className="label-time-second-column label-text">
                                預約
                            </Typography>
                        </Stack>
                    </Card.CardContent>
                    <Card.CardContent>
                        {apoTimeLineList.map(({ appointmentTime: time, appointmentItems: list = [] }) => {
                            return (
                                <Stack
                                    key={time}
                                    className={clsx('row-item', 'row-divider', {
                                        clicked: time === apoTime,
                                    })}
                                    onClick={() => handleTimeChange(time)}
                                >
                                    <Typography component="div" variant="body2" className="label-time-first-column label-text text-center">
                                        {time}
                                    </Typography>
                                    <Stack direction="column" className="label-time-second-column label-text" spacing={1}>
                                        {list &&
                                            list.map(({ appointmentTypeText: apoTypeName, appointmentType: apoType, names = [] }) => {
                                                return (
                                                    <Box className="lable-catalog" key={`${time}-${apoTypeName}-${apoType}-${names.length}`}>
                                                        <Stack component="div">
                                                            <Box className="label-time-first-column">{apoTypeName}</Box>
                                                            <Box className="label-time-second-column">{names.join(',')}</Box>
                                                        </Stack>
                                                    </Box>
                                                );
                                            })}
                                    </Stack>
                                </Stack>
                            );
                        })}
                    </Card.CardContent>
                </Card>
            </Box>
        </React.Fragment>
    );
});

const AppointmentFormDialog = React.forwardRef((props, ref) => {
    const {
        open: openProps,
        onClose: onCloseProps,
        options: optionsProps,
        refresh: refreshProps,
        sourceData: sourceDataProps,
        isEdit: isEditProps = false,
    } = props;
    const setMessageDialog = useMessageDialog();
    const { postAppointmentAddApi, putAppointmentEditApi, getAppointmentFormOptionsDataApi } = useAppointmentApi();
    const { postAppointmentCheckApi } = useAppointmentApi();
    const [options, setOptions] = useState([]);
    const apoTypeRef = useRef(null);
    const apoInfoRef = useRef(null);
    const apoTimelineRef = useRef(null);
    const {
        appointmentWorkoutTypeOption: woOption = [],
        appointmentSellTypeOption: sellOption = [],
        appointmentInterviewTypeOption: interOption = [],
        employeeOption: empOptions = [],
        invalidMemberSourceType: sourceTypeOption = [],
    } = options || {};

    const {
        appointmentID,
        appointmentDate = null,
        appointmentType = null,
        peopleID = null,
        peopleType = null,
        employeeID = null,
        notes = null,
        cellPhone = null,
        memberNo = null,
        name = null,
        sourceType = null,
        sourceTypeText = null,
    } = sourceDataProps || {};

    const handleAddAppointmentForm = () => {
        if (!refIsRequiredError(apoTypeRef, apoInfoRef, apoTimelineRef)) {
            let params = {};
            const apoParam = apoTypeRef.current.getResult();
            const infoParam = apoInfoRef.current.getResult();
            const apoTimelineParam = apoTimelineRef.current.getResult();
            Object.assign(params, apoParam, infoParam, apoTimelineParam);

            if (String(params.appointmentType) !== '305') {
                postAddAppointmentApi(params);
            } else {
                checkDuplicateAppointmentApi(params);
            }
        }
    };

    const handleEditAppointmentForm = () => {
        if (!refIsRequiredError(apoTypeRef, apoInfoRef, apoTimelineRef)) {
            let params = {};
            const apoParam = apoTypeRef.current.getResult();
            const infoParam = apoInfoRef.current.getResult();
            const apoTimelineParam = apoTimelineRef.current.getResult();
            Object.assign(params, apoParam, infoParam, apoTimelineParam);
            putEditAppointmentApi(params, appointmentID);
        }
    };

    const handleOnclose = () => {
        onCloseProps && onCloseProps();
    };

    /**
     * 檢查是否存在重複的預約
     */
    const checkDuplicateAppointmentApi = async (params = {}) => {
        const { peopleID } = params;
        const checkDuplicateAppointment = await postAppointmentCheckApi({
            peopleID: peopleID,
            peopleType: 2, // 1顧客 2會員
        });

        if (Array.isArray(checkDuplicateAppointment)) {
            setMessageDialog({
                open: true,
                title: '預約單訊息',
                MsgComp: () => (
                    <Alert color="info" variant="filled" severity="warning">
                        <Typography>
                            此會員已預約FA
                            <br />
                            已預約的日期：
                            <br />
                            {checkDuplicateAppointment.map((dateString) => (
                                <Typography key={dateString} className="pl-5" variant="h5" component="span">
                                    {dateString}
                                    <br />
                                </Typography>
                            ))}
                        </Typography>
                        <Typography>確定要新增預約？？</Typography>
                    </Alert>
                ),
                onAsyncConfirm: async (e, closeFn) => {
                    let resp = postAddAppointmentApi(params);
                    if (resp) closeFn();
                },
            });
        } else {
            postAddAppointmentApi(params);
        }
    };

    // 修改預約單
    const putEditAppointmentApi = async (params, apoID) => {
        const resp = await putAppointmentEditApi(params, apoID);
        if (resp) {
            handleOnclose();
            refreshProps && refreshProps.current && refreshProps.current();
        }
    };

    // 新增預約單
    const postAddAppointmentApi = async (params) => {
        const resp = await postAppointmentAddApi(params);
        if (resp) {
            handleOnclose();
            refreshProps && refreshProps.current && refreshProps.current();
        }
    };

    // 初始資料
    const getAppointmentItemDataApi = async () => {
        const resp = await getAppointmentFormOptionsDataApi();
        resp && setOptions(resp);
    };

    useEffect(
        () => {
            if (openProps && isEmpty(options)) {
                if (isEmpty(optionsProps)) {
                    getAppointmentItemDataApi();
                } else {
                    setOptions(optionsProps);
                }
            }
        },
        // eslint-disable-next-line
        [optionsProps, options, openProps]
    );

    return (
        <Dialog key={openProps} fullScreen open={openProps} TransitionComponent={Transition}>
            <GradientColorHeader onClose={handleOnclose}>{isEditProps ? '編輯會員預約' : '新增會員預約'}</GradientColorHeader>
            {!isEmpty(options) ? (
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <AppointmentItemBox
                                ref={apoTypeRef}
                                apoInfoRef={apoInfoRef}
                                sourceData={{ item: appointmentType, peopleType }}
                                options={[
                                    { text: 'WO', value: 'wo', option: woOption },
                                    { text: '銷售', value: 'sell', option: sellOption },
                                    { text: '面談', value: 'interview', option: interOption },
                                ]}
                                isEdit={isEditProps}
                            />
                            <Divider />
                            <AppointmentPeopleInformationItem
                                ref={apoInfoRef}
                                appointmentItemBoxRef={apoTypeRef}
                                options={{ employeeOption: empOptions, sourceTypeOption: sourceTypeOption }}
                                sourceData={{
                                    peopleID,
                                    employeeID,
                                    notes,
                                    cellPhone,
                                    memberNo,
                                    name,
                                    sourceType,
                                    sourceTypeText,
                                    appointmentType,
                                    peopleType,
                                }}
                                isEdit={isEditProps}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Stack direction="column" spacing={2}>
                                <Typography variant="h4" component="h4">
                                    選擇時間
                                </Typography>
                                <AppointmentTimelineBox ref={apoTimelineRef} apoDate={appointmentDate} />
                                <Stack justifyContent="end">
                                    {isEditProps ? (
                                        <Button variant="contained" onClick={handleEditAppointmentForm}>
                                            確認修改
                                        </Button>
                                    ) : (
                                        <Button variant="contained" onClick={handleAddAppointmentForm}>
                                            確認新增
                                        </Button>
                                    )}
                                </Stack>
                            </Stack>
                        </Grid>
                    </Grid>
                </DialogContent>
            ) : null}
        </Dialog>
    );
});

export default AppointmentFormDialog;
