import React, { useRef } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import {
    DOCUMENTS_MEMBERSHIPTRANSFERIN_ENTEREDTYPE_PEOPLETYPE_ROUTER_PATH,
    DOCUMENTS_MEMBERSHIPTRANSFERIN_PEOPLETYPE_REFERENCEID_HISTORY_ROUTER_PATH,
    DOCUMENTS_MEMBERSHIPTRANSFERIN_UNENTERED_PEOPLETYPE_REFERENCEID_CONTRACT_PATH,
    DOCUMENTS_MEMBERSHIPTRANSFERIN_ENTERED_PEOPLETYPE_REFERENCEID_CONTRACT_ROUTER_PATH,
    DOCUMENTS_MEMBERSHIPTRANSFERIN_ENTERED_PEOPLETYPE_REFERENCEID_READONLY_CONTRACT_ROUTER_PATH,
} from '@icoach/router/routerPath';
import Tablenization from '@icoach/components/Tablenization';
import { parseDate } from '@util/moment';
import { Typography } from '@common/components/';
import useDocMembershipTransferInApi from '@apis/useDocMembershipTransferInApi';
import useToggleDialog from '@util/hook/useToggleDialog';
import { moreColData } from '@icoach/documents/components/moreColData';
import { dialogTypeMap } from '@icoach/documents/membership/staticData';
import { ConfirmDeleteDocDialog } from '@icoach/documents/membership/dialog';

const rowsLabel = [
    {
        cellKey: 'applyDate',
        headerLabel: '申請時間',
        width: 20,
        formatCell: (cellValue) => parseDate(cellValue),
    },
    {
        cellKey: 'changeDate',
        headerLabel: '最後更新時間',
        width: 20,
        formatCell: (cellValue) => parseDate(cellValue),
    },
    {
        cellKey: 'isTemporary',
        headerLabel: '狀態',
        align: 'center',
        formatCell: (isTemporary, { isDeleted }) => {
            return isTemporary ? '暫存' : isDeleted ? '已刪除' : '已受理';
        },
    },
    {
        cellKey: 'note',
        headerLabel: '備註',
        className: 'white-space-normal',
        width: 30,
        formatCell: (value) => {
            return <Typography>{value}</Typography>;
        },
    },
    moreColData,
];

const MembershipTransferInHistoryTable = (props) => {
    const { className, sourceData = [], searchRef } = props;
    const { getDocTransferInPDFApi, deleteDocTransferInContractApi, deleteDocTemporaryTransferInContractApi } = useDocMembershipTransferInApi();
    const { peopleType: peopleTypeProps, referenceID: referenceIDProps, enteredType } = useParams();
    const history = useHistory();
    const { openTarget, handleOpenDialog, handleCloseDialog, rowData } = useToggleDialog();
    const labelTitle = useRef(rowsLabel);

    // 查看
    const handleReviewOnClick = ({ contractID }) => {
        let path;
        if (enteredType === 'entered') {
            path = DOCUMENTS_MEMBERSHIPTRANSFERIN_ENTERED_PEOPLETYPE_REFERENCEID_READONLY_CONTRACT_ROUTER_PATH(peopleTypeProps, referenceIDProps, contractID);
        }
        path && history.push(path);
    };

    // 編輯
    const handleEditOnClick = ({ contractID }) => {
        let path;
        if (enteredType === 'entered') {
            path = DOCUMENTS_MEMBERSHIPTRANSFERIN_ENTERED_PEOPLETYPE_REFERENCEID_CONTRACT_ROUTER_PATH(peopleTypeProps, referenceIDProps, contractID);
        } else if (enteredType === 'unentered') {
            path = DOCUMENTS_MEMBERSHIPTRANSFERIN_UNENTERED_PEOPLETYPE_REFERENCEID_CONTRACT_PATH(peopleTypeProps, referenceIDProps, contractID);
        }
        history.push(path);
    };

    // 下載
    const handleDownloadPDFOnClick = (rowData) => {
        const { contractUrl } = rowData;
        doSuspendPDFApi(contractUrl);
    };

    // 下載PDF API
    const doSuspendPDFApi = async (params) => {
        await getDocTransferInPDFApi(params);
    };

    // 刪除暫停合約資料
    const doDeleteApi = async (rowData) => {
        const { isTemporary, contractID } = rowData;
        let resp;
        if (isTemporary) {
            resp = await deleteDocTemporaryTransferInContractApi({
                type: peopleTypeProps,
                id: referenceIDProps,
            });
        } else {
            resp = await deleteDocTransferInContractApi(contractID);
        }
        return resp;
    };

    const refresh = () => {
        const { isTemporary } = rowData;
        if (isTemporary) {
            history.replace(DOCUMENTS_MEMBERSHIPTRANSFERIN_ENTEREDTYPE_PEOPLETYPE_ROUTER_PATH(enteredType, peopleTypeProps));
            searchRef.current && searchRef.current.setLock && searchRef.current.setLock(false);
        } else {
            history.replace(DOCUMENTS_MEMBERSHIPTRANSFERIN_PEOPLETYPE_REFERENCEID_HISTORY_ROUTER_PATH(enteredType, peopleTypeProps, referenceIDProps));
        }
    };

    return (
        <React.Fragment>
            <Tablenization cardProps={{ className: className }}>
                <Tablenization.TableRowHeader headerRow={labelTitle.current} />
                <Tablenization.TableRowBody
                    headerRow={labelTitle.current}
                    rowsData={sourceData}
                    onReviewEvent={handleReviewOnClick}
                    onEditEvent={handleEditOnClick}
                    onDownloadPDFEvent={handleDownloadPDFOnClick}
                    onDeleteOnEvent={(rowData) => {
                        handleOpenDialog(dialogTypeMap.deleteDoc, { rowData });
                    }}
                />
            </Tablenization>
            <ConfirmDeleteDocDialog
                title={rowData?.isTemporary ? '確認刪除暫存移入申請書' : '確認刪除移入申請書'}
                open={openTarget === dialogTypeMap.deleteDoc}
                onClose={handleCloseDialog}
                data={rowData}
                deleteDocApi={doDeleteApi}
                refresh={refresh}
            />
        </React.Fragment>
    );
};

export default MembershipTransferInHistoryTable;
