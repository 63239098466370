import React, { useMemo } from 'react';
import clsx from 'clsx';
import _ from 'lodash';
import IconGroupHeader from '@icoach/nonMember/components/IconGroupHeader';
import useNonMember from '@icoach/nonMember/components/useNonMember';
import MoreLabel from '@icoach/nonMember/components/MoreLabel';
import { nameLabel } from '@icoach/nonMember/customer/CustomerTable';
import { prospectDialogTextMap } from '@icoach/nonMember/staticData';
import Tablenization from '@icoach/components/Tablenization';
import { NON_MEMBER_CUSTOMER_ADD_ROUTER, NON_MEMBER_PROSPECT_INFO_ROUTER } from '@icoach/router/NonMemberRouter';
import { FormControlLabel, Checkbox, Chip, Typography, Box } from '@common/components/';
import useGroupCheckbox from '@util/hook/useGroupCheckbox';
import { parseDate } from '@util/moment';
import { DescribeLabelBox, MorePageBox } from '@icoach/components';

export const rowsLabel = [
    {
        cellKey: 'customerID',
        headerLabel: ' ',
        isIcon: true,
        formatCell: (cellValue, rowData, { chkRef, checkedOnChange }) => {
            return (
                <Checkbox
                    color="primary"
                    onChange={checkedOnChange}
                    value={cellValue}
                    inputRef={(el) => {
                        if (el && chkRef.current && chkRef.current.indexOf(el) === -1) {
                            chkRef.current.push(el);
                        }
                    }}
                />
            );
        },
    },
    {
        cellKey: 'customer',
        headerLabel: '名單',
        width: 14,
        maxWidth: 22,
        align: 'left',
        formatCell: (cellValue, rowData) => {
            const { name, cellPhone: phone, status } = rowData;
            return nameLabel({ name, phone, status });
        },
    },
    {
        cellKey: 'sourceTypeText',
        headerLabel: '來源',
        maxWidth: 8,
        align: 'center',
        formatCell: (cellValue, rowData) => {
            return <Chip label={cellValue} color="warning" />;
        },
    },
    {
        cellKey: 'requiredTypeText',
        headerLabel: '需求',
        maxWidth: 8,
        align: 'center',
        formatCell: (cellValue, rowData) => {
            return <Chip label={cellValue} color="success" />;
        },
    },
    {
        cellKey: 'contact',
        headerLabel: '聯繫',
        align: 'left',
        minWidth: 12,
        maxWidth: 15,
        formatCell: (cellValue, rowData, { setContactState }) => {
            return !_.isEmpty(cellValue) ? (
                <DescribeLabelBox {...cellValue} targetID={rowData.customerID} peopelType={'1'} setContactState={setContactState} />
            ) : (
                ''
            );
        },
    },
    {
        cellKey: 'introducer',
        headerLabel: '介紹人',
        maxWidth: 6,
        align: 'center',
    },
    {
        cellKey: 'registrationDate',
        headerLabel: '登錄日期',
        align: 'center',
        formatCell: (cellValue) => {
            return (
                <Typography variant={'body1'} className={'mb-0'}>
                    {parseDate(cellValue, 'YYYY/MM/DD')}
                </Typography>
            );
        },
    },
];

const namelistMoreLabel = {
    cellKey: '',
    headerLabel: '',
    align: 'right',
    isIcon: true,
    formatCell: (cellValue, rowData, { setContactState }) => {
        return (
            <MoreLabel
                moreActionArray={['edit', 'appointment', 'sms', 'contact', 'onvalid', 'remove']}
                urls={{
                    edit: `${NON_MEMBER_PROSPECT_INFO_ROUTER}${rowData.customerID}`,
                    apo: `${NON_MEMBER_CUSTOMER_ADD_ROUTER}${rowData.customerID}`,
                }}
                sourceData={rowData}
                peopleID={rowData.customerID}
                dialogViewMap={prospectDialogTextMap}
                setContactState={setContactState}
                {...(rowData || {})}
            />
        );
    },
};

const ProspectTable = (props) => {
    const { tableData, setBookmarkNum, setContactState, refresh } = useNonMember();
    const { pageIndex: page, totalPage: count } = tableData;
    let { list: rowsData = [] } = tableData;
    rowsData = rowsData || [];
    const labelTitle = useMemo(() => [...rowsLabel, namelistMoreLabel], []);
    const [handleCheckAllChange, handleCheckedChange, selectedAllRef, selectedOptRef, indeterminateRef, , clearAllChecked] = useGroupCheckbox(rowsData);
    const handlePageChange = (e, num) => setBookmarkNum(num);

    return (
        <Box className="customer-table">
            <Box className="customer-table-action">
                <Box className={clsx('customer-table-action-icon', rowsData.length === 0 && 'visibility-hidden')}>
                    <FormControlLabel
                        value="all"
                        label="全選"
                        control={
                            <Checkbox
                                color="primary"
                                indeterminateRef={indeterminateRef}
                                inputRef={(el) => (selectedAllRef.current = el)}
                                onChange={handleCheckAllChange}
                            />
                        }
                    />
                    <IconGroupHeader
                        viewIconArray={['sms', 'folder', 'invalid', 'remove']}
                        selectedRef={selectedOptRef}
                        rowsData={rowsData}
                        customerType={1}
                        dialogViewMap={prospectDialogTextMap}
                        refresh={refresh.current}
                        clearAllChecked={clearAllChecked}
                    />
                </Box>
            </Box>
            <Box>
                <Tablenization Pagination={MorePageBox} page={page} count={count} onChange={handlePageChange} isPagination>
                    <Tablenization.TableRowHeader headerRow={labelTitle} />
                    <Tablenization.TableRowBody
                        headerRow={labelTitle}
                        rowsData={rowsData}
                        chkRef={selectedOptRef}
                        checkedOnChange={handleCheckedChange}
                        setContactState={setContactState}
                    />
                </Tablenization>
            </Box>
        </Box>
    );
};

export default ProspectTable;
