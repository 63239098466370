import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Grid } from '@mui/material';
import { EditTemporaryDialog } from '@icoach/nonMember/dialog/';
import SendSmsDialog from '@icoach/components/dialog/SendSmsDialog';
import { NON_MEMBER_TEMPORARIES_OVERVIEW_ROUTER } from '@icoach/router/NonMemberRouter';
import { Typography, Stack, IconButton, Chip, Button } from '@common/components/';
import { BorderColor as BorderColorIcon, Clock as ClockIcon, File as FileIcon, Magnifier as MagnifierIcon } from '@common/SvgIcon/';
import { parseDate } from '@util/moment';

const FilterConditionBox = (props) => {
    const { texts = '' } = props;
    const splitTexts = texts?.split('&') ?? [];

    const FilterConditionContent = (props) => {
        const { items } = props;
        const enums = items.split(',').sort(({ length: f }, { length: s }) => f - s);
        return (
            <Stack spacing={1} flexWrap="wrap">
                {enums.map((text, _i) => {
                    return <Chip key={`${text}_${_i}`} label={text} color={'secondary'} />;
                })}
            </Stack>
        );
    };

    return (
        <React.Fragment>
            {splitTexts.map((t) => {
                const [title, items] = t.split(':');
                if (!title) return null;
                return (
                    <Stack className="py-1" key={`${title}${items}`} spacing={1} alignItems="center">
                        <Typography style={{ flexShrink: 0, width: 80 }} variant={'h6'} component={'span'}>{`${title}:`}</Typography>
                        <FilterConditionContent items={items?.trim() ?? ''} />
                    </Stack>
                );
            })}
        </React.Fragment>
    );
};

const TemporariesInfoHeader = (props) => {
    const [dialogOpen, setDialogOpen] = useState(null);
    const histtory = useHistory();
    const { sourceData = {} } = props;
    const { temporaryName, customerType, note = '', createdDate, temporaryID } = sourceData;

    const handleEditClick = (e) => {
        setDialogOpen('edit');
    };

    const handleSendTemporarySMS = () => {
        setDialogOpen('sendSMS');
    };

    const handleReturnOnClick = () => {
        histtory.push(NON_MEMBER_TEMPORARIES_OVERVIEW_ROUTER);
    };

    return (
        <div className="p-4">
            <Grid container>
                <Grid item xs={8}>
                    <Stack alignItems="center" spacing={2}>
                        <IconButton onClick={handleEditClick}>
                            <BorderColorIcon />
                        </IconButton>
                        <Typography variant="span" className="h3">
                            {temporaryName}
                        </Typography>
                    </Stack>
                </Grid>
                <Grid item xs={4}>
                    <Stack justifyContent="end" spacing={1}>
                        <Button variant="outlined" onClick={handleSendTemporarySMS}>
                            發送簡訊
                        </Button>
                        <Button variant="contained" onClick={handleReturnOnClick}>
                            返回暫存清單
                        </Button>
                    </Stack>
                </Grid>
            </Grid>
            <Stack className="pt-2" spacing="3">
                <Stack alignItems="center" spacing={1} style={{ flexShrink: 0 }}>
                    <ClockIcon />
                    <div>{parseDate(createdDate)}</div>
                </Stack>
                <Stack alignItems="center" spacing={1} style={{ flexShrink: 0 }}>
                    <FileIcon />
                    <div>{customerType}</div>
                </Stack>
                <Stack alignItems="center" spacing={1}>
                    <MagnifierIcon />
                    <div>
                        <FilterConditionBox texts={note} />
                    </div>
                </Stack>
            </Stack>
            <EditTemporaryDialog open={dialogOpen === 'edit'} onClose={() => setDialogOpen(null)} fileName={temporaryName} />
            <SendSmsDialog open={dialogOpen === 'sendSMS'} onClose={() => setDialogOpen(null)} affixParams={{ temporaryID }} isTemporaries />
        </div>
    );
};

export default TemporariesInfoHeader;
