import Mock from 'mockjs';
import { successApi } from './respondData';

export const withMockAuthUserProvider = () => {
    Mock.mock(/^\/api\/me$/, 'get', () => {
        let sourceData = { ...successApi };
        sourceData.result = {
            userID: 16,
            userName: 'curves',
            displayName: '健康快樂店',
            email: '',
            routerType: 2,
        };
        return sourceData;
    });

    Mock.mock(/^\/api\/me\/check$/, 'get', () => {
        let sourceData = { ...successApi };
        sourceData.result = true;
        return sourceData;
    });
    
    Mock.setup({ timeout: '200-800' });
};
