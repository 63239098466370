import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import { isEmpty } from 'lodash';
import clsx from 'clsx';
import GradientColorHeader from '@icoach/components/dialog/GradientColorHeader';
import { Dialog, DialogActions, DialogContent, Grid } from '@mui/material';
import { Alert, Button, Collapse, Form, FormControlLabel, MenuItem, SelectField, Switch, TextField } from '@common/components/';
import { ReviewOrderCard } from '@icoach/accountBook/components/CreditOrderList';
import { formatCurrencyFn, refIsRequiredError } from '@util/utils';
import useOrderApi from '@apis/useOrderApi';
import {
    InvoiceByCarrierNo,
    InvoiceByCarrierType,
    InvoiceByDonate,
    InvoiceByEmail,
    InvoiceByIssuingType,
    InvoiceByManual,
    InvoiceByTriplicateUniform,
    invoiceCarrierTypeMap,
    issuingTypeMap,
    SynchronizeMemberInvoice,
} from '@icoach/components/Invoice';
import { PaidList } from '@icoach/documents/components';
import { PaidListNew } from '@icoach/documents/components/PaidList';

const OrderPaymentInfoCard = (props) => {
    const { data = {} } = props;
    const { cashPayment = 0, cardPayment = 0 } = data;
    return (
        <PaidListNew className={'checkout-box'}>
            <PaidList.PaidListGroup>
                <PaidList.PaidListItem label={'現金收款金額'}>
                    <strong>{formatCurrencyFn(cashPayment)}</strong>
                </PaidList.PaidListItem>
                <PaidList.PaidListItem label={'信用卡收款金額'}>
                    <strong>{formatCurrencyFn(cardPayment)}</strong>
                </PaidList.PaidListItem>
            </PaidList.PaidListGroup>
        </PaidListNew>
    );
};

const CheckoutOrderCard = (props) => {
    const { data = {} } = props;
    const { orders, cashPayment, cardPayment } = data;
    return (
        <React.Fragment>
            {!isEmpty(orders) &&
                orders.map((item) => {
                    return <ReviewOrderCard key={item.orderID} className={'mb-4'} data={item} />;
                })}
            <Grid spacing={3} container>
                <Grid className={'ml-auto'} xs={6} item>
                    <OrderPaymentInfoCard data={{ cashPayment, cardPayment }} />
                </Grid>
            </Grid>
        </React.Fragment>
    );
};

const InvoiceGroup = React.forwardRef((props, ref) => {
    const { className, sourceData = {}, errorModule, options = {}, readOnly = false } = props;
    const {
        issuingType: issuingTypeProps,
        invoiceCarrierType: invoiceCarrierTypeProps,
        invoiceCarrierNo,
        donationCode,
        invoiceNo,
        issuingEmail,
    } = sourceData || {};
    const { invoiceTypeOptions = [], invoiceCarrierTypeOptions = [] } = options;
    const [issuingType, setIssuingType] = useState(issuingTypeProps || 2);
    const [invoiceCarrierType, setInvoiceCarrierType] = useState(invoiceCarrierTypeProps || 1);
    const issuingTypeRef = useRef(null);
    const donateRef = useRef(null);
    const invoiceCarrierTypeRef = useRef(null);
    const invoiceCarrierNoRef = useRef(null);
    const triplicateUniformRef = useRef(null);
    const manualRef = useRef(null);
    const emailRef = useRef(null);
    const SynchronizeMemberInvoiceRef = useRef(null);

    const isNotificationSMS = String(invoiceCarrierType) === invoiceCarrierTypeMap.sms;

    const handleChangeIssuingType = (value) => {
        setIssuingType(value);
    };

    const handleChangeInvoiceCarrierType = (value) => {
        setInvoiceCarrierType(value);
    };

    useImperativeHandle(
        ref,
        () => ({
            getResult: () => {
                let result = {};

                if (issuingTypeRef.current && issuingTypeRef.current.getResult) {
                    result = Object.assign(result, issuingTypeRef.current.getResult());
                }

                if (donateRef.current && donateRef.current.getResult) {
                    result = Object.assign(result, donateRef.current.getResult());
                }

                if (invoiceCarrierTypeRef.current && invoiceCarrierTypeRef.current.getResult) {
                    const { carrierType } = invoiceCarrierTypeRef.current.getResult();
                    Object.assign(result, { invoiceCarrierType: carrierType });
                }

                if (invoiceCarrierNoRef.current && invoiceCarrierNoRef.current.getResult) {
                    const { carrierNo } = invoiceCarrierNoRef.current.getResult();
                    Object.assign(result, { invoiceCarrierNo: carrierNo });
                }

                if (triplicateUniformRef.current && triplicateUniformRef.current.getResult) {
                    result = Object.assign(result, triplicateUniformRef.current.getResult());
                }

                if (manualRef.current && manualRef.current.getResult) {
                    result = Object.assign(result, manualRef.current.getResult());
                }

                if (emailRef.current && emailRef.current.getResult) {
                    result = Object.assign(result, emailRef.current.getResult());
                }

                if (SynchronizeMemberInvoiceRef.current && SynchronizeMemberInvoiceRef.current.getResult) {
                    result = Object.assign(result, SynchronizeMemberInvoiceRef.current.getResult());
                }

                return result;
            },
            isError: () => refIsRequiredError(issuingTypeRef, donateRef, invoiceCarrierTypeRef, invoiceCarrierNoRef, triplicateUniformRef, manualRef, emailRef),
        }),
        // eslint-disable-next-line
        [],
    );

    useEffect(
        () => {
            if (issuingTypeProps) setIssuingType(issuingTypeProps);
        },
        // eslint-disable-next-line
        [issuingTypeProps],
    );

    useEffect(
        () => {
            if (invoiceCarrierTypeProps) setInvoiceCarrierType(invoiceCarrierTypeProps);
        },
        // eslint-disable-next-line
        [invoiceCarrierTypeProps],
    );

    return (
        <Grid key={issuingTypeProps} className={className} spacing={2} container>
            <Grid xs={12} item>
                <InvoiceByIssuingType
                    ref={issuingTypeRef}
                    defaultValue={issuingTypeProps}
                    options={invoiceTypeOptions}
                    errorData={errorModule}
                    onChange={handleChangeIssuingType}
                    readOnly={readOnly}
                />
            </Grid>
            {String(issuingType) === issuingTypeMap.donate && (
                <Grid xs={12} item>
                    <InvoiceByDonate ref={donateRef} defaultValue={donationCode} errorData={errorModule} readOnly={readOnly} />
                </Grid>
            )}
            {String(issuingType) === issuingTypeMap.duplicateUniform && (
                <React.Fragment>
                    <Grid xs={12} item>
                        <InvoiceByCarrierType
                            ref={invoiceCarrierTypeRef}
                            defaultValue={invoiceCarrierType}
                            options={invoiceCarrierTypeOptions}
                            errorData={errorModule}
                            onChange={handleChangeInvoiceCarrierType}
                            readOnly={readOnly}
                        />
                    </Grid>
                    {(String(invoiceCarrierType) === invoiceCarrierTypeMap.citizenDigitalCertificate1 ||
                        String(invoiceCarrierType) === invoiceCarrierTypeMap.mobile) && (
                        <Grid xs={12} item>
                            <InvoiceByCarrierNo
                                ref={invoiceCarrierNoRef}
                                defaultValue={invoiceCarrierNo}
                                invoiceCarrierType={invoiceCarrierType}
                                errorData={errorModule}
                                readOnly={readOnly}
                            />
                        </Grid>
                    )}
                </React.Fragment>
            )}
            {String(issuingType) === issuingTypeMap.triplicateUniform && (
                <InvoiceByTriplicateUniform ref={triplicateUniformRef} sourceData={sourceData} errorData={errorModule} readOnly={readOnly} />
            )}
            {String(issuingType) === issuingTypeMap.manual && (
                <Grid xs={12} item>
                    <InvoiceByManual ref={manualRef} sourceData={{ invoiceNo }} errorData={errorModule} readOnly={readOnly} />
                </Grid>
            )}

            {String(issuingType) !== issuingTypeMap.manual && (
                <React.Fragment>
                    <Grid item xs={12}>
                        <InvoiceByEmail ref={emailRef} defaultValue={issuingEmail} errorData={errorModule} required={!isNotificationSMS} readOnly={readOnly} />
                    </Grid>
                    <Grid xs={12} item>
                        <SynchronizeMemberInvoice ref={SynchronizeMemberInvoiceRef} />
                    </Grid>
                </React.Fragment>
            )}
        </Grid>
    );
});

const ResetOrderInvoiceDialogContent = React.forwardRef((props, ref) => {
    const { sourceData = {} } = props;
    const { invoiceExceptionMessage, coachOptions: employeeOptions, invoiceTypeOptions, invoiceCarrierTypeOptions, isIssued, tipText } = sourceData;
    const [showOrder, setShowOrder] = useState(false);
    const invoiceRef = useRef(null);
    const employeeRef = useRef(null);
    const notesRef = useRef(null);

    // 顯示/隱藏訂單資訊
    const handleStateChange = (_, value) => {
        setShowOrder(value);
    };

    const getResult = () => {
        let result = {};
        if (invoiceRef.current && invoiceRef.current.getResult) {
            result = Object.assign(result, invoiceRef.current.getResult());
        }
        if (employeeRef.current && employeeRef.current.getResult) {
            result = Object.assign(result, { employeeID: employeeRef.current.getResult() });
        }
        result = Object.assign(result, { notes: notesRef.current.getResult() });
        result = Object.assign(result, { isIssued });
        return result;
    };

    useImperativeHandle(
        ref,
        () => ({
            getResult,
            isError: () => refIsRequiredError(invoiceRef, employeeRef),
        }),
        // eslint-disable-next-line
        [isIssued],
    );

    const alertStyles = isIssued ? 'info' : 'error';

    return (
        <DialogContent>
            <Alert className="mb-3" severity={alertStyles} variant="filled" color={alertStyles}>
                {isIssued ? tipText : invoiceExceptionMessage}
            </Alert>
            <Grid className={'mb-3'} spacing={3} container>
                <Grid xs={2} item />
                <Grid xs={8} item>
                    <Grid spacing={3} container>
                        <Grid xs={12} item>
                            <InvoiceGroup ref={invoiceRef} sourceData={sourceData} options={{ invoiceTypeOptions, invoiceCarrierTypeOptions }} />
                        </Grid>
                        <Grid xs={12} item>
                            <SelectField label={'擔當'} ref={employeeRef} fullWidth required>
                                {Array.isArray(employeeOptions) &&
                                    employeeOptions
                                        .filter((item) => item || !item.disabled)
                                        .map((item) => (
                                            <MenuItem key={item.value} value={item.value}>
                                                {item.text}
                                            </MenuItem>
                                        ))}
                            </SelectField>
                        </Grid>
                        <Grid xs={12} item>
                            <TextField ref={notesRef} label="備註" rows={4} multiline fullWidth />
                        </Grid>
                        <Grid xs={12} item>
                            <FormControlLabel
                                className={'ml-0 mb-3'}
                                control={<Switch defaultChecked={showOrder} onChange={handleStateChange} />}
                                prefixLabel={showOrder ? '隱藏訂單資訊' : '顯示訂單資訊'}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Collapse in={showOrder}>
                <CheckoutOrderCard data={sourceData} />
            </Collapse>
        </DialogContent>
    );
});

const ResetOrderInvoiceDialog = (props) => {
    const { className, open, sourceData: sourceDataProps = {}, onClose, refresh } = props;
    const orderID = sourceDataProps?.orderID;
    const { getOrderReissuingApi, postOrderReissuingApi } = useOrderApi();
    const [sourceData, setSourceData] = useState({});
    const contentRef = useRef();

    const doGetOrderReissuingApi = async (orderID) => {
        let res = await getOrderReissuingApi(orderID);
        if (res) {
            setSourceData(res);
        }
    };

    const doPostOrderReissuingApi = async (orderID, params) => {
        let res = await postOrderReissuingApi(orderID, params);
        if (res) {
            refresh && refresh(orderID);
            onClose();
        }
    };

    const getParams = () => {
        let result = {
            orderID,
            syncUpgradeMemberEmail: false,
            syncUpgradeMemberCarrier: false,
        };
        if (contentRef.current && contentRef.current.getResult) {
            result = Object.assign(result, contentRef.current.getResult());
        }
        return result;
    };

    const handleSubmit = () => {
        const isError = refIsRequiredError(contentRef);
        if (!isError) {
            const params = getParams();
            doPostOrderReissuingApi(orderID, params);
        }
    };

    useEffect(
        () => {
            if (open && orderID) {
                doGetOrderReissuingApi(orderID);
            }
            return () => setSourceData({});
        },
        // eslint-disable-next-line
        [open, orderID],
    );

    return (
        <Dialog
            className={clsx('reset-order-invoice-dialog', 'simple-content', 'dialog', className)}
            PaperProps={{ className: 'wx-90rem', component: Form }}
            open={open}
            onSubmit={handleSubmit}
            scroll="paper"
            fullWidth
        >
            <GradientColorHeader onClose={onClose}>重新設定發票</GradientColorHeader>
            <ResetOrderInvoiceDialogContent ref={contentRef} sourceData={sourceData} />
            <DialogActions>
                <Button type={'submit'} variant="contained">
                    確認送出
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ResetOrderInvoiceDialog;
