import React, { useState, useCallback } from 'react';
import clsx from 'clsx';
import { TaskTodo, SimpleContentItem, GuidanceNotesTitle } from '@icoach/members/components';
import {
    AgreedNotesDialog,
    GuidanceNotesDialog,
    HundredTShirtSizeDialog,
    MemoDialog,
    ProbioticsPushDialog,
    ProteinPushDialog,
    CalciumPushDialog,
    XFactorDialog,
    MeasureDayDialog,
    NotificationDialog,
    SpecialConditionDialog,
    BatchPaymentMemo,
} from '@icoach/members/memberDetail/dialog';
import { useMemberDetailContext } from '@icoach/members/memberDetail/MemberDetailContext';
import { Check as CheckIcon, Measuring as MeasuringIcon } from '@common/SvgIcon/';
import { Card, Stack, Typography } from '@common/components/';

export const handleListValue = (data) => {
    let text;
    if (Array.isArray(data)) {
        text = data.join('\n');
    }
    return text;
};
/**
 * @param {string} id
 * @param {string} title
 * @param {string} value
 * @param {boolean} isMultiline
 * @param {boolean} isEdit
 * @param {{}} otherObj
 * @returns
 */
export const makeBasicData = (id, title = '', value = '', isMultiline = false, isEdit = true, otherObj = {}) => {
    return {
        id,
        title,
        value,
        isMultiline,
        isEdit,
        ...otherObj,
    };
};

const setValueText = (val, defaultText = '未設定') => {
    return val ? val : defaultText;
};

export const viewOrderText = (s1, s2) => {
    const text = s2 ? `${s1}(${s2})` : s1;
    return text;
};

const SalesInfoBox = (props) => {
    const { data = {}, resource } = props;
    const {
        memberID,
        measureDay,
        hundredTShirtSize,
        hasSMS,
        xFactorNotes,
        agreedNotes,
        guidanceNotes,
        specialCondition,
        isInterviewNotMeasure,
        memo,
        batchPaymentMemo,
    } = data;
    const { doMemberProfileApi } = useMemberDetailContext();
    const getSalesData = useCallback(
        () => {
            const {
                measureDay,
                proteinPush,
                probioticsPush,
                calciumPush,
                hundredTShirtSize,
                hasSMS,
                xFactorNotes,
                agreedNotes,
                guidanceNotes,
                specialCondition,
                memo,
                batchPaymentMemo = '',
                isFinishSevenDay,
                isMonthMeasured,
            } = data;

            return [
                makeBasicData(
                    'measureDay',
                    'WM日',
                    <Stack alignItems="center" justifyContent="center" spacing={2}>
                        {isFinishSevenDay ? <CheckIcon color="success" /> : <Typography component="span">{setValueText(measureDay)}</Typography>}
                        {isMonthMeasured ? <MeasuringIcon fontSize="large" color="primary" /> : null}
                    </Stack>,
                    false,
                    true
                ),
                makeBasicData('hasSMS', '訊息通知', setValueText(hasSMS ? '開啟' : '關閉'), false, true, {
                    className: 'mb-1',
                }),
                makeBasicData('proteinPush', '蛋白訂單', viewOrderText(proteinPush.pushStatusText, proteinPush.reasonTypeText), false, proteinPush.isEditable),
                makeBasicData(
                    'probioticsPush',
                    '益菌訂單',
                    viewOrderText(probioticsPush.pushStatusText, probioticsPush.reasonTypeText),
                    false,
                    probioticsPush.isEditable
                ),
                makeBasicData(
                    'calciumPush',
                    '鈣訂單',
                    viewOrderText(calciumPush.pushStatusText, calciumPush.reasonTypeText),
                    false,
                    calciumPush.isEditable
                ),
                makeBasicData('hundredTShirtSize', '百T尺寸', setValueText(hundredTShirtSize), false, true, {
                    className: 'mb-1',
                }),

                makeBasicData('xFactorNotes', 'X-Factor', handleListValue(xFactorNotes), true, true, {
                    className: 'mb-2',
                }),
                makeBasicData('agreedNotes', '約定事項', handleListValue(agreedNotes), true, true, {
                    className: 'mb-2',
                }),
                makeBasicData('guidanceNotes', <GuidanceNotesTitle />, guidanceNotes, true, true, {
                    className: 'mb-2',
                }),
                makeBasicData('specialCondition', '特殊狀況', specialCondition, true, true, {
                    className: 'mb-2',
                }),
                makeBasicData('memo', '備註', memo, true, true, {
                    className: 'mb-2',
                }),
                makeBasicData('batchPaymentMemo', '批次扣款備註', batchPaymentMemo, true, true, {
                    className: 'mb-2',
                }),
            ];
        },
        // eslint-disable-next-line
        [data]
    );

    const [openDialog, setOpenDialog] = useState('');
    const handleOpenDialog = (target) => setOpenDialog(target);
    const handleCloseDialog = () => setOpenDialog('');

    return (
        <Card className="mb-4">
            <Card.CardHeader title="會員狀態" className="pt-4 pb-0" />
            <Card.CardContent className="px-4 member-state">
                <ul className={'list'}>
                    {getSalesData().map((item) => {
                        return (
                            <li className={clsx('lists-item', item.className)} key={item.id}>
                                <SimpleContentItem
                                    label={item.title}
                                    isEdit={item.isEdit}
                                    isMultiline={item.isMultiline}
                                    handleClickEdit={() => handleOpenDialog(item.id)}
                                >
                                    {item.value}
                                </SimpleContentItem>
                            </li>
                        );
                    })}
                </ul>
            </Card.CardContent>
            <MeasureDayDialog
                open={openDialog === 'measureDay'}
                memberID={memberID}
                data={{ measureDay, isInterviewNotMeasure }}
                onClose={handleCloseDialog}
                refresh={doMemberProfileApi}
            />
            <NotificationDialog open={openDialog === 'hasSMS'} memberID={memberID} data={hasSMS} onClose={handleCloseDialog} refresh={doMemberProfileApi} />
            <HundredTShirtSizeDialog
                open={openDialog === 'hundredTShirtSize'}
                memberID={memberID}
                data={hundredTShirtSize}
                resource={resource}
                onClose={handleCloseDialog}
                refresh={doMemberProfileApi}
            />
            <AgreedNotesDialog
                open={openDialog === 'agreedNotes'}
                memberID={memberID}
                data={agreedNotes}
                onClose={handleCloseDialog}
                refresh={doMemberProfileApi}
            />
            <GuidanceNotesDialog
                open={openDialog === 'guidanceNotes'}
                memberID={memberID}
                data={guidanceNotes}
                onClose={handleCloseDialog}
                refresh={doMemberProfileApi}
            />
            <MemoDialog open={openDialog === 'memo'} memberID={memberID} data={memo} onClose={handleCloseDialog} refresh={doMemberProfileApi} />
            <SpecialConditionDialog
                open={openDialog === 'specialCondition'}
                memberID={memberID}
                data={specialCondition}
                onClose={handleCloseDialog}
                refresh={doMemberProfileApi}
            />
            <ProbioticsPushDialog
                open={openDialog === 'probioticsPush'}
                memberID={memberID}
                data={data.probioticsPush}
                onClose={handleCloseDialog}
                refresh={doMemberProfileApi}
            />
            <ProteinPushDialog
                open={openDialog === 'proteinPush'}
                memberID={memberID}
                data={data.proteinPush}
                onClose={handleCloseDialog}
                refresh={doMemberProfileApi}
            />
            <CalciumPushDialog
                open={openDialog === 'calciumPush'}
                memberID={memberID}
                data={data.calciumPush}
                onClose={handleCloseDialog}
                refresh={doMemberProfileApi}
            />
            <XFactorDialog
                open={openDialog === 'xFactorNotes'}
                memberID={memberID}
                data={xFactorNotes}
                onClose={handleCloseDialog}
                refresh={doMemberProfileApi}
            />
            <BatchPaymentMemo
                open={openDialog === 'batchPaymentMemo'}
                memberID={memberID}
                data={batchPaymentMemo}
                onClose={handleCloseDialog}
                refresh={doMemberProfileApi}
            />
        </Card>
    );
};

const MemberDetailSideBar = (props) => {
    const { data = {}, memberID: id } = props;
    const { resource, todoTasks, serviceTasks, ...other } = data;
    const { serviceTodoTextOptions = [] } = resource;
    return (
        <React.Fragment>
            <SalesInfoBox data={other} resource={resource} />
            <TaskTodo className={'mb-4'} title={'待辦事項'} taskType={11} memberID={id} data={todoTasks} hasIcon />
            <TaskTodo title={'服銷待辦'} taskType={12} memberID={id} data={serviceTasks} options={serviceTodoTextOptions} />
        </React.Fragment>
    );
};

export default MemberDetailSideBar;
