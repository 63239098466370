import React, { useRef, useState, useEffect } from 'react';
import clsx from 'clsx';
import { Dialog, DialogActions, DialogContent } from '@mui/material';
import { Button } from '@common/components/';
import { UploadBox, UploadFeedbackBox } from '@icoach/components/';
import GradientColorHeader from '@icoach/components/dialog/GradientColorHeader';
import useOrderBatchPaymentApi from '@apis/useOrderBatchPaymentApi';

const getInitFeedbackData = () => {
    return {
        isShow: false,
        isError: false,
        data: {},
    };
};

const ImportAchDialog = (props) => {
    const { className, open, sourceData, onClose, refresh } = props;
    const { billingID } = sourceData || {};
    const { postOrderBatchPaymentAchImportApi } = useOrderBatchPaymentApi();
    const [feedbackData, setFeedBackData] = useState(getInitFeedbackData());
    const uploadFileRef = useRef(null);

    const doOrderBatchPaymentAchImportApi = async (bID, formData) => {
        let res = await postOrderBatchPaymentAchImportApi(bID, formData);
        if (res) {
            if (res.isError) {
                const { isError, result } = res;
                setFeedBackData({
                    isShow: true,
                    isError,
                    data: result,
                });
            } else {
                onClose();
                refresh && refresh(billingID);
            }
        }
    };

    // 上傳請假證明 事件
    const handleFileChange = (file) => {
        if (file) uploadFileRef.current = file;
    };

    const handleConfirm = () => {
        const formData = new FormData();
        if (uploadFileRef.current) {
            let file = uploadFileRef.current;
            formData.append('file', file, file.name);
            doOrderBatchPaymentAchImportApi(billingID, formData);
        }
    };

    useEffect(
        () => {
            if (open) {
                setFeedBackData(getInitFeedbackData());
            }
            return () => (uploadFileRef.current = null);
        },
        // eslint-disable-next-line
        [open],
    );

    if (!open) return null;

    return (
        <Dialog className={clsx('simple-content', 'dialog', className)} PaperProps={{ className: 'wx-65rem' }} open={open} fullWidth>
            <GradientColorHeader onClose={onClose}>匯入ACH扣款Excel</GradientColorHeader>
            <DialogContent>
                {!feedbackData.isShow ? (
                    <UploadBox className={'import-ach-box'} onFileChange={handleFileChange} fileExtension={['xlsx']} />
                ) : (
                    <UploadFeedbackBox
                        isShow={feedbackData.isShow}
                        className={'import-ach-box'}
                        isError={feedbackData.isError}
                        {...feedbackData.data}
                        onConfirm={onClose}
                        onDownload={onClose}
                    />
                )}
            </DialogContent>
            {!feedbackData.isShow && (
                <DialogActions>
                    <Button variant="contained" onClick={handleConfirm}>
                        確認匯入
                    </Button>
                </DialogActions>
            )}
        </Dialog>
    );
};

export default ImportAchDialog;
