import React from 'react';
import SvgIcon from '../SvgIcon';
import useForkRef from '@util/hook/useForkRef';


const BellSolid = React.forwardRef((props, ref) => {
    const { className, ...others } = props;
    const handleRef = React.useRef(null);
    const svgRef = useForkRef(handleRef, ref);
    const defaultProperty = {
        className,
        ref: svgRef,
        ...others,
    };
    return (
        <SvgIcon {...defaultProperty}>
            <path d="m12,0c-.95,0-1.71.67-1.71,1.5v.9c-3.91.69-6.86,3.72-6.86,7.35v.88c0,2.2-.93,4.33-2.6,5.98l-.4.39c-.45.44-.56,1.07-.28,1.61s.89.89,1.56.89h20.57c.67,0,1.29-.35,1.56-.89s.17-1.17-.28-1.61l-.4-.39c-1.67-1.65-2.6-3.77-2.6-5.98v-.88c0-3.63-2.95-6.66-6.86-7.35v-.9c0-.83-.77-1.5-1.71-1.5Zm2.43,23.12c.64-.56,1-1.33,1-2.12h-6.86c0,.8.36,1.56,1,2.12s1.52.88,2.43.88,1.78-.31,2.43-.88Z" />
        </SvgIcon>
    );
});

export default BellSolid;
