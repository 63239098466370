import React, { useState, useRef, useImperativeHandle, useLayoutEffect } from 'react';
import { Grid } from '@mui/material';
import { PaidList } from '@icoach/documents/components/';
import { TextField } from '@common/components/';
import { accAdd } from '@util/math';

const MembershipTransferOverFeeStatement = React.forwardRef((props, ref) => {
    const { tmpSourceData, isReadOnly } = props;
    const { transferFee: transferFeeProps, cost: costProps, cashAmount, creditCardAmount } = tmpSourceData.current;
    const [transferFeeState] = useState(transferFeeProps);
    const [costState, setCostState] = useState(costProps);
    const feeRef = useRef(null);

    const total = accAdd(costState, transferFeeState);

    const setParams = () => {
        if (isReadOnly) return;
        const { cashPaymentAmount, cardPaymentAmount } = feeRef.current.getResult();
        tmpSourceData.current.transferFee = transferFeeState * 1;
        tmpSourceData.current.cost = costState * 1;
        tmpSourceData.current.cashAmount = cashPaymentAmount * 1;
        tmpSourceData.current.creditCardAmount = cardPaymentAmount * 1;
    };

    const handleCostFeeChange = (dom) => {
        setCostState(dom.target.value);
    };

    useImperativeHandle(
        ref,
        () => ({
            getResult: () => setParams(),
        }),
        // eslint-disable-next-line
        [transferFeeState, costState]
    );

    useLayoutEffect(
        () => {
            return () => {
                if (!feeRef.current) return;
                setParams();
            };
        },
        // eslint-disable-next-line
        [costState]
    );

    return (
        <Grid xs={8} item>
            <PaidList
                ref={(el) => (feeRef.current = el)}
                cashPaymentAmountProps={{ defaultValue: cashAmount }}
                cardPaymentAmountProps={{ defaultValue: creditCardAmount }}
                readOnly={isReadOnly}
                total={total}
            >
                <PaidList.PaidListItem label="轉讓費" required>
                    <TextField maskType={'MONEY'} value={transferFeeState} disabled readOnly={isReadOnly} />
                </PaidList.PaidListItem>
                <PaidList.PaidListItem label="工本費" required>
                    <TextField maskType={'MONEY'} value={costState} onChange={handleCostFeeChange} readOnly={isReadOnly} />
                </PaidList.PaidListItem>
            </PaidList>
        </Grid>
    );
});

export default MembershipTransferOverFeeStatement;
