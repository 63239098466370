import React, { useImperativeHandle, useRef } from 'react';
import clsx from 'clsx';
import { Dialog, DialogActions, DialogContent, Grid } from '@mui/material';
import { Button, Form, MenuItem, SelectField, TextField } from '../../../components';
import GradientColorHeader from '../../components/dialog/GradientColorHeader';
import useOrderAccountBookApi from '../../../apis/useOrderAccountBookApi';
import { refIsRequiredError } from '../../../util/utils';
import { parseDate } from '../../../util/moment';
import { useSettleCashContext } from '../context/SettleCashContext';

const PettyMoneyDialogContent = React.forwardRef((props, ref) => {
    const { employeeOptions = [] } = props;
    const pettyMoneyRef = useRef();
    const employeeRef = useRef();

    useImperativeHandle(
        ref,
        () => ({
            getResult: () => {
                return {
                    fundPrice: pettyMoneyRef.current.getResult(),
                    employeeID: employeeRef.current.getResult(),
                };
            },
            isError: () => refIsRequiredError(pettyMoneyRef, employeeRef),
        }),
        []
    );

    return (
        <DialogContent>
            <Grid spacing={3} container>
                <Grid xs={6} item>
                    <TextField maskType={'MONEY'} label={'零用金'} ref={pettyMoneyRef} fullWidth required />
                </Grid>
                <Grid xs={6} item>
                    <SelectField label={'擔當'} ref={employeeRef} fullWidth required>
                        {Array.isArray(employeeOptions) &&
                            employeeOptions
                                .filter((item) => item || !item.disabled)
                                .map((item) => (
                                    <MenuItem key={item.value} value={item.value}>
                                        {item.text}
                                    </MenuItem>
                                ))}
                    </SelectField>
                </Grid>
            </Grid>
        </DialogContent>
    );
});
const PettyMoneyDialog = (props) => {
    const { className, open, onClose } = props;
    const { postOrderAccountBookFundApi } = useOrderAccountBookApi();
    const { accountingDate, resource, refresh } = useSettleCashContext();
    const fieldRef = useRef();

    const getParams = () => {
        return {
            accountingDate: parseDate(accountingDate, 'YYYY-MM-DDTHH:mm:ss'),
            ...fieldRef.current.getResult(),
        };
    };

    const checkError = () => fieldRef.current.isError();

    const doOrderAccountBookFundApi = async () => {
        let params = getParams();
        let res = await postOrderAccountBookFundApi(params);
        if (res) {
            refresh && refresh(accountingDate);
            onClose();
        }
    };

    const handleSubmit = () => {
        if (!checkError()) doOrderAccountBookFundApi();
    };

    if (!open) return null;

    return (
        <Dialog
            className={clsx('simple-content', 'dialog', className)}
            PaperProps={{ className: 'wx-65rem', component: Form }}
            onSubmit={handleSubmit}
            open={open}
            fullWidth
        >
            <GradientColorHeader onClose={onClose}>編輯零用金</GradientColorHeader>
            <PettyMoneyDialogContent ref={fieldRef} employeeOptions={resource?.coachOptions} />
            <DialogActions>
                <Button type={'submit'} variant="contained">
                    確認送出
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default PettyMoneyDialog;
