import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { setLoadingData } from '../../../redux/loading/action';

const useShowLoading = () => {
    const _dispatch = useDispatch();
    const _showLoading = useCallback(
        (isShow) => {
            _dispatch(setLoadingData(isShow));
        },
        // eslint-disable-next-line
        []
    );

    return _showLoading;
};

export default useShowLoading;
