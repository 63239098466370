import { useCallback } from 'react';
import useAjax from '@apis/useAjax';
import { useSnackbar } from 'notistack';

const GET_ORDERS_STATISTICS_OPTIONS_API = `/api/orders/statistics/options/`;
const GET_ORDERS_STATISTICS_SITE_API = `/api/orders/statistics/on-site/`;
const GET_ORDERS_STATISTICS_ALL_API = `/api/orders/statistics/on-all/`;
const GET_ORDERS_STATISTICS_FILTER_ITEMS_API = (date, filterType) => `/api/orders/statistics/${date}/${filterType}/items/`;

const useOrderStatisticsApi = () => {
    const Ajax = useAjax();
    const { enqueueSnackbar } = useSnackbar();

    const getOrderStatisticsOptionsApi = useCallback(
        async () => {
            let resp = await Ajax.get(GET_ORDERS_STATISTICS_OPTIONS_API);
            !resp && enqueueSnackbar('讀取資料失敗', { variant: 'error' });
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    const getOrderStatisticsSiteApi = useCallback(
        async (params) => {
            let resp = await Ajax.get(GET_ORDERS_STATISTICS_SITE_API, params);
            !resp && enqueueSnackbar('讀取資料失敗', { variant: 'error' });
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    const getOrderStatisticsAllApi = useCallback(
        async (params) => {
            let resp = await Ajax.get(GET_ORDERS_STATISTICS_ALL_API, params);
            !resp && enqueueSnackbar('讀取資料失敗', { variant: 'error' });
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    const getOrderStatisticsFilterItemsApi = useCallback(
        async (params) => {
            let { date, filterType, ...other } = params;
            let resp = await Ajax.get(GET_ORDERS_STATISTICS_FILTER_ITEMS_API(date, filterType), other);
            !resp && enqueueSnackbar('讀取資料失敗', { variant: 'error' });
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    return {
        getOrderStatisticsOptionsApi,
        getOrderStatisticsSiteApi,
        getOrderStatisticsAllApi,
        getOrderStatisticsFilterItemsApi,
    };
};

export default useOrderStatisticsApi;
