import React, { useImperativeHandle, useRef, useState } from 'react';
import clsx from 'clsx';
import { Dialog, DialogActions, DialogContent } from '@mui/material';
import { Button, Form } from '@common/components/';
import GradientColorHeader from '@icoach/components/dialog/GradientColorHeader';
import InvoiceCard, { INVOICE_CARRIER_TYPE_OPTIONS, INVOICE_ISSUING_TYPE_OPTIONS } from '@icoach/documents/components/InvoiceCard';
import { useMemberProfileApi } from '@apis/useMemberProfileApi';
import { refIsRequiredError } from '@util/utils';
import { useMemberDetailContext } from '@icoach/members/memberDetail/MemberDetailContext';

const InvoiceDialogContent = React.forwardRef((props, ref) => {
    const { sourceData, resource, errorModule } = props;
    const initInvoice = {
        issuingType: 0,
        carrierType: 0,
        carrierNo: '',
        taxTitle: '',
        taxID: '',
        donationCode: '',
        invoiceNotes: '',
    };
    const invoiceRef = useRef(null);

    useImperativeHandle(
        ref,
        () => ({
            getResult: () => {
                let result = {
                    ...initInvoice,
                };

                if (invoiceRef.current && invoiceRef.current.getResult) {
                    const targetValue = invoiceRef.current.getResult();
                    const { invoiceIssuingType, invoiceCarrierType, invoiceCarrierNo, invoiceTitle, invoiceTaxID, invoiceLoveCode, invoiceNotes } = targetValue;

                    Object.assign(result, {
                        carrierType: invoiceCarrierType,
                        issuingType: invoiceIssuingType,
                        carrierNo: invoiceCarrierNo,
                        taxTitle: invoiceTitle,
                        taxID: invoiceTaxID,
                        donationCode: invoiceLoveCode,
                        invoiceNotes,
                    });
                }

                return result;
            },
            isError: () => refIsRequiredError(invoiceRef),
        }),
        // eslint-disable-next-line
        [],
    );

    return (
        <DialogContent>
            <InvoiceCard
                ref={invoiceRef}
                sourceData={{
                    invoiceIssuingType: sourceData.issuingType,
                    invoiceCarrierType: sourceData.carrierType,
                    invoiceCarrierNo: sourceData.carrierNo,
                    invoiceTitle: sourceData.taxTitle,
                    invoiceTaxID: sourceData.taxID,
                    invoiceLoveCode: sourceData.donationCode,
                    invoiceNotes: sourceData.invoiceNotes,
                }}
                resource={resource}
                errorModule={errorModule}
                showInvoiceNotes
            />
        </DialogContent>
    );
});

const InvoiceDialog = (props) => {
    const { className, open, memberID, data = {}, onClose } = props;
    const { memberInvoiceID, title } = data;
    const { refresh } = useMemberDetailContext();
    const { postMemberProfileInvoicesApi } = useMemberProfileApi();
    const [errorModule, setErrorModule] = useState({});
    const contentRef = useRef(null);

    // 修改發票
    const editMemberProfileInvoicesApi = async (memberID, params) => {
        const resp = await postMemberProfileInvoicesApi(memberID, params);
        if (resp) {
            if (Array.isArray(resp)) {
                let _errorData = {};
                resp.forEach((item) => {
                    if (!Object.hasOwn(_errorData, item.key)) {
                        _errorData[item.key] = item.message;
                    }
                });
                setErrorModule(_errorData);
            } else {
                refresh.current && refresh.current(memberID);
                onClose();
            }
        }
    };

    const getParams = () => {
        if (!contentRef.current) return {};
        return {
            title,
            memberInvoiceID,
            ...contentRef.current.getResult(),
        };
    };

    const handleSubmit = () => {
        let isError = contentRef.current && contentRef.current.isError();
        if (!isError) {
            let params = getParams();

            setErrorModule({});
            editMemberProfileInvoicesApi(memberID, params);
        }
    };

    if (!open) return null;

    return (
        <Dialog
            className={clsx('simple-content', 'dialog', className)}
            PaperProps={{ className: 'wx-65rem', component: Form }}
            onSubmit={handleSubmit}
            open={open}
        >
            <GradientColorHeader onClose={onClose}>修改發票資訊</GradientColorHeader>
            <InvoiceDialogContent
                ref={contentRef}
                sourceData={data}
                resource={{ invoiceCarrierTypeOptions: INVOICE_CARRIER_TYPE_OPTIONS, invoiceIssuingTypeOptions: INVOICE_ISSUING_TYPE_OPTIONS }}
                errorModule={errorModule}
            />
            <DialogActions>
                <Button type={'submit'} variant="contained">
                    確認送出
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default InvoiceDialog;
