// redux
import { combineReducers } from 'redux';
// types
import { SET_VERSION_DATA } from './types';

export function VersionData(state = { Data: {} }, action) {
    switch (action.type) {
        case SET_VERSION_DATA:
            if (action.Data === null) {
                action.Data = {};
            }
            return { Data: action.Data };

        default:
            return state;
    }
}

const Reducer = combineReducers({
    VersionData,
});

export default Reducer;
