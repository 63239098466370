import { useCallback } from 'react';
import useAjax from '@apis/useAjax';
import { useSnackbar } from 'notistack';

const GET_CUSTOMER_MANUFACTURE_OPTIONS_API = `/api/customer-manufacture/options/`;
const POST_CUSTOMER_MANUFACTURE_OVERVIEWS_API = `/api/customer-manufacture/overviews/`;
const GET_CUSTOMER_MANUFACTURE_CUSTOMERMANUFACTUREID_API = (customerManufactureID) => `/api/customer-manufacture/${customerManufactureID}/`;
const PUT_CUSTOMER_MANUFACTURE_CUSTOMERMANUFACTUREID_API = (customerManufactureID) => `/api/customer-manufacture/${customerManufactureID}/`;
const POST_CUSTOMER_MANUFACTURE_API = `/api/customer-manufacture/`;
const DELETE_CUSTOMER_MANUFACTURE_API = `/api/customer-manufacture/`;

// 主動顧客製造
const useCustomerManufactureApi = () => {
    const Ajax = useAjax();
    const { enqueueSnackbar } = useSnackbar();

    // options
    const getCustomerManufactureOptionsApi = useCallback(
        async () => {
            const resp = await Ajax.get(GET_CUSTOMER_MANUFACTURE_OPTIONS_API);
            if (!resp) enqueueSnackbar('讀取資料失敗,請重新整理', { variant: 'error' });
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    // 總覽列表
    const getCustomerManufactureOverviewApi = useCallback(
        async (parmas) => {
            const resp = await Ajax.post(POST_CUSTOMER_MANUFACTURE_OVERVIEWS_API, parmas);
            if (!resp) {
                enqueueSnackbar('查詢失敗,請重新再操作一次', { variant: 'error' });
            }
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    // 新增主動顧客製造資料
    const postCustomerManufactureApi = useCallback(
        async (params) => {
            const resp = await Ajax.post(POST_CUSTOMER_MANUFACTURE_API, params);
            if (resp) {
                enqueueSnackbar('新增主動顧客製造成功', { variant: 'success' });
            } else {
                enqueueSnackbar('新增失敗，請重新再操作一次', { variant: 'error' });
            }
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    // 個別主動顧客製造資料
    const getCustomerManufactureApi = useCallback(
        async (id) => {
            const resp = await Ajax.get(GET_CUSTOMER_MANUFACTURE_CUSTOMERMANUFACTUREID_API(id));
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    // 編輯主動顧客製造資料
    const putCustomerManufactureApi = useCallback(
        async (params, id) => {
            const resp = await Ajax.put(PUT_CUSTOMER_MANUFACTURE_CUSTOMERMANUFACTUREID_API(id), params);
            if (resp) {
                enqueueSnackbar('編輯主動顧客製造成功', { variant: 'success' });
            } else {
                enqueueSnackbar('編輯失敗，請重新再操作一次', { variant: 'error' });
            }
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    // 刪除主動顧客製造資料
    const deleteCustomerManufactureApi = useCallback(
        async (params) => {
            const resp = await Ajax.delete(DELETE_CUSTOMER_MANUFACTURE_API, params);
            if (resp) {
                enqueueSnackbar('刪除主動顧客製造成功', { variant: 'success' });
            } else {
                enqueueSnackbar('刪除失敗，請重新再操作一次', { variant: 'error' });
            }
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    return {
        getCustomerManufactureOptionsApi,
        getCustomerManufactureOverviewApi,
        postCustomerManufactureApi,
        getCustomerManufactureApi,
        putCustomerManufactureApi,
        deleteCustomerManufactureApi,
    };
};

export default useCustomerManufactureApi;
