import React, { useImperativeHandle, useRef, useMemo } from 'react';
import { TransitionGroup } from 'react-transition-group';
import { useSnackbar } from 'notistack';
import { Grid } from '@mui/material';
import useNonMember from '@icoach/nonMember/components/useNonMember';
import { ErrorCircle as ErrorCircleIcon, Plus as PlusIcon } from '@common/SvgIcon/';
import { Button, TextField, Typography, IconButton, Collapse, SelectField, MenuItem } from '@common/components/';
import { refIsRequiredError } from '@util/utils';

/**
 * 自店社群(2)、既有名單(4)、自店BR(8)、BR名單(11)、CP去電(13)、GP名單(14) 皆帶入一樣 否則 都是自店BR(8)
 */
const friendsOriginRule = ['2', '4', '11', '13', '14'];

// 友人資訊
const GourpFriends = React.forwardRef((props, ref) => {
    const { onRemoveFriendsClick, options = [] } = props;
    const { getOriginType } = useNonMember();
    const nameRef = useRef(null);
    const phoneRef = useRef(null);
    const originRef = useRef(null);

    useImperativeHandle(
        ref,
        () => ({
            isError: () => refIsRequiredError(nameRef, phoneRef, originRef),
            getResult: () => {
                return {
                    name: nameRef.current.getResult(),
                    cellphone: phoneRef.current.getResult(),
                    sourceType: originRef.current.getResult(),
                };
            },
        }),
        // eslint-disable-next-line
        []
    );

    const lockOriginValue = useMemo(
        () => {
            const value = getOriginType();
            return friendsOriginRule.includes(String(value)) ? value : '8';
        },
        // eslint-disable-next-line
        []
    );

    return (
        <Grid container spacing={1}>
            <Grid item xs={3}>
                <TextField label="姓名" ref={nameRef} required />
            </Grid>
            <Grid item xs={4}>
                <TextField label="手機" type="tel" ref={phoneRef} maskType={'MOB'} required />
            </Grid>
            <Grid item xs={4}>
                <SelectField ref={originRef} label={'來源'} fullWidth={false} defaultValue={lockOriginValue} displayEmpty disabled required>
                    <MenuItem disabled value="">
                        <em>請選擇</em>
                    </MenuItem>
                    {options.map(({ text, value, disabled }) => (
                        <MenuItem value={value} key={value} disabled={disabled}>
                            {text}
                        </MenuItem>
                    ))}
                </SelectField>
            </Grid>
            <Grid item xs={1} style={{ margin: '22px 0 0 -20px' }}>
                <IconButton onClick={onRemoveFriendsClick}>
                    <ErrorCircleIcon />
                </IconButton>
            </Grid>
        </Grid>
    );
});

const InviteFriendsBox = React.forwardRef((props, ref) => {
    const { buttonLabel = '友人邀約', options } = props;
    const { getOriginType } = useNonMember();
    const { enqueueSnackbar } = useSnackbar();
    const [isFriends, setFriends] = React.useState(0);
    const [deleteIdx, setDeleteIdx] = React.useState([]);
    const friendsRef = useRef([]);

    const handleAddFriendsClick = (e) => {
        if (getOriginType && getOriginType()) {
            setFriends(isFriends + 1);
        } else {
            enqueueSnackbar('請選擇來源', { variant: 'info' });
        }
    };

    const handleRemoveFriendsClick = (id) => (e) => {
        let newState = [];
        if (id !== 'all') newState = [...deleteIdx, id];
        if (isFriends !== 0 && isFriends === deleteIdx.length + 1) newState = [];
        if (newState.length === 0) {
            setFriends(0);
            friendsRef.current = [];
        }
        setDeleteIdx([...newState]);
    };

    useImperativeHandle(
        ref,
        () => ({
            isNull: () => friendsRef.current.every((x) => x === null),
            isError: () => refIsRequiredError(...Object.values(friendsRef.current)),
            getResult: () => {
                return friendsRef.current.reduce((acc, cur) => {
                    let newAry = [...acc];
                    if (cur) newAry.push(cur.getResult());
                    return newAry;
                }, []);
            },
        }),
        // eslint-disable-next-line
        []
    );

    return (
        <React.Fragment>
            <Grid container spacing={1}>
                {isFriends !== 0 && (
                    <Grid item xs={12}>
                        <Typography variant="h5" display="inlineBlock">
                            友人邀約
                        </Typography>
                        <Button onClick={handleRemoveFriendsClick('all')}>清除全部</Button>
                    </Grid>
                )}
                <TransitionGroup>
                    {Array(isFriends)
                        .fill()
                        .map((x, idx) => {
                            if (deleteIdx.indexOf(idx) !== -1) return null;
                            return (
                                <Collapse key={idx}>
                                    <Grid item xs={12} key={idx}>
                                        <GourpFriends
                                            ref={(el) => (friendsRef.current[idx] = el)}
                                            onRemoveFriendsClick={handleRemoveFriendsClick(idx)}
                                            options={options}
                                        />
                                    </Grid>
                                </Collapse>
                            );
                        })}
                </TransitionGroup>
                <Grid item xs={12}>
                    <Button onClick={handleAddFriendsClick} variant="outlined" startIcon={<PlusIcon />}>
                        {buttonLabel}
                    </Button>
                </Grid>
            </Grid>
        </React.Fragment>
    );
});

export default InviteFriendsBox;
