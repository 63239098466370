import React from 'react';
import clsx from 'clsx';
import { ReadTextField } from '../../components';
import { CounterBox } from '../../members/components';
import { displayKpiDefaultValue } from '../staticData';

// 實際成績與目標比較，是否達標
const isUpToStandard = (score, goal, condition) => {
    switch (condition) {
        case '>':
            return score > goal;
        case '>=':
            return score >= goal;
        case '<':
            return score < goal;
        case '<=':
            return score<= goal;
        case '!==':
            return score !== goal;
        default:
            return score === goal;
    }
}

const CounterBoxByKpi = (props) => {
    const { title, tip, goalTitle='目標', goal = '設定目標', goalAppendText='',goalPrependText='', condition, data = {}, onClick } = props;
    const {targetSettingValue} = data;
    const _data = {
        ...data,
        targetSettingValue: data.targetSettingValue || goal
    }

    const getStateClassName = (data, condition) => {
        const { exactValue, targetValue, isForward } = data;
        let _exactValue = parseFloat(exactValue);
        let _targetValue = parseFloat(targetValue);
        let _condition = condition ? condition : isForward ? '>=': '<';

        if (_targetValue === 0 || isNaN(_targetValue) || isNaN(_exactValue)) {
            return '';
        } else {
            return isUpToStandard(_exactValue, _targetValue, _condition) ? 'is-valid' : 'is-invalid';
        }
    };

    return (
        <CounterBox
            className={clsx('kpi-counter-box', 'text-left', getStateClassName(_data, condition))}
            title={title}
            tip={tip}
            counter={displayKpiDefaultValue(_data, 'actualData', true)}
        >
            <ReadTextField className={'cursor-pointer'} textProps={{ className: clsx({'font-color-4': !Boolean(targetSettingValue)}) }} label={goalTitle} onClick={onClick}>
                {`${goalPrependText}${displayKpiDefaultValue(_data, 'targetSettingValue', true)}${goalAppendText}`}
            </ReadTextField>
        </CounterBox>
    );
};

export default CounterBoxByKpi;
