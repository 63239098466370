import React, { createContext, useContext } from 'react';

const MembershipContext = createContext(undefined);

export const MembershipProvider = ({ children, value }) => {
    return (
        <MembershipContext.Provider value={value}>
            {children}
        </MembershipContext.Provider>
    );
};

export const useMembershipContext = () => {
    const context = useContext(MembershipContext);
    if (context === undefined) {
        throw new Error(
            'useMembershipContext 必須在 MembershipProvider 中使用'
        );
    }
    return context;
};
