import { AppointmentInformationPage } from '@icoach/appointment/';
import { APPOINTMENT_APPOINTMENTID_INFORMATIO_ROUTER } from '@icoach/router/routerPath';

const appointmentRouter = [
    {
        description: '預約單明細頁',
        strict: true,
        path: APPOINTMENT_APPOINTMENTID_INFORMATIO_ROUTER,
        as: AppointmentInformationPage,
        frontKey: 'C7X02',
    },
];

export default appointmentRouter;
