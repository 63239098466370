import React, { useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import { useHistory } from 'react-router-dom';
import { Badge } from '@mui/material';
import { ROOT_NOTIFICATIONS_ROUTER } from '@icoach/router/routerPath';
import { IconButton } from '@common/components/';
import { BellSolid as BellSolidIcon } from '@common/SvgIcon/';
import useNotificationApi from '@apis/useNotificationApi';

const NotificationBellBox = () => {
    const { getNotificationCountApi } = useNotificationApi();
    const history = useHistory();
    const [count, setCount] = useState(0);

    const doNotificationCount = async () => {
        const resp = await getNotificationCountApi();
        setCount(resp);
    };

    const handleNotifyOnClick = () => {
        ReactGA.event({ category: '首頁鈴鐺通知', action: '點擊首頁鈴鐺通知', label: '鈴鐺' });
        history.push(ROOT_NOTIFICATIONS_ROUTER);
    };

    useEffect(
        () => {
            doNotificationCount();
        },
        // eslint-disable-next-line
        []
    );

    return (
        <IconButton onClick={handleNotifyOnClick}>
            <Badge badgeContent={count} color="error">
                <BellSolidIcon />
            </Badge>
        </IconButton>
    );
};

export default NotificationBellBox;
