import React from 'react';
import { v4 as uuid } from 'uuid';
import clsx from 'clsx';
import _ from 'lodash';
import InbBodtyXFactor from '@icoach/inbody/components/InBodyXFactor';
import InBodyAgreedNotes from '@icoach/inbody/components/InBodyAgreedNotes';
import InBodyInterviewNote from '@icoach/inbody/components/InBodyInterviewNote';
import InBodyDeleteMeasure from '@icoach/inbody/components/InBodyDeleteMeasure';
import InBodyInterviewMeasureTranscriptDialog from '@icoach/inbody/interview/InBodyInterviewMeasureTranscriptDialog';
import InBodyInterviewPdfDialog from '@icoach/inbody/components/InBodyInterviewPdfDialog';
import { parseDate } from '@util/moment';
import { accSub } from '@util/math';
import { Typography, SelectField, MenuItem, Box } from '@common/components/';
import { DateTimeStandard } from '../../../components/pickers/enums/DateTimeStandard';
import positiveImg from '../../assets/img/positive-vote.png';

// 量身紀錄項目 身體總水重 骨骼肌重 基礎代謝 InBody評分 這四項為反指標
export const inBodyInterviewMeasureItems = [
    {
        value: '目標',
        key: 'measureDate',
        uuid: uuid(),
        rowClass: clsx('header', 'title'),
        formatCell: (value) => parseDate(value, DateTimeStandard.DisplayDateBySlash),
        formatCompare: () => '比較',
        formatMutipleCell: (value) => parseDate(value, DateTimeStandard.DisplayDateBySlash),
    },
    {
        value: 'X-factor',
        uuid: uuid(),
        key: 'xFactorNotes',
        formatCell: (sourceData, { measureID }) => {
            return <InbBodtyXFactor sourceData={sourceData} measureID={measureID} />;
        },
    },
    {
        value: '約定事項',
        uuid: uuid(),
        key: 'agreedNotes',
        formatCell: (sourceData, { measureID }) => {
            return <InBodyAgreedNotes sourceData={sourceData} measureID={measureID} />;
        },
    },
    { value: 'WO頻率', uuid: uuid(), key: 'monthlyWorkoutFrequency' },
    { value: '上個月WO總數', uuid: uuid(), key: 'lastMonthWorkoutQuantity' },
    {
        value: '身體組成分析',
        key: 'title-1',
        uuid: uuid(),
        rowClass: clsx('title'),
        formatCell: () => '',
        formatCompare: () => '',
        formatMutipleCell: () => '',
    },
    {
        value: 'InBody評分',
        uuid: uuid(),
        key: 'inbodyScore',
        formatCompare: (target) => <MeasureCompareNumericBox {...target} isInverted />,
    },
    {
        value: '骨骼肌重 KG',
        uuid: uuid(),
        key: 'smm',
        formatCompare: (target) => <MeasureCompareNumericBox {...target} isInverted />,
    },
    {
        value: '蛋白質重 KG',
        uuid: uuid(),
        key: 'protein',
        formatCompare: (target) => <MeasureCompareNumericBox {...target} isInverted />,
    },
    {
        value: '體脂肪率 %',
        uuid: uuid(),
        key: 'pbf',
        formatCompare: (target) => <MeasureCompareNumericBox {...target} />,
    },
    {
        value: '體脂肪重 KG',
        uuid: uuid(),
        key: 'bfm',
        formatCompare: (target) => <MeasureCompareNumericBox {...target} />,
    },
    {
        value: '體重 KG',
        uuid: uuid(),
        key: 'weight',
        formatCompare: (target) => <MeasureCompareNumericBox {...target} />,
    },
    {
        value: '身體總水重 L',
        uuid: uuid(),
        key: 'tbw',
        formatCompare: (target) => <MeasureCompareNumericBox {...target} isInverted />,
    },
    {
        value: '部分別圍度',
        key: 'title-2',
        uuid: uuid(),
        rowClass: clsx('title'),
        formatCell: () => '',
        formatCompare: () => '',
        formatMutipleCell: () => '',
    },
    {
        value: '總體圍 CM',
        uuid: uuid(),
        key: 'ac',
        formatCompare: (target) => <MeasureCompareNumericBox {...target} />,
    },
    {
        value: '頸部 CM',
        uuid: uuid(),
        key: 'neckMC',
        formatCompare: (target) => <MeasureCompareNumericBox {...target} />,
    },
    {
        value: '胸圍 CM',
        uuid: uuid(),
        key: 'chestMC',
        formatCompare: (target) => <MeasureCompareNumericBox {...target} />,
    },
    {
        value: '腹圍 CM',
        uuid: uuid(),
        key: 'abdomenMC',
        formatCompare: (target) => <MeasureCompareNumericBox {...target} />,
    },
    {
        value: '臀圍 CM',
        uuid: uuid(),
        key: 'hipMC',
        formatCompare: (target) => <MeasureCompareNumericBox {...target} />,
    },
    {
        value: '右臂/左臂 CM',
        uuid: uuid(),
        key: ['rightArmMC', 'leftArmMC'],
        formatCell: (v, rowData, { key }) => key.map((target) => rowData[target]).join(' / '),
        formatCompare: (target) => <MeasureCompareNumericBox {...target} />,
    },
    {
        value: '右大腿/左大腿 CM',
        uuid: uuid(),
        key: ['rightThighMC', 'leftThighMC'],
        formatCell: (v, rowData, { key }) => key.map((target) => rowData[target]).join(' / '),
        formatCompare: (target) => <MeasureCompareNumericBox {...target} />,
    },
    {
        value: '研究參數',
        key: 'title-3',
        uuid: uuid(),
        rowClass: clsx('title'),
        formatCell: () => '',
        formatCompare: () => '',
        formatMutipleCell: () => '',
    },
    {
        value: '內臟脂肪',
        uuid: uuid(),
        key: 'vfl',
        formatCompare: (target) => <MeasureCompareNumericBox {...target} />,
    },
    {
        value: '腰臀圍比',
        uuid: uuid(),
        key: 'whr',
        formatCompare: (target) => <MeasureCompareNumericBox {...target} />,
    },
    {
        value: '肌肉質量指數SMI',
        uuid: uuid(),
        key: 'smi',
        formatCompare: (target) => <MeasureCompareNumericBox {...target} isInverted />,
    },

    {
        value: '骨礦含量',
        uuid: uuid(),
        key: 'bmc',
        formatCompare: (target) => <MeasureCompareNumericBox {...target} isInverted />,
    },
    {
        value: '基礎代謝率',
        uuid: uuid(),
        key: 'bmr',
        formatCompare: (target) => <MeasureCompareNumericBox {...target} isInverted />,
    },
    {
        value: '身體平衡評估',
        key: 'title-4',
        uuid: uuid(),
        rowClass: clsx('title'),
        formatCell: () => '',
        formatCompare: () => '',
        formatMutipleCell: () => '',
    },
    {
        value: '上半身',
        uuid: uuid(),
        key: 'ubbEvalText',
    },
    {
        value: '下半身',
        uuid: uuid(),
        key: 'lbbEvalText',
    },
    {
        value: '上-下半身',
        uuid: uuid(),
        key: 'ulbbEvalText',
    },
    {
        value: 'InBody 套表',
        uuid: uuid(),
        key: 'pathUrl',
        rowClass: 'inbody-chart',
        formatCell: (value) => {
            return <InBodyInterviewPdfDialog sourceData={value} />;
        },
    },
    {
        value: '個人成績單',
        uuid: uuid(),
        rowClass: 'bg-measure-gray',
        key: 'isShowTranscript',
        formatCell: (value, { measureID, measureDate, referenceID, isShowQrcode }) => {
            return <InBodyInterviewMeasureTranscriptDialog isShow={value} sourceData={{ measureID, memberID: referenceID, measureDate, isShowQrcode }} />;
        },
    },
    { value: '量身擔當', uuid: uuid(), key: 'employeeName' },
    {
        value: '',
        uuid: uuid(),
        key: 'measureID',
        formatCell: (measureID, rowData) => {
            return <InBodyDeleteMeasure measureID={measureID} sourceData={rowData} />;
        },
    },
];

// 量身面談項目
export const inBodyInterviewNotesItems = [
    {
        value: '項目',
        uuid: uuid(),
        key: 'measureDate',
        rowClass: clsx('bg-measure-gray', 'header'),
        formatCell: (value) => {
            return (
                <Typography component={'div'} className="interview-notes">
                    {parseDate(value)}
                </Typography>
            );
        },
    },
    {
        value: '面談擔當',
        uuid: uuid(),
        key: 'interviewEmployeeName',
        formatCell: (value, { measureID, interviewContent, interviewEmployeeID }) => {
            return <Typography component="div">{`${value || '尚未設定'}`}</Typography>;
        },
    },
    {
        value: '面談內容',
        uuid: uuid(),
        key: 'interviewContent',
        formatCell: (value, rowData) => {
            return <InBodyInterviewNote value={value} sourceData={rowData} measureID={rowData.measureID} />;
        },
    },
    {
        value: '',
        uuid: uuid(),
        key: 'measureID',
        formatCell: (measureID, rowData) => {
            return <InBodyDeleteMeasure measureID={measureID} sourceData={rowData} />;
        },
    },
];

// 處理cell顯示是兩個value值 ex: xx / xx
export const MeasureCompareNumericBox = (props) => {
    const { datumDataValue = 0, compareDataValue = 0, isInverted = false, itemKey } = props;
    const viewValue = accSub(datumDataValue, compareDataValue);
    if (typeof itemKey !== 'string') {
        const comp = itemKey.map((target) => {
            if (target === '/') return target;
            return (
                <MeasureCompareNumericBox key={target} datumDataValue={props.datumData[target]} compareDataValue={props.compareData[target]} itemKey={target} />
            );
        });

        return [comp[0], ' / ', comp[1]];
    }

    let className = viewValue > 0 ? 'text-primary' : '';

    if (!isInverted) {
        className = viewValue < 0 ? 'text-primary' : '';
    }

    return (
        <React.Fragment>
            <span className={className}>
                {viewValue > 0 && <span>↑</span>}
                {viewValue < 0 && <span>↓</span>}
                {parseFloat(viewValue) === 0 ? '-' : Math.abs(viewValue)}
            </span>
            {className === 'text-primary' && <img className={'positive-img'} src={positiveImg} alt="好棒" />}
        </React.Fragment>
    );
};

// 量測資訊比較外框元件
export const InBodyInterviewMeasureCompareBox = (props) => {
    const {
        itemKey,
        formatCell,
        formatCompare,
        formatMutipleCell,
        datumData = [],
        compareData = [],
        styles = {},
        selectedItem,
        setSelectedItem,
        measureDateOptions = [],
    } = props;

    const handleDateOnChange = (e, n, value) => {
        setSelectedItem(value);
    };

    return (
        <React.Fragment>
            <Box className={clsx('item', 'text-center')} style={{ width: '30%', ...styles }}>
                {formatCell ? formatCell(datumData[itemKey], datumData, { key: itemKey }) : datumData[itemKey]}
            </Box>
            <Box className={clsx('item', 'text-center')} style={{ width: '220px', ...styles }}>
                {formatCompare
                    ? formatCompare({
                          datumDataValue: datumData[itemKey],
                          compareDataValue: compareData[itemKey],
                          itemKey: itemKey,
                          datumData: datumData,
                          compareData: compareData,
                      })
                    : ''}
            </Box>
            <Box className={clsx('item', 'text-center', 'right-divider')} style={{ width: '30%', ...styles }}>
                {itemKey === 'measureDate' && !_.isEmpty(measureDateOptions) ? (
                    <SelectField defaultValue={selectedItem} onChange={handleDateOnChange}>
                        {measureDateOptions.map(({ text, value }) => {
                            const dateString = parseDate(text);
                            return (
                                <MenuItem key={value} value={value}>
                                    {dateString}
                                </MenuItem>
                            );
                        })}
                    </SelectField>
                ) : formatMutipleCell ? (
                    formatMutipleCell(compareData[itemKey], compareData, { key: itemKey })
                ) : formatCell ? (
                    formatCell(compareData[itemKey], compareData, { key: itemKey })
                ) : (
                    compareData[itemKey]
                )}
            </Box>
        </React.Fragment>
    );
};

// 顯示所有量測資訊
const InBodyInterviewMeasureNormalBox = (props) => {
    const { formatCell, sourceData = [], itemKey, styles, className } = props;

    return (
        <React.Fragment>
            {sourceData.map((targetData, columnIndex) => {
                const { [itemKey]: cellVale } = targetData;
                return (
                    <Box className={clsx('item', 'text-center', className)} key={`${itemKey}_${columnIndex}`} style={{ width: '260px', ...styles }}>
                        {formatCell ? formatCell(cellVale, targetData, { key: itemKey }) : cellVale}
                    </Box>
                );
            })}
        </React.Fragment>
    );
};

export default InBodyInterviewMeasureNormalBox;
