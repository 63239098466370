import React, { useCallback, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { Grid } from '@mui/material';
import { Box, DateField, TextField, Button } from '@common/components/';
import { AddressField } from '@icoach/documents/components';
import { ReadTextField, PrivateInformationBox } from '@icoach/components';
import { useMemberDetailContext } from '@icoach/members/memberDetail/MemberDetailContext';
import { makeField } from '@icoach/members/memberDetail/tools';
import { useMemberProfileApi } from '@apis/useMemberProfileApi';
import { refIsRequiredError } from '@util/utils';
import { initialDate, displayDateText } from '@util/moment';
import useMemberCyclePurchaseApi from '@apis/useMemberCyclePurchaseApi';
import { CycleOrderTipDialog } from '@icoach/members/memberDetail/dialog';
import { useSnackbar } from 'notistack';
import { checkResponse } from '@apis/utils';

const MemberDetailBasicInfoContentInfo = (props) => {
    const { className, memberID, isEdit, toggleEditBasicInfoForm } = props;
    const { enqueueSnackbar } = useSnackbar();
    const { getMemberProfileBasicApi, postMemberProfileBasicApi } = useMemberProfileApi();
    const { getMemberCycleOrderCheckValidApi } = useMemberCyclePurchaseApi();
    const { doMemberProfileApi } = useMemberDetailContext();
    const [sourceData, setSourceData] = useState({});
    const [errorData, setErrorData] = useState({});
    const [openDialog, setOpenDialog] = useState(false);
    const fieldRef = useRef({});

    const getParams = () => {
        let fieldObj = fieldRef.current;
        let result = { memberID };
        for (let key in fieldObj) {
            const field = fieldObj[key];
            if (field && field.hasOwnProperty('getResult')) {
                if (key === 'address') {
                    result = {
                        ...result,
                        ...field.getResult(),
                    };
                } else {
                    result[key] = field.getResult();
                }
            }
        }
        return result;
    };

    const checkError = () => !refIsRequiredError(...Object.values(fieldRef.current));
    const handleOpenDialog = () => setOpenDialog(true);
    const handleCloseDialog = () => setOpenDialog(false);
    const handleSaveInfo = () => {
        let isError = checkError();
        if (isError) {
            const params = getParams();
            doPostMemberProfileBasicApi(memberID, params);
        }
    };

    // todo 檢查是否需要提示循環訂單的訊息
    const validateFieldByCycleOrder = async (original, params, memberID) => {
        const isValid = await getMemberCycleOrderCheckValidApi(memberID);
        if (!isValid) return false;
        let isShowTip = false;
        let isMemberNameDifferent = original.memberName !== params.memberName;
        let isCellPhoneDifferent = original.cellPhone !== params.cellPhone;
        let isAddressDifferent = original.address !== params.address || original.postCode !== params.postCode;
        if (isMemberNameDifferent || isCellPhoneDifferent || isAddressDifferent) {
            isShowTip = true;
        }
        if (isShowTip) handleOpenDialog();
    };

    const doGetMemberProfileBasicApi = async (memberID) => {
        let res = await getMemberProfileBasicApi(memberID);
        res && setSourceData(res);
    };

    const doPostMemberProfileBasicApi = async (memberID, params) => {
        const handleResponseError = (successData, respondBody) => {
            let result = respondBody.data.result;
            if (!checkResponse(successData)) {
                let _errorData = {};
                Array.isArray(result) &&
                    result.forEach((item) => {
                        if (!Object.hasOwn(_errorData, item.key)) {
                            _errorData[item.key] = item.message;
                        }
                    });
                setErrorData(_errorData);
            } else {
                enqueueSnackbar('已更新', { variant: 'success' });
                validateFieldByCycleOrder(sourceData, params, memberID);
                doMemberProfileApi && doMemberProfileApi(memberID);
                doGetMemberProfileBasicApi(memberID);
                toggleEditBasicInfoForm(false);
            }
        };
        await postMemberProfileBasicApi(memberID, params, handleResponseError);
    };

    const getFieldData = useCallback(
        () => {
            const {
                memberNo,
                memberName,
                nickName,
                cellPhone,
                tel,
                companyTel,
                memberCardNo,
                height,
                birthday,
                mail,
                address,
                postCode,
                contactName,
                contactPhone,
                contactship,
                identifierID,
            } = sourceData;
            const {
                memberName: memberNameError,
                nickName: nickNameError,
                cellPhone: cellPhoneError,
                tel: telError,
                companyTel: companyTelError,
                memberCardNo: memberCardNoError,
                height: heightError,
                birthday: birthdayError,
                mail: mailError,
                address: addressError,
                postCode: postCodeError,
                contactName: contactNameError,
                contactPhone: contactPhoneError,
                contactship: contactshipError,
                identifierID: identifierIDError,
            } = errorData;
            const targetData = [
                makeField('memberNo', '會員編號', memberNo, <TextField defaultValue={memberNo} disabled fullWidth />),
                makeField(
                    'memberName',
                    '會員姓名',
                    memberName,
                    <TextField defaultValue={memberName} isError={Boolean(memberNameError)} helperText={memberNameError} fullWidth required />
                ),
                makeField(
                    'nickName',
                    '暱稱',
                    nickName,
                    <TextField defaultValue={nickName} isError={Boolean(nickNameError)} helperText={nickNameError} fullWidth />
                ),
                makeField(
                    'cellPhone',
                    '手機號碼',
                    cellPhone,
                    <TextField defaultValue={cellPhone} isError={Boolean(cellPhoneError)} helperText={cellPhoneError} maskType={'MOB'} fullWidth required />
                ),
                makeField('tel', '電話號碼', tel, <TextField defaultValue={tel} isError={Boolean(telError)} helperText={telError} fullWidth />),
                makeField(
                    'companyTel',
                    '公司電話',
                    companyTel,
                    <TextField defaultValue={companyTel} isError={Boolean(companyTelError)} helperText={companyTelError} fullWidth />
                ),
                makeField(
                    'birthday',
                    '生日',
                    displayDateText(birthday),
                    <DateField defaultValue={initialDate(birthday)} isError={Boolean(birthdayError)} helperText={birthdayError} required />
                ),
                makeField(
                    'height',
                    '身高',
                    height,
                    <TextField
                        defaultValue={height}
                        isError={Boolean(heightError)}
                        helperText={heightError}
                        type={'number'}
                        inputProps={{ min: 0 }}
                        fullWidth
                    />
                ),
                makeField(
                    'identifierID',
                    '身分證/護照號碼',
                    identifierID,
                    <PrivateInformationBox
                        defaultValue={identifierID}
                        as={TextField}
                        isError={Boolean(identifierIDError)}
                        helperText={identifierIDError}
                        fullWidth
                    />
                ),
                makeField(
                    'memberCardNo',
                    '簽到卡號',
                    memberCardNo,
                    <TextField defaultValue={memberCardNo} isError={Boolean(memberCardNoError)} helperText={memberCardNoError} fullWidth />
                ),
                makeField(
                    'mail',
                    'Email',
                    mail,
                    <TextField
                        defaultValue={mail}
                        isError={Boolean(mailError)}
                        helperText={mailError}
                        inputProps={{
                            placeholder: '建議填寫，以便收取重要權益異動、發票、優惠等相關訊息',
                        }}
                        fullWidth
                    />,
                    {
                        xs: 8,
                    }
                ),
                makeField(
                    'address',
                    '通訊地址',
                    `${postCode} ${address}`,
                    <AddressField
                        address={address}
                        postCode={postCode}
                        isError={Boolean(addressError) || Boolean(postCodeError)}
                        helperText={addressError || postCodeError}
                    />,
                    {
                        xs: 12,
                    }
                ),
                makeField(
                    'contactName',
                    '緊急聯絡人',
                    contactName,
                    <TextField defaultValue={contactName} isError={Boolean(contactNameError)} helperText={contactNameError} fullWidth required />
                ),
                makeField(
                    'contactPhone',
                    '緊急聯絡人電話',
                    contactPhone,
                    <TextField defaultValue={contactPhone} isError={Boolean(contactPhoneError)} helperText={contactPhoneError} fullWidth required />
                ),
                makeField(
                    'contactship',
                    '緊急聯絡人關係',
                    contactship,
                    <TextField defaultValue={contactship} isError={Boolean(contactshipError)} helperText={contactshipError} fullWidth required />
                ),
            ];

            return targetData;
        },
        // eslint-disable-next-line
        [sourceData, errorData]
    );

    useEffect(
        () => {
            if (memberID) {
                doGetMemberProfileBasicApi(memberID);
            }
        },
        // eslint-disable-next-line
        [memberID]
    );

    const fieldData = getFieldData();
    return (
        <Box className={clsx('mb-4', className)}>
            <Grid container spacing={3}>
                {Array.isArray(fieldData) &&
                    fieldData.map((item) => {
                        return (
                            <Grid key={item.id} item {...item.gridItemSize}>
                                {isEdit ? (
                                    React.isValidElement(item.component) &&
                                    React.cloneElement(item.component, {
                                        label: item.label,
                                        ref: (el) => (fieldRef.current[item.id] = el),
                                    })
                                ) : (
                                    <ReadTextField label={item.label}>{item.value}</ReadTextField>
                                )}
                            </Grid>
                        );
                    })}
                {isEdit && (
                    <Grid item xs={12} className="text-right">
                        <Button variant="contained" onClick={handleSaveInfo}>
                            更新資料
                        </Button>
                    </Grid>
                )}
            </Grid>
            <CycleOrderTipDialog open={openDialog} memberID={memberID} onClose={handleCloseDialog} />
        </Box>
    );
};

export default MemberDetailBasicInfoContentInfo;
