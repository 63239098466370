import React from 'react';
import SwitchBaseIcon from '../internal/SwitchBase';
import clsx from 'clsx';

const staticClass = `switch`;
const switchBaseClass = `${staticClass}-base`;
const switchTrackClass = `${staticClass}-track`;
const switchThumbClass = `${staticClass}-thumb`;

const edgeMap = ['start', 'end'];
const colorMap = ['primary', 'secondary', 'success', 'error', 'warning', 'add'];

const SwitchTrack = (props) => {
    const { color } = props;
    let classNames = switchTrackClass;
    if (colorMap.indexOf(color) !== -1) classNames = clsx(classNames, `${switchTrackClass}-color-${color}`);
    return <span className={classNames} />;
};

const SwitchThumb = () => {
    return <span className={switchThumbClass} />;
};

const Switch = React.forwardRef((props, ref) => {
    const { className, color = 'primary', edge = false, size = 'medium', ...other } = props;
    let classNames = staticClass;
    let switchBaseNames = switchBaseClass;

    if (edge && edgeMap.indexOf(edge) !== -1) classNames = classNames = clsx(classNames, `${staticClass}-edge-${edge}`);
    if (size === 'small') classNames = classNames = clsx(classNames, `${staticClass}-size-${size}`);

    if (other.disabled) switchBaseNames = clsx(switchBaseNames, `${switchBaseClass}-disabled`);
    if (colorMap.indexOf(color) !== -1) switchBaseNames = clsx(switchBaseNames, `${switchBaseClass}-color-${color}`);

    const handleCheckedFn = (cls, chk) => {
        return chk ? clsx(cls, `${switchBaseClass}-checked`) : cls;
    };

    const icon = <SwitchThumb />;
    const defaultProperty = {
        className: clsx(classNames, className),
        ref,
    };
    return (
        <span {...defaultProperty}>
            <SwitchBaseIcon
                type="checkbox"
                className={switchBaseNames}
                handleCheckedFn={handleCheckedFn}
                icon={icon}
                checkedIcon={icon}
                {...props}
                edge={false}
            />
            <SwitchTrack color={color} />
        </span>
    );
});

export default Switch;
