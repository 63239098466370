export default [
    '循環訂單:指訂單成立後，每月定期信用卡扣款及配送，若未辦理暫停/退訂者，仍進行扣款及配送之服務。 ',
    '若您已是循環訂戶，本訂單內容將與之前相同出貨週期、相同收件資訊之訂單，合併同箱出貨。',
    '每月出貨日前2-3個工作日扣款，如持卡人非訂購人，請於下方註明關係並簽名；持卡人同意依照信用卡使用規定，應按所示之金額，付款予發卡銀行。',
    '欲變更訂單資料(手機號碼、收件地址、信用卡資訊)及更換口味、盒數，須於出貨日前5天向店舖提出變更申請：「10號出貨，最晚須於當月5號前提出」，「20號出貨，最晚須於當月15號前提出」，若超過前述日期則於次月生效。',
    '首次配送之商品享有7日鑑賞期，如逾期未退回，則視為交貨完整，恕無法退換貨；非瑕疵商品拆除封膜後不接受退換貨。',
    '扣款後3個工作日內，將開立電子發票至您的電子信箱或手機簡訊或載具；開立三聯式發票者，若後續申請退貨時，需填寫【四聯折讓單】、用印公司發票章，將正本寄回本公司後做退款申請處理。',
    '本公司將不定期傳送優惠活動及相關服務資訊至您所留存的Email/手機，如需修改Email/手機，請主動告知以保障您的權利。',
    '以傳真或Email方式訂購產品時，您同意所填之影本、傳真、電子檔案內容同具法律效用。',
    '本公司得保留接受訂單與否之權利。',
];
