import React from 'react';
import clsx from 'clsx';
import { Box, Typography } from '@common/components/';

const ReadTextField = (props) => {
    const { className, label, children, textProps = {}, labelProps = {}, onClick } = props;
    return (
        <Box className={clsx('read-text-field', className)} onClick={onClick}>
            <Typography {...labelProps} className={clsx('read-text-field-label', labelProps.className)}>
                {label}
            </Typography>
            <Typography {...textProps} className={clsx('read-text-field-text', textProps.className)}>
                {children}
            </Typography>
        </Box>
    );
};

export default ReadTextField;
