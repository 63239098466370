import React, { useState, useRef } from 'react';
import clsx from 'clsx';
import AppointmentOverviewSearchBar from './AppointmentOverviewSearchBar';
import AppointmentOverviewTable from './AppointmentOverviewTable';
import AppointmentContext from '../components/AppointmentContext';
import { initBookmark } from '../staticData';
import { Card } from '@common/components/';

const AppointmentOverview = React.forwardRef((props, ref) => {
    const [sourceData, setSourceData] = useState({});
    const [bookmark, setBookmark] = useState({
        ...initBookmark(),
    });
    const dialogRef = useRef(null);

    const childContext = {
        dialogRef: dialogRef,
        setDialogRef: (el) => (dialogRef.current = el),
        sourceData,
        setSourceData,
        // refresh: () => refreshRef.current && refreshRef.current(),
        // setRefresh: (el) => (refreshRef.current = el),
        bookmark: bookmark,
        setBookmarkNum: (num) => setBookmark((pre) => ({ ...pre, pageIndex: num })),
    };

    return (
        <AppointmentContext.Provider value={childContext}>
            <Card className={clsx(`appointment-time-list-context`, 'p-3')} square isOverflowHidden>
                <AppointmentOverviewSearchBar />
                <Card.CardContent className={'pt-2'}>
                    <AppointmentOverviewTable />
                </Card.CardContent>
            </Card>
        </AppointmentContext.Provider>
    );
});

export default AppointmentOverview;
