import React from 'react';
import { InputAdornment, TextField } from '../../../components';
import { displayKpiDefaultValue } from '../staticData';
import { FieldRow } from '../../documents/components/Layout';
import { TitleByTooltip } from '../../members/components/CounterBox';
import clsx from 'clsx';

const KpiTextField = React.forwardRef((props, ref) => {
    const { className, data, labelData, items } = props;
    const { title, tip, goal } = labelData;
    const { isRate } = data;
    let InputProps = {},
        inputProps = {
            step: '0.01',
            placeholder: '設定目標',
        };
    if (isRate) {
        InputProps.endAdornment = <InputAdornment position={'end'}>%</InputAdornment>;
    }
    if (goal) {
        inputProps.placeholder = goal;
    }
    return (
        <FieldRow
            className={clsx('kpi-text-filed', className)}
            label={<TitleByTooltip title={title} tip={tip} />}
            items={items}
        >
            <TextField
                ref={ref}
                type={'number'}
                key={displayKpiDefaultValue(data)}
                defaultValue={displayKpiDefaultValue(data)}
                inputProps={inputProps}
                InputProps={InputProps}
                fullWidth
            />
        </FieldRow>
    );
});

export default KpiTextField;
