import { useCallback } from 'react';
import useAjax from '@apis/useAjax';
import { useSnackbar } from 'notistack';

const GET_CUSTOMERS_CONTACT_RECORDS_OPTIONS_API = '/api/customers/contact-records/options/';
const GET_CUSTOMERS_CUSTOMERID_CONTACT_RECORDS_API = (customerID) => `/api/customers/${customerID}/contact-records/`;
const POST_CUSTOMERS_CUSTOMERID_CONTACT_RECORDS_API = GET_CUSTOMERS_CUSTOMERID_CONTACT_RECORDS_API;
const PUT_CUSTOMERS_CUSTOMERSID_CONTACT_RECORDS_CONTACTID_API = (customerID, contactID) => `/api/customers/${customerID}/contact-records/${contactID}/`;
const DELETE_CUSTOMERS_CUSTOMERSID_CONTACT_RECORDS_API = PUT_CUSTOMERS_CUSTOMERSID_CONTACT_RECORDS_CONTACTID_API;

const useCustomerContactRecordApi = () => {
    const { enqueueSnackbar } = useSnackbar();
    const Ajax = useAjax();

    // 初始畫面所需options
    const getCustomerContactRecordOptionsApi = useCallback(
        async () => {
            const optionsResp = await Ajax.get(GET_CUSTOMERS_CONTACT_RECORDS_OPTIONS_API);
            if (!optionsResp) enqueueSnackbar('初始資料有誤，請重新整理', { variant: 'error' });
            return optionsResp;
        },
        // eslint-disable-next-line
        []
    );

    // 聯繫紀錄
    const getCustomerContactRecordDataApi = useCallback(
        async (customerID) => {
            const resp = await Ajax.get(GET_CUSTOMERS_CUSTOMERID_CONTACT_RECORDS_API(customerID));
            if (!resp) enqueueSnackbar('讀取聯繫紀錄有誤，請重新整理', { variant: 'error' });
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    // 建立聯繫紀錄
    const postCustomerContactRecordDataApi = useCallback(
        async (params, customerID) => {
            const resp = await Ajax.post(POST_CUSTOMERS_CUSTOMERID_CONTACT_RECORDS_API(customerID), params);
            if (!resp) enqueueSnackbar('新增聯繫紀錄有誤，請再操作一次', { variant: 'error' });
            else enqueueSnackbar('成功新增聯繫', { variant: 'success' });
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    // 編輯聯繫紀錄
    const putCustomerContactRecordDataApi = useCallback(
        async (params, customerID, contactID) => {
            const resp = await Ajax.put(PUT_CUSTOMERS_CUSTOMERSID_CONTACT_RECORDS_CONTACTID_API(customerID, contactID), params);
            if (!resp) enqueueSnackbar('編輯聯繫紀錄有誤，請再操作一次', { variant: 'error' });
            else enqueueSnackbar('成功編輯聯繫', { variant: 'success' });
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    // 刪除聯繫紀錄
    const deleteCustomerContactRecordDataApi = useCallback(
        async (customerID, contactID) => {
            const resp = await Ajax.delete(DELETE_CUSTOMERS_CUSTOMERSID_CONTACT_RECORDS_API(customerID, contactID));
            if (!resp) enqueueSnackbar('刪除聯繫紀錄有誤，請再操作一次', { variant: 'error' });
            else enqueueSnackbar('成功刪除聯繫', { variant: 'success' });
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    return {
        getCustomerContactRecordOptionsApi,
        getCustomerContactRecordDataApi,
        postCustomerContactRecordDataApi,
        putCustomerContactRecordDataApi,
        deleteCustomerContactRecordDataApi,
    };
};

export { useCustomerContactRecordApi };
