import React, { useState } from 'react';
import clsx from 'clsx';
import { EditRecordDialog, RemoveContractRecordDialog } from '@icoach/nonMember/dialog';
import { Card, Divider, IconButton, Stack, Typography } from '@common/components/';
import { Edit as EditIcon, Trash as TrashIcon } from '@common/SvgIcon/';
import { parseDate } from '@util/moment';

const ContactRecordCard = ({
    className,
    title,
    notes,
    contactDate,
    contactResultTypeText,
    coachName,
    contactID,
    customerID,
    openDeleteDialog: openDeleteDialogFn,
    openEditDialog: openEditDialogFn,
    handleEditContact,
    handleDeleteContact,
    isShowTime = false,
}) => {
    const [isOpen, setOpen] = useState(null);
    const format = isShowTime ? 'YYYY/MM/DD HH:mm:ss' : 'YYYY/MM/DD';

    const openDeleteDialog = () => {
        if (typeof openDeleteDialogFn === 'function') openDeleteDialogFn();
        setOpen('remove');
    };

    const openEditDialog = () => {
        if (typeof openEditDialogFn === 'function') openEditDialogFn();
        setOpen('edit');
    };

    const closeDialog = () => {
        setOpen(null);
    };

    const handleEditEvent = async (customerID, contactID, result) => {
        if (typeof handleEditContact === 'function') {
            let resp = await handleEditContact(customerID, contactID, result);
            if (resp) closeDialog();
        }
    };

    const handleDeleteEvent = async (customerID, contactID) => {
        if (typeof handleDeleteContact === 'function') {
            let resp = await handleDeleteContact(customerID, contactID);
            if (resp) closeDialog();
        }
    };

    return (
        <Card isOverflowHidden className={clsx('contact-record-card', className)}>
            <Card.CardContent>
                <Stack justifyContent="space-between" className="pt-2 px-2">
                    <span className="contact-record-card-markbook">
                        <Typography variant="h5" className="contact-record-card-date">
                            {parseDate(contactDate, format)}
                        </Typography>
                    </span>
                    <div>
                        {handleEditContact && (
                            <IconButton onClick={openEditDialog}>
                                <EditIcon />
                            </IconButton>
                        )}
                        {handleDeleteContact && (
                            <IconButton onClick={openDeleteDialog}>
                                <TrashIcon />
                            </IconButton>
                        )}
                    </div>
                </Stack>
                <Stack className="px-3 py-3" justifyContent="space-between">
                    <div className="overflow-hidden info-item">
                        <span className="contact-record-card-label">狀態</span>
                        <span className="contact-record-card-label-text">{contactResultTypeText}</span>
                    </div>
                    <div className="overflow-hidden info-item text-right">
                        <span className="contact-record-card-label">擔當</span>
                        <span className="contact-record-card-label-text">{coachName}</span>
                    </div>
                </Stack>
            </Card.CardContent>
            <Divider />
            <Card.CardContent>
                <div className="px-3">
                    <Typography variant="h6" className="title py-2">
                        {title}
                    </Typography>
                    <Typography className="content pb-4">{notes}</Typography>
                </div>
            </Card.CardContent>
            <RemoveContractRecordDialog open={isOpen === 'remove'} setOpen={() => setOpen(null)} doAction={() => handleDeleteEvent(customerID, contactID)} />
            <EditRecordDialog
                open={isOpen === 'edit'}
                setOpen={() => setOpen(null)}
                doAction={(_par) => handleEditEvent(customerID, contactID, _par)}
                title={title}
                notes={notes}
            />
        </Card>
    );
};

export default ContactRecordCard;
