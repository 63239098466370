import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import { Dialog, DialogActions, DialogContent, Grid } from '@mui/material';
import clsx from 'clsx';
import GradientColorHeader from '@icoach/components/dialog/GradientColorHeader';
import { Alert, Button, CheckboxField, DateField, Form, MenuItem, SelectField, TextField } from '@common/components/';
import { parseDate } from '@util/moment';
import useEmployeeApi from '@apis/useEmployeeApi';
import { getCheckboxValue, setCheckboxValue } from '@icoach/documents/tools';
import useMembershipApi from '@apis/useMembershipApi';
import { refIsRequiredError } from '@util/utils';
import { DateTimeStandard } from '../../../../components/pickers/enums/DateTimeStandard';

const ManualGiftDialogContent = React.forwardRef((props, ref) => {
    const { employeeOptions = [] } = props;
    const [isExtension, setExtension] = useState(false);
    const createDateRef = useRef(null);
    const applyMonthRef = useRef(null);
    const applyDayRef = useRef(null);
    const employeeRef = useRef(null);
    const reasonTextRef = useRef(null);
    const isExtensionRef = useRef(null);

    const handleChangeExtension = (e, v) => {
        setExtension(v);
    };

    useImperativeHandle(
        ref,
        () => ({
            getResult: () => {
                let result = {};
                if (createDateRef.current && createDateRef.current.getResult) {
                    result = Object.assign(result, {
                        createDate: parseDate(createDateRef.current.getResult(), DateTimeStandard.DisplayUtcFull),
                    });
                }
                if (applyMonthRef.current && applyMonthRef.current.getResult) {
                    result = Object.assign(result, {
                        applyMonth: applyMonthRef.current.getResult(),
                    });
                }
                if (applyDayRef.current && applyDayRef.current.getResult) {
                    result = Object.assign(result, {
                        applyDay: applyDayRef.current.getResult(),
                    });
                }
                if (employeeRef.current && employeeRef.current.getResult) {
                    result = Object.assign(result, { employeeID: employeeRef.current.getResult() });
                }
                if (reasonTextRef.current && reasonTextRef.current.getResult) {
                    result = Object.assign(result, {
                        reasonText: reasonTextRef.current.getResult(),
                    });
                }
                if (isExtensionRef.current && isExtensionRef.current.getResult) {
                    result = Object.assign(result, {
                        isExtension: getCheckboxValue(isExtensionRef.current.getResult(), {
                            isMultiple: false,
                        }),
                    });
                }
                return result;
            },
            isError: () => refIsRequiredError(createDateRef, applyMonthRef, applyDayRef, employeeRef, reasonTextRef, isExtensionRef),
        }),
        // eslint-disable-next-line
        []
    );

    return (
        <DialogContent>
            <Grid spacing={3} container>
                <Grid xs={6} item>
                    <DateField ref={createDateRef} label={'建單日期'} defaultValue={parseDate()} fullWidth required />
                </Grid>
                <Grid xs={6} item>
                    <SelectField label={'擔當'} ref={employeeRef} fullWidth required>
                        {Array.isArray(employeeOptions) &&
                            employeeOptions
                                .filter((item) => item || !item.disabled)
                                .map((item) => (
                                    <MenuItem key={item.value} value={item.value}>
                                        {item.text}
                                    </MenuItem>
                                ))}
                    </SelectField>
                </Grid>
                <Grid xs={3} item>
                    <TextField type={'number'} ref={applyMonthRef} label={'贈送月數'} defaultValue={0} inputProps={{ min: 0 }} fullWidth required />
                </Grid>
                <Grid xs={3} item>
                    <TextField type={'number'} ref={applyDayRef} label={'贈送天數'} defaultValue={0} inputProps={{ min: 0 }} fullWidth required />
                </Grid>
                <Grid xs={6} item>
                    <CheckboxField ref={isExtensionRef} className={'mt-4'} defaultChecked={setCheckboxValue(isExtension)} onChange={handleChangeExtension}>
                        <CheckboxField.Checkbox label={'展延'} value={true} />
                    </CheckboxField>
                </Grid>
                <Grid xs={12} item className={clsx({ hide: !isExtension })}>
                    <Alert className={'alert-justify-content-start'} severity={'info'} variant={'filled'} color={'info'}>
                        勾選『展延』，會員提出終止時若未使用完畢將退回。
                    </Alert>
                </Grid>
                <Grid xs={12} item>
                    <TextField ref={reasonTextRef} label={'贈送原因'} minRows={3} multiline fullWidth required />
                </Grid>
            </Grid>
        </DialogContent>
    );
});

const ManualGiftDialog = (props) => {
    const { className, open, memberID, membershipID, onClose, refresh } = props;
    const { getEmployeesOptionsApi } = useEmployeeApi();
    const { postMemberMembershipManualGiftApi } = useMembershipApi();
    const [resource, setResource] = useState();
    const dialogContentRef = useRef();
    const doEmployeesOptionsApi = async () => {
        const res = await getEmployeesOptionsApi();
        if (res) {
            setResource(res);
        }
    };

    const getParams = () => {
        let result = {
            applyMonth: 0,
        };
        if (dialogContentRef.current && dialogContentRef.current.getResult) {
            result = Object.assign(result, dialogContentRef.current.getResult());
        }
        return result;
    };

    const doMemberMembershipManualGiftApi = async (memberID, membershipID, params) => {
        const res = await postMemberMembershipManualGiftApi(memberID, membershipID, params);
        if (res) {
            refresh && refresh();
            onClose();
        }
    };

    const handleSubmit = () => {
        const isError = refIsRequiredError(dialogContentRef);
        if (!isError) {
            const params = getParams();
            doMemberMembershipManualGiftApi(memberID, membershipID, params);
        }
    };

    useEffect(() => {
        if (open) {
            doEmployeesOptionsApi();
        }
        // eslint-disable-next-line
    }, [open]);
    return (
        <Dialog
            className={clsx('simple-content', 'dialog', className)}
            PaperProps={{ className: 'wx-65rem', component: Form }}
            onSubmit={handleSubmit}
            open={open}
            fullWidth
        >
            <GradientColorHeader onClose={onClose}>新增贈送</GradientColorHeader>
            <ManualGiftDialogContent ref={dialogContentRef} employeeOptions={resource} />
            <DialogActions>
                <Button type={'submit'} variant="contained">
                    確認送出
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ManualGiftDialog;
