import Mock from 'mockjs';
import { successApi } from './respondData';

// 來店會員清單
export const withMockCheckinProvider = () => {
    Mock.mock(/^\/api\/members\/checkin$/, 'get', ({ url }) => {
        let sourceData = { ...successApi };
        sourceData.result = [...checkinMember];
        return sourceData;
    });
    Mock.setup({ timeout: '200-800' });
};

const checkinMember = [
    {
        workoutFrequency: '0-1-3-4',
        workoutCount: 8,
        measureDay: 16,
        memberID: 1,
        memberNo: 'F331003912',
        memberName: '李禎祐',
        nickName: '禎祐',
        checkinTime: '2022-05-19T11:01:00',
        warinessTypes: [2 ** 0, 2 ** 1, 2 ** 2, 2 ** 3, 2 ** 4, 2 ** 5, 2 ** 6, 2 ** 7, 2 ** 8],
        promotionTypes: [2 ** 11],
        serviceSaleItems: ['二週面談', '推廣內衣', '發日記', '邀約講座'],
        order: 10,
        memberImg: 'https://randomuser.me/api/portraits/women/60.jpg',
    },
    {
        workoutFrequency: '1-1-1-1',
        workoutCount: 330,
        measureDay: 8,
        memberID: 1,
        memberNo: 'F331003903',
        memberName: '郝彥生',
        nickName: '阿郝',
        checkinTime: '2022-05-19T10:53:00',
        warinessTypes: [2 ** 5, 2 ** 6, 2 ** 7, 2 ** 8],
        promotionTypes: [2 ** 11, 2 ** 12],
        serviceSaleItems: ['邀約講座'],
        order: 9,
        memberImg: 'https://randomuser.me/api/portraits/women/48.jpg',
    },
    {
        workoutFrequency: '1-1-1-1',
        workoutCount: 490,
        measureDay: 16,
        memberID: 1,
        memberNo: 'F331003902',
        memberName: '許幸妮',
        nickName: '阿妮',
        checkinTime: '2022-05-19T10:49:00',
        warinessTypes: [],
        promotionTypes: [2 ** 14],
        serviceSaleItems: ['邀約講座'],
        order: 8,
        memberImg: 'https://randomuser.me/api/portraits/women/44.jpg',
    },
    {
        workoutFrequency: '1-1-1-1',
        workoutCount: 400,
        measureDay: 12,
        memberID: 1,
        memberNo: 'F331003889',
        memberName: '蕭美君',
        nickName: '阿君',
        checkinTime: '2022-05-19T10:45:00',
        warinessTypes: [2 ** 8, 2 ** 3, 2 ** 1, 2 ** 5],
        promotionTypes: [2 ** 11],
        serviceSaleItems: ['邀約講座'],
        order: 7,
        memberImg: 'https://randomuser.me/api/portraits/women/2.jpg',
    },
    {
        workoutFrequency: '3-3-3-4',
        workoutCount: 263,
        measureDay: 13,
        memberID: 1,
        memberNo: 'F331003886',
        memberName: '詹惠菁',
        nickName: '小菁',
        checkinTime: '2022-05-19T10:28:28',
        warinessTypes: [2 ** 5, 2 ** 3],
        promotionTypes: [2 ** 14],
        serviceSaleItems: ['邀約講座'],
        order: 6,
        memberImg: 'https://randomuser.me/api/portraits/women/90.jpg',
    },
    {
        workoutFrequency: '1-3-4-3',
        workoutCount: 122,
        measureDay: 23,
        memberID: 1,
        memberNo: 'F331003884',
        memberName: '黃如意',
        nickName: '如意',
        checkinTime: '2022-05-19T10:20:22',
        warinessTypes: [2 ** 2],
        promotionTypes: [2 ** 13],
        serviceSaleItems: ['推廣內衣', '邀約講座'],
        order: 5,
        memberImg: 'https://randomuser.me/api/portraits/women/80.jpg',
    },
    {
        workoutFrequency: '1-0-0-1',
        workoutCount: 34,
        measureDay: 2,
        memberID: 1,
        memberNo: 'F331003883',
        memberName: '梁逸君',
        nickName: '小君',
        checkinTime: '2022-05-19T10:10:12',
        warinessTypes: [2 ** 3],
        promotionTypes: [],
        serviceSaleItems: [],
        order: 4,
        memberImg: 'https://randomuser.me/api/portraits/women/78.jpg',
    },
    {
        workoutFrequency: '3-3-3-3',
        workoutCount: 54,
        measureDay: 13,
        memberID: 1,
        memberNo: 'F331003868',
        memberName: '黃郁惇',
        nickName: '小惇',
        checkinTime: '2022-05-19T10:03:50',
        warinessTypes: [2 ** 3],
        promotionTypes: [2 ** 11],
        serviceSaleItems: ['推廣內衣'],
        order: 3,
        memberImg: 'https://randomuser.me/api/portraits/women/12.jpg',
    },
    {
        workoutFrequency: '1-2-3-2',
        workoutCount: 22,
        measureDay: 30,
        memberID: 1,
        memberNo: 'F331003858',
        memberName: '沈惠菊',
        nickName: '小菊',
        checkinTime: '2022-05-19T10:02:45',
        warinessTypes: [2 ** 3],
        promotionTypes: [2 ** 13],
        serviceSaleItems: [],
        order: 2,
        memberImg: 'https://randomuser.me/api/portraits/women/35.jpg',
    },
    {
        workoutFrequency: '1-1-1-1',
        workoutCount: 1290,
        measureDay: 12,
        memberID: 1,
        memberNo: 'F331003842',
        memberName: '徐明雪',
        nickName: '小雪',
        checkinTime: '2022-05-19T10:00:48',
        warinessTypes: [2 ** 3],
        promotionTypes: [2 ** 12],
        serviceSaleItems: [],
        order: 1,
        memberImg: 'https://randomuser.me/api/portraits/women/50.jpg',
    },
];
