import React, { useImperativeHandle, useRef } from 'react';
import { Stack, DateField, TextField } from '../../../components';
import { refIsRequiredError } from '../../../util/utils';
import { parseDate, getAddDays, getDateIterate, getDiffDate } from '../../../util/moment';

/**
 * 批次
 */
const BatchDayBox = React.forwardRef((props, ref) => {
    const dateRef = useRef(null);
    const counts = useRef(null);
    useImperativeHandle(
        ref,
        () => {
            return {
                getResult: () => {
                    const startDate = parseDate(dateRef.current.getResult(), 'YYYY-MM-DD');
                    const count = counts.current.getResult();
                    const endDate = getAddDays(startDate, count - 1);
                    const nowDate = parseDate(new Date());
                    const lastDate = getDiffDate(nowDate, endDate) < 0 ? nowDate : endDate;
                    return getDateIterate(startDate, lastDate);
                },
                isError: () => refIsRequiredError(dateRef, counts),
            };
        },
        // eslint-disable-next-line
        []
    );
    return (
        <Stack alignItems="start" spacing={2}>
            <DateField ref={dateRef} label="批次簽到起始日" maxDate={parseDate(new Date())} required />
            <TextField label="天數" defaultValue={1} ref={counts} type={'number'} inputProps={{ min: 1 }} helperText="欄位必填與不得輸入負數" required />
        </Stack>
    );
});

export default BatchDayBox;
