import EmployeesPage from '../employees/EmployeesPage';
import EmployeesInformationsPage from '../employees/informations/EmployeesInformationsPage';
import EmployeesInformationsAddPage from '../employees/informations/EmployeesInformationsAddPage';
import ClubSystemPage from '../club/ClubSystemPage';
import ClubSuspensionPage from '../club/suspension/ClubSuspensionPage';

export const HUMAN_RESOURCES_TABS = `/club/human-resources/:tabs(employees-overview)/`;
export const HUMAN_RESOURCES_EMPLOYEES_OVERVIEW = `/club/human-resources/employees-overview/`;
export const HUMAN_RESOURCES_ADD = `/club/human-resources/employees/add/`;
export const HUMAN_RESOURCES_INFORMATION_EMPLOYEEID = `/club/human-resources/employees/information/:employeeID/`;
export const HUMAN_RESOURCES_INFORMATION_EMPLOYEEID_PATH = (employeeID) => `/club/human-resources/employees/information/${employeeID}/`;

export const SYSTEM_CONFIGURATION_TABS = `/club/system-configuration/:tabs(basic|others)/`;
export const SYSTEM_CONFIGURATION_BASIC = `/club/system-configuration/basic/`;
export const SYSTEM_CONFIGURATION_OTHERS = `/club/system-configuration/others/`;

export const CLUB_CLOSED_CONFIGURATION = `/club/club-closed/configuration/`;

// 員工
const employeesRouter = [
    {
        description: '店舖管理',
        frontKey: 'C10X01',
    },
    {
        description: '員工總覽',
        exact: true,
        path: HUMAN_RESOURCES_TABS,
        href: HUMAN_RESOURCES_EMPLOYEES_OVERVIEW,
        as: EmployeesPage,
        frontKey: 'C10X02',
    },
    {
        description: '新增員工',
        exact: true,
        path: HUMAN_RESOURCES_ADD,
        as: EmployeesInformationsAddPage,
        frontKey: 'C10X03',
    },
    {
        description: '員工資訊頁面',
        exact: true,
        path: HUMAN_RESOURCES_INFORMATION_EMPLOYEEID,
        as: EmployeesInformationsPage,
        frontKey: 'C10X04',
    },
    {
        description: '系統設定',
        exact: true,
        path: SYSTEM_CONFIGURATION_TABS,
        href: SYSTEM_CONFIGURATION_BASIC,
        as: ClubSystemPage,
        frontKey: 'C10X05',
    },
    {
        description: '營業暫停',
        exact: true,
        path: CLUB_CLOSED_CONFIGURATION,
        href: CLUB_CLOSED_CONFIGURATION,
        as: ClubSuspensionPage,
        frontKey: 'C10X06',
    },
];

export default employeesRouter;
