import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import { Dialog, DialogActions, DialogContent, Grid } from '@mui/material';
import _ from 'lodash';
import clsx from 'clsx';
import GradientColorHeader from '@icoach/components/dialog/GradientColorHeader';
import { CheckoutPaidListBox, CreditOrderList } from '@icoach/accountBook/components';
import { InvoiceCard } from '@icoach/accountBook/AccountBookCreateOrderPage';
import { refIsRequiredError } from '@util/utils';
import useOrderCheckoutApi from '@apis/useOrderCheckoutApi';
import { Alert, Button, Form, MenuItem, SelectField } from '@common/components/';
import { parseDate } from '@util/moment';

const NoInformationDialogContent = () => {
    return (
        <DialogContent className={'bg-blue-gray-50 container main-container-spacing'}>
            <Alert variant={'standard'} severity={'info'} color={'info'}>
                沒有欠費資料
            </Alert>
        </DialogContent>
    );
};
const CheckoutCreditOrderDialogContent = React.forwardRef((props, ref) => {
    const { data = {}, resource, isShowCash, isShoCredit } = props;
    const { orders, invoiceSetting, member } = data;
    const { coachOptions: employeeOptions } = resource;
    const [paidData, setPaidData] = useState({
        total: 0,
        paidPrice: 0,
    });
    const employeeRef = useRef();
    const creditOrderRef = useRef();
    const checkoutRef = useRef();
    const invoiceRef = useRef();

    let calculateTotalData = () => {
        if (creditOrderRef && creditOrderRef.current && creditOrderRef.current.getTotalData) {
            setPaidData(creditOrderRef.current.getTotalData());
        }
    };

    useImperativeHandle(ref, () => ({
        getResult: () => {
            let result = {
                employeeID: employeeRef.current.getResult(),
            };
            if (creditOrderRef.current && creditOrderRef.current.getResult) {
                result = Object.assign(result, creditOrderRef.current.getResult());
            } else {
                result.checkoutOrderIDs = [];
            }
            if (checkoutRef.current && checkoutRef.current.getResult) result = Object.assign(result, checkoutRef.current.getResult());
            if (invoiceRef.current && checkoutRef.current.getResult) result = Object.assign(result, { invoiceSetting: invoiceRef.current.getResult() });
            return result;
        },
        isError: () => refIsRequiredError(creditOrderRef, checkoutRef, invoiceRef),
    }));

    return (
        <DialogContent className={'bg-blue-gray-50 container main-container-spacing'}>
            <Grid spacing={2} container>
                <Grid xs={4} item></Grid>
            </Grid>
            <CreditOrderList ref={creditOrderRef} orders={orders} updateOrderTotal={calculateTotalData} />
            <Grid spacing={2} container>
                <Grid xs={6} item>
                    <InvoiceCard ref={invoiceRef} sourceData={{ ...invoiceSetting, issuingEmail: member?.issuingEmail || '' }} options={resource} showEmail />
                </Grid>
                <Grid xs={6} item>
                    <CheckoutPaidListBox
                        className={'mb-4'}
                        ref={checkoutRef}
                        receivableAmount={paidData.total}
                        paidPrice={paidData.paidPrice}
                        isShowCash={isShowCash}
                        isShoCredit={isShoCredit}
                    />
                    <SelectField label={'擔當'} ref={employeeRef} direction={'row'} fullWidth required>
                        {Array.isArray(employeeOptions) &&
                            employeeOptions
                                .filter((item) => item || !item.disabled)
                                .map((item) => (
                                    <MenuItem key={item.value} value={item.value}>
                                        {item.text}
                                    </MenuItem>
                                ))}
                    </SelectField>
                </Grid>
            </Grid>
        </DialogContent>
    );
});
const CheckoutCreditOrderDialog = (props) => {
    const { className, open, title = '欠費補款', data: dataProps = {}, memberID, resource: resourceProps, onClose, refresh, isShowCash, isShoCredit } = props;
    const { getMemberInfoCardApi } = useOrderCheckoutApi();
    const { postOrderCheckoutByPeopleApi } = useOrderCheckoutApi();
    const [sourceData, setSourceData] = useState(null);
    const [options, setOptions] = useState(null);
    const contentRef = useRef(null);
    const preloadRef = useRef(false);

    const getParams = () => {
        let result = {
            receiverType: 1,
            receiverID: memberID,
            orderDate: parseDate(),
            items: [],
            products: [],
            purchaseTimeType: 0,
            purchaseToolType: 0,
        };
        if (contentRef.current && contentRef.current.getResult) {
            Object.assign(result, contentRef.current.getResult());
        }
        return result;
    };

    const handleSubmit = () => {
        let isError = contentRef.current && contentRef.current.isError();
        if (!isError) {
            const params = getParams();
            doOrderCheckoutByPeopleApi(params);
        }
    };

    const doOrderCheckoutByPeopleApi = async (params) => {
        let res = await postOrderCheckoutByPeopleApi(params);
        if (res) {
            refresh(memberID);
            onClose();
        }
    };

    const doInitOrderAndOptionsApi = async (memberID) => {
        let res = await getMemberInfoCardApi(memberID);
        if (res) {
            preloadRef.current = true;
            const { orderData = {}, resource: _r } = res;
            _.isEmpty(options) && setOptions(_r);
            setSourceData(orderData);
        }
    };

    useEffect(
        () => {
            if (open) {
                if (!_.isEmpty(dataProps)) {
                    _.isEmpty(options) && setOptions(resourceProps);
                    setSourceData(dataProps);
                } else if (_.isEmpty(sourceData) && !preloadRef.current) {
                    doInitOrderAndOptionsApi(memberID);
                }
            } else {
                setSourceData(null);
                preloadRef.current = false;
            }
        },
        // eslint-disable-next-line
        [open, dataProps],
    );

    if (!open || _.isEmpty(sourceData)) return null;

    return (
        <Dialog
            className={clsx('simple-content', 'dialog', className)}
            PaperProps={{ className: 'wx-90rem', component: Form }}
            onSubmit={handleSubmit}
            open={open}
            fullWidth
        >
            <GradientColorHeader onClose={onClose}>{title}</GradientColorHeader>
            {Array.isArray(sourceData.orders) && sourceData.orders.length > 0 ? (
                <React.Fragment>
                    <CheckoutCreditOrderDialogContent ref={contentRef} data={sourceData} resource={options} isShowCash={isShowCash} isShoCredit={isShoCredit} />
                    <DialogActions className={' bg-blue-gray-50'}>
                        <Button type={'submit'} variant="contained">
                            確認送出
                        </Button>
                    </DialogActions>
                </React.Fragment>
            ) : (
                <NoInformationDialogContent />
            )}
        </Dialog>
    );
};

export default CheckoutCreditOrderDialog;
