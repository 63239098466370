import { useCallback } from 'react';
import { useSnackbar } from 'notistack';
import useAjax from '@apis/useAjax';
import useShowLoading from '@util/hook/useShowLoading';

// 置物櫃
export const GET_CABINET_API = `/api/cabinet/`;
const POST_CABINET_API = GET_CABINET_API;
const GET_CABINET_OPTIONS_API = `/api/cabinet/options/`;
const POST_CABINET_LIST_API = `/api/cabinet/list/`;
const GET_CABINET_ID_API = (cabinetID) => `/api/cabinet/${cabinetID}/`;
const POST_CABINET_EXCEL_API = (employeeID) => `/api/cabinet/excel/${employeeID}/`;
const GET_CABINET_EXCEL_TEMPLATE_API = `/api/cabinet/export/empty/`;

// 置物櫃管理 api
const useCabinetApi = () => {
    const Ajax = useAjax();
    const isLoading = useShowLoading();
    const { enqueueSnackbar } = useSnackbar();

    // 置物櫃的下拉式選單
    // 取得單一置物櫃
    const getInitCabinetApi = useCallback(
        async (cabinetID) => {
            let result = {};
            isLoading(true);
            const cabinet = await Ajax.get(GET_CABINET_ID_API(cabinetID));
            const resource = await Ajax.get(GET_CABINET_OPTIONS_API);
            if (cabinet) result = Object.assign(result, { cabinet });
            if (resource) result = Object.assign(result, { resource });
            isLoading(false);
            return result;
        },
        // eslint-disable-next-line
        []
    );

    // 置物櫃的下拉式選單
    const getCabinetOptionsApi = useCallback(
        async () => {
            const resp = await Ajax.get(GET_CABINET_OPTIONS_API);
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    // 取得所有閒置的置物櫃列表(ID 名稱 租金 備註)
    const getIdleCabinetApi = useCallback(
        async () => {
            const resp = await Ajax.get(GET_CABINET_API);
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    // 取得置物櫃列表
    const postCabinetListApi = useCallback(
        async (params) => {
            const resp = await Ajax.post(POST_CABINET_LIST_API, params);
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    // 取得單一置物櫃
    const getCabinetApi = useCallback(
        async (cabinetID) => {
            const resp = await Ajax.get(GET_CABINET_ID_API(cabinetID));
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    // 新增單一置物櫃
    const postCabinetApi = useCallback(
        async (params) => {
            const resp = await Ajax.post(POST_CABINET_API, params);
            if (resp) {
                enqueueSnackbar('新增成功', {
                    variant: 'success',
                });
            }
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    // 更新單一置物櫃
    const putCabinetApi = useCallback(
        async (cabinetID, params) => {
            const resp = await Ajax.put(GET_CABINET_ID_API(cabinetID), params);
            if (resp) {
                enqueueSnackbar('更新成功', {
                    variant: 'success',
                });
            }
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    // 刪除單一置物櫃
    const deleteCabinetApi = useCallback(
        async (cabinetID, params) => {
            const resp = await Ajax.delete(GET_CABINET_ID_API(cabinetID), params);
            if (resp) {
                enqueueSnackbar('已刪除', {
                    variant: 'success',
                });
            }
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    // 批次上傳置物櫃 excel
    const postCabinetExcelApi = useCallback(
        async (employeeID, formData) => {
            const resp = await Ajax.post(POST_CABINET_EXCEL_API(employeeID), formData, {
                headerOption: {
                    contentType: false,
                    processData: false,
                },
            });
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    // 匯出空白EXCEL
    const getCabinetExcelTemplateApi = useCallback(
        () => {
            window.open(GET_CABINET_EXCEL_TEMPLATE_API, '_blank');
        },
        // eslint-disable-next-line
        []
    );
    return {
        getInitCabinetApi,
        getCabinetOptionsApi,
        getIdleCabinetApi,
        getCabinetApi,
        postCabinetApi,
        putCabinetApi,
        deleteCabinetApi,
        postCabinetListApi,
        postCabinetExcelApi,
        getCabinetExcelTemplateApi,
    };
};

export default useCabinetApi;
