import React, { useState, useRef, useImperativeHandle, useEffect } from 'react';
import { Grid } from '@mui/material';
import { SelectField, MenuItem } from '@common/components/';
import { refIsRequiredError } from '@util/utils';
import useEmployeeApi from '@apis/useEmployeeApi';

const MembershipTransferOutExecuteClubCard = React.forwardRef((props, ref) => {
    const { options: clubOptions = [], clubID: clubIDProps, employeeID: employeeIDProps, readOnly: readOnlyProps } = props;
    const [coachOptions, setCoachOptions] = useState([]);
    const [clubID, setClubID] = useState(clubIDProps || null);
    const clubIDRef = useRef(null);
    const coachIDRef = useRef(null);
    const { employees = {} } = useEmployeeApi(clubID);

    const handleClubOptionsChange = (e, dom) => {
        const { value } = dom.props;
        setClubID(value);
    };

    useImperativeHandle(
        ref,
        () => {
            return {
                isError: () => refIsRequiredError(clubIDRef, coachIDRef),
                getResult: () => ({
                    contractClubID: clubIDRef.current.getResult() || 0,
                    employeeID: coachIDRef.current.getResult() || 0,
                }),
            };
        },
        // eslint-disable-next-line
        []
    );

    useEffect(
        () => {
            if (employees[clubID]) {
                setCoachOptions(employees[clubID]);
            }
        },
        // eslint-disable-next-line
        [employees[clubID]]
    );

    return (
        <React.Fragment>
            <Grid xs={4} item>
                <SelectField
                    ref={clubIDRef}
                    label="經辦店舖"
                    fullWidth
                    value={clubID || null}
                    onChange={handleClubOptionsChange}
                    readOnly={readOnlyProps}
                    displayEmpty
                    required
                >
                    <MenuItem value={''} disabled>
                        <em>請選擇</em>
                    </MenuItem>
                    {clubOptions.map(({ text, value, disabled }) => (
                        <MenuItem value={value} key={value} disabled={disabled}>
                            {text}
                        </MenuItem>
                    ))}
                </SelectField>
            </Grid>
            <Grid xs={4} item>
                <SelectField
                    ref={coachIDRef}
                    key={coachOptions}
                    defaultValue={employeeIDProps || null}
                    label="經辦教練"
                    readOnly={readOnlyProps}
                    displayEmpty
                    fullWidth
                    required
                >
                    <MenuItem value={''} disabled>
                        <em>請選擇</em>
                    </MenuItem>
                    {coachOptions.map(({ text, value, disabled }) => (
                        <MenuItem value={value} key={value} disabled={disabled}>
                            {text}
                        </MenuItem>
                    ))}
                </SelectField>
            </Grid>
        </React.Fragment>
    );
});

export default MembershipTransferOutExecuteClubCard;
