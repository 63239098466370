import React from 'react';
import clsx from 'clsx';
import { isEmpty } from 'lodash';
import Tablenization from '@icoach/components/Tablenization';
import { TableLabel } from '@icoach/dataAnalysis/components/';
import { floatToPercentage, toFixed } from '@util/utils';
import { transposedWeeklyData } from '../staticData';

export const getDynamicThData = (data) => {
    let someoneData;

    if (data[0].hasOwnProperty('cumulative')) {
        someoneData = data[0].cumulative.healthcare || [];
    } else {
        someoneData = data[0].healthcare || [];
    }
    return someoneData.map((item) => {
        const { saleCategoryID, dataType, orderType } = item;
        const id = `hc_${dataType}_${saleCategoryID}_${orderType}`;
        return {
            key: id,
            title: item.itemName,
        };
    });
};

export const handleCumulativeData = (data) => {
    let result = {};
    let keys = Object.keys(data);
    keys.forEach((key) => {
        let value = data[key];
        if (Array.isArray(value)) {
            let arr = value;
            arr.forEach((dynamicItem) => {
                const { amount, saleCategoryID, dataType, orderType } = dynamicItem;
                const id = `hc_${dataType}_${saleCategoryID}_${orderType}`;
                if (result[id] === undefined) {
                    result[id] = {};
                }
                result[id] = amount;
            });
        } else {
            result[key] = value;
        }
    });
    return result;
};
const getRowsData = (data, cumulative, thData) => {
    let rowsData = [];
    let rowsLabel = [
        {
            cellKey: 'title',
            headerLabel: '項目',
            width: 26,
            formatCell: (title, { tip, isStar }) => {
                return <TableLabel title={title} tip={tip} isStar={isStar} />;
            },
        },
        {
            cellKey: 'goal',
            headerLabel: '目標',
            align: 'center',
            width: 11,
        },
        {
            cellKey: 'cumulative',
            headerLabel: '最新數字',
            align: 'center',
            className: 'total-col',
            minWidth: 128,
        },
    ];
    let dynamicThData = getDynamicThData(data);

    data = transposedWeeklyData(data);

    if (!isEmpty(data)) {
        if (!isEmpty(dynamicThData)) {
            thData = thData.concat(dynamicThData);
        }
        rowsData = thData.map(({ key, formatCell, ...other }) => {
            let weeklyData = data[key];
            let rowData = {
                cellKey: '',
                cumulative: cumulative[key],
                ...other,
                ...weeklyData,
            };

            if (formatCell) {
                rowData.cellKey = 'cumulative';
                rowData.cumulative = formatCell(cumulative[key], rowData);
                for (let week in weeklyData) {
                    rowData.cellKey = week;
                    rowData[week] = formatCell(weeklyData[week], rowData);
                }
            }

            return rowData;
        });

        rowsLabel = [...rowsLabel, ...data.week];
    }

    return { rowsLabel, rowsData };
};

// 表格標題與目標資訊
// title: 標題 string
// tip: 說明 string
// isStar: 閃亮之星 boolean 選填
// goal: 目標 string
export const thData = [
    {
        key: 'listAmount',
        title: '名單數',
        tip: '',
        goal: '',
        isStar: false,
        formatCell: (val) => {
            return toFixed(val);
        },
    },
    {
        key: 'askAmount',
        title: '詢問數',
        tip: '',
        goal: '',
        isStar: false,
        formatCell: (val) => {
            return toFixed(val);
        },
    },
    {
        key: 'apoAmount',
        title: 'APO數',
        tip: '',
        goal: '',
        isStar: false,
        formatCell: (val) => {
            return toFixed(val);
        },
    },
    {
        key: 'apoRate',
        title: 'APO率',
        tip: 'APO數/詢問數',
        goal: '',
        isStar: false,
        formatCell: (val) => {
            return `${floatToPercentage(val)}%`;
        },
    },
    {
        key: 'faAmount',
        title: 'FA',
        tip: '',
        goal: '',
        isStar: false,
        formatCell: (val) => {
            return toFixed(val);
        },
    },
    {
        key: 'totalSales',
        title: '新銷售',
        tip: '',
        goal: '',
        isStar: false,
        formatCell: (val) => {
            return toFixed(val);
        },
    },
    {
        key: 'noSales',
        title: 'NoSale',
        tip: '',
        goal: '',
        isStar: false,
        formatCell: (val) => {
            return toFixed(val);
        },
    },
    {
        key: 'salesRate',
        title: '結案率',
        tip: '結案率 = 新銷售 / (新銷售 + FA noSale)',
        goal: '',
        isStar: false,
        formatCell: (val) => {
            return `${floatToPercentage(val)}%`;
        },
    },

    {
        key: 'measureAmount',
        title: '量身數',
        tip: '',
        goal: '',
        formatCell: (val) => {
            return toFixed(val);
        },
    },
    {
        key: 'proteinSales',
        title: '蛋白新訂單',
        tip: '',
        goal: '',
        formatCell: (val) => {
            return toFixed(val);
        },
    },
    {
        key: 'probioticsSales',
        title: '益生菌新訂單',
        tip: '',
        goal: '',
        formatCell: (val) => {
            return toFixed(val);
        },
    },
    {
        key: 'calciumSales',
        title: '鈣新訂單',
        tip: '',
        goal: '',
        formatCell: (val) => {
            return toFixed(val);
        },
    },
];

const EmployeesMonthlyTable = (props) => {
    const { className, rowsData: data, cumulative } = props;
    const { rowsLabel, rowsData } = getRowsData(data, handleCumulativeData(cumulative), thData);
    return (
        <Tablenization cardProps={{ className: clsx('monthly-table', className) }}>
            <Tablenization.TableRowHeader headerRow={rowsLabel} />
            <Tablenization.TableRowBody headerRow={rowsLabel} rowsData={rowsData} />
        </Tablenization>
    );
};

export default EmployeesMonthlyTable;
