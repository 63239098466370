import React from 'react';
import EmployeeScheduleOtherMatter from '@icoach/employees/schedule/EmployeeScheduleOtherMatter';
import EmployeeScheduleServiceItem from '@icoach/employees/schedule/EmployeeScheduleServiceItem';
import EmployeeScheduleCircuitMatter from '@icoach/employees/schedule/EmployeeScheduleCircuitMatter';
import useEmployees from '@icoach/employees/compoments/useEmployees';
import Tablenization from '@icoach/components/Tablenization';

const labelTitle = [
    {
        headerLabel: '時段',
        cellKey: 'scheduleTime',
        align: 'center',
        width: 12,
    },
    {
        headerLabel: 'CC',
        cellKey: 'circuitCoach',
        align: 'center',
        width: 18,
        formatCell: (curData, { scheduleTime }, { options, scheduleDate, refresh }) => {
            const { usableEmployeesOption } = options;
            return (
                <EmployeeScheduleCircuitMatter
                    option={usableEmployeesOption}
                    sourceData={curData}
                    scheduleDate={scheduleDate}
                    scheduleTime={scheduleTime}
                    refresh={refresh}
                />
            );
        },
    },
    {
        headerLabel: '銷售&服務',
        cellKey: 'appointments',
        align: 'center',
        width: 38,
        formatCell: (curData, { scheduleTime }, { options, scheduleDate, refresh }) => {
            const { usableEmployeesOption } = options;
            return (
                <EmployeeScheduleServiceItem
                    options={usableEmployeesOption}
                    sourceData={curData}
                    scheduleDate={scheduleDate}
                    scheduleTime={scheduleTime}
                    refresh={refresh}
                />
            );
        },
    },
    {
        headerLabel: '其他',
        cellKey: 'others',
        align: 'center',
        width: 40,
        formatCell: (curData, { scheduleTime }, { options, scheduleDate, refresh }) => {
            const { usableEmployeesOption } = options;
            return (
                <EmployeeScheduleOtherMatter
                    options={usableEmployeesOption}
                    sourceList={curData}
                    scheduleDate={scheduleDate}
                    scheduleTime={scheduleTime}
                    refresh={refresh}
                />
            );
        },
    },
];

const EmployeeScheduleTable = React.forwardRef((props, ref) => {
    const { sourceData: rowsData = [], options } = props;
    const { refresh } = useEmployees();
    return (
        <div>
            <Tablenization>
                <Tablenization.TableRowHeader headerRow={labelTitle} />
                <Tablenization.TableRowBody headerRow={labelTitle} rowsData={rowsData} options={{ ...options }} refresh={refresh} {...props} />
            </Tablenization>
        </div>
    );
});

export default EmployeeScheduleTable;
