import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';
import Tablenization from '../../components/Tablenization';
import { isValidDate, parseDate } from '../../../util/moment';
import { formatCurrencyFn } from '../../../util/utils';
import { Typography } from '../../../components';
import { isEmpty } from 'lodash';
import { ACCOUNT_BOOK_ORDERS_ORDER_URL } from '../../router/AccountBookRouter';
import { paymentTypeTabs, sumTotal } from '../staticData';
import { DateTimeStandard } from '../../../components/pickers/enums/DateTimeStandard';
import { MemoControllerBox } from '@icoach/components';

// 增加總計資料
const handleRowsData = (rowsData, rowsLabel) => {
    const totalData = sumTotal(rowsData, ['cashPrice', 'cardPrice', 'totalPrice', 'returnAmount', 'inwardAmount', 'handingFee']);
    const rowsDataByTotal = {};
    let newRowsData = rowsData;
    rowsLabel.forEach(({ cellKey }) => {
        if (!rowsDataByTotal.hasOwnProperty(cellKey)) {
            let val = '';
            if (totalData.hasOwnProperty(cellKey)) {
                val = totalData[cellKey];
            }
            if (['remitDate', 'orderDate'].includes(cellKey)) val = '總計';
            rowsDataByTotal[cellKey] = val;
        }
    });
    if (!isEmpty(rowsDataByTotal)) {
        newRowsData = [...newRowsData, rowsDataByTotal];
    }
    return newRowsData;
};
const orderRowsLabel = [
    {
        cellKey: 'orderDate',
        headerLabel: '建立日期',
        formatCell: (cellValue, { orderID, orderNo }) => {
            if (!isValidDate(cellValue)) {
                return cellValue;
            }
            return (
                <React.Fragment>
                    <Typography className={'mb-0'}>{parseDate(cellValue, DateTimeStandard.DisplayFullBySlash)}</Typography>
                    {orderNo && (
                        <Link className={'body2'} to={ACCOUNT_BOOK_ORDERS_ORDER_URL(orderID)}>
                            {orderNo}
                        </Link>
                    )}
                </React.Fragment>
            );
        },
    },
    {
        cellKey: 'receiverName',
        headerLabel: '購買人',
        formatCell: (cellValue) => {
            return <strong>{cellValue}</strong>;
        },
    },
    {
        cellKey: 'itemName',
        headerLabel: '品名',
        align: 'center',
    },
    {
        cellKey: 'invoiceNo',
        headerLabel: '發票號碼',
        align: 'center',
    },
    {
        cellKey: 'employeeName',
        headerLabel: '擔當',
        align: 'center',
    },
];
const orderRowsLabelByIncome = [
    ...orderRowsLabel,
    {
        cellKey: 'cashPrice',
        headerLabel: '現金付款金額',
        align: 'right',
        formatCell: (cellValue) => {
            return formatCurrencyFn(cellValue);
        },
    },
    {
        cellKey: 'cardPrice',
        headerLabel: '信用卡付款金額',
        align: 'right',
        formatCell: (cellValue) => {
            return formatCurrencyFn(cellValue);
        },
    },
    {
        cellKey: 'totalPrice',
        headerLabel: '總金額',
        align: 'right',
        formatCell: (cellValue) => {
            return formatCurrencyFn(cellValue);
        },
    },
    {
        cellKey: 'note',
        headerLabel: '備註',
        className: 'vertical-align-middle',
        formatCell: (cellValue) => {
            return <MemoControllerBox data={cellValue} dialogTitle={'備註'} />;
        },
    },
];
const orderRowsLabelByRefund = [
    ...orderRowsLabel,

    {
        cellKey: 'cashPrice',
        headerLabel: '現金退款金額',
        align: 'right',
        formatCell: (cellValue) => {
            return formatCurrencyFn(cellValue);
        },
    },
    {
        cellKey: 'cardPrice',
        headerLabel: '信用卡退款金額',
        align: 'right',
        formatCell: (cellValue) => {
            return formatCurrencyFn(cellValue);
        },
    },
    {
        cellKey: 'totalPrice',
        headerLabel: '總金額',
        align: 'right',
        formatCell: (cellValue) => {
            return formatCurrencyFn(cellValue);
        },
    },
    {
        cellKey: 'note',
        headerLabel: '備註',
        className: 'vertical-align-middle',
        formatCell: (cellValue) => {
            return <MemoControllerBox data={cellValue} dialogTitle={'備註'} />;
        },
    },
    {
        cellKey: 'returnNotes',
        headerLabel: '退單備註',
        className: 'vertical-align-middle',
        formatCell: (cellValue) => {
            return <MemoControllerBox data={cellValue} dialogTitle={'備註'} />;
        },
    },
];
const remitRowsLabel = [
    {
        cellKey: 'orderDate',
        headerLabel: '建立日期',
        formatCell: (cellValue) => {
            if (!isValidDate(cellValue)) {
                return cellValue;
            }
            return <Typography className={'mb-0'}>{parseDate(cellValue, DateTimeStandard.DisplayFullBySlash)}</Typography>;
        },
    },
    {
        cellKey: 'receiverName',
        headerLabel: '匯款對象',
        formatCell: (cellValue) => {
            return <strong>{cellValue}</strong>;
        },
    },
    {
        cellKey: 'itemName',
        headerLabel: '匯款項目',
        align: 'center',
    },
    {
        cellKey: 'invoiceNo',
        headerLabel: '發票號碼',
        align: 'center',
    },
    {
        cellKey: 'employeeDisplayName',
        headerLabel: '擔當',
        align: 'center',
    },
];
const remitRowsLabelByIncome = [
    ...remitRowsLabel,
    {
        cellKey: 'handingFee',
        headerLabel: '手續費',
        align: 'right',
        formatCell: (cellValue) => {
            return formatCurrencyFn(cellValue);
        },
    },
    {
        cellKey: 'totalPrice',
        headerLabel: '匯入金額',
        align: 'right',
        formatCell: (cellValue) => {
            return formatCurrencyFn(cellValue);
        },
    },
    {
        cellKey: 'note',
        headerLabel: '備註',
        className: 'vertical-align-middle',
        formatCell: (cellValue) => {
            return <MemoControllerBox data={cellValue} dialogTitle={'備註'} />;
        },
    },
];
const remitRowsLabelByRefund = [
    ...remitRowsLabel,
    {
        cellKey: 'returnAmount',
        headerLabel: '退費金額',
        align: 'right',
        formatCell: (cellValue) => {
            return formatCurrencyFn(cellValue);
        },
    },
    {
        cellKey: 'totalPrice',
        headerLabel: '匯出金額',
        align: 'right',
        formatCell: (cellValue) => {
            return formatCurrencyFn(cellValue);
        },
    },
    {
        cellKey: 'note',
        headerLabel: '備註',
        className: 'vertical-align-middle',
        formatCell: (cellValue) => {
            return <MemoControllerBox data={cellValue} dialogTitle={'備註'} />;
        },
    },
    {
        cellKey: 'returnNotes',
        headerLabel: '退單備註',
        className: 'vertical-align-middle',
        formatCell: (cellValue) => {
            return <MemoControllerBox data={cellValue} dialogTitle={'備註'} />;
        },
    },
];

const remitRowsLabelByReturn = [
    ...remitRowsLabel,
    {
        cellKey: 'returnAmount',
        headerLabel: '退費金額',
        align: 'right',
        formatCell: (cellValue) => {
            return formatCurrencyFn(cellValue);
        },
    },
    {
        cellKey: 'totalPrice',
        headerLabel: '匯出金額',
        align: 'right',
        formatCell: (cellValue) => {
            return formatCurrencyFn(cellValue);
        },
    },
    {
        cellKey: 'note',
        headerLabel: '備註',
        className: 'vertical-align-middle',
        formatCell: (cellValue) => {
            return <MemoControllerBox data={cellValue} dialogTitle={'備註'} />;
        },
    },
    {
        cellKey: 'returnNotes',
        headerLabel: '退單備註',
        className: 'vertical-align-middle',
        formatCell: (cellValue) => {
            return <MemoControllerBox data={cellValue} dialogTitle={'備註'} />;
        },
    },
];
const rowsLabelMap = new Map([
    [paymentTypeTabs.income, orderRowsLabelByIncome],
    [paymentTypeTabs.refund, orderRowsLabelByRefund],
    [paymentTypeTabs.incomeByRemit, remitRowsLabelByIncome],
    [paymentTypeTabs.refundByRemit, remitRowsLabelByRefund],
    [paymentTypeTabs.returnByRemit, remitRowsLabelByReturn],
]);

// paymentType 收入/退款
const DebitItemTableByOrder = (props) => {
    const { className, rowsData = [], paymentType = paymentTypeTabs.income, page, count, handlePageChange } = props;
    const getLabelTitle = useCallback(() => rowsLabelMap.get(paymentType), [paymentType]);
    const labelTitle = getLabelTitle();
    const getRowsData = useCallback(() => handleRowsData(rowsData, labelTitle), [rowsData, labelTitle]);
    return (
        <Tablenization cardProps={{ className: className }} count={count} page={page} onChange={handlePageChange} isPagination>
            <Tablenization.TableRowHeader headerRow={labelTitle} />
            <Tablenization.TableRowBody headerRow={labelTitle} rowsData={getRowsData()} />
        </Tablenization>
    );
};

export default DebitItemTableByOrder;
