import React, { useImperativeHandle, useRef } from 'react';
import clsx from 'clsx';
import { Dialog, DialogActions, DialogContent } from '@mui/material';
import { Button, Form } from '@common/components/';
import { CreditCard } from '@icoach/documents/components';
import GradientColorHeader from '@icoach/components/dialog/GradientColorHeader';
import { useMemberProfileApi } from '@apis/useMemberProfileApi';
import { useMemberDetailContext } from '@icoach/members/memberDetail/MemberDetailContext';

// 會員總覽 付款使用 如果其他地方使用邏輯與流程需雷同
export const CreditCardDialogContent = React.forwardRef((props, ref) => {
    const { sourceData } = props;
    let creditRef = useRef(null);

    let handleDefaultValue = (defaultValue) => {
        if (!defaultValue) return undefined;
        let { memberPaymentID, title, creditCardBankName, creditCardType, creditCardNo, cardExpiryDate, cardName, paymentType, cardRelation } = defaultValue;
        return {
            memberPaymentID,
            title,
            creditCardBankName,
            creditCardType,
            creditCardNo,
            cardExpiryDate,
            cardName,
            paymentType,
            cardRelation,
        };
    };

    useImperativeHandle(
        ref,
        () => ({
            getResult: () => {
                let { memberPaymentID, title, paymentType } = sourceData;
                let result = {
                    memberPaymentID,
                    paymentType,
                    title,
                    ...creditRef.current.getResult(),
                };

                return result;
            },
            isError: () => {
                return creditRef.current.isError();
            },
        }),
        // eslint-disable-next-line
        [],
    );

    return (
        <DialogContent>
            <CreditCard ref={creditRef} defaultValue={handleDefaultValue(sourceData)} isShowCreditCardBankName isShowCardRelation required />
        </DialogContent>
    );
});

const CreditCardDialog = (props) => {
    const { className, open, memberID, sourceData, onClose } = props;
    const { refresh } = useMemberDetailContext();
    const { postMemberPaymentCreditCardApi } = useMemberProfileApi();
    const contentRef = useRef(null);

    const doMemberPaymentCreditCardApi = async (memberID, params) => {
        let res = await postMemberPaymentCreditCardApi(memberID, params);
        if (res) {
            refresh.current && refresh.current(memberID);
            onClose();
        }
    };

    const handleSubmit = () => {
        let isError = contentRef.current && contentRef.current.isError();
        if (!isError) {
            let params = contentRef.current && contentRef.current.getResult();
            doMemberPaymentCreditCardApi(memberID, params);
        }
    };

    if (!open) return null;

    return (
        <Dialog className={clsx('simple-content', 'dialog', className)} PaperProps={{ className: 'wx-65rem' }} open={open} fullWidth>
            <Form onSubmit={handleSubmit}>
                <GradientColorHeader onClose={onClose}>修改信用卡付款資訊</GradientColorHeader>
                <CreditCardDialogContent ref={contentRef} sourceData={sourceData} />
                <DialogActions>
                    <Button type={'submit'} variant="contained">
                        確認送出
                    </Button>
                </DialogActions>
            </Form>
        </Dialog>
    );
};

export default CreditCardDialog;
