import React, { useImperativeHandle, useEffect, useState, useRef, useCallback } from 'react';
import { isEmpty } from '@util/utils';
import { Checkbox } from '@common/components/';

export const useLabelCheckBox = () => {
    const checkBoxsRef = useRef({});
    const headerCheckBoxRef = useRef({});
    const saveCheckedRef = useRef([]);
    const selfChangeRef = useRef({});

    const handleHeaderCheckBoxChange = useCallback(
        (isChecked) => {
            const checkBoxsAry = Object.values(checkBoxsRef.current);
            checkBoxsAry.map((target) => target?.setCheckedState(isChecked) ?? undefined);
        },
        // eslint-disable-next-line
        []
    );

    const handleCellCheckBoxChange = useCallback(
        (isChecked) => {
            const checkBoxsAry = Object.values(checkBoxsRef.current);
            if (isChecked) {
                const chkedAry = checkBoxsAry.map((target = {}) => (target?.getCheckedResult ?? null ? target.getCheckedResult() : null));
                const allChecked = chkedAry.every((val) => val);
                if (allChecked) {
                    headerCheckBoxRef.current.setHeaderCheckBox && headerCheckBoxRef.current.setHeaderCheckBox(true);
                } else {
                    headerCheckBoxRef.current.setIndeterminate && headerCheckBoxRef.current.setIndeterminate(true);
                }
            } else {
                headerCheckBoxRef.current.setHeaderCheckBox && headerCheckBoxRef.current.setHeaderCheckBox(false);
                const unChkAry = checkBoxsAry.map((target = {}) => (target?.getUnCheckedResult ?? null ? target.getUnCheckedResult() : null));
                const allUnChecked = unChkAry.every((val) => val);
                if (allUnChecked) {
                    headerCheckBoxRef.current.setIndeterminate && headerCheckBoxRef.current.setIndeterminate(false);
                } else {
                    headerCheckBoxRef.current.setIndeterminate && headerCheckBoxRef.current.setIndeterminate(true);
                }
            }
            onCellChange();
        },
        // eslint-disable-next-line
        []
    );

    const onCellChange = () => {
        selfChangeRef.current.getOnCellChangeAllData && selfChangeRef.current.getOnCellChangeAllData(getCheckedSourceData());
    };

    const getCheckedSourceData = useCallback(
        () => {
            const checkBoxsAry = Object.values(checkBoxsRef.current);
            const chkedAry = checkBoxsAry.filter((target = {}) => (target ? target?.getCheckedResult() ?? null : null));
            return chkedAry.map(({ getCheckedResult }) => {
                return getCheckedResult();
            });
        },
        // eslint-disable-next-line
        []
    );

    // 切換頁數時,更多頁面時要觸發此功能去記錄當下點選的item ID,分頁的話就不用
    const doSaveCheckedItem = () => {
        const getAllCheckedData = getCheckedSourceData();
        saveCheckedRef.current = getAllCheckedData.map(({ key }) => key);
    };

    // 重新搜尋或是重新排序觸發此功能清空已儲存的ID
    const doClearCheckedItems = () => {
        if (Object.keys(headerCheckBoxRef.current || {}).length !== 0) {
            headerCheckBoxRef.current.setHeaderCheckBox && headerCheckBoxRef.current.setHeaderCheckBox(false);
            headerCheckBoxRef.current.setIndeterminate && headerCheckBoxRef.current.setIndeterminate(false);
        }
        checkBoxsRef.current = {};
        saveCheckedRef.current = [];
    };

    const getCheckedItems = () => {
        return saveCheckedRef.current;
    };

    return {
        checkBoxsRef: checkBoxsRef,
        headerCheckBoxRef: headerCheckBoxRef,
        setCheckedChangeRef: selfChangeRef,
        onHeaderChange: handleHeaderCheckBoxChange,
        onCellChange: handleCellCheckBoxChange,
        getOnCellChange: onCellChange,
        getCheckedSourceData: getCheckedSourceData,
        doSaveCheckedItem: doSaveCheckedItem,
        getCheckedItems: getCheckedItems,
        doClearCheckedItems: doClearCheckedItems,
    };
};

export const LabelHeaderCheckBox = React.forwardRef((props, ref) => {
    const { onChange: onChangeProps, ...others } = props;
    const indeterminateRef = useRef(null);
    const [isCheckedState, setCheckedState] = useState(false);

    const handleCheckedChange = (value, checked) => {
        setCheckedState(checked);
        onChangeProps(checked);
    };

    let defaultCheckedBoxProp = {
        indeterminateRef: indeterminateRef,
        color: 'primary',
        checked: isCheckedState,
        onChange: handleCheckedChange,
        ...others,
    };

    useImperativeHandle(
        ref,
        () => {
            return {
                setHeaderCheckBox: (checked) => {
                    setCheckedState(!!checked);
                },
                setIndeterminate: (checked) => {
                    indeterminateRef.current.setIndeterminate(checked);
                },
            };
        },
        // eslint-disable-next-line
        [isCheckedState]
    );

    useEffect(
        () => {
            if (isCheckedState && indeterminateRef.current) {
                indeterminateRef.current.setIndeterminate(false);
            }
        },
        // eslint-disable-next-line
        [isCheckedState]
    );

    return <Checkbox {...defaultCheckedBoxProp} />;
});

export const LabelCheckBox = React.forwardRef((props, ref) => {
    const {
        idKey: idKeyProps = null,
        onChange: onChangeProps,
        defalutCehecked: defalutCeheckedProps = false,
        checkBoxRef,
        sourceData: sourceDataProps = {},
        asyncFnApi,
        disabled,
        ...others
    } = props;
    const [isCheckedState, setCheckedState] = useState(false);
    const [sourceData] = useState({ ...sourceDataProps, key: idKeyProps });
    const preload = useRef(false);

    const handleCheckedChange = (value, checked) => {
        if (typeof asyncFnApi === 'function') {
            asyncFnApi(sourceData, () => {
                setCheckedState(checked);
            });
        } else {
            setCheckedState(checked);
        }
    };

    let defaultCheckedBoxProp = {
        key: undefined,
        color: 'primary',
        checked: isCheckedState,
        onChange: handleCheckedChange,
        disabled: disabled,
        ...others,
    };

    if (!isEmpty(idKeyProps)) {
        defaultCheckedBoxProp.key = idKeyProps;
    }

    useImperativeHandle(
        disabled ? null : ref,
        () => {
            return {
                getResult: (targetKey = '') => {
                    return targetKey ? sourceData[targetKey] : sourceData;
                },
                getCheckedResult: (targetKey = '') => {
                    if (isCheckedState) return targetKey ? sourceData[targetKey] : sourceData;
                    else return null;
                },
                getUnCheckedResult: (targetKey = '') => {
                    if (!isCheckedState) return targetKey ? sourceData[targetKey] : sourceData;
                    else return null;
                },
                setCheckedState: (val = false) => setCheckedState(!!val),
            };
        },
        // eslint-disable-next-line
        [isCheckedState]
    );

    useEffect(
        () => {
            if (disabled) return;
            preload.current && onChangeProps(isCheckedState);
        },
        // eslint-disable-next-line
        [isCheckedState]
    );

    useEffect(
        () => {
            preload.current = true;
            setCheckedState(!!defalutCeheckedProps);
        },
        // eslint-disable-next-line
        [!!defalutCeheckedProps]
    );

    return <Checkbox {...defaultCheckedBoxProp} />;
});
