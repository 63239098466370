import React, { useEffect, useRef, useState } from 'react';
import GradientColorHeader from '@icoach/components/dialog/GradientColorHeader';
import { Dialog, DialogContent, DialogActions, Grid } from '@mui/material';
import { Button, SelectField, MenuItem, DateField, TextField, CheckboxField } from '@common/components/';
import { refIsRequiredError } from '@util/utils';
import useEmployeeApi from '@apis/useEmployeeApi';
import useDocMembershipTransferOutApi from '@apis/useDocMembershipTransferOutApi';
import { parseDate } from '@util/moment';
import { useHistory } from 'react-router-dom';
import { DOCUMENTS_MEMBERSHIPTRANSFEROUT_MOVEOUT_PEOPLETYPE_REFERENCEID_HISTORY_PATH } from '@icoach/router/routerPath';

const MembershipTransferOutMoveOutDialog = (props) => {
    const {
        open: openProps,
        onClose: onCloseProps,
        sourceData: sourceDataProps,
        // refresh: refreshPrpos
    } = props;
    const { transferInDate, isMoveToVersion0 } = sourceDataProps || {};
    const history = useHistory();
    const [employeeOption, setEmployeeOption] = useState([]);
    const employeeIDRef = useRef(null);
    const moveoutDateRef = useRef(null);
    const memoRef = useRef(null);
    const isConfirm = useRef(null);
    const { getEmployeesOptionsApi } = useEmployeeApi();
    const { putDocMembershipTransferOutConfirmApi } = useDocMembershipTransferOutApi();

    const getParams = () => {
        return {
            employeeID: employeeIDRef.current.getResult(),
            effectiveDate: moveoutDateRef.current.getResult(),
            note: memoRef.current.getResult(),
            memberID: sourceDataProps.memberID,
        };
    };

    const handleSubmit = () => {
        if (!refIsRequiredError(employeeIDRef, moveoutDateRef, memoRef, isConfirm)) {
            const params = getParams();
            doConfirmApo(params);
        }
    };

    const doConfirmApo = async (params) => {
        const cID = sourceDataProps.contractID;
        const resp = await putDocMembershipTransferOutConfirmApi(params, cID);
        if (resp) {
            const transferOutMemberID = resp;
            history.replace(DOCUMENTS_MEMBERSHIPTRANSFEROUT_MOVEOUT_PEOPLETYPE_REFERENCEID_HISTORY_PATH(17, transferOutMemberID));
            onCloseProps();
        }
    };

    const doInitApi = async () => {
        const resp = await getEmployeesOptionsApi();
        resp && setEmployeeOption(resp);
    };

    useEffect(
        () => {
            if (!openProps) return null;
            employeeOption.length === 0 && doInitApi();
        },
        // eslint-disable-next-line
        [openProps],
    );

    return (
        <Dialog PaperProps={{ className: 'wx-60rem' }} open={openProps} fullWidth>
            <GradientColorHeader onClose={onCloseProps}>移出作業完成</GradientColorHeader>
            <DialogContent>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <SelectField ref={employeeIDRef} label="經辦教練" displayEmpty fullWidth required>
                            <MenuItem disabled value="">
                                <em>請選擇</em>
                            </MenuItem>
                            {employeeOption.map(({ text, value, disabled }) => (
                                <MenuItem key={value} disabled={disabled} value={value}>
                                    {text}
                                </MenuItem>
                            ))}
                        </SelectField>
                    </Grid>
                    <Grid item xs={12}>
                        <DateField
                            key={transferInDate}
                            defaultValue={parseDate(transferInDate)}
                            ref={moveoutDateRef}
                            label={'移出生效日'}
                            FormLabelTip={'移出數登記日期'}
                            fullWidth
                            required
                            disabled={!isMoveToVersion0}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField ref={memoRef} label={'備註'} rows={5} multiline fullWidth />
                    </Grid>
                    <Grid item xs={12}>
                        <CheckboxField ref={isConfirm} className={'mb-1'} required>
                            <CheckboxField.Checkbox
                                label={
                                    <span className={'font-weight-bold'}>
                                        送出之後資料就<span className="text-error">無法調整</span>了哦
                                    </span>
                                }
                            />
                        </CheckboxField>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={handleSubmit}>
                    確認送出
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default MembershipTransferOutMoveOutDialog;
