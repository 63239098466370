import React, { useRef } from 'react';
import { useParams } from 'react-router-dom';
import { Button, TextField, Form } from '@common/components/';
import GradientColorHeader from '@icoach/components/dialog/GradientColorHeader';
import { Dialog, DialogActions, DialogContent } from '@mui/material';
import { refIsRequiredError } from '@util/utils';
import useTemporaryApi from '@apis/useTemporaryApi';
import useNonMember from '@icoach/nonMember/components/useNonMember';

const EditTemporaryDialog = (props) => {
    const { onClose: propsOnClose, open, fileName = '' } = props;
    const { id: temporaryID } = useParams();
    const { putTemporaryCustomersApi } = useTemporaryApi();
    const { refresh } = useNonMember();
    const folderRef = useRef(null);
    const handleCloseOnClick = () => {
        propsOnClose(null);
    };

    const handleSendOnClick = (e) => {
        if (!refIsRequiredError(folderRef)) {
            const params = {
                name: folderRef.current.getResult(),
            };
            editTemporariesListApi(params);
        }
    };

    const editTemporariesListApi = async (params) => {
        const resp = await putTemporaryCustomersApi(temporaryID, params);
        if (resp) {
            propsOnClose(null);
            refresh && refresh.current && refresh.current();
        }
    };

    return (
        <Dialog open={open} PaperProps={{ className: 'wx-65rem', component: Form }} onSubmit={handleSendOnClick} fullWidth>
            <GradientColorHeader onClose={handleCloseOnClick}>編輯暫存清單</GradientColorHeader>
            <DialogContent>
                <TextField direction="row" defaultValue={fileName} ref={folderRef} label="標題" fullWidth required />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleSendOnClick} color="secondary" variant="contained" type="submit">
                    確認
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default EditTemporaryDialog;
