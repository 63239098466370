import React, {useRef, useState, useEffect, useMemo} from 'react';
import {useParams, useHistory, Switch} from 'react-router-dom';
import {SearchBar, BlankFormBox} from '@icoach/documents/components/';
import MembershipTransferInHistory from '@icoach/documents/membership/transferIn/MembershipTransferInHistory';
import MembershipTransferInContent from '@icoach/documents/membership/transferIn/MembershipTransferInContent';
import MembershipTransferInSearchBarTabs from '@icoach/documents/membership/transferIn/MembershipTransferInSearchBarTabs';
import ConfirmTemporaryContractDialog from '@icoach/documents/dialog/ConfirmTemporaryContractDialog';
import {DOC_MEMBERSHIP_TRANSFER_IN_LINK} from '@icoach/documents/membership/staticData';
import {
    DOCUMENTS_MEMBERSHIPTRANSFERIN_PEOPLETYPE_REFERENCEID_HISTORY_ROUTER,
    DOCUMENTS_MEMBERSHIPTRANSFERIN_ENTEREDTYPE_ROUTER_PATH,
    DOCUMENTS_MEMBERSHIPTRANSFERIN_ENTEREDTYPE_PEOPLETYPE_ROUTER_PATH,
    DOCUMENTS_MEMBERSHIPTRANSFERIN_PEOPLETYPE_REFERENCEID_HISTORY_ROUTER_PATH,
    DOCUMENTS_MEMBERSHIPTRANSFERIN_UNENTERED_PEOPLETYPE_REFERENCEID_CONTRACT_ROUTER,
    DOCUMENTS_MEMBERSHIPTRANSFERIN_UNENTERED_PEOPLETYPE_REFERENCEID_CONTRACT_PATH,
    DOCUMENTS_MEMBERSHIPTRANSFERIN_ENTERED_PEOPLETYPE_REFERENCEID_CONTRACT_ROUTER,
    DOCUMENTS_MEMBERSHIPTRANSFERIN_ENTERED_PEOPLETYPE_REFERENCEID_READONLY_CONTRACT_ROUTER,
    DOCUMENTS_HEALTHDECLARATION_PEOPLETYPE_REFERENCEID_CONTRACT_PATH,
} from '@icoach/router/routerPath';
import useDocMembershipTransferInApi from '@apis/useDocMembershipTransferInApi';
import {Typography, PrivateRoute, Box} from '@common/components/';
import {ConfirmHealthDeclarationNotifyDialog} from "@icoach/documents/dialog";
import useToggleDialog from "@util/hook/useToggleDialog";
import {peopleType} from "@apis/usePeopleApi";
import useDocHealthDeclarationApi from "@apis/useDocHealthDeclarationApi";

const dialogTypeMap = {
    temporary: 'temporary',
    healthDeclarationNotify: 'healthDeclarationNotify',
};

const MembershipTransferInPage = ({path, match, isHistory = false, ...props}) => {
    const {
        getInitTransferInUnenteredOptionApi,
        getInitTransferInEnteredOptionApi,
        getDocTemporaryTransferInContractApi,
        deleteDocTemporaryTransferInContractApi,
    } = useDocMembershipTransferInApi();
    const history = useHistory();
    const {peopleType: peopleTypeProps, enteredType: enteredTypeProps, page: pageStatus, referenceID: referenceIDProps} = useParams();
    const [options, setOptions] = useState({});
    const {getDocHealthDeclarationValidApi} = useDocHealthDeclarationApi();
    const {openTarget, handleOpenDialog, handleCloseDialog} = useToggleDialog();
    const [isMounted, setMounted] = useState(false);
    const searchRef = useRef([]);
    const temporaryIDRef = useRef(0);

    const goToHealthDeclarationPage = (target) => {
        const {peopleType = peopleTypeProps, referenceID = referenceIDProps} = target;
        const path = `${DOCUMENTS_HEALTHDECLARATION_PEOPLETYPE_REFERENCEID_CONTRACT_PATH(peopleType, referenceID, 0)}?continue-doc`;
        history.push(path);
    }

    const checkPeopleType = (opts) => {
        const {targetOptions = []} = opts;
        const isInclude = targetOptions.find(({value}) => String(peopleTypeProps) === String(value));
        //初始預設值 尚未移入 10(移入會員) , 已移入 14(已移入會員)
        const nextTargetType = enteredTypeProps === 'entered' ? 14 : 10;
        if (!peopleTypeProps || !isInclude) {
            history.replace(DOCUMENTS_MEMBERSHIPTRANSFERIN_ENTEREDTYPE_PEOPLETYPE_ROUTER_PATH(enteredTypeProps, nextTargetType));
        }
    };

    // 搜尋 按鈕
    const handleSearchHistoryClick = (id) => {
        if (!pageStatus || pageStatus === 'history' || pageStatus === 'contract') {
            const {targetType, targetID} = searchRef.current.getResult();
            const path = DOCUMENTS_MEMBERSHIPTRANSFERIN_PEOPLETYPE_REFERENCEID_HISTORY_ROUTER_PATH(enteredTypeProps, targetType, id || targetID);
            history.replace(path);
        }
    };

    // 取消 按鈕 返回總覽頁 移入流程與其他文書不同故邏輯不同
    const handleReturnHistory = (nextTargetType, nextTargetID) => {
        if (nextTargetID !== 0) {
            let enteredType = nextTargetType === '14' ? 'entered' : 'unentered';
            const path = DOCUMENTS_MEMBERSHIPTRANSFERIN_PEOPLETYPE_REFERENCEID_HISTORY_ROUTER_PATH(enteredType, nextTargetType, nextTargetID);
            history.replace(path);
        }
        else {
            handleResetClick(peopleTypeProps);
        }
    };

    // 重設 按鈕
    const handleResetClick = (targetType) => {
        const path = DOCUMENTS_MEMBERSHIPTRANSFERIN_ENTEREDTYPE_PEOPLETYPE_ROUTER_PATH(enteredTypeProps, targetType);
        history.replace(path);
    };

    // 新增 按鈕
    const handleCreateClick = async () => {
        const {targetID: id, targetType: type} = searchRef.current.getResult();
        const params = {id, type};
        if ((String(type) === peopleType.transferManually && (id === 0 || id === '')) || !await doCheckHealthDeclarationApi(type, id)) {
            // 前置檢查健康申告書
            handleOpenDialog(dialogTypeMap.healthDeclarationNotify);
        } else {
            doCreateBlankContractApi(params, type, id);
        }
    };

    // 彈出視窗 要刪除暫存資料
    const handleDialogNoClick = () => {
        const params = {
            id: referenceIDProps,
            type: peopleTypeProps,
        };
        doDeleteTemporaryDocumentTransferInContractApi(params);
    };

    // 彈出視窗 讀取上次暫存資料繼續編輯
    const handleDialogYseClick = () => {
        if (!temporaryIDRef.current) return;
        const contractID = temporaryIDRef.current;
        const path = DOCUMENTS_MEMBERSHIPTRANSFERIN_UNENTERED_PEOPLETYPE_REFERENCEID_CONTRACT_PATH(peopleTypeProps, referenceIDProps, contractID);
        handleCloseDialog();
        history.push(path);
    };

    // 彈出視窗 跳至健康申告書
    const handleGoToHealthDeclarationPage = () => {
        const {targetID: id, targetType: type} = searchRef.current.getResult();

        goToHealthDeclarationPage({
            peopleType: type,
            referenceID: id === '' ? 0 : id,
        });
    }

    // 刪除暫存文書 且跳轉到新增空白的移入申請書
    const doDeleteTemporaryDocumentTransferInContractApi = async (params) => {
        const resp = await deleteDocTemporaryTransferInContractApi(params);
        if (resp) {
            const path = DOCUMENTS_MEMBERSHIPTRANSFERIN_UNENTERED_PEOPLETYPE_REFERENCEID_CONTRACT_PATH(peopleTypeProps, referenceIDProps, 0);
            history.push(path);
        }
    };

    // 新增空白頁面
    const doCreateBlankContractApi = async (params, type, id) => {
        let temporaryID;
        temporaryIDRef.current = 0;
        temporaryID = await getDocTemporaryTransferInContractApi(params);
        if (temporaryID !== 0 && !!temporaryID) {
            temporaryIDRef.current = temporaryID;
            handleOpenDialog(dialogTypeMap.temporary);
        } else {
            const path = DOCUMENTS_MEMBERSHIPTRANSFERIN_UNENTERED_PEOPLETYPE_REFERENCEID_CONTRACT_PATH(type, id, 0);
            history.push(path);
        }
    };

    // 初始searchbar options
    const doInitOptionsApi = async () => {
        let resp;
        if (enteredTypeProps === 'entered') {
            resp = await getInitTransferInEnteredOptionApi();
        } else if (enteredTypeProps === 'unentered') {
            resp = await getInitTransferInUnenteredOptionApi();
        } else {
            history.push(DOCUMENTS_MEMBERSHIPTRANSFERIN_ENTEREDTYPE_ROUTER_PATH('unentered'));
        }
        if (resp) {
            checkPeopleType(resp);
            setOptions(resp);
            setMounted(true);
        }
    };

    // 檢查健康申告書
    const doCheckHealthDeclarationApi = async (type, id) => {
        return (id > 0) && await getDocHealthDeclarationValidApi({type, id});
    }

    const useSearchBarMemo = useMemo(
        () => {
            return (
                <SearchBar
                    ref={searchRef}
                    className="mb-4"
                    TabsComponentProps={<MembershipTransferInSearchBarTabs/>}
                    sourceOption={options.targetOptions}
                    onResetEvent={handleResetClick}
                    onCreateEvent={handleCreateClick}
                    onSearchHistoryEvent={handleSearchHistoryClick}
                    routerPath={DOCUMENTS_MEMBERSHIPTRANSFERIN_ENTEREDTYPE_ROUTER_PATH(enteredTypeProps)}
                />
            );
        },
        // eslint-disable-next-line
        [options, enteredTypeProps]
    );

    useEffect(
        () => {
            doInitOptionsApi();
            return () => setMounted(false);
        },
        // eslint-disable-next-line
        [enteredTypeProps]
    );

    // peopleTypeProps === 14  必為閱讀對象為已移入只能看不能改
    return (
        <Box className="container main-container-spacing">
            {isMounted && (
                <React.Fragment>
                    <Box component="header" className={'page-header'}>
                        <Typography className={'page-header-title'} variant={'h3'}>
                            國內移籍申請書 (移入)
                        </Typography>
                        <Box className="btn-group">
                            <BlankFormBox sourceData={DOC_MEMBERSHIP_TRANSFER_IN_LINK} />
                        </Box>
                    </Box>
                    {useSearchBarMemo}
                    <Switch>
                        <PrivateRoute
                            exact
                            path={DOCUMENTS_MEMBERSHIPTRANSFERIN_PEOPLETYPE_REFERENCEID_HISTORY_ROUTER}
                            as={MembershipTransferInHistory}
                            isReadOnly={String(peopleTypeProps) === '14'}
                            onBlankContractClick={handleCreateClick}
                            searchRef={searchRef}
                        />
                        <PrivateRoute
                            exact
                            path={DOCUMENTS_MEMBERSHIPTRANSFERIN_UNENTERED_PEOPLETYPE_REFERENCEID_CONTRACT_ROUTER}
                            as={MembershipTransferInContent}
                            onReturnHistory={handleReturnHistory}
                        />
                        <PrivateRoute
                            exact
                            path={DOCUMENTS_MEMBERSHIPTRANSFERIN_ENTERED_PEOPLETYPE_REFERENCEID_CONTRACT_ROUTER}
                            as={MembershipTransferInContent}
                            onReturnHistory={handleReturnHistory}
                        />
                        <PrivateRoute
                            exact
                            path={DOCUMENTS_MEMBERSHIPTRANSFERIN_ENTERED_PEOPLETYPE_REFERENCEID_READONLY_CONTRACT_ROUTER}
                            as={MembershipTransferInContent}
                            onReturnHistory={handleReturnHistory}
                            isReadOnly={true}
                        />
                    </Switch>
                    <ConfirmTemporaryContractDialog
                        open={openTarget === dialogTypeMap.temporary}
                        handleClose={handleCloseDialog}
                        handleClickYes={handleDialogYseClick}
                        handleClickNo={handleDialogNoClick}
                    />
                    <ConfirmHealthDeclarationNotifyDialog
                        open={openTarget === dialogTypeMap.healthDeclarationNotify}
                        handleClose={handleCloseDialog}
                        handleClickYes={handleGoToHealthDeclarationPage}
                        handleClickNo={handleCloseDialog}
                    />
                </React.Fragment>
            )}
        </Box>
    );
};

export default MembershipTransferInPage;
