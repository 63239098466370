import React, { useRef, useState } from 'react';
import { useSnackbar } from 'notistack';
import moment from 'moment';
import { Grid } from '@mui/material';
import {
    Snackbar,
    Button,
    Switch,
    FormControlLabel,
    Box,
    DateIntervalField,
    DateField,
    Stack,
    Alert,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Typography,
    Form,
    IconButton,
} from '@common/components/';
import { Check as CheckIcon, Close as CloseIcon, QrCode } from '@common/SvgIcon/';
import { SignBox, UploadFeedbackBox } from '@icoach/components/';
import { refIsRequiredError } from '@util/utils';
import { getFirstOfMonth, getLastOfMonth, getAgeData } from '@util/moment';
import useToggleDialog from '@util/hook/useToggleDialog';
import UploadFileDialog from '@icoach/components/dialog/UploadFileDialog';
import MembershipFormAvatarDialog from '@icoach/members/dialog/MembershipFormAvatarDialog';
import img_cloud from '@icoach/assets/img/cloud.png';
import { CreateOrderDialog } from '@icoach/accountBook/dialog';
import { parseDate } from '@util/moment';
import ProfileImageUploader from '@icoach/components/ProfileImageUploader';
import MemberSiteQrCodeDialog from '@icoach/inbody/interview/MemberSiteQrCodeDialog';
import { ReactComponent as Spinner } from '@images/spinner-solid.svg';
// import { TaskTodo } from '@icoach/members/components';

const CalAgeComponent = () => {
    const [ageData, setAgeData] = useState(null);

    const handleBirthChange = (_, value) => {
        setAgeData(getAgeData(value));
    };
    return (
        <Stack direction="column" spacing="2">
            <DateField label="出生年月日" onChange={handleBirthChange} />
            <Typography>{ageData ? String(ageData.years) : '尚無設定'}</Typography>
        </Stack>
    );
};

const dialogTypeMap = {
    createOrder: 'createOrder',
    uploadFile: 'uploadFile',
    memberSide: 'member-site',
};
const ComponentTestPage = (props) => {
    const [open, setOpen] = React.useState(false);
    const [openMemberProfile, setOpenMemberProfile] = React.useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const { openTarget, handleOpenDialog, handleCloseDialog } = useToggleDialog();
    const switchRef = useRef(null);
    const dateRangeRef = useRef(null);
    const testDate1Ref = useRef(null);
    const testDate2Ref = useRef(null);
    const testDate3Ref = useRef(null);
    const testDate4Ref = useRef(null);
    const testTextRef = useRef([]);
    const signImgRef = useRef(null);
    const handleOnClick = () => {
        console.dir(switchRef.current);
        console.log(switchRef.current.checked);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    const handleMemberProfile = () => {
        setOpenMemberProfile(true);
    };

    const handleNotistackClick = () => {
        enqueueSnackbar('出來啊啊啊', { variant: 'info' });
        const { startDate, endDate } = dateRangeRef.current.getResult();
        refIsRequiredError(dateRangeRef);
        console.log(startDate, endDate);
        console.log(getFirstOfMonth(startDate));
        console.log(getLastOfMonth(endDate));
    };

    const handleDateSubmit = () => {
        const isError = refIsRequiredError(testDate4Ref, testDate1Ref, testDate2Ref, testDate3Ref);

        if (!isError) {
            console.log('submit ya~');
        }
    };

    return (
        <div className="main-container-spacing">
            <div className={'p-4'}>
                <Spinner width={40} />
                <div className="spinner"></div>
            </div>
            <Grid container spacing={3}>
                <Grid item xs={3}>
                    <DateField ref={(el) => (testTextRef.current[0] = el)} label={'測試'} FormLabelTip={'說明文字'} />
                </Grid>
                <Grid item xs={3}>
                    <DateField ref={(el) => (testTextRef.current[1] = el)} label={'申請日期'} FormLabelTip={'意旨移即生效日'} required />
                </Grid>
                <Grid item xs={3}>
                    <Button
                        className={'mt-4'}
                        variant="contained"
                        onClick={() => {
                            refIsRequiredError(testTextRef);
                            // console.log(testTextRef.current.getResult());
                        }}
                    >
                        取值
                    </Button>
                </Grid>
                <Grid item xs={3}>
                    <Button variant="contained" onClick={handleOnClick}>
                        Click
                    </Button>
                </Grid>
                <Grid item xs={3}>
                    <Button variant="contained" onClick={handleOnClick}>
                        Click
                    </Button>
                </Grid>
                <Grid item xs={3}>
                    <Button variant="contained" onClick={handleNotistackClick}>
                        notistack Click
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    <div className="btn-group">
                        <Button variant="contained" onClick={() => handleOpenDialog(dialogTypeMap.createOrder)}>
                            會員購物
                        </Button>
                        <Button variant="contained" onClick={() => handleOpenDialog(dialogTypeMap.uploadFile)}>
                            上傳檔案
                        </Button>
                        <IconButton variant="contained" onClick={() => handleOpenDialog(dialogTypeMap.memberSide)}>
                            <QrCode />
                        </IconButton>
                    </div>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={3}>
                    <span className="pr-2">測試Switch</span>
                    <FormControlLabel control={<Switch defaultChecked={false} inputProps={{ ref: switchRef }} />} label="APO" />
                </Grid>
            </Grid>
            <Snackbar open={open} autoHideDuration={2000} onClose={handleClose} message="okok" />

            <Grid spacing={3} container>
                <Grid xs={6} item>
                    <div>
                        <Alert severity={'success'} variant={'outlined'} color={'success'}>
                            成功數：10
                        </Alert>
                        <Alert severity={'error'} variant={'outlined'} color={'error'}>
                            失敗數：10
                        </Alert>
                        <Button>下載失敗檔案</Button>
                    </div>
                </Grid>
                <Grid xs={6} item>
                    <div
                        style={{
                            backgroundColor: '#f9f4fb',
                            border: '2px dashed #e4ceee',
                            borderRadius: '5px',
                        }}
                    >
                        <Typography style={{ padding: '15px 20px 0' }} className={'font-weight-bold'} variant={'h6'}>
                            處理筆數： 6{' '}
                        </Typography>
                        <List style={{ padding: '4px 4px' }}>
                            <ListItem className={'text-success'}>
                                <ListItemIcon>
                                    <CheckIcon htmlColor={'#4caf50'} />
                                </ListItemIcon>
                                <ListItemText primary={'成功數： 3'} />
                            </ListItem>
                            <ListItem className={'text-error'}>
                                <ListItemIcon>
                                    <CloseIcon htmlColor={'#ef5350'} />
                                </ListItemIcon>
                                <ListItemText primary={'失敗數： 2'} />
                            </ListItem>
                        </List>
                    </div>
                </Grid>
                <Grid xs={4} item>
                    <Box className={'upload-box'}>
                        <div className={`upload-box-wrap`}>
                            <div className={`upload-box-content text-center`}>
                                <img style={{ maxWidth: '90px' }} className={`upload-box-img`} src={img_cloud} alt="上傳檔案" />
                                <Typography>總筆數： 9</Typography>
                                <Typography className="desc">成功數： 6</Typography>
                                <Typography className="desc text-error bg-error-light">失敗數： 3</Typography>
                                <div className={'mt-2'}>
                                    <Button variant={'contained'}>下載失敗檔案</Button>
                                </div>
                            </div>
                        </div>
                    </Box>
                </Grid>
                <Grid xs={4} item>
                    <UploadFeedbackBox />
                </Grid>
                <Grid xs={4} item>
                    <UploadFeedbackBox isError />
                </Grid>

                <Grid xs={4} item>
                    <CalAgeComponent />
                </Grid>
                <Grid xs={12} item />
                <Grid className={'mb-4'} xs={8} item>
                    <Form onSubmit={handleDateSubmit}>
                        <Grid container spacing={2}>
                            <Grid xs={12} item>
                                <DateIntervalField
                                    ref={testDate4Ref}
                                    label="起始與結束"
                                    startDateProps={{
                                        minDate: parseDate(moment().add(1, 'months')),
                                    }}
                                    endDateProps={{
                                        maxDate: parseDate(moment().add(1, 'months')),
                                    }}
                                    views={['year', 'month']}
                                    openTo="month"
                                    format="YYYY/MM"
                                    mask="____/__"
                                    fullWidth
                                />
                            </Grid>
                            <Grid xs={6} item>
                                <DateField ref={testDate1Ref} label="日期測試限制" minDate={parseDate(moment().add(1, 'days'))} fullWidth />
                            </Grid>
                            <Grid xs={6} item />
                            <Grid xs={6} item>
                                <DateField
                                    ref={testDate2Ref}
                                    label="月份測試限制"
                                    minDate={parseDate(moment().add(1, 'months'))}
                                    views={['year', 'month']}
                                    openTo="month"
                                    format="YYYY/MM"
                                    mask="____/__"
                                    fullWidth
                                />
                            </Grid>
                            <Grid xs={6} item />
                            <Grid xs={6} item>
                                <DateField
                                    ref={testDate3Ref}
                                    label="年份測試限制"
                                    maxDate={parseDate(moment().add(1, 'years'))}
                                    views={['year']}
                                    openTo="year"
                                    format="YYYY"
                                    mask="____"
                                    fullWidth
                                />
                            </Grid>
                            <Grid xs={12} item>
                                <Button type={'submit'}>送出</Button>
                            </Grid>
                        </Grid>
                    </Form>
                </Grid>
                <Grid xs={12} item>
                    <Grid container spacing={2}>
                        <Grid xs={3} item>
                            <Button variant="contained" onClick={handleMemberProfile}>
                                修改會員資料
                            </Button>
                            <MembershipFormAvatarDialog open={openMemberProfile} onClose={setOpenMemberProfile} memberID={604} />
                        </Grid>
                        <Grid xs={4} item>
                            <SignBox title={'持卡人簽名'} ref={signImgRef} />
                            <Typography variant={'body2'} className={'font-color-3'}>
                                (須與信用卡背面簽名一致)
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid xs={12} item>
                    <ProfileImageUploader />
                </Grid>
                <CreateOrderDialog open={openTarget === dialogTypeMap.createOrder} memberID={604} onClose={handleCloseDialog} />
                <UploadFileDialog open={openTarget === dialogTypeMap.uploadFile} onClose={handleCloseDialog} />
                <MemberSiteQrCodeDialog open={openTarget === dialogTypeMap.memberSide} onClose={handleCloseDialog} sourceData={{ memberID: 1853 }} />
            </Grid>
        </div>
    );
};

export default ComponentTestPage;
