import { useCallback } from 'react';
import useAjax from '@apis/useAjax';
import { useSnackbar } from 'notistack';

const GET_ORDER_ID_API = (orderID) => `/api/orders/${orderID}/`;
const POST_ORDERS_OVERVIEW_API = `/api/orders/overview/`;
const GET_ORDERS_OVERVIEW_OPTIONS_API = `/api/orders/overview/options/`;
const POST_ORDERS_OVERVIEW_ADVANCE_API = `/api/orders/overview/advance/`;
const POST_ORDER_ISSUING_INVOICE_API = (orderID) => `/api/orders/${orderID}/issuing-invoice/`;
const GET_ORDER_ALLOWANCE_DIALOG_API = (orderID) => `/api/orders/${orderID}/allowance-dialog/`;
const POST_ORDER_ALLOWANCE_CONFIRM_API = `/api/orders/allowance-confirm/`;
const GET_ORDER_REISSUING_DIALOG_API = (orderID) => `/api/orders/${orderID}/reissuing-dialog/`;
const PUT_ORDER_REISSUING_DIALOG_API = GET_ORDER_REISSUING_DIALOG_API;
const POST_ORDER_REISSUING_API = (orderID) => `/api/orders/${orderID}/reissuing/`;
const PUT_ORDER_DELETE_API = (orderID) => `/api/orders/${orderID}/delete/`;
const PUT_ORDER_REINIT_API = (orderID) => `/api/orders/${orderID}/reinit/`;

const useOrderApi = () => {
    const Ajax = useAjax();
    const { enqueueSnackbar } = useSnackbar();

    // 訂單明細(主單(退單清單)、付款資訊、購買清單(退貨內容)、訂單紀錄)
    const getOrderApi = useCallback(
        async (orderID) => {
            let resp = await Ajax.get(GET_ORDER_ID_API(orderID));
            if (!resp) {
                enqueueSnackbar('讀取資料失敗', {
                    variant: 'error',
                });
            }
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    // 訂單總覽需使用到的下拉式選單
    const getOrdersOptionsApi = useCallback(
        async (orderID) => {
            let resp = await Ajax.get(GET_ORDERS_OVERVIEW_OPTIONS_API, orderID);

            if (!resp) {
                enqueueSnackbar('讀取資料失敗', {
                    variant: 'error',
                });
            }
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    // 訂單總覽
    const postOrderOverviewApi = useCallback(
        async (params) => {
            let resp = await Ajax.post(POST_ORDERS_OVERVIEW_API, params);
            !resp && enqueueSnackbar('讀取資料失敗', { variant: 'error' });
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    // 訂單總覽(進階)
    const postOrderOverviewAdvanceApi = useCallback(
        async (params) => {
            let resp = await Ajax.post(POST_ORDERS_OVERVIEW_ADVANCE_API, params);
            !resp && enqueueSnackbar('讀取資料失敗', { variant: 'error' });
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    // 指定訂單開立發票(開立失敗時)
    const postOrderIssuingInvoiceApi = useCallback(
        async (orderID) => {
            let resp = await Ajax.post(POST_ORDER_ISSUING_INVOICE_API(orderID));
            !resp && enqueueSnackbar('開立發票失敗', { variant: 'error' });
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    // 取得折讓訂單/發票相關資訊
    const getAllowanceInfoApi = useCallback(
        async (orderID) => {
            let resp = await Ajax.get(GET_ORDER_ALLOWANCE_DIALOG_API(orderID));
            !resp && enqueueSnackbar('讀取資料失敗', { variant: 'error' });
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    // 建立訂單/發票折讓
    const postAllowanceConfirmApi = useCallback(
        async (params) => {
            let resp = await Ajax.post(POST_ORDER_ALLOWANCE_CONFIRM_API, params);
            if (!resp) {
                enqueueSnackbar('折讓失敗', { variant: 'error' });
            } else {
                enqueueSnackbar('折讓成功', { variant: 'success' });
            }
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    // 取得重新開立發票的內容
    const getOrderReissuingApi = useCallback(
        async (orderID) => {
            let resp = Ajax.get(GET_ORDER_REISSUING_DIALOG_API(orderID));
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    // 重新送出更新訂單發票內容可重開發票
    const postOrderReissuingApi = useCallback(
        async (orderID, params) => {
            let resp = Ajax.post(POST_ORDER_REISSUING_API(orderID), params);
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    // 更新訂單發票內容可重開發票或折讓、作廢
    const putOrderReissuingApi = useCallback(
        async (orderID) => {
            let resp = Ajax.put(PUT_ORDER_REISSUING_DIALOG_API(orderID));
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    // 刪除訂單
    const putOrderDeleteApi = useCallback(
        async (orderID, params) => {
            let resp = Ajax.put(PUT_ORDER_DELETE_API(orderID), params);
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    // 更新訂單狀態為初始狀態
    const putOrderReinitApi = useCallback(
        async (orderID, params) => {
            let resp = Ajax.put(PUT_ORDER_REINIT_API(orderID), params);
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    return {
        getOrderApi,
        getOrdersOptionsApi,
        postOrderOverviewApi,
        postOrderOverviewAdvanceApi,
        postOrderIssuingInvoiceApi,
        getAllowanceInfoApi,
        postAllowanceConfirmApi,
        getOrderReissuingApi,
        postOrderReissuingApi,
        putOrderReissuingApi,
        putOrderDeleteApi,
        putOrderReinitApi,
    };
};

export default useOrderApi;
