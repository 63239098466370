import { InBodyHistoryPage, InBodyInterviewPage, InBodyOverviewFilterPage } from '@icoach/inbody/';
import { MeasureLeaderboardPage } from '@icoach/measureLeaderboard/';
import { INBODY_RECORD_ROUTER, INBODY_INTERVIEW_ROUTER, INBODY_OVERVIEW_FILTER_NUM_PATH, MEASURE_HEADERBOARD_ROUTER } from '@icoach/router/routerPath';

const inbodyRouter = [
    {
        description: '量身管理',
        frontKey: 'C8X01',
    },
    {
        description: '面談&量身',
        static: true,
        path: `${INBODY_INTERVIEW_ROUTER}:id?`,
        as: InBodyInterviewPage,
        frontKey: 'C8X02',
    },
    {
        description: '量身歷史紀錄清單',
        static: true,
        path: `${INBODY_RECORD_ROUTER}:id?`,
        href: INBODY_RECORD_ROUTER,
        as: InBodyHistoryPage,
        frontKey: 'C8X03',
    },
    {
        description: '快速名單',
        exact: true,
        path: INBODY_OVERVIEW_FILTER_NUM_PATH(':quickType(1|2|3|4)'),
        href: INBODY_OVERVIEW_FILTER_NUM_PATH(1),
        as: InBodyOverviewFilterPage,
        frontKey: 'C8X04',
    },
    {
        description: '全店排行榜',
        static: true,
        path: MEASURE_HEADERBOARD_ROUTER,
        href: MEASURE_HEADERBOARD_ROUTER,
        as: MeasureLeaderboardPage,
        frontKey: 'C8X05',
    },
];

export default inbodyRouter;
