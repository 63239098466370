import React, { useEffect, useRef } from 'react';
import useEmployees from '@icoach/employees/compoments/useEmployees';
import Tablenization from '@icoach/components/Tablenization';
import { MoreLabelBox } from '@icoach/components';
import { HUMAN_RESOURCES_INFORMATION_EMPLOYEEID_PATH } from '@icoach/router/EmployeesRouter';
import { Chip } from '@common/components/';

const MoreLabel = (props) => {
    const moreLabelMap = useRef({
        edit: {
            text: '查看',
            to: ({ urls }) => urls,
        },
    });

    return <MoreLabelBox {...props} moreLabelMap={moreLabelMap.current} textAlign={'center'} />;
};

const rowsLabel = [
    {
        cellKey: 'employeeNo',
        headerLabel: '員工編號',
    },
    {
        cellKey: 'name',
        headerLabel: '姓名',
    },
    {
        cellKey: 'nickName',
        headerLabel: '名牌名稱',
    },
    {
        cellKey: 'statusText',
        headerLabel: '狀態',
        align: 'center',
        formatCell: (cellValue) => {
            return <Chip label={cellValue} />;
        },
    },
    {
        cellKey: 'jobTitleText',
        headerLabel: '職稱',
    },
    // {
    //     cellKey: 'isOverdue',
    //     headerLabel: '證照是否到期',
    //     align: 'center',
    //     // formatCell: (cellValue) => {
    //     //     return cellValue ? '是' : '否';
    //     // },
    //     formatCell: (cellValue) => {
    //         return '尚未做';
    //     },
    // },
    {
        cellKey: 'mobile',
        headerLabel: '手機',
    },
    {
        cellKey: 'postCodeAddress',
        headerLabel: '居住地址',
        formatCell: (_, rowData) => {
            return `${rowData['postCode']} ${rowData['address']}`;
        },
    },
    {
        cellKey: 'more',
        headerLabel: '',
        align: 'right',
        isIcon: true,
        formatCell: (cellValue, rowData) => {
            return (
                <MoreLabel moreActionArray={['edit', 'remove']} {...(rowData || {})} urls={HUMAN_RESOURCES_INFORMATION_EMPLOYEEID_PATH(rowData.employeeID)} />
            );
        },
    },
];

const EmployeesOverivewTable = (props) => {
    const { className } = props;
    const { sourceData, setBookmark, refresh } = useEmployees();
    const { list: rowsData = [], totalPage: count = 0, pageIndex: page = 1 } = sourceData || {};
    const labelTitle = useRef(rowsLabel);

    const handlePageChange = (e, num) => {
        setBookmark(num);
    };

    useEffect(
        () => {
            refresh && refresh.current && refresh.current();
        },
        // eslint-disable-next-line
        []
    );

    return (
        <React.Fragment>
            <Tablenization cardProps={{ className: className }} onChange={handlePageChange} count={count} page={page} isPagination>
                <Tablenization.TableRowHeader headerRow={labelTitle.current} />
                <Tablenization.TableRowBody headerRow={labelTitle.current} rowsData={rowsData} />
            </Tablenization>
        </React.Fragment>
    );
};

export default EmployeesOverivewTable;
