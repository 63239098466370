import { useCallback } from 'react';
import useAjax from '@apis/useAjax';
import useShowLoading from '@util/hook/useShowLoading';
import { useSnackbar } from 'notistack';

const GET_ORDER_ACCOUNT_BOOK_OPTIONS_API = `/api/orders/account-book/options/`;
const GET_ORDER_ACCOUNT_BOOK_API = `/api/orders/account-book/`;
const POST_ORDER_ACCOUNT_BOOK_FUND_API = `/api/orders/account-book/fund/`;
const POST_ORDER_ACCOUNT_BOOK_SETTLE_API = `/api/orders/account-book/settle/`;
const DELETE_ORDER_ACCOUNT_BOOK_SETTLE_API = POST_ORDER_ACCOUNT_BOOK_SETTLE_API;
const POST_ORDER_ACCOUNT_BOOK_ITEMS_API = `/api/orders/account-book/items/`;
const PUT_ORDER_ACCOUNT_BOOK_ITEM_API = (itemID) => `/api/orders/account-book/items/${itemID}/`;
const DELETE_ORDER_ACCOUNT_BOOK_ITEM_API = PUT_ORDER_ACCOUNT_BOOK_ITEM_API;

const useOrderAccountBookApi = () => {
    const Ajax = useAjax();
    const { enqueueSnackbar } = useSnackbar();
    const isLoading = useShowLoading();

    // 取得每日結算需使用到的下拉式選單
    const getOrderAccountBookOptionsApi = useCallback(
        async () => {
            let resp = await Ajax.get(GET_ORDER_ACCOUNT_BOOK_OPTIONS_API);

            if (!resp) {
                enqueueSnackbar('讀取資料失敗', {
                    variant: 'error',
                });
            }
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    // 取得每日結算內容
    const getOrderAccountBookApi = useCallback(
        async (date) => {
            const params = { time: date };
            let resp = await Ajax.get(GET_ORDER_ACCOUNT_BOOK_API, params);

            if (!resp) {
                enqueueSnackbar('讀取資料失敗', {
                    variant: 'error',
                });
            }
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    //取得每日結算需使用到的下拉式選單
    // 取得每日結算內容
    const getInitOrderAccountBookApi = useCallback(
        async (date) => {
            const params = { time: date };
            let result = {};
            isLoading(true);
            const options = await Ajax.get(GET_ORDER_ACCOUNT_BOOK_OPTIONS_API);
            const accountBookData = await Ajax.get(GET_ORDER_ACCOUNT_BOOK_API, params);
            if (!options || !accountBookData) {
                enqueueSnackbar('讀取資料失敗', {
                    variant: 'error',
                });
            }
            if (options) result = Object.assign(result, { resource: options });
            if (accountBookData) result = Object.assign(result, { accountBookData: accountBookData });
            isLoading(false);
            return result;
        },
        // eslint-disable-next-line
        []
    );

    // 儲存每日結算零用金
    const postOrderAccountBookFundApi = useCallback(
        async (parameters) => {
            let resp = await Ajax.post(POST_ORDER_ACCOUNT_BOOK_FUND_API, parameters);

            if (!resp) {
                enqueueSnackbar('零用金設定失敗', {
                    variant: 'error',
                });
            }
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    // 儲存每日結算結帳結果
    const postOrderAccountBookSettleApi = useCallback(
        async (parameters) => {
            let resp = await Ajax.post(POST_ORDER_ACCOUNT_BOOK_SETTLE_API, parameters);

            if (!resp) {
                enqueueSnackbar('結帳失敗', {
                    variant: 'error',
                });
            } else {
                enqueueSnackbar('結帳成功', {
                    variant: 'success',
                });
            }
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    // 刪除今日結算內容
    const deleteOrderAccountBookSettleApi = useCallback(
        async () => {
            let resp = await Ajax.delete(DELETE_ORDER_ACCOUNT_BOOK_SETTLE_API);

            if (!resp) {
                enqueueSnackbar('刪除失敗', {
                    variant: 'error',
                });
            } else {
                enqueueSnackbar('成功刪除', {
                    variant: 'success',
                });
            }
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    // 建立每日結算項目(支出)
    const postOrderAccountBookItemApi = useCallback(
        async (parameters) => {
            let resp = await Ajax.post(POST_ORDER_ACCOUNT_BOOK_ITEMS_API, parameters);

            if (!resp) {
                enqueueSnackbar('新增費用失敗', {
                    variant: 'error',
                });
            }
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    // 更新每日結算項目(支出)
    const putOrderAccountBookItemApi = useCallback(
        async (itemID, parameters) => {
            let resp = await Ajax.put(PUT_ORDER_ACCOUNT_BOOK_ITEM_API(itemID), parameters);

            if (!resp) {
                enqueueSnackbar('更新失敗', {
                    variant: 'error',
                });
            }
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    // 更新今日結算項目(支出)
    const deleteOrderAccountBookItemApi = useCallback(
        async (itemID) => {
            let resp = await Ajax.delete(DELETE_ORDER_ACCOUNT_BOOK_ITEM_API(itemID));

            if (!resp) {
                enqueueSnackbar('刪除失敗', {
                    variant: 'error',
                });
            } else {
                enqueueSnackbar('成功刪除', {
                    variant: 'success',
                });
            }
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    return {
        getOrderAccountBookOptionsApi,
        getOrderAccountBookApi,
        getInitOrderAccountBookApi,
        postOrderAccountBookFundApi,
        postOrderAccountBookSettleApi,
        deleteOrderAccountBookSettleApi,
        postOrderAccountBookItemApi,
        putOrderAccountBookItemApi,
        deleteOrderAccountBookItemApi,
    };
};

export default useOrderAccountBookApi;
