import React from 'react';
import SvgIcon from '../SvgIcon';
import useForkRef from '@util/hook/useForkRef';

const Building = React.forwardRef((props, ref) => {
    const { className, ...others } = props;
    const handleRef = React.useRef(null);
    const svgRef = useForkRef(handleRef, ref);
    const defaultProperty = {
        className,
        ref: svgRef,
        ...others,
    };
    return (
        <SvgIcon {...defaultProperty}>
            <path d="M23.99,2.45c0-1.49-1.24-2.44-3.21-2.44-5.86-.01-11.71-.01-17.57,0C1.27,.01,.01,.94,.01,2.39c-.01,6.41-.01,12.82,0,19.23,0,1.42,1.25,2.34,3.14,2.37,1.75,.02,3.5-.01,5.25,.02,.56,0,.63-.15,.62-.5-.03-1.02-.02-2.04,0-3.06,.02-1.43,1.22-2.42,2.96-2.44,1.75-.02,3.02,.99,3.04,2.45,.01,.98,.03,1.96-.01,2.93-.02,.46,.1,.65,.79,.63,1.58-.04,3.16-.01,4.75-.01,2.29,0,3.47-.89,3.47-2.62,0-3.1,0-6.2,0-9.3,0-3.2,0-6.41,0-9.61ZM6.95,13.49c-.5,.01-1,0-1.5,0-1.17,0-1.44-.22-1.45-1.12,0-.12,0-.25,0-.37,0-.21,0-.42,0-.62,.04-.62,.35-.86,1.17-.88,.58-.01,1.16-.01,1.75,0,.66,.01,1.04,.27,1.07,.77,.03,.48,.02,.96,0,1.43-.02,.49-.38,.77-1.04,.79Zm-.07-5.99c-.61,0-1.22,.01-1.83,0-.66-.02-1.01-.3-1.04-.79-.02-.35,0-.71,0-1.06,0-.94,.27-1.14,1.5-1.14,.17,0,.33,0,.5,0,.28,0,.55,0,.83,0,.82,.03,1.14,.27,1.17,.89,.02,.42,0,.83,0,1.25,0,.55-.38,.85-1.12,.86Zm3.12-1.48c0-1.45,.08-1.51,2-1.51,.3,0,.61,0,.91,0,.7,.02,1.06,.3,1.08,.83,.01,.44,0,.87,0,1.31,0,.55-.38,.85-1.13,.86-.58,0-1.16,0-1.75,0-.75,0-1.11-.31-1.12-.86,0-.21,0-.42,0-.62Zm2.59,7.48c-.19,0-.39,0-.58,0-.28,0-.55,0-.83,0-.82-.03-1.15-.26-1.17-.88-.02-.42,0-.83,0-1.25,0-.55,.37-.85,1.12-.86,.61,0,1.22-.01,1.83,0,.66,.02,1.02,.29,1.04,.79,.02,.37,0,.75,0,1.12,0,.86-.29,1.08-1.41,1.08Zm3.41-7.49c0-.21-.01-.42,0-.62,.04-.61,.36-.86,1.18-.88,.55-.01,1.11,0,1.66,0,.74,0,1.14,.28,1.15,.84,0,.46,.02,.91,0,1.37-.02,.5-.39,.76-1.05,.78-.47,.01-.94,0-1.41,0-1.25,0-1.52-.2-1.52-1.12,0-.12,0-.25,0-.37Zm4,6.61c-.04,.62-.35,.86-1.18,.88-.55,.01-1.11,0-1.66,0-.74,0-1.14-.28-1.15-.84,0-.46-.02-.91,0-1.37,.02-.5,.39-.76,1.05-.78,.5-.01,1,0,1.5,0,1.15,0,1.44,.22,1.45,1.05,0,.15,0,.29,0,.44,0,.21,0,.42,0,.62Z" />
        </SvgIcon>
    );
});

export default Building;
