import React, { useRef, useState } from 'react';
import { MeasureLeaderboardSearchBar, MeasureLeaderboardTable } from '@icoach/measureLeaderboard/';
import { LeaderboradContext } from '@icoach/measureLeaderboard/useLeaderboradContext';
import { Typography, Box } from '@common/components/';

const MeasureLeaderboardPage = (props) => {
    const [rowsLabel, setRowsLabel] = useState([]);
    const [sourceData, setSourceData] = useState([]);
    const refreshRef = useRef(null);
    const compareMonthRef = useRef({
        firstDate: null,
        secondDate: null,
    });

    const refresh = () => {
        refreshRef && typeof refreshRef.current === 'function' && refreshRef.current();
    };

    const chilrenValue = {
        sourceData,
        setSourceData,
        rowsLabel,
        setRowsLabel,
        refresh: () => refresh(),
        getMonths: () => compareMonthRef.current,
        setMonths: (f, s) => {
            compareMonthRef.current = {
                firstDate: f,
                secondDate: s,
            };
        },
        setRefresh: (el) => (refreshRef.current = el),
    };

    const PanelCompoment = React.useMemo(
        () => {
            return <MeasureLeaderboardSearchBar className={'mb-4'} />;
        },
        // eslint-disable-next-line
        []
    );

    return (
        <LeaderboradContext.Provider value={chilrenValue}>
            <Box className="container main-container-spacing">
                <Box component="header" className={'page-header'}>
                    <Typography className={'page-header-title'} variant={'h3'}>
                        全店排行榜
                    </Typography>
                </Box>
                {PanelCompoment}
                <MeasureLeaderboardTable />
            </Box>
        </LeaderboradContext.Provider>
    );
};

export default MeasureLeaderboardPage;
