import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { setPdfDialogData } from '@redux/pdfDialog/action';

const useShowPDFDialog = () => {
    const dispatch = useDispatch();

    const handleOnclose = () => {
        setPdfDialog({});
    };

    const setPdfDialog = useCallback(
        (data) => {
            dispatch(
                setPdfDialogData({
                    ...data,
                    onClose: () => {
                        data.onClose && data.onClose();
                        handleOnclose();
                    },
                })
            );
        },
        // eslint-disable-next-line
        []
    );

    return setPdfDialog;
};

export default useShowPDFDialog;
