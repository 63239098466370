import { NotificationsPage } from '@icoach/notifications/';
import { ROOT_NOTIFICATIONS_ROUTER } from '@icoach/router/routerPath';

// 通知管理
const notificationsRouter = [
    {
        description: '通知管理',
        strict: true,
        path: ROOT_NOTIFICATIONS_ROUTER,
        as: NotificationsPage,
        // frontKey: 'C12X01',
        frontKey: 'CXX99901',
    },
];

export default notificationsRouter;
