import React, { useState, useRef } from 'react';
import SmsOverviewSearchBar from './SmsOverviewSearchBar';
import MessageContext from '../components/MessageContext';
import { Typography } from '@common/components/';
const SmsOverviewTable = React.lazy(() => import('./SmsOverviewTable'));

const SmsOverviewPage = React.forwardRef((props, ref) => {
    const [sourceData, setSourceData] = useState({});
    const [sortParams, setSortParams] = useState(1);
    const [bookmark, setBookmark] = useState({
        pageIndex: 1,
        pageSize: 10,
    });
    const refreshRef = useRef(null);

    const childContext = {
        sourceData: sourceData,
        setSourceData: (target) => {
            setSourceData(target);
        },
        sortParams,
        setSortParams: (sortType) => setSortParams(sortType),
        bookmark: bookmark,
        setBookmarkNum: (num) => setBookmark((pre) => ({ ...pre, pageIndex: num })),
        refresh: refreshRef,
        setRefresh: (el) => (refreshRef.current = el),
    };

    const SearchBar = React.useMemo(
        () => <SmsOverviewSearchBar />,
        // eslint-disable-next-line
        []
    );

    return (
        <MessageContext.Provider value={childContext}>
            <div className="container main-container-spacing">
                <header className={'page-header'}>
                    <Typography className={'page-header-title'} variant={'h3'}>
                        簡訊總覽
                    </Typography>
                </header>
                {SearchBar}
                <React.Suspense fallback={null}>
                    <SmsOverviewTable />
                </React.Suspense>
            </div>
        </MessageContext.Provider>
    );
});

export default SmsOverviewPage;
