import React, { useCallback } from 'react';
import { isEmpty } from 'lodash';
import Tablenization from '@icoach/components/Tablenization';
import CellController from '@icoach/accountBook/components/CellController';
import { formatCurrencyFn } from '@util/utils';
import { isValidDate, parseDate } from '@util/moment';
import { filterTypeMap, statisticalType as statisticalTypeMap, sumTotal } from '@icoach/accountBook/staticData';
import { useAccountBookContext } from '@icoach/accountBook/components/AccountBookContext';
import { DateTimeStandard } from '../../components/pickers/enums/DateTimeStandard';

// 標題
const rowsLabelByOrderType = [
    {
        cellKey: 'statisticsDate',
        headerLabel: '建立日期',
        formatCell: (cellValue) => {
            return (
                <React.Fragment>
                    <span>{isValidDate(cellValue) ? parseDate(cellValue, DateTimeStandard.DisplayDateBySlash) : cellValue}</span>
                </React.Fragment>
            );
        },
    },
    {
        cellKey: 'newSalePrice',
        headerLabel: '新銷售收入',
        align: 'right',
        formatCell: (cellValue, { statisticsDate }) => {
            return (
                <CellController filterType={filterTypeMap.get('newSalePrice')} date={statisticsDate} isShow={isValidDate(statisticsDate)} isOrder>
                    {formatCurrencyFn(cellValue)}
                </CellController>
            );
        },
    },
    {
        cellKey: 'membershipPrice',
        headerLabel: '會籍收入',
        align: 'right',
        formatCell: (cellValue, { statisticsDate }) => {
            return (
                <CellController filterType={filterTypeMap.get('membershipPrice')} date={statisticsDate} isShow={isValidDate(statisticsDate)} isOrder isRemit>
                    {formatCurrencyFn(cellValue)}
                </CellController>
            );
        },
    },
    {
        cellKey: 'productSalePrice',
        headerLabel: '商品收入',
        align: 'right',
        formatCell: (cellValue, { statisticsDate }) => {
            return (
                <CellController filterType={filterTypeMap.get('productSalePrice')} date={statisticsDate} isShow={isValidDate(statisticsDate)} isOrder>
                    {formatCurrencyFn(cellValue)}
                </CellController>
            );
        },
    },
    {
        cellKey: 'healthcareSalePrice',
        headerLabel: '健康產品收入',
        align: 'right',
        formatCell: (cellValue, { statisticsDate }) => {
            return (
                <CellController filterType={filterTypeMap.get('healthcareSalePrice')} date={statisticsDate} isShow={isValidDate(statisticsDate)} isOrder>
                    {formatCurrencyFn(cellValue)}
                </CellController>
            );
        },
    },
    {
        cellKey: 'cabinetPrice',
        headerLabel: '租櫃收入',
        align: 'right',
        formatCell: (cellValue, { statisticsDate }) => {
            return (
                <CellController filterType={filterTypeMap.get('cabinetPrice')} date={statisticsDate} isShow={isValidDate(statisticsDate)} isOrder>
                    {formatCurrencyFn(cellValue)}
                </CellController>
            );
        },
    },
    {
        cellKey: 'totalPrice',
        headerLabel: '總計',
        align: 'right',
        formatCell: (cellValue) => formatCurrencyFn(cellValue),
    },
];
const rowsLabelByPaymentType = [
    {
        cellKey: 'statisticsDate',
        headerLabel: '建立日期',
        formatCell: (cellValue, rowData) => {
            const _parseDate = (date, format) => {
                return parseDate(date, format, false);
            };
            let _cellValue = cellValue;

            if (isValidDate(cellValue)) _cellValue = _parseDate(cellValue, 'YYYY/MM/DD');
            return (
                <React.Fragment>
                    <span>{_cellValue}</span>
                </React.Fragment>
            );
        },
    },
    {
        cellKey: 'cashTotalPrice',
        headerLabel: '現金',
        align: 'right',
        formatCell: (cellValue) => formatCurrencyFn(cellValue),
    },
    {
        cellKey: 'cardTotalPrice',
        headerLabel: '信用卡',
        align: 'right',
        formatCell: (cellValue) => formatCurrencyFn(cellValue),
    },
    {
        cellKey: 'totalPrice',
        headerLabel: '總計',
        align: 'right',
        formatCell: (cellValue) => formatCurrencyFn(cellValue),
    },
];
const rowsLabelMap = new Map([
    [statisticalTypeMap.orderType, rowsLabelByOrderType],
    [statisticalTypeMap.paymentType, rowsLabelByPaymentType],
]);

// 計算總計用
const initTotalDataByOrderType = ['newSalePrice', 'membershipPrice', 'productSalePrice', 'healthcareSalePrice', 'cabinetPrice', 'totalPrice'];
const initTotalDataByPaymentType = ['cashTotalPrice', 'cardTotalPrice', 'totalPrice'];
const initTotalDataMap = new Map([
    [statisticalTypeMap.orderType, initTotalDataByOrderType],
    [statisticalTypeMap.paymentType, initTotalDataByPaymentType],
]);

// 增加總計資料
const handleRowsData = (rowsData, rowsLabel, initData) => {
    const totalData = sumTotal(rowsData, initData);
    const rowsDataByTotal = {};
    let newRowsData = rowsData;
    rowsLabel.forEach(({ cellKey }) => {
        if (!rowsDataByTotal.hasOwnProperty(cellKey)) {
            let val = '';
            if (totalData.hasOwnProperty(cellKey)) {
                val = totalData[cellKey];
            }
            if (cellKey === 'statisticsDate') val = '總計';
            rowsDataByTotal[cellKey] = val;
        }
    });
    if (!isEmpty(rowsDataByTotal)) {
        newRowsData = [...newRowsData, rowsDataByTotal];
    }
    return newRowsData;
};

const AccountBookOrderStatOnSiteTable = (props) => {
    const { tableData, tabIndex } = useAccountBookContext();
    const getLabelTitle = useCallback(
        () => rowsLabelMap.get(tabIndex),
        // eslint-disable-next-line
        [tabIndex],
    );
    const labelTitle = getLabelTitle();
    const getRowsData = useCallback(
        () => handleRowsData(tableData, labelTitle, initTotalDataMap.get(tabIndex)),
        // eslint-disable-next-line
        [tableData, labelTitle],
    );
    const rowsData = getRowsData();

    return (
        <Tablenization cardProps={{ className: 'theme-gray overview' }}>
            <Tablenization.TableRowHeader headerRow={labelTitle} />
            <Tablenization.TableRowBody headerRow={labelTitle} rowsData={rowsData} />
        </Tablenization>
    );
};

export default AccountBookOrderStatOnSiteTable;
