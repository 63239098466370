import React from 'react';
import CycleOrderBox from './CycleOrderBox';
import { isEmpty } from 'lodash';
import { Box, Alert } from '@common/components/';

const CycleOrderList = (props) => {
    const { data, handleShowDialog } = props;
    return (
        <React.Fragment>
            {!isEmpty(data) ? (
                <Box component="ul" className="main-orders">
                    {data.map((item, index) => {
                        const { cycleOrderID } = item;
                        const handleShowDialogFn = handleShowDialog(index);
                        return (
                            <Box component="li" key={cycleOrderID} className="order-item">
                                <CycleOrderBox className={'main-order'} cycleOrderData={item} handleShowDialog={handleShowDialogFn} />
                            </Box>
                        );
                    })}
                </Box>
            ) : (
                <Alert color="info" severity="info" variant="filled">
                    尚無資料
                </Alert>
            )}
        </React.Fragment>
    );
};

export default CycleOrderList;
