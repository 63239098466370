import React from 'react';
import clsx from 'clsx';

const staticClass = 'outlinedinput-notchedOutline';

const NotchedOutlineRoot = (props) => {
    const { children } = props;
    let classNames = staticClass;
    return <fieldset className={classNames}>{children}</fieldset>;
};
const NotchedOutlineLegend = (props) => {
    const { children, ownerState } = props;
    let classNames = '';
    if (ownerState.label) classNames = `${staticClass}-label`;
    if (ownerState.label && ownerState.notched) classNames = clsx(classNames, `${staticClass}-notched`);
    return <legend className={classNames}>{children}</legend>;
};

const NotchedOutline = (props, ref) => {
    const { label, notched, ...other } = props;
    const ownerState = {
        notched,
        label,
    };

    return (
        <NotchedOutlineRoot aria-hidden {...other}>
            <NotchedOutlineLegend ownerState={ownerState}>
                {/* Use the nominal use case of the legend, avoid rendering artefacts. */}
                {label ? (
                    <span>{label}</span>
                ) : (
                    // notranslate needed while Google Translate will not fix zero-width space issue
                    // eslint-disable-next-line react/no-danger
                    <span className="notranslate" dangerouslySetInnerHTML={{ __html: '&#8203;' }} />
                )}
            </NotchedOutlineLegend>
        </NotchedOutlineRoot>
    );
};

export default NotchedOutline;
