import React, { useImperativeHandle, useRef } from 'react';
import { StaticDateRangeCalendar } from '../../../components';
import { refIsRequiredError } from '../../../util/utils';
import { parseDate } from '../../../util/moment';
/**
 * 多選天數
 */
const MultipleDayBox = React.forwardRef((props, ref) => {
    const datesRef = useRef(null);
    useImperativeHandle(
        ref,
        () => {
            return {
                getResult: () => {
                    const dates = datesRef.current.getResult();
                    return dates;
                },
                isError: () => refIsRequiredError(datesRef),
            };
        },
        // eslint-disable-next-line
        []
    );

    return <StaticDateRangeCalendar ref={datesRef} maxDate={parseDate()} />;
});

export default MultipleDayBox;
