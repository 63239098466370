import { useCallback } from 'react';
import useAjax from '@apis/useAjax';
import { useSnackbar } from 'notistack';

export const GET_CLUBS_STAMP_JPG_API = `/clubs/stamp.jpg`;
const GET_CLUBS_ME_API = `/api/clubs/me/`;
const PUT_CLUBS_CLUBID_API = (clubID) => `/api/clubs/${clubID}/`;
const GET_CLUBS_SETTING_API = `/api/clubs/setting/`;
const PUT_CLUBS_SETTING_API = GET_CLUBS_SETTING_API;
const GET_CLUBS_SUSPENSION_API = `/api/clubs/suspension/`;
const POST_CLUBS_SUSPENSION_API = GET_CLUBS_SUSPENSION_API;
const PUT_CLUBS_SUSPENSION_API = (id) => `/api/clubs/suspension/${id}/`;
const DELETE_CLUBS_SUSPENSION_API = PUT_CLUBS_SUSPENSION_API;

const useClubApi = () => {
    const Ajax = useAjax();
    const { enqueueSnackbar } = useSnackbar();

    // 取得登入使用者店舖
    const getClubInfomationDataApi = useCallback(
        async () => {
            let resp = await Ajax.get(GET_CLUBS_ME_API);
            if (!resp) {
                enqueueSnackbar('讀取店舖資料失敗', { variant: 'error' });
            }
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    // 取得登入使用者店舖
    const putClubInfomationDataApi = useCallback(
        async (params, clubID) => {
            let resp = await Ajax.put(PUT_CLUBS_CLUBID_API(clubID), params);
            if (resp) {
                enqueueSnackbar('成功修改店舖資料', { variant: 'success' });
            } else {
                enqueueSnackbar('修改店舖之料失敗', { variant: 'error' });
            }
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    // 讀取其他參數資料
    const getClubOthersParamsDataApi = useCallback(
        async () => {
            let resp = await Ajax.get(GET_CLUBS_SETTING_API);
            if (!resp) {
                enqueueSnackbar('讀取其他參數失敗', { variant: 'error' });
            }
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    // 修改其他參數資料
    const putClubOthersParamsDataApi = useCallback(
        async (params) => {
            let resp = await Ajax.put(PUT_CLUBS_SETTING_API, params);
            if (resp) {
                enqueueSnackbar('成功修改其他參數', { variant: 'success' });
            } else {
                enqueueSnackbar('修改其他參數失敗', { variant: 'error' });
            }
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    const getClubsSuspensionApi = useCallback(
        async (params) => {
            const resp = await Ajax.get(GET_CLUBS_SUSPENSION_API, params);
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    const postClubsSuspensionApi = useCallback(
        async (params) => {
            const resp = await Ajax.post(POST_CLUBS_SUSPENSION_API, params);
            if (!resp) {
                enqueueSnackbar('新增資料失敗，請再操作一次', { variant: 'error' });
            } else {
                enqueueSnackbar('新增成功', { variant: 'success' });
            }
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    const putClubsSuspensionApi = useCallback(
        async (params, suspensionID) => {
            const resp = await Ajax.put(PUT_CLUBS_SUSPENSION_API(suspensionID), params);
            if (!resp) {
                enqueueSnackbar('修改資料失敗，請再操作一次', { variant: 'error' });
            } else {
                enqueueSnackbar('修改成功', { variant: 'success' });
            }
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    const deleteClubsSuspensionApi = useCallback(
        async (suspensionID) => {
            const resp = await Ajax.delete(DELETE_CLUBS_SUSPENSION_API(suspensionID));
            if (!resp) {
                enqueueSnackbar('刪除資料失敗，請再操作一次', { variant: 'error' });
            } else {
                enqueueSnackbar('刪除成功', { variant: 'success' });
            }
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    return {
        getClubInfomationDataApi,
        putClubInfomationDataApi,
        getClubOthersParamsDataApi,
        putClubOthersParamsDataApi,
        getClubsSuspensionApi,
        postClubsSuspensionApi,
        putClubsSuspensionApi,
        deleteClubsSuspensionApi,
    };
};

export default useClubApi;
