import React, { useRef } from 'react';
import { Stack, Box, Chip, Typography } from '@common/components/';
import { ListInfo } from '@icoach/accountBook/components';
import Tablenization from '@icoach/components/Tablenization';
import { ACCOUNT_BOOK_ORDERS_ALLOWANCE_URL, ACCOUNT_BOOK_ORDERS_ORDER_URL } from '@icoach/router/AccountBookRouter';
import { COLOR_MATCH } from '@icoach/accountBook/staticData';
import { Link, useHistory } from 'react-router-dom';
import { parseDate } from '@util/moment';
import { formatCurrencyFn } from '@util/utils';
import { useAccountBookContext } from '@icoach/accountBook/components/AccountBookContext';
import { OrderMoreRowsLabel } from '@icoach/accountBook/tables/BatchPaymentOrdersCreditCardTable';

const rowsLabel = [
    {
        cellKey: 'orderNo',
        headerLabel: '訂單',
        align: 'center',
        width: '20',
        formatCell: (orderNo, { orderID, statusColor, statusText }) => {
            return (
                <Stack direction={'column'}>
                    <Link className={'mb-1'} to={ACCOUNT_BOOK_ORDERS_ORDER_URL(orderID)}>
                        {orderNo}
                    </Link>
                    <Chip label={statusText} color={COLOR_MATCH[statusColor]} title={'訂單狀態'} />
                </Stack>
            );
        },
    },

    {
        cellKey: 'receiverName',
        headerLabel: '購買人',
        width: '20',
        formatCell: (receiverName, { receiverTypeText }) => {
            return (
                <React.Fragment>
                    <Typography variant={'subtitle1'} className={'p-0 font-weight-bold'}>
                        {receiverName}
                    </Typography>
                    <Typography variant={'body2'} className={'mb-0 font-color-3'}>{`身份：${receiverTypeText}`}</Typography>
                </React.Fragment>
            );
        },
    },
    {
        cellKey: 'orderTypeText',
        headerLabel: '訂單類型',
        width: '12',
        align: 'center',
    },
    {
        cellKey: 'payablePrice',
        headerLabel: '訂單金額',
        align: 'right',
        width: '15',
        formatCell: (cellValue) => {
            return formatCurrencyFn(cellValue);
        },
    },
    {
        cellKey: 'orderDate',
        headerLabel: '建立日期',
        // align: 'center',
        formatCell: (cellValue) => {
            const _parseDate = (date, format) => {
                return parseDate(date, format, false);
            };
            return (
                <React.Fragment>
                    <Typography variant={'body1'} className={'mb-0'}>
                        {_parseDate(cellValue, 'YYYY/MM/DD')}
                    </Typography>
                    <Typography variant={'body1'} className={'mb-0 font-color-3'}>
                        {_parseDate(cellValue, 'HH:mm')}
                    </Typography>
                </React.Fragment>
            );
        },
    },
    { ...OrderMoreRowsLabel },
];

const AccountBookOrderOverviewTable = () => {
    const history = useHistory();
    const { tableData, orderSourceType, setBookmarkNum, refresh, dialogRef } = useAccountBookContext();
    const { list = [], totalCount, totalPage, pageIndex, pageSize } = tableData;
    const labelTitle = useRef(rowsLabel);

    const handlePageChange = (e, page) => setBookmarkNum(page);

    const refreshFn = refresh.current;

    const goToOrderPage = (orderID) => {
        history.push(ACCOUNT_BOOK_ORDERS_ORDER_URL(orderID));
    };

    const goToAccountBookPage = (orderID) => {
        history.push(ACCOUNT_BOOK_ORDERS_ALLOWANCE_URL(orderID));
    };

    const goToAllowanceRecord = (orderID) => {
        history.push(ACCOUNT_BOOK_ORDERS_ALLOWANCE_URL(orderID));
    };

    return (
        <Box>
            <Stack className={'mb-1'} alignItems={'center'} justifyContent={'space-between'}>
                <Typography className={'font-weight-bold'} variant={'h5'}>
                    {orderSourceType === '1' ? '現場訂單' : '批次訂單'}
                </Typography>
                <ListInfo
                    className={'ml-auto'}
                    pageIndex={pageIndex}
                    padeSize={pageSize}
                    totalCount={totalCount}
                    isCurrentPageFirstIndex
                    isCurrentPageLastIndex
                />
            </Stack>
            <Tablenization cardProps={{ className: 'theme-gray overview' }} count={totalPage} page={pageIndex} onChange={handlePageChange} isPagination>
                <Tablenization.TableRowHeader headerRow={labelTitle.current} />
                <Tablenization.TableRowBody
                    headerRow={labelTitle.current}
                    rowsData={list}
                    dialogRef={dialogRef}
                    goToOrderPage={goToOrderPage}
                    goToAccountBookPage={goToAccountBookPage}
                    goToAllowanceRecord={goToAllowanceRecord}
                    refresh={refreshFn}
                />
            </Tablenization>
        </Box>
    );
};

export default AccountBookOrderOverviewTable;
