import React, { useState, useEffect, useRef } from 'react';
import clsx from 'clsx';
import { Grid } from '@mui/material';
import { Typography, Popper, Fade, IconButton, Box } from '@common/components/';
import { ErrorCircle as ErrorCircleIcon, NavigateBefore as NavigateBeforeIcon, NavigateNext as NavigateNextIcon } from '@common/SvgIcon/';
import { getDiffDate, displayDateText } from '@util/moment';
import useMembershipApi from '@apis/useMembershipApi';
import { isEmpty } from '@util/utils';
import { CounterBox } from '@icoach/members/components/index';

const membershipStatusColor = {
    normal: '#a45bc8',
    gift: '#f06292',
    suspend: '#ff9800',
    compensate: '#66bb6a',
    out: '#bdbdbd',
    invalid: '#333333',
};

// 後端 rangeType 對應會籍狀態顏色
const getMembershipStatusColor = (type) => {
    switch (type) {
        case 1:
        case 54:
            return membershipStatusColor.normal;
        case 2:
            return membershipStatusColor.gift;
        case 11:
        case 12:
        case 13:
            return membershipStatusColor.suspend;
        case 3:
        case 23:
        case 24:
        case 25:
            return membershipStatusColor.compensate;
        case 51:
        case 52:
        case 53:
        case 55:
            return membershipStatusColor.out;
        default:
            return membershipStatusColor.invalid;
    }
};

const isLastIndex = (i, length) => i === length - 1;

const isFirstIndex = (i) => i === 0;

const MembershipInfoPopup = (props) => {
    const { open = false, anchorEl, membershipData, isPrev, isNext, onPrev, onNext, onClose } = props;
    const { typeName, startDate, endDate } = membershipData || {};

    if (!open) return null;
    return (
        <Popper open={open} anchorEl={anchorEl} transition style={{ zIndex: 10000 }}>
            {({ TransitionProps }) => (
                <Fade {...TransitionProps} timeout={350}>
                    <Box className="membership-progress-bar-tooltip text-center">
                        <IconButton className="btn-close" onClick={onClose}>
                            <ErrorCircleIcon />
                        </IconButton>
                        <Box className="membership-progress-bar-tooltip-wrap">
                            <IconButton className={'membership-progress-bar-tooltip-prev'} onClick={onPrev} disabled={isPrev}>
                                <NavigateBeforeIcon />
                            </IconButton>
                            <IconButton className={'membership-progress-bar-tooltip-next'} onClick={onNext} disabled={isNext}>
                                <NavigateNextIcon />
                            </IconButton>
                            <Typography variant="h6" className={'font-weight-bold'}>
                                {typeName}
                            </Typography>
                            <Typography variant="body1">{displayDateText(startDate, endDate)}</Typography>
                        </Box>
                    </Box>
                </Fade>
            )}
        </Popper>
    );
};

const MembershipProgressBar = (props) => {
    const { progressBarData, className } = props;
    const { ranges: membershipRecordProps, startDate, endDate, membershipTermText } = progressBarData || {};
    const [membershipRecord, setMembershipRecord] = useState(membershipRecordProps || []);
    const [currentIndex, setCurrentIndex] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const [barData, setBarData] = useState({
        barSlice: [],
        itemStyles: [],
        unfinished: {},
        unfinishedStyle: {},
        rangeHandleStyle: {},
    });
    const listRef = useRef([]);

    const today = new Date();
    const min = 0;
    const max = dayOf(endDate);
    const step = 1;
    const precision = 2;

    function dayOf(date) {
        return getDiffDate(date, startDate);
    }

    /**
     * 計算在指定範圍內的百分比。
     * @param {number} val - 要計算百分比的值。
     * @returns {number} - 值在指定範圍內的百分比，範圍由 min 和 max 定義。
     */
    function percentOf(val) {
        let percent = ((val - min) / (max - min)) * 100;

        // 如果計算得到的百分比為 NaN（不是數字）或小於等於 0，
        if (isNaN(percent) || percent <= 0) {
            // 返回 0，表示值在範圍中的最小百分比。
            return 0;
        }
        // 如果百分比大於等於 100，
        else if (percent >= 100) {
            // 返回 100，表示值在範圍中的最大百分比。
            return 100;
        }
        // 否則，返回計算得到的百分比。
        else {
            return percent;
        }
    }

    /**
     * 修正浮點數的小數部分。
     * @param {number} val - 待修正的浮點數。
     * @returns {number} - 修正後的浮點數。
     */
    function fixFloat(val) {
        // 將 val 轉換為數字，確保它是一個浮點數
        // 然後使用 toFixed 方法固定小數部分，小數位數由 precision 決定
        return parseFloat((+val).toFixed(precision));
    }

    /**
     * 限制數值在指定範圍內。
     * @param {number} val - 待限制的數值。
     * @returns {number} - 限制後的數值，不會小於最小值 min 且不會大於最大值 max。
     */
    function clampValue(val) {
        return val <= min ? min : val >= max ? max : val;
    }

    /**
     * 使數值對齊段落。
     * @param {number} val - 待對齊的數值。
     * @returns {number} - 對齊後的數值，依據段落 step 進行對齊，並限制在最小值 min 與最大值 max 之間。
     */
    function alignValueToStep(val) {
        if (val <= min) {
            return fixFloat(min);
        } else if (val >= max) {
            return fixFloat(max);
        } else {
            val = fixFloat(val);
        }

        // 計算 val 相對於最小值 min 的餘數
        let remainder = (val - min) % step;
        let aligned = val - remainder;
        // 若餘數的絕對值大於等於段長的一半，將 val 對齊下一個段落
        if (Math.abs(remainder) * 2 >= step) {
            aligned += remainder > 0 ? step : -step;
        }
        // 將對齊後的值限制在最小值與最大值之間
        aligned = clampValue(aligned);
        return fixFloat(aligned);
    }

    /**
     * 截取數值陣列中的前兩個元素 （開始與結束)。
     * @param {number[]} values - 待截取的數值陣列。
     * @returns {number[]} - 包含前兩個元素的新數值陣列。
     */
    function trimRange(values) {
        // 使用 slice 方法截取數值陣列中的前兩個元素
        return values.slice(0, 2);
    }

    /**
     * 获取指定日期的隔天日期。
     * @param {Date} date - 起始日期。
     * @returns {Date} - 表示隔天日期的 Date 对象。
     */
    function getNextDay(date) {
        const nextDay = new Date(date);

        // 获取隔天的日期，将日期加一天
        nextDay.setDate(nextDay.getDate() + 1);

        return nextDay;
    }

    /**
     * 檢查值是否超出指定的範圍。
     * @param {number} val - 待檢查的值。
     * @returns {boolean} - 如果值小於最小值（min）或大於最大值（max），返回 true；否則返回 false。
     */
    function isOverRange(val) {
        return val < min || val > max;
    }

    /**
     * 取得範圍的間隔寬度。
     * @param {number} rangeStart - 範圍的起始值。
     * @param {number} rangeEnd - 範圍的結束值。
     * @returns {number} - 範圍的間隔寬度。
     */
    function getStepWidth(rangeStart, rangeEnd) {
        // 計算範圍的步長寬度，即結束值減去起始值
        return fixFloat(rangeEnd - rangeStart);
    }

    const getItemStyle = (data) => {
        const unit = '%';
        if (!Array.isArray(data)) return [];
        return data.map(({ startDate, endDate }) => {
            const values = [dayOf(startDate), dayOf(getNextDay(endDate))];

            const trimmedAlignedValues = trimRange(values.map((v) => fixFloat(percentOf(alignValueToStep(v)))));
            return {
                left: trimmedAlignedValues[0] + unit,
                width: getStepWidth(trimmedAlignedValues[0], trimmedAlignedValues[1]) + unit,
            };
        });
    };

    const handleClick = (event, i) => {
        setCurrentIndex(i);
    };

    const handlePrev = () => setCurrentIndex(currentIndex - 1);

    const handleNext = (i) => setCurrentIndex(currentIndex + 1);

    const handleColes = (e) => {
        setTimeout(() => {
            setCurrentIndex(null);
        }, 350);
    };

    useEffect(
        () => {
            if (typeof currentIndex === 'number') {
                if (listRef.current) {
                    setAnchorEl(listRef.current[currentIndex]);
                }
            } else {
                setAnchorEl(null);
            }
        },
        // eslint-disable-next-line
        [currentIndex],
    );

    useEffect(
        () => {
            if (!membershipRecord) return null;
            const todayValue = dayOf(today);
            const unfinishedRange = { startDate: getNextDay(today), endDate };
            const unfinishedStyle = getItemStyle([unfinishedRange])[0];
            const itemStyles = getItemStyle(membershipRecord);
            let rangeHandleStyle = { display: 'none' };

            // "現在"箭頭位置
            if (!isOverRange(todayValue)) {
                const todayRange = getStepWidth(
                    fixFloat(percentOf(alignValueToStep(todayValue))),
                    fixFloat(percentOf(alignValueToStep(dayOf(getNextDay(today))))),
                );
                const rangeHandleOffset = fixFloat(todayRange / 2);
                let rangeHandlePosition = fixFloat(percentOf(alignValueToStep(todayValue)));
                rangeHandlePosition = rangeHandlePosition + rangeHandleOffset;
                rangeHandleStyle = { left: rangeHandlePosition + '%' };
            }

            setBarData((prevState) => ({
                ...prevState,
                barSlice: membershipRecord,
                itemStyles,
                unfinishedStyle,
                rangeHandleStyle,
            }));
            return () => {
                setAnchorEl(null);
                setCurrentIndex(null);
            };
        },
        // eslint-disable-next-line
        [membershipRecord],
    );

    useEffect(
        () => {
            if (!membershipRecordProps) return null;
            setMembershipRecord(membershipRecordProps);
        },
        // eslint-disable-next-line
        [membershipRecordProps],
    );

    return (
        <React.Fragment>
            {barData.barSlice && (
                <Box className={clsx('membership-progress-bar-box', className)}>
                    <Typography variant="body2" className="date date-start">
                        {membershipTermText && <span>{membershipTermText}</span>}
                        {displayDateText(startDate)}
                    </Typography>
                    <Typography variant="body2" className="date date-end">
                        {displayDateText(endDate)}
                    </Typography>
                    <ul className="membership-progress-bar">
                        {barData.barSlice.map(({ type: rangeType, isShowToolTip }, i) => {
                            return (
                                <li
                                    key={i}
                                    ref={(el) => (listRef.current[i] = el)}
                                    style={{
                                        ...barData.itemStyles[i],
                                        backgroundColor: getMembershipStatusColor(rangeType),
                                    }}
                                    className={clsx('membership-progress-bar-slice', {
                                        'is-focus': isShowToolTip && i === currentIndex,
                                        'has-pointer': isShowToolTip,
                                    })}
                                    onClick={(e) => {
                                        isShowToolTip && handleClick(e, i);
                                    }}
                                />
                            );
                        })}
                        <li className="bar-today-arrow" style={barData.rangeHandleStyle} />
                        <li className="unfinished" style={barData.unfinishedStyle} />
                    </ul>
                    <MembershipInfoPopup
                        open={Boolean(anchorEl)}
                        membershipData={typeof currentIndex === 'number' && membershipRecord[currentIndex]}
                        anchorEl={anchorEl}
                        isPrev={isFirstIndex(currentIndex)}
                        isNext={isLastIndex(currentIndex, membershipRecord?.length ?? 0)}
                        onPrev={handlePrev}
                        onNext={handleNext}
                        onClose={handleColes}
                    />
                </Box>
            )}
        </React.Fragment>
    );
};

export const MembershipProgressBarController = (props) => {
    const { className, onPrev, onNext, currentIndex, memberID, membershipData } = props;
    const { getMemberMembershipNonUseSuspendApi } = useMembershipApi();
    const [suspendInfo, setSuspendInfo] = useState(null);
    const barData = !isEmpty(membershipData[currentIndex]) && membershipData[currentIndex].barRanges;
    const count = membershipData ? membershipData.length : 0;

    const handlePrev = () => typeof onPrev === 'function' && onPrev();

    const handleNext = (i) => typeof onNext === 'function' && onNext();

    const nonUseSuspendApi = async (mID, msID) => {
        const resp = await getMemberMembershipNonUseSuspendApi(mID, msID);
        if (resp) {
            setSuspendInfo(resp);
        } else {
            setSuspendInfo(null);
        }
    };

    useEffect(
        () => {
            if (membershipData[currentIndex]?.isCurrent) {
                nonUseSuspendApi(memberID, membershipData[currentIndex].membershipID);
            }
        },
        // eslint-disable-next-line
        [membershipData[currentIndex]?.isCurrent ?? null],
    );

    const { unusedMonth = 0, unusedDay = 0, usedMonth = 0, usedDay = 0 } = suspendInfo || {};

    const usedText = usedMonth > 0 && usedDay > 0 ? `${usedMonth}月${usedDay}天` : usedMonth > 0 ? `${usedMonth}個月` : usedDay > 0 ? `${usedDay}天` : '-';

    const unusedText =
        unusedMonth > 0 && unusedDay > 0
            ? `${unusedMonth}月${unusedMonth}天`
            : unusedMonth > 0
              ? `${unusedMonth}個月`
              : unusedDay > 0
                ? `${unusedMonth}天`
                : '-';

    return (
        <React.Fragment>
            <Box className={clsx('membership-progress-bar-controller-box', className)}>
                <Box className="membership-progress-bar-controller prev">
                    <IconButton onClick={handlePrev} disabled={isFirstIndex(currentIndex)}>
                        <NavigateBeforeIcon />
                    </IconButton>
                </Box>
                <Box className="membership-progress-bar-controller next">
                    <IconButton onClick={handleNext} disabled={isLastIndex(currentIndex, count)}>
                        <NavigateNextIcon />
                    </IconButton>
                </Box>
                {!isEmpty(membershipData) && <MembershipProgressBar progressBarData={barData} />}
            </Box>
            {suspendInfo && (
                <Grid container spacing={2} columns={6}>
                    <Grid item xs={1}>
                        <CounterBox title="已停扣天數" className="counter-box-sm" counter={usedText} />
                    </Grid>
                    <Grid item xs={1}>
                        <CounterBox title="待停扣天數" className="counter-box-sm" counter={unusedText} />
                    </Grid>
                </Grid>
            )}
        </React.Fragment>
    );
};

export default MembershipProgressBar;
