import React from 'react';
import SvgIcon from '../SvgIcon';
import useForkRef from '@util/hook/useForkRef';

const UserSlash = React.forwardRef((props, ref) => {
    const { className, ...others } = props;
    const handleRef = React.useRef(null);
    const svgRef = useForkRef(handleRef, ref);
    const defaultProperty = {
        className,
        ref: svgRef,
        ...others,
    };
    return (
        <SvgIcon {...defaultProperty}>
            <path
                d="M23.768,17.179,13.586,9.311A4.788,4.788,0,1,0,7.241,4.4L1.707.127a.6.6,0,0,0-.844.1l-.735.945a.6.6,0,0,0,.1.84L22.3,19.069a.6.6,0,0,0,.844-.105l.735-.949a.594.594,0,0,0-.109-.836ZM3.6,15.84V17.4a1.8,1.8,0,0,0,1.8,1.8H18.533L7.778,10.886A5.033,5.033,0,0,0,3.6,15.84Z"
                transform="translate(-0.001 2)"
            />
        </SvgIcon>
    );
});

export default UserSlash;
