import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

const variantMap = ['rounded', 'square'];
const staticClass = `avatar`;

const useLoaded = ({ crossOrigin, referrerPolicy, src, srcSet }) => {
    const [loaded, setLoaded] = React.useState(false);
    React.useEffect(() => {
        if (!src && !srcSet) {
            return undefined;
        }

        setLoaded(false);

        let active = true;
        const image = new Image();
        image.onload = () => {
            if (!active) {
                return;
            }
            setLoaded('loaded');
        };
        image.onerror = () => {
            if (!active) {
                return;
            }
            setLoaded('error');
        };
        image.crossOrigin = crossOrigin;
        image.referrerPolicy = referrerPolicy;
        image.src = src;
        if (srcSet) {
            image.srcset = srcSet;
        }

        return () => {
            active = false;
        };
    }, [crossOrigin, referrerPolicy, src, srcSet]);

    return loaded;
};

const AvatarImg = (props) => {
    const { alt, className, src } = props;
    const defaultProperty = {
        className: clsx('avatar-img', className),
        src,
    };
    return <img {...defaultProperty} alt={alt} />;
};

const AvatarFallback = (props) => {
    return (
        <svg viewBox="0 0 24 24" className={clsx('avatar-fallback')}>
            <path d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z" />
        </svg>
    );
};

const Avatar = React.forwardRef((props, ref) => {
    const {
        className: classNameProps,
        alt,
        children: childrenProp,
        imgProps,
        sizes,
        src,
        srcSet,
        variant = 'circular',
        width,
        isIcon = false,
        style: styleProps = {},
        ...others
    } = props;

    let children = null;
    let classNames = clsx({ [`${staticClass}-root`]: !isIcon });
    let styles = { ...styleProps };
    // Use a hook instead of onError on the img element to support server-side rendering.
    const loaded = useLoaded({ ...imgProps, src, srcSet });
    const hasImg = src;
    const hasImgNotFailing = hasImg && loaded !== 'error';

    if (variantMap.indexOf(variant) !== -1)
        classNames = clsx(classNames, `${staticClass}-${variant}`);
    if (!hasImgNotFailing) {
        classNames = clsx(classNames, {
            [`${staticClass}-color-default`]: !isIcon,
        });
    }

    if (hasImgNotFailing) {
        children = <AvatarImg alt={alt} src={src} sizes={sizes} {...imgProps} />;
    } else if (childrenProp != null) {
        children = childrenProp;
    } else if (!hasImg && alt) {
        children = alt[0];
    } else {
        children = <AvatarFallback />;
    }
    if (width) {
        styles.width = `${width * 1}rem`;
        styles.height = `${width * 1}rem`;
        styles.fontSize = `${width * 0.5}rem`;
    }

    const defaultProperty = {
        className: clsx(classNames, classNameProps),
        style: { ...styles },
        ref,
        ...others,
    };

    return <div {...defaultProperty}>{children}</div>;
});

Avatar.propTypes /* remove-proptypes */ = {
    // ----------------------------- Warning --------------------------------
    // | These PropTypes are generated from the TypeScript type definitions |
    // |     To update them edit the d.ts file and run "yarn proptypes"     |
    // ----------------------------------------------------------------------
    /**
     * Used in combination with `src` or `srcSet` to
     * provide an alt attribute for the rendered `img` element.
     */
    alt: PropTypes.string,
    /**
     * Used to render icon or text elements inside the Avatar if `src` is not set.
     * This can be an element, or just a string.
     */
    children: PropTypes.node,
    /**
     * @ignore
     */
    className: PropTypes.string,
    /**
     * The component used for the root node.
     * Either a string to use a HTML element or a component.
     */
    component: PropTypes.elementType,
    /**
     * [Attributes](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/img#attributes) applied to the `img` element if the component is used to display an image.
     * It can be used to listen for the loading error event.
     */
    imgProps: PropTypes.object,
    /**
     * The `sizes` attribute for the `img` element.
     */
    sizes: PropTypes.string,
    /**
     * The `src` attribute for the `img` element.
     */
    src: PropTypes.string,
    /**
     * The `srcSet` attribute for the `img` element.
     * Use this attribute for responsive image display.
     */
    srcSet: PropTypes.string,
    /**
     * The shape of the avatar.
     * @default 'circular'
     */
    variant: PropTypes.oneOfType([
        PropTypes.oneOf(['circular', 'rounded', 'square']),
        PropTypes.string,
    ]),
};

export default Avatar;
