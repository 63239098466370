import React from 'react';
import { Button } from '../../../components';
import useInBody from './useInBody';

const InBodyDeleteMeasure = React.forwardRef((props, ref) => {
    const { sourceData, measureID } = props;
    const { dialogRef } = useInBody();
    const handleOnClick = () => {
        dialogRef.current.openDeleteMeasureDialog(sourceData, measureID);
    };
    return (
        <React.Fragment>
            <Button variant="outlined" onClick={handleOnClick}>
                刪除
            </Button>
        </React.Fragment>
    );
});

export default InBodyDeleteMeasure;
