import React, { useState, useEffect, useRef } from 'react';
import _ from 'lodash';
import { useParams, useHistory } from 'react-router-dom';
import TemporariesInfoTable from '@icoach/nonMember/temporary/TemporariesInfoTable';
import TemporariesInfoHeader from '@icoach/nonMember/temporary/TemporariesInfoHeader';
import NonMemberContext from '@icoach/nonMember/components/NonMemberContext';
import { initBookmark, customerTypeMap } from '@icoach/nonMember/staticData';
import { NON_MEMBER_TEMPORARIES_OVERVIEW_ROUTER } from '@icoach/router/NonMemberRouter';
import useTemporaryApi from '@apis/useTemporaryApi';
import { Card } from '@common/components/';

const TemporariesInfoPage = (props) => {
    const history = useHistory();
    const { id: temporaryID, entity: pathRouter } = useParams();
    const { postTemporariesIDOverviewApi } = useTemporaryApi();
    const refreshRef = useRef(() => {});
    const [sourceData, setSourceData] = useState({});
    const [isMounted, setMounted] = useState(false);
    const [bookmark, setBookmark] = useState({
        ...initBookmark,
    });

    const doInitDataApi = async () => {
        const customerType = customerTypeMap[pathRouter];
        const resp = await postTemporariesIDOverviewApi(temporaryID, customerType, { ...bookmark });
        if (resp) {
            setSourceData({ ...resp });
            setMounted(true);
        }
    };

    useEffect(() => {
        if (!_.isInteger(temporaryID * 1)) history.push(NON_MEMBER_TEMPORARIES_OVERVIEW_ROUTER);
        else doInitDataApi();
        return () => setMounted(false);
        // eslint-disable-next-line
    }, []);

    const { temporaryBase = {}, overviews = {} } = sourceData;

    const childContext = {
        bookmark: bookmark,
        refresh: refreshRef,
        setBookmarkNum: (num) => setBookmark((pre) => ({ ...pre, pageIndex: num })),
        title: temporaryBase.temporaryName,
    };
    refreshRef.current = doInitDataApi;

    return (
        <NonMemberContext.Provider value={childContext}>
            {isMounted && (
                <div className="main-container-spacing container">
                    <Card>
                        <Card.CardContent>
                            <TemporariesInfoHeader sourceData={{ temporaryID, ...temporaryBase }} />
                        </Card.CardContent>
                    </Card>
                    <div className="non-member-content">
                        <TemporariesInfoTable sourceData={overviews} type={pathRouter} refresh={refreshRef} />
                    </div>
                </div>
            )}
        </NonMemberContext.Provider>
    );
};

export default TemporariesInfoPage;
