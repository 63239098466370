import React from 'react';
import { Grid } from '@mui/material';
import { Stack, Skeleton, Card, Divider } from '@common/components/';

const FieldSkeletonBox = (props) => {
    return (
        <Stack direction="column" style={{ flexGrow: 1 }}>
            <Skeleton height={30} width={80} />
            <Skeleton height={40} />
        </Stack>
    );
};

// 預Load畫面
const AppointmentInformationDataSkeleton = () => {
    return (
        <React.Fragment>
            <Grid xs={9} item>
                <Stack spacing={2} direction="column">
                    <Card className="p-3" square>
                        <Stack className="pb-2" spacing={2}>
                            <Skeleton height={40} width={120} />
                            <Skeleton height={40} width={160} />
                        </Stack>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <Stack spacing={3}>
                                    <Skeleton variant="circular" height={80} width={80} />
                                    <FieldSkeletonBox />
                                </Stack>
                            </Grid>
                            {Array(8)
                                .fill()
                                .map((_t, _i) => (
                                    <Grid item xs={3} key={_i}>
                                        <FieldSkeletonBox />
                                    </Grid>
                                ))}
                            <Grid item xs={6}>
                                <FieldSkeletonBox />
                            </Grid>
                        </Grid>
                    </Card>
                    <Card className="p-3" square>
                        <Stack className="pb-2" spacing={2}>
                            <Skeleton height={40} width={120} />
                        </Stack>
                        <Card isOverflowHidden className={'contact-record-card'}>
                            <Card.CardContent>
                                <Stack justifyContent="space-between" className="pt-2 px-2">
                                    <span className="contact-record-card-markbook">0000-00-00</span>
                                    <div>
                                        <Skeleton height={60} width={100} />
                                    </div>
                                </Stack>
                                <Stack className="px-3 py-3" spacing={2}>
                                    {Array(3)
                                        .fill()
                                        .map((_t, _i) => (
                                            <Stack
                                                className="overflow-hidden info-item"
                                                spacing={2}
                                                key={_i}
                                            >
                                                <Skeleton height={40} width={50} />
                                                <Skeleton height={40} width={120} />
                                            </Stack>
                                        ))}
                                </Stack>
                                <Divider />
                                <Stack className="px-3 py-3" spacing={2} direction="column">
                                    <Skeleton height={40} width={120} />
                                    <Skeleton height={40} />
                                </Stack>
                            </Card.CardContent>
                        </Card>
                    </Card>
                </Stack>
            </Grid>
            <Grid xs={3} item>
                <Card className="p-3" square>
                    <Skeleton height={40} width={120} />
                    <Stack spacing={2}>
                        <Skeleton height={80} width={40} />
                        <Skeleton height={80} width={40} />
                    </Stack>
                    <Divider />
                    <Skeleton height={40} width={120} />
                    <FieldSkeletonBox />
                </Card>
            </Grid>
        </React.Fragment>
    );
};

export default AppointmentInformationDataSkeleton;
