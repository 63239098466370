import { useCallback } from 'react';
import useAjax from '@apis/useAjax';
import { useSnackbar } from 'notistack';

const GET_REMITTANCE_ALLOWANCE_ID_API = (orderID) => `/api/remittance/${orderID}/allowance-dialog/`;
const POST_REMITTANCE_ALLOWANCE_CONFIRM_API = `/api/remittance/allowance-confirm/`;
const GET_REMITTANCE_INVALID_ABANDON_ID_API = (orderID) => `/api/remittance/${orderID}/invalid-dialog/`;
const POST_REMITTANCE_INVALID_ABANDON_CONFIRM_API = `/api/remittance/invalid-confirm/`;
const GET_REMITTANCE_ABANDON_ALLOWANCE_ID_API = (orderID) => `/api/remittance/${orderID}/allowance-history/`;
const POST_REMITTANCE_ABANDON_ALLOWANCE_CONFIRM_API = `/api/remittance/invalid-allowance-confirm/`;

const useClubRemittanceReturnApi = () => {
    const Ajax = useAjax();
    const { enqueueSnackbar } = useSnackbar();

    // 訂單明細(主單(退單清單)、付款資訊、購買清單(退貨內容)、訂單紀錄)
    const getRemittanceAllowanceApi = useCallback(
        async (orderID) => {
            let resp = await Ajax.get(GET_REMITTANCE_ALLOWANCE_ID_API(orderID));
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    // 發送折讓內容及項目
    const postRemittanceAllowanceConfirmApi = useCallback(
        async (parameters) => {
            let resp = await Ajax.post(POST_REMITTANCE_ALLOWANCE_CONFIRM_API, parameters);
            resp && enqueueSnackbar('發送成功', { variant: 'success' });
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    // 取得需要一併開立發票的訂單內全部的作廢項目
    const getRemittanceInvalidAbandonApi = useCallback(
        async (orderID) => {
            let resp = await Ajax.get(GET_REMITTANCE_INVALID_ABANDON_ID_API(orderID));
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    // 發送作廢發票內容及項目
    const postRemittanceInvalidAbandonConfirmApi = useCallback(
        async (parameters) => {
            let resp = await Ajax.post(POST_REMITTANCE_INVALID_ABANDON_CONFIRM_API, parameters);
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    // 取得已折讓清單，準備執行折讓作廢
    const getRemittanceAbandonAllowanceApi = useCallback(
        async (orderID) => {
            let resp = await Ajax.get(GET_REMITTANCE_ABANDON_ALLOWANCE_ID_API(orderID));
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    // 發送作廢折讓
    const postRemittanceAbandonAllowanceConfirmApi = useCallback(
        async (params) => {
            let resp = await Ajax.post(POST_REMITTANCE_ABANDON_ALLOWANCE_CONFIRM_API, params);
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    return {
        getRemittanceAllowanceApi,
        postRemittanceAllowanceConfirmApi,
        getRemittanceInvalidAbandonApi,
        postRemittanceInvalidAbandonConfirmApi,
        getRemittanceAbandonAllowanceApi,
        postRemittanceAbandonAllowanceConfirmApi,
    };
};

export default useClubRemittanceReturnApi;
