import React, { useRef, useImperativeHandle, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { Grid } from '@mui/material';
import IntervalTimerBox from '@icoach/nonMember/components/IntervalTimerBox';
import { DateField, SelectField, MenuItem, Stack, TextField, Button, IconButton } from '@common/components/';
import { Plus as PlusIcon, Cancel as CancelIcon } from '@common/SvgIcon/';
import { refIsRequiredError } from '@util/utils';
import { parseDate, getDateAddTime, getCurrentTimeSecond } from '@util/moment';

const MutipleAddTemplate = React.memo(
    React.forwardRef((props, ref) => {
        const { options, isAddMode = false, originPosition, onDelete: onDeleteProps, sourceData = {} } = props;
        const { customerManufactureType = 0, notes = '', position = '', quantity = 0 } = sourceData;
        const typeRef = useRef(null);
        const positionRef = useRef(null);
        const numRef = useRef(null);
        const noteRef = useRef(null);

        useImperativeHandle(
            ref,
            () => {
                return {
                    isError: () => refIsRequiredError(typeRef, positionRef, numRef, noteRef),
                    getResult: () => ({
                        customerManufactureType: typeRef.current.getResult(),
                        position: positionRef.current.getResult(),
                        quantity: numRef.current?.getResult() ?? 0,
                        notes: noteRef.current.getResult(),
                    }),
                };
            },
            // eslint-disable-next-line
            []
        );

        return (
            <React.Fragment>
                <Grid item xs={3}>
                    {options.length !== 0 && (
                        <SelectField label="方式" ref={typeRef} defaultValue={customerManufactureType} displayEmpty fullWidth required>
                            <MenuItem disabled value="">
                                <em>請選擇</em>
                            </MenuItem>
                            {options.map(({ text, value }) => (
                                <MenuItem value={value} key={`start-time-${value}`}>
                                    {text}
                                </MenuItem>
                            ))}
                        </SelectField>
                    )}
                </Grid>
                <Grid item xs={3}>
                    <TextField ref={positionRef} label="地點" defaultValue={isAddMode ? originPosition || '' : position} fullWidth required />
                </Grid>
                <Grid item xs={3}>
                    <TextField ref={numRef} type={'number'} inputProps={{ min: 0 }} label="數量/時數/家數" defaultValue={quantity} fullWidth required />
                </Grid>
                {isAddMode && (
                    <Grid item xs={3}>
                        <Stack justifyContent="end">
                            <IconButton onClick={onDeleteProps}>
                                <CancelIcon />
                            </IconButton>
                        </Stack>
                    </Grid>
                )}
                <Grid item xs={12}>
                    <TextField ref={noteRef} label="備註" defaultValue={notes} multiline rows={3} fullWidth />
                </Grid>
            </React.Fragment>
        );
    })
);

const GuestProductionForm = React.forwardRef((props, ref) => {
    const { onClick: onClickProps, options, sourceData, isAddMode = false } = props;
    const [mutipleNum, setMutipleNum] = useState([]);
    const startDateRef = useRef(null);
    const intervalTimeRef = useRef(null);
    const coachRef = useRef(null);
    const baseTemplateRef = useRef(null);
    const mutipleTemplateRef = useRef({});

    const { startTime, endTime, employeeID = 0 } = sourceData;

    const _startDate = parseDate(startTime, 'YYYY-MM-DD');
    const _startTime = getCurrentTimeSecond(startTime);
    const _endTime = getCurrentTimeSecond(endTime);

    const getParams = () => {
        const intervalTime = intervalTimeRef.current.getResult();
        const targetDate = startDateRef.current.getResult();
        const startTime = getDateAddTime(targetDate, intervalTime.startTime);
        const endTime = getDateAddTime(targetDate, intervalTime.endTime);
        const templateData = baseTemplateRef.current.getResult();
        const regularParams = { startTime, endTime, employeeID: coachRef.current.getResult() };
        let paramsJson = {
            ...regularParams,
            ...templateData,
        };
        if (isAddMode) {
            let mutipleParams = [...mutipleNum].map((target) => {
                const targetData = mutipleTemplateRef.current[target]?.getResult() ?? {};
                return { ...regularParams, ...targetData };
            });
            paramsJson = [{ ...paramsJson }].concat(mutipleParams);
        }
        return paramsJson;
    };

    const getPositionValue = () => {
        if (baseTemplateRef.current && baseTemplateRef.current.getResult()) {
            const templateData = baseTemplateRef.current.getResult();
            return templateData.position;
        }
        return '';
    };

    const handleDeleteMutipleEvent = (uuID) => (_e) => {
        setMutipleNum((prev) => [...prev].filter((ele) => ele !== uuID));
    };

    useImperativeHandle(
        ref,
        () => ({
            isError: () => {
                return refIsRequiredError(startDateRef, intervalTimeRef, coachRef, baseTemplateRef, mutipleTemplateRef);
            },
            getResult: () => getParams(),
        }),
        // eslint-disable-next-line
        [mutipleNum, isAddMode]
    );
    const { saleTypeOption = [], coachsOption = [] } = options;
    return (
        <Grid container spacing={2}>
            <Grid item xs={3}>
                {coachsOption.length !== 0 && (
                    <SelectField label="擔當" ref={coachRef} defaultValue={employeeID} displayEmpty fullWidth required>
                        <MenuItem disabled value="">
                            <em>請選擇</em>
                        </MenuItem>
                        {coachsOption.map(({ text, value }) => (
                            <MenuItem value={value} key={`start-time-${value}`}>
                                {text}
                            </MenuItem>
                        ))}
                    </SelectField>
                )}
            </Grid>
            <Grid item xs={3}>
                <DateField ref={startDateRef} defaultValue={_startDate || new Date()} label="日期" fullWidth required />
            </Grid>
            <Grid item xs={6}>
                <IntervalTimerBox startTime={_startTime} endTime={_endTime} ref={intervalTimeRef} />
            </Grid>
            {saleTypeOption.length !== 0 && <MutipleAddTemplate ref={baseTemplateRef} options={saleTypeOption} sourceData={sourceData} />}
            {isAddMode &&
                mutipleNum.length !== 0 &&
                mutipleNum.map((targetUUID) => (
                    <MutipleAddTemplate
                        key={targetUUID}
                        ref={(el) => (mutipleTemplateRef.current[targetUUID] = el)}
                        onDelete={handleDeleteMutipleEvent(targetUUID)}
                        options={saleTypeOption}
                        originPosition={getPositionValue()}
                        isAddMode
                    />
                ))}
            {isAddMode && (
                <Grid item xs={12}>
                    <Button onClick={() => setMutipleNum((prev) => [...prev, uuid()])} variant="outlined" startIcon={<PlusIcon />}>
                        新增一筆顧客製造
                    </Button>
                </Grid>
            )}
            <Grid item xs={12}>
                <Stack justifyContent="end">
                    <Button onClick={onClickProps} variant="contained">
                        送出
                    </Button>
                </Stack>
            </Grid>
        </Grid>
    );
});

export default GuestProductionForm;
