import { useCallback } from 'react';
import useAjax from '@apis/useAjax';
import { checkResponse } from '@apis/utils';

const GET_MEMBERS_GIFT_ITEM_API = (memberID) => `/api/members/${memberID}/gift-item/records/`;

const useMemberGiftItemApi = () => {
    const Ajax = useAjax();

    const getMemberGiftItemApi = useCallback(
        async (memberID) => {
            const resp = await Ajax.get(GET_MEMBERS_GIFT_ITEM_API(memberID));
            if (checkResponse(resp)) return resp;
            return false;
        },
        // eslint-disable-next-line
        []
    );

    return {
        getMemberGiftItemApi,
    };
};

export default useMemberGiftItemApi;
