import React, { useMemo } from 'react';
import { TodoList } from '@icoach/members/components/';
import { useMemberProfileTasksApi } from '@apis/useMemberProfileApi';
import { attentionArray } from '@icoach/landingPage/checkin/CheckinTable';

const handleTodoObj = (todo) => {
    let { taskID, taskTitle, reminderConditionType, ...other } = todo;
    const reminderConditionIcon = [...attentionArray];
    const icon = reminderConditionIcon.find((item) => item.id === reminderConditionType)?.Icon;
    return {
        id: taskID,
        text: taskTitle,
        icon,
        reminderConditionType,
        ...other,
    };
};

const handleTaskData = (data) => {
    if (!Array.isArray(data)) return [];
    return data.map((item) => {
        return handleTodoObj(item);
    });
};

const TaskTodo = React.memo((props) => {
    const { memberID, taskType, title, className: classNameProps, data, variant, hasIcon, options, refresh } = props;

    const { postMemberProfileTasksApi, putMemberProfileTasksApi, deleteMemberProfileTasksApi } = useMemberProfileTasksApi();
    let todoData = useMemo(() => handleTaskData(data), [data]);

    const handleAddTodoTasks = async (todo) => {
        const result = {
            taskType,
            taskID: 0,
            taskTitle: todo.text,
        };
        let data = await postMemberProfileTasksApi(memberID, result);
        if (data) {
            typeof refresh === 'function' && refresh();
            data = handleTodoObj(data);
        }
        return data;
    };

    const handleUpdateTodoTasks = async ({ reminderConditionType, isCompleted, id: taskID }) => {
        const result = {
            taskType,
            taskID,
            reminderConditionType,
            isCompleted,
        };
        const resp = await putMemberProfileTasksApi(memberID, result);
        if (resp) {
            typeof refresh === 'function' && refresh();
        }
        return resp;
    };

    const handleDeleteTodoTasks = async (todoID) => {
        const resp = await deleteMemberProfileTasksApi(memberID, todoID);
        if (resp) {
            typeof refresh === 'function' && refresh();
        }
        return resp;
    };

    return (
        <TodoList
            data={todoData}
            onAddTodoApi={handleAddTodoTasks}
            onCompleteApi={handleUpdateTodoTasks}
            onDeleteItemApi={handleDeleteTodoTasks}
            title={title}
            className={classNameProps}
            variant={variant}
            hasIcon={hasIcon}
            options={options}
        />
    );
});

export default TaskTodo;
