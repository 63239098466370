import React from 'react';
import SvgIcon from '../SvgIcon';
import useForkRef from '@util/hook/useForkRef';

const ProbioticsSuspended = React.forwardRef((props, ref) => {
    const { className, htmlColor = '#D02952', ...others } = props;
    const handleRef = React.useRef(null);
    const svgRef = useForkRef(handleRef, ref);
    const defaultProperty = {
        className,
        ref: svgRef,
        htmlColor,
        ...others,
    };
    return (
        <SvgIcon {...defaultProperty}>
            <path
                d="M12,0C5.4,0,0,5.4,0,12s5.4,12,12,12s12-5.4,12-12S18.6,0,12,0z M12,22.9C6,22.9,1.1,18,1.1,12
		C1.1,6,6,1.1,12,1.1C18,1.1,22.9,6,22.9,12S18,22.9,12,22.9z"
            />
            <path
                d="M16.2,11.9L14,10.3c-0.2-0.1-0.4-0.1-0.5-0.1c-0.2,0-0.4,0.1-0.5,0.3l-2,2.9c-0.2,0.3-0.1,0.8,0.2,1l2.2,1.5
		c0.5,0.4,1.2,0.5,1.9,0.4c0.6-0.1,1.2-0.5,1.6-1.1C17.6,14.2,17.3,12.6,16.2,11.9z"
            />
            <path
                d="M8.5,11.1c-0.6-0.3-0.9-0.9-0.8-1.6c0.1-0.3-0.1-0.6-0.4-0.7C7,8.7,6.6,8.9,6.5,9.3c-0.2,1.2,0.3,2.3,1.3,2.9
		l1.5,1c0.3,0.2,0.7,0.1,0.9-0.2c0.1-0.1,0.1-0.2,0.1-0.3c0-0.2-0.1-0.4-0.3-0.5L8.5,11.1z"
            />
            <path
                d="M12,2.2c-5.4,0-9.8,4.4-9.8,9.8s4.4,9.8,9.8,9.8s9.8-4.4,9.8-9.8S17.4,2.2,12,2.2z M17.9,16.1
		c-1.2,1.7-3.5,2.1-5.2,0.9l-5.6-3.8c-0.8-0.6-1.4-1.4-1.6-2.4c-0.4-2,0.9-4,2.9-4.4c1-0.2,2,0,2.8,0.6l5.6,3.8
		c0.1,0,0.1,0.1,0.2,0.1C18.7,12.1,19.1,14.5,17.9,16.1z"
            />
        </SvgIcon>
    );
});

export default ProbioticsSuspended;
