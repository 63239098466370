import React, { useState, useRef, useImperativeHandle } from 'react';
import OneDayBox from './CheckinOneDayBox';
import MultipleDayBox from './CheckinMultipleDayBox';
import BatchDayBox from './CheckinBatchDayBox';
import { Stack, Tabs, Tab } from '../../../components';
import { refIsRequiredError } from '../../../util/utils';

const ClockInTabs = (props) => {
    const { value: propsValue = 'oneDay', handleTabsValue = () => {} } = props;
    const handleChange = (event, newValue) => {
        handleTabsValue && handleTabsValue(newValue);
    };
    return (
        <Stack justifyContent={'space-between'} alignItems={'end'}>
            <Tabs
                value={propsValue}
                onChange={handleChange}
                variant="scrollable"
                allowScrollButtonsMobile
            >
                {tabsContent.map(({ label, value }) => (
                    <Tab key={label} label={label} value={value} />
                ))}
            </Tabs>
        </Stack>
    );
};

const tabsContent = [
    { label: '單日簽到', value: 'oneDay', Component: OneDayBox },
    { label: '多選簽到', value: 'multipleDay', Component: MultipleDayBox },
    { label: '批次簽到', value: 'batchDay', Component: BatchDayBox },
];

const CheckinMultipleDateTimeBox = React.forwardRef((props, ref) => {
    const [tabValue, setTabValue] = useState('oneDay');
    const compRef = useRef(null);
    const Component = tabsContent.find(({ value }) => value === tabValue)['Component'];

    useImperativeHandle(
        ref,
        () => {
            return {
                getResult: () => compRef.current.getResult(),
                isError: () => refIsRequiredError(compRef),
            };
        },
        // eslint-disable-next-line
        []
    );

    return (
        <Stack direction="column" spacing={2}>
            <ClockInTabs value={tabValue} handleTabsValue={setTabValue} />
            <Component ref={compRef} />
        </Stack>
    );
});

export default CheckinMultipleDateTimeBox;
