import React, { useEffect, useRef, useState } from 'react';
import { isEmpty } from 'lodash';
import { Redirect, Switch, Route } from 'react-router-dom';
import { Grid } from '@mui/material';
import { Box, PrivateRoute } from '../../../components';
import MemberDetailSummary from './MemberDetailSummary';
import MemberDetailSideBar from './MemberDetailSideBar';
import { useMemberProfileApi } from '../../../apis/useMemberProfileApi';
import { MemberDetailProvider } from './MemberDetailContext';
import { memberDetailRouter as routers } from '../../router/MembersRouter';

const MemberDetailPage = (props) => {
    const { memberID } = props.match.params;
    let { getMemberProfileApi } = useMemberProfileApi();
    const [tab, setTab] = useState();
    const [data, setData] = useState({});
    const refreshRef = useRef();

    const doMemberProfileApi = async (memberID) => {
        let res = await getMemberProfileApi(memberID);
        if (res) {
            setData(res);
        }
    };

    useEffect(() => {
        if (memberID) doMemberProfileApi(memberID);
        // eslint-disable-next-line
    }, [memberID]);

    if (isEmpty(data)) return null;

    return (
        <MemberDetailProvider
            value={{
                data,
                setData,
                doMemberProfileApi,
                refresh: refreshRef,
                setRefresh: (fn) => (refreshRef.current = fn),
            }}
        >
            <Box className="container main-container-spacing member-information-page">
                <Grid container spacing={3}>
                    <Grid item xs={9}>
                        <MemberDetailSummary
                            data={data}
                            className="mb-4"
                            memberID={memberID}
                            tab={tab}
                            setTab={setTab}
                        />
                        {
                            <Switch>
                                {routers.map((item) => {
                                    return (
                                        <PrivateRoute
                                            name={data.nickName || data.memberName}
                                            memberID={memberID}
                                            key={item.description}
                                            exact={item.exact}
                                            path={item.path}
                                            as={item.as}
                                            setTab={setTab}
                                        />
                                    );
                                })}
                                <Route path="/">
                                    <Redirect to={routers[0].href(memberID)} />
                                </Route>
                            </Switch>
                        }
                    </Grid>
                    <Grid item xs={3}>
                        <MemberDetailSideBar memberID={memberID} data={data} />
                    </Grid>
                </Grid>
            </Box>
        </MemberDetailProvider>
    );
};

export default MemberDetailPage;
