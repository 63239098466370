import React, { useEffect, useRef, useState } from 'react';
import { Card, IconButton, Typography } from '../../../components';
import clsx from 'clsx';
import {
    NavigateBefore as NavigateBeforeIcon,
    NavigateNext as NavigateNextIcon,
} from '../../../components/icons/SvgIcon/';
import CycleOrderMonthStatisticsTable from '../tables/CycleOrderMonthStatisticsTable';
import useMemberCyclePurchaseApi from '../../../apis/useMemberCyclePurchaseApi';

const getInitPageInfo = () => {
    return {
        pageIndex: 1,
        pageSize: 9,
    };
};
const CycleOrderMonthStatisticsBox = (props) => {
    const { className, memberID } = props;
    const { getMemberCycleOrderTimelineApi } = useMemberCyclePurchaseApi();
    const [data, setData] = useState({});
    const [pageInfo, setPageInfo] = useState(getInitPageInfo());
    const { nextText, prevText, items } = data;
    const preloadRef = useRef(false);

    const doMemberCyclePurchaseTimelineApi = async (memberID, params) => {
        const res = await getMemberCycleOrderTimelineApi(memberID, params);
        if (res) {
            setData(res);
        }
        preloadRef.current = true;
    };
    const onChangePage = (index) => {
        setPageInfo((prev) => ({
            ...prev,
            pageIndex: index,
        }));
    };
    const onClickNext = () => {
        let index = pageInfo.pageIndex;
        index++;
        onChangePage(index);
    };

    const onClickPrev = () => {
        let index = pageInfo.pageIndex;
        index--;
        onChangePage(index);
    };

    useEffect(() => {
        if (preloadRef.current) {
            preloadRef.current = false;
            doMemberCyclePurchaseTimelineApi(memberID, pageInfo);
        } else {
            preloadRef.current = true;
        }
        // eslint-disable-next-line
    }, [pageInfo.pageIndex]);

    useEffect(() => {
        if (memberID) {
            if (preloadRef.current) {
                preloadRef.current = false;
                doMemberCyclePurchaseTimelineApi(memberID, pageInfo);
            } else {
                preloadRef.current = true;
            }
        }
        // eslint-disable-next-line
    }, [memberID]);

    return (
        <Card className={clsx('cycle-order-month-statistics-box', className)}>
            <Card.CardContent className="py-2 px-4">
                <div className="cycle-order-month-statistics-box-controller prev">
                    <Typography variant="body2" className="text">
                        {prevText}
                    </Typography>
                    <IconButton onClick={onClickNext} disabled={!prevText}>
                        <NavigateBeforeIcon />
                    </IconButton>
                </div>
                <div className="cycle-order-month-statistics-box-controller next">
                    <Typography variant="body2" className="text">
                        {nextText}
                    </Typography>
                    <IconButton onClick={onClickPrev} disabled={!nextText}>
                        <NavigateNextIcon />
                    </IconButton>
                </div>
                <CycleOrderMonthStatisticsTable data={items} pageSize={pageInfo.pageSize} />
            </Card.CardContent>
        </Card>
    );
};

export default CycleOrderMonthStatisticsBox;
