import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import clsx from 'clsx';
import { isEmpty } from 'lodash';
import { Dialog, DialogActions, DialogContent } from '@mui/material';
import { Alert, Box, Button, DateField, Grid, MenuItem, SelectField, TextField } from '@common/components/';
import GradientColorHeader from '@icoach/components/dialog/GradientColorHeader';
import useCabinetRentalApi from '@apis/useCabinetRentalApi';
import { refIsRequiredError } from '@util/utils';
import { parseDate } from '@util/moment';
import { RentCabinetsInfoBox } from '@icoach/rentCabinet/dialog/';
import { cabinetQuitKeyStatusMap } from '@icoach/rentCabinet/staticData';

const QuitRentCabinetDialogContent = React.forwardRef((props, ref) => {
    const { data = {}, resource = {} } = props;
    const { memberName, cellPhone, cabinetName, monthlyFee, memo, startDate } = data;
    const { employeeOption, cabinetQuitKeyStatusOption } = resource;
    const [isShowAlert, setIsShowAlert] = useState(false);
    const keyRef = useRef(null);
    const dateRef = useRef(null);
    const employeeRef = useRef(null);
    const quitReasonRef = useRef(null);

    const handleChangeKeyStatus = (event, node, value) => {
        setIsShowAlert(parseInt(value) === cabinetQuitKeyStatusMap.lost);
    };

    useImperativeHandle(
        ref,
        () => ({
            getResult: () => {
                let result = {};

                if (dateRef.current && dateRef.current.getResult) {
                    result = Object.assign(result, {
                        endDate: dateRef.current.getResult(),
                    });
                }
                if (employeeRef.current && employeeRef.current.getResult) {
                    result = Object.assign(result, {
                        employeeID: employeeRef.current.getResult(),
                    });
                }
                if (keyRef.current && keyRef.current.getResult) {
                    result = Object.assign(result, {
                        cabinetQuitKeyStatus: keyRef.current.getResult(),
                    });
                }
                if (quitReasonRef.current && quitReasonRef.current.getResult) {
                    result = Object.assign(result, {
                        quitReason: quitReasonRef.current.getResult(),
                    });
                }
                return result;
            },
            isError: () => refIsRequiredError(dateRef, employeeRef, keyRef, quitReasonRef),
        }),
        [],
    );

    return (
        <DialogContent className={'p-0'}>
            {!isEmpty(data) && (
                <RentCabinetsInfoBox
                    startDate={startDate}
                    memberName={memberName}
                    cellPhone={cellPhone}
                    cabinetName={cabinetName}
                    monthlyFee={monthlyFee}
                    memo={memo}
                />
            )}
            <Box className={'p-4'}>
                <Grid spacing={3} container>
                    <Grid xs={6} item>
                        <DateField ref={dateRef} label={'退租日期'} defaultValue={parseDate()} fullWidth required />
                    </Grid>
                    <Grid xs={6} item />
                    <Grid xs={6} item>
                        <SelectField label={'擔當'} ref={employeeRef} fullWidth required>
                            {Array.isArray(employeeOption) &&
                                employeeOption
                                    .filter((item) => item || !item.disabled)
                                    .map((item) => (
                                        <MenuItem key={item.value} value={item.value}>
                                            {item.text}
                                        </MenuItem>
                                    ))}
                        </SelectField>
                    </Grid>
                    <Grid xs={6} item>
                        <SelectField label={'繳回鑰匙'} ref={keyRef} onChange={handleChangeKeyStatus} fullWidth required>
                            {Array.isArray(cabinetQuitKeyStatusOption) &&
                                cabinetQuitKeyStatusOption
                                    .filter((item) => item || !item.disabled)
                                    .map((item) => (
                                        <MenuItem key={item.value} value={item.value}>
                                            {item.text}
                                        </MenuItem>
                                    ))}
                        </SelectField>
                    </Grid>
                    <Grid xs={12} item>
                        <TextField ref={quitReasonRef} label={'退租原因'} inputProps={{ placeholder: '如：正常退租' }} minRows={3} multiline fullWidth />
                    </Grid>
                    {isShowAlert && (
                        <Grid xs={12} item>
                            <Alert variant={'filled'} severity={'info'} color={'info'}>
                                鑰匙遺失如有費用產生請至營收管理增加收入
                            </Alert>
                        </Grid>
                    )}
                </Grid>
            </Box>
        </DialogContent>
    );
});

const QuitRentCabinetDialog = (props) => {
    const { open, className, sourceData, onClose, refresh } = props;
    const { cabinetRentalID } = sourceData || {};
    const { getInitQuitRentCabinetByEditApi, putQuitRentCabinetApi } = useCabinetRentalApi();
    const [data, setData] = useState({});
    const { rentCabinetData, options } = data;
    const contentRef = useRef();

    const handleSubmit = () => {
        const isError = contentRef.current && contentRef.current.isError();
        if (!isError) {
            const params = contentRef.current && contentRef.current.getResult();
            doQuitRentCabinetApi(cabinetRentalID, params);
        }
    };

    const doInitQuitRentCabinetByEditApi = async (cabinetRentalID) => {
        let res = await getInitQuitRentCabinetByEditApi(cabinetRentalID);
        // console.log(res);
        if (res) {
            const { rentCabinetData, resource } = res;
            setData({
                options: resource,
                rentCabinetData,
            });
        }
    };

    const doQuitRentCabinetApi = async (cabinetRentalID, params) => {
        let res = await putQuitRentCabinetApi(cabinetRentalID, params);
        if (res) {
            if (typeof refresh === 'function') refresh();
            if (typeof onClose === 'function') onClose();
        }
    };

    useEffect(() => {
        if (open) doInitQuitRentCabinetByEditApi(cabinetRentalID);
        // eslint-disable-next-line
    }, [open]);

    return (
        <Dialog key={open} className={clsx('simple-content', 'dialog', className)} PaperProps={{ className: 'wx-90rem' }} open={open} fullWidth>
            <GradientColorHeader onClose={onClose}>退租申請</GradientColorHeader>
            <QuitRentCabinetDialogContent ref={contentRef} data={rentCabinetData} resource={options} />
            <DialogActions className="px-4 pt-0 pb-2">
                <Button variant="contained" onClick={handleSubmit}>
                    確認送出
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default QuitRentCabinetDialog;
