import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { effectTypeMap } from '@icoach/documents/cyclePurchase/staticData';
import { displayDateText, getAddMonths, getFirstOfMonth, initialDate, parseDate } from '@util/moment';
import moment from 'moment';
import { DateTimeStandard } from '../../../../components/pickers/enums/DateTimeStandard';
import { refIsRequiredError } from '@util/utils';
import { isEmpty } from 'lodash';
import { Card, DateField } from '@common/components/';
import { Grid } from '@mui/material';
import { ReadTextField } from '@icoach/components';
import { getEffectiveDate, SectionHeader, useSectionEffect } from '@icoach/documents/cyclePurchase/components/CyclePurchaseUtility';

const ResuspensionSection = forwardRef((props, ref) => {
    const {
        title = '暫停恢復',
        effectDate,
        shippingDayData,
        data = {},
        isCreate = false,
        setShowTip,
        onChangeEffectType: onChangeEffectTypeProps,
        onChangeResuspendDate,
    } = props;
    const { originalSuspendStartDate, originalSuspendEndDate, suspendStartDate, suspendEndDate, reSuspendDate } = data;
    const effectTypeID = effectTypeMap.resuspension;
    const [isShow, setShow] = useState(Boolean(isCreate));
    const pickItemRef = useRef();
    const lastShippingMonthRef = useRef();

    const handleShowContent = (checked) => {
        setShow(checked);
    };

    const getResult = () => {
        let result = {
            originalSuspendStartDate: originalSuspendStartDate || suspendStartDate,
            originalSuspendEndDate: originalSuspendEndDate || suspendEndDate,
        };
        if (lastShippingMonthRef.current && lastShippingMonthRef.current.getResult) {
            let reSuspendDate = lastShippingMonthRef.current.getResult();
            reSuspendDate = parseDate(moment(reSuspendDate).date(1), DateTimeStandard.DisplayUtcFull);

            result = Object.assign(result, { reSuspendDate });
        }
        return result;
    };

    const handleChangeReSuspendDate = (node, val) => {
        const date = parseDate(moment(val).date(1), DateTimeStandard.DisplayUtcFull);
        if (typeof onChangeResuspendDate === 'function') onChangeResuspendDate(date);
    };

    useImperativeHandle(
        ref,
        () => ({
            getResult,
            isError: () => refIsRequiredError(lastShippingMonthRef),
            getEffectTypeID: () => isShow && effectTypeID,
            setHelperText: (isError, text) => {
                return pickItemRef.current && pickItemRef.current.setHelperText(isError, text);
            },
        }),
        // eslint-disable-next-line
        [isShow],
    );

    useSectionEffect(setShowTip, onChangeEffectTypeProps, isShow, effectTypeID);

    useEffect(
        () => {
            if (!isEmpty(data.effectType)) {
                setShow(data.effectType.includes(effectTypeID));
            }
        },
        // eslint-disable-next-line
        [data],
    );

    return (
        <React.Fragment>
            <SectionHeader title={title} isShowCheckBox={!isCreate} refItem={pickItemRef} handleChange={handleShowContent} defaultChecked={isShow} />
            {isShow && (
                <Card.CardContent className={'p-4'}>
                    <Grid spacing={3} container>
                        <Grid xs={6} item>
                            <ReadTextField label={'原暫停期間'}>
                                {displayDateText(originalSuspendStartDate || suspendStartDate, originalSuspendEndDate || suspendEndDate, {
                                    format: 'YYYY/MM',
                                })}
                            </ReadTextField>
                        </Grid>
                        <Grid xs={6} item>
                            <ReadTextField label={'原恢復出貨月份'}>
                                {displayDateText(getFirstOfMonth(getAddMonths(originalSuspendEndDate || suspendEndDate, 1)), null, {
                                    format: 'YYYY/MM',
                                })}
                            </ReadTextField>
                        </Grid>
                        <Grid xs={6} item>
                            <DateField
                                key={initialDate(reSuspendDate)}
                                ref={lastShippingMonthRef}
                                label={'恢復出貨月份'}
                                defaultValue={initialDate(reSuspendDate)}
                                views={['year', 'month']}
                                openTo={'month'}
                                format={'YYYY/MM'}
                                mask={'____/__'}
                                minDate={getEffectiveDate(effectDate, shippingDayData.text)}
                                maxDate={getEffectiveDate(originalSuspendEndDate || suspendEndDate, shippingDayData.text)}
                                placeholder={'請選擇月份'}
                                onChange={handleChangeReSuspendDate}
                                fullWidth
                                required
                            />
                        </Grid>
                    </Grid>
                </Card.CardContent>
            )}
        </React.Fragment>
    );
});

export default ResuspensionSection;
