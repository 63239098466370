import React from 'react';
import { Stack, Typography } from '../../../components';
import { Heart as HeartIcon } from '../../../components/icons/SvgIcon/';

const GuidanceNotesTitle = () => (
    <Stack className={'guidance-notes-title'} alignItems={'center'} justifyContent={'start'}>
        <HeartIcon className={'icon'} />
        <Typography className={'m-0 p-0'}>指導注意</Typography>
    </Stack>
);

export default GuidanceNotesTitle;
