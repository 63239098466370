import React, { createContext, useContext } from 'react';

const PointsRedeemContext = createContext(undefined);

export const PointsRedeemProvider = ({ children, value }) => {
    return <PointsRedeemContext.Provider value={value}>{children}</PointsRedeemContext.Provider>;
};

export const usePointsRedeemContext = () => {
    const context = useContext(PointsRedeemContext);
    if (context === undefined) {
        throw new Error('usePointsRedeemContext 必須在 PointsRedeemProvider 中使用');
    }
    return context;
};
