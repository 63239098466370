import React from 'react';
import useInBody from './useInBody';
import { IconButton } from '@common/components/';
import { Download as DownloadIcon } from '@common/SvgIcon/';

const InBodyInterviewPdfDialog = React.forwardRef((props, ref) => {
    const { sourceData: pdfUrl } = props;
    const { dialogRef } = useInBody();

    const handleOnClick = () => {
        dialogRef.current.openViewPdfDialog(pdfUrl);
    };

    return (
        <React.Fragment>
            {pdfUrl && (
                <IconButton onClick={handleOnClick}>
                    <DownloadIcon />
                </IconButton>
            )}
        </React.Fragment>
    );
});

export default InBodyInterviewPdfDialog;
