import React, { useRef, useImperativeHandle } from 'react';
import { v4 as uuid } from 'uuid';
import { isEmpty } from 'lodash';
import { useMembershipContext } from '@icoach/documents/membership/create/MembershipContext';
import { Alert, CheckboxField, Stack } from '@common/components/';
import { SignBox } from '@icoach/components/';
import { NoticeCard } from '@icoach/documents/components/';
import { getCheckboxValue, setCheckboxValue } from '@icoach/documents/tools';
import { default as noticeData } from '@icoach/documents/membership/create/notice';
import { refIsRequiredError } from '@util/utils';

const MembershipCreateContentSurvey = React.forwardRef((props, ref) => {
    const { desc, isSign = false } = props;
    const { data, errorModule, isReadOnly, getErrorModuleStatus } = useMembershipContext();
    const { contractVersion, contractMain = {}, contractSurvey = {} } = data;
    const { membershipType, memberType } = contractMain;
    const { items = [], isConfirm, signImg } = contractSurvey;
    const noticeRef = useRef([]);
    const isConfirmRef = useRef(null);
    const signImgRef = useRef(null);

    // 判斷新約與續約條款顯示
    const checkMembershipType = (membershipType, memberType) => {
        let target = 'new';
        if (String(membershipType) === '2') {
            target = 'renewal';
        } else if (String(memberType) === '6') {
            target = 'newStudent';
        } else {
            target = 'new';
        }

        return target;
    };

    const makeNoticeData = (membershipType, memberType) => {
        if (!membershipType) return [];
        const current = noticeData[contractVersion][checkMembershipType(membershipType, memberType)];
        const _data = current.data;
        let _notice;
        _notice = current.config.map((item) => {
            return {
                title: item.title,
                img: item.img,
                noticeData: item.end ? _data.slice(item.start, item.end) : _data.slice(item.start),
                WarnComponent: item.WarnComponent || null,
            };
        });
        return _notice;
    };

    const getResult = () => {
        let result = { contractSurvey: {} };
        const noticeObj = noticeRef.current;

        if (!isEmpty(noticeObj)) {
            let val = noticeObj.map((item) => item.getResult && item.getResult().checked);
            result.contractSurvey.items = val;
        }

        if (isConfirmRef.current && isConfirmRef.current.getResult) {
            result.contractSurvey.isConfirm = getCheckboxValue(isConfirmRef.current.getResult(), {
                type: 'checked',
                isMultiple: false,
            });
        }

        if (signImgRef.current && signImgRef.current.getResult) {
            result.contractSurvey.signImg = signImgRef.current.getResult();
        }

        return result;
    };

    useImperativeHandle(
        ref,
        () => ({
            getResult,
            isError: () => {
                const allObj = {
                    isConfirm: isConfirmRef.current,
                    signImg: signImgRef.current,
                };
                return refIsRequiredError.apply(null, Object.values(allObj));
            },
        }),
        // eslint-disable-next-line
        []
    );

    return (
        <React.Fragment>
            <div className="survey">
                {makeNoticeData(membershipType, memberType).map((item, i) => {
                    return (
                        <NoticeCard key={uuid()} index={i} ref={(r) => (noticeRef.current[i] = r)} defaultValue={items[i]} readOnly={isReadOnly} {...item} />
                    );
                })}
                <Alert className="mb-3" severity="info" variant="filled" color="info">
                    {desc}
                </Alert>
                {isSign && (
                    <Stack direction={'column'} justifyContent={'center'} alignItems={'center'}>
                        <CheckboxField
                            key={setCheckboxValue(isConfirm)}
                            ref={isConfirmRef}
                            defaultChecked={setCheckboxValue(isConfirm)}
                            {...getErrorModuleStatus(errorModule.contractSurvey, 'isConfirm')}
                            readOnly={isReadOnly}
                            required
                        >
                            <CheckboxField.Checkbox label="本人已了解並詳讀以上會員須知，並同意遵守。" />
                        </CheckboxField>
                        <SignBox
                            ref={signImgRef}
                            title="會員簽名"
                            defaultValue={signImg}
                            {...getErrorModuleStatus(errorModule.contractSurvey, 'signImg')}
                            readOnly={isReadOnly}
                        />
                    </Stack>
                )}
            </div>
        </React.Fragment>
    );
});

export default MembershipCreateContentSurvey;
