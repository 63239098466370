import { useState } from 'react';

const useToggleDialog = () => {
    const [openDialog, setOpenDialog] = useState({
        target: '',
    });
    const { target, ...other } = openDialog;

    const handleOpenDialog = (key, other) => {
        setOpenDialog({ target: key, ...other });
    };
    const handleCloseDialog = () => {
        setOpenDialog({ target: '' });
    };
    return {
        openTarget: target,
        handleOpenDialog,
        handleCloseDialog,
        ...other,
    };
};

export default useToggleDialog;
