import React, { useCallback, useEffect, useState } from 'react';
import clsx from 'clsx';
import { Grid } from '@mui/material';
import { Box, Button } from '@common/components/';
import { ReadTextField } from '@icoach/components/';
import { makeField } from '@icoach/members/memberDetail/tools';
import {displayDateText, isAfter} from '@util/moment';
import useMembershipApi from '@apis/useMembershipApi';
import { dialogTypeMap } from '@icoach/members/staticData';

const MemberDetailMembershipContentInfo = (props) => {
    const { className, memberID, membershipID, handleOpenDialog } = props;
    const { getMembershipApi } = useMembershipApi();
    const [data, setData] = useState({});
    const { isMembershipNormal = false } = data;
    const getFieldData = useCallback(() => {
        const {
            memberCardNo,
            membershipStatusText,
            memberTypeText,
            membershipTypeText,
            contractStartDate,
            contractEndDate,
            endDate,
            membershipTermText,
            monthlyFee,
            paymentTypeText,
            debitDay,
            debitFirstDate,
            sourceTypeText,
            introducer,
            faDate,
            faEmployeeName,
        } = data;

        return [
            makeField('memberCardNo', '會員卡號', memberCardNo),
            makeField('membershipStatusText', '會籍狀態', membershipStatusText),
            makeField('memberTypeText', '會員類型', memberTypeText),
            makeField('membershipTypeText', '會籍類型', membershipTypeText),
            makeField('contractDate', '合約期間', displayDateText(contractStartDate, contractEndDate), null, {
                xs: 8,
            }),
            makeField('membershipTermText', '合約長度', membershipTermText),
            makeField('monthlyFee', '月費', monthlyFee),
            makeField('paymentTypeText', '付款方式', paymentTypeText),
            makeField('debitDay', '每月扣款日', debitDay),
            makeField('debitFirstDate', '首次扣款日', isAfter(debitFirstDate, endDate, 'YYYY-MM-DDTHH:mm:ss') ? '無需扣款' : displayDateText(debitFirstDate)),
            makeField('sourceTypeText', '來源', sourceTypeText),
            makeField('introducer', '介紹人', introducer),
            makeField('faDate', 'FA日期', displayDateText(faDate)),
            makeField('faEmployeeName', 'FA擔當', faEmployeeName),
        ];
    }, [data]);

    const doMembershipApi = async (memberID, membershipID) => {
        let res = await getMembershipApi(memberID, membershipID);
        if (res) {
            setData(res);
        }
    };

    useEffect(() => {
        if (memberID && membershipID) {
            doMembershipApi(memberID, membershipID);
        }
        // eslint-disable-next-line
    }, [memberID, membershipID]);

    return (
        <Box className={clsx('mb-4', className)}>
            {isMembershipNormal && (
                <div className={'text-right'}>
                    <Button variant={'contained'} onClick={() => handleOpenDialog(dialogTypeMap.manualGift)}>
                        贈送會籍
                    </Button>
                </div>
            )}
            <Grid container spacing={3}>
                {getFieldData().map((item) => {
                    return (
                        <Grid key={item.id} item {...item.gridItemSize}>
                            <ReadTextField label={item.label}>{item.value}</ReadTextField>
                        </Grid>
                    );
                })}
            </Grid>
        </Box>
    );
};

export default MemberDetailMembershipContentInfo;
