import React from 'react';
import { Dialog, DialogActions, DialogContent } from '@mui/material';
import GradientColorHeader from '@icoach/components/dialog/GradientColorHeader';
import { Button, Stack, Alert } from '@common/components/';
import useSmsTemplateApi from '@apis/useSmsTemplateApi';

const SmsTemplateDeleteDialog = React.forwardRef((props, ref) => {
    const { open, onClose: propsOnClose, rowData, onRefrash } = props;
    const { name: title, smsTemplateID } = rowData;
    const { deleteSmsTemplatePublicDataApi } = useSmsTemplateApi();

    // 送出 按鈕
    const handleDeleteClick = () => {
        doDeleteSMSTemplateApi(smsTemplateID);
    };

    // 刪除 SMS公版 Api
    const doDeleteSMSTemplateApi = async (smsTempID) => {
        const resp = await deleteSmsTemplatePublicDataApi(smsTempID);
        if (resp) {
            propsOnClose && propsOnClose();
            onRefrash && onRefrash();
        }
    };

    return (
        <Dialog open={open} PaperProps={{ className: 'wx-65rem' }} fullWidth>
            <GradientColorHeader onClose={propsOnClose}>SMS刪除</GradientColorHeader>
            <DialogContent>
                <Stack direction="column" spacing={2}>
                    <Alert severity="warning" variant="filled" color="error">
                        {`確認要刪除【${title}】？`}
                    </Alert>
                    <Stack justifyContent="center">
                        <Button variant="contained" onClick={handleDeleteClick}>
                            送出
                        </Button>
                    </Stack>
                </Stack>
            </DialogContent>
            <DialogActions></DialogActions>
        </Dialog>
    );
});

export default SmsTemplateDeleteDialog;
