import React, { useImperativeHandle, useRef, useState } from 'react';
import { useSnackbar } from 'notistack';
import clsx from 'clsx';
import { v4 as uuid } from 'uuid';
import img_cloud from '../assets/img/cloud.png';
import img_pdf from '../assets/img/file-pdf.png';
import img_image from '../assets/img/file-image.png';
import img_empty from '../assets/img/file-empty.png';
import img_nomatch from '../assets/img/file-nomatch.png';
import img_excel from '../assets/img/file-excel.png';
import { IconButton, FormHelperText, Typography, Box } from '@common/components/';
import { Close as CloseIcon } from '@common/SvgIcon/';
import { linkDownload } from '@util/utils';

const staticClass = 'upload-box';

let imgMap = {
    jpg: img_image,
    png: img_image,
    gif: img_image,
    pdf: img_pdf,
    xlsx: img_excel,
};

const ViewFileBox = (props) => {
    const { file: _file, link: _link, onClearFile, readOnly } = props;
    let fileObject = {};
    if (_link) Object.assign(fileObject, convertUrlToObject(_link));
    else Object.assign(fileObject, _file);
    return (
        <picture className="img-wrap">
            {!readOnly && (
                <IconButton color="error" className={`${staticClass}-close-icon`} onClick={onClearFile}>
                    <CloseIcon htmlColor="#fff" />
                </IconButton>
            )}
            <img className={`${staticClass}-img`} src={imgMap[fileObject.extension] || img_nomatch} alt={fileObject.name} />
            {fileObject && <Typography className={`${staticClass}-filename`}>{`${fileObject.name}.${fileObject.extension}`}</Typography>}
        </picture>
    );
};

const NoFileBox = (props) => {
    const { readOnly } = props;
    return (
        <React.Fragment>
            {!readOnly ? (
                <React.Fragment>
                    <img className={`${staticClass}-img`} src={img_cloud} alt="上傳檔案" />
                    <Typography>{'請將要上傳的檔案拖曳至此或點擊此區域'}</Typography>
                </React.Fragment>
            ) : (
                <picture className="img-wrap">
                    <img className={`${staticClass}-img`} src={img_empty} alt="無檔案" />
                    <Typography>{'無上傳檔案'}</Typography>
                </picture>
            )}
        </React.Fragment>
    );
};

const convertUrlToObject = (_url) => {
    let source;
    if (_url) {
        const [extension, fileInfo] = _url.split('.').reverse();
        const [name] = fileInfo.split('/').reverse();
        source = {};
        source.extension = extension;
        source.name = name;
    }
    return source ? source : null;
};

const UploadBox = React.forwardRef((props, ref) => {
    const {
        link: linkProps = null,
        className,
        onFileChange = () => {},
        onClearFile = () => {},
        accept = '*/*',
        fileExtension: fileExt,
        isError: propsError = false,
        helperText: helperTextProps = '此選項為必填，不得為空值',
        required = false,
        // onFileDownLoad = () => {},
        readOnly = false,
    } = props;
    const { enqueueSnackbar: _snackbar } = useSnackbar();
    // const [helperText, setHelperText] = useState(helperTextProps);
    const [fileState, setFile] = useState(null);
    const [linkState, setLink] = useState(linkProps);
    const [isError, setError] = useState(propsError);
    const fileRef = useRef(null);
    const divRef = useRef(null);

    const handleClearFile = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setFile(null);
        setLink(null);
        typeof onClearFile === 'function' && onClearFile();
    };

    const handleClick = () => {
        if (linkState) linkDownload(linkState);
        else if (readOnly) return;
        else fileRef.current && fileRef.current.click();
    };

    const handleFileChange = (_file) => {
        if (readOnly) return;
        setError(false);
        if (!_file) {
            console.error('No File unpload');
            return;
        }
        const [fileExtension, fileName] = _file.name.split('.').reverse();
        if (fileExt && !fileExt.includes(fileExtension)) {
            _snackbar(`錯誤的格式，請使用以下格式：${fileExt.join('、')}`, {
                variant: 'error',
            });
        } else {
            setFile({
                extension: fileExtension,
                name: fileName,
                file: _file,
            });

            onFileChange && onFileChange(_file);
        }
    };

    const handleOnDragEnter = (e) => {
        divRef.current.classList.add(`${staticClass}-drop-enter`);
        e.preventDefault();
        e.stopPropagation();
        if (readOnly) return;
    };

    const handleDragleave = (e) => {
        divRef.current.classList.remove(`${staticClass}-drop-enter`);
        e.preventDefault();
        e.stopPropagation();
        if (readOnly) return;
    };

    const handleDragover = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (readOnly) return;
    };

    const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (readOnly) return;
        const dt = e.dataTransfer;
        const files = dt.files;
        handleFileChange(files[0]);
        divRef.current.classList.remove(`${staticClass}-drop-enter`);
    };

    useImperativeHandle(
        ref,
        () => ({
            getResult: () => fileState,
            isError: () => {
                let isError = required;
                if (fileState) isError = false;
                setError(isError);
                return isError;
            },
        }),
        // eslint-disable-next-line
        [fileState]
    );

    return (
        <Box className={clsx(staticClass, className)} ref={divRef}>
            <input className="file-input" type="file" ref={fileRef} onChange={(e) => handleFileChange(e.target.files[0])} accept={accept} key={uuid()} />
            <div
                className={`${staticClass}-wrap`}
                onClick={handleClick}
                onDragEnter={handleOnDragEnter}
                onDragLeave={handleDragleave}
                onDragOver={handleDragover}
                onDrop={handleDrop}
            >
                <div className={`${staticClass}-content text-center`} onDragEnter={handleOnDragEnter}>
                    {fileState || linkState ? (
                        <ViewFileBox file={fileState} link={linkState} onClearFile={handleClearFile} readOnly={readOnly} />
                    ) : (
                        <NoFileBox readOnly={readOnly} />
                    )}
                    {fileExt && <Typography className="desc">支援檔案格式：{fileExt.join('、')}</Typography>}
                </div>
            </div>
            {isError && <FormHelperText isError={isError}>{helperTextProps}</FormHelperText>}
        </Box>
    );
});

export default UploadBox;
