import React from 'react';
import clsx from 'clsx';
import { Cancel as CancelIcon } from '../icons/SvgIcon/';
import ButtonBase from '../button/ButtonBase';
import useForkRef from '../../util/hook/useForkRef';

const colorArray = ['primary', 'secondary', 'error', 'info', 'success', 'warning', 'gray'];
const sizeArray = ['medium', 'small'];
const variantArray = ['filled', 'outlined'];

const staticClass = 'chip';

const ChipRoot = React.forwardRef((props, ref) => {
    const {
        as: Component = 'div',
        className,
        color,
        size,
        variant,
        disabled,
        isAvatar,
        isIcon,
        isClickable,
        isShrink,
        // focusVisible,
        onKeyDown = () => {},
        onKeyUp = () => {},
        onClick = () => {},
        children,
        ...other
    } = props;

    let classNames = `${staticClass}-root`;

    if (colorArray.indexOf(color) !== -1) classNames = clsx(classNames, `${staticClass}-color-${color}`);
    if (sizeArray.indexOf(size) !== -1) classNames = clsx(classNames, `${staticClass}-size-${size}`);
    if (variantArray.indexOf(variant) !== -1) classNames = clsx(classNames, `${staticClass}-variant-${variant}`);
    if (disabled) classNames = clsx(classNames, `${staticClass}-disabled`);
    if (isAvatar) classNames = clsx(classNames, `${staticClass}-avatar`);
    if (isIcon) classNames = clsx(classNames, `${staticClass}-icon`);
    if (isClickable) classNames = clsx(classNames, `${staticClass}-clickable`);
    if (!isShrink) classNames = clsx(classNames, `${staticClass}-no-shrink`);
    // if (focusVisible) classNames = clsx(classNames, `${staticClass}-focus-visible`);

    const defaultProperty = {
        className: clsx(classNames, className),
        ref,
        onKeyDown,
        onKeyUp,
        onClick,
        ...other,
    };
    return (
        <Component className={clsx(classNames)} {...defaultProperty}>
            {children}
        </Component>
    );
});

const ChipLabel = (props) => {
    const { size, children } = props;
    let classNames = `${staticClass}-label`;
    if (sizeArray.indexOf(size) !== -1) classNames = clsx(classNames, `${staticClass}-label-size-${size}`);
    const defaultProperty = {
        className: classNames,
    };
    return <span {...defaultProperty}>{children}</span>;
};

const isDeleteKeyboardEvent = (keyboardEvent) => {
    return keyboardEvent.key === 'Backspace' || keyboardEvent.key === 'Delete';
};

const Chip = React.forwardRef((props, ref) => {
    const {
        className,
        avatar: avatarProp,
        clickable: clickableProp,
        color = '',
        component: ComponentProp,
        deleteIcon: deleteIconProp,
        disabled = false,
        icon: iconProp,
        label,
        onClick,
        onDelete,
        onKeyDown,
        onKeyUp,
        size = 'medium',
        variant = 'filled',
        isShrink = true,
    } = props;

    const chipRef = React.useRef(null);
    const handleRef = useForkRef(chipRef, ref);

    const handleDeleteIconClick = (event) => {
        event.stopPropagation();
        onDelete && onDelete(event);
    };

    const handleKeyDown = (event) => {
        // Ignore events from children of `Chip`.
        if (event.currentTarget === event.target && isDeleteKeyboardEvent(event)) {
            // Will be handled in keyUp, otherwise some browsers
            // might init navigation
            event.preventDefault();
        }
        onKeyDown && onKeyDown(event);
    };

    const handleKeyUp = (event) => {
        // Ignore events from children of `Chip`.
        if (event.currentTarget === event.target) {
            if (onDelete && isDeleteKeyboardEvent(event)) {
                onDelete(event);
            } else if (event.key === 'Escape' && chipRef.current) {
                chipRef.current.blur();
            }
        }
        onKeyUp && onKeyUp(event);
    };

    const clickable = clickableProp !== false && onClick ? true : clickableProp;
    const small = size === 'small';
    const component = clickable || onDelete ? ButtonBase : ComponentProp || 'div';

    const moreProps =
        component === ButtonBase
            ? {
                  component: ComponentProp || 'div',
                  //   focusVisibleClassName: classes.focusVisible,
                  disableRipple: Boolean(onDelete),
              }
            : {};

    let deleteIcon = null;
    if (onDelete) {
        let deleteIconClass = `${staticClass}-delete-icon`;
        if (small) deleteIconClass = clsx(deleteIconClass, `${staticClass}-delete-icon-size-small`);
        if (colorArray.indexOf(color) !== -1) deleteIconClass = clsx(deleteIconClass, `${staticClass}-delete-icon-color-${color}`);
        deleteIcon =
            deleteIconProp && React.isValidElement(deleteIconProp) ? (
                React.cloneElement(deleteIconProp, {
                    className: deleteIconClass,
                    onClick: handleDeleteIconClick,
                })
            ) : (
                <CancelIcon onClick={handleDeleteIconClick} className={deleteIconClass} />
            );
    }

    let avatar = null;
    if (avatarProp && React.isValidElement(avatarProp)) {
        let avatarClass = `${staticClass}-avatar`;
        if (small) avatarClass = clsx(avatarClass, `${staticClass}-avatar-size-small`);

        avatar = React.cloneElement(avatarProp, {
            className: avatarClass,
        });
    }

    let icon = null;
    if (iconProp && React.isValidElement(iconProp)) {
        let iconClass = `${staticClass}-icon`;
        if (small) iconClass = clsx(iconClass, `${staticClass}-icon-sizes-small`);
        icon = React.cloneElement(iconProp, {
            className: iconClass,
        });
    }

    return (
        <ChipRoot
            as={component}
            className={className}
            color={color}
            size={size}
            variant={variant}
            disabled={disabled}
            isAvatar={!!avatar}
            isIcon={!!label}
            isClickable={clickable}
            isShrink={isShrink}
            // focusVisible={false}
            onClick={onClick}
            onKeyDown={handleKeyDown}
            onKeyUp={handleKeyUp}
            ref={handleRef}
            {...moreProps}
        >
            {avatar || icon}
            <ChipLabel size={size}>{label}</ChipLabel>
            {deleteIcon}
        </ChipRoot>
    );
});

export default Chip;
