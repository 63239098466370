import React from 'react';
import SvgIcon from '../SvgIcon';
import useForkRef from '@util/hook/useForkRef';

const ArrowDown = React.forwardRef((props, ref) => {
    const { className, ...others } = props;
    const handleRef = React.useRef(null);
    const svgRef = useForkRef(handleRef, ref);
    const defaultProperty = {
        className,
        ref: svgRef,
        ...others,
    };
    return (
        <SvgIcon {...defaultProperty}>
            <path
                d="M10.8,23.5c0.7,0.7,1.8,0.7,2.4,0l8.6-8.6c0.7-0.7,0.7-1.8,0-2.4s-1.8-0.7-2.4,0l-5.6,5.7V1.7
	C13.7,0.8,12.9,0,12,0s-1.7,0.8-1.7,1.7v16.4l-5.6-5.6c-0.7-0.7-1.8-0.7-2.4,0c-0.7,0.7-0.7,1.8,0,2.4L10.8,23.5L10.8,23.5z"
            />
        </SvgIcon>
    );
});

export default ArrowDown;
