import React, { useRef } from 'react';
import { Dialog, DialogContent, DialogActions, Grid } from '@mui/material';
import GradientColorHeader from '@icoach/components/dialog/GradientColorHeader';
import { Button, TextField, SelectField, MenuItem } from '@common/components/';
import { refIsRequiredError } from '@util/utils';
import useInBodyApi from '@apis/useInBodyApi';

const InBodyInterviewNoteDialog = React.forwardRef((props, ref) => {
    const {
        open: propsOpen = false,
        onClose: propsOnClose,
        sourceData = {},
        option,
        measureID,
        refresh,
    } = props;
    const { putInterviewNotesApi } = useInBodyApi();
    const interviewNotesRef = useRef(null);
    const employeeIDRef = useRef(null);

    const getAllPatrams = () => {
        return {
            measureID: measureID,
            interviewEmployeeID: employeeIDRef.current.getResult(),
            interviewContent: interviewNotesRef.current.getResult(),
        };
    };

    const handleSendOnClick = () => {
        if (!refIsRequiredError(interviewNotesRef, employeeIDRef)) {
            const params = getAllPatrams();
            doEditInterviewNotes(params);
        }
    };

    const doEditInterviewNotes = async (params) => {
        const resp = await putInterviewNotesApi(params);
        if (resp) {
            propsOnClose && propsOnClose();
            refresh && refresh();
        }
    };

    const { interviewEmployeeID } = sourceData;
    return (
        <Dialog open={propsOpen} PaperProps={{ sx: { width: 1000 } }}>
            <GradientColorHeader onClose={propsOnClose}>面談</GradientColorHeader>
            <DialogContent>
                <Grid container className="pt-4 px-2" spacing={3}>
                    <Grid item xs={12}>
                        <SelectField
                            direction="row"
                            key={propsOpen}
                            label="面談擔當"
                            ref={employeeIDRef}
                            displayEmpty
                            defaultValue={interviewEmployeeID}
                            fullWidth
                            required
                        >
                            <MenuItem value={''}>
                                <em>請選擇</em>
                            </MenuItem>
                            {option.map(({ text, value, disabled }) => (
                                <MenuItem value={value} key={value} disabled={disabled}>
                                    {text}
                                </MenuItem>
                            ))}
                        </SelectField>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            ref={interviewNotesRef}
                            defaultValue={sourceData.interviewContent || null}
                            label="面談內容"
                            rows="10"
                            multiline
                            fullWidth
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button variant={'contained'} onClick={handleSendOnClick}>
                    送出
                </Button>
            </DialogActions>
        </Dialog>
    );
});

export default InBodyInterviewNoteDialog;
