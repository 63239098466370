import React from 'react';
import SvgIcon from '../SvgIcon';
import useForkRef from '@util/hook/useForkRef';

const Info = React.forwardRef((props, ref) => {
    const { className, ...others } = props;
    const handleRef = React.useRef(null);
    const svgRef = useForkRef(handleRef, ref);
    const defaultProperty = {
        className,
        ref: svgRef,
        ...others,
    };
    return (
        <SvgIcon {...defaultProperty}>
            <path d="M.833,16.571h.833V10.929H.833A.809.809,0,0,1,0,10.147V8.281A.809.809,0,0,1,.833,7.5H5.5a.809.809,0,0,1,.833.781v8.29h.833A.809.809,0,0,1,8,17.353v1.866A.809.809,0,0,1,7.167,20H.833A.809.809,0,0,1,0,19.219V17.353A.809.809,0,0,1,.833,16.571ZM4,0A2.911,2.911,0,0,0,1,2.813,2.911,2.911,0,0,0,4,5.625,2.911,2.911,0,0,0,7,2.813,2.911,2.911,0,0,0,4,0Z" transform="translate(8 2)"></path>
        </SvgIcon>
    );
});

export default Info;
