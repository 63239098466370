import React from 'react';
import { Button } from '../../components/';
import useAjax from '../../apis/useAjax';

const DownloadExcel = React.forwardRef((props, ref) => {
    const testParams = { billingCategory: '1', assignmentMonth: '2022-10', orderIDs: [70] };
    const Ajax = useAjax();

    const handleDownloadExcel = () => {
        doDownloadExcel();
    };

    const doDownloadExcel = async () => {
        await Ajax.post(`/api/orders/batch-payments/export-order/`, testParams, {
            headerOption: { responseType: 'blob' },
        });
    };

    return (
        <div className="container m-4">
            <Button variant="contained" onClick={handleDownloadExcel}>
                下載Excel
            </Button>
        </div>
    );
});

export default DownloadExcel;
