import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import { initialDate, parseDate } from '@util/moment';
import { DateTimeStandard } from '../../../../components/pickers/enums/DateTimeStandard';
import { getErrorModuleStatus, refIsRequiredError } from '@util/utils';
import { Card, DateField } from '@common/components/';
import { Grid } from '@mui/material';
import { SectionHeader, SelectWithMenuItems } from '@icoach/documents/cyclePurchase/components/CyclePurchaseUtility';

const ClubSection = forwardRef((props = {}, ref) => {
    const { effectDate, setEffectDate, errorModule = {} } = props;
    const { employeeID, clubID, saleClubID, cycleTimeType: cycleTimeTypeProps, cycleToolType: cycleToolTypeProps } = props.data || {};
    const { coachOptions: employeeOptions, clubOptions, cycleTimeTypeOptions, cycleToolTypeOptions } = props.resource || {};

    const dateRef = useRef(null);
    const timeRef = useRef(null);
    const toolRef = useRef(null);
    const clubRef = useRef(null);
    const saleClubRef = useRef(null);
    const employeeRef = useRef(null);

    const getResult = () => {
        let result = {};
        if (dateRef.current && dateRef.current.getResult) {
            result.cycleOrderDate = parseDate(dateRef.current.getResult(), DateTimeStandard.DisplayUtcFull);
        }
        if (timeRef.current && timeRef.current.getResult) {
            result.cycleTimeType = timeRef.current.getResult();
        }
        if (toolRef.current && toolRef.current.getResult) {
            result.cycleToolType = toolRef.current.getResult();
        }
        if (clubRef.current && clubRef.current.getResult) result.clubID = clubRef.current.getResult();
        if (saleClubRef.current && saleClubRef.current.getResult) result.saleClubID = saleClubRef.current.getResult();
        if (employeeRef.current && employeeRef.current.getResult) result.employeeID = employeeRef.current.getResult();

        return result;
    };

    const handleChangeDate = (node, val) => {
        if (typeof setEffectDate === 'function') setEffectDate(val);
    };

    useImperativeHandle(
        ref,
        () => ({
            getResult,
            isError: () => refIsRequiredError(clubRef, saleClubRef, timeRef, toolRef, dateRef, employeeRef),
            getEffectDate: () => dateRef.current && dateRef.current.getResult(),
        }),
        // eslint-disable-next-line
        [],
    );

    return (
        <React.Fragment>
            <SectionHeader title="店舖教練確認" />
            <Card.CardContent className={'p-4'}>
                <Grid spacing={3} container>
                    <Grid xs={4} item>
                        <DateField
                            key={initialDate(effectDate)}
                            ref={dateRef}
                            label="訂購日期"
                            defaultValue={initialDate(effectDate)}
                            onChange={handleChangeDate}
                            minDate={parseDate()}
                            fullWidth
                            required
                        />
                    </Grid>
                    <Grid xs={4} item>
                        <SelectWithMenuItems
                            refItem={timeRef}
                            label="TIME"
                            defaultValue={cycleTimeTypeProps || null}
                            options={cycleTimeTypeOptions}
                            fullWidth
                            required
                        />
                    </Grid>
                    <Grid xs={4} item>
                        <SelectWithMenuItems
                            refItem={toolRef}
                            label="TOOL"
                            defaultValue={cycleToolTypeProps || null}
                            options={cycleToolTypeOptions}
                            fullWidth
                            required
                        />
                    </Grid>
                    <Grid xs={4} item>
                        <SelectWithMenuItems refItem={clubRef} label={'所屬店舖'} defaultValue={clubID} options={clubOptions} fullWidth required disabled />
                    </Grid>
                    <Grid xs={4} item>
                        <SelectWithMenuItems
                            refItem={saleClubRef}
                            label={'販售店舖'}
                            defaultValue={saleClubID}
                            options={clubOptions}
                            fullWidth
                            required
                            disabled
                        />
                    </Grid>
                    <Grid xs={4} item>
                        <SelectWithMenuItems
                            refItem={employeeRef}
                            label="擔當"
                            defaultValue={employeeID}
                            options={employeeOptions}
                            {...getErrorModuleStatus(errorModule, 'employeeID')}
                            fullWidth
                            required
                        />
                    </Grid>
                </Grid>
            </Card.CardContent>
        </React.Fragment>
    );
});

export default ClubSection;
