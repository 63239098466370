import React, { useRef } from 'react';
import { Box, Stack, Typography } from '@common/components/';
import { MorePageBox } from '@icoach/components';
import { useGiftContext } from '@icoach/gift/components/GiftContext';
import ScrollerArrowButton from '@icoach/inbody/components/ScrollerArrowButton';
import { formatCurrencyFn } from '@util/utils';
import useMessageDialog from '@util/hook/useMessageDialog';
import Tablenization from '@icoach/components/Tablenization';
import { ListInfo } from '@icoach/accountBook/components';

const headerRow = [
    {
        cellKey: 'giftName',
        headerLabel: '贈品名稱',
    },
    {
        cellKey: 'giftPrice',
        headerLabel: '單價',
        align: 'center',
        formatCell: (cellValue) => formatCurrencyFn(cellValue, { symbol: '$' }),
    },
    {
        cellKey: 'giftSpecifications',
        align: 'center',
        headerLabel: '規格',
    },
    {
        cellKey: 'count',
        align: 'center',
        headerLabel: '贈送人數',
    },
];

const GiftDispatchMetrics = () => {
    const { dialogRef, sourceData, bookmark, setBookmarkNum, refresh } = useGiftContext();
    const { pageIndex = bookmark.pageIndex, pageSize = bookmark.pageSize, totalPage, totalCount, list = [] } = sourceData;
    const setMessageDialog = useMessageDialog();
    const scrollerArrowRef = useRef(null);

    const handleChangePage = (e, num) => {
        if (typeof setBookmarkNum === 'function') setBookmarkNum(num);
    };

    const handleChangeGiftStatus = (rowData) => {
        const { status } = rowData;
        const target = status === 1 ? '下架' : '上架';
        setMessageDialog({
            open: true,
            title: '提醒',
            msg: `確定要進行${target}嗎？`,
            onAsyncConfirm: (_, onclose) => {
                console.log('call api');
            },
        });
    };

    return (
        <Box className={'scroll-arrow-container'}>
            <Stack className={'mb-1'} alignItems={'center'} justifyContent={'space-between'}>
                <Typography className={'font-weight-bold'} variant="h5">
                    贈送統計
                </Typography>
                <ListInfo className={'ml-auto'} pageIndex={pageIndex} pageSize={pageSize} totalCount={totalCount} isCurrentPageLastIndex />
            </Stack>
            <Tablenization
                cardProps={{ className: 'theme-gray overview' }}
                page={pageIndex}
                count={totalPage}
                onChange={handleChangePage}
                tableContainerRef={scrollerArrowRef}
                Pagination={MorePageBox}
                isPagination
            >
                <Tablenization.TableRowHeader headerRow={headerRow} />
                <Tablenization.TableRowBody
                    headerRow={headerRow}
                    rowsData={list}
                    refresh={refresh}
                    bodyRowClass={{
                        key: 'isEnabled',
                        value: false,
                        className: 'overview-item-invalid',
                    }}
                    openPromotionPlanDialog={dialogRef.current && dialogRef.current.openPromotionPlanDialog}
                    handleChangeGiftStatus={handleChangeGiftStatus}
                />
            </Tablenization>
            <ScrollerArrowButton parentRef={scrollerArrowRef} />
        </Box>
    );
};

export default GiftDispatchMetrics;
