import React, { useState, useRef, useImperativeHandle, useEffect } from 'react';
import clsx from 'clsx';
import { Checkbox, Table, TableCell, TableHead, TableRow, TableBody, TextField } from '../../../components';
import useGroupCheckbox from '../../../util/hook/useGroupCheckbox';
import { ProductBox } from '../components';
import { formatCurrencyFn, refIsRequiredError } from '../../../util/utils';
import { isEmpty } from 'lodash';

const SalesAllowanceItemByNotSelected = React.forwardRef((props, ref) => {
    const { chkRef, data = {}, selected, handleCheckOpt } = props;
    const { orderItemID, productName, productTypeText, variantName, salePrice, quantity, returnPrice, returnQuantity, onlyAllowancePrice } = data;
    const isSelected = selected.includes(orderItemID);

    return (
        <TableRow className={'cart-table-item fee-item'}>
            <TableCell>
                <Checkbox
                    inputRef={(el) => {
                        if (el && chkRef.current && chkRef.current.indexOf(el) === -1) chkRef.current.push(el);
                    }}
                    defaultChecked={isSelected}
                    onChange={handleCheckOpt}
                    disabled={salePrice === returnPrice}
                />
            </TableCell>
            <TableCell>
                <ProductBox className={'flex-align-center'} name={productName} variantName={variantName} />
            </TableCell>
            <TableCell align={'center'}>{productTypeText}</TableCell>
            <TableCell align={'center'}>{formatCurrencyFn(salePrice)}</TableCell>
            <TableCell align={'center'}>{onlyAllowancePrice ? '' : quantity}</TableCell>
            <TableCell align={'center'}>{onlyAllowancePrice ? '' : returnQuantity}</TableCell>
            <TableCell align={'center'}>
                <strong>{formatCurrencyFn(salePrice * quantity)}</strong>
            </TableCell>
            <TableCell align={'center'}>
                <strong>{formatCurrencyFn(returnPrice)}</strong>
            </TableCell>
        </TableRow>
    );
});
const SalesAllowanceItemBySelect = React.forwardRef((props, ref) => {
    const { data = {}, selected, chkRef, handleCheckOpt, calculateOrderTotal } = props;
    const { orderID, orderItemID, productName, productTypeText, variantName, salePrice, returnPrice, quantity = 1, returnQuantity, onlyAllowancePrice } = data;
    const isSelected = selected.includes(orderItemID);
    const [total, setTotal] = useState(isSelected ? salePrice * quantity : 0);
    const priceRef = useRef();
    const qtyRef = useRef();

    const calculateTotal = () => {
        let price = salePrice,
            qty = quantity;
        if (isSelected) {
            if (priceRef.current) price = parseInt(priceRef.current.getResult());
            if (qtyRef.current) qty = parseInt(qtyRef.current.getResult());
            if (typeof price !== 'number' || typeof qty !== 'number') return 0;
        } else {
            price = 0;
            qty = 1;
        }

        setTotal(price * qty);
    };

    useEffect(() => {
        calculateTotal();
        // eslint-disable-next-line
    }, [isSelected]);

    useEffect(() => {
        calculateOrderTotal();
        // eslint-disable-next-line
    }, [total]);

    useImperativeHandle(
        ref,
        () => ({
            getResult: () => {
                if (!isSelected) return null;
                let allowancePrice = salePrice,
                    allowanceQuantity = quantity;
                if (priceRef.current) allowancePrice = priceRef.current.getResult();
                if (qtyRef.current) allowanceQuantity = qtyRef.current.getResult();
                return {
                    orderItemID,
                    orderID,
                    allowancePrice,
                    allowanceQuantity,
                };
            },
            isError: () => refIsRequiredError(priceRef, qtyRef),
            getTotal: () => total,
        }),
        // eslint-disable-next-line
        [total, isSelected]
    );

    return (
        <TableRow className={'cart-table-item product-item'}>
            <TableCell>
                <Checkbox
                    inputRef={(el) => {
                        if (el && chkRef.current && chkRef.current.indexOf(el) === -1) {
                            chkRef.current.push(el);
                        }
                    }}
                    defaultChecked={isSelected}
                    onChange={handleCheckOpt}
                    disabled={salePrice === returnPrice}
                />
            </TableCell>
            <TableCell>
                <ProductBox className={'flex-align-center'} name={productName} variantName={variantName} />
            </TableCell>
            <TableCell align={'center'}>{productTypeText}</TableCell>
            <TableCell align={'center'}>
                {onlyAllowancePrice ? (
                    <TextField
                        key={salePrice - returnPrice}
                        maskType={'MONEY'}
                        ref={priceRef}
                        defaultValue={salePrice - returnPrice}
                        inputProps={{ placeholder: salePrice, max: salePrice, min: 1 }}
                        disabled={!isSelected}
                        onChangeNative={calculateTotal}
                    />
                ) : (
                    salePrice
                )}
            </TableCell>
            <TableCell align={'center'}>
                {onlyAllowancePrice ? (
                    ''
                ) : (
                    <TextField
                        key={quantity - returnQuantity}
                        maskType={'MONEY'}
                        ref={qtyRef}
                        defaultValue={quantity - returnQuantity}
                        inputProps={{ placeholder: quantity, max: quantity, min: 1 }}
                        disabled={!isSelected}
                        onChangeNative={calculateTotal}
                    />
                )}
            </TableCell>
            <TableCell align={'center'}>
                <strong>{formatCurrencyFn(total)}</strong>
            </TableCell>
        </TableRow>
    );
});
const salesAllowanceItemMap = new Map([
    ['notSelected', SalesAllowanceItemByNotSelected],
    ['selected', SalesAllowanceItemBySelect],
]);
const CartTableHeadByNotSelected = (props) => {
    const { allChkRef, indeterminateRef, isAll, checkboxDisabled = true, handleCheckAll } = props;
    return (
        <TableHead>
            <TableRow>
                <TableCell>
                    <Checkbox
                        inputRef={allChkRef}
                        indeterminateRef={indeterminateRef}
                        defaultChecked={isAll}
                        onChange={handleCheckAll}
                        disabled={checkboxDisabled}
                    />
                </TableCell>
                <TableCell>品項</TableCell>
                <TableCell align={'center'}>類型</TableCell>
                <TableCell align={'center'}>價格</TableCell>
                <TableCell align={'center'}>數量</TableCell>
                <TableCell align={'center'}>已折讓數量</TableCell>
                <TableCell align={'center'}>總計</TableCell>
                <TableCell align={'center'}>已折讓金額</TableCell>
            </TableRow>
        </TableHead>
    );
};
const CartTableHeadBySelect = (props) => {
    const { allChkRef, indeterminateRef, isAll, checkboxDisabled, handleCheckAll } = props;
    return (
        <TableHead>
            <TableRow>
                <TableCell>
                    <Checkbox
                        inputRef={allChkRef}
                        indeterminateRef={indeterminateRef}
                        defaultChecked={isAll}
                        onChange={handleCheckAll}
                        disabled={checkboxDisabled}
                    />
                </TableCell>
                <TableCell>品項</TableCell>
                <TableCell align={'center'}>類型</TableCell>
                <TableCell align={'center'}>可折讓金額</TableCell>
                <TableCell align={'center'}>可折讓數量</TableCell>
                <TableCell align={'center'}>折讓總計</TableCell>
            </TableRow>
        </TableHead>
    );
};
const cartTableHeadMap = new Map([
    ['notSelected', CartTableHeadByNotSelected],
    ['selected', CartTableHeadBySelect],
]);

const CartTableBody = React.forwardRef((props, ref) => {
    const { selectedOptRef, data, target, selected, handleCheckOpt, calculateOrderTotal } = props;
    const SalesAllowanceItem = salesAllowanceItemMap.get(target);
    const itemRef = useRef([]);

    useImperativeHandle(
        ref,
        () => ({
            getResult: () => {
                let items = [];
                if (itemRef.current && Array.isArray(itemRef.current)) {
                    items = itemRef.current.reduce((prev, current) => {
                        let _items = [...prev];
                        let result = current.getResult();
                        if (!isEmpty(result)) _items.push(result);
                        return _items;
                    }, []);
                }
                return items;
            },
            isError: () => {
                let isError = false;
                if (itemRef.current && Array.isArray(itemRef.current)) {
                    itemRef.current.filter((item) => {
                        if (item) {
                            let _isError = item.isError();
                            if (_isError) isError = _isError;
                        }
                        return item;
                    });
                }
                return isError;
            },
            getTotal: () => {
                let total = 0;
                if (itemRef.current && Array.isArray(itemRef.current)) {
                    total = itemRef.current.reduce((total, current) => {
                        let _total = total;
                        if (current) {
                            _total = _total + current.getTotal();
                        }
                        return _total;
                    }, 0);
                }
                return total;
            },
        }),
        // eslint-disable-next-line
        [data]
    );

    return (
        <TableBody>
            {Array.isArray(data) &&
                data.map((item, i) => (
                    <SalesAllowanceItem
                        key={i}
                        ref={(el) => (itemRef.current[i] = el)}
                        index={i}
                        chkRef={selectedOptRef}
                        data={item}
                        selected={selected}
                        handleCheckOpt={handleCheckOpt}
                        calculateOrderTotal={calculateOrderTotal}
                    />
                ))}
        </TableBody>
    );
});

const CartTableHead = (props) => {
    const { allChkRef, indeterminateRef, target, isAll, checkboxDisabled, handleCheckAll } = props;
    const TableHead = cartTableHeadMap.get(target);
    return (
        <TableHead
            isAll={isAll}
            allChkRef={allChkRef}
            indeterminateRef={indeterminateRef}
            handleCheckAll={handleCheckAll}
            checkboxDisabled={checkboxDisabled}
        />
    );
};

const SalesAllowanceItemTable = React.forwardRef((props, ref) => {
    const { className, data, paidPrice, returnPrice, calculateOrderTotal } = props;
    const [checkAllChange, checkOptChange, selectedAllRef, selectedOptRef, indeterminateRef, changeIndeterminate] = useGroupCheckbox(data);
    const [selectedData, setSelectData] = useState({
        orderItemIDs: [],
        isAll: false,
    });
    const getTarget = (selected) => {
        if (selected.length > 0) return 'selected';
        return 'notSelected';
    };

    const selectAll = (data) => {
        let array = [];
        selectedOptRef.current.forEach((item, i) => {
            if (!item.disabled) {
                array.push(data[i].orderItemID);
            }
        });
        return array;
    };

    const selectOrderItem = (selectedOptRef, data) => {
        let orderItemIDs = [];
        let isAll = true;
        selectedOptRef.current &&
            selectedOptRef.current.forEach((item, i) => {
                if (item.checked) {
                    orderItemIDs.push(data[i].orderItemID);
                } else {
                    if (isAll) isAll = false;
                }
            });
        return {
            orderItemIDs,
            isAll,
        };
    };

    const handleCheckAll = (e, checked) => {
        let orderItemIDs = [],
            isAll = checked;
        if (typeof checkAllChange === 'function') checkAllChange(e, checked);
        if (checked) {
            orderItemIDs = selectAll(data);
        }
        setSelectData({
            orderItemIDs,
            isAll,
        });
    };

    const handleCheckOpt = (e) => {
        if (typeof checkOptChange === 'function') checkOptChange(e);

        setSelectData(selectOrderItem(selectedOptRef, data));
    };

    useEffect(() => {
        changeIndeterminate();
        if (typeof calculateOrderTotal === 'function') calculateOrderTotal();
        // eslint-disable-next-line
    }, [selectedData]);

    return (
        <Table className={clsx('cart-table sales-allowance-item-table', className)}>
            <CartTableHead
                allChkRef={selectedAllRef}
                indeterminateRef={indeterminateRef}
                target={getTarget(selectedData.orderItemIDs)}
                isAll={selectedData.isAll}
                checkboxDisabled={paidPrice === returnPrice}
                handleCheckAll={handleCheckAll}
            />
            <CartTableBody
                ref={ref}
                selectedOptRef={selectedOptRef}
                data={data}
                target={getTarget(selectedData.orderItemIDs)}
                selected={selectedData.orderItemIDs}
                handleCheckOpt={handleCheckOpt}
                calculateOrderTotal={calculateOrderTotal}
            />
        </Table>
    );
});

export default SalesAllowanceItemTable;
