import React, { useImperativeHandle, useState, useRef } from 'react';
import SendSmsDialog from '@icoach/components/dialog/SendSmsDialog';
import AppointmentContactDialog from './AppointmentContactDialog';
import useAppointment from '@icoach/appointment/components/useAppointment';

// table 重複元件使用(單一來源彈跳視窗)，如果只有一個元件在使用就不用寫在這
const AppointmentOverviewDialogBox = React.forwardRef((props, ref) => {
    const [openDialog, setOpenDialog] = useState(null);
    const [sourceData, setSourceData] = useState(null);
    const { setDialogRef } = useAppointment();
    const refreshRef = useRef(null);

    const handleCloseDialog = () => {
        setOpenDialog(false);
        setSourceData(null);
    };

    useImperativeHandle(
        setDialogRef,
        () => {
            return {
                openSmsDialog: (targetData, refresh) => {
                    refreshRef.current = refresh;
                    setSourceData(targetData);
                    setOpenDialog('sendSMS');
                },
                openAppointmentContactDialog: (targetData) => {
                    setSourceData(targetData);
                    setOpenDialog('appointmentContact');
                },
                closeDialog: () => handleCloseDialog(),
            };
        },
        [],
    );

    // 以下給的property都是可選(除了open)，*****不是一定要這樣給*****，依照自己的需求給
    return (
        <React.Fragment>
            <SendSmsDialog open={openDialog === 'sendSMS'} onClose={handleCloseDialog} sourceData={sourceData} refresh={refreshRef} isPeopleAppointment />
            <AppointmentContactDialog open={openDialog === 'appointmentContact'} sourceData={sourceData} onClose={handleCloseDialog} />
        </React.Fragment>
    );
});

export default AppointmentOverviewDialogBox;
