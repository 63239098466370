import SmsTemplatePage from '../message/smsTemplate/SmsTemplatePage';
import SmsOverviewPage from '../message/smsOverview/SmsOverviewPage';
import SmsIdOverviewPage from '../message/smsOverview/SmsIdOverviewPage';

const MESSAGE_SMS_TEMPLATE = `/club/message/sms/template/`;
export const MESSAGE_SMS_OVERVIEW = `/club/message/sms/overview/`;
const MESSAGE_SMS_SMSID_OVERVIEW = `/club/message/sms/:smsid/overview/`;
export const MESSAGE_SMS_SMSID_OVERVIEW_URL = (smsid) => `/club/message/sms/${smsid}/overview/`;

const messageRouter = [
    {
        description: '簡訊管理',
        frontKey: 'C9X01',
    },
    {
        description: 'SNS公版設定',
        exact: true,
        path: MESSAGE_SMS_TEMPLATE,
        href: MESSAGE_SMS_TEMPLATE,
        as: SmsTemplatePage,
        frontKey: 'C9X02',
    },
    {
        description: 'SMS簡訊總覽',
        exact: true,
        path: MESSAGE_SMS_OVERVIEW,
        href: MESSAGE_SMS_OVERVIEW,
        as: SmsOverviewPage,
        frontKey: 'C9X03',
    },
    {
        description: 'SMS簡訊總覽明細',
        exact: true,
        path: MESSAGE_SMS_SMSID_OVERVIEW,
        href: MESSAGE_SMS_SMSID_OVERVIEW,
        as: SmsIdOverviewPage,
        frontKey: 'C9X04',
    },
];

export default messageRouter;
