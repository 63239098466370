import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import clsx from 'clsx';
import { Dialog, DialogActions, DialogContent, Grid } from '@mui/material';
import { Button, Form, MenuItem, SelectField, TextField } from '@common/components/';
import GradientColorHeader from '@icoach/components/dialog/GradientColorHeader';
import { refIsRequiredError } from '@util/utils';
import useOrderApi from '@apis/useOrderApi';
import useEmployeeApi from '@apis/useEmployeeApi';

const ReinitOrderDialogDialogContent = React.forwardRef((props, ref) => {
    const { options } = props;
    const employeeRef = useRef();
    const reasonTextRef = useRef();

    useImperativeHandle(
        ref,
        () => ({
            getResult: () => {
                let result = {};
                if (employeeRef.current && employeeRef.current.getResult) {
                    result = Object.assign(result, { employeeID: employeeRef.current.getResult() });
                }
                if (reasonTextRef.current && reasonTextRef.current.getResult) {
                    result = Object.assign(result, {
                        reasonText: reasonTextRef.current.getResult(),
                    });
                }
                return result;
            },
            isError: () => refIsRequiredError(employeeRef, reasonTextRef),
        }), // eslint-disable-next-line
        [],
    );

    return (
        <DialogContent>
            <Grid spacing={3} container>
                <Grid xs={12} item>
                    <SelectField ref={employeeRef} label={'擔當'} fullWidth required>
                        {Array.isArray(options) &&
                            options
                                .filter((item) => item || !item.disabled)
                                .map((item) => (
                                    <MenuItem key={item.value} value={item.value}>
                                        {item.text}
                                    </MenuItem>
                                ))}
                    </SelectField>
                </Grid>
                <Grid xs={12} item>
                    <TextField ref={reasonTextRef} label={'恢復原因'} minRows={2} multiline fullWidth />
                </Grid>
            </Grid>
        </DialogContent>
    );
});

const ReinitOrderDialog = (props) => {
    const { className, open, sourceData, onClose, refresh } = props;
    const orderID = sourceData?.orderID;
    const { putOrderReinitApi } = useOrderApi();
    const { getEmployeesOptionsApi } = useEmployeeApi();
    const [options, setOptions] = useState([]);
    const contentRef = useRef();

    const doOrderReinitApi = async (orderID, params) => {
        let res = await putOrderReinitApi(orderID, params);
        if (res) {
            if (typeof refresh === 'function') refresh();
            if (typeof onClose === 'function') onClose();
        }
    };

    const doEmployeesOptionsApi = async () => {
        let res = await getEmployeesOptionsApi();
        if (res) {
            setOptions(res);
        }
    };
    const getParams = () => {
        let result = {};

        if (contentRef.current && contentRef.current.getResult) {
            result = Object.assign(result, contentRef.current.getResult());
        }

        return result;
    };
    const handleSubmit = () => {
        let isError = contentRef.current && contentRef.current.isError();
        if (!isError) {
            let params = getParams();
            doOrderReinitApi(orderID, params);
        }
    };

    useEffect(() => {
        if (open) doEmployeesOptionsApi();
        // eslint-disable-next-line
    }, [open]);

    if (!open) return null;

    return (
        <Dialog
            className={clsx('simple-content', 'dialog', className)}
            PaperProps={{ className: 'wx-65rem', component: Form }}
            onSubmit={handleSubmit}
            open={open}
            fullWidth
        >
            <GradientColorHeader onClose={onClose}>恢復訂單</GradientColorHeader>
            <ReinitOrderDialogDialogContent ref={contentRef} options={options} />
            <DialogActions>
                <Button type={'submit'} variant="contained">
                    確認送出
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ReinitOrderDialog;
