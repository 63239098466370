import ContractsPage, {
    HealthDeclarationPage,
    MembershipCreatePage,
    MembershipQuitPage,
    MembershipSuspensionPage,
    MembershipTransferInPage,
    MembershipTransferOutPage,
    MembershipTransferOverPage,
} from '@icoach/documents';
import CyclePurchaseOverviewPage from '@icoach/documents/cyclePurchase/CyclePurchaseOverviewPage';
import CyclePurchasePage from '@icoach/documents/cyclePurchase/CyclePurchasePage';
import CyclePurchaseEffectPage from '@icoach/documents/cyclePurchase/CyclePurchaseEffectPage';
import {
    DOCUMENTS_ROUTER,
    DOCUMENTS_MEMBERSHIP_ROUTER,
    DOCUMENTS_MEMBERSHIPSUSPENSION_ROUTER,
    DOCUMENTS_HEALTHDECLARATION_ROUTER,
    DOCUMENTS_MEMBERSHIPTRANSFEROVER_ROUTER,
    DOCUMENTS_MEMBERSHIPTRANSFERIN_ROUTER,
    DOCUMENTS_MEMBERSHIPTRANSFEROUT_PAGE_ROUTER,
    DOCUMENT_CYCLE_PURCHASE_MEMBERID_CYCLEORDERID_ROUTER,
    DOCUMENT_CYCLE_PURCHASE_CREATE_ROUTER,
    DOCUMENT_CYCLE_PURCHASE_EFFECT_ROUTER,
    DOCUMENT_CYCLE_PURCHASE_ROUTER,
    DOCUMENTS_MEMBERSHIP_QUIT_ROUTER,
} from '@icoach/router/routerPath';
import CyclePurchaseDetailPage from '@icoach/documents/cyclePurchase/CyclePurchaseDetailPage';

// 文書管理
const contractsRouter = [
    {
        description: '合約書頁面',
        exact: true,
        path: DOCUMENTS_ROUTER,
        href: DOCUMENTS_ROUTER,
        as: ContractsPage,
        frontKey: 'C4X01',
    },
    {
        description: '入會申請書',
        static: true,
        path: `${DOCUMENTS_MEMBERSHIP_ROUTER}:peopleType?/:referenceID?/:page(history|contract|readonly-contract)?/:contractID?/`,
        as: MembershipCreatePage,
        frontKey: 'C4X02',
    },
    {
        description: '健康狀況申告書',
        exact: true,
        path: `${DOCUMENTS_HEALTHDECLARATION_ROUTER}:peopleType?/:referenceID?/:page(history|contract|readonly-contract)?/:contractID?/`,
        as: HealthDeclarationPage,
        frontKey: 'C4X03',
    },
    {
        description: '會籍轉讓申請書',
        exact: true,
        path: `${DOCUMENTS_MEMBERSHIPTRANSFEROVER_ROUTER}:peopleType?/:referenceID?/:page(history|contract|readonly-contract)?/:contractID?/`,
        as: MembershipTransferOverPage,
        frontKey: 'C4X12',
    },
    {
        description: '國內移籍申請書(移出)',
        exact: true,
        path: DOCUMENTS_MEMBERSHIPTRANSFEROUT_PAGE_ROUTER,
        as: MembershipTransferOutPage,
        frontKey: 'C4X06',
    },
    {
        description: '國內移籍申請書(移入)',
        static: true,
        path: `${DOCUMENTS_MEMBERSHIPTRANSFERIN_ROUTER}:enteredType(unentered|entered)?/:peopleType?/:referenceID?/:page(history|contract|readonly-contract)?`,
        as: MembershipTransferInPage,
        frontKey: 'C4X07',
    },
    {
        description: '會籍暫停申請書',
        exact: true,
        path: `${DOCUMENTS_MEMBERSHIPSUSPENSION_ROUTER}:peopleType?/:referenceID?/:page(history|contract|readonly-contract)?/:contractID?/`,
        as: MembershipSuspensionPage,
        frontKey: 'C4X04',
    },
    {
        description: '會籍終止申請書',
        exact: true,
        path: `${DOCUMENTS_MEMBERSHIP_QUIT_ROUTER}:peopleType?/:referenceID?/:page(history|contract|readonly-contract)?/:contractID?/`,
        as: MembershipQuitPage,
        frontKey: 'C4X05',
    },
    {
        description: '建立健康產品訂購單',
        exact: true,
        path: DOCUMENT_CYCLE_PURCHASE_CREATE_ROUTER,
        as: CyclePurchasePage,
        frontKey: 'C4X09',
    },
    {
        description: '查看健康產品訂購單',
        exact: true,
        path: DOCUMENT_CYCLE_PURCHASE_MEMBERID_CYCLEORDERID_ROUTER,
        as: CyclePurchaseDetailPage,
        frontKey: 'C4X13',
    },
    {
        description: '健康產品訂購變更單',
        exact: true,
        path: DOCUMENT_CYCLE_PURCHASE_EFFECT_ROUTER,
        as: CyclePurchaseEffectPage,
        frontKey: 'C4X10',
    },
    {
        description: '循環訂單總覽',
        exact: true,
        path: DOCUMENT_CYCLE_PURCHASE_ROUTER,
        as: CyclePurchaseOverviewPage,
        frontKey: 'C4X11',
    },
];

export default contractsRouter;
