import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import { refIsRequiredError } from '@util/utils';
import { TermContent } from '@icoach/documents/components';
import { Box, CheckboxField } from '@common/components/';
import { isValidDate, parseDate } from '@util/moment';
import { DateTimeStandard } from '../../../../components/pickers/enums/DateTimeStandard';
import { Grid } from '@mui/material';
import { SignBox } from '@icoach/components';

const TermSection = forwardRef((props, ref) => {
    const { className, data, nextShippingDate } = props;
    const confirmRef = useRef(null);
    const confirmRef2 = useRef(null);
    const signImgRef = useRef(null);

    const getResult = () => {
        let result = {};
        if (signImgRef.current && signImgRef.current.getResult) result = Object.assign(result, { signImg: signImgRef.current.getResult() });

        return result;
    };

    useImperativeHandle(
        ref,
        () => ({
            getResult,
            isError: () => refIsRequiredError(confirmRef, confirmRef2, signImgRef),
        }),
        // eslint-disable-next-line
        [],
    );

    return (
        <TermContent className={className} title="訂購注意事項" data={data}>
            <Box className="bg-info-light px-4 py-2 mb-4">
                <CheckboxField label="" ref={confirmRef} required>
                    <CheckboxField.Checkbox
                        value={true}
                        label={
                            '我已詳細閱讀商品訂購注意事項，且同意授權每月信用卡扣款及辦理退貨時，由韻智股份有限公司代為處理發票銷貨退回證明單，以加速退貨退款作業。'
                        }
                    />
                </CheckboxField>
            </Box>
            {isValidDate(nextShippingDate) && (
                <Box className="bg-info-light px-4 py-2 mb-4">
                    <CheckboxField key={nextShippingDate} label="" ref={confirmRef2} required>
                        <CheckboxField.Checkbox value={true} label={`預計下次出貨日為：${parseDate(nextShippingDate, DateTimeStandard.DisplayDateBySlash)}`} />
                    </CheckboxField>
                </Box>
            )}
            <Grid spacing={3} container>
                <Grid xs={6} item>
                    <SignBox ref={signImgRef} title="訂購人簽名" />
                </Grid>
            </Grid>
        </TermContent>
    );
});

export default TermSection;
