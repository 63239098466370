import React from 'react';
import SvgIcon from '../SvgIcon';
import useForkRef from '../../../util/hook/useForkRef';

const CaSuspended = React.forwardRef((props, ref) => {
    const { className, htmlColor = '#C32C4F', ...others } = props;
    const handleRef = React.useRef(null);
    const svgRef = useForkRef(handleRef, ref);
    const defaultProperty = {
        className,
        ref: svgRef,
        htmlColor,
        ...others,
    };
    return (
        <SvgIcon {...defaultProperty}>
            <path
                d="M8.8,6.2C8.8,6.2,8.8,6.2,8.8,6.2C8.8,6.2,8.8,6.2,8.8,6.2L8.8,6.2z M24,12c0,6.6-5.4,12-12,12S0,18.6,0,12
	S5.4,0,12,0S24,5.4,24,12z M22.9,12C22.9,6,18,1.1,12,1.1S1.1,6,1.1,12S6,22.9,12,22.9S22.9,18,22.9,12z M21.8,12
	c0,5.4-4.4,9.8-9.8,9.8S2.2,17.4,2.2,12S6.6,2.2,12,2.2S21.8,6.6,21.8,12z M8.4,8.8C8.6,8.2,8.6,8,9,8s0.7,1.2,1.3,1.4
	C11,9.6,14,8.5,14.6,8.1c0.6-0.4,0.6-1.7,0.2-2.2c-0.4-0.6-0.5-1.1-0.8-1.8c-0.3-0.7-1.2-0.2-2.1,0.4s-2.2,1-2.7,1.1
	C8.7,5.6,9,6,8.8,6.2C8.5,6.3,8.4,6.1,8.3,5.6C8.2,5.2,7.8,5.1,7.2,5.7C6.6,6.3,7.4,6.4,7.3,6.8c-0.1,0.4-2,0.6-2.3,0.8
	C4.8,7.9,5.3,9.6,5.7,9.8C6.2,10.1,8.1,9.5,8.4,8.8z M9,13.7c0.4-0.3,1.1-0.3,1.6-0.6c0.5-0.3,0.3-0.8,0.7-1c0.4-0.2,0.7,0.6,1.1,1
	c0.4,0.4,1,0.1,1.9-0.2c0.8-0.3,2-0.2,2.5-0.3c0.6-0.1,0.7-0.9,0.1-1.5c-0.6-0.6-0.7-1-0.8-1.7c-0.1-0.7-0.5-0.7-1.1-0.5
	c-0.6,0.1-3.8,1.2-4.1,1.4l0,0c-0.3,0.2,0,1.1-0.5,1.2c-0.5,0.1-0.6-0.3-0.8-0.9c-0.2-0.6-0.8-0.3-1.1,0.1c-0.4,0.3-0.1,0.7-0.1,1
	c0,0.3-0.6,0.5-1.1,0.8c-0.5,0.3-0.3,1.5,0.3,1.9S8.6,13.9,9,13.7z M17.4,13.6c-0.4-0.6-4.1,0.2-4.4,0.6s-0.1,1.4-0.5,1.5h0
	c-0.9,0.2-0.5-0.9-0.7-1.4c-0.2-0.6-1.1-0.4-1.5,0.2c-0.3,0.6,0.8,1.1,0.7,1.4c-0.2,0.3-0.9,0.1-1.6,0.2c-0.7,0.1-0.7,0.5-0.5,1.8
	c0.1,1.2,1,0.9,1.4,0.5c0.4-0.5,1.1-0.7,1.9-1c0.7-0.3,0.5,0.1,0.8,0.6c0.3,0.6,0.9,0.4,1.7,0.4c0.8-0.1,1.5,0.8,2.4,0.3
	c0.9-0.5,0.2-2.1,0.1-2.8C17,15.2,17.8,14.2,17.4,13.6z"
            />
        </SvgIcon>
    );
});

export default CaSuspended;
