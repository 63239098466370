import React, { useRef, useMemo } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import { Grid } from '@mui/material';
import { DOC_INTERNATIONAL_TRANSFER_LINK, DOC_TRAVEL_PASS_LINK } from '@icoach/documents/membership/staticData';
import {
    DOCUMENTS_MEMBERSHIP_ROUTER,
    DOCUMENTS_MEMBERSHIPSUSPENSION_ROUTER,
    DOCUMENTS_HEALTHDECLARATION_ROUTER,
    DOCUMENTS_MEMBERSHIPTRANSFEROVER_ROUTER,
    DOCUMENTS_MEMBERSHIPTRANSFERIN_ROUTER,
    DOCUMENTS_MEMBERSHIPTRANSFEROUT_ROUTER,
    DOCUMENT_CYCLE_PURCHASE_CREATE_ROUTER,
    DOCUMENT_CYCLE_PURCHASE_ROUTER,
    DOCUMENTS_MEMBERSHIP_QUIT_ROUTER,
} from '@icoach/router/routerPath';
import { Card, Typography, Button, Alert, Stack } from '@common/components/';
import useMessageDialog from '@util/hook/useMessageDialog';

const OverNoteComponent = () => {
    return (
        <Stack direction="column">
            <Typography variant={'h5'}>請注意：</Typography>
            <ul className="lists ol">
                <li className="lists-item">轉讓會員須先完成轉讓申請，承接會員才能辦理入會手續。</li>
                <li className="lists-item">他店轉讓者，原始會員須先完成移籍手續。</li>
            </ul>
        </Stack>
    );
};

const OutNoteComponent = () => {
    //TODO: 未來需要改說明文字
    return (
        <Stack direction="column">
            <Typography variant={'h5'}>請注意：</Typography>
            <ul className="lists ol">
                <li className="lists-item">接收店舖若未使用iCoach2.0，移出會員資料將會自動寄送於店舖mail中，請至店舖mail中查詢確認後，email給接收店舖。</li>
            </ul>
        </Stack>
    );
};

const InNoteComponent = () => {
    //TODO: 未來需要改說明文字
    return (
        <Stack direction="column">
            <Typography variant={'h5'}>請注意：</Typography>
            <ul className="lists ol">
                <li className="lists-item">待原始店舖提供會員資料後辦理移入。</li>
            </ul>
        </Stack>
    );
};

const DocumentsPage = (props) => {
    const blankInternationTransferLinkRef = useRef(DOC_INTERNATIONAL_TRANSFER_LINK);
    const blankTravelPassLinkRef = useRef(DOC_TRAVEL_PASS_LINK);
    const setMessageDialog = useMessageDialog();
    const history = useHistory();

    const membershipMenu = useMemo(
        () => [
            {
                text: '入會申請書',
                to: DOCUMENTS_MEMBERSHIP_ROUTER,
            },
            {
                text: '健康狀況申告書',
                to: DOCUMENTS_HEALTHDECLARATION_ROUTER,
            },
            {
                text: '會籍暫停申請書',
                to: DOCUMENTS_MEMBERSHIPSUSPENSION_ROUTER,
            },
            {
                text: '會籍終止申請書',
                to: DOCUMENTS_MEMBERSHIP_QUIT_ROUTER,
            },
            {
                text: '會籍轉讓申請書',
                onClick: () => {
                    setMessageDialog({
                        open: true,
                        title: '會籍轉讓申請書',
                        MsgComp: OverNoteComponent,
                        onAsyncConfirm: (e, onClose) => {
                            history.push(DOCUMENTS_MEMBERSHIPTRANSFEROVER_ROUTER);
                            onClose();
                        },
                    });
                },
            },
            {
                text: '國內會籍移出申請書',
                onClick: () => {
                    setMessageDialog({
                        open: true,
                        title: '國內會籍移出申請書',
                        MsgComp: OutNoteComponent,
                        onAsyncConfirm: (e, onClose) => {
                            history.push(DOCUMENTS_MEMBERSHIPTRANSFEROUT_ROUTER);
                            onClose();
                        },
                    });
                },
            },
            {
                text: '國內會籍移入申請書',
                onClick: () => {
                    setMessageDialog({
                        open: true,
                        title: '國內會籍移入申請書',
                        MsgComp: InNoteComponent,
                        onAsyncConfirm: (e, onClose) => {
                            history.push(DOCUMENTS_MEMBERSHIPTRANSFERIN_ROUTER);
                            onClose();
                        },
                    });
                },
            },
        ],
        // eslint-disable-next-line
        []
    );

    const cyclePurchaseMenu = useMemo(
        () => [
            {
                text: '新增循環訂購單',
                to: DOCUMENT_CYCLE_PURCHASE_CREATE_ROUTER,
            },
            {
                text: '循環訂單總覽',
                to: DOCUMENT_CYCLE_PURCHASE_ROUTER,
            },
        ],
        // eslint-disable-next-line
        []
    );

    return (
        <React.Fragment>
            <div className="container main-container-spacing">
                <Card className="mb-5">
                    <Card.CardContent className="px-4 pb-4 pt-1">
                        <Typography variant="h5" component="h3" className="mb-2">
                            會籍相關
                        </Typography>
                        <Grid container spacing={3}>
                            {membershipMenu.map(({ text, to: pathRouter, onClick }) => {
                                let propertyValue = {
                                    variant: 'outlined',
                                    color: 'secondary',
                                    fullWidth: true,
                                };

                                if (pathRouter) {
                                    propertyValue.to = pathRouter;
                                    propertyValue.component = NavLink;
                                } else if (onClick) {
                                    propertyValue.onClick = onClick;
                                }

                                return (
                                    <Grid key={uuid()} item xs={3}>
                                        <Button {...propertyValue}>{text}</Button>
                                    </Grid>
                                );
                            })}
                        </Grid>
                        <Alert severity="info" variant="filled" color="info" className="my-3">
                            以下為空白表單，提供列印使用。
                        </Alert>
                        <Grid container spacing={3}>
                            <Grid item xs={3}>
                                <Button variant="outlined" color="secondary" fullWidth to={blankInternationTransferLinkRef.current[0].link} target="_blank">
                                    國外移籍申請書
                                </Button>
                            </Grid>
                            <Grid item xs={3}>
                                <Button variant="outlined" color="secondary" fullWidth to={blankTravelPassLinkRef.current[0].link} target="_blank">
                                    旅行卡 Travel Pass
                                </Button>
                            </Grid>
                        </Grid>
                    </Card.CardContent>
                </Card>
                <Card>
                    <Card.CardContent className="px-4 pb-4">
                        <Typography variant="h5" component="h3" className="mb-2">
                            健康產品相關
                        </Typography>
                        <Grid container spacing={3}>
                            {cyclePurchaseMenu.map(({ text, to: pathRouter }) => (
                                <Grid key={uuid()} item xs={3}>
                                    <Button variant="outlined" color="secondary" onClick={() => history.push(pathRouter)} fullWidth>
                                        {text}
                                    </Button>
                                </Grid>
                            ))}
                        </Grid>
                    </Card.CardContent>
                </Card>
            </div>
        </React.Fragment>
    );
};

export default DocumentsPage;
