import { useCallback } from 'react';
import useAjax from '@apis/useAjax';
import usePeopleApi from '@apis/usePeopleApi';
import useShowLoading from '@util/hook/useShowLoading';
import { useSnackbar } from 'notistack';

const POST_CUSTOMERS_OVERVIEW_OPTIONS_API = `/api/customers/overview/opitons/`;
const GET_CUSTOMERS_OVERVIEW_API = `/api/customers/overview/`;
const GET_CUSTOMERS_CUSTOMERID_API = (customerID) => `/api/customers/${customerID}/`;
const PUT_CUSTOMERS_CUSTOMERID_API = GET_CUSTOMERS_CUSTOMERID_API;
const POST_CUSTOMERS_API = '/api/customers/';
const DELETE_CUSTOMERS_API = POST_CUSTOMERS_API;
const PUT_CUSTOMERS_JOIN_INVALID_API = '/api/customers/join-invalid/';

const useCustomerApi = () => {
    const Ajax = useAjax();
    const isLoading = useShowLoading();
    const { getPeopleApi } = usePeopleApi();
    const { enqueueSnackbar } = useSnackbar();

    // 顧客總覽 options
    const getCustomersOverviewOptionsApi = useCallback(
        async () => {
            const resp = await Ajax.post(POST_CUSTOMERS_OVERVIEW_OPTIONS_API);
            if (!resp) enqueueSnackbar('讀取資料失敗,請重新整理', { variant: 'error' });
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    // 顧客清單
    const postCustomersOverviewApi = useCallback(
        async () => {
            const resp = await Ajax.post(GET_CUSTOMERS_OVERVIEW_API);
            if (!resp) enqueueSnackbar('查詢失敗,請再操作一次', { variant: 'error' });
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    // 顧客 / 名單 資訊頁初始用
    const getCustomerInfoApi = useCallback(
        async (customerID) => {
            const resp = await Ajax.get(GET_CUSTOMERS_CUSTOMERID_API(customerID));
            if (!resp) enqueueSnackbar('讀取資料失敗,請重新整理', { variant: 'success' });
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    // 新增詢問初始資料
    const getCutomnerOptionsApi = useCallback(
        async (customerID = 0) => {
            let result = {};
            isLoading(true);
            const customersData = await Ajax.get(GET_CUSTOMERS_CUSTOMERID_API(customerID));
            if (customersData) Object.assign(result, { ...customersData });
            const customerOptions = await getPeopleApi(9);
            const invalidMembersOptions = await getPeopleApi(3);
            if (customerOptions || invalidMembersOptions) {
                const peopleOptions = [
                    ...(Array.isArray(customerOptions) ? customerOptions : []),
                    ...(Array.isArray(invalidMembersOptions)
                        ? invalidMembersOptions.map((tar) => {
                              tar.isMember = true;
                              return tar;
                          })
                        : []),
                ];
                Object.assign(result, {
                    resource: {
                        ...(result.resource || {}),
                        peopleOptions: peopleOptions,
                    },
                });
            }
            isLoading(false);
            return result;
        },
        // eslint-disable-next-line
        []
    );

    // 顧客 / 名單 資訊頁 修改基本資訊
    const putCustomersInfoApi = useCallback(
        async (customerID, params) => {
            const resp = await Ajax.put(PUT_CUSTOMERS_CUSTOMERID_API(customerID), params);
            if (resp) {
                enqueueSnackbar('編輯成功', { variant: 'success' });
            } else {
                enqueueSnackbar('編輯失敗,請再操作一次', { variant: 'error' });
            }
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    // 新增詢問 初使用
    const postCustomersApi = useCallback(
        async (params) => {
            const resp = await Ajax.post(POST_CUSTOMERS_API, params);
            if (resp) {
                enqueueSnackbar('新增成功', { variant: 'success' });
            } else {
                enqueueSnackbar('新增失敗,請再操作一次', { variant: 'error' });
            }
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    // 修改為無效
    const putCustomersJoinInvalidApi = useCallback(
        async (params) => {
            const resp = await Ajax.put(PUT_CUSTOMERS_JOIN_INVALID_API, params);
            if (resp) enqueueSnackbar('已變為無效', { variant: 'success' });
            else enqueueSnackbar('修改無效失敗,請重新操作', { variant: 'error' });
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    // 刪除 顧客 或 名單
    const deleteCustomersRemove = useCallback(
        async (params) => {
            const resp = await Ajax.delete(DELETE_CUSTOMERS_API, params);
            if (resp) enqueueSnackbar('刪除成功', { variant: 'success' });
            else enqueueSnackbar('刪除失敗,請重新操作', { variant: 'error' });
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    return {
        getCustomerInfoApi,
        getCustomersOverviewOptionsApi,
        postCustomersOverviewApi,
        getCutomnerOptionsApi,
        postCustomersApi,
        putCustomersInfoApi,
        putCustomersJoinInvalidApi,
        deleteCustomersRemove,
    };
};

export default useCustomerApi;
