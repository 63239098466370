import React from 'react';
import '../../scss/error/_notFoundPDFPage.scss';

const NotFoundPDFPage = () => {
    return (
        <div className={'not-found-pdf-page'}>
            <div className="container">
                <div className="img-action"></div>
                <div className="content">
                    <h1 className={'title'}>Oops!</h1>
                    <h2 className={'sub-title'}>造成無法開啟的原因有可能是</h2>
                    <ul className={'list'}>
                        <li>在1.0操作的，套表不會導進2.0唷！</li>
                        <li>該筆資料非所屬店舖，不能查看唷！</li>
                        <li>如果是量身套表無法開啟，請重新測量或者關閉重整!</li>
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default NotFoundPDFPage;
