import React, { useEffect, useState } from 'react';
import { Dialog, DialogContent } from '@mui/material';
import clsx from 'clsx';
import GradientColorHeader from '@icoach/components/dialog/GradientColorHeader';
import useMembershipApi from '@apis/useMembershipApi';
import { Stack } from '@common/components/';
import { ListInfo } from '@icoach/accountBook/components';
import { BillingRecordTable } from '@icoach/members/memberDetail/table';
import { initBookmark } from '@icoach/members/staticData';

const MembershipBillingRecordDialogContent = (props) => {
    const { data, bookmark, handlePageChange } = props;
    const { list = [], totalCount, totalPage, pageIndex = bookmark.pageIndex, pageSize = bookmark.pageSize } = data;

    return (
        <DialogContent>
            <Stack className={'mb-2'} alignItems={'center'} justifyContent={'end'}>
                <ListInfo
                    className={'ml-auto'}
                    pageIndex={pageIndex}
                    padeSize={pageSize}
                    totalCount={totalCount}
                    isCurrentPageFirstIndex
                    isCurrentPageLastIndex
                />
            </Stack>
            <BillingRecordTable className={'theme-gray'} rowsData={list} page={pageIndex} count={totalPage} handlePageChange={handlePageChange} />
        </DialogContent>
    );
};

const MembershipBillingRecordDialog = (props) => {
    const { className, open, memberID, onClose } = props;
    const { postMemberMembershipOrdersApi } = useMembershipApi();
    const [bookmark, setBookmark] = useState(initBookmark());
    const [data, setData] = useState([]);

    const doMemberMembershipOrders = async (memberID, params) => {
        let res = await postMemberMembershipOrdersApi(memberID, params);
        if (res) {
            setData(res);
        }
    };

    const handlePageChange = (e, num) => {
        setBookmark((prev) => ({
            ...prev,
            pageIndex: num,
        }));
    };

    useEffect(
        () => {
            if (open && memberID) doMemberMembershipOrders(memberID, bookmark);
        },
        // eslint-disable-next-line
        [open, memberID, bookmark]
    );

    return (
        <Dialog className={clsx('simple-content', 'dialog', className)} PaperProps={{ className: 'wx-90rem' }} open={open} fullWidth>
            <GradientColorHeader onClose={onClose}>會籍付款紀錄</GradientColorHeader>
            <MembershipBillingRecordDialogContent data={data} bookmark={bookmark} handlePageChange={handlePageChange} />
        </Dialog>
    );
};

export default MembershipBillingRecordDialog;
