// redux
import { combineReducers } from 'redux';
// types
import { SET_MENUS_DATA } from './types';

const interfaceMenuDate = {
    menulist: [],
    oriMenuData: [],
};

export function MenusData(state = { Data: interfaceMenuDate }, action) {
    switch (action.type) {
        case SET_MENUS_DATA:
            if (action.Data === null) {
                action.Data = {};
            }
            return { Data: action.Data };

        default:
            return state;
    }
}

const Reducer = combineReducers({
    MenusData,
});

export default Reducer;
