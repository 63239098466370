import React from 'react';
import clsx from 'clsx';
import { Dialog, DialogActions, DialogContent } from '@mui/material';
import { Button, Typography } from '@common/components/';
import GradientColorHeader from '@icoach/components/dialog/GradientColorHeader';

const ConfirmTemporaryContractDialog = (props) => {
    const {
        className,
        open,
        handleClose,
        handleClickYes: handleClickYesFn,
        handleClickNo: handleClickNoFn,
    } = props;
    const handleCloseEvent = (e) => {
        if (typeof handleClose === 'function') handleClose(e);
    };

    const handleClickYes = (e) => {
        if (typeof handleClickYesFn === 'function') handleClickYesFn();
    };

    const handleClickNo = (e) => {
        if (typeof handleClickNoFn === 'function') handleClickNoFn();
    };
    return (
        <Dialog open={open} fullWidth className={clsx('simple-content', 'dialog', className)}>
            <GradientColorHeader onClose={handleCloseEvent}>是否使用前次資料</GradientColorHeader>
            <DialogContent>
                <Typography paragraph>有前次編輯紀錄，是否使用前次資料繼續編輯？</Typography>
            </DialogContent>
            <DialogActions>
                <Button color="error" variant="contained" onClick={handleClickNo}>
                    否，清除資料
                </Button>
                <Button color="success" variant="contained" onClick={handleClickYes}>
                    是，繼續編輯
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmTemporaryContractDialog;
