import React from 'react';
import { Card } from '@common/components/';
import clsx from 'clsx';
import { Grid } from '@mui/material';
import { ReadTextField } from '@icoach/components';
import { displayDateText } from '@util/moment';

const QuitSectionByRead = (props) => {
    const { titleClassName = 'bg-light-active', data = {} } = props;
    const { unsubscribeDate, unsubscribeReasonText, unsubscribeNote } = data;

    return (
        <React.Fragment>
            <Card.CardHeader title="退訂" className={clsx('px-4', titleClassName)} />
            <Card.CardContent className={'p-4'}>
                <Grid spacing={3} container>
                    {unsubscribeDate && (
                        <Grid xs={6} item>
                            <ReadTextField label={'退訂日期'}>{displayDateText(unsubscribeDate)}</ReadTextField>
                        </Grid>
                    )}

                    <Grid xs={6} item>
                        <ReadTextField label={'退訂原因'}>{unsubscribeReasonText}</ReadTextField>
                    </Grid>
                    <Grid xs={12} item>
                        <ReadTextField label={'退訂原因補充說明'}>{unsubscribeNote}</ReadTextField>
                    </Grid>
                </Grid>
            </Card.CardContent>
        </React.Fragment>
    );
};

export default QuitSectionByRead;
