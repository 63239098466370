import { useCallback } from 'react';
import useAjax from '@apis/useAjax';
import { checkResponse } from '@apis/utils';

const GET_GIFT_ITEM_ENABLED_API = `/api/gift-item/enabled/`;
const GET_GIFT_ITEM_OPTIONS_API = `/api/gift-item/options/`;
const GET_GIFT_ITEM_OVERVIEW_API = `/api/gift-item/overview/`;
const POST_GIFT_ITEM_API = `/api/gift-item/`;
const PUT_GIFT_ITEM_API = (giftID) => `/api/gift-item/${giftID}`;
const PUT_GIFT_ITEM_ENABLED_API = (giftID) => `/api/gift-item/${giftID}/enabled/`;

const useGiftItemApi = () => {
    const Ajax = useAjax();

    const getGiftItemEnabledApi = useCallback(
        async () => {
            const resp = await Ajax.get(GET_GIFT_ITEM_ENABLED_API);
            if (checkResponse(resp)) return resp;
            return false;
        },
        // eslint-disable-next-line
        []
    );

    const getGiftItemOptionsApi = useCallback(
        async () => {
            const resp = await Ajax.get(GET_GIFT_ITEM_OPTIONS_API);
            if (checkResponse(resp)) return resp;
            return false;
        },
        // eslint-disable-next-line
        []
    );

    const getGiftItemOverviewApi = useCallback(
        async (params) => {
            const resp = await Ajax.get(GET_GIFT_ITEM_OVERVIEW_API, params);
            if (checkResponse(resp)) return resp;
            return false;
        },
        // eslint-disable-next-line
        []
    );

    const postGiftItemApi = useCallback(
        async (params) => {
            const resp = await Ajax.post(POST_GIFT_ITEM_API, params);
            if (checkResponse(resp)) return resp;
            return false;
        },
        // eslint-disable-next-line
        []
    );

    const putGiftItemApi = useCallback(
        async (giftID, params) => {
            const resp = await Ajax.put(PUT_GIFT_ITEM_API(giftID), params);
            if (checkResponse(resp)) return resp;
            return false;
        },
        // eslint-disable-next-line
        []
    );

    const putGiftItemEnabledApi = useCallback(
        async (giftID, params) => {
            const resp = await Ajax.put(PUT_GIFT_ITEM_ENABLED_API(giftID), params);
            if (checkResponse(resp)) return resp;
            return false;
        },
        // eslint-disable-next-line
        []
    );

    return {
        getGiftItemEnabledApi,
        getGiftItemOptionsApi,
        getGiftItemOverviewApi,
        postGiftItemApi,
        putGiftItemApi,
        putGiftItemEnabledApi,
    };
};

export default useGiftItemApi;
