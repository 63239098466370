import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import useAjax from '@apis/useAjax';
import { localUser } from '@util/utils';
import { getHistoryLockData } from '@redux/historyLock/selector';

const POST_AUTH_LOGIN_API = `/api/login/`;
const GET_AUTH_LOGOUT_API = `/api/logout/`;

const useAuthApi = () => {
    const Ajax = useAjax();
    const history = useHistory();
    const isHistoryLock = useSelector(getHistoryLockData);

    const postLoginApi = useCallback(
        async (params) => {
            const resp = await Ajax.post(POST_AUTH_LOGIN_API, params);
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    const getLogoutApi = useCallback(
        async () => {
            const { isLock, fn } = isHistoryLock;
            if (isLock) {
                if (window.confirm('此動作將會遺失資料')) {
                    window.removeEventListener('beforeunload', fn);
                } else {
                    return null;
                }
            }
            await Ajax.get(
                GET_AUTH_LOGOUT_API,
                {},
                {
                    callbackfn: (resultData, respBody) => {
                        const { success, statusCode } = respBody.data;
                        localUser.logout();
                        if (success && statusCode === '0000') {
                            history.push('/login');
                            window.location.reload();
                        } else {
                            alert('登出失敗請再操作一次');
                        }
                    },
                }
            );
        },
        // eslint-disable-next-line
        [isHistoryLock]
    );

    return {
        postLoginApi,
        getLogoutApi,
    };
};

export default useAuthApi;
