import React, { useImperativeHandle, useRef, useState } from 'react';
import clsx from 'clsx';
import { Grid } from '@mui/material';
import { Box, Button, Form, SelectField, MenuItem, Typography, Alert } from '@common/components/';
import useOrderBatchPaymentApi from '@apis/useOrderBatchPaymentApi';
import { formatCurrencyFn, refIsRequiredError } from '@util/utils';
import { bulkDebitBillingType as bulkDebitBillingTypeMap } from '@icoach/accountBook/staticData';
import useMessageDialog from '@util/hook/useMessageDialog';
import { parseDate } from '@util/moment';

const BatchPaymentController = React.forwardRef((props, ref) => {
    const { className, billingCategory, assignmentMonth, tableRef, resource = {}, handleCancel, alertInvoiceWordSetting, refresh, paramData } = props;
    const { coachOptions: employeeOptions } = resource;
    const setMessageDialog = useMessageDialog();
    const { postOrderBatchPaymentConfirmApi } = useOrderBatchPaymentApi();
    const [totalOrderAmount, setTotalOrderAmount] = useState(0);
    const [count, setCount] = useState(0);
    const employeeRef = useRef(null);

    const refreshFn = () => {
        const { date } = paramData;
        const today = new Date();
        const isCurrentMonth = new Date(date).getMonth() === today.getMonth();
        if (!isCurrentMonth) {
            setMessageDialog({
                open: true,
                title: '提示',
                msg: `扣款結果會顯示於${parseDate(today, 'YYYY/MM')}的扣款結果列表中，請問要轉跳到該頁面嗎？`,
                buttonText: '確認轉跳',
                onConfirm: () => {
                    const _paramData = {
                        ...paramData,
                        bulkDebitBillingType: bulkDebitBillingTypeMap.billing,
                        date: parseDate(today, 'YYYY-MM'),
                    };
                    if (typeof refresh === 'function') refresh(_paramData);
                },
            });
        } else {
            refresh();
        }
    };

    const getParams = () => {
        let result = {
            assignmentMonth,
            billingCategory,
        };

        if (employeeRef.current && employeeRef.current.getResult) {
            result = Object.assign(result, { employeeID: employeeRef.current.getResult() });
        }

        if (tableRef.current && tableRef.current.getCurrentData) {
            const currentData = tableRef.current.getCurrentData() || [];
            result = Object.assign(result, { orderIDs: currentData.map((item) => item.orderID) });
        }

        return result;
    };

    const checkError = () => refIsRequiredError(employeeRef);

    const doOrderBatchPaymentConfirm = async (params) => {
        let res = await postOrderBatchPaymentConfirmApi(params);
        if (res) {
            refreshFn();
            if (typeof handleCancel === 'function') handleCancel();
        }
    };

    const handleSubmit = () => {
        let isError = checkError();
        if (!isError) {
            const params = getParams();
            if (params.orderIDs.length > 0) {
                doOrderBatchPaymentConfirm(params);
            }
        }
    };

    useImperativeHandle(
        ref,
        () => ({
            setTotalOrderAmount,
            setCount,
        }),
        // eslint-disable-next-line
        [],
    );

    return (
        <Box className={clsx('mb-2 bulk-controller-box', className)}>
            <Form onSubmit={handleSubmit}>
                <Grid spacing={3} container>
                    <Grid xs={8} item>
                        <Typography className={'font-weight-bold'} variant={'body1'}>
                            請選擇批次扣款的名單
                        </Typography>
                        <Typography variant={'body1'}>
                            扣款筆數：<strong className={'h6 text-primary'}>{count}</strong>
                        </Typography>
                        <Typography className={'mb-0'} variant={'body1'}>
                            扣款總金額：
                            <strong className={'h6 text-primary'}>{formatCurrencyFn(totalOrderAmount, { symbol: '$' })}</strong>
                        </Typography>
                    </Grid>
                    <Grid xs={4} item>
                        <SelectField className={'mb-2'} ref={employeeRef} label={'擔當'} fullWidth required>
                            {Array.isArray(employeeOptions) &&
                                employeeOptions
                                    .filter((item) => item || !item.disabled)
                                    .map((item) => (
                                        <MenuItem key={item.value} value={item.value}>
                                            {item.text}
                                        </MenuItem>
                                    ))}
                        </SelectField>
                        <Box className={'btn-group'}>
                            <Button color={'grey'} variant={'outlined'} onClick={handleCancel}>
                                取消
                            </Button>
                            <Button type={'submit'} variant={'outlined'}>
                                進行批次扣款
                            </Button>
                        </Box>
                    </Grid>
                    {alertInvoiceWordSetting && (
                        <Grid xs={12} item>
                            <Alert severity="error" variant="filled" color="error">
                                當月發票字軌不足，請至綠界申請
                            </Alert>
                        </Grid>
                    )}
                </Grid>
            </Form>
        </Box>
    );
});

export default BatchPaymentController;
