import React from 'react';
import { Grid } from '@mui/material';
import { ReadTextField } from '../../components';
import { Stack, IconButton } from '../../../components/';
import { Edit as EditIcon } from '../../../components/icons/SvgIcon/';
import { matchOptionsText } from '../../../util/utils';
import { parseDate } from '../../../util/moment';

const EmployeesInformationsBasicReadContent = React.forwardRef((props, ref) => {
    const { sourceData, options, onEditClick: onEditClickProps } = props;
    const { employeeRankTypeOptions, employeeStatusOptions } = options;
    const {
        employeeName,
        nickName,
        birthday,
        jobTitleType,
        identifierID,
        isForeigner,
        email,
        mobile,
        cardNo,
        permanentPostCode,
        permanentAddress,
        correspondencePostCode,
        correspondenceAddress,
        hireDate,
        contacts,
        employeeStatus,
        resignDate,
    } = sourceData;

    const handleEditClick = () => onEditClickProps && onEditClickProps();
    const isResignation = String(employeeStatus) === '2';

    return (
        <React.Fragment>
            <Stack justifyContent="end">
                <IconButton size="small" onClick={handleEditClick}>
                    <EditIcon />
                </IconButton>
            </Stack>
            <Grid container spacing={3}>
                <Grid xs={4} item>
                    <ReadTextField label={'員工姓名'}>{employeeName}</ReadTextField>
                </Grid>
                <Grid xs={4} item>
                    <ReadTextField label={'名牌名稱'}>{nickName}</ReadTextField>
                </Grid>
                <Grid xs={4} item>
                    <ReadTextField label={'出生日'}>{parseDate(birthday)}</ReadTextField>
                </Grid>
                <Grid xs={4} item>
                    <ReadTextField label={'職稱'}>{matchOptionsText(employeeRankTypeOptions, jobTitleType)}</ReadTextField>
                </Grid>
                <Grid xs={4} item>
                    <ReadTextField label={'身分證字號'}>{identifierID}</ReadTextField>
                </Grid>
                <Grid xs={4} item>
                    <ReadTextField label={'非本國人'}>{isForeigner ? '是' : '否'}</ReadTextField>
                </Grid>
                <Grid xs={4} item>
                    <ReadTextField label={'電子信箱'}>{email}</ReadTextField>
                </Grid>
                <Grid xs={4} item>
                    <ReadTextField label={'手機號碼'}>{mobile}</ReadTextField>
                </Grid>
                <Grid xs={4} item>
                    <ReadTextField label={'卡號'}>{cardNo}</ReadTextField>
                </Grid>
                <Grid xs={4} item>
                    <ReadTextField label={'戶籍區碼'}>{permanentPostCode}</ReadTextField>
                </Grid>
                <Grid xs={8} item>
                    <ReadTextField label={'戶籍地址'}>{permanentAddress}</ReadTextField>
                </Grid>
                <Grid xs={4} item>
                    <ReadTextField label={'通訊區碼'}>{correspondencePostCode}</ReadTextField>
                </Grid>
                <Grid xs={8} item>
                    <ReadTextField label={'通訊地址'}>{correspondenceAddress}</ReadTextField>
                </Grid>
                {contacts.map((target) => {
                    return (
                        <React.Fragment key={target.employeeContactID}>
                            <Grid xs={4} item>
                                <ReadTextField label={'聯絡人姓名'}>{target.name}</ReadTextField>
                            </Grid>
                            <Grid xs={4} item>
                                <ReadTextField label={'聯絡人電話'}>{target.mobile}</ReadTextField>
                            </Grid>
                            <Grid xs={4} item>
                                <ReadTextField label={'與聯絡人關係'}>{target.relationship}</ReadTextField>
                            </Grid>
                        </React.Fragment>
                    );
                })}

                <Grid xs={4} item>
                    <ReadTextField label={'到職日'}>{parseDate(hireDate)}</ReadTextField>
                </Grid>
                <Grid xs={4} item>
                    <ReadTextField label={'狀態'}>{matchOptionsText(employeeStatusOptions, employeeStatus)}</ReadTextField>
                </Grid>
                {isResignation && (
                    <Grid xs={4} item>
                        <ReadTextField label={'離職日期'}>{parseDate(resignDate)}</ReadTextField>
                    </Grid>
                )}
            </Grid>
        </React.Fragment>
    );
});

export default EmployeesInformationsBasicReadContent;
