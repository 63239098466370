import React, { useRef, useState, useEffect } from 'react';
import GuestProductionSaerchBar from '@icoach/nonMember/guestProduction/GuestProductionSaerchBar';
import GuestProductionTable from '@icoach/nonMember/guestProduction/GuestProductionTable';
import { initBookmark } from '@icoach/nonMember/staticData';
import NonMemberContext from '@icoach/nonMember/components/NonMemberContext';
import { Card, Typography } from '@common/components/';
import useCustomerManufactureApi from '@apis/useCustomerManufactureApi';
import useEmployeeApi from '@apis/useEmployeeApi';
import useShowLoading from '@util/hook/useShowLoading';

const GuestProductionPage = () => {
    const showLoading = useShowLoading();
    const { getCustomerManufactureOptionsApi } = useCustomerManufactureApi();
    const { getEmployeesOptionsApi } = useEmployeeApi();
    const [sourceData, setSourceData] = useState({});
    const [optionEnums, setOptionEnums] = useState({});
    const [bookmark, setBookmark] = useState({
        ...initBookmark,
    });

    const refreshRef = useRef(null);

    const childContext = {
        optionEnums,
        sourceData,
        setSourceData,
        bookmark: bookmark,
        setBookmarkNum: (num) => setBookmark((pre) => ({ ...pre, pageIndex: num })),
        refresh: refreshRef,
        setRefresh: (el) => (refreshRef.current = el),
    };

    useEffect(
        () => {
            const doApi = async () => {
                showLoading(true);
                let options = {};
                let resp = await getCustomerManufactureOptionsApi();
                options = Object.assign(options, { saleTypeOption: resp.customerManufactureType });
                resp = await getEmployeesOptionsApi();
                options = Object.assign(options, { coachsOption: resp });
                setOptionEnums(options);
                showLoading(false);
            };
            doApi();
        },
        //eslint-disable-next-line
        []
    );

    return (
        <NonMemberContext.Provider value={childContext}>
            <div className="container main-container-spacing">
                <header className={'page-header'}>
                    <Typography className={'page-header-title'} variant={'h3'}>
                        主動顧客製造
                    </Typography>
                </header>

                <Card>
                    <Card.CardContent>
                        <GuestProductionSaerchBar />
                    </Card.CardContent>
                </Card>
                <div className="non-member-content">
                    <GuestProductionTable />
                </div>
            </div>
        </NonMemberContext.Provider>
    );
};

export default GuestProductionPage;
