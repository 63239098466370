import React, { useRef } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import Tablenization from '@icoach/components/Tablenization';
import {
    DOCUMENTS_MEMBERSHIPTRANSFEROUT_UNMOVEOUT_PEOPLETYPE_REFERENCEID_CONTRACT_ROUTER,
    DOCUMENTS_MEMBERSHIPTRANSFEROUT_MOVEOUT_PEOPLETYPE_REFERENCEID_READONLY_CONTRACT_PATH,
    DOCUMENTS_MEMBERSHIPTRANSFEROUT_UNMOVEOUT_PEOPLETYPE_REFERENCEID_READONLY_CONTRACT_PATH,
} from '@icoach/router/routerPath';
import { parseDate } from '@util/moment';
import { Typography } from '@common/components/';
import { pathRouterShit } from '@util/utils';
import useDocMembershipTransferOutApi from '@apis/useDocMembershipTransferOutApi';
import { peopleType as peopleTypeMap } from '@apis/usePeopleApi';
import { moreColData } from '@icoach/documents/components/moreColData';
import MembershipTransferOutDialogBox from '@icoach/documents/membership/transferOut/MembershipTransferOutDialogBox';
import { DateTimeStandard } from '../../../components/pickers/enums/DateTimeStandard';

const isMoveoutTypeMap = [peopleTypeMap.unConfirmTransferOutMember, peopleTypeMap.alreadyTransferOutMember];

const rowsLabel = [
    {
        cellKey: 'applyDate',
        headerLabel: '申請時間',
        width: 20,
        formatCell: (cellValue) => parseDate(cellValue, DateTimeStandard.DisplayDateBySlash, false),
    },
    {
        cellKey: 'changeDate',
        headerLabel: '最後更新時間',
        width: 20,
        formatCell: (cellValue) => parseDate(cellValue, DateTimeStandard.DisplayFullBySlash, false),
    },
    {
        cellKey: 'isTemporary',
        headerLabel: '狀態',
        align: 'center',
        formatCell: (isTemporary, { isDeleted }) => {
            return isTemporary ? '暫存' : isDeleted ? '已刪除' : '已受理';
        },
    },
    {
        cellKey: 'transferOutClubText',
        headerLabel: '說明',
        align: 'center',
        className: 'white-space-normal',
        width: 30,
        formatCell: (value) => {
            return <Typography>{value}</Typography>;
        },
    },
    moreColData,
];

const MembershipTransferOutHistoryTable = (props) => {
    const { className, sourceData = [], onReload } = props;
    const history = useHistory();
    const { peopleType: peopleTypeProps, referenceID: referenceIDProps } = useParams();
    const { deleteTransferOutContractApi, deleteDocTemporaryTransferOutContractApi, downloadTransferOutPDFApi } = useDocMembershipTransferOutApi();
    const membershipTransferOutDialogBoxRef = useRef(null);
    const labelTitle = useRef(rowsLabel);

    // 查看會籍移出合約 icon
    const handleReviewOnClick = ({ contractID }) => {
        let path;
        if (isMoveoutTypeMap.includes(String(peopleTypeProps))) {
            path = DOCUMENTS_MEMBERSHIPTRANSFEROUT_MOVEOUT_PEOPLETYPE_REFERENCEID_READONLY_CONTRACT_PATH(peopleTypeProps, referenceIDProps, contractID);
        } else {
            path = DOCUMENTS_MEMBERSHIPTRANSFEROUT_UNMOVEOUT_PEOPLETYPE_REFERENCEID_READONLY_CONTRACT_PATH(peopleTypeProps, referenceIDProps, contractID);
        }
        history.push(path);
    };

    // 編輯會籍移出合約 icon
    const handleEditOnClick = ({ contractID }) => {
        const path = pathRouterShit(DOCUMENTS_MEMBERSHIPTRANSFEROUT_UNMOVEOUT_PEOPLETYPE_REFERENCEID_CONTRACT_ROUTER, {
            peopleType: peopleTypeProps,
            referenceID: referenceIDProps,
            contractID: contractID,
        });
        history.push(path);
    };

    // 下載 icon
    const handleDownloadPDFOnClick = (rowData) => {
        const { mainID, contractID, contractUrl } = rowData;
        doTransferOverPDFApi({ memberID: referenceIDProps, mainID, contractID, contractUrl });
    };

    const handleTransferOutConfirm = (rowData) => {
        membershipTransferOutDialogBoxRef.current.openMembershipTransferOutMoveOutDialog({ ...rowData, memberID: referenceIDProps }, onReload);
    };

    // 下載PDF API
    const doTransferOverPDFApi = async (params) => {
        await downloadTransferOutPDFApi(params);
    };

    // 刪除暫停合約資料
    const doDeleteApi = async (rowData) => {
        const { isTemporary, contractID } = rowData;
        let resp;
        if (isTemporary) {
            resp = await deleteDocTemporaryTransferOutContractApi({
                type: peopleTypeProps,
                id: referenceIDProps,
            });
        } else {
            resp = await deleteTransferOutContractApi(contractID);
        }
        return resp;
    };

    return (
        <React.Fragment>
            <Tablenization cardProps={{ className: className }}>
                <Tablenization.TableRowHeader headerRow={labelTitle.current} />
                <Tablenization.TableRowBody
                    headerRow={labelTitle.current}
                    rowsData={sourceData}
                    onTransferOutConfirm={handleTransferOutConfirm}
                    onReviewEvent={handleReviewOnClick}
                    onEditEvent={handleEditOnClick}
                    onDownloadPDFEvent={handleDownloadPDFOnClick}
                    onDeleteOnEvent={(rowData) => {
                        membershipTransferOutDialogBoxRef.current.openConfirmDeleteDocDialog({ ...rowData, doDeleteApi }, onReload);
                    }}
                />
            </Tablenization>
            <MembershipTransferOutDialogBox ref={membershipTransferOutDialogBoxRef} />
        </React.Fragment>
    );
};

export default MembershipTransferOutHistoryTable;
