import React, { useEffect, useState, useRef } from 'react';
import { Grid } from '@mui/material/';
import { Form, Card, Button, MenuItem, SelectField, Typography, Stack, Box } from '@common/components/';
import { PointsProductGiftTable } from '@icoach/points/';
import { PointsRedeemDialogBox } from '@icoach/points/dialog/';
import { PointsProductGiftProvider, usePointsProductGiftContext } from '@icoach/points/components/PointsProductGiftContext';
import { initBookmark } from '@icoach/points/staticData';
import usePointsProductGiftApi from '@apis/usePointsProductGiftApi';
import { isEmpty } from '@util/utils';
import { head } from 'lodash/array';

const PointsProductGiftSearchBar = React.memo((props) => {
    const { setSourceData, dialogRef } = usePointsProductGiftContext();
    const [options, setOptions] = useState({});
    const { getPointsRewardOrderOverviewOptionsApi, getPointsRewardOrderOverviewApi } = usePointsProductGiftApi();
    const activityIdRef = useRef(null);

    const handleSearchOnClick = () => {
        if (isEmpty(options)) return;
        let defaultActivityID = (head(activityOption) ?? { value: '' }).value;
        const params = { activityID: activityIdRef.current.getResult() || defaultActivityID };
        doSearchApi(params);
    };

    const handleOpenDialog = () => {
        dialogRef.current.openPointsRedeemOrdersTasksDialog({
            activityID: activityIdRef.current.getResult(),
            title: activityOption.find(({ value }) => String(value) === String(activityIdRef.current.getResult()))?.text ?? '',
        });
    };

    const doSearchApi = async (params) => {
        const resp = await getPointsRewardOrderOverviewApi(params);
        if (resp) {
            setSourceData(resp);
        }
    };

    const initOptionsApi = async () => {
        const resp = await getPointsRewardOrderOverviewOptionsApi();
        if (resp) {
            setOptions(resp);
        }
    };

    useEffect(
        () => {
            handleSearchOnClick();
        },
        // eslint-disable-next-line
        [options]
    );

    useEffect(
        () => {
            if (isEmpty(options)) initOptionsApi();
        },
        // eslint-disable-next-line
        []
    );

    const { activityOption = [] } = options || {};

    return (
        <Form onSubmit={handleSearchOnClick}>
            <Card>
                <Card.CardContent className="p-4 px-2">
                    <Grid container spacing={2}>
                        <Grid item xs={5}>
                            <SelectField
                                key={activityOption}
                                ref={activityIdRef}
                                label="兌換時間"
                                direction="row"
                                defaultValue={activityOption[0]?.value ?? ''}
                                displayEmpty
                                fullWidth
                                required
                            >
                                <MenuItem value="" disabled>
                                    <em>請選擇</em>
                                </MenuItem>
                                {activityOption.map(({ text, value, disabled }) => (
                                    <MenuItem key={value} value={value} disabled={disabled}>
                                        {text}
                                    </MenuItem>
                                ))}
                            </SelectField>
                        </Grid>
                        <Grid item xs={4}>
                            <Stack spacing="2">
                                <Button className="ml-2" variant="contained" type="submit">
                                    搜尋
                                </Button>
                                {activityOption.length > 0 && (
                                    <Button className="ml-2" variant="contained" color="info" onClick={handleOpenDialog}>
                                        加入來店通知
                                    </Button>
                                )}
                            </Stack>
                        </Grid>
                    </Grid>
                </Card.CardContent>
            </Card>
        </Form>
    );
});

// 贈品兌換
const PointsProductGiftPage = React.forwardRef((props, ref) => {
    const [sourceData, setSourceData] = useState({});
    const dialogRef = useRef(null);
    const [bookmark, setBookmark] = useState({
        ...initBookmark(),
    });

    const childContext = {
        dialogRef: dialogRef,
        setDialogRef: (el) => (dialogRef.current = el),
        sourceData,
        setSourceData,
        bookmark: bookmark,
        setBookmarkNum: (num) => setBookmark((pre) => ({ ...pre, pageIndex: num })),
    };

    return (
        <PointsProductGiftProvider value={childContext}>
            <Box className="container main-container-spacing point-product-gift-container">
                <Box component="header" className={'page-header'}>
                    <Typography className={'page-header-title'} variant={'h3'}>
                        贈品兌換總覽
                    </Typography>
                </Box>
                <PointsProductGiftSearchBar />
                <PointsProductGiftTable />
                <PointsRedeemDialogBox ref={dialogRef} />
            </Box>
        </PointsProductGiftProvider>
    );
});

export default PointsProductGiftPage;
