import React, { useState, useEffect, useRef } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import clsx from 'clsx';
import { Grid } from '@mui/material';
import { Button, Typography, RadioField, Card, DateField, TextField, SelectField, MenuItem, Stack, Collapse } from '@common/components/';
import SignBox from '@icoach/components/SignBox';
import PromptDialog from '@icoach/components/dialog/PromptDialog';
import { parseDate } from '@util/moment';
import { refIsRequiredError } from '@util/utils';
import useShowPDF from '@util/hook/useShowPDF';
import useDocHealthDeclarationApi from '@apis/useDocHealthDeclarationApi';
import MutiRadioTextField from '@icoach/documents/healthDeclaration/MutiRadioTextField';
import { DOCUMENTS_HEALTHDECLARATION_PEOPLETYPE_REFERENCEID_READONLY_CONTRACT_ROUTER_PATH } from '@icoach/router/routerPath';
import { peopleType as peopleTypeMap } from '@apis/usePeopleApi';

const baseDatePeopleTypes = [peopleTypeMap.onlyJoin, peopleTypeMap.transferManually];
/**
 * 初始列舉項目的itemType
 * @param {number} typeNum 對應的itemType數字
 * @param {string} key 獲取想要的keyValue
 * @param {array} targetItem
 */
const getItemsValue = (typeNum, key, targetItem) => {
    if (Array.isArray(targetItem)) {
        return (targetItem.find((cur) => String(cur.itemType) === String(typeNum))?.[key] ?? null) || null;
    } else {
        console.error('targetItem param is not Array');
        return null;
    }
};

/**
 * 初始列舉項目的itemType
 * @param {number} typeNum 對應的itemType數字
 * @param {string} targetItem 獲取想要的keyValue
 * @param {array} targetItem
 */
const getItemsData = (typeNum, targetItem) => {
    if (Array.isArray(targetItem)) {
        return targetItem.find((cur) => String(cur.itemType) === String(typeNum));
    } else {
        console.error('targetItem param is not Array');
        return null;
    }
};

const ToggleBox = React.forwardRef((props, ref) => {
    const { children, defalutValue = 0 } = props;

    // 1 表示為 Yes 該目標才打開 (Q:您現在有定期去醫院看病嗎？)
    const [open, setOpen] = useState(String(defalutValue) === '1');

    React.useImperativeHandle(
        ref,
        () => {
            return {
                setOpen: setOpen,
                isOpen: open,
            };
        },
        // eslint-disable-next-line
        [open],
    );

    return (
        <Collapse in={open} key={open}>
            {children}
        </Collapse>
    );
});

const HealthSurveyContent = React.forwardRef((props, ref) => {
    const { isReadOnly = false, onReturnHistory, onReturnContinueDoc } = props;
    const { referenceID, peopleType, contractID } = useParams();
    const history = useHistory();
    const { getDocHealthDeclarationContractApi, postDocHealthDeclarationContractApi } = useDocHealthDeclarationApi();
    const [isMounted, setMounted] = useState(false);
    // 判斷是否阻擋
    const [showPrompt, setShowPrompt] = useState(true);
    const [options, setOptions] = useState({});
    const [source, setHistory] = useState({});
    const basicDisplayNameRef = useRef(null);
    const basicCellPhoneRef = useRef(null);
    const itemRadioRef = React.useRef({});
    const sickNameRef = React.useRef(null);
    const hospitalNameRef = React.useRef(null);
    const hospitalTelRef = React.useRef(null);
    const precautionRef = React.useRef(null);
    const employeeRef = React.useRef(null);
    const fillDateRef = React.useRef(null);
    const signImgRef = React.useRef(null);
    const toggleRef = React.useRef(null);
    const apiSendingRef = React.useRef(false);
    const showPDF = useShowPDF();

    const getAllParams = () => {
        let paramsPack = { ...source };
        const refPack = itemRadioRef.current || {};
        const sickName = sickNameRef.current;
        const hospitalName = hospitalNameRef.current;
        const hospitalTel = hospitalTelRef.current;
        const precaution = precautionRef.current;
        const employeeID = employeeRef.current;
        const fillDate = fillDateRef.current;
        const signImg = signImgRef.current;
        const itemsPack = Object.keys(refPack).map((itemName) => {
            let targetItemData = refPack[itemName].getResult();
            if (Array.isArray(targetItemData)) {
                targetItemData = targetItemData.find(({ checked }) => checked);
                return {
                    itemType: itemName,
                    itemValue: targetItemData?.value ?? '',
                    itemText: '',
                };
            }
            return targetItemData;
        });
        itemsPack.push({
            itemType: sickName.getName(),
            itemValue: '',
            itemText: sickName.getResult(),
        });
        itemsPack.push({
            itemType: hospitalName.getName(),
            itemValue: '',
            itemText: hospitalName.getResult(),
        });
        itemsPack.push({
            itemType: hospitalTel.getName(),
            itemValue: '',
            itemText: hospitalTel.getResult(),
        });
        itemsPack.push({
            itemType: precaution.getName(),
            itemValue: '',
            itemText: precaution.getResult(),
        });
        Object.assign(paramsPack, { survey: { items: itemsPack } });
        Object.assign(paramsPack, { employeeID: employeeID.getResult() || 0 });
        Object.assign(paramsPack, { fillDate: fillDate.getResult() || parseDate(source.fillDate) });
        Object.assign(paramsPack, { signImg: signImg.getResult() });
        Object.assign(paramsPack, { targetType: peopleType, targetID: referenceID });

        if (baseDatePeopleTypes.indexOf(peopleType) !== -1) {
            Object.assign(paramsPack, {
                displayName: basicDisplayNameRef.current.getResult(),
                cellPhone: basicCellPhoneRef.current.getResult(),
            });
        }

        return paramsPack;
    };

    const setNameRef = (el) => {
        if (el && el.getName) itemRadioRef.current[el.getName()] = el;
    };

    // 確認送出
    const handleSendOnClick = () => {
        if (apiSendingRef.current) return false;
        const { isOpen } = toggleRef.current;
        if (isOpen && refIsRequiredError(sickNameRef, hospitalNameRef, precautionRef)) {
            return false;
        } else if (!refIsRequiredError(...Object.values(itemRadioRef.current), employeeRef, fillDateRef, signImgRef)) {
            const params = getAllParams();
            apiSendingRef.current = true;
            addDocHealthDeclarationData(params);
        }
    };

    // 暫存按鈕
    // const handleTemporaryOnClick = () => {
    //     if (apiSendingRef.current) return false;
    //     const params = getAllParams();
    //     params.signImg = null;
    //     params.contractID = 0;
    //     params.mainID = 0;
    //     apiSendingRef.current = true;
    //     addTemporaryDocHealthDeclaration(params);
    // };

    // 顯示定期去醫院看病
    const handleToggleChange = (e, checkedValue) => {
        let isShow = false;
        // 1 為 Yes 表示要顯示 其餘則不顯示
        if (checkedValue === '1') isShow = true;
        toggleRef.current.setOpen(isShow);
    };

    // 返回總覽頁
    const handleReturnHistory = () => {
        setShowPrompt(false);
        window.setTimeout(onReturnHistory, 5);
    };

    // 新增健康申告書
    const addDocHealthDeclarationData = async (params) => {
        const resp = await postDocHealthDeclarationContractApi(params, contractID);
        if (resp) {
            if (resp?.completedPath) {
                showPDF({
                    open: true,
                    pdfUrl: resp.completedPath,
                    onClose: () => {
                        // 新增結束後的動作判斷及處理
                        if (window.location.search.indexOf('continue-doc') !== -1 && typeof onReturnContinueDoc === 'function') {
                            onReturnContinueDoc(resp.contractID);
                        }
                    },
                });
            }
            setShowPrompt(false);
            window.setTimeout(() => {
                let path = `${DOCUMENTS_HEALTHDECLARATION_PEOPLETYPE_REFERENCEID_READONLY_CONTRACT_ROUTER_PATH(peopleType, referenceID, resp.contractID)}${
                    window.location.search
                }`;
                history.replace(path);
            }, 5);
        } else {
            apiSendingRef.current = false;
        }
    };

    // 新增暫存資料
    // const addTemporaryDocHealthDeclaration = async (params) => {
    //     const resp = await postDocHealthDeclarationTemporaryApi(params);
    //     if (resp) {
    //         handleReturnHistory();
    //     } else {
    //         apiSendingRef.current = false;
    //     }
    // };

    const getBasicDataTitle = () => {
        return peopleType === peopleTypeMap.onlyJoin ? '新入會請填寫基礎資訊，提供入會申請書引用：' : '手動移入請填寫基礎資訊，提供移入申請書引用：';
    };

    // 初始畫面資料
    const doInitDataApi = async (params, id) => {
        const resp = await getDocHealthDeclarationContractApi(params, id);
        if (resp) {
            const { resource, ...contractData } = resp;
            setOptions({ employeesOption: resource.coachOptions });
            setHistory(contractData);
            setMounted(true);
        }
    };

    useEffect(() => {
        doInitDataApi(
            {
                id: referenceID,
                type: peopleType,
            },
            contractID,
        );
        return () => setMounted(false);
        // eslint-disable-next-line
    }, [contractID]);

    // isCompleteContract:是否為正式文件
    const { employeeID = null, fillDate = null, displayName, cellPhone } = source;
    const { items = [] } = source?.survey ?? {};
    const onlyCreate = baseDatePeopleTypes.indexOf(peopleType) !== -1;

    return (
        <React.Fragment>
            <Card>
                {isMounted && (
                    <Card.CardContent className="py-3 px-5">
                        <div className={clsx(isReadOnly && 'pb-3')}>
                            {onlyCreate && (
                                <React.Fragment>
                                    <Typography variant="h6" component="h3" className="mb-4">
                                        {getBasicDataTitle()}
                                    </Typography>
                                    <div className={clsx(isReadOnly && 'mb-3', 'p-3', 'bg-light')}>
                                        <Grid spacing={3} container>
                                            <Grid item xs={4}>
                                                <TextField
                                                    key={'displayName'}
                                                    ref={basicDisplayNameRef}
                                                    label={'顯示名稱'}
                                                    readOnly={isReadOnly}
                                                    defaultValue={displayName}
                                                    fullWidth
                                                    required
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <TextField
                                                    key={'cellPhone'}
                                                    ref={basicCellPhoneRef}
                                                    label={'手機'}
                                                    readOnly={isReadOnly}
                                                    defaultValue={cellPhone}
                                                    maskType={'MOB'}
                                                    fullWidth
                                                    required
                                                />
                                            </Grid>
                                        </Grid>
                                    </div>
                                </React.Fragment>
                            )}
                            <Typography variant="h6" component="h3" className="mb-4">
                                Curves在此確認前來加入會員或免費體驗之顧客，其健康狀態適合使用本公司健身設施。
                            </Typography>
                            <ol className="questions main">
                                <li className="questions-item">
                                    <RadioField
                                        name={'1'}
                                        defaultValue={getItemsValue(1, 'itemValue', items)}
                                        ref={setNameRef}
                                        label="您現在有定期去醫院看病嗎？"
                                        row
                                        labelDirection="row"
                                        origin={'end'}
                                        onChange={handleToggleChange}
                                        readOnly={isReadOnly}
                                        required
                                    >
                                        <RadioField.Radio value={1} label="Yes" />
                                        <RadioField.Radio value={2} label="No" />
                                    </RadioField>
                                    <ToggleBox ref={toggleRef} defalutValue={getItemsValue(1, 'itemValue', items)}>
                                        <div className="p-4 bg-gray-100 ">
                                            <div className="row mb-2 round">
                                                <TextField
                                                    autoComplete="off"
                                                    name={'2'}
                                                    defaultValue={getItemsValue(2, 'itemText', items)}
                                                    ref={sickNameRef}
                                                    label="病名"
                                                    readOnly={isReadOnly}
                                                    required
                                                />
                                                <TextField
                                                    autoComplete="off"
                                                    name={'3'}
                                                    defaultValue={getItemsValue(3, 'itemText', items)}
                                                    ref={hospitalNameRef}
                                                    label="醫院名"
                                                    readOnly={isReadOnly}
                                                    required
                                                />
                                                <TextField
                                                    name={'4'}
                                                    defaultValue={getItemsValue(4, 'itemText', items)}
                                                    ref={hospitalTelRef}
                                                    label="醫院電話"
                                                    readOnly={isReadOnly}
                                                    maskType="TEL"
                                                    required
                                                />
                                            </div>
                                            <RadioField
                                                name={'5'}
                                                defaultValue={getItemsValue(5, 'itemValue', items)}
                                                ref={setNameRef}
                                                label="您的醫生知道您打算開始運動嗎？"
                                                className="mb-1"
                                                row
                                                labelDirection="row"
                                                readOnly={isReadOnly}
                                                origin={'end'}
                                            >
                                                <RadioField.Radio value={1} label="Yes" />
                                                <RadioField.Radio value={2} label="No" />
                                            </RadioField>
                                            <TextField
                                                name={'6'}
                                                rows={3}
                                                multiline
                                                defaultValue={getItemsValue(6, 'itemText', items)}
                                                ref={precautionRef}
                                                label="目前運動時您可能特別需要注意的地方有哪些，請詳述："
                                                fullWidth
                                                readOnly={isReadOnly}
                                                required
                                            />
                                        </div>
                                    </ToggleBox>
                                </li>
                                <li className="questions-item">
                                    <Typography variant="body1">下列內容中, 現在是否有或曾經符合的項目, 請選擇：</Typography>
                                    <ol className="questions">
                                        <li className="questions-item">
                                            <MutiRadioTextField
                                                name={'7'}
                                                itemData={getItemsData(7, items)}
                                                ref={setNameRef}
                                                label="心臟病, 胸痛, 胸悶"
                                                isReadOnly={isReadOnly}
                                                required
                                            />
                                        </li>
                                        <li className="questions-item">
                                            <MutiRadioTextField
                                                name={'8'}
                                                itemData={getItemsData(8, items)}
                                                label="高血壓"
                                                ref={setNameRef}
                                                isReadOnly={isReadOnly}
                                                required
                                            />
                                        </li>
                                        <li className="questions-item">
                                            <MutiRadioTextField
                                                name={'9'}
                                                itemData={getItemsData(9, items)}
                                                label="高血脂肪"
                                                ref={setNameRef}
                                                isReadOnly={isReadOnly}
                                                required
                                            />
                                        </li>
                                        <li className="questions-item">
                                            <MutiRadioTextField
                                                name={'10'}
                                                itemData={getItemsData(10, items)}
                                                label="被醫生禁止運動"
                                                ref={setNameRef}
                                                isReadOnly={isReadOnly}
                                                required
                                            />
                                        </li>
                                        <li className="questions-item">
                                            <MutiRadioTextField
                                                name={'11'}
                                                itemData={getItemsData(11, items)}
                                                label="過去一年內有動過手術"
                                                ref={setNameRef}
                                                isReadOnly={isReadOnly}
                                                required
                                            />
                                        </li>
                                        <li className="questions-item">
                                            <MutiRadioTextField
                                                name={'12'}
                                                itemData={getItemsData(12, items)}
                                                label="現在，或3個月內打算懷孕"
                                                ref={setNameRef}
                                                isReadOnly={isReadOnly}
                                                required
                                            />
                                        </li>
                                        <li className="questions-item">
                                            <MutiRadioTextField
                                                name={'13'}
                                                itemData={getItemsData(13, items)}
                                                label="呼吸道或肺部疾病(例如：氣喘)"
                                                ref={setNameRef}
                                                isReadOnly={isReadOnly}
                                                required
                                            />
                                        </li>
                                        <li className="questions-item">
                                            <MutiRadioTextField
                                                name={'14'}
                                                itemData={getItemsData(14, items)}
                                                label="肌肉、關節、腰部病痛，或曾有現在仍感到不適的舊傷"
                                                ref={setNameRef}
                                                isReadOnly={isReadOnly}
                                                required
                                            />
                                        </li>
                                        <li className="questions-item">
                                            <MutiRadioTextField
                                                name={'15'}
                                                itemData={getItemsData(15, items)}
                                                label="糖尿病或甲狀腺疾病"
                                                ref={setNameRef}
                                                isReadOnly={isReadOnly}
                                                required
                                            />
                                        </li>
                                        <li className="questions-item">
                                            <MutiRadioTextField
                                                name={'16'}
                                                itemData={getItemsData(16, items)}
                                                label="抽煙、喝酒、藥物依賴"
                                                ref={setNameRef}
                                                isReadOnly={isReadOnly}
                                                required
                                            />
                                        </li>
                                        <li className="questions-item">
                                            <MutiRadioTextField
                                                name={'17'}
                                                itemData={getItemsData(17, items)}
                                                label="超重(以醫學觀點，宜減重條件為:腰圍80cm以上，或因超重引發疾病者)"
                                                ref={setNameRef}
                                                isReadOnly={isReadOnly}
                                                required
                                            />
                                        </li>
                                        <li className="questions-item">
                                            <MutiRadioTextField
                                                name={'18'}
                                                itemData={getItemsData(18, items)}
                                                label="膽固醇過高"
                                                ref={setNameRef}
                                                isReadOnly={isReadOnly}
                                                required
                                            />
                                        </li>
                                        <li className="questions-item">
                                            <MutiRadioTextField
                                                name={'19'}
                                                itemData={getItemsData(19, items)}
                                                label="有心血管疾病家族史"
                                                ref={setNameRef}
                                                isReadOnly={isReadOnly}
                                                required
                                            />
                                        </li>
                                        <li className="questions-item">
                                            <MutiRadioTextField
                                                name={'20'}
                                                itemData={getItemsData(20, items)}
                                                label="椎間盤突出或可能因肌肉訓練引起惡化的症狀"
                                                ref={setNameRef}
                                                isReadOnly={isReadOnly}
                                                required
                                            />
                                        </li>
                                        <li className="questions-item">
                                            <MutiRadioTextField
                                                name={'21'}
                                                itemData={getItemsData(21, items)}
                                                label="目前正在服用藥物"
                                                ref={setNameRef}
                                                isReadOnly={isReadOnly}
                                                required
                                            />
                                        </li>
                                    </ol>
                                </li>
                            </ol>
                        </div>
                        {!isReadOnly && (
                            <React.Fragment>
                                <div className="pb-3">
                                    <Typography variant="h3" className="h6 mb-1 mt-5">
                                        確認書
                                    </Typography>
                                    <Typography className="mark">請確實確認內容後, 在本確認書上簽名。</Typography>
                                    <ol>
                                        <li>
                                            本人在加入 Curves會員或使用免費體驗前，對自我健康狀況充分了解且負責， 並認為本人目前健康狀況能夠負荷
                                            Curves各項設施。
                                        </li>
                                        <li>對於隱匿自身健康狀況造成傷害或發生急性病症等事故，本公司無需負賠償之責。</li>
                                    </ol>
                                </div>
                                <Grid className="pb-3" container>
                                    <Grid xs={6} item>
                                        <Typography variant="body2" className="font-color-3">
                                            未滿十八歲者請由監護人簽名
                                        </Typography>
                                        <SignBox ref={signImgRef} title="簽名" required />
                                    </Grid>
                                </Grid>
                            </React.Fragment>
                        )}
                        <div className={clsx(isReadOnly && 'mb-3', 'p-3', 'bg-light')}>
                            <Grid spacing={3} container>
                                <Grid xs={4} item>
                                    <DateField defaultValue={parseDate(fillDate)} ref={fillDateRef} label="填表日期" readOnly={isReadOnly} fullWidth required />
                                </Grid>
                                <Grid xs={4} item>
                                    <SelectField
                                        ref={employeeRef}
                                        label="擔當教練"
                                        defaultValue={employeeID || null}
                                        displayEmpty
                                        readOnly={isReadOnly}
                                        fullWidth
                                        required
                                    >
                                        <MenuItem value="" disabled>
                                            <em>請選擇</em>
                                        </MenuItem>
                                        {options.employeesOption.map(({ value: _v, disabled: _d, text: _t }) => (
                                            <MenuItem key={_v} value={_v} disabled={_d}>
                                                {_t}
                                            </MenuItem>
                                        ))}
                                    </SelectField>
                                </Grid>
                            </Grid>
                        </div>
                        <Stack className="my-3" justifyContent={'center'} spacing={2}>
                            {!onlyCreate && (
                                <Button variant="contained" color="secondary" className="btn-minWidth" onClick={handleReturnHistory}>
                                    返回查看歷史
                                </Button>
                            )}
                            {!isReadOnly && (
                                <React.Fragment>
                                    {/*{!isCompleteContract && !onlyCreate && (*/}
                                    {/*    <Button variant="outlined" color="secondary" className="btn-minWidth" onClick={handleTemporaryOnClick}>*/}
                                    {/*        暫存資料*/}
                                    {/*    </Button>*/}
                                    {/*)}*/}
                                    <Button variant="contained" color="secondary" className="btn-minWidth" onClick={handleSendOnClick}>
                                        確認送出
                                    </Button>
                                </React.Fragment>
                            )}
                        </Stack>
                    </Card.CardContent>
                )}
            </Card>
            {!isReadOnly && <PromptDialog when={showPrompt} />}
        </React.Fragment>
    );
});

export default HealthSurveyContent;
