import React from 'react';
import { Dialog, DialogContent, Grid } from '@mui/material';
import GradientColorHeader from '@icoach/components/dialog/GradientColorHeader';
import { Typography, Button, Box } from '@common/components/';

const MeasureLeaderboardWarningDialog = React.forwardRef((props, ref) => {
    const { onClose: onCloseProps, open: openProps } = props;
    if (!openProps) return null;
    return (
        <Dialog open={openProps} PaperProps={{ className: 'wx-40rem' }} fullWidth>
            <GradientColorHeader onClose={onCloseProps}>提醒</GradientColorHeader>
            <DialogContent dividers>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography align="center" variant="h6">
                            目前超前已超過25位人數
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Box className="flex flex-y-flex-center">
                            <Button variant="contained" color="secondary" onClick={onCloseProps}>
                                了解
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
});

export default MeasureLeaderboardWarningDialog;
