import React, { useImperativeHandle, useRef } from 'react';
import { Grid } from '@mui/material';
import { DateField } from '@common/components/';
import { refIsRequiredError } from '@util/utils';
import { parseDate } from '@util/moment';

// 量身成果名單(excel匯出)
export const FilterScannerResultSearch = React.forwardRef((props, ref) => {
    const startDateRef = useRef(null);
    const endDateRef = useRef(null);

    useImperativeHandle(
        ref,
        () => {
            return {
                isError: () => refIsRequiredError(startDateRef, endDateRef),
                getResult: () => {
                    return {
                        startDate: parseDate(startDateRef.current.getResult(), 'YYYY-MM'),
                        endDate: parseDate(endDateRef.current.getResult(), 'YYYY-MM'),
                    };
                },
            };
        },
        // eslint-disable-next-line
        [],
    );

    return (
        <React.Fragment>
            <Grid item xs={2}>
                <DateField ref={startDateRef} views={['year', 'month']} openTo="month" format="YYYY/MM" label="月份和月份1" mask="____/__" required fullWidth />
            </Grid>
            <Grid item xs={2}>
                <DateField ref={endDateRef} views={['year', 'month']} openTo="month" format="YYYY/MM" label="月份和月份2" mask="____/__" required fullWidth />
            </Grid>
        </React.Fragment>
    );
});
