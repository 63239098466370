import { useCallback } from 'react';
import useAjax from '@apis/useAjax';
import { useSnackbar } from 'notistack';

const GET_AUTH_CLUB_PATH_API = `/api/me/club/`;
const PUT_AUTH_SECURITY_API = `/api/me/security/`;
const POST_AUTH_AGENT_OWNER_API = `/api/me/agent-owner/`;

const useAuthUserApi = () => {
    const Ajax = useAjax();
    const { enqueueSnackbar } = useSnackbar();

    // 取得登入使用者的店舖資料
    const getAuthClubApi = useCallback(
        async () => {
            const resp = await Ajax.get(GET_AUTH_CLUB_PATH_API);
            return resp;
        },
        // eslint-disable-next-line
        [],
    );

    // 設定次代理權限者
    const postAuthAgentOwnerApi = useCallback(
        async (params) => {
            const resp = await Ajax.post(POST_AUTH_AGENT_OWNER_API, params);
            return resp;
        },
        // eslint-disable-next-line
        [],
    );

    const putModifyAuthPwdApi = useCallback(
        async (params) => {
            const resp = await Ajax.put(PUT_AUTH_SECURITY_API, params);
            if (!resp) {
                enqueueSnackbar('錯誤，請再操作一次', { variant: 'error' });
            } else {
                enqueueSnackbar('變更密碼成功，請重新登入', { variant: 'success' });
            }
            return resp;
        },
        // eslint-disable-next-line
        [],
    );

    return {
        getAuthClubApi,
        postAuthAgentOwnerApi,
        putModifyAuthPwdApi,
    };
};

export default useAuthUserApi;
