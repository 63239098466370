import React, { useRef, useState } from 'react';
import clsx from 'clsx';
import { Box, Checkbox, ClickAwayListener, FormControlLabel, IconButton, ListItemText, Menu, MenuItem, Stack, Tooltip } from '@common/components/';
import { MoreVertical as MoreVerticalIcon, PaperPlane as PaperPlaneIcon, Check as CheckIcon, Trash as TrashIcon } from '@common/SvgIcon/';
import { DateTimeStandard } from '../../components/pickers/enums/DateTimeStandard';
import { BusinessCardBox, MorePageBox } from '@icoach/components';
import { useGiftContext } from '@icoach/gift/components/GiftContext';
import ScrollerArrowButton from '@icoach/inbody/components/ScrollerArrowButton';
import { ListInfo } from '@icoach/accountBook/components';
import { rowLabelCheckbox } from '@icoach/members/overview/NormalTemplate';
import Tablenization from '@icoach/components/Tablenization';
import useGiftItemRecordApi from '@apis/useGiftItemRecordApi';
import { isEmpty } from '@util/utils';
import { parseDate } from '@util/moment';
import useGroupCheckbox from '@util/hook/useGroupCheckbox';
import useMessageDialog from '@util/hook/useMessageDialog';

const ActionMenuButton = (props) => {
    const { menuData = [], actionButtonProps = {}, textAlign = 'left', enabled = true } = props;
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClickActionButton = (e) => {
        setAnchorEl(e.currentTarget);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    // 為啟用與沒有操作項就不顯示
    if (!enabled || menuData.length < 1) return null;

    return (
        <React.Fragment>
            <IconButton {...actionButtonProps} onClick={handleClickActionButton}>
                <MoreVerticalIcon />
            </IconButton>
            <Menu className={clsx('more-label-box-menu', `align-${textAlign}`)} anchorEl={anchorEl} open={open} onClose={(e) => handleCloseMenu(null)}>
                {menuData.map(({ text, ...others }) => {
                    return (
                        <MenuItem {...others} key={`more_label_${text}`}>
                            <ListItemText>{text}</ListItemText>
                        </MenuItem>
                    );
                })}
            </Menu>
        </React.Fragment>
    );
};

const GiftClaimedTooltip = (props) => {
    const { tip } = props;
    const [open, setOpen] = useState(false);

    const handleCloseTooltip = () => {
        setOpen(false);
    };

    const handleOpenTooltip = () => {
        setOpen(true);
    };

    return (
        <ClickAwayListener onClickAway={handleCloseTooltip}>
            <Tooltip
                title={tip}
                PopperProps={{
                    disablePortal: true,
                }}
                onClose={handleCloseTooltip}
                open={open}
                disableFocusListener
                disableHoverListener
                disableTouchListener
            >
                <CheckIcon color={'success'} onClick={handleOpenTooltip} />
            </Tooltip>
        </ClickAwayListener>
    );
};

const headerRow = [
    { ...rowLabelCheckbox, isFixed: true, isFixedLeft: true },
    {
        cellKey: 'memberName',
        headerLabel: '受贈人',
        formatCell: (memberName, { memberNo, memberPicturePath }) => {
            let memberInfo = {
                memberNo,
                memberName,
                memberImg: memberPicturePath,
            };
            return <BusinessCardBox rowData={memberInfo} />;
        },
    },
    {
        cellKey: 'eventNameText',
        headerLabel: '活動名稱',
        align: 'center',
    },
    {
        cellKey: 'giftName',
        headerLabel: '贈品名稱',
        align: 'center',
    },
    {
        cellKey: 'giftSpecifications',
        align: 'center',
        headerLabel: '規格',
    },
    {
        cellKey: 'createDate',
        headerLabel: '發送日期',
        formatCell: (cellValue) => {
            return (
                <React.Fragment>
                    <p className="mb-0">{parseDate(cellValue, DateTimeStandard.DisplayDate, false)}</p>
                    <p className="mb-0 font-color-3">{parseDate(cellValue, DateTimeStandard.DisplayTime, false)}</p>
                </React.Fragment>
            );
        },
    },
    {
        cellKey: 'isGiftClaimed',
        headerLabel: '是否領取',
        align: 'center',
        formatCell: (isGiftClaimed, { claimDate }) => {
            return isGiftClaimed ? <GiftClaimedTooltip tip={parseDate(claimDate, DateTimeStandard.DisplayFullBySlash, false)} /> : '';
        },
    },
    {
        cellKey: 'more',
        headerLabel: '',
        width: 6.8,
        formatCell: (value, rowData, { refresh, handleFreshFn, openBatchDeleteGiftRecordsDialog, handleGiftClaimedChange }) => {
            const { isGiftClaimed } = rowData;

            const claimedActionData = {
                text: isGiftClaimed ? '取消領取' : '領取',
                onClick: (e) => {
                    handleGiftClaimedChange(rowData);
                },
            };

            const deleteActionData = {
                text: '刪除',
                onClick: (e) => {
                    openBatchDeleteGiftRecordsDialog([rowData], handleFreshFn(refresh));
                },
            };

            const menuData = [claimedActionData];

            if (!isGiftClaimed) {
                menuData.push(deleteActionData);
            }

            return <ActionMenuButton menuData={menuData} />;
        },
    },
];

const GiftDispatchRecordTable = () => {
    const { dialogRef, sourceData, sortOption, bookmark, setBookmarkNum, setSortBy, refresh } = useGiftContext();
    const { list = [], pageIndex = bookmark.pageIndex, pageSize = bookmark.pageSize, totalPage, totalCount } = sourceData || {};
    const { putGiftItemClaimedApi } = useGiftItemRecordApi();
    const setMessageDialog = useMessageDialog();
    const [checkAllChange, checkOptChange, selectedAllRef, selectedOptRef, indeterminateRef] = useGroupCheckbox(list);
    const scrollerArrowRef = useRef(null);

    const handleChangePage = (e, num) => {
        if (typeof setBookmarkNum === 'function') setBookmarkNum(num);
    };

    const handleGiftItemClaimedApi = async (params, onClose) => {
        const { recordID } = params;
        let resp = await putGiftItemClaimedApi(recordID, params);
        if (resp) {
            let refreshFn = handleFreshFn(refresh);
            if (typeof refreshFn === 'function') refreshFn(params);
            if (typeof onClose === 'function') onClose();
        }
    };

    const handleGiftClaimedChange = (rowData) => {
        const { recordID, isGiftClaimed } = rowData;
        const target = isGiftClaimed ? '未領取' : '已領取';
        setMessageDialog({
            open: true,
            title: '提醒',
            msg: `確定要改為${target}狀態嗎？`,
            onAsyncConfirm: (_, onclose) => handleGiftItemClaimedApi({ recordID, isGiftClaimed: !isGiftClaimed }, onclose),
        });
    };

    const handleFreshFn = (refreshFn) => {
        return (params) => {
            setTimeout(() => {
                refreshFn(params);
            }, 5);
        };
    };

    const handleSendSMSClick = () => {
        const els = (selectedOptRef.current && selectedOptRef.current) || [];
        const members = els.reduce((accumulator, currentEl, currentIndex) => {
            if (!currentEl) return accumulator;

            const array = [];
            let currentData = list[currentIndex];

            if (currentEl.checked) {
                if (String(currentData.memberID) !== String(currentEl.value)) {
                    throw new Error('咦??ID沒有匹配');
                }
                currentData.type = 2;
                currentData.name = currentEl.dataset.name;
                currentData.id = currentData.memberID;

                array.push(currentData);
            }

            return accumulator.concat(array);
        }, []);
        if (members.length === 0) return;
        dialogRef.current && dialogRef.current.openSMSDialog(members);
    };

    const handleBatchDeleteGiftRecords = () => {
        const els = (selectedOptRef.current && selectedOptRef.current) || [];
        const giftRecords = els.reduce((accumulator, currentEl, currentIndex) => {
            if (!currentEl) return accumulator;

            const array = [];
            let currentData = list[currentIndex];

            if (currentEl.checked) {
                if (String(currentData.memberID) !== String(currentEl.value)) {
                    throw new Error('咦??ID沒有匹配');
                }

                array.push(currentData);
            }

            return accumulator.concat(array);
        }, []);
        return giftRecords;
    };

    const handleDeleteClick = () => {
        const giftRecords = handleBatchDeleteGiftRecords();
        const freshFn = handleFreshFn(refresh);
        dialogRef.current && dialogRef.current.openBatchDeleteGiftRecordsDialog(giftRecords, freshFn);
    };

    return (
        <Box className={'customer-table'}>
            <Stack className={'mb-1'} alignItems={'center'} justifyContent={'space-between'}>
                {!isEmpty(list) && (
                    <Stack className="pl-4 pb-1">
                        <FormControlLabel
                            label="全選"
                            control={
                                <Checkbox
                                    color="primary"
                                    indeterminateRef={indeterminateRef}
                                    inputRef={(el) => (selectedAllRef.current = el)}
                                    onChange={checkAllChange}
                                />
                            }
                        />
                        <Stack>
                            <Tooltip title="發送簡訊">
                                <IconButton onClick={handleSendSMSClick}>
                                    <PaperPlaneIcon />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="批次刪除">
                                <IconButton onClick={handleDeleteClick}>
                                    <TrashIcon />
                                </IconButton>
                            </Tooltip>
                        </Stack>
                    </Stack>
                )}
                <ListInfo
                    className={'ml-auto'}
                    pageIndex={pageIndex}
                    pageSize={pageSize}
                    totalCount={totalCount}
                    sortOptions={sortOption}
                    sortDefaultValue={bookmark?.sortBy ?? 1}
                    isCurrentPageFirstIndex
                    isCurrentPageLastIndex
                    sortOnChange={setSortBy}
                    isSort
                />
            </Stack>
            <Box className={'scroll-arrow-container'}>
                <Tablenization
                    tableContainerRef={scrollerArrowRef}
                    cardProps={{ className: 'theme-gray overview' }}
                    page={pageIndex}
                    count={totalPage}
                    onChange={handleChangePage}
                    Pagination={MorePageBox}
                    isPagination
                >
                    <Tablenization.TableRowHeader headerRow={headerRow} />
                    <Tablenization.TableRowBody
                        headerRow={headerRow}
                        rowsData={list}
                        refresh={refresh}
                        onCheckChange={checkOptChange}
                        checkInputRef={selectedOptRef}
                        handleFreshFn={handleFreshFn}
                        handleGiftClaimedChange={handleGiftClaimedChange}
                        openBatchDeleteGiftRecordsDialog={dialogRef.current && dialogRef.current.openBatchDeleteGiftRecordsDialog}
                        Pagination
                    />
                </Tablenization>
                <ScrollerArrowButton parentRef={scrollerArrowRef} />
            </Box>
        </Box>
    );
};

export default GiftDispatchRecordTable;
