import React, { useRef } from 'react';
import { useAccountBookContext } from './components/AccountBookContext';
import { Box, Chip, Stack, Typography } from '../../components';
import { ListInfo } from './components';
import { COLOR_MATCH, dialogType } from './staticData';
import Tablenization from '@icoach/components/Tablenization';
import ScrollerArrowButton from '@icoach/inbody/components/ScrollerArrowButton';
import { RemitAllowanceDialog, RemitInvoiceAbandonDialog } from '@icoach/accountBook/dialog';
import useClubRemittanceApi from '@apis/useClubRemittanceApi';
import useToggleDialog from '@util/hook/useToggleDialog';
import useMessageDialog from '@util/hook/useMessageDialog';
import { ACCOUNT_BOOK_REMIT_ALLOWANCE_URL } from '@icoach/router/AccountBookRouter';
import { MemoControllerBox, MoreLabelBox } from '@icoach/components';
import { formatCurrencyFn } from '@util/utils';
import { parseDate } from '@util/moment';
import { DateTimeStandard } from '../../components/pickers/enums/DateTimeStandard';
import { getMoreActionArray } from '@icoach/rentCabinet/staticData';
import { isEmpty } from 'lodash';

const MoreLabel = (props) => {
    const { remittanceID, handleOpenDialog, handleOpenDeleteDialog } = props;

    const moreLabelMap = useRef({
        allowance: {
            text: '匯款單折讓',
            onClickEvent: () => handleOpenDialog(dialogType.allowance, { remittanceID }),
        },
        abandon: {
            text: '作廢發票',
            onClickEvent: () => handleOpenDialog(dialogType.invoiceAbandon, { remittanceID }),
        },
        allowanceRecord: {
            text: '折讓歷史',
            to: () => ACCOUNT_BOOK_REMIT_ALLOWANCE_URL(remittanceID),
        },
        delete: {
            text: '刪除匯款',
            onClickEvent: () => handleOpenDeleteDialog(remittanceID),
        },
    });

    return <MoreLabelBox {...props} orderID={remittanceID} moreLabelMap={moreLabelMap.current} />;
};

const rowsLabel = [
    {
        cellKey: 'remitDate',
        headerLabel: '匯款',
        width: '22',
        align: 'center',
        formatCell: (remitDate, { remitStatusText, remitStatusColor }) => {
            const _parseDate = (date, format) => {
                return parseDate(date, format, false);
            };
            return (
                <Stack direction={'column'}>
                    <Typography variant={'body1'} className={'mb-1'}>
                        {_parseDate(remitDate, DateTimeStandard.DisplayDate)}
                        <Typography variant={'body1'} className={'mb-0 font-color-3'} component={'span'}>
                            {` ${_parseDate(remitDate, 'HH:mm')}`}
                        </Typography>
                    </Typography>
                    <Chip label={remitStatusText} color={COLOR_MATCH[remitStatusColor]} title={'匯款狀態'} />
                </Stack>
            );
        },
    },
    {
        cellKey: 'remitter',
        headerLabel: '對象',
        formatCell: (cellValue) => {
            return (
                <Typography variant={'subtitle1'} className={'p-0 font-weight-bold'}>
                    {cellValue}
                </Typography>
            );
        },
    },
    // {
    //     cellKey: 'remitStatusText',
    //     headerLabel: '狀態',
    //     align: 'center',
    //     formatCell: (cellValue, { remitStatusColor }) => {
    //         return <Chip label={cellValue} color={remitStatusColorMap.get(remitStatusColor)} />;
    //     },
    // },
    {
        cellKey: 'itemContent',
        headerLabel: '發票',
        formatCell: (itemContent, { invoiceNo, remitStatus }) => {
            return (
                <React.Fragment>
                    <Typography variant={'body1'} className={'mb-0'}>
                        {itemContent}
                    </Typography>
                    {invoiceNo ? (
                        <Typography variant={'body2'} className={'mb-0 font-color-3'}>
                            {`號碼：${invoiceNo}`}
                        </Typography>
                    ) : (
                        ''
                    )}
                </React.Fragment>
            );
        },
    },
    {
        cellKey: 'itemNotes',
        headerLabel: '發票備註',
        formatCell: (cellValue) => {
            return <MemoControllerBox data={cellValue} dialogTitle={'發票備註'} />;
        },
    },

    {
        cellKey: 'inwardAmount',
        headerLabel: '匯入金額',
        align: 'right',
        formatCell: (cellValue) => {
            return formatCurrencyFn(cellValue);
        },
    },
    {
        cellKey: 'handingFee',
        headerLabel: '手續費',
        align: 'right',
        formatCell: (cellValue) => {
            return formatCurrencyFn(cellValue);
        },
    },
    {
        cellKey: 'totalAmount',
        headerLabel: '匯入總計',
        align: 'right',
        formatCell: (cellValue, { inwardAmount, handingFee }) => {
            return (
                <Typography variant={'body1'} className={'mb-0 font-weight-bold'}>
                    {formatCurrencyFn(inwardAmount + handingFee)}
                </Typography>
            );
        },
    },
    {
        cellKey: 'returnAmount',
        headerLabel: '退匯金額',
        align: 'right',
        formatCell: (cellValue) => {
            return (
                <Typography variant={'body1'} className={'text-error mb-0'}>
                    {formatCurrencyFn(cellValue)}
                </Typography>
            );
        },
    },
    {
        cellKey: 'outwardAmount',
        headerLabel: '匯出總計',
        align: 'right',
        formatCell: (cellValue) => {
            return (
                <Typography variant={'body1'} className={'text-error mb-0 font-weight-bold'}>
                    {formatCurrencyFn(cellValue)}
                </Typography>
            );
        },
    },
    {
        cellKey: 'employeeDisplayName',
        headerLabel: '擔當',
        align: 'center',
    },
    {
        cellKey: 'more',
        headerLabel: '',
        align: 'right',
        isIcon: true,
        formatCell: (cellValue, rowData = {}, { handleOpenDialog, handleOpenDeleteDialog }) => {
            const { canAllowance, canInvalid, canDelete, inwardAmount } = rowData;
            const moreAction = {
                allowance: canAllowance,
                abandon: canInvalid,
                allowanceRecord: inwardAmount,
                delete: canDelete,
            };
            const moreActionArray = getMoreActionArray(moreAction);

            if (isEmpty(moreActionArray)) return null;

            return (
                <MoreLabel {...rowData} moreActionArray={moreActionArray} handleOpenDialog={handleOpenDialog} handleOpenDeleteDialog={handleOpenDeleteDialog} />
            );
        },
    },
];

const AccountBookRemitOverviewTable = () => {
    const { tableData, setBookmarkNum, refresh } = useAccountBookContext();
    const { list = [], totalCount, totalPage, pageIndex, pageSize } = tableData;
    const { deleteRemittanceApi } = useClubRemittanceApi();
    const { openTarget, handleOpenDialog, handleCloseDialog, remittanceID } = useToggleDialog();
    const setMessageDialog = useMessageDialog();
    const labelTitle = useRef(rowsLabel);
    const scrollerArrowRef = useRef(null);

    const refreshFn = refresh.current;
    const doRemittanceApi = async (remittanceID, onClose) => {
        let res = await deleteRemittanceApi(remittanceID);
        if (res) {
            refreshFn && refreshFn();
            onClose();
        }
    };

    const handleOpenDeleteDialog = (remittanceID) => {
        setMessageDialog({
            open: true,
            title: '確認刪除',
            msg: '刪除後將無法恢復囉，確定要刪除匯款嗎？',
            onAsyncConfirm: (e, onClose) => doRemittanceApi(remittanceID, onClose),
        });
    };

    const handlePageChange = (e, page) => setBookmarkNum(page);

    return (
        <React.Fragment>
            <Stack className={'mb-1'} alignItems={'center'} justifyContent={'space-between'}>
                <Typography className={'font-weight-bold'} variant={'h5'}>
                    匯款總覽
                </Typography>
                <ListInfo
                    className={'ml-auto'}
                    pageIndex={pageIndex}
                    padeSize={pageSize}
                    totalCount={totalCount}
                    isCurrentPageFirstIndex
                    isCurrentPageLastIndex
                />
            </Stack>
            <Box className={'scroll-arrow-container'}>
                <Tablenization
                    cardProps={{ className: 'theme-gray overview' }}
                    tableContainerRef={scrollerArrowRef}
                    count={totalPage}
                    page={pageIndex}
                    onChange={handlePageChange}
                    isPagination
                >
                    <Tablenization.TableRowHeader headerRow={labelTitle.current} />
                    <Tablenization.TableRowBody
                        headerRow={labelTitle.current}
                        rowsData={list}
                        handleOpenDialog={handleOpenDialog}
                        handleOpenDeleteDialog={handleOpenDeleteDialog}
                    />
                </Tablenization>
                <ScrollerArrowButton parentRef={scrollerArrowRef} />
            </Box>
            <RemitAllowanceDialog open={openTarget === dialogType.allowance} remittanceID={remittanceID} onClose={handleCloseDialog} refresh={refreshFn} />
            <RemitInvoiceAbandonDialog
                open={openTarget === dialogType.invoiceAbandon}
                remittanceID={remittanceID}
                onClose={handleCloseDialog}
                refresh={refreshFn}
            />
        </React.Fragment>
    );
};

export default AccountBookRemitOverviewTable;
