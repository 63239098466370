import NonMemberPage, {
    CustomerAddInquiryPage,
    CustomerInformationPage,
    ProspectAddPage,
    ProspectInformationPage,
    TemporariesInfoPage,
    GuestProductionPage,
} from '@icoach/nonMember/';

// 顧客管理
export const NON_MEMBER_CUSTOMER_OVERVIEW_ROUTER = '/club/non-member/customer/overview/';
export const NON_MEMBER_PROSPECT_OVERVIEW_ROUTER = '/club/non-member/prospect/overview/';
export const NON_MEMBER_TEMPORARIES_OVERVIEW_ROUTER = '/club/non-member/temporaries/overview/';
export const NON_MEMBER_CUSTOMER_ADD_ROUTER = '/club/non-member/customer/add/';
export const NON_MEMBER_CUSTOMER_ADD_URL = (id) => `/club/non-member/customer/add/${id}/`;
export const NON_MEMBER_PROSPECT_ADD_ROUTER = '/club/non-member/prospect/add/';
export const NON_MEMBER_CUSTOMER_INFO_ROUTER = '/club/non-member/customer/info/';
export const NON_MEMBER_CUSTOMER_INFO_URL = (id) => `/club/non-member/customer/info/${id}`;
export const NON_MEMBER_PROSPECT_INFO_ROUTER = '/club/non-member/prospect/info/';
export const NON_MEMBER_TEMPORARIES_INFO_ROUTER = `/club/non-member/temporaries/:entity(customer|prospect)/info/`;
export const NON_MEMBER_TEMPORARIES_CUSTOMERTYPE_INFO_URL = (customerTypeName) => `/club/non-member/temporaries/${customerTypeName}/info/`;
export const NON_MEMBER_GUEST_PRODUCTION_OVERVIEW_ROUTER = `/club/non-member/guest-production/overview`;

// 顧客管理
const nonmemberRouter = [
    {
        description: '顧客總覽',
        frontKey: 'C3X01',
    },
    {
        description: '顧客總覽',
        exact: true,
        path: NON_MEMBER_CUSTOMER_OVERVIEW_ROUTER,
        href: NON_MEMBER_CUSTOMER_OVERVIEW_ROUTER,
        as: NonMemberPage,
        frontKey: 'C3X02',
        tabsIndex: 'customerOverview',
    },
    {
        description: '名單總覽',
        exact: true,
        path: NON_MEMBER_PROSPECT_OVERVIEW_ROUTER,
        href: NON_MEMBER_PROSPECT_OVERVIEW_ROUTER,
        as: NonMemberPage,
        frontKey: 'C3X04',
        tabsIndex: 'prospectOverview',
    },
    {
        description: '暫存名單',
        exact: true,
        path: NON_MEMBER_TEMPORARIES_OVERVIEW_ROUTER,
        href: NON_MEMBER_TEMPORARIES_OVERVIEW_ROUTER,
        as: NonMemberPage,
        frontKey: 'C3X06',
        tabsIndex: 'temporariesOverview',
    },
    {
        description: '新增詢問',
        exact: true,
        path: `${NON_MEMBER_CUSTOMER_ADD_ROUTER}:id?`,
        href: NON_MEMBER_CUSTOMER_ADD_ROUTER,
        as: CustomerAddInquiryPage,
        frontKey: 'C3X03',
    },
    {
        description: '新增名單',
        exact: true,
        path: `${NON_MEMBER_PROSPECT_ADD_ROUTER}:id?`,
        href: NON_MEMBER_PROSPECT_ADD_ROUTER,
        as: ProspectAddPage,
        frontKey: 'C3X05',
    },
    {
        description: '顧客基本資料與聯絡記錄',
        exact: true,
        path: `${NON_MEMBER_CUSTOMER_INFO_ROUTER}:id?`,
        as: CustomerInformationPage,
        frontKey: 'C3X09',
    },
    {
        description: '名單基本資料與聯絡記錄',
        exact: true,
        path: `${NON_MEMBER_PROSPECT_INFO_ROUTER}:id?`,
        as: ProspectInformationPage,
        frontKey: 'C3X10',
    },
    {
        description: '暫存名單明細列表記錄',
        exact: true,
        path: `${NON_MEMBER_TEMPORARIES_INFO_ROUTER}:id?`,
        as: TemporariesInfoPage,
        frontKey: 'C3X11',
    },
    {
        description: '主動顧客製造',
        exact: true,
        path: NON_MEMBER_GUEST_PRODUCTION_OVERVIEW_ROUTER,
        href: NON_MEMBER_GUEST_PRODUCTION_OVERVIEW_ROUTER,
        as: GuestProductionPage,
        frontKey: 'C3X07',
    },
];

export default nonmemberRouter;
