import React, { useEffect, useState, useRef } from 'react';
import { useSnackbar } from 'notistack';
import { Dialog, DialogContent, DialogActions } from '@mui/material';
import useInBody from '@icoach/inbody/components/useInBody';
import ProcessCircleBox from '@icoach/inbody/components/ProcessCircleBox';
import GradientColorHeader from '@icoach/components/dialog/GradientColorHeader';
import { Button, SelectField, MenuItem, TextField, RadioField, Grid, Box } from '@common/components/';
import { refIsRequiredError } from '@util/utils';
import useInBodyApi from '@apis/useInBodyApi';
import InBodyInterviewCancelMeasureDialog from '@icoach/inbody/interview/InBodyInterviewCancelMeasureDialog';
import useToggleDialog from '@util/hook/useToggleDialog';
import InBodyInterviewImpedanceDialog from '@icoach/inbody/interview/InBodyInterviewImpedanceDialog';
import inBodyImage from '@icoach/assets/img/image-7.png';

const dialogTypeMap = {
    cancelMeasure: 'cancelMeasure',
    addMeasure: 'addMeasure',
    openUnusualImpedance: 'openUnusualImpedance',
};
const InBodyInterviewAddMeasureDialog = React.forwardRef((props, ref) => {
    const { peopleID, memberHeight: height, setSourceData: setMeasureData, isInterviewNotMeasure, options = [] } = useInBody();
    const { enqueueSnackbar } = useSnackbar();
    const { openTarget, handleOpenDialog, handleCloseDialog } = useToggleDialog();
    const [openProcessCircle, setOpenProcessCircle] = useState(false);
    const [prevMeasureParam, setPrevMeasureParam] = useState(null);
    const employeesRef = useRef(null);
    const memberHeightRef = useRef(null);
    const genderRef = useRef(null);

    const { postTriggerMeasureApi, getInterviewMemberMeasureApi } = useInBodyApi();

    const getAllParams = (emp, heig) => {
        return {
            employeeID: emp,
            height: heig,
            referenceID: peopleID,
            referenceType: 2,
            gender: genderRef.current.getResult().find((target) => target.checked)?.value ?? 1,
        };
    };

    const handleStartInBodyMeasure = () => {
        if (!refIsRequiredError(employeesRef, memberHeightRef)) {
            const employeeID = employeesRef.current.getResult();
            const memberHeight = memberHeightRef.current.getResult();
            const params = getAllParams(employeeID, memberHeight);

            setPrevMeasureParam(params);
            doPrepareInbodyMeasureApi(params);
        }
    };

    const handleOpenAddMeasureDialog = () => {
        if (isInterviewNotMeasure) {
            enqueueSnackbar('已勾選只面談不量身，無法新增量身', { variant: 'info' });
        } else {
            handleOpenDialog(dialogTypeMap.addMeasure);
        }
    };

    const handleOpenImpedanceDialog = () => {
        setOpenProcessCircle(null);
        handleOpenDialog(dialogTypeMap.openUnusualImpedance);
    };

    const handleConfirmAgainInBodyMeasure = () => {
        if (prevMeasureParam) {
            doPrepareInbodyMeasureApi(prevMeasureParam);
        } else {
            setOpenProcessCircle(false);
        }
    };

    const doCloseDialog = () => {
        setOpenProcessCircle(null);
        handleCloseDialog();
    };

    const doPrepareInbodyMeasureApi = async (params) => {
        const resp = await postTriggerMeasureApi(params);
        if (resp.isError) {
            if (resp.statusCode === 'ME003') {
                handleOpenDialog(dialogTypeMap.cancelMeasure);
            }
        } else {
            setOpenProcessCircle(true);
        }
    };

    const successRefreshApi = async () => {
        const resp = await getInterviewMemberMeasureApi(peopleID);
        if (resp) {
            setMeasureData && setMeasureData((preData) => ({ ...preData, ...resp }));
        }
    };

    useEffect(
        () => {
            return () => doCloseDialog();
        },
        // eslint-disable-next-line
        []
    );

    const { employeesOptions } = options;

    return (
        <React.Fragment>
            <Button className={'measure-weight-button'} variant="contained" onClick={handleOpenAddMeasureDialog}>
                <React.Fragment>
                    <Box component={'img'} src={inBodyImage} />
                    進行量身
                </React.Fragment>
            </Button>
            <Dialog open={openTarget === dialogTypeMap.addMeasure} PaperProps={{ className: 'wx-40rem' }} fullWidth>
                <GradientColorHeader onClose={doCloseDialog}>新增量身</GradientColorHeader>
                {!openProcessCircle ? (
                    <React.Fragment>
                        <DialogContent>
                            <div className="pt-3 px-3">
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <SelectField direction={'row'} label="擔當" displayEmpty required ref={employeesRef} fullWidth>
                                            <MenuItem disabled value="">
                                                <em>請選擇</em>
                                            </MenuItem>
                                            {employeesOptions.map(({ value, text, disabled }) => (
                                                <MenuItem key={value} value={value} disabled={disabled}>
                                                    {text}
                                                </MenuItem>
                                            ))}
                                        </SelectField>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            direction={'row'}
                                            ref={memberHeightRef}
                                            label="身高"
                                            type={'number'}
                                            defaultValue={height}
                                            fullWidth
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <RadioField ref={genderRef} defaultValue={1} label="性別" labelDirection="row" required row>
                                            <RadioField.Radio value={1} label={'女'} />
                                            <RadioField.Radio value={2} label={'男'} />
                                        </RadioField>
                                    </Grid>
                                </Grid>
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleStartInBodyMeasure}>開始量身</Button>
                        </DialogActions>
                    </React.Fragment>
                ) : (
                    <DialogContent>
                        <ProcessCircleBox
                            className="pt-3 px-3"
                            peopleID={peopleID}
                            refresh={successRefreshApi}
                            onClose={doCloseDialog}
                            onOpenImpedanceDialog={handleOpenImpedanceDialog}
                        />
                    </DialogContent>
                )}
            </Dialog>
            <InBodyInterviewCancelMeasureDialog
                open={openTarget === dialogTypeMap.cancelMeasure}
                onClose={handleCloseDialog}
                refresh={handleOpenAddMeasureDialog}
            />
            <InBodyInterviewImpedanceDialog
                open={openTarget === dialogTypeMap.openUnusualImpedance}
                onClose={handleCloseDialog}
                onConfirm={handleConfirmAgainInBodyMeasure}
            />
        </React.Fragment>
    );
});

export default InBodyInterviewAddMeasureDialog;
