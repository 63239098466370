import React from 'react';
import clsx from 'clsx';
import MenuList from './MenuList';
import Popover from '../popover/Popover';

const staticClass = `menu`;

const anchorOriginDefault = (isRtl) => ({
    vertical: 'bottom',
    horizontal: isRtl ? 'right' : 'left',
});

const RTL_ORIGIN = {
    vertical: 'top',
    horizontal: 'right',
};

const LTR_ORIGIN = {
    vertical: 'top',
    horizontal: 'left',
};

const Menu = React.forwardRef((props, ref) => {
    const {
        className,
        autoFocus = true,
        onClose,
        open,
        disableAutoFocusItem = false,
        MenuListProps = {},
        PaperProps = {},
        transitionDuration = 'auto',
        TransitionProps: { onEntering, ...TransitionProps } = {},
        variant = 'selectedMenu',
        children,
        isRtl = false,
        anchorOrigin = anchorOriginDefault(isRtl),
        ...other
    } = props;
    const autoFocusItem = autoFocus && !disableAutoFocusItem && open;
    const menuListActionsRef = React.useRef(null);

    const handleEntering = (element, isAppearing) => {
        if (menuListActionsRef.current) {
            menuListActionsRef.current.adjustStyleForScrollbar(element, isRtl);
        }
        onEntering && onEntering(element, isAppearing);
    };

    const handleListKeyDown = (event) => {
        if (event.key === 'Tab') {
            event.preventDefault();
            onClose && onClose(event, 'tabKeyDown');
        }
    };

    /**
     * the index of the item should receive focus
     * in a `variant="selectedMenu"` it's the first `selected` item
     * otherwise it's the very first item.
     */
    let activeItemIndex = -1;
    // since we inject focus related props into children we have to do a lookahead
    // to check if there is a `selected` item. We're looking for the last `selected`
    // item and use the first valid item as a fallback
    React.Children.map(children, (child, index) => {
        if (!React.isValidElement(child)) return;
        if (!child.props.disabled) {
            if (variant === 'selectedMenu' && child.props.selected) activeItemIndex = index;
            else if (activeItemIndex === -1) activeItemIndex = index;
        }
    });
    return (
        <Popover
            onClose={onClose}
            anchorOrigin={{ ...anchorOriginDefault(isRtl), ...anchorOrigin }}
            transformOrigin={isRtl ? RTL_ORIGIN : LTR_ORIGIN}
            PaperProps={{
                ...PaperProps,
                className: clsx(staticClass, PaperProps.className, className),
            }}
            open={open}
            ref={ref}
            transitionDuration={transitionDuration}
            TransitionProps={{ onEntering: handleEntering, ...TransitionProps }}
            {...other}
        >
            <MenuList
                onKeyDown={handleListKeyDown}
                actions={menuListActionsRef}
                autoFocus={autoFocus && (activeItemIndex === -1 || disableAutoFocusItem)}
                autoFocusItem={autoFocusItem}
                variant={variant}
                {...MenuListProps}
                className={clsx(MenuListProps.className)}
            >
                {children}
            </MenuList>
        </Popover>
    );
});

export default Menu;
