import { useCallback } from 'react';
import useAjax from '@apis/useAjax';
import { useSnackbar } from 'notistack';

const GET_MEMBER_FILTER_EXPORT_WORKOUT_FREQUENCY_API = `/api/member/filter-export/workout-frequency/`;
const GET_MEMBER_FILTER_EXPORT_HUNDRED_T_SHIRT_API = `/api/member/filter-export/hundred-t-shirt/`;
const GET_MEMBER_FILTER_EXPORT_NEW_API = `/api/member/filter-export/new/`;
const GET_MEMBER_FILTER_EXPORT_INVALID_API = `/api/member/filter-export/invalid/`;
const GET_MEMBER_FILTER_EXPORT_BIRTHDAY_API = `/api/member/filter-export/birthday/`;
const GET_MEMBER_FILTER_EXPORT_LEAVE_API = `/api/member/filter-export/leave/`;
const GET_MEMBER_FILTER_EXPORT_RENEW_API = `/api/member/filter-export/renew/`;
const POST_MEMBER_FILTER_EXPORT_HEALTHCARE_API = `/api/member/filter-export/healthcare/`;
const GET_MEMBER_FILTER_EXPORT_MEASURE_NOT_YET_API = `/api/member/filter-export/measure-not-yet/`;
const GET_MEMBER_FILTER_EXPORT_MEASURED_API = `/api/member/filter-export/measured/`;
const GET_MEMBER_FILTER_EXPORT_OVERDRAFT_API = `/api/member/filter-export/overdraft/`;
const GET_MEMBER_FILTER_EXPORT_MONTH_FEE_API = `/api/member/filter-export/month-fee/`;
const GET_MEMBER_FILTER_EXPORT_OVERVIEW_API = `/api/member/filter-export/overview/`;
const GET_MEMBER_FILTER_EXPORT_MEASURE_RESULT_API = `/api/member/filter-export/measure-result/`;

const useMemberFilterExportApi = () => {
    const Ajax = useAjax();
    const { enqueueSnackbar } = useSnackbar();

    const getMemberFilterWorkoutFrequencyApi = useCallback(
        async (params) => {
            const resp = await Ajax.get(GET_MEMBER_FILTER_EXPORT_WORKOUT_FREQUENCY_API, params);
            if (resp && typeof resp === 'string') {
                enqueueSnackbar(resp, { variant: 'success' });
                return null;
            } else if (resp && Object.prototype.toString.call(resp) === '[object Object]') {
                return resp;
            }
        },
        // eslint-disable-next-line
        [],
    );

    const getMemberFilterHundredTShirtApi = useCallback(
        async (params) => {
            const resp = await Ajax.get(GET_MEMBER_FILTER_EXPORT_HUNDRED_T_SHIRT_API, params);
            if (resp && typeof resp === 'string') {
                enqueueSnackbar(resp, { variant: 'success' });
                return null;
            } else if (resp && Object.prototype.toString.call(resp) === '[object Object]') {
                return resp;
            }
        },
        // eslint-disable-next-line
        [],
    );

    const getMemberFilterNewApi = useCallback(
        async (params) => {
            const resp = await Ajax.get(GET_MEMBER_FILTER_EXPORT_NEW_API, params);
            if (resp && typeof resp === 'string') {
                enqueueSnackbar(resp, { variant: 'success' });
                return null;
            } else if (resp && Object.prototype.toString.call(resp) === '[object Object]') {
                return resp;
            }
        },
        // eslint-disable-next-line
        [],
    );

    const getMemberFilterInvalidApi = useCallback(
        async (params) => {
            const resp = await Ajax.get(GET_MEMBER_FILTER_EXPORT_INVALID_API, params);
            if (resp && typeof resp === 'string') {
                enqueueSnackbar(resp, { variant: 'success' });
                return null;
            } else if (resp && Object.prototype.toString.call(resp) === '[object Object]') {
                return resp;
            }
        },
        // eslint-disable-next-line
        [],
    );

    const getMemberFilterBirthdayApi = useCallback(
        async (params) => {
            const resp = await Ajax.get(GET_MEMBER_FILTER_EXPORT_BIRTHDAY_API, params);
            if (resp && typeof resp === 'string') {
                enqueueSnackbar(resp, { variant: 'success' });
                return null;
            } else if (resp && Object.prototype.toString.call(resp) === '[object Object]') {
                return resp;
            }
        },
        // eslint-disable-next-line
        [],
    );

    const getMemberFilterLeaveApi = useCallback(
        async (params) => {
            const resp = await Ajax.get(GET_MEMBER_FILTER_EXPORT_LEAVE_API, params);
            if (resp && typeof resp === 'string') {
                enqueueSnackbar(resp, { variant: 'success' });
                return null;
            } else if (resp && Object.prototype.toString.call(resp) === '[object Object]') {
                return resp;
            }
        },
        // eslint-disable-next-line
        [],
    );

    const getMemberFilterRenewApi = useCallback(
        async (params) => {
            const resp = await Ajax.get(GET_MEMBER_FILTER_EXPORT_RENEW_API, params);
            if (resp && typeof resp === 'string') {
                enqueueSnackbar(resp, { variant: 'success' });
                return null;
            } else if (resp && Object.prototype.toString.call(resp) === '[object Object]') {
                return resp;
            }
        },
        // eslint-disable-next-line
        [],
    );

    const postMemberFilterHealthcareApi = useCallback(
        async (params) => {
            const resp = await Ajax.post(POST_MEMBER_FILTER_EXPORT_HEALTHCARE_API, params);
            if (resp && typeof resp === 'string') {
                enqueueSnackbar(resp, { variant: 'success' });
                return null;
            } else if (resp && Object.prototype.toString.call(resp) === '[object Object]') {
                return resp;
            }
        },
        // eslint-disable-next-line
        [],
    );

    const getMemberFilterMeasureNotYetApi = useCallback(
        async (params) => {
            const resp = await Ajax.get(GET_MEMBER_FILTER_EXPORT_MEASURE_NOT_YET_API, params);
            if (resp && typeof resp === 'string') {
                enqueueSnackbar(resp, { variant: 'success' });
                return null;
            } else if (resp && Object.prototype.toString.call(resp) === '[object Object]') {
                return resp;
            }
        },
        // eslint-disable-next-line
        [],
    );

    const getMemberFilterMeasuredApi = useCallback(
        async (params) => {
            const resp = await Ajax.get(GET_MEMBER_FILTER_EXPORT_MEASURED_API, params);
            if (resp && typeof resp === 'string') {
                enqueueSnackbar(resp, { variant: 'success' });
                return null;
            } else if (resp && Object.prototype.toString.call(resp) === '[object Object]') {
                return resp;
            }
        },
        // eslint-disable-next-line
        [],
    );

    const getMemberFilterOverdraftApi = useCallback(
        async (params) => {
            const resp = await Ajax.get(GET_MEMBER_FILTER_EXPORT_OVERDRAFT_API, params);
            if (resp && typeof resp === 'string') {
                enqueueSnackbar(resp, { variant: 'success' });
                return null;
            } else if (resp && Object.prototype.toString.call(resp) === '[object Object]') {
                return resp;
            }
        },
        // eslint-disable-next-line
        [],
    );

    const getMemberFilterMonthFeeApi = useCallback(
        async (params) => {
            const resp = await Ajax.get(GET_MEMBER_FILTER_EXPORT_MONTH_FEE_API, params);
            if (resp && typeof resp === 'string') {
                enqueueSnackbar(resp, { variant: 'success' });
                return null;
            } else if (resp && Object.prototype.toString.call(resp) === '[object Object]') {
                return resp;
            }
        },
        // eslint-disable-next-line
        [],
    );

    const getMemberFilterOverviewApi = useCallback(
        async (params) => {
            const resp = await Ajax.get(GET_MEMBER_FILTER_EXPORT_OVERVIEW_API, params);
            if (resp && typeof resp === 'string') {
                enqueueSnackbar(resp, { variant: 'success' });
                return null;
            } else if (resp && Object.prototype.toString.call(resp) === '[object Object]') {
                return resp;
            }
        },
        // eslint-disable-next-line
        [],
    );

    const getMemberFilterMeasureResultApi = useCallback(async (params) => {
        const resp = await Ajax.get(GET_MEMBER_FILTER_EXPORT_MEASURE_RESULT_API, params);
        if (resp && typeof resp === 'string') {
            enqueueSnackbar(resp, { variant: 'success' });
            return null;
        } else if (resp && Object.prototype.toString.call(resp) === '[object Object]') {
            return resp;
        }
        // eslint-disable-next-line
    }, []);

    return {
        getMemberFilterWorkoutFrequencyApi,
        getMemberFilterHundredTShirtApi,
        getMemberFilterNewApi,
        getMemberFilterInvalidApi,
        getMemberFilterBirthdayApi,
        getMemberFilterLeaveApi,
        getMemberFilterRenewApi,
        postMemberFilterHealthcareApi,
        getMemberFilterMeasureNotYetApi,
        getMemberFilterMeasuredApi,
        getMemberFilterOverdraftApi,
        getMemberFilterMonthFeeApi,
        getMemberFilterOverviewApi,
        getMemberFilterMeasureResultApi,
    };
};

export default useMemberFilterExportApi;
