import React, { useState, useCallback, useRef } from 'react';
import Cropper from 'react-easy-crop';
import getCroppedImg from '@util/cropImage';
import { Box, Button } from '@common/components/';
import { PhotoCamera as PhotoCameraIcon } from '@common/SvgIcon/';

export const ProfileImageDisplay = (props) => {
    const { imageUrl } = props;
    return (
        <Box className={'profile-image-display'}>
            {imageUrl && (
                <Box className="profile-image">
                    <Box component={'img'} src={imageUrl} />
                </Box>
            )}
        </Box>
    );
};

const ProfileImageUploader = (props) => {
    const { onImageUpload, buttonText } = props;
    const [imageSrc, setImageSrc] = useState(null);
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
    const [showCropper, setShowCropper] = useState(false);
    const fileInputRef = useRef(null);

    const handleFileChange = async (event) => {
        const file = event.target.files[0];
        if (file) {
            const imageDataUrl = await readFile(file);
            setImageSrc(imageDataUrl);
            setShowCropper(true);
        }
    };

    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels);
    }, []);

    const handleCrop = useCallback(async () => {
        try {
            const croppedImageUrl = await getCroppedImg(imageSrc, croppedAreaPixels);
            const compressedImageUrl = await compressImage(croppedImageUrl);
            const blob = await fetch(compressedImageUrl).then((res) => res.blob());
            onImageUpload && onImageUpload({ blob, previewImage: compressedImageUrl });
            resetUploader();
        } catch (e) {
            console.error(e);
        }
    }, [imageSrc, croppedAreaPixels, onImageUpload]);

    const compressImage = async (imageUrl) => {
        return new Promise((resolve) => {
            const img = new Image();
            img.src = imageUrl;
            img.onload = () => {
                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');
                const maxSize = 500;
                let width = img.width;
                let height = img.height;

                if (width > height) {
                    if (width > maxSize) {
                        height *= maxSize / width;
                        width = maxSize;
                    }
                } else {
                    if (height > maxSize) {
                        width *= maxSize / height;
                        height = maxSize;
                    }
                }

                canvas.width = width;
                canvas.height = height;
                ctx.drawImage(img, 0, 0, width, height);

                canvas.toBlob(
                    (blob) => {
                        const reader = new FileReader();
                        reader.readAsDataURL(blob);
                        reader.onloadend = () => {
                            resolve(reader.result);
                        };
                    },
                    'image/jpeg',
                    0.95
                );
            };
        });
    };

    const readFile = (file) => {
        return new Promise((resolve) => {
            const reader = new FileReader();
            reader.addEventListener('load', () => resolve(reader.result));
            reader.readAsDataURL(file);
        });
    };

    const handleCancel = () => {
        resetUploader();
    };

    const resetUploader = () => {
        setImageSrc(null);
        setCrop({ x: 0, y: 0 });
        setZoom(1);
        setCroppedAreaPixels(null);
        setShowCropper(false);
        if (fileInputRef.current) {
            fileInputRef.current.value = '';
        }
    };

    return (
        <Box className={'profile-image-uploader'}>
            <input
                ref={fileInputRef}
                type={'file'}
                accept={'image/*'}
                onChange={handleFileChange}
                className={'upload-input'}
                id={'upload-input'}
                style={{ display: 'none' }}
            />
            <label htmlFor={'upload-input'} className={'upload-icon'}>
                <PhotoCameraIcon />
                {buttonText && <span>{buttonText}</span>}
            </label>
            {showCropper && (
                <Box className={'crop-container'}>
                    <Box className={'crop-wrap'}>
                        <Cropper
                            image={imageSrc}
                            crop={crop}
                            zoom={zoom}
                            aspect={1}
                            onCropChange={setCrop}
                            onZoomChange={setZoom}
                            onCropComplete={onCropComplete}
                        />
                        <Box className={'crop-controller'}>
                            <label className={'zoom-range-label'}>
                                縮小
                                <input
                                    type={'range'}
                                    min={'1'}
                                    max={'3'}
                                    step={'0.1'}
                                    value={zoom}
                                    onChange={(e) => setZoom(e.target.value)}
                                    className={'zoom-range'}
                                />
                                放大
                            </label>
                            <Box className={'btn-group'}>
                                <Button variant={'contained'} color={'gray'} onClick={handleCancel}>
                                    取消
                                </Button>
                                <Button variant={'contained'} onClick={handleCrop}>
                                    確認並上傳
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            )}
        </Box>
    );
};

export default ProfileImageUploader;
