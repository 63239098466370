// 半小時全時段
export const halfPeriodOption = Array(48)
    .fill()
    .map((_x, _index) => {
        const value = 1800 * _index;
        const tranTime = (value / 3600).toString().split('.');
        const hh = tranTime[0].padStart(2, 0);
        const mm = tranTime[1] ? tranTime[1] * 6 : '00';
        return { text: `${hh}:${mm}`, value };
    });

// pageIndex: 第幾頁
// pageSize:  一頁幾筆
export const initBookmark = {
    pageIndex: 1,
    pageSize: 10,
};

export const PROSPECT_ROUTER = 'prospect';
export const CUSTOMER_ROUTER = 'customer';

export const customerTypeRouterMap = {
    1: PROSPECT_ROUTER,
    2: CUSTOMER_ROUTER,
};

export const customerTypeMap = {
    [PROSPECT_ROUTER]: 1,
    [CUSTOMER_ROUTER]: 2,
};

export const customerDialogTextMap = {
    TEXT: '顧客',
    INVALIDTEXT: '無效顧客',
};

export const prospectDialogTextMap = {
    TEXT: '名單',
    INVALIDTEXT: '無效名單',
};
