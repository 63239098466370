import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { isEmpty, trim } from 'lodash';
import { Form, Stack, TextField, Button, Card, Grid, MenuItem, SelectField } from '@common/components/';
import { objectToQueryString, paramsToObject, refIsRequiredError } from '@util/utils';
import useCabinetApi from '@apis/useCabinetApi';
import { useRentCabinetContext } from './components/RentCabinetContext';
import { cabinetStatusMap, initBookmark } from './staticData';
import { RENT_CABINET_CABINETS_URL } from '@icoach/router/MembersRouter';
import { useHistory } from 'react-router-dom';

// 租櫃管理用
const CabinetOverviewSearchBar = React.forwardRef((props, ref) => {
    const { className } = props;
    const history = useHistory();
    const { setRefresh, setTableData, bookmark, setBookmarkNum } = useRentCabinetContext();
    const [paramsData, setParamsData] = useState({});
    const { cabinetStatus, cabinetName } = paramsData || {};
    const [resource, setResource] = useState({});
    const { cabinetStatusOption = [] } = resource;
    const { postCabinetListApi, getCabinetOptionsApi } = useCabinetApi();
    const cabinetStatusRef = useRef();
    const cabinetNameRef = useRef();
    const preload = useRef(false);

    const updateUrlWithSearchQuery = (params) => {
        let url = RENT_CABINET_CABINETS_URL;
        if (!isEmpty(params)) url += objectToQueryString(params);
        history.replace(url);
    };

    const getParams = (pageInfo) => {
        let result = {};

        if (!isEmpty(pageInfo)) {
            result = Object.assign(result, pageInfo);
        }
        if (cabinetStatusRef.current && cabinetStatusRef.current.getResult) {
            result = Object.assign(result, {
                cabinetStatus: cabinetStatusRef.current.getResult(),
            });
        }
        if (cabinetNameRef.current && cabinetNameRef.current.getResult) {
            result = Object.assign(result, {
                cabinetName: trim(cabinetNameRef.current.getResult()),
            });
        }

        return result;
    };

    const getOverviewApi = async (params) => {
        let resp = await postCabinetListApi(params);
        if (resp) {
            let { list, ...other } = resp;
            if (String(params.pageIndex) === '1') {
                setTableData({ list, ...other });
            } else {
                setTableData((prev) => {
                    const prevList = prev.list;
                    let targetList = list;

                    if (Array.isArray(prevList)) {
                        targetList = prevList.concat(targetList);
                    }

                    return {
                        list: targetList,
                        ...other,
                    };
                });
            }
        }
        updateUrlWithSearchQuery(params);
        preload.current = true;
    };

    const getOptionsApi = async () => {
        let res = await getCabinetOptionsApi();
        if (res) {
            setResource(res);
            const { location } = history;
            const search = location.search;
            const params = search ? paramsToObject(search) : getParams(bookmark);
            if (!isEmpty(params)) {
                setParamsData(params);
            }
            preload.current = false;
            getOverviewApi(params);
        }
    };

    const handleSearchSubmit = () => {
        let isError = refIsRequiredError(cabinetStatusRef, cabinetNameRef);
        if (!isError) {
            let params = getParams(initBookmark());
            preload.current = false;
            getOverviewApi(params);
            setBookmarkNum(1);
        }
    };

    useEffect(
        () => {
            if (!preload.current) return;
            const params = getParams(bookmark);
            preload.current = false;
            getOverviewApi(params);
        },
        // eslint-disable-next-line
        [bookmark.pageIndex],
    );

    useEffect(() => {
        getOptionsApi();
        setRefresh(handleSearchSubmit);
        // eslint-disable-next-line
    }, []);

    return (
        <Card className={clsx('search-bar-light px-4 py-3', className)}>
            <Card.CardContent>
                <Form onSubmit={() => handleSearchSubmit(initBookmark())}>
                    <Stack spacing={3} alignItems={'center'}>
                        <Grid spacing={3} container>
                            <Grid xs={6} item>
                                <TextField
                                    key={cabinetName}
                                    label={'置物櫃名稱'}
                                    ref={cabinetNameRef}
                                    defaultValue={cabinetName}
                                    className={'flex-auto'}
                                    InputProps={{ placeholder: '請輸入置物櫃名稱' }}
                                    fullWidth
                                />
                            </Grid>
                            <Grid xs={6} item>
                                <SelectField key={cabinetStatus} ref={cabinetStatusRef} label={'置物櫃狀態'} defaultValue={cabinetStatusMap.all} fullWidth>
                                    {Array.isArray(cabinetStatusOption) &&
                                        cabinetStatusOption.map(({ text, value }) => (
                                            <MenuItem value={value} key={value}>
                                                {text}
                                            </MenuItem>
                                        ))}
                                </SelectField>
                            </Grid>
                        </Grid>
                        <Button className={'white-space-nowrap mt-3'} type={'submit'} variant={'contained'}>
                            搜尋
                        </Button>
                    </Stack>
                </Form>
            </Card.CardContent>
        </Card>
    );
});

export default CabinetOverviewSearchBar;
