import React from 'react';
import SvgIcon from '../SvgIcon';
import useForkRef from '@util/hook/useForkRef';

const Assignment = React.forwardRef((props, ref) => {
    const { className, ...others } = props;
    const handleRef = React.useRef(null);
    const svgRef = useForkRef(handleRef, ref);
    const defaultProperty = {
        className,
        ref: svgRef,
        ...others,
    };
    return (
        <SvgIcon {...defaultProperty}>
            <path d="M19 3h-4.18C14.4 1.84 13.3 1 12 1c-1.3 0-2.4.84-2.82 2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-7 0c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1zm2 14H7v-2h7v2zm3-4H7v-2h10v2zm0-4H7V7h10v2z" />
        </SvgIcon>
    );
});

export default Assignment;
