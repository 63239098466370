import React, { useRef } from 'react';
import { Chip } from '../../../../components';
import Tablenization from '../../../components/Tablenization';
import {displayDateText, isValidDate} from '../../../../util/moment';
import { COLOR_MATCH } from '../../../accountBook/staticData';
import { MemoControllerBox } from '../../../components';

const rowsLabel = [
    {
        cellKey: 'orderDate',
        headerLabel: '日期',
        formatCell: (cellValue, {paymentDate}) => 
            isValidDate(paymentDate) ? displayDateText(paymentDate) : displayDateText(cellValue),
    },
    {
        cellKey: 'itemName',
        headerLabel: '品項',
    },
    {
        cellKey: 'paymentStatusText',
        headerLabel: '付款狀態',
        align: 'center',
        formatCell: (cellValue, { paymentStatus }) => {
            return <Chip label={cellValue} color={COLOR_MATCH[paymentStatus]} />;
        },
    },
    {
        cellKey: 'invoiceStatusText',
        headerLabel: '發票狀態',
        align: 'center',
        formatCell: (cellValue, rowData) => {
            const { invoiceStatus } = rowData;
            return <Chip label={cellValue} color={COLOR_MATCH[invoiceStatus]} />;
        },
    },
    {
        cellKey: 'invoiceNo',
        headerLabel: '發票號碼',
        align: 'center',
    },
    {
        cellKey: 'itemPrice',
        headerLabel: '金額',
        align: 'right',
    },
    {
        cellKey: 'notes',
        headerLabel: '備註',
        formatCell: (cellValue) => {
            return <MemoControllerBox data={cellValue} dialogTitle={'匯款說明'} />;
        },
    },
];

const BillingRecordTable = (props) => {
    const { className, rowsData = [], count = 1, page = 1, handlePageChange } = props;
    const labelTitle = useRef(rowsLabel);

    return (
        <React.Fragment>
            <Tablenization
                cardProps={{ className: className }}
                count={count}
                page={page}
                onChange={handlePageChange}
                isPagination
            >
                <Tablenization.TableRowHeader headerRow={labelTitle.current} />
                <Tablenization.TableRowBody headerRow={labelTitle.current} rowsData={rowsData} />
            </Tablenization>
        </React.Fragment>
    );
};

export default BillingRecordTable;
