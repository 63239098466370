import React, { useRef } from 'react';
import { NavLink, useParams, Switch, useHistory } from 'react-router-dom';
import { Button, Tab, Tabs, Box, PrivateRoute } from '@common/components/';
import {
    CashRegister as CashRegisterIcon,
    DollarSign as DollarSignIcon,
    FileInvoiceDollar as FileInvoiceDollarIcon,
    ListUl as ListUlIcon,
} from '@common/SvgIcon/';
import AccountBookOrderOverviewPage from '@icoach/accountBook/AccountBookOrderOverviewPage';
import AccountBookOrderStatOnSitePage from '@icoach/accountBook/AccountBookOrderStatOnSitePage';
import AccountBookOrderStatOnAllPage from '@icoach/accountBook/AccountBookOrderStatOnAllPage';
import AccountBookContentRemitOrderOverview from '@icoach/accountBook/AccountBookRemitOverviewPage';
import BatchPaymentManagementPage from '@icoach/accountBook/BatchPaymentManagementPage';
import {
    ACCOUNT_BOOK_ORDER_CREATE,
    ACCOUNT_BOOK_REMIT_CREATE,
    ACCOUNT_BOOK_ORDERS_OVERVIEW_URL,
    ACCOUNT_BOOK_REMIT_ORDERS_OVERVIEW_URL,
    ACCOUNT_BOOK_BULK_DEBIT_URL,
    ACCOUNT_BOOK_SITE_ORDER_STAT_URL,
    ACCOUNT_BOOK_ALL_ORDER_STAT_URL,
} from '@icoach/router/AccountBookRouter';
import AccountBookDialogBox from '@icoach/accountBook/dialog/AccountBookDialogBox';

const AccountBookTabs = (props) => {
    const { value: propsValue, handleTabsValue = () => {} } = props;
    const history = useHistory();

    const handleChange = (event, newValue) => {
        handleTabsValue && handleTabsValue(newValue);
    };
    return (
        <Box className="page-tabs tabs-bottom-line mb-4">
            <Tabs value={propsValue} onChange={handleChange}>
                <Tab className={'py-3'} label={'收入總覽'} value={'orders'} icon={<ListUlIcon />} component={NavLink} to={ACCOUNT_BOOK_ORDERS_OVERVIEW_URL} />
                <Tab
                    className={'py-3'}
                    label={'匯款總覽'}
                    value={'remit-orders'}
                    icon={<ListUlIcon />}
                    component={NavLink}
                    to={ACCOUNT_BOOK_REMIT_ORDERS_OVERVIEW_URL}
                />
                <Tab
                    className={'py-3'}
                    label={'現場收入總覽'}
                    value={'site-order-stat'}
                    icon={<CashRegisterIcon />}
                    component={NavLink}
                    to={ACCOUNT_BOOK_SITE_ORDER_STAT_URL}
                />
                <Tab
                    className={'py-3'}
                    label={'總收入總覽'}
                    value={'all-order-stat'}
                    icon={<DollarSignIcon />}
                    component={NavLink}
                    to={ACCOUNT_BOOK_ALL_ORDER_STAT_URL}
                />
                <Tab
                    className={'py-3'}
                    label={'批次扣款'}
                    value={'bulk-debit'}
                    icon={<FileInvoiceDollarIcon />}
                    component={NavLink}
                    to={ACCOUNT_BOOK_BULK_DEBIT_URL}
                />
            </Tabs>
            <Box className="btn-group">
                <Button variant={'outlined'} onClick={() => history.push(ACCOUNT_BOOK_REMIT_CREATE)}>
                    新增匯款
                </Button>
                <Button variant={'contained'} onClick={() => history.push(ACCOUNT_BOOK_ORDER_CREATE)}>
                    新增收入
                </Button>
            </Box>
        </Box>
    );
};

const AccountBookPage = (props) => {
    const { tabs } = useParams();
    const dialogRef = useRef(null);
    return (
        <Box className="container main-container-spacing">
            <AccountBookTabs value={tabs} />
            <Switch>
                <PrivateRoute path={ACCOUNT_BOOK_ORDERS_OVERVIEW_URL} as={AccountBookOrderOverviewPage} dialogRef={dialogRef} exact />
                <PrivateRoute path={ACCOUNT_BOOK_REMIT_ORDERS_OVERVIEW_URL} as={AccountBookContentRemitOrderOverview} dialogRef={dialogRef} exact />
                <PrivateRoute path={ACCOUNT_BOOK_SITE_ORDER_STAT_URL} as={AccountBookOrderStatOnSitePage} dialogRef={dialogRef} exact />
                <PrivateRoute path={ACCOUNT_BOOK_ALL_ORDER_STAT_URL} as={AccountBookOrderStatOnAllPage} dialogRef={dialogRef} exact />
                <PrivateRoute path={ACCOUNT_BOOK_BULK_DEBIT_URL} as={BatchPaymentManagementPage} dialogRef={dialogRef} />
            </Switch>
            <AccountBookDialogBox ref={dialogRef} />
        </Box>
    );
};

export default AccountBookPage;
