import React, { useRef } from 'react';
import { isBoolean } from 'lodash';
import { useParams, useHistory } from 'react-router-dom';
import { Typography } from '@common/components/';
import Tablenization from '@icoach/components/Tablenization';
import {
    DOCUMENTS_MEMBERSHIPSUSPENSION_PEOPLETYPE_REFERENCEID_HISTORY_ROUTER,
    DOCUMENTS_MEMBERSHIPSUSPENSION_PEOPLETYPE_REFERENCEID_CONTRACT_ROUTER,
    DOCUMENTS_MEMBERSHIPSUSPENSION_PEOPLETYPE_REFERENCEID_READONLY_CONTRACT_ROUTER,
} from '@icoach/router/routerPath';
import { parseDate } from '@util/moment';
import { pathRouterShit } from '@util/utils';
import useToggleDialog from '@util/hook/useToggleDialog';
import useMessageDialog from '@util/hook/useMessageDialog';
import useDocMembershipSuspendApi from '@apis/useDocMembershipSuspendApi';
import { moreColData } from '@icoach/documents/components/moreColData';
import { dialogTypeMap } from '@icoach/documents/membership/staticData';
import { LeaveCancellationDialog, ConfirmDeleteDocDialog } from '@icoach/documents/membership/dialog';
import { DateTimeStandard } from '../../../components/pickers/enums/DateTimeStandard';

const rowsLabel = [
    {
        cellKey: 'applyDate',
        headerLabel: '申請時間',
        width: 20,
        formatCell: (cellValue) => parseDate(cellValue, DateTimeStandard.DisplayDateBySlash, false),
    },
    {
        cellKey: 'changeDate',
        headerLabel: '最後更新時間',
        width: 20,
        formatCell: (cellValue) => parseDate(cellValue, DateTimeStandard.DisplayFullBySlash, false),
    },
    {
        cellKey: 'isTemporary',
        headerLabel: '狀態',
        align: 'center',
        formatCell: (isTemporary, { isDeleted }) => {
            return isTemporary ? '暫存' : isDeleted ? '已刪除' : '已受理';
        },
    },
    {
        cellKey: 'suspendRange',
        headerLabel: '暫停期間',
        align: 'center',
    },
    {
        cellKey: 'note',
        headerLabel: '說明',
        maxWidth: 20,
        align: 'center',
        formatCell: (cellValue, rowData, { onMemoOnClick }) => {
            const text = `${rowData.suspendTypeText} ${cellValue}`;
            return (
                <Typography
                    component="span"
                    className="text-ellipsis cursor-pointer"
                    onClick={() => {
                        onMemoOnClick(text);
                    }}
                >
                    {text}
                </Typography>
            );
        },
    },
    moreColData,
];

const MembershipSuspensionHistoryTable = (props) => {
    const { className, sourceData = [] } = props;
    const { downloadDocMembershipSuspendPDFApi, deleteDocumentSuspensionsApi, deleteDocTemporarySuspendContractApi } = useDocMembershipSuspendApi();
    const { peopleType: peopleTypeProps, referenceID: referenceIDProps } = useParams();
    const setMessageDialog = useMessageDialog();
    const history = useHistory();
    const { openTarget, handleOpenDialog, handleCloseDialog, rowData } = useToggleDialog();
    const labelTitle = useRef(rowsLabel);

    // 查看
    const handleReviewOnClick = ({ contractID }) => {
        const path = pathRouterShit(DOCUMENTS_MEMBERSHIPSUSPENSION_PEOPLETYPE_REFERENCEID_READONLY_CONTRACT_ROUTER, {
            peopleType: peopleTypeProps,
            referenceID: referenceIDProps,
            contractID: contractID,
        });
        history.push(path);
    };

    // 編輯
    const handleEditOnClick = ({ contractID }) => {
        const path = pathRouterShit(DOCUMENTS_MEMBERSHIPSUSPENSION_PEOPLETYPE_REFERENCEID_CONTRACT_ROUTER, {
            peopleType: peopleTypeProps,
            referenceID: referenceIDProps,
            contractID: contractID,
        });
        history.push(path);
    };

    // 下載
    const handleDownloadPDFOnClick = (rowData) => {
        const { contractUrl } = rowData;
        doSuspendPDFApi({ contractUrl });
    };

    const handleMemoOnClick = (mesg) => {
        setMessageDialog({
            open: true,
            title: '說明',
            MsgComp: () => <Typography className="word-break-break-word">{mesg}</Typography>,
        });
    };

    // 刪除暫存暫停合約資料
    const doDeleteApi = async (rowData, params) => {
        const { isTemporary } = rowData;
        let resp;
        if (isTemporary) {
            resp = await deleteDocTemporarySuspendContractApi({
                type: peopleTypeProps,
                id: referenceIDProps,
            });
        } else if (isBoolean(isTemporary) && !isTemporary && params) {
            resp = await deleteDocumentSuspensionsApi(params);
        }
        return resp;
    };

    // 下載PDF API
    const doSuspendPDFApi = async (params) => {
        await downloadDocMembershipSuspendPDFApi(params);
    };

    return (
        <React.Fragment>
            <Tablenization cardProps={{ className: className }}>
                <Tablenization.TableRowHeader headerRow={labelTitle.current} />
                <Tablenization.TableRowBody
                    headerRow={labelTitle.current}
                    rowsData={sourceData}
                    onReviewEvent={handleReviewOnClick}
                    onEditEvent={handleEditOnClick}
                    onDownloadPDFEvent={handleDownloadPDFOnClick}
                    onDeleteOnEvent={(rowData) => {
                        const { isTemporary } = rowData;
                        if (isTemporary) {
                            handleOpenDialog(dialogTypeMap.temporaryDoc, { rowData });
                        } else {
                            handleOpenDialog(dialogTypeMap.deleteDoc, { rowData });
                        }
                    }}
                    onMemoOnClick={handleMemoOnClick}
                    moreLabelText={{ deleteLabelText: '銷假', eidtLabelText: '調整' }}
                />
            </Tablenization>
            <LeaveCancellationDialog
                open={openTarget === dialogTypeMap.deleteDoc}
                onClose={handleCloseDialog}
                sourceData={rowData}
                deleteDocApi={doDeleteApi}
                refresh={() => {
                    const path = pathRouterShit(DOCUMENTS_MEMBERSHIPSUSPENSION_PEOPLETYPE_REFERENCEID_HISTORY_ROUTER, {
                        peopleType: peopleTypeProps,
                        referenceID: referenceIDProps,
                    });
                    history.replace(path);
                }}
            />
            <ConfirmDeleteDocDialog
                title={'確認刪除暫存暫停申請書'}
                open={openTarget === dialogTypeMap.temporaryDoc}
                onClose={handleCloseDialog}
                data={rowData}
                deleteDocApi={doDeleteApi}
                refresh={() => {
                    const path = pathRouterShit(DOCUMENTS_MEMBERSHIPSUSPENSION_PEOPLETYPE_REFERENCEID_HISTORY_ROUTER, {
                        peopleType: peopleTypeProps,
                        referenceID: referenceIDProps,
                    });
                    history.replace(path);
                }}
            />
        </React.Fragment>
    );
};

export default MembershipSuspensionHistoryTable;
