import React, { useEffect, useRef, useState } from 'react';
import { isEmpty } from 'lodash';
import { useHistory } from 'react-router-dom';
import PromptDialog from '@icoach/components/dialog/PromptDialog';
import { Box, Button, Card, Typography } from '@common/components/';
import useMessageDialog from '@util/hook/useMessageDialog';
import { mergeDeep, organizeErrorMessages, refIsRequiredError } from '@util/utils';
import { parseDate } from '@util/moment';
import useShowPDF from '@util/hook/useShowPDF';
import useCyclePurchaseEffectApi from '@apis/useCyclePurchaseEffectApi';
import useMemberCyclePurchaseApi from '@apis/useMemberCyclePurchaseApi';
import { DOCUMENT_CYCLE_PURCHASE_ROUTER } from '@icoach/router/routerPath';
import { MEMBER_DETAIL_CYCLE_PURCHASE_URL } from '@icoach/router/MembersRouter';
import {
    PaymentSection,
    ProductSection,
    ReceiverSection,
    ShippingSection,
    SuspensionSection,
    TermSection,
    EditTopSection,
    QuitSection,
    ResuspensionSection,
    InvoiceSection,
    ConsumerSectionByRead,
} from '@icoach/documents/cyclePurchase/components';
import terms from '@icoach/documents/cyclePurchase/terms';
import { cycleOrderTypeMap, effectTypeMap } from '@icoach/documents/cyclePurchase/staticData';

const CyclePurchaseEffectPage = (props) => {
    const { memberID, cycleOrderID, effectID = 0 } = props.match.params;
    const history = useHistory();
    const setMessageDialog = useMessageDialog();
    const {
        getInitCyclePurchaseEffectOrderByCreateApi,
        getInitCyclePurchaseEffectOrderByUpdateApi,
        postCyclePurchaseEffectOrderApi,
        putCyclePurchaseEffectOrderApi,
        postCyclePurchaseEffectRangeApi,
    } = useCyclePurchaseEffectApi();
    const { getMemberCycleOrderFutureApi } = useMemberCyclePurchaseApi();
    const [data, setData] = useState({});
    const [errorModule, setErrorModule] = useState({}); // 錯誤回饋
    const { resource = {}, products, order: orderData = {} } = data;
    const { isVisibleNewCalciumCheckbox } = resource;
    const showPDF = useShowPDF();
    const { clubID, defaultData: membershipData, cycleOrderType } = orderData;
    const [showPrompt, setShowPrompt] = useState(true);
    const [shippingDayData, setShippingDayData] = useState({}); // 出貨日
    const [showNextShippingDate, setShowNextShippingDate] = useState(false);
    const [date, setDate] = useState({
        effectDate: parseDate(), // 生效日 預設今天
        nextShippingDate: '', // 預計出貨日
    });
    const independentOptions = [effectTypeMap.quit, effectTypeMap.suspension];
    const topRef = useRef();
    const productRef = useRef();
    const shippingRef = useRef();
    const receiverRef = useRef();
    const paymentRef = useRef();
    const invoiceRef = useRef();
    const suspensionRef = useRef();
    const quitRef = useRef();
    const termRef = useRef();
    const resuspensionRef = useRef();
    const effectTypeRefs = [resuspensionRef, quitRef, suspensionRef, productRef, paymentRef, invoiceRef, receiverRef, shippingRef];
    const apiSendingRef = useRef(false);

    const doInitCyclePurchaseEffectOrderApi = async (memberID, cycleOrderID, effectID) => {
        let resp;
        if (parseInt(effectID)) {
            // 更新
            resp = await getInitCyclePurchaseEffectOrderByUpdateApi(cycleOrderID, effectID);
        } else {
            // 新增
            resp = await getInitCyclePurchaseEffectOrderByCreateApi(memberID, cycleOrderID);
        }
        if (resp) {
            setData(resp);
        }
    };

    const doMemberCycleOrderFutureApi = async (memberID, cycleOrderID) => {
        let res = await getMemberCycleOrderFutureApi(memberID, cycleOrderID);
        if (res) {
            if (!isEmpty(res)) {
                setMessageDialog({
                    open: true,
                    title: '提醒',
                    msg: '該訂購單尚有未執行的變更單。',
                });
            }
        }
    };

    const doCyclePurchaseEffectOrderApi = async (params, effectID) => {
        let res;
        if (parseInt(effectID)) {
            // 更新
            res = await putCyclePurchaseEffectOrderApi(params, effectID);
        } else {
            // 新增
            res = await postCyclePurchaseEffectOrderApi(params);
        }
        if (res) {
            if (res.isError) {
                setErrorModule(organizeErrorMessages(res.result));
                apiSendingRef.current = false;
            } else {
                const { completedPath } = res;

                setErrorModule({});

                let routerPath = DOCUMENT_CYCLE_PURCHASE_ROUTER;
                if (effectID) {
                    routerPath = MEMBER_DETAIL_CYCLE_PURCHASE_URL(memberID);
                }
                // 開啟PDF
                showPDF({
                    open: true,
                    pdfUrl: completedPath,
                });
                setShowPrompt(false);
                window.setTimeout(() => {
                    // 轉跳訂單總覽
                    history.replace(routerPath);
                }, 5);
            }
        } else {
            setErrorModule({});
            apiSendingRef.current = false;
        }
    };

    const doCyclePurchaseEffectRangeApi = async (params) => {
        let resp = await postCyclePurchaseEffectRangeApi(params);
        if (resp) {
            setDate(resp);
        }
    };

    // 取得變更項目資料
    const getEffectTypeData = () => {
        return effectTypeRefs.map((item) => {
            return {
                // 參照物件
                ref: item,
                // 效果類型ID，若item.current存在且有getEffectTypeID方法則取得
                effectType: item.current && item.current.getEffectTypeID && item.current.getEffectTypeID(),
            };
        });
    };

    // 取得變更項目ID
    const getEffectType = () => {
        // 過濾並返回存在的效果類型ID
        return effectTypeRefs.map((item) => item.current && item.current.getEffectTypeID()).filter((item) => item);
    };

    // 是否含有單獨申請的項目
    const hasIndependentOptions = (effectData) => {
        return independentOptions.some((effectType) => {
            let index = effectData.findIndex((item) => item.effectType === effectType);
            return index !== -1;
        });
    };

    // 顯示提示單獨申請的訊息
    const showIndependentTip = () => {
        setMessageDialog({
            open: true,
            title: '提醒',
            msg: '您選取的變更業務含有只能單獨申請的項目，請您拆單申請。謝謝。',
        });
    };

    // 設置獨立申請選項的錯誤提示文字
    const setIndependentOptionsErrorHelperText = (ref, isError = false) => {
        let text = isError ? '此為獨立申請的項目，不可與其他項目一起申請。' : '';

        if (ref.current && ref.current.setHelperText) {
            ref.current.setHelperText(isError, text);
        }
    };

    const setShowTip = () => {
        let effectTypeData = getEffectTypeData();
        effectTypeData = effectTypeData.filter((item) => item.effectType);
        if (effectTypeData.length > 1 && hasIndependentOptions(effectTypeData)) showIndependentTip();
    };

    const getParams = () => {
        let result = {
            effectType: getEffectType(),
            clubID,
            memberID,
            cycleOrderID,
            shippingDay: orderData.shippingDay,
        };
        if (topRef.current && topRef.current.getResult) result = Object.assign(result, topRef.current.getResult());
        if (productRef.current && productRef.current.getResult) result = Object.assign(result, productRef.current.getResult());
        if (shippingRef.current && shippingRef.current.getResult) result = Object.assign(result, shippingRef.current.getResult());
        if (receiverRef.current && receiverRef.current.getResult) result = Object.assign(result, receiverRef.current.getResult());
        if (paymentRef.current && paymentRef.current.getResult) result = Object.assign(result, paymentRef.current.getResult());
        if (invoiceRef.current && invoiceRef.current.getResult) result = Object.assign(result, invoiceRef.current.getResult());
        if (suspensionRef.current && suspensionRef.current.getResult) result = Object.assign(result, suspensionRef.current.getResult());
        if (quitRef.current && quitRef.current.getResult) result = Object.assign(result, quitRef.current.getResult());
        if (termRef.current && termRef.current.getResult) result = Object.assign(result, termRef.current.getResult());
        if (resuspensionRef.current && resuspensionRef.current.getResult) result = Object.assign(result, resuspensionRef.current.getResult());
        return result;
    };

    const checkError = () => {
        const effectTypeData = getEffectTypeData();
        let isError = false;

        effectTypeData.forEach((item) => {
            let pickEffectType = effectTypeData.filter((item) => item.effectType);
            let isErrorState = pickEffectType.length > 1 && item.effectType && independentOptions.includes(item.effectType);
            if (isErrorState && !isError) isError = true;
            setIndependentOptionsErrorHelperText(item.ref, isErrorState);
        });

        if (!isError) {
            isError = refIsRequiredError(topRef, productRef, shippingRef, receiverRef, paymentRef, suspensionRef, quitRef, termRef, resuspensionRef);
        }

        return isError;
    };

    const handleSubmit = () => {
        if (apiSendingRef.current) return false;
        const isError = checkError();
        if (!isError) {
            const params = getParams();
            apiSendingRef.current = true;
            doCyclePurchaseEffectOrderApi(params, effectID);
        }
    };

    const handleChangeEffectType = () => {
        let effectTypeData = getEffectTypeData();
        let effectTypeLength = getEffectType().length;

        handleChangeEffectOrder();

        setShowNextShippingDate(effectTypeLength > 0 && !hasIndependentOptions(effectTypeData));
    };

    const handleChangeEffectOrder = (targetData) => {
        let params = getParams();
        const { employeeID, effectType } = params;

        if (!isEmpty(targetData)) {
            params = mergeDeep(params, targetData);
        }

        params.employeeID = employeeID || 0;

        if (effectType?.length > 0) {
            doCyclePurchaseEffectRangeApi(params);
        }
    };

    const handleChangeShippingDate = (e, n, v, targetData) => {
        setShippingDayData(targetData);
        handleChangeEffectOrder({ shippingDay: v });
    };

    const handleOrderInitialization = (memberID, effectID, cycleOrderID) => {
        if (!memberID || !cycleOrderID) return;

        if (effectID) {
            // 取得變更單資料
            doInitCyclePurchaseEffectOrderApi(memberID, cycleOrderID, effectID);
        }

        // 檢查是否有未執行的變更單
        doMemberCycleOrderFutureApi(memberID, cycleOrderID);
    };

    useEffect(
        () => {
            handleOrderInitialization(memberID, effectID, cycleOrderID);
        },
        // eslint-disable-next-line
        [memberID, effectID, cycleOrderID],
    );

    return (
        <Box className="container main-container-spacing cycle-purchase-page">
            <header className={'page-header'}>
                <Typography className={'page-header-title'} variant={'h3'}>
                    健康產品變更單
                </Typography>
            </header>
            <Card className={'order-info-card mb-4'}>
                <ConsumerSectionByRead data={orderData} />
            </Card>
            <Typography className={'font-weight-bold mb-1'} variant={'h5'}>
                請選擇要變動的項目
            </Typography>
            <Card className={'main-effect-group order-info-card mb-4'}>
                <ProductSection
                    ref={productRef}
                    title={'商品口味數量'}
                    sourceData={orderData}
                    options={{ products, isVisibleNewCalciumCheckbox }}
                    errorModule={errorModule}
                    setShowTip={setShowTip}
                    onChangeEffectType={handleChangeEffectType}
                />
                <ShippingSection
                    ref={shippingRef}
                    title={'出貨週期'}
                    effectDate={date.effectDate}
                    sourceData={orderData}
                    options={resource}
                    errorModule={errorModule}
                    setShowTip={setShowTip}
                    onChangeEffectType={handleChangeEffectType}
                    onChangeShipping={handleChangeShippingDate}
                />
                <ReceiverSection
                    ref={receiverRef}
                    title={'收件資訊'}
                    sourceData={orderData}
                    membershipData={membershipData}
                    errorModule={errorModule}
                    setShowTip={setShowTip}
                    onChangeEffectType={handleChangeEffectType}
                />
                <PaymentSection
                    ref={paymentRef}
                    title={'付款資訊'}
                    sourceData={orderData}
                    membershipData={membershipData}
                    errorModule={errorModule}
                    setShowTip={setShowTip}
                    onChangeEffectType={handleChangeEffectType}
                />
                <InvoiceSection
                    ref={invoiceRef}
                    title={'發票資訊'}
                    sourceData={orderData.invoice}
                    membershipData={membershipData}
                    options={resource}
                    errorModule={errorModule}
                    setShowTip={setShowTip}
                    onChangeEffectType={handleChangeEffectType}
                />
                {cycleOrderType && cycleOrderType === cycleOrderTypeMap.suspension && (
                    <ResuspensionSection
                        ref={resuspensionRef}
                        effectDate={date.effectDate}
                        shippingDayData={shippingDayData}
                        data={orderData}
                        resource={resource}
                        errorModule={errorModule}
                        setShowTip={setShowTip}
                        onChangeEffectType={handleChangeEffectType}
                        onChangeResuspendDate={(val) => handleChangeEffectOrder({ reSuspendDate: val })}
                    />
                )}
                <SuspensionSection
                    ref={suspensionRef}
                    effectDate={date.effectDate}
                    shippingDayData={shippingDayData}
                    data={{
                        effectID,
                        ...orderData,
                    }}
                    resource={resource}
                    errorModule={errorModule}
                    setShowTip={setShowTip}
                    // setEffectDate={setEffectDate}
                    onChangeEffectType={handleChangeEffectType}
                    onChangeSuspendDate={(target) => handleChangeEffectOrder(target)}
                />
                <QuitSection
                    ref={quitRef}
                    effectDate={date.effectDate}
                    shippingDayText={shippingDayData}
                    data={{
                        effectID,
                        ...orderData,
                    }}
                    resource={resource}
                    errorModule={errorModule}
                    setShowTip={setShowTip}
                    onChangeEffectType={handleChangeEffectType}
                />
            </Card>
            <TermSection className={'mb-4'} ref={termRef} data={terms} nextShippingDate={showNextShippingDate && date.nextShippingDate} />
            <Card className={'order-info-card mb-4'}>
                <EditTopSection
                    className={'mb-4'}
                    ref={topRef}
                    effectDate={date.effectDate}
                    resource={resource}
                    setShowTip={setShowTip}
                    errorModule={errorModule}
                    onChangeEffectDate={(val) => handleChangeEffectOrder({ effectDate: val })}
                />
            </Card>
            <Box className={'text-right'}>
                <Button type={'submit'} variant={'contained'} onClick={handleSubmit}>
                    確認送出
                </Button>
            </Box>
            <PromptDialog when={showPrompt} />
        </Box>
    );
};

export default CyclePurchaseEffectPage;
