import React, { useState, useEffect, useRef } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import _ from 'lodash';
import CustomerBasicInformationBox from '@icoach/nonMember/customer/CustomerBasicInformationBox';
import CustomerContactRecordBox from '@icoach/nonMember/customer/CustomerContactRecordBox';
import NonMemberContext from '@icoach/nonMember/components/NonMemberContext';
import { NON_MEMBER_CUSTOMER_OVERVIEW_ROUTER } from '@icoach/router/NonMemberRouter';
import useCustomerApi from '@apis/useCustomerApi';
import { Stack } from '@common/components/';
import { mergeContactAndSMSData } from '@util/utils';

const CustomerInformationPage = () => {
    const { getCustomerInfoApi } = useCustomerApi();
    const history = useHistory();
    const { id: customerID } = useParams();
    const [isMounted, setMounted] = useState(false);
    const [sourceData, setSourceData] = useState({});
    const { resource = {} } = sourceData;
    const refreshRef = useRef(null);
    const list = mergeContactAndSMSData(sourceData.contacts || [], sourceData.sms || []);

    const getCustomerInitDataApi = async (stateType, doRefresh) => {
        if (stateType === 'successDelete') {
            doRefresh();
        } else {
            const resp = await getCustomerInfoApi(customerID);
            if (resp) {
                setSourceData({ ...resp });
                setMounted(true);
            }
        }
    };

    const childContext = {
        refresh: refreshRef,
    };

    useEffect(() => {
        if (!_.isInteger(customerID * 1)) history.push(NON_MEMBER_CUSTOMER_OVERVIEW_ROUTER);
        else {
            refreshRef.current = getCustomerInitDataApi;
            getCustomerInitDataApi();
        }
        return () => setMounted(false);
        // eslint-disable-next-line
    }, []);

    return (
        <NonMemberContext.Provider value={childContext}>
            <Stack>
                <div className="nonmember-info-width-50 px-5 py-4">{isMounted && <CustomerBasicInformationBox sourceData={sourceData} />}</div>
                <div className="nonmember-info-width-50 px-5 py-4 nonmember-info-contact-cotainer">
                    {isMounted && <CustomerContactRecordBox sourceData={list} customerID={customerID} options={resource.coachOptions} />}
                </div>
            </Stack>
        </NonMemberContext.Provider>
    );
};

export default CustomerInformationPage;
