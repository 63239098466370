import React from 'react';
import { useAccountBookContext } from '@icoach/accountBook/components/AccountBookContext';
import { bulkDebitBillingType as bulkDebitBillingTypeMap } from '@icoach/accountBook/staticData';
import { Box, Tab, Tabs } from '@common/components/';

const BatchPaymentTabs = () => {
    const { paramData, setBulkDebitBillingType } = useAccountBookContext();
    const { bulkDebitBillingType } = paramData;
    const bulkDebitTabData = [
        {
            label: '扣款結果列表',
            value: bulkDebitBillingTypeMap.billing,
        },
        {
            label: '扣款名單',
            value: bulkDebitBillingTypeMap.currentOrders,
        },
        {
            label: '免扣款名單',
            value: bulkDebitBillingTypeMap.exemptedOrders,
        },
        {
            label: '未扣款名單',
            value: bulkDebitBillingTypeMap.overdueOrders,
        },
    ];

    const handleTabChange = (e, value) => setBulkDebitBillingType(value);

    return (
        <Box component={'header'} className={'page-header'}>
            <Tabs value={bulkDebitBillingType} onChange={handleTabChange}>
                {bulkDebitTabData.map((item) => (
                    <Tab key={item.value} className={'py-3'} label={item.label} value={item.value} />
                ))}
            </Tabs>
        </Box>
    );
};

export default BatchPaymentTabs;
