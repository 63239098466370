import React, { useImperativeHandle, useRef } from 'react';
import { memberSheet, moreSheet, cellPhoneSheet, contactSheet, rowLabelCheckboxSheet, SpecialConditionBox } from '@icoach/components/';
import { parseDate } from '@util/moment';
import { DateTimeStandard } from '../../../components/pickers/enums/DateTimeStandard';
import { refIsRequiredError } from '@util/utils';
import { Grid } from '@mui/material';
import { MenuItem, SelectField } from '@common/components/';

//********************** 續約名單 **********************//
export const FilterExtensionSearch = React.forwardRef((props, ref) => {
    const { resource = {}, paramsData } = props;
    const { renewRangeTypeOption = [] } = resource;
    const rangeTypeRef = useRef(null);

    useImperativeHandle(
        ref,
        () => {
            return {
                isError: () => refIsRequiredError(rangeTypeRef),
                getResult: () => {
                    let result = {};
                    if (rangeTypeRef.current && rangeTypeRef.current.getResult) {
                        Object.assign(result, { rangeType: rangeTypeRef.current.getResult() });
                    }
                    return result;
                },
            };
        },
        // eslint-disable-next-line
        []
    );

    return (
        <React.Fragment>
            <Grid item xs={2}>
                <SelectField ref={rangeTypeRef} defaultValue={paramsData.rangeType || '1'} label={'合約終止區間'} fullWidth>
                    {Array.isArray(renewRangeTypeOption) &&
                        renewRangeTypeOption
                            .filter((item) => item || !item.disabled)
                            .map((item) => (
                                <MenuItem key={item.value} value={item.value}>
                                    {item.text}
                                </MenuItem>
                            ))}
                </SelectField>
            </Grid>
        </React.Fragment>
    );
});

export const FilterExtensionLabel = [
    {
        ...rowLabelCheckboxSheet,
        cellKey: 'memberID',
    },
    { ...memberSheet, isFixed: true, isFixedLeft: true, isShowAvatarImage: false },
    { ...cellPhoneSheet },
    {
        cellKey: 'specialCondition',
        headerLabel: '特殊狀況',
        width: 18,
        formatCell: (cellValue, { memberID }) => {
            return <SpecialConditionBox sourceData={{ memberID, value: cellValue }} />;
        },
    },
    {
        cellKey: 'weeklyWorkoutQuantity',
        headerLabel: '本週WO',
        width: 9,
        align: 'center',
    },
    {
        cellKey: 'monthlyWorkoutFrequency',
        headerLabel: 'WO頻率',
        align: 'center',
    },
    {
        cellKey: 'membershipEndDate',
        headerLabel: '現行合約終止日',
        width: 17,
        align: 'center',
        formatCell: (value) => parseDate(value, DateTimeStandard.DisplayDateBySlash),
    },
    {
        cellKey: 'membershipContinueMonth',
        headerLabel: '會籍已持續時間',
        align: 'center',
        width: 17,
        formatCell: (value) => `${value} 個月`,
    },
    { ...contactSheet },
    { ...moreSheet },
];
