import React, { useEffect, useImperativeHandle, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { Table, TableBody, TableRow, TableCell, SelectField, MenuItem, TableHead, IconButton, Button, Stack, Typography } from '@common/components/';
import { ErrorCircle as ErrorCircleIcon } from '@common/SvgIcon/';
import { formatCurrencyFn } from '@util/utils';
import useMessageDialog from '@util/hook/useMessageDialog';
import clsx from 'clsx';

const makeCartItem = (productData = {}) => {
    const { id, productID, variantID, price, salePrice, ...other } = productData;
    const _price = price || salePrice;
    return {
        ...other,
        productID,
        variantID,
        salePrice: _price,
        id: id || uuid(),
        value: `${productID}-${variantID}`,
        quantity: 1,
        total: _price * 1,
    };
};

const CountController = ({ qty, setQty }) => {
    const setMessageDialog = useMessageDialog();

    const handlePlus = () => setQty(qty + 1);
    const handleMinus = () => {
        if (qty <= 1) {
            setMessageDialog({
                open: true,
                title: '提醒',
                msg: '購買數量不可以小於1。',
            });
        } else {
            setQty(qty - 1);
        }
    };

    return (
        <Stack className="count-controller" justifyContent="center" alignItems="center">
            <IconButton className="minus" onClick={handleMinus}>
                <span className="icon"></span>
            </IconButton>
            <div className="count">{qty}</div>
            <IconButton className="plus" onClick={handlePlus}>
                <span className="icon"></span>
            </IconButton>
        </Stack>
    );
};

const CartItem = React.forwardRef(({ data, menu, readOnly, deleteCartItem, updateCartItem }, ref) => {
    const { id, value, salePrice, quantity, total, productName } = data;

    const handleDeleteProduct = () => deleteCartItem(id);

    const handleChangeProduct = (event) => {
        const value = event.target.value;
        const [productID, variantID] = value.split('-');
        const productData = menu.find((item) => item.productID.toString() === productID && item.variantID.toString() === variantID);
        if (productData) {
            const newData = makeCartItem({ ...data, ...productData });
            updateCartItem(newData);
        }
    };

    const setQty = (qty) => {
        const newData = { id, quantity: qty, total: salePrice * qty };
        updateCartItem(newData);
    };

    return (
        <TableRow>
            <TableCell>
                {readOnly ? (
                    <Typography className="mb-0">{productName}</Typography>
                ) : (
                    <SelectField key={value} defaultValue={value} onChange={handleChangeProduct} fullWidth required>
                        {menu.map((item) => (
                            <MenuItem
                                key={`${item.productID}-${item.variantID}`}
                                value={`${item.productID}-${item.variantID}`}
                                disabled={item.isLimited && item.stock === 0}
                            >
                                {item.productName}
                            </MenuItem>
                        ))}
                    </SelectField>
                )}
            </TableCell>
            <TableCell className="text-right" style={{ width: '125px' }}>
                {formatCurrencyFn(salePrice)}
            </TableCell>
            <TableCell className="text-center" style={{ width: '70px' }}>
                X
            </TableCell>
            <TableCell className="text-center">{readOnly ? quantity : <CountController qty={quantity} setQty={setQty} />}</TableCell>
            <TableCell className="text-right" style={{ width: '125px' }}>
                {formatCurrencyFn(total)}
            </TableCell>
            <TableCell style={{ width: '70px' }}>
                {!readOnly && (
                    <IconButton onClick={handleDeleteProduct}>
                        <ErrorCircleIcon />
                    </IconButton>
                )}
            </TableCell>
        </TableRow>
    );
});

const CartHead = () => (
    <TableHead>
        <TableRow>
            <TableCell>商品</TableCell>
            <TableCell className="text-right">單價</TableCell>
            <TableCell className="text-center"></TableCell>
            <TableCell className="text-center">盒數</TableCell>
            <TableCell className="text-right">總計</TableCell>
            <TableCell></TableCell>
        </TableRow>
    </TableHead>
);

const CartTable = React.forwardRef(({ className, data = [], resource = [], readOnly = false, onUpdateProduct }, ref) => {
    const [cartItems, setCartItems] = useState([]);
    const [total, setTotal] = useState(0);

    const getProductMenu = (id) => {
        if (readOnly) return [];
        const selected = cartItems.filter((item) => (id ? item.id !== id : item)).map((item) => `${item.productID}-${item.variantID}`);
        return resource.filter((item) => !selected.includes(`${item.productID}-${item.variantID}`) && !(item.isLimited && item.stock === 0));
    };

    const addCartItem = () => {
        const currentMenu = getProductMenu();
        if (cartItems.length < resource.length) {
            setCartItems([...cartItems, makeCartItem(currentMenu[0])]);
        }
    };

    const updateCartItem = (data) => {
        setCartItems(cartItems.map((item) => (item.id === data.id ? { ...item, ...data } : item)));
    };

    const deleteCartItem = (id) => {
        setCartItems(cartItems.filter((item) => item.id !== id));
    };

    useEffect(
        () => {
            setTotal(cartItems.reduce((sum, item) => sum + item.total, 0));
            if (typeof onUpdateProduct === 'function') onUpdateProduct(cartItems);
        },
        // eslint-disable-next-line
        [cartItems],
    );

    useEffect(
        () => {
            if (data.length) {
                setCartItems(
                    data.map((item) => {
                        const { productID, variantID, salePrice, price, quantity } = item;
                        const _price = price || salePrice;
                        return { id: uuid(), ...item, value: `${productID}-${variantID}`, total: _price * quantity };
                    }),
                );
            }
        },
        // eslint-disable-next-line
        [data],
    );

    useImperativeHandle(
        ref,
        () => ({
            getResult: () => cartItems.map(({ id, total, ...rest }) => rest),
        }),
        // eslint-disable-next-line
        [cartItems],
    );

    return (
        <Table className={clsx('cycle-purchase-cart', className)}>
            <CartHead />
            <TableBody>
                {cartItems.map((item) => (
                    <CartItem
                        key={item.id}
                        data={item}
                        menu={getProductMenu(item.id)}
                        readOnly={readOnly}
                        deleteCartItem={deleteCartItem}
                        updateCartItem={updateCartItem}
                    />
                ))}
                <TableRow>
                    <TableCell colSpan={3}>
                        {!readOnly && (
                            <Button variant="outlined" onClick={addCartItem}>
                                新增商品
                            </Button>
                        )}
                    </TableCell>
                    <TableCell className="text-right" colSpan={3}>
                        <Typography className="text-primary" variant="h5">
                            <strong>合計：{formatCurrencyFn(total, { symbol: '$' })}</strong>
                        </Typography>
                        <Typography className="mb-0" variant="body2">
                            售價已包含5%加值營業稅及運費(僅配送台灣本島)
                        </Typography>
                    </TableCell>
                </TableRow>
            </TableBody>
        </Table>
    );
});

export default CartTable;
