import { useState, useLayoutEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useSessionStorage } from '@util/hook/useStorage';

const hp = (key = null, py = null) => {
    return {
        key: key,
        scrollerY: py,
    };
};

const useWindowScrollPositions = () => {
    const location = useLocation();
    const [keyState] = useState(location.key);
    const { value, setValue } = useSessionStorage('historyPage', hp());

    useLayoutEffect(
        () => {
            if (value.key === keyState && String(value.scrollerY) !== '0') {
                window.scrollTo({
                    top: value.scrollerY,
                    behavior: 'smooth',
                });
            }
            return () => {
                const scrollY = document.scrollingElement.scrollTop;
                setValue(hp(location.key, scrollY));
            };
        },
        // eslint-disable-next-line
        [location]
    );
};

export default useWindowScrollPositions;
