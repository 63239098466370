import React, { useState, useImperativeHandle, useRef, useEffect } from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import parseISO from 'date-fns/parseISO';
import OutlinedInput from '../outlinedInput/OutlinedInput';
import FormLabel from '../formLabel/FormLabel';
import FormControl from '../formControl/FormControl';
import FormHelperText from '../formHelperText/FormHelperText';
import Stack from '../stack/Stack';
import { isEmpty } from '@util/utils';
import { isAfter, isBefore, isValidDate, parseDate } from '@util/moment';
import useForkRef from '@util/hook/useForkRef';
import useControlled from '@util/hook/useControlled';
import { NavigateNext as NavigateNextIcon, NavigateBefore as NavigateBeforeIcon, AngleDown as AngleDownIcon } from '@common/SvgIcon/';

const DATE_EMPTY = '此選項為必填，不得為空值';
const DATE_DORM_ERROR = '日期格式錯誤';

// 設定的日期 是否大於或等於 minDate
export const validateMinDate = (dateValue, minDate, format) => {
    return isAfter(dateValue, minDate) || parseDate(minDate, format) === parseDate(dateValue, format);
};

// 設定的日期 是否小於或等於 maxDate
export const validateMaxDate = (dateValue, maxDate, format) => {
    return isBefore(dateValue, maxDate) || parseDate(maxDate, format) === parseDate(dateValue, format);
};

const DateField = React.forwardRef((props, ref) => {
    const {
        isError: propsError = false,
        helperText = DATE_EMPTY,
        label,
        disabled = false,
        fullWidth = false,
        required = false,
        onChange: onChangeProps,
        className,
        value: propsValue,
        defaultValue: propsDefaultValue,
        name,
        views = ['year', 'month', 'day'],
        format: inputFormat = 'YYYY/MM/DD',
        openTo = 'day',
        lightColor = false,
        direction: directionProps = 'column',
        readOnly: readOnlyProps = false,
        maxDate: maxDateProp,
        minDate: minDateProp,
        mask = '____/__/__',
        placeholder: placeholderPrpos = '請選擇日期',
        disableMaskedInput = false,
        FormLabelTip = '',
        ...other
    } = props;

    const inputValueRef = useRef(null);
    const [isError, setError] = useState(propsError);
    const [helperTextState, setHelperText] = useState(helperText);
    // 進入的值必須親自用 parseDate 轉過 變成日期格式
    const [dateValue, setDateValue] = useControlled({
        controlled: propsValue,
        default: propsDefaultValue || null,
    });
    const dateValRef = useRef(dateValue);
    const isDateFieldEmpty = useRef(true);
    let dateFormatsHeaderLabel = 'YYYY年 MMMM';

    if (openTo === 'year') {
        dateFormatsHeaderLabel = 'YYYY年';
    } else if (openTo === 'month') {
        dateFormatsHeaderLabel = 'MMMM';
    }

    useImperativeHandle(
        ref,
        () => {
            return {
                isError: () => {
                    let source = true;
                    let errorMesg = DATE_DORM_ERROR;
                    if (isValidDate(dateValue)) {
                        source = false;
                        errorMesg = '';
                        if (minDateProp && isValidDate(minDateProp) && !validateMinDate(dateValue, minDateProp, inputFormat)) {
                            source = true;
                            errorMesg = `不得小於${parseDate(minDateProp, inputFormat)}`;
                        } else if (maxDateProp && isValidDate(maxDateProp) && !validateMaxDate(dateValue, maxDateProp, inputFormat)) {
                            source = true;
                            errorMesg = `不得大於${parseDate(maxDateProp, inputFormat)}`;
                        }
                    } else if (required && isDateFieldEmpty.current) {
                        errorMesg = DATE_EMPTY;
                    } else if (isDateFieldEmpty.current) {
                        source = false;
                    }
                    setError(source);
                    setHelperText(errorMesg);
                    return source;
                },
                getResult: (formatDate = '') => {
                    // 預設回傳 YYYY-MM-DDT00:00:00
                    return formatDate ? parseDate(dateValRef.current, formatDate) : parseDate(dateValRef.current);
                },
                resetValue: (value) => {
                    if (isValidDate(value)) {
                        setDateValue(value);
                        dateValRef.current = parseDate(value);
                    }
                },
            };
        },
        // eslint-disable-next-line
        [dateValue, isDateFieldEmpty],
    );

    const handleOnChange = (value) => {
        const val = parseDate(value);
        const node = inputValueRef.current;
        onChangeProps && onChangeProps(node, val);
    };

    const formlabelProperties = {
        lightColor: lightColor,
        inline: directionProps === 'row',
        FormLabelProps: {
            labelTip: FormLabelTip,
        },
    };

    useEffect(
        () => {
            setError(propsError);
        },
        // eslint-disable-next-line
        [propsError],
    );

    return (
        <FormControl
            disabled={disabled}
            fullWidth={fullWidth}
            required={required}
            className={className}
            error={isError}
            direction={directionProps}
            readOnly={readOnlyProps}
            {...other}
        >
            {label && <FormLabel {...formlabelProperties}>{label}</FormLabel>}
            <Stack direction="column" style={{ flexGrow: 1 }}>
                <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    dateFormats={{ monthAndYear: dateFormatsHeaderLabel }}
                    adapterLocale={'zh-tw'}
                    localeText={{
                        cancelButtonLabel: '取消',
                        clearButtonLabel: '清空',
                        okButtonLabel: '確定',
                        todayButtonLabel: '今日',
                    }}
                >
                    <DatePicker
                        componentsProps={{ actionBar: { className: 'mobile-date-picker-action' } }}
                        components={{
                            SwitchViewIcon: AngleDownIcon,
                            LeftArrowIcon: NavigateBeforeIcon,
                            RightArrowIcon: NavigateNextIcon,
                        }}
                        disableMaskedInput={disableMaskedInput}
                        showToolbar={false}
                        disabled={disabled}
                        readOnly={readOnlyProps}
                        mask={mask}
                        inputFormat={inputFormat}
                        views={views}
                        openTo={openTo}
                        value={dateValue}
                        maxDate={maxDateProp ? parseISO(maxDateProp) : null}
                        minDate={minDateProp ? parseISO(minDateProp) : null}
                        onClose={() => !!dateValRef.current && handleOnChange(dateValRef.current)}
                        onChange={(newValue, keyboardInputValue) => {
                            const formatDate = newValue ? newValue['$d'] : new Date('');
                            keyboardInputValue && handleOnChange(formatDate);
                            setDateValue(formatDate);
                            dateValRef.current = formatDate;
                            isDateFieldEmpty.current = isEmpty(newValue) && !keyboardInputValue;
                        }}
                        renderInput={({ inputProps, inputRef, ...renderInputOther }) => {
                            const newInputProps = {
                                inputProps: {
                                    ...inputProps,
                                    placeholder: placeholderPrpos,
                                },
                            };
                            return (
                                <OutlinedInput
                                    inputRef={useForkRef(inputRef, inputValueRef)}
                                    {...newInputProps}
                                    {...renderInputOther}
                                    name={name}
                                    error={isError}
                                />
                            );
                        }}
                    />
                </LocalizationProvider>
                {isError && <FormHelperText>{helperTextState}</FormHelperText>}
            </Stack>
        </FormControl>
    );
});

export default DateField;
