import React, { useRef } from 'react';
import { refIsRequiredError } from '../../../util/utils';
import useClubRemittanceReturnApi from '../../../apis/useClubRemittanceReturnApi';
import { AllowanceAbandonDialog } from './OrderAllowanceAbandonDialog';

const RemitAllowanceAbandonDialog = (props) => {
    const { className, open, allowanceID, items, onClose, refresh } = props;
    const { postRemittanceAbandonAllowanceConfirmApi } = useClubRemittanceReturnApi();
    const contentRef = useRef();

    const doRemittanceAbandonAllowanceConfirmApi = async (params) => {
        let res = await postRemittanceAbandonAllowanceConfirmApi(params);
        if (res) {
            refresh && refresh();
            onClose();
        }
    };

    const getParams = () => {
        let result = { allowanceID };
        if (contentRef.current && contentRef.current.getResult) {
            result = Object.assign(result, contentRef.current.getResult());
        }
        return result;
    };

    const handleSubmit = () => {
        let isError = refIsRequiredError(contentRef);
        if (!isError) {
            let params = getParams();
            doRemittanceAbandonAllowanceConfirmApi(params);
        }
    };

    return (
        <AllowanceAbandonDialog
            ref={contentRef}
            className={className}
            open={open}
            data={items}
            onClose={onClose}
            onSubmit={handleSubmit}
        />
    );
};

export default RemitAllowanceAbandonDialog;
