import React, { useRef, useState, useLayoutEffect, useImperativeHandle } from 'react';
import { useParams } from 'react-router-dom';
import { Grid } from '@mui/material';
import { v4 as uuid } from 'uuid';
import MembershipTransferInDiscountDateItem from './MembershipTransferInDiscountDateItem';
import MembershipTransferInSuspendDateItem from './MembershipTransferInSuspendDateItem';
import MembershipTransferInContactDateItem from './MembershipTransferInContactDateItem';
import { FieldGroup } from '@icoach/documents/components/Layout';
import { TextField, DateIntervalField, DateField, Typography, RadioField, SelectField, MenuItem, Button, Stack } from '@common/components/';
import { parseDate, getFirstOfMonth } from '@util/moment';
import { getRadioFieldResult } from '@util/utils';
import useDocMembershipTransferInApi from '@apis/useDocMembershipTransferInApi';
import { checkIsManualTransferIn } from '@icoach/documents/tools';

// 變更會籍
const MembershipTransferInModifyMembership = React.forwardRef((props, ref) => {
    const { temporarySourceDataRef, options, isReadOnly = false } = props;
    const { postTransferInRangeApi } = useDocMembershipTransferInApi();
    const [errorMessage, setErrorMessage] = useState('');
    const { peopleType } = useParams();
    const { coachOptions, membershipMemberTypeOptions, membershipTermOptions, paymentTypeOptions, giftOptions, suspendOptions } = options;
    const {
        employeeID,
        applyDate,
        memberType,
        paymentType,
        membershipTerm,
        contractStartDate,
        contractEndDate,
        giftItems,
        suspendItems,
        memberShipStartDate,
        memberShipEndDate,
        joinFee,
        monthlyFee,
        notes,
        isManual = false,
        nextMembershipStartDate,
        nextMembershipEndDate,
    } = temporarySourceDataRef.current;

    const [refreshKey, setRefreshKey] = useState(null);
    const employeeIDRef = useRef(null);
    const applyDateRef = useRef(null);
    const memberTypeRef = useRef(null);
    const paymentTypeRef = useRef(null);
    const membershipTermRef = useRef(null);
    const contractDurationRef = useRef(null);
    const giftItemsRef = useRef(null);
    const suspendItemsRef = useRef(null);
    const memberShipDateRef = useRef(null);
    const nextMembershipDateRef = useRef(null);
    const joinFeeRef = useRef(null);
    const monthlyFeeRef = useRef(null);
    const notesRef = useRef(null);

    const setParams = () => {
        if (isReadOnly) return false;
        const memTypeID = getRadioFieldResult(memberTypeRef.current.getResult(), 'value', 0);
        const petTypeID = getRadioFieldResult(paymentTypeRef.current.getResult(), 'value', 0);
        const termTypeID = getRadioFieldResult(membershipTermRef.current.getResult(), 'value', 0);
        const { startDate: s1, endDate: e1 } = contractDurationRef.current.getResult();
        const { startDate: s2, endDate: e2 } = memberShipDateRef.current.getResult();
        const { startDate: s3, endDate: e3 } = nextMembershipDateRef.current?.getResult() ?? {};
        temporarySourceDataRef.current.employeeID = employeeIDRef.current.getResult();
        temporarySourceDataRef.current.applyDate = applyDateRef.current.getResult();
        temporarySourceDataRef.current.memberType = memTypeID;
        temporarySourceDataRef.current.paymentType = petTypeID;
        temporarySourceDataRef.current.membershipTerm = termTypeID;
        temporarySourceDataRef.current.contractStartDate = s1 || null;
        temporarySourceDataRef.current.contractEndDate = e1 || null;
        temporarySourceDataRef.current.memberShipStartDate = s2 || null;
        temporarySourceDataRef.current.memberShipEndDate = e2 || null;
        temporarySourceDataRef.current.nextMembershipStartDate = s3 || null;
        temporarySourceDataRef.current.nextMembershipEndDate = e3 || null;
        temporarySourceDataRef.current.joinFee = joinFeeRef.current.getResult() * 1;
        temporarySourceDataRef.current.monthlyFee = monthlyFeeRef.current.getResult() * 1;
        temporarySourceDataRef.current.notes = notesRef.current.getResult();
        temporarySourceDataRef.current.giftItems = giftItemsRef.current.getResult();
        temporarySourceDataRef.current.suspendItems = suspendItemsRef.current.getResult();
        // 主動幫他換成1號
        temporarySourceDataRef.current.debitEndDate = getFirstOfMonth(e1);
    };

    // 手動移入時同步按鈕
    const handleSyncOriginalClick = () => {
        temporarySourceDataRef.current.memberType = temporarySourceDataRef.current.originalMemberType;
        temporarySourceDataRef.current.paymentType = temporarySourceDataRef.current.originalPaymentType;
        temporarySourceDataRef.current.membershipTerm = temporarySourceDataRef.current.originalMembershipTerm;
        temporarySourceDataRef.current.contractStartDate = temporarySourceDataRef.current.originalContractStartDate;
        temporarySourceDataRef.current.contractEndDate = temporarySourceDataRef.current.originalContractEndDate;
        temporarySourceDataRef.current.giftItems = temporarySourceDataRef.current.originalGiftItems;
        temporarySourceDataRef.current.suspendItems = temporarySourceDataRef.current.originalSuspendItems;
        temporarySourceDataRef.current.memberShipStartDate = temporarySourceDataRef.current.originalMemberShipStartDate;
        temporarySourceDataRef.current.memberShipEndDate = temporarySourceDataRef.current.originalMemberShipEndDate;
        temporarySourceDataRef.current.nextMembershipStartDate = temporarySourceDataRef.current.originalNextMembershipStartDate;
        temporarySourceDataRef.current.nextMembershipEndDate = temporarySourceDataRef.current.originalNextMembershipEndDate;
        temporarySourceDataRef.current.joinFee = temporarySourceDataRef.current.originalJoinFee;
        temporarySourceDataRef.current.monthlyFee = temporarySourceDataRef.current.originalMonthlyFee;
        setRefreshKey(uuid());
    };

    // 計算
    const handleMembershipDateCalculateClick = () => {
        setParams();
        const params = {
            memberType: temporarySourceDataRef.current.originalMemberType,
            membershipTerm: temporarySourceDataRef.current.originalMembershipTerm,
            startDate: temporarySourceDataRef.current.contractStartDate,
            endDate: temporarySourceDataRef.current.contractEndDate,
            nextStartDate: temporarySourceDataRef.current.nextMembershipStartDate,
            nextEndDate: temporarySourceDataRef.current.nextMembershipEndDate,
            items: [...(temporarySourceDataRef.current.giftItems || []), ...(temporarySourceDataRef.current.suspendItems || [])],
        };
        doMembershipDateCalculate(params);
    };

    const doMembershipDateCalculate = async (params) => {
        const resp = await postTransferInRangeApi(params);
        if (resp) {
            memberShipDateRef.current.setDate(resp.startDate, resp.endDate);

            if (nextMembershipStartDate && nextMembershipEndDate) nextMembershipDateRef.current.setDate(resp.nextStartDate, resp.nextEndDate);

            setErrorMessage(resp.errorMessage);
        }
    };

    useImperativeHandle(
        ref,
        () => ({
            getResult: () => setParams(),
        }),
        // eslint-disable-next-line
        []
    );

    useLayoutEffect(
        () => {
            return () => setParams();
        },
        // eslint-disable-next-line
        []
    );

    return (
        <React.Fragment key={refreshKey}>
            {checkIsManualTransferIn(peopleType, isManual) && (
                <FieldGroup label="">
                    <Button onClick={handleSyncOriginalClick} variant="contained" className="mt-2 ml-4">
                        同步原會籍資料
                    </Button>
                </FieldGroup>
            )}
            <FieldGroup label={'合約資訊'}>
                <Grid item xs={4}>
                    <SelectField ref={employeeIDRef} label="經辦教練" defaultValue={employeeID || ''} displayEmpty required readOnly={isReadOnly} fullWidth>
                        <MenuItem disabled value="">
                            <em>請選擇</em>
                        </MenuItem>
                        {coachOptions.map(({ text, value, disabled }) => (
                            <MenuItem key={value} value={value} disabled={disabled}>
                                {text}
                            </MenuItem>
                        ))}
                    </SelectField>
                </Grid>
                <Grid item xs={4}>
                    <DateField
                        ref={applyDateRef}
                        label="申請日期"
                        defaultValue={parseDate(applyDate)}
                        FormLabelTip={'意指移籍生效日'}
                        readOnly={isReadOnly}
                        required
                        fullWidth
                    />
                </Grid>
            </FieldGroup>
            <React.Fragment>
                <FieldGroup label={'方案資訊'}>
                    <Grid xs={12} item>
                        <RadioField row ref={memberTypeRef} label="會員類型" defaultValue={memberType} required readOnly={isReadOnly}>
                            {membershipMemberTypeOptions.map(({ text, value }) => (
                                <RadioField.Radio key={value} value={value} label={text} />
                            ))}
                        </RadioField>
                    </Grid>
                    <Grid xs={12} item>
                        <RadioField row ref={paymentTypeRef} label="付款方式" defaultValue={paymentType} required readOnly={isReadOnly}>
                            {paymentTypeOptions.map(({ text, value }) => (
                                <RadioField.Radio key={value} value={value} label={text} />
                            ))}
                        </RadioField>
                    </Grid>
                    <Grid xs={12} item>
                        <RadioField row ref={membershipTermRef} label="合約長度" defaultValue={membershipTerm} required readOnly={isReadOnly}>
                            {membershipTermOptions.map(({ text, value }) => (
                                <RadioField.Radio key={value} value={value} label={text} />
                            ))}
                        </RadioField>
                    </Grid>
                    <Grid xs={12} item>
                        <DateIntervalField
                            ref={contractDurationRef}
                            label="合約期間"
                            startDateProps={{ defaultValue: contractStartDate }}
                            endDateProps={{ defaultValue: contractEndDate }}
                            readOnly={isReadOnly}
                            fullWidth
                        />
                    </Grid>
                </FieldGroup>
                <FieldGroup label={'贈送期間'}>
                    <Grid xs={12} item>
                        <MembershipTransferInDiscountDateItem sourceData={giftItems} ref={giftItemsRef} options={giftOptions} isReadOnly={isReadOnly} />
                    </Grid>
                </FieldGroup>
                <FieldGroup label={'暫停期間'}>
                    <Grid xs={12} item>
                        <MembershipTransferInSuspendDateItem sourceData={suspendItems} ref={suspendItemsRef} options={suspendOptions} isReadOnly={isReadOnly} />
                    </Grid>
                </FieldGroup>
                <FieldGroup label={'會籍期間'}>
                    {!isReadOnly && (
                        <Grid xs={12} item>
                            <Stack alignItems={'center'} spacing={2}>
                                <Button onClick={handleMembershipDateCalculateClick} variant="contained">
                                    計算
                                </Button>
                                <Typography className={'text-error mt-1'} compoment="div">
                                    如有新增期間贈送或暫停期間，請再按一次計算
                                </Typography>
                            </Stack>
                        </Grid>
                    )}
                    <Grid xs={10} item>
                        <MembershipTransferInContactDateItem
                            ref={memberShipDateRef}
                            startDate={memberShipStartDate}
                            endDate={memberShipEndDate}
                            errorMessage={errorMessage}
                        />
                    </Grid>
                </FieldGroup>
                {nextMembershipStartDate && nextMembershipEndDate && (
                    <FieldGroup label={'續約會籍期間'}>
                        <Grid xs={10} item>
                            <MembershipTransferInContactDateItem
                                ref={nextMembershipDateRef}
                                startDate={nextMembershipStartDate}
                                endDate={nextMembershipEndDate}
                            />
                        </Grid>
                    </FieldGroup>
                )}
                <FieldGroup label={'付款資訊'}>
                    <Grid xs={4} item>
                        <TextField
                            ref={joinFeeRef}
                            maskType={'MONEY'}
                            label="入會費"
                            defaultValue={joinFee}
                            inputProps={{ min: 0, placeholder: 0 }}
                            readOnly={isReadOnly}
                            fullWidth
                        />
                    </Grid>
                    <Grid xs={4} item>
                        <TextField
                            ref={monthlyFeeRef}
                            maskType={'MONEY'}
                            label="月費"
                            defaultValue={monthlyFee}
                            inputProps={{ min: 0, placeholder: 0 }}
                            readOnly={isReadOnly}
                            fullWidth
                        />
                    </Grid>
                </FieldGroup>
                <FieldGroup label={'其他'}>
                    <Grid xs={12} item>
                        <TextField ref={notesRef} label="備註" defaultValue={notes} rows={3} readOnly={isReadOnly} multiline fullWidth />
                    </Grid>
                </FieldGroup>
            </React.Fragment>
        </React.Fragment>
    );
});

export default MembershipTransferInModifyMembership;
