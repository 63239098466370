import React from 'react';
import { memberSheet, moreSheet, cellPhoneSheet, contactSheet, rowLabelCheckboxSheet, SpecialConditionBox } from '@icoach/components/';
import { formatCurrencyFn } from '@util/utils';
import { Typography } from '@common/components/';
import { getOptionData } from '@icoach/members/staticData';

// 欠費名單
export const FilterDebtLabel = [
    {
        ...rowLabelCheckboxSheet,
        cellKey: 'memberID',
    },
    { ...memberSheet, isFixed: true, isFixedLeft: true, isShowAvatarImage: false },
    { ...cellPhoneSheet },
    {
        cellKey: 'specialCondition',
        headerLabel: '特殊狀況',
        width: 18,
        formatCell: (cellValue, { memberID }) => {
            return <SpecialConditionBox sourceData={{ memberID, value: cellValue }} />;
        },
    },
    {
        cellKey: 'membershipStatusText',
        headerLabel: '會籍狀態',
        align: 'center',
    },
    {
        cellKey: 'overdraftTotalPrice',
        headerLabel: '累積欠款',
        align: 'right',
        formatCell: (value, { memberID }, { onOrderDialogClick }) => {
            return (
                <Typography className="cursor-pointer" onClick={() => onOrderDialogClick(memberID)}>
                    {formatCurrencyFn(value)}
                </Typography>
            );
        },
    },
    { ...contactSheet },
    { ...moreSheet },
];
export const filterDebtSortOption = [
    getOptionData('會員狀態遞增', '1'),
    getOptionData('會員狀態遞減', '2'),
    getOptionData('累積欠款遞增', '3'),
    getOptionData('累積欠款遞減', '4'),
];
