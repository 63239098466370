import React from 'react';
import Tablelvl2Context from './Tablelvl2Context';
import clsx from 'clsx';

const staticClass = `table-row`;

const TableRow = React.forwardRef((props, ref) => {
    const { className, hover = false, selected = false, ...other } = props;
    const tablelvl2 = React.useContext(Tablelvl2Context);
    let classNames = staticClass;

    if (selected) classNames = clsx(classNames, `${staticClass}-selected`);
    if (hover && tablelvl2 && tablelvl2.variant === 'body') classNames = clsx(classNames, `${staticClass}-hover`);

    const defaultProperty = {
        className: clsx(classNames, className),
        ref,
        ...other,
    };

    return <tr {...defaultProperty} />;
});

export default TableRow;
