import Mock from 'mockjs';
import { successApi } from './respondData';

export const withMockCustomerContactRecordApiProvider = () => {
    Mock.setup({ timeout: '200-800' });

    Mock.mock(/^\/api\/customers\/contact-records\/options$/, 'get', () => {
        let sourceData = { ...successApi };
        sourceData.result = CONTACTRECORDSOPTIONS;
        return sourceData;
    });

    Mock.mock(/^\/api\/customers\/[0-9]+\/contact-records$/, 'post', () => {
        let sourceData = { ...successApi };
        sourceData.result = true;
        return sourceData;
    });
};

export const CONTACTRECORDSOPTIONS = {
    contactResultType: [
        {
            text: '已確認',
            value: '1',
            disabled: false,
        },
        {
            text: '已打未接',
            value: '2',
            disabled: false,
        },
        {
            text: '電話錯誤',
            value: '3',
            disabled: false,
        },
    ],
    contactReasonType: [
        {
            text: '工作忙碌',
            value: '1',
            disabled: false,
        },
        {
            text: '身體不適',
            value: '2',
            disabled: false,
        },
        {
            text: '照顧家人',
            value: '3',
            disabled: false,
        },
        {
            text: '出國出差',
            value: '4',
            disabled: false,
        },
        {
            text: '其他',
            value: '5',
            disabled: false,
        },
    ],
    employee: [
        {
            text: '林宗敬(小C)',
            value: '11',
            disabled: false,
        },
        {
            text: 'Allen(小A)',
            value: '12',
            disabled: false,
        },
        {
            text: 'Kevin(小K)',
            value: '13',
            disabled: false,
        },
        {
            text: 'Sabrina(小K)',
            value: '14',
            disabled: false,
        },
    ],
};
