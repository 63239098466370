import React from 'react';
import clsx from 'clsx';
import { Card, Divider, Stack, Typography } from '@common/components/';
import { parseDate } from '@util/moment';

const ContactSMSRecordCard = (props) => {
    const { sendStatusText, sendDate, employeeName, content, isUseTemplate, smsTemplateName } = props;
    const sendDateText = `發送時間：${parseDate(sendDate, 'YYYY-MM-DD HH:mm:ss')}；`;
    const templateText = isUseTemplate ? `公版標題：${smsTemplateName}` : '未使用公版';
    const title = `${sendDateText}${templateText}`;

    return (
        <Card isOverflowHidden className={clsx('contact-record-card')}>
            <Card.CardContent>
                <Stack justifyContent="space-between" className="pt-2 px-2">
                    <span className="contact-record-card-markbook">
                        <Typography variant="h5" className="contact-record-card-date">
                            {parseDate(sendDate)}
                        </Typography>
                    </span>
                </Stack>
                <Stack className="px-3 py-3" justifyContent="space-between">
                    <div className="overflow-hidden info-item">
                        <span className="contact-record-card-label">狀態</span>
                        <span className="contact-record-card-label-text">{sendStatusText}</span>
                    </div>
                    <div className="overflow-hidden info-item text-right">
                        <span className="contact-record-card-label">擔當</span>
                        <span className="contact-record-card-label-text">{employeeName}</span>
                    </div>
                </Stack>
            </Card.CardContent>
            <Divider />
            <Card.CardContent>
                <div className="px-3">
                    <Typography variant="h6" className="title py-2">
                        {title}
                    </Typography>
                    <Typography className="content pb-4">{content}</Typography>
                </div>
            </Card.CardContent>
        </Card>
    );
};

export default ContactSMSRecordCard;
