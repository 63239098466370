import React from 'react';
import SvgIcon from '../SvgIcon';
import useForkRef from '@util/hook/useForkRef';

const CalendarDays = React.forwardRef((props, ref) => {
    const { className, ...others } = props;
    const handleRef = React.useRef(null);
    const svgRef = useForkRef(handleRef, ref);
    const defaultProperty = {
        className,
        ref: svgRef,
        ...others,
    };
    return (
        <SvgIcon {...defaultProperty}>
            <path
                d="M9,1.5V3h6V1.5C15,0.7,15.7,0,16.5,0C17.3,0,18,0.7,18,1.5V3h2.2c1.2,0,2.2,1,2.2,2.2v2.2h-21V5.2C1.5,4,2.5,3,3.8,3H6V1.5
	C6,0.7,6.7,0,7.5,0S9,0.7,9,1.5z M1.5,9h21v12.7c0,1.2-1,2.2-2.2,2.2H3.8c-1.2,0-2.2-1-2.2-2.2V9z M4.5,14.2c0,0.4,0.3,0.8,0.8,0.8
	h1.5c0.4,0,0.8-0.3,0.8-0.8v-1.5c0-0.4-0.3-0.8-0.8-0.8H5.2c-0.4,0-0.8,0.3-0.8,0.8V14.2z M10.5,14.2c0,0.4,0.3,0.8,0.8,0.8h1.5
	c0.4,0,0.8-0.3,0.8-0.8v-1.5c0-0.4-0.3-0.8-0.8-0.8h-1.5c-0.4,0-0.8,0.3-0.8,0.8V14.2z M17.2,12c-0.4,0-0.8,0.3-0.8,0.8v1.5
	c0,0.4,0.3,0.8,0.8,0.8h1.5c0.4,0,0.8-0.3,0.8-0.8v-1.5c0-0.4-0.3-0.8-0.8-0.8H17.2z M4.5,20.2c0,0.4,0.3,0.8,0.8,0.8h1.5
	c0.4,0,0.8-0.3,0.8-0.8v-1.5c0-0.4-0.3-0.8-0.8-0.8H5.2c-0.4,0-0.8,0.3-0.8,0.8V20.2z M11.2,18c-0.4,0-0.8,0.3-0.8,0.8v1.5
	c0,0.4,0.3,0.8,0.8,0.8h1.5c0.4,0,0.8-0.3,0.8-0.8v-1.5c0-0.4-0.3-0.8-0.8-0.8H11.2z M16.5,20.2c0,0.4,0.3,0.8,0.8,0.8h1.5
	c0.4,0,0.8-0.3,0.8-0.8v-1.5c0-0.4-0.3-0.8-0.8-0.8h-1.5c-0.4,0-0.8,0.3-0.8,0.8V20.2z"
            />
        </SvgIcon>
    );
});

export default CalendarDays;
