import { useCallback } from 'react';
import useAjax from '@apis/useAjax';
import { useSnackbar } from 'notistack';

const GET_TEMPORARIES_OVERVIEW_OPTIONS_API = `/api/temporaries/overview/options/`;
const POST_TEMPORARIES_API = `/api/temporaries/`;
const DELETE_TEMPORARIES_API = POST_TEMPORARIES_API;
const PUT_TEMPORARIES_TEMPORARYID_API = (temporaryID) => `/api/temporaries/${temporaryID}/`;
const DELETE_TEMPORARIES_TEMPORARYID_API = PUT_TEMPORARIES_TEMPORARYID_API;
const POST_TEMPORARIES_TEMPORARYID_OVERVIEW_API = (temporaryID, customerType) => `/api/temporaries/${temporaryID}/${customerType}/overview/`;
const GET_TEMPORARIES_TEMPORARYID_EXPORT_API = (temporaryID) => `/api/temporaries/${temporaryID}/export/`;

const useTemporaryApi = () => {
    const Ajax = useAjax();
    const { enqueueSnackbar } = useSnackbar();

    // 暫存管理列表options
    const getTemorariesOverviewOptionsApi = useCallback(
        async () => {
            const resp = await Ajax.get(GET_TEMPORARIES_OVERVIEW_OPTIONS_API);
            if (!resp) enqueueSnackbar('讀取資料失敗,請重新整理', { variant: 'error' });
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    // 新增暫存資料
    const postTemorariesOverviewApi = useCallback(
        async (params) => {
            const resp = await Ajax.post(POST_TEMPORARIES_API, params);
            if (resp) enqueueSnackbar('新增暫存成功', { variant: 'success' });
            else enqueueSnackbar('新增暫存失敗,請再操作一次', { variant: 'error' });
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    // 刪除暫存資料清單
    const deleteTemporaryApi = useCallback(
        async (params) => {
            const resp = await Ajax.delete(DELETE_TEMPORARIES_API, params);
            if (resp) enqueueSnackbar('成功刪除', { variant: 'success' });
            else enqueueSnackbar('刪除失敗,請再操作一次', { variant: 'error' });
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    // 暫存資料 顧客/名單/會員 明細
    const postTemporariesIDOverviewApi = useCallback(
        async (temporaryID, customerType, params) => {
            const resp = await Ajax.post(POST_TEMPORARIES_TEMPORARYID_OVERVIEW_API(temporaryID, customerType), params);
            if (!resp) enqueueSnackbar('讀取資料失敗,請重新整理', { variant: 'error' });
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    // 修改暫存明細的暫存名稱
    const putTemporaryCustomersApi = useCallback(
        async (id, params) => {
            const resp = await Ajax.put(PUT_TEMPORARIES_TEMPORARYID_API(id), params);
            if (resp) enqueueSnackbar('修改成功', { variant: 'success' });
            else enqueueSnackbar('修改失敗，請重新再操作一次', { variant: 'error' });
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    // 刪除暫存明細內容的 顧客/名單
    const deleteTemporaryCustomersApi = useCallback(
        async (id, params) => {
            const resp = await Ajax.delete(DELETE_TEMPORARIES_TEMPORARYID_API(id), params);
            if (resp) enqueueSnackbar('刪除成功', { variant: 'success' });
            else enqueueSnackbar('刪除失敗,請重新再操作一次', { variant: 'error' });
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    const getTemorariesExcelApi = useCallback(
        async (temporaryID) => {
            const resp = await window.open(GET_TEMPORARIES_TEMPORARYID_EXPORT_API(temporaryID));
            resp && enqueueSnackbar('將開啟畫面', { variant: 'success' });
        },
        // eslint-disable-next-line
        []
    );

    return {
        getTemorariesOverviewOptionsApi,
        postTemorariesOverviewApi,
        deleteTemporaryApi,
        postTemporariesIDOverviewApi,
        putTemporaryCustomersApi,
        deleteTemporaryCustomersApi,
        getTemorariesExcelApi,
    };
};

export default useTemporaryApi;
