import React from 'react';
import SvgIcon from '../SvgIcon';
import useForkRef from '@util/hook/useForkRef';

const NavigateNext = React.forwardRef((props, ref) => {
    const { className, ...others } = props;
    const handleRef = React.useRef(null);
    const svgRef = useForkRef(handleRef, ref);
    const defaultProperty = {
        className,
        ref: svgRef,
        ...others,
    };
    return (
        <SvgIcon {...defaultProperty}>
            <path
                d="M5.586,13.762.273,8.449a.934.934,0,0,1,0-1.324l.883-.883a.934.934,0,0,1,1.324,0l3.766,3.766,3.766-3.766a.934.934,0,0,1,1.324,0l.883.883a.934.934,0,0,1,0,1.324L6.906,13.762A.929.929,0,0,1,5.586,13.762Z"
                transform="translate(6.002 3.033)"
            />
        </SvgIcon>
    );
});

export default NavigateNext;
