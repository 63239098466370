import React from 'react';
import clsx from 'clsx';
import { isEmpty } from 'lodash';
import Tablenization from '@icoach/components/Tablenization';
import { TableLabel } from '@icoach/dataAnalysis/components';
import {
    guestProductionTabsItemType,
    transposedWeeklyData,
    agpMonthlyTableThData,
    callsMonthlyTableThData,
    apoMonthlyTableThData,
    totalSalesMonthlyTableThData,
    agpMonthlyRowsLabel,
    agpGuestMonthlyRowsLabel,
    callsMonthlyRowsLabel,
    apoMonthlyRowsLabel,
    totalSalesMonthlyRowsLabel,
} from '@icoach/dataAnalysis/staticData';
import { Box } from '@common/components/';
import useReportDetailApi from '@apis/useReportDetailApi';

const getRowsData = (data, cumulative, thData, arg) => {
    let rowsData = [];
    let rowsLabel = [
        {
            cellKey: 'title',
            headerLabel: '項目',
            width: 26,
            formatCell: (title, { tip, isStar }) => {
                return <TableLabel title={title} tip={tip} isStar={isStar} />;
            },
        },
        {
            cellKey: 'goal',
            headerLabel: '目標',
            align: 'center',
            width: 11,
        },
        {
            cellKey: 'cumulative',
            headerLabel: '最新數字',
            align: 'center',
            className: 'total-col',
            minWidth: 82,
            formatCell: (
                val,
                { current, title, category, isShowDetail = false, ...other },
                { dialogRef, defaultDate, tableRowsLabelMap, guestProductionApiMap },
            ) => {
                const { openReportDetailDialog } = dialogRef.current || {};

                if (isShowDetail && val) {
                    return (
                        <Box
                            className={'data-analysis-link'}
                            onClick={() =>
                                openReportDetailDialog({
                                    title: `${title}-最新數字`,
                                    dateString: defaultDate.current,
                                    apiFn: guestProductionApiMap.get(category),
                                    tableRowsLabel: tableRowsLabelMap.get(category),
                                    dataKeyName: current,
                                    category,
                                })
                            }
                        >
                            {val}
                        </Box>
                    );
                }
                return val;
            },
        },
    ];

    data = transposedWeeklyData(data);

    if (!isEmpty(data)) {
        rowsData = thData.map(({ key, formatCell, ...other }) => {
            let weeklyData = data[key];
            let rowData = {
                current: key,
                cumulative: cumulative[key],
                ...other,
                ...weeklyData,
            };

            if (formatCell) {
                rowData.cumulative = formatCell(cumulative[key], { ...rowData, cellKey: 'cumulative' }, arg);
                for (let week in weeklyData) {
                    rowData[week] = formatCell(weeklyData[week], { ...rowData, cellKey: week }, arg);
                }
            }

            return rowData;
        });

        rowsLabel = [...rowsLabel, ...data.week];
    }

    return { rowsLabel, rowsData };
};

const GuestProductionMonthlyTable = (props) => {
    const { className, defaultDate, tabsIndex, rowsData: data = [], cumulative, dialogRef } = props;
    const { getAGPReportDetailApi, getAGPGuestReportDetailApi, getCallsReportDetailApi, getAPOReportDetailApi, getTotalSalesReportDetailApi } =
        useReportDetailApi();
    const thDataMap = new Map([
        [guestProductionTabsItemType.agp, agpMonthlyTableThData],
        [guestProductionTabsItemType.calls, callsMonthlyTableThData],
        [guestProductionTabsItemType.apo, apoMonthlyTableThData],
        [guestProductionTabsItemType.totalSales, totalSalesMonthlyTableThData],
    ]);
    const { rowsLabel, rowsData } = getRowsData(data, cumulative, thDataMap.get(tabsIndex));

    const tableRowsLabelMap = new Map([
        [guestProductionTabsItemType.agp, agpMonthlyRowsLabel],
        [guestProductionTabsItemType.agpGuest, agpGuestMonthlyRowsLabel],
        [guestProductionTabsItemType.calls, callsMonthlyRowsLabel],
        [guestProductionTabsItemType.apo, apoMonthlyRowsLabel],
        [guestProductionTabsItemType.totalSales, totalSalesMonthlyRowsLabel],
    ]);

    const guestProductionApiMap = new Map([
        [guestProductionTabsItemType.agp, getAGPReportDetailApi],
        [guestProductionTabsItemType.agpGuest, getAGPGuestReportDetailApi],
        [guestProductionTabsItemType.calls, getCallsReportDetailApi],
        [guestProductionTabsItemType.apo, getAPOReportDetailApi],
        [guestProductionTabsItemType.totalSales, getTotalSalesReportDetailApi],
    ]);

    return (
        <Tablenization cardProps={{ className: clsx('monthly-table', className) }}>
            <Tablenization.TableRowHeader headerRow={rowsLabel} />
            <Tablenization.TableRowBody
                headerRow={rowsLabel}
                rowsData={rowsData}
                dialogRef={dialogRef}
                tableRowsLabelMap={tableRowsLabelMap}
                guestProductionApiMap={guestProductionApiMap}
                defaultDate={defaultDate}
            />
        </Tablenization>
    );
};

export default GuestProductionMonthlyTable;
