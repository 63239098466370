import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import Tablenization from '@icoach/components/Tablenization';
import { formatCurrencyFn } from '@util/utils';
import { parseDate } from '@util/moment';
import { MoreLabelBox, PageSortAndTotalBox, MorePageBox } from '@icoach/components/';
import { CycleOrderDeleteDialog } from '@icoach/documents/dialog/';
import { DOCUMENT_CYCLE_PURCHASE_PATH, DOCUMENT_CYCLE_PURCHASE_EFFECT_PATH } from '@icoach/router/routerPath';
import { useCyclePurchaseContext } from '@icoach/documents/cyclePurchase/CyclePurchaseContext';
import { Stack, Typography } from '@common/components/';

const MainMoreLabel = (props) => {
    const { memberID, cycleOrderID } = props;
    const { refresh } = useCyclePurchaseContext();
    const moreLabelMap = useRef({
        view: {
            text: '查看',
            to: () => DOCUMENT_CYCLE_PURCHASE_PATH(memberID, cycleOrderID),
        },
        modify: {
            text: '變更申請書',
            to: () => DOCUMENT_CYCLE_PURCHASE_EFFECT_PATH(memberID, cycleOrderID),
        },
        delete: {
            text: '刪除申請書',
            dialogString: 'delete',
            Dialog: CycleOrderDeleteDialog,
        },
    });

    return <MoreLabelBox {...props} memberID={0} moreLabelMap={moreLabelMap.current} textAlign={'center'} refresh={refresh.current} />;
};

const rowsLabel = [
    {
        cellKey: 'cycleOrderNo',
        headerLabel: '循環訂單編號',
        width: '12',
        formatCell: (cellValue, { memberID, cycleOrderID }) => (
            <Link className={'text-decoration-underline text-secondary'} to={DOCUMENT_CYCLE_PURCHASE_PATH(memberID, cycleOrderID)}>
                {cellValue}
            </Link>
        ),
    },
    {
        cellKey: 'memberName',
        align: 'center',
        headerLabel: '訂購人',
    },
    {
        cellKey: 'cycleOrderTypeText',
        align: 'center',
        headerLabel: '訂單狀態',
    },
    {
        cellKey: 'saleAmount',
        headerLabel: '訂單金額',
        align: 'center',
        formatCell: (cellValue) => formatCurrencyFn(cellValue),
    },
    {
        cellKey: 'shippingDayText',
        headerLabel: '出貨週期',
        align: 'center',
        formatCell: (cellValue) => `${cellValue}日`,
    },
    {
        cellKey: 'updatedDate',
        headerLabel: '更新日期',
        width: '12',
        formatCell: (cellValue) => {
            const _parseDate = (date, format) => {
                return parseDate(date, format, false);
            };
            return (
                <React.Fragment>
                    <Typography className="mb-0">{_parseDate(cellValue, 'YYYY/MM/DD')}</Typography>
                    <Typography className="mb-0 font-color-3">{_parseDate(cellValue, 'HH:mm')}</Typography>
                </React.Fragment>
            );
        },
    },
    {
        cellKey: 'employeeName',
        headerLabel: '擔當',
        align: 'center',
    },
    {
        cellKey: 'action',
        headerLabel: '',
        align: 'right',
        isIcon: true,
        formatCell: (_, rowData) => {
            const { canDeleted, canEffection } = rowData;
            let moreAction = {
                view: true,
                modify: canEffection,
                delete: canDeleted,
            };
            let getMoreActionArray = () => {
                let array = [];
                for (let key in moreAction) {
                    let bool;
                    if (moreAction.hasOwnProperty(key)) {
                        bool = moreAction[key];
                    }
                    if (bool) {
                        array.push(key);
                    }
                }
                return array;
            };
            return <MainMoreLabel {...rowData} moreActionArray={getMoreActionArray()} />;
        },
    },
];

const CycleOrderListTable = (props) => {
    const { className, rowsData = [], pageIndex = 1, totalPage = 1, pageSize = 0, totalCount = 0 } = props;
    const { setBookmarkNum } = useCyclePurchaseContext();
    const labelTitle = useRef(rowsLabel);
    const handlePageChange = (e, num) => setBookmarkNum(num);

    return (
        <React.Fragment>
            <Stack justifyContent="end">
                <PageSortAndTotalBox className="mr-2 my-2" pageIndex={pageIndex} pageSize={pageSize} totalCount={totalCount} isCurrentPageLastIndex />
            </Stack>
            <Tablenization
                Pagination={MorePageBox}
                count={totalPage}
                page={pageIndex}
                cardProps={{ className: className }}
                onChange={handlePageChange}
                isPagination
            >
                <Tablenization.TableRowHeader headerRow={labelTitle.current} />
                <Tablenization.TableRowBody headerRow={labelTitle.current} rowsData={rowsData} />
            </Tablenization>
        </React.Fragment>
    );
};

export default CycleOrderListTable;
