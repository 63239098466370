import React from 'react';
import { useParams } from 'react-router-dom';
import { Typography, IconButton } from '@common/components/';
import { Edit as EditIcon, Cancel as CancelIcon, RemoveRedEye as RemoveRedEyeIcon, Download as DownloadIcon } from '@common/SvgIcon/';
import { peopleType } from '@apis/usePeopleApi';

// 展開的操作按鈕們
export const moreColData = {
    cellKey: '',
    headerLabel: () => <div></div>,
    align: 'right',
    formatCell: (cellValue, rowsData, { onReviewEvent, onEditEvent, onDeleteOnEvent, onDownloadPDFEvent, onTransferOutConfirm, moreLabelText }) => {
        return (
            <MoreLabel
                rowData={rowsData}
                handleOnEdit={onEditEvent}
                handleOnRead={onReviewEvent}
                handleOnDelete={onDeleteOnEvent}
                handleOnDownload={onDownloadPDFEvent}
                handleTransferOutConfirm={onTransferOutConfirm}
                {...moreLabelText}
            />
        );
    },
};

const MoreLabel = (props) => {
    const {
        rowData = {},
        handleOnEdit,
        handleOnRead,
        handleOnDelete,
        handleOnDownload,
        handleTransferOutConfirm,
        deleteLabelText = '刪除',
        eidtLabelText = '重填',
    } = props;
    const { peopleType: peopleTypeProps } = useParams();
    const { canReModify, canPreview, canDeleted, isTemporary } = rowData;
    let moreLabelActionArray = [];

    if (String(peopleType.unConfirmTransferOutMember) === String(peopleTypeProps)) {
        moreLabelActionArray.push({
            key: 'transferOutConfirm',
            Icon: EditIcon,
            text: `確認移出`,
            onClick: handleTransferOutConfirm,
            isShow: true,
        });
    }

    moreLabelActionArray.push(
        ...[
            {
                key: 'delete',
                Icon: CancelIcon,
                text: `${isTemporary ? '刪除' : deleteLabelText}`,
                onClick: handleOnDelete,
                isShow: canDeleted,
            },
            {
                key: 'edit',
                Icon: EditIcon,
                text: `${isTemporary ? '編輯' : eidtLabelText}`,
                onClick: handleOnEdit,
                isShow: canReModify,
            },
            {
                key: 'download',
                Icon: DownloadIcon,
                text: '下載',
                onClick: handleOnDownload,
                isShow: canPreview,
            },
            {
                key: 'read',
                Icon: RemoveRedEyeIcon,
                text: '查看',
                onClick: handleOnRead,
                isShow: canPreview,
            },
        ]
    );

    return (
        <React.Fragment>
            {moreLabelActionArray
                .filter((item) => item.isShow)
                .map(({ key: _key, text, Icon, onClick: handleClick }) => {
                    return (
                        <IconButton
                            name={_key}
                            key={_key}
                            className="icon-button-column"
                            onClick={(e) => {
                                if (typeof handleClick === 'function') handleClick(rowData);
                            }}
                        >
                            <Icon />
                            <Typography className="icon-button-column-text">{text}</Typography>
                        </IconButton>
                    );
                })}
        </React.Fragment>
    );
};

export default moreColData;
