import { useCallback } from 'react';
import useAjax from '@apis/useAjax';

const GET_ORDER_ALLOWANCE_ID_API = (orderID) => `/api/orders/${orderID}/allowance-dialog/`;
const POST_ORDER_ALLOWANCE_CONFIRM_API = `/api/orders/allowance-confirm/`;
const GET_ORDER_INVALID_ABANDON_ID_API = (orderID) => `/api/orders/${orderID}/invalid-dialog/`;
const POST_ORDER_INVALID_ABANDON_CONFIRM_API = `/api/orders/invalid-confirm/`;
const GET_ORDER_ABANDON_ALLOWANCE_ID_API = (orderID) => `/api/orders/${orderID}/allowance-history/`;
const POST_ORDER_ABANDON_ALLOWANCE_CONFIRM_API = `/api/orders/invalid-allowance-confirm/`;

const useOrderReturnApi = () => {
    const Ajax = useAjax();

    // 訂單明細(主單(退單清單)、付款資訊、購買清單(退貨內容)、訂單紀錄)
    const getOrderAllowanceApi = useCallback(
        async (orderID) => {
            const resp = await Ajax.get(GET_ORDER_ALLOWANCE_ID_API(orderID));
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    // 發送折讓內容及項目
    const postOrderAllowanceConfirmApi = useCallback(
        async (parameters) => {
            const resp = await Ajax.post(POST_ORDER_ALLOWANCE_CONFIRM_API, parameters);
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    // 取得需要一併開立發票的訂單內全部的作廢項目
    const getOrderInvalidAbandonApi = useCallback(
        async (orderID) => {
            const resp = await Ajax.get(GET_ORDER_INVALID_ABANDON_ID_API(orderID));
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    // 發送作廢發票內容及項目
    const postOrderInvalidAbandonConfirmApi = useCallback(
        async (parameters) => {
            const resp = await Ajax.post(POST_ORDER_INVALID_ABANDON_CONFIRM_API, parameters);
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    // 取得已折讓清單，準備執行折讓作廢
    const getOrderAbandonAllowanceApi = useCallback(
        async (orderID) => {
            const resp = await Ajax.get(GET_ORDER_ABANDON_ALLOWANCE_ID_API(orderID));
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    // 發送作廢折讓
    const postOrderAbandonAllowanceConfirmApi = useCallback(
        async (parameters) => {
            const resp = await Ajax.post(POST_ORDER_ABANDON_ALLOWANCE_CONFIRM_API, parameters);
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    return {
        getOrderAllowanceApi,
        postOrderAllowanceConfirmApi,
        getOrderInvalidAbandonApi,
        postOrderInvalidAbandonConfirmApi,
        getOrderAbandonAllowanceApi,
        postOrderAbandonAllowanceConfirmApi,
    };
};

export default useOrderReturnApi;
