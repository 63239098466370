import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { KeyboardArrowLeft, KeyboardArrowRight } from '../icons/SvgIcon/';
import ButtonBase from '../button/ButtonBase';

const staticClass = `tabs-scroll-button`;

const TabScrollButton = React.forwardRef((props, ref) => {
    const { component = 'div', direction, orientation, disabled, isRtl, ...other } = props;
    let classNames = `staticClass`;
    if (disabled) classNames = clsx(classNames, `${staticClass}-disabled`);
    if (orientation === 'vertical') classNames = clsx(classNames, `${staticClass}-${orientation}`);
    if (isRtl) classNames = clsx(classNames, `${staticClass}-rtl`);

    const defaultProperty = { component, ref, role: null, tabIndex: null, className: classNames, ...other };
    
    return (
        <ButtonBase {...defaultProperty}>
            {direction === 'left' ? <KeyboardArrowLeft fontSize="small" /> : <KeyboardArrowRight fontSize="small" />}
        </ButtonBase>
    );
});

TabScrollButton.propTypes /* remove-proptypes */ = {
    // ----------------------------- Warning --------------------------------
    // | These PropTypes are generated from the TypeScript type definitions |
    // |     To update them edit the d.ts file and run "yarn proptypes"     |
    // ----------------------------------------------------------------------
    /**
     * The content of the component.
     */
    children: PropTypes.node,
    /**
     * The direction the button should indicate.
     */
    direction: PropTypes.oneOf(['left', 'right']).isRequired,
    /**
     * If `true`, the component is disabled.
     */
    disabled: PropTypes.bool,
    /**
     * The component orientation (layout flow direction).
     */
    orientation: PropTypes.oneOf(['horizontal', 'vertical']).isRequired,
};

export default TabScrollButton;
