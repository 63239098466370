import React, { useEffect, useRef, useState } from 'react';
import { Dialog, DialogActions } from '@mui/material';
import clsx from 'clsx';
import { Button, Form } from '@common/components/';
import GradientColorHeader from '@icoach/components/dialog/GradientColorHeader';
import { PushDialogContent } from '@icoach/members/memberDetail/dialog/ProbioticsPushDialog';
import { useMemberProfileApi } from '@apis/useMemberProfileApi';

const ProteinPushDialog = (props) => {
    const { className, open, memberID, data, onClose, refresh } = props;
    const { postMemberProfileProteinPushApi, getMembersProteinOptionsApi } = useMemberProfileApi();
    const [options, setOptions] = useState([]);
    const contentRef = useRef();

    const handleSubmit = () => {
        let isError = contentRef.current && contentRef.current.isError();
        if (!isError) {
            let params = contentRef.current && contentRef.current.getResult();
            doMemberProfileProteinPushApi(memberID, params);
        }
    };

    const doMemberProfileProteinPushApi = async (memberID, params) => {
        let res = await postMemberProfileProteinPushApi(memberID, params);
        if (res) {
            refresh && refresh(memberID);
            onClose();
        }
    };

    const doOptionsInitApi = async (mID) => {
        const resp = await getMembersProteinOptionsApi(mID);
        if (resp) {
            setOptions(resp);
        }
    };

    useEffect(
        () => {
            if (open && memberID) {
                doOptionsInitApi(memberID);
            }
        },
        // eslint-disable-next-line
        [open, memberID]
    );

    if (!open) return null;

    return (
        <Dialog className={clsx('simple-content', 'dialog', className)} PaperProps={{ className: 'wx-50rem' }} open={open} fullWidth>
            <Form onSubmit={handleSubmit}>
                <GradientColorHeader onClose={onClose}>編輯蛋白訂單</GradientColorHeader>
                <PushDialogContent ref={contentRef} data={data} resource={options} />
                <DialogActions>
                    <Button type={'submit'} variant="contained">
                        確認送出
                    </Button>
                </DialogActions>
            </Form>
        </Dialog>
    );
};

export default ProteinPushDialog;
