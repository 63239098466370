import React from 'react';
import Popper from '../popper/Popper';
import Grow from '../grow/Grow';
import useControlled from '../../util/hook/useControlled';
import useId from '../../util/hook/useId';
import useForkRef from '../../util/hook/useForkRef';
import useEventCallback from '../../util/hook/useEventCallback';
import useIsFocusVisible from '../../util/hook/useIsFocusVisible';
import { duration } from '../../util/createTransitions';
import { appendOwnerState } from '../../util/utils';
import clsx from 'clsx';

// const placementArray = [
//     'bottom-end',
//     'bottom-start',
//     'bottom',
//     'left-end',
//     'left-start',
//     'left',
//     'right-end',
//     'right-start',
//     'right',
//     'top-end',
//     'top-start',
//     'top',
// ];

const staticClass = 'tooltip';

// const round = (value) => {
//     return Math.round(value * 1e5) / 1e5;
// };

const composeEventHandler = (handler, eventHandler) => {
    return (event) => {
        if (eventHandler) {
            eventHandler(event);
        }
        handler(event);
    };
};

const TooltipPopper = (props) => {
    const { ownerState, placement, className: classNameProps, ...other } = props;

    return (
        <Popper
            className={classNameProps}
            data-popper-placement={placement}
            placement={placement}
            {...other}
        />
    );
};

const TooltipTooltip = React.forwardRef((props, ref) => {
    const { style: styleProps, className: classNameProps, ownerState, children } = props;
    const { arrow, isRtl, touch } = ownerState;
    let classNames = classNameProps;
    if (arrow) classNames = clsx(`${staticClass}-isArrow`, classNames);
    if (isRtl) classNames = clsx(`${staticClass}-isRtl`, classNames);
    if (touch) classNames = clsx(`${staticClass}-touch`, classNames);
    return (
        <div className={classNames} style={styleProps} ref={ref}>
            {children}
        </div>
    );
});

const TooltipArrow = React.forwardRef((props, ref) => {
    const { className: classNameProps } = props;
    return <span className={classNameProps} ref={ref} />;
});

let hystersisOpen = false;
let hystersisTimer = null;

const Tooltip = React.forwardRef((props, ref) => {
    const {
        id: idProp,
        open: openProp,
        arrow = false,
        describeChild = false,
        disableFocusListener = false,
        disableHoverListener = false,
        disableInteractive: disableInteractiveProp = false,
        disableTouchListener = false,
        followCursor = false,
        children,
        // components = {},
        componentsProps = {},
        placement = 'bottom',
        TransitionComponent: TransitionComponentProp = Grow,
        TransitionProps,
        enterDelay = 100,
        enterNextDelay = 0,
        enterTouchDelay = 700,
        leaveDelay = 0,
        leaveTouchDelay = 1500,
        title,
        onClose,
        onOpen,
        isRtl = false,
        PopperProps = {},
        PopperComponent: PopperComponentProp, //no
        ...other
    } = props;

    const [childNode, setChildNode] = React.useState();
    const [arrowRef, setArrowRef] = React.useState(null);
    const ignoreNonTouchEvents = React.useRef(false);
    const closeTimer = React.useRef();
    const enterTimer = React.useRef();
    const leaveTimer = React.useRef();
    const touchTimer = React.useRef();
    const prevUserSelect = React.useRef();

    const disableInteractive = disableInteractiveProp || followCursor;

    const [openState, setOpenState] = useControlled({
        controlled: openProp,
        default: false,
        name: 'Tooltip',
        state: 'open',
    });

    let open = openState;
    const id = useId(idProp);

    const stopTouchInteraction = React.useCallback(() => {
        if (prevUserSelect.current !== undefined) {
            document.body.style.WebkitUserSelect = prevUserSelect.current;
            prevUserSelect.current = undefined;
        }
        clearTimeout(touchTimer.current);
    }, []);

    React.useEffect(() => {
        return () => {
            clearTimeout(closeTimer.current);
            clearTimeout(enterTimer.current);
            clearTimeout(leaveTimer.current);
            stopTouchInteraction();
        };
    }, [stopTouchInteraction]);

    const handleOpen = (event) => {
        clearTimeout(hystersisTimer);
        hystersisOpen = true;
        // The mouseover event will trigger for every nested element in the tooltip.
        // We can skip rerendering when the tooltip is already open.
        // We are using the mouseover event instead of the mouseenter event to fix a hide/show issue.
        setOpenState(true);
        if (onOpen && !open) onOpen(event);
    };

    const handleClose = useEventCallback(
        /**
         * @param {React.SyntheticEvent | Event} event
         */
        (event) => {
            clearTimeout(hystersisTimer);
            hystersisTimer = setTimeout(() => {
                hystersisOpen = false;
            }, 800 + leaveDelay);
            setOpenState(false);

            if (onClose && open) {
                onClose(event);
            }

            clearTimeout(closeTimer.current);
            closeTimer.current = setTimeout(() => {
                ignoreNonTouchEvents.current = false;
            }, duration.shortest);
        }
    );

    const handleEnter = (event) => {
        if (ignoreNonTouchEvents.current && event.type !== 'touchstart') {
            return;
        }

        // Remove the title ahead of time.
        // We don't want to wait for the next render commit.
        // We would risk displaying two tooltips at the same time (native + this one).
        if (childNode) {
            childNode.removeAttribute('title');
        }

        clearTimeout(enterTimer.current);
        clearTimeout(leaveTimer.current);
        if (enterDelay || (hystersisOpen && enterNextDelay)) {
            enterTimer.current = setTimeout(
                () => {
                    handleOpen(event);
                },
                hystersisOpen ? enterNextDelay : enterDelay
            );
        } else {
            handleOpen(event);
        }
    };

    const handleLeave = (event) => {
        clearTimeout(enterTimer.current);
        clearTimeout(leaveTimer.current);
        leaveTimer.current = setTimeout(() => {
            handleClose(event);
        }, leaveDelay);
    };

    const {
        isFocusVisibleRef,
        onBlur: handleBlurVisible,
        onFocus: handleFocusVisible,
        ref: focusVisibleRef,
    } = useIsFocusVisible();

    const [, setChildIsFocusVisible] = React.useState(false);
    const handleBlur = (event) => {
        handleBlurVisible(event);
        if (isFocusVisibleRef.current === false) {
            setChildIsFocusVisible(false);
            handleLeave(event);
        }
    };

    const handleFocus = (event) => {
        // Workaround for https://github.com/facebook/react/issues/7769
        // The autoFocus of React might trigger the event before the componentDidMount.
        // We need to account for this eventuality.
        if (!childNode) {
            setChildNode(event.currentTarget);
        }

        handleFocusVisible(event);
        if (isFocusVisibleRef.current === true) {
            setChildIsFocusVisible(true);
            handleEnter(event);
        }
    };

    const detectTouchStart = (event) => {
        ignoreNonTouchEvents.current = true;

        const childrenProps = children.props;
        childrenProps.onTouchStart && childrenProps.onTouchStart(event);
    };

    const handleMouseOver = handleEnter;
    const handleMouseLeave = handleLeave;

    const handleTouchStart = (event) => {
        detectTouchStart(event);
        clearTimeout(leaveTimer.current);
        clearTimeout(closeTimer.current);
        stopTouchInteraction();

        prevUserSelect.current = document.body.style.WebkitUserSelect;
        // Prevent iOS text selection on long-tap.
        document.body.style.WebkitUserSelect = 'none';

        touchTimer.current = setTimeout(() => {
            document.body.style.WebkitUserSelect = prevUserSelect.current;
            handleEnter(event);
        }, enterTouchDelay);
    };

    const handleTouchEnd = (event) => {
        if (children.props.onTouchEnd) {
            children.props.onTouchEnd(event);
        }

        stopTouchInteraction();
        clearTimeout(leaveTimer.current);
        leaveTimer.current = setTimeout(() => {
            handleClose(event);
        }, leaveTouchDelay);
    };

    React.useEffect(() => {
        if (!open) {
            return undefined;
        }

        /**
         * @param {KeyboardEvent} nativeEvent
         */
        function handleKeyDown(nativeEvent) {
            // IE11, Edge (prior to using Bink?) use 'Esc'
            if (nativeEvent.key === 'Escape' || nativeEvent.key === 'Esc') {
                handleClose(nativeEvent);
            }
        }

        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [handleClose, open]);

    const handleUseRef = useForkRef(setChildNode, ref);
    const handleFocusRef = useForkRef(focusVisibleRef, handleUseRef);
    const handleRef = useForkRef(children.ref, handleFocusRef);

    // There is no point in displaying an empty tooltip.
    if (title === '') open = false;

    const positionRef = React.useRef({ x: 0, y: 0 });
    const popperRef = React.useRef();

    const handleMouseMove = (event) => {
        const childrenProps = children.props;
        childrenProps.onMouseMove && childrenProps.onMouseMove(event);

        positionRef.current = { x: event.clientX, y: event.clientY };

        popperRef.current && popperRef.current.update();
    };

    const nameOrDescProps = {};
    const titleIsString = typeof title === 'string';
    if (describeChild) {
        nameOrDescProps.title = !open && titleIsString && !disableHoverListener ? title : null;
        nameOrDescProps['aria-describedby'] = open ? id : null;
    } else {
        nameOrDescProps['aria-label'] = titleIsString ? title : null;
        nameOrDescProps['aria-labelledby'] = open && !titleIsString ? id : null;
    }

    const childrenProps = {
        ...nameOrDescProps,
        ...other,
        ...children.props,
        className: clsx(other.className, children.props.className),
        onTouchStart: detectTouchStart,
        ref: handleRef,
        ...(followCursor ? { onMouseMove: handleMouseMove } : {}),
    };

    const interactiveWrapperListeners = {};

    if (!disableTouchListener) {
        childrenProps.onTouchStart = handleTouchStart;
        childrenProps.onTouchEnd = handleTouchEnd;
    }

    if (!disableHoverListener) {
        childrenProps.onMouseOver = composeEventHandler(handleMouseOver, childrenProps.onMouseOver);
        childrenProps.onMouseLeave = composeEventHandler(
            handleMouseLeave,
            childrenProps.onMouseLeave
        );

        if (!disableInteractive) {
            interactiveWrapperListeners.onMouseOver = handleMouseOver;
            interactiveWrapperListeners.onMouseLeave = handleMouseLeave;
        }
    }

    if (!disableFocusListener) {
        childrenProps.onFocus = composeEventHandler(handleFocus, childrenProps.onFocus);
        childrenProps.onBlur = composeEventHandler(handleBlur, childrenProps.onBlur);

        if (!disableInteractive) {
            interactiveWrapperListeners.onFocus = handleFocus;
            interactiveWrapperListeners.onBlur = handleBlur;
        }
    }

    const popperOptions = React.useMemo(() => {
        let tooltipModifiers = [
            {
                name: 'arrow',
                enabled: Boolean(arrowRef),
                options: {
                    element: arrowRef,
                    padding: 4,
                },
            },
        ];

        if (PopperProps.popperOptions?.modifiers) {
            tooltipModifiers = tooltipModifiers.concat(PopperProps.popperOptions.modifiers);
        }

        return {
            ...PopperProps.popperOptions,
            modifiers: tooltipModifiers,
        };
    }, [arrowRef, PopperProps]);

    const PopperComponent = TooltipPopper;
    const TransitionComponent = TransitionComponentProp;
    const TooltipComponent = TooltipTooltip;
    const ArrowComponent = TooltipArrow;

    const defaultProperty = {
        ...props,
        isRtl,
        arrow,
        disableInteractive,
        placement,
        PopperComponentProp,
        touch: ignoreNonTouchEvents.current,
    };

    const popperProps = appendOwnerState(
        PopperComponent,
        { ...PopperProps, ...componentsProps.popper },
        defaultProperty
    );

    const transitionProps = appendOwnerState(
        TransitionComponent,
        { ...TransitionProps, ...componentsProps.transition },
        defaultProperty
    );

    const tooltipProps = appendOwnerState(
        TooltipComponent,
        { ...componentsProps.tooltip },
        defaultProperty
    );

    const tooltipArrowProps = appendOwnerState(
        ArrowComponent,
        { ...componentsProps.arrow },
        defaultProperty
    );

    return (
        <React.Fragment>
            {React.cloneElement(children, childrenProps)}
            <PopperComponent
                as={PopperComponentProp ?? Popper}
                placement={placement}
                anchorEl={
                    followCursor
                        ? {
                              getBoundingClientRect: () => ({
                                  top: positionRef.current.y,
                                  left: positionRef.current.x,
                                  right: positionRef.current.x,
                                  bottom: positionRef.current.y,
                                  width: 0,
                                  height: 0,
                              }),
                          }
                        : childNode
                }
                popperRef={popperRef}
                open={childNode ? open : false}
                id={id}
                transition
                {...interactiveWrapperListeners}
                {...popperProps}
                className={clsx(
                    `${staticClass}-popper`,
                    PopperProps?.className,
                    componentsProps.popper?.className
                )}
                popperOptions={popperOptions}
            >
                {({ TransitionProps: TransitionPropsInner }) => {
                    return (
                        <TransitionComponent
                            timeout={duration.shorter}
                            {...TransitionPropsInner}
                            {...transitionProps}
                        >
                            <TooltipComponent
                                {...tooltipProps}
                                className={clsx(
                                    `${staticClass}`,
                                    componentsProps.tooltip?.className
                                )}
                            >
                                {title}
                                {arrow ? (
                                    <ArrowComponent
                                        {...tooltipArrowProps}
                                        className={clsx(
                                            `${staticClass}-arrow`,
                                            componentsProps.arrow?.className
                                        )}
                                        ref={setArrowRef}
                                    />
                                ) : null}
                            </TooltipComponent>
                        </TransitionComponent>
                    );
                }}
            </PopperComponent>
        </React.Fragment>
    );
});

export default Tooltip;
